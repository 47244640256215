import { Typography } from '@mui/material'
import { Close, QuestionMark } from '@mui/icons-material'

import { renderGuideline } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayVisualization/Modals/HelpModal/HelpModal'
import TrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap'
import { LoadingButton } from 'components/mui'

import useTrayAnalysisContentLogic from './TrayAnalysisContent.logic'

import { TrayType } from '../EditTrayModal/EditTrayModal.types'
import './TrayAnalysisContent.scss'

const TrayAnalysisContent = ({
  onClose,
  trayType = 'stryker screw caddy',
  trayImg,
}: {
  onClose: () => void
  trayType?: TrayType
  trayImg?: string
}) => {
  const { shouldShowGuide, handleGuideClick } = useTrayAnalysisContentLogic()

  return (
    <div className="loaner-tray-analysis-content_wrapper">
      <div className="header">
        <Typography variant="h2">Confirm Tray Content</Typography>

        {trayImg && <img src={trayImg} alt="Captured Tray" />}

        <div className="buttons_container">
          <LoadingButton onClick={onClose} className="confirm">
            Confirm
          </LoadingButton>

          <LoadingButton
            className="help"
            loading={false}
            disabled={false}
            onClick={handleGuideClick}
          >
            {shouldShowGuide ? (
              <Close className="icon" />
            ) : (
              <QuestionMark className="icon" />
            )}
          </LoadingButton>
        </div>
      </div>

      {shouldShowGuide && (
        <div className="guide_container">
          {renderGuideline('- Non present:', 'black', '0.5px solid black')}
          {renderGuideline('- Bone Screw:', '#92c05b', '0.5px solid black')}
          {renderGuideline(
            '- Self Drilling screw:',
            '#e2dca8',
            '0.5px solid black'
          )}
          {renderGuideline(
            '- Emergency screw:',
            '#b13b80',
            '0.5px solid black'
          )}
        </div>
      )}

      <TrayMap trayType={trayType} loadWithUDIs={false} />
    </div>
  )
}

export default TrayAnalysisContent
