import FloatingBar from './FloatingBar/FloatingBar'
import Box from '@mui/material/Box'
import * as React from 'react'

type Props = {
  color?: string
  children: JSX.Element
}

function Layout({ color, children }: Props) {
  const [isLg, setIsLg] = React.useState(window.innerWidth >= 600)
  const navbarHeight = isLg ? 64 : 56

  React.useEffect(() => {
    const handleResize = () => {
      setIsLg(window.innerWidth >= 600)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div>
      <FloatingBar />
      <Box
        display="flex"
        flexDirection="column"
        minHeight={`calc(100dvh - ${navbarHeight}px)`}
        width="100%"
        pt={`${navbarHeight}px`}
        sx={{ backgroundColor: color ? color : 'white' }}
      >
        {children}
      </Box>
    </div>
  )
}

export default Layout
