import * as React from 'react'
import { QueryResult, OperationVariables } from '@apollo/client'
import { isProbablyGS1, isMaybeHIBCC, isMaybeICCBBA } from 'lib/udi'
import { newBidSearchResponseToResults } from 'lib/utils/betterid'
import { BetterIdLookupQuery, BetterIdResult } from 'common/types'
import { useCortexDecoderContext } from 'lib/batchScanner'
import { useBetterIdLookupQuery } from 'lib/apollo/hooks'

type ScanDataType = 'url' | 'udi' | 'unknown'
type IssuingAgency = 'GS1' | 'HIBCC' | 'ICCBBA' | 'unknown'

interface ScanValueDetails {
  type: ScanDataType
  suspectedIssuer: IssuingAgency
}

interface ScanLookupState {
  isComplete: boolean
  isLoading: boolean
  error?: Error
}

export interface CurrentScanDetails {
  activeId?: string
  activeValue?: string
  details: ScanValueDetails
  lookupState: ScanLookupState
  results?: BetterIdResult[]
  selectedResult?: BetterIdResult
  setSelectedResult: (result?: BetterIdResult) => void
  reset: () => void
  _lookupQuery: QueryResult<BetterIdLookupQuery, OperationVariables>
}

function getScanValueDetails(scanData?: string): ScanValueDetails {
  const ret: ScanValueDetails = {
    type: 'unknown',
    suspectedIssuer: 'unknown',
  }

  if (!scanData) {
    return ret
  }

  try {
    new URL(scanData)
    ret.type = 'url'
    return ret
  } catch (_) {}

  if (isProbablyGS1(scanData)) {
    ret.type = 'udi'
    ret.suspectedIssuer = 'GS1'
  } else if (isMaybeHIBCC(scanData)) {
    ret.type = 'udi'
    ret.suspectedIssuer = 'HIBCC'
  } else if (isMaybeICCBBA(scanData)) {
    ret.type = 'udi'
    ret.suspectedIssuer = 'ICCBBA'
  }

  return ret
}

export function useBatchScanHandler(): CurrentScanDetails {
  // scanner state
  const { currentScan, setCurrentScan } = useCortexDecoderContext()

  // control the current scan value
  const [activeValue, setActiveValue] = React.useState<string | undefined>()
  const activeValueTimestampRef = React.useRef<number | undefined>(undefined)
  const activeId =
    activeValue && activeValueTimestampRef.current
      ? `${activeValue}|${activeValueTimestampRef.current}`
      : undefined

  // control our own lookup state
  const [isComplete, setIsComplete] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // lookup results
  const [results, setResults] = React.useState<BetterIdResult[] | undefined>()
  const [selectedResult, setSelectedResult] = React.useState<
    BetterIdResult | undefined
  >(undefined)

  // parse the current scan value
  const valueDetails = getScanValueDetails(activeValue)

  // reset state of scan handler
  const reset = React.useCallback(() => {
    setIsLoading(false)
    setError(undefined)
    setResults(undefined)
    setSelectedResult(undefined)
    setActiveValue(undefined)
    setCurrentScan(undefined)
    setIsComplete(false)
  }, [setCurrentScan])

  const lookupQuery = useBetterIdLookupQuery(activeValue, {
    // don't bother looking up urls
    skip: valueDetails.type === 'url',
    onCompleted: (data) => {
      const results = newBidSearchResponseToResults(data.betterIdLookup)
      // if results in not an array, it's an exact match
      if (Array.isArray(results)) {
        setResults(results)
      } else {
        setSelectedResult(results)
        setResults([results])
      }
      setIsLoading(false)
      setIsComplete(true)
      return data
    },
    onError: (error) => {
      setError(error)
      setIsLoading(false)
      setIsComplete(false)
    },
  })

  // This runs whenenver the scanner callback hits because a code is in the
  // viewfinder (currentScan).
  React.useEffect(() => {
    if (
      currentScan?.barcodeData &&
      !isComplete &&
      (!activeValue || currentScan.barcodeData !== activeValue)
    ) {
      setIsLoading(true)
      setActiveValue(currentScan.barcodeData)
      activeValueTimestampRef.current = Date.now()
    }
  }, [currentScan, valueDetails.type, isComplete, activeValue])

  // If the scan value is a url, we don't need to do a lookup
  React.useEffect(() => {
    if (valueDetails.type === 'url') {
      setIsComplete(true)
      setIsLoading(false)
    }
  }, [valueDetails.type])

  const lookupState = React.useMemo(
    () => ({
      isComplete,
      error,
      isLoading,
    }),
    [isComplete, error, isLoading]
  )

  return {
    activeId,
    activeValue,
    details: valueDetails,
    lookupState,
    selectedResult,
    results,
    setSelectedResult,
    reset,
    _lookupQuery: lookupQuery,
  }
}
