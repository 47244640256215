import MuiLoadingButton from '@mui/lab/LoadingButton'
import { Check, Add, DeleteOutline } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Typography,
  ButtonBaseProps,
} from '@mui/material'

type Props = React.ComponentProps<typeof MuiLoadingButton> & {
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  showIcon?: boolean
  mode?: 'default' | 'delete' | 'add' | 'secondary' | 'success' | 'cancel'
  fullwidth?: boolean
  customIcon?: any
  dataTestId?: string
  wrapText?: boolean
} & ButtonBaseProps

function LoadingButton({
  disabled,
  loading,
  loadingText,
  showIcon = true,
  mode = 'default',
  variant = 'contained',
  sx = {},
  fullwidth = true,
  customIcon,
  dataTestId,
  wrapText = false,
  ...props
}: Props) {
  const isOutlined = variant === 'outlined'
  const isOutlinedAndLoading = loading && isOutlined
  const disabledTextColor = disabled ? 'white' : 'transparent'

  // TODO: combine loading button and alert dialog mode configs
  const config = {
    default: {
      color: 'primary.lightest',
      outlinedVariantText: 'primary.main',
      disabledBackgroundColor: 'primary.disabled',
      hover: 'primary.hover',
      backgroundColor: 'primary.main',
      contrastText: 'primary.contrastText',
      dark: 'primary.dark',
    },
    cancel: {
      color: 'error.lightest',
      outlinedVariantText: 'error.main',
      disabledBackgroundColor: 'error.disabled',
      hover: 'error.hover',
      backgroundColor: 'error.main',
      contrastText: 'error.contrastText',
      dark: 'error.dark',
    },
    secondary: {
      color: 'warning.lightest',
      outlinedVariantText: 'warning.main',
      disabledBackgroundColor: 'warning.disabled',
      hover: 'warning.hover',
      backgroundColor: 'warning.main',
      contrastText: 'warning.contrastText',
      dark: 'warning.dark',
    },
    delete: {
      color: 'warning.lightest',
      outlinedVariantText: 'warning.main',
      disabledBackgroundColor: 'warning.disabled',
      hover: 'warning.hover',
      backgroundColor: 'warning.main',
      contrastText: 'warning.contrastText',
      dark: 'warning.dark',
      startIcon: DeleteOutline,
    },
    // TODO: combine add and success objects
    add: {
      color: 'success.lightest',
      outlinedVariantText: 'success.main',
      disabledBackgroundColor: 'success.disabled',
      hover: 'success.hover',
      backgroundColor: 'success.main',
      contrastText: 'success.contrastText',
      dark: 'success.dark',
      startIcon: Add,
    },
    success: {
      color: 'success.lightest',
      outlinedVariantText: 'success.dark',
      disabledBackgroundColor: 'success.disabled',
      hover: 'success.hover',
      backgroundColor: 'success.main',
      contrastText: 'success.contrastText',
      dark: 'success.dark',
      startIcon: Check,
    },
  } as any

  const StartIcon = config[mode].startIcon

  const loadingTextComponent = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
      }}
    >
      <CircularProgress
        size={16}
        sx={{
          color: isOutlinedAndLoading ? config[mode].hover : 'white',
          mr: 1,
        }}
      />
      <Typography variant="h4" color="inherit">
        {loadingText}
      </Typography>
    </Box>
  )

  return (
    <MuiLoadingButton
      data-testid={dataTestId}
      disabled={!loading && disabled}
      fullWidth={fullwidth}
      disableElevation
      loading={loading}
      loadingIndicator={loadingText ? loadingTextComponent : null}
      startIcon={
        StartIcon && showIcon ? <StartIcon /> ? customIcon : null : customIcon
      }
      color="inherit"
      sx={{
        whiteSpace: wrapText ? 'normal' : 'nowrap',
        textAlign: 'center',
        color: isOutlined
          ? config[mode].outlinedVariantText
          : config[mode].color,
        backgroundColor: isOutlined
          ? 'transparent'
          : config[mode].backgroundColor,
        '&:disabled': {
          backgroundColor: isOutlinedAndLoading
            ? 'transparent'
            : config[mode].disabledBackgroundColor,
          borderColor: config[mode].hover,
          border: 'transparent',
          color: disabledTextColor,
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: isOutlinedAndLoading ? config[mode].hover : 'white',
        },
        '&:hover': {
          backgroundColor: config[mode].hover,
          color: loading ? 'transparent' : config[mode].contrastText,
          borderColor: loading ? 'transparent' : config[mode].hover,
        },
        ...sx,
      }}
      variant={variant}
      {...props}
    >
      {props.children}
    </MuiLoadingButton>
  )
}

export default LoadingButton
