import { Grid } from '@mui/material'
import { FilterButtonBox } from 'components/molecules/FilterButtonBox/FilterButtonBox'
import './FilterBar.scss'
import { DateRangePicker } from 'components/molecules/DateRangePicker/DateRangePicker'
import { FilterProps } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'
import { useIsMobile } from 'lib/utils/useIsMobile'

interface FilterBarProps extends FilterProps {
  renderDatePicker?: boolean
}

export const FilterBar = ({
  condenseButtons,
  renderAssetTypeFilter,
  renderMrnFilter,
  renderPatientNameFilter,
  renderSurgeonFilter,
  renderProcedureTypeFilter,
  renderDepartmentFilter,
  renderDatePicker,
  renderDeviceDescription,
  renderDisposition,
  isDisabled,
  isLoading,
}: FilterBarProps) => {
  const isMobile = useIsMobile()

  return (
    <Grid
      container
      className={`filter-bar-container ${isMobile ? 'is-mobile' : ''}`}
      rowSpacing={2}
    >
      <Grid item xs={!isMobile && 8}>
        <FilterButtonBox
          condenseButtons={condenseButtons}
          renderAssetTypeFilter={renderAssetTypeFilter}
          renderMrnFilter={renderMrnFilter}
          renderPatientNameFilter={renderPatientNameFilter}
          renderSurgeonFilter={renderSurgeonFilter}
          renderProcedureTypeFilter={renderProcedureTypeFilter}
          renderDepartmentFilter={renderDepartmentFilter}
          renderDeviceDescription={renderDeviceDescription}
          renderDisposition={renderDisposition}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Grid>
      {renderDatePicker && (
        <Grid item xs={!isMobile && 4}>
          <DateRangePicker />
        </Grid>
      )}
    </Grid>
  )
}
