// MUI
import { ButtonBase, Typography } from '@mui/material'

// Style
import './ScannedItem.css'

// Types
import { ProcessedProduct } from '../process-modal/ProcessModal.types'
import { Delete } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useIsTablet } from 'lib/utils/mediaQueries'
import DetailsItem from './details-item/DetailsItem'
import dayjs from 'lib/dayjs'

const ScannedItem: React.FC<ProcessedProduct> = ({
  name,
  flag,
  serialNumber,
  modelNumber,
  lotBatch,
  expiryDate,
  quantity,
  index,
  isNew,
  isError,
  onDelete,
}) => {
  const isTablet = useIsTablet()
  const [isHighlighted, setIsHighlighted] = useState(false)
  const isExpired = dayjs(expiryDate).isBefore(dayjs())

  useEffect(() => {
    if (quantity && quantity > 1 && index === 0) {
      setIsHighlighted(true)
      const timeoutId = setTimeout(() => {
        setIsHighlighted(false)
      }, 6000)

      return () => clearTimeout(timeoutId)
    }
  }, [index, quantity])

  return (
    <div
      className={`scanned-item ${isHighlighted ? 'highlight' : ''} ${
        isError ? 'error' : ''
      } ${isTablet ? 'is-tablet' : ''}`}
    >
      <div className="top">
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          {name}
        </Typography>
        <div className="right">
          {quantity && quantity > 1 && (
            <span className="quantity">{quantity}</span>
          )}
          {isNew && <span className="quantity">NEW</span>}
          <span className="flag">{flag}</span>
        </div>
      </div>
      <div className="bottom">
        <div className="details">
          <div className="col">
            {modelNumber && <DetailsItem label="Model " value={modelNumber} />}
            {expiryDate && (
              <DetailsItem
                label="Expiration "
                value={dayjs(expiryDate).format('MM/DD/YYYY')}
                isExpired={isExpired}
              />
            )}
          </div>
          <div className="col">
            {lotBatch && <DetailsItem label="Lot " value={lotBatch} />}
            {serialNumber && (
              <DetailsItem label="Serial " value={serialNumber} />
            )}
          </div>
        </div>
        <ButtonBase disableRipple onClick={onDelete}>
          <Delete
            color="disabled"
            sx={{ ':hover': { color: 'var(--gray-5)' } }}
          />
        </ButtonBase>
      </div>
    </div>
  )
}

export default ScannedItem
