import { Box, Grid, Typography } from '@mui/material'
import { MainIconFlowState } from 'common/types'
import { useNavigate } from 'react-router-dom'
import { AssetIconButton } from './AssetTypeIconButton'
import { Helmet } from 'react-helmet'
import { DigitalTrayIcon } from 'assets/icons/DigitalTrayIcon'
import { InstrumentTrayIcon } from 'assets/icons/InstrumentTrayIcon'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface TrayIconLandingProps {
  mainIconFlowState: MainIconFlowState
  setMainIconFlowState: (val: MainIconFlowState) => void
  setOnTrayIconLanding: (val: boolean) => void
}

const TrayIconLanding = ({
  mainIconFlowState,
  setMainIconFlowState,
  setOnTrayIconLanding,
}: TrayIconLandingProps) => {
  const flags = useFlags()
  const TITLE = 'Home - Trays'
  const navigate = useNavigate()

  const trayList = {
    instrumentTray: {
      IconComponent: InstrumentTrayIcon,
      label: 'Instrument Tray',
      isVisible: flags.traySelectionInstrumentTrayButton,
    },
    digitalHardwareTray: {
      IconComponent: DigitalTrayIcon,
      label: 'Digital Hardware Tray',
      isVisible: flags.traySelectionDigitalTrayButton,
    },
  }

  const handleIconClick = (trayType: MainIconFlowState['trayType']) => {
    if (mainIconFlowState) {
      const iconFlowState = mainIconFlowState
      iconFlowState.trayType = trayType
      setMainIconFlowState(iconFlowState)
      setOnTrayIconLanding(false)
      if (trayType === 'INSTRUMENT TRAY') {
        navigate(`${window.location.pathname}/instrument-trays`)
      }
      if (trayType === 'DIGITAL HARDWARE TRAY') {
        navigate(`${window.location.pathname}/assigned-digital-trays`)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100vh',
          m: 1,
        }}
        display="block"
      >
        <Box>
          <Typography variant="h1" align="center">
            Document Tray Type
          </Typography>
        </Box>

        <Grid
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          display="flex"
          minHeight={'70vh'}
        >
          {Object.entries(trayList).map(([key, value]) => {
            if (value.isVisible === false) {
              return null
            }

            const upperCaseLabel =
              value?.label?.toUpperCase() as MainIconFlowState['trayType']
            const labelTyped = value?.label as MainIconFlowState['trayType']
            const iconTyped =
              value?.IconComponent as unknown as React.ComponentClass

            return (
              <div key={key} data-testid={key}>
                <AssetIconButton
                  key={key}
                  onClick={() => handleIconClick(upperCaseLabel)}
                  IconComponent={iconTyped}
                  label={labelTyped}
                />
              </div>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

export default TrayIconLanding
