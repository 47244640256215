import { useAuth0 } from 'app/Auth'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { PrefCardsResponseItem } from './types'

export const useGetPreferenceCardById = ({
  id,
  enabled,
}: {
  id: string
  enabled: boolean
}) => {
  const { getAccessToken } = useAuth0()
  const tokenPromise = getAccessToken()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL

  return useQuery<PrefCardsResponseItem, AxiosError | any>(
    ['preferenceCard', id],
    async () => {
      const token = await tokenPromise
      try {
        const response = await axios.get(
          `${REPORTS_URL}api/v1/preference_cards/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data
      } catch (error) {
        throw error
      }
    },
    {
      enabled,
    }
  )
}
