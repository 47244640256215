import styled from '@emotion/styled'
import {
  TableRowProps,
  TableHeadProps,
  TableRow,
  TableHead,
  TableCell,
  TableSortLabel,
} from '@mui/material'
import { theme } from 'assets/styles/customTheme'

export type Order = 'asc' | 'desc'

export interface HeadCell extends TableHeadProps {
  label: string
  numeric: boolean
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export interface EnhancedTableProps {
  tableHeaders: HeadCell[]
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TableRowProps
  ) => void
  order: Order
  orderBy: string
  disableSorting?: boolean
}

export const TableHeader = ({
  tableHeaders,
  onRequestSort,
  order,
  orderBy,
  disableSorting,
}: EnhancedTableProps) => {
  const createSortHandler =
    (property: keyof TableRowProps) => (event: React.MouseEvent<unknown>) => {
      if (!disableSorting) {
        onRequestSort(event, property)
      }
    }
  const CustomHeader = styled(TableRow)({
    '&.MuiTableRow-root': {
      backgroundColor: theme.palette.grayscale.hover,
    },
  })

  return (
    <TableHead>
      <CustomHeader>
        {tableHeaders?.map((headCell: HeadCell) => {
          return (
            <TableCell
              sx={{ ...headCell.sx, backgroundColor: 'transparent' }}
              key={headCell.id}
              align={'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                hideSortIcon={disableSorting}
                active={orderBy === headCell.id && !disableSorting}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id as keyof TableRowProps)}
                sx={{
                  textTransform: 'uppercase',
                  whiteSpace: 'nowrap',
                  '& .MuiTableSortLabel-icon, &.Mui-active': {
                    color: `${theme.palette.primary.main} !important`,
                  },
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </CustomHeader>
    </TableHead>
  )
}
