import { useEffect, useState } from 'react'
import './EditCount.scss'
import { EditCountProps } from './EditCount.types'
import useEditCountLogic from './EditCount.logic'

const EditCount: React.FC<EditCountProps> = (props) => {
  const { disableIncrease } = props
  const { count, handleDecrease, handleIncrease } = useEditCountLogic(props)

  return (
    <div className="edit-count-container">
      <span
        className="count"
        aria-label={`Edit count, current value is ${count}`}
      >
        {count}
      </span>
      <button
        className="decrease-button"
        onClick={handleDecrease}
        aria-label="Decrease count"
        disabled={count === 0}
      >
        -
      </button>
      <button
        className="increase-button"
        onClick={handleIncrease}
        aria-label="Increase count"
        disabled={disableIncrease}
      >
        +
      </button>
    </div>
  )
}

export default EditCount
