import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react'

// Components
import { SelectChangeEvent } from '@mui/material'

// Context
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'

// Types
import {
  ValidationErrorMap,
  FormField,
  BatchDispositionModalLogicProps,
} from './BatchDispositionModal.types'

// Other
import { z } from 'zod'
import { dispositionSchema, DispositionData } from 'common/disposition'
import { useAssignedDigitalTrays } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import { ItemResponse, UndocumentedScan } from 'common/types'
import { useParams } from 'react-router-dom'
import { useEditTrayItemJSONDetailsFunction } from 'lib/apollo/hooks'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'

export const initialBatchDispositionModalFormState = {
  assetTray: '',
  assetType: '',
  implantStatus: undefined,
  implantSite: '',
  wastedReason: undefined,
}

const formatValidationErrors = (
  error: z.ZodError<DispositionData>
): ValidationErrorMap => {
  const formattedErrors = error.format()
  const errorMap: ValidationErrorMap = {}
  for (const field in formattedErrors) {
    if (field in initialBatchDispositionModalFormState) {
      const errorMessage = formattedErrors[field as FormField]?._errors?.[0]
      errorMap[field as FormField] = errorMessage
    }
  }
  return errorMap
}

export const useBatchDispositionModalLogic = ({
  activeTray,
  assetType,
  onSave,
  registerTrayScanHandler,
  isAssociatedProduct,
  setAssociatedModal,
  handleAssociatedSubmitInitial,
  dispositionStatus,
  isNonAssociatedHardwareSelected,
  isManualAddition,
  selectedScans,
}: BatchDispositionModalLogicProps) => {
  // --------------------  Implant Sites --------------------
  const { implantSites } = useImplantSites()
  const implantSiteList = implantSites.map((implantSite) => implantSite.name)

  // DTM update SPD data
  const { surgeryId } = useParams()
  const { undocumentedScans } = useUndocumentedAssetsContext()
  const { editTrayItemJSONDetails } = useEditTrayItemJSONDetailsFunction()
  const { spdScrews } = useAssignedDigitalTrays()

  // --------------------  Form Validation --------------------
  const [formState, setFormState] = useState<Partial<DispositionData>>({
    ...initialBatchDispositionModalFormState,
    implantStatus: 'IMPLANTED',
    assetType: assetType,
    assetTray: activeTray,
  })

  const [validationErrors, setValidationErrors] = useState<ValidationErrorMap>(
    {}
  )
  const isSiteLocationEnabled =
    (formState.implantStatus === 'IMPLANTED' ||
      formState.implantStatus === 'EXPLANTED') &&
    (formState.implantSite === 'Other' || formState.implantSite === 'Mouth')

  const isFormValid = useMemo(() => {
    const hasErrors = Object.values(validationErrors).some(Boolean)
    const isMissingDisposition =
      formState.assetType !== 'consumable' &&
      formState.implantStatus === undefined
    const isMissingWastedReason =
      formState.implantStatus === 'WASTED' && !formState.wastedReason

    const isMissingImplantSite =
      (formState.implantStatus === 'IMPLANTED' ||
        formState.implantStatus === 'EXPLANTED') &&
      !formState.implantSite

    const isMissingExplantedReason =
      isManualAddition &&
      formState.implantStatus === 'EXPLANTED' &&
      !formState.explantedReason

    const isMissingExplantedReasonNote =
      isManualAddition &&
      formState.implantStatus === 'EXPLANTED' &&
      formState.explantedReason === 'OTHER' &&
      !formState.explantedReasonNote?.length
    const isMissingSiteLocation =
      isSiteLocationEnabled && !formState.siteLocation

    return (
      !hasErrors &&
      !isMissingImplantSite &&
      !isMissingWastedReason &&
      !isMissingDisposition &&
      !isMissingExplantedReason &&
      !isMissingExplantedReasonNote &&
      !isMissingSiteLocation
    )
  }, [formState, validationErrors, isSiteLocationEnabled, isManualAddition])

  // --------------------  Hooks --------------------
  useEffect(() => {
    handleChangeFormData({
      target: {
        name: 'assetType',
        value: assetType,
      },
    })
    handleChangeFormData({
      target: {
        name: 'implantStatus',
        value: 'IMPLANTED',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // should only run upon modal open or else it resets values on every render

  useEffect(() => {
    registerTrayScanHandler &&
      registerTrayScanHandler((scan) => {
        setFormState((prev) => ({
          ...prev,
          assetTray: scan,
        }))
      })
  }, [setFormState, registerTrayScanHandler])

  useEffect(() => {
    const result = dispositionSchema.safeParse(formState)
    if (!result.success) {
      const errors = formatValidationErrors(result.error)
      setValidationErrors(errors)
    } else {
      setValidationErrors({})
    }
  }, [formState])

  useEffect(() => {
    if (isAssociatedProduct) {
      setFormState((prev) => ({
        ...prev,
        implantStatus: 'ASSOCIATED_ASSET',
      }))
    }
  }, [assetType, isAssociatedProduct])

  useEffect(() => {
    if (dispositionStatus) {
      setFormState((prevFormState) => ({
        ...prevFormState,
        implantStatus: dispositionStatus,
      }))
    }
  }, [dispositionStatus])

  // --------------------  Handles --------------------
  const handleChangeFormData = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string> | any
  ) => {
    const { name, value } = e.target

    if (
      formState.implantSite === 'Mouth' &&
      name === 'siteLocation' &&
      value.length > 4
    ) {
      return
    }

    const resetValues: Partial<DispositionData> = {}

    if (name === 'assetType') {
      resetValues.assetType = value
      resetValues.implantStatus = undefined
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
    }

    if (name === 'implantStatus') {
      resetValues.implantStatus = value
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
    }

    if (name === 'disposition') {
      switch (value) {
        case 'IMPLANTED':
          resetValues.wastedReason = undefined
          break
        case 'WASTED':
          resetValues.implantSite = undefined
          break
      }
    }

    if (name === 'implantSite') {
      resetValues.implantSite = value
    }

    setFormState((prev: any) => ({
      ...prev,
      ...resetValues,
      [name]: value,
    }))
  }

  const handleAssociatedSubmit = (e: any) => {
    e.preventDefault()
    const result = dispositionSchema.safeParse(formState)

    if (isNonAssociatedHardwareSelected && handleAssociatedSubmitInitial) {
      setAssociatedModal && setAssociatedModal(false)
      handleAssociatedSubmitInitial(e)
    } else if (result.success) {
      onSave(result.data)
    }
  }

  const areThereDTMScrews = undocumentedScans.some((scan) => scan.isDTMScrew)

  const updateTray = async (trayId: string, screws: BetterIDTrayScrew[]) => {
    const input = {
      id: Number(trayId),
      productDetails: JSON.stringify({ screws }),
    }

    try {
      await editTrayItemJSONDetails(input)
    } catch (error) {
      console.error('Failed to edit the JSON data of the tray item', error)
    }
  }

  const groupByTrayId = (
    undocumentedScans: UndocumentedScan[]
  ): Map<string, BetterIDTrayScrew[]> => {
    const map = new Map<string, BetterIDTrayScrew[]>()

    const dtmScrewScans = undocumentedScans.filter(
      (scan) =>
        scan.isDTMScrew &&
        selectedScans?.some((selectedScan) => selectedScan._id === scan._id)
    )

    for (const scan of dtmScrewScans) {
      const trayId = scan.dtmScrewData?.trayId
      if (trayId) {
        const trayScrews = dtmScrewScans
          .filter(
            (scan) => scan.dtmScrewData && scan.dtmScrewData.trayId === trayId
          )
          .map((screwScan) => ({
            x: screwScan.dtmScrewData?.column as number,
            row: screwScan.dtmScrewData?.row as number,
            label: screwScan.dtmScrewData?.label as string,
            column: screwScan.dtmScrewData?.size as number,
          }))

        if (!map.has(trayId)) {
          map.set(trayId, [])
        }

        for (const screw of trayScrews) {
          map.get(trayId)!.push(screw)
        }
      }
    }
    return map
  }

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    e.preventDefault()

    const result = dispositionSchema.safeParse(formState)
    if (result.success) {
      if (surgeryId && areThereDTMScrews) {
        try {
          const mapOfTrayScrews = groupByTrayId(undocumentedScans)

          mapOfTrayScrews.forEach((trayScrews, trayId) =>
            updateTray(trayId, trayScrews)
          )
        } catch (error) {
          console.error('Failed to update SPD data', error)
        }
      }

      onSave(result.data)
    }
  }

  return {
    formState,
    isFormValid,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    handleAssociatedSubmit,
    isSiteLocationEnabled,
  }
}
