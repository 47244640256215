import AddTrayDetails from 'components/organisms/AddTrayDetails/AddTrayDetails'

import './MainTrayInfo.scss'
import TrayCaseDetails from 'components/organisms/TrayCaseDetails/TrayCaseDetails'

const MainTrayInfo = () => {
  return (
    <div className="main-tray-info-page_container">
      <div className="tray-details_container">
        <AddTrayDetails />
      </div>

      <div className="case-details_container">
        <TrayCaseDetails />
      </div>
    </div>
  )
}

export default MainTrayInfo
