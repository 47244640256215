import { SelectChangeEvent } from '@mui/material'
import { DispositionData, dispositionSchema } from 'common/disposition'
import { AssetData, Scan } from 'common/types'
import { useEditDocumentedListContext } from 'lib/context/EditDocumentedListContext/EditDocumentedListContext'
import {
  EditDispositionModalLogicProps,
  EditDocumentedScanInput,
} from 'lib/context/EditDocumentedListContext/EditDispositionList.types'
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import { ChangeEvent, useState } from 'react'

export const useEditDispositionModalLogic = ({
  assetTray,
  isMultipack,
}: EditDispositionModalLogicProps) => {
  const {
    setScanStateMap,
    foundScanRef,
    isSavingEditedAsset,
    submitDisposition,
    isSelectModalOpen,
    setIsSelectModalOpen,
    setIsEditModalOpen,
    setScans,
    scans,
    setAssetGroupId,
    assetGroupId,
    isEditModalOpen,
    handleReset,
    scanStateMap,
    scanIsMatching,
    consumableAssetGroup,
    setConsumableAssetGroup,
    isLoading,
  } = useEditDocumentedListContext()
  const { implantSites } = useImplantSites()
  const implantSiteList = implantSites.map((implantSite) => implantSite.name)

  const scanToUseInForm = foundScanRef.current ? foundScanRef.current : scans[0]

  const selectIsSelectedId = () => {
    const selectedEntry = Object.entries(scanStateMap).find(
      ([, value]) => value.isSelected
    )

    return selectedEntry ? selectedEntry[0] : undefined
  }

  const selectedAssetId =
    scans.length > 1 ? selectIsSelectedId() : scans[0]?._id

  const [formState, setFormState] = useState<Partial<DispositionData>>({
    siteLocation: scanToUseInForm?.siteLocation,
    implantStatus: scanToUseInForm?.implantStatus,
    assetType:
      scanToUseInForm?.assetType || consumableAssetGroup?.scans[0].assetType,
    implantSite: scanToUseInForm?.implantSite || undefined,
    wastedReason:
      (scanToUseInForm?.wastedReason as DispositionData['wastedReason']) ||
      undefined,
    assetTray: assetTray || '',
    explantedReason: scanToUseInForm?.explantedReason,
    explantedReasonNote: scanToUseInForm?.explantedReasonNote,
    count: scanToUseInForm?.count || consumableAssetGroup?.total,
  })

  const isSiteLocationEnabled =
    (formState.implantStatus === 'IMPLANTED' ||
      formState.implantStatus === 'EXPLANTED') &&
    (formState.implantSite === 'Other' || formState.implantSite === 'Mouth')

  const isConsumable = formState.assetType === 'consumable'

  const isImplantedOrExplanted =
    formState.implantStatus === 'IMPLANTED' ||
    formState.implantStatus === 'EXPLANTED'

  const isNotWastedOrAssociated =
    formState.implantStatus !== 'WASTED' &&
    formState.implantStatus !== 'ASSOCIATED_ASSET'

  const renderImplantSites =
    !isConsumable &&
    isImplantedOrExplanted &&
    !!formState.implantStatus &&
    isNotWastedOrAssociated

  const handleEditOnOpen = (
    agId: AssetData['_id'],
    scansToEdit?: Scan[],
    scanIds?: Scan['_id'],
    consumableAssetGroup?: AssetData
  ) => {
    setAssetGroupId(agId)

    const isMultipleAssets = scanIds?.includes(',') || false
    if (!isMultipleAssets && scansToEdit) {
      setScans(scansToEdit)
      setIsEditModalOpen(true)
    } else if (scansToEdit) {
      const scanIdsArray = scanIds?.split(',')
      const filteredScans: Scan[] = scansToEdit?.filter((scan) =>
        scanIdsArray?.includes(scan._id)
      )
      setScans(filteredScans)
      setIsSelectModalOpen(true)
    } else if (consumableAssetGroup) {
      setConsumableAssetGroup(consumableAssetGroup)
      setIsEditModalOpen(true)
    }
  }

  const toggleSelection = (scanId: string) => {
    setScanStateMap((s) => ({
      ...s,
      [scanId]: {
        ...s[scanId],
        isSelected: !s[scanId]?.isSelected,
      },
    }))
  }

  const handleChangeFormData = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string> | any
  ) => {
    const { name, value } = e.target

    if (
      formState.implantSite === 'Mouth' &&
      name === 'siteLocation' &&
      value.length > 4
    ) {
      return
    }

    const resetValues: Partial<DispositionData> = {}

    if (name === 'assetType') {
      resetValues.assetType = value
    }

    if (name === 'implantStatus') {
      resetValues.implantStatus = value
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
    }

    if (name === 'implantSite') {
      resetValues.siteLocation = undefined
    }

    if (name === 'disposition') {
      switch (value) {
        case 'IMPLANTED':
          resetValues.wastedReason = undefined
          break
        case 'WASTED':
          resetValues.implantSite = undefined
          break
      }

      if (name === 'consumableCount') {
        resetValues.count = value
      }
    }
    setFormState((prev: Partial<DispositionData>) => ({
      ...prev,
      ...resetValues,
      [name]: value,
    }))
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (e) {
      e.preventDefault()

      const result = dispositionSchema.safeParse(formState)
      const deviceId = scanToUseInForm?.deviceId
      const scanId = scanToUseInForm?._id

      const scanObject = {
        deviceId: deviceId,
        assetGroupId: assetGroupId || consumableAssetGroup?._id,
        scanId: scanId,
        isMultipack: isMultipack,
      }

      if (result.success && scanObject) {
        submitDisposition(
          result.data,
          scanObject as EditDocumentedScanInput
        ).then((result) => {
          if (result) setIsEditModalOpen(false)
          else {
            console.error('Error submitting disposition')
          }
        })
      } else if (!result.success) {
        console.error(result.error)
      }
    }
  }

  return {
    handleEditOnOpen,
    toggleSelection,
    isSelectModalOpen,
    setIsEditModalOpen,
    setIsSelectModalOpen,
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSavingEditedAsset,
    renderImplantSites,
    isConsumable,
    setScanStateMap,
    isEditModalOpen,
    handleReset,
    scanStateMap,
    scans,
    selectedAssetGroupId: assetGroupId || consumableAssetGroup?._id || '',
    scanIsMatching,
    isSiteLocationEnabled,
    selectedAssetId,
    consumableAssetGroup,
    isLoading,
  }
}
