import { useState, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  AssetData,
  Surgery,
  MainIconFlowState,
  BetterIdAsset,
  ManualInputFormData,
  BetterIdResult,
  NewAsset,
} from 'common/types'
import { useAddSurgeryAssetScans } from 'lib/apollo/hooks'
import { useAssetFormData } from 'lib/context/AssetFormData'
import { useScanHandler } from 'lib/scan-handler'
import { useCortexDecoderContext } from 'lib/cortex'
import ManualAssetEntry from 'views/assets/ManualAssetEntry/ManualAssetEntry'
import Result from 'views/assets/Result/Result'
import ScanAssetEntry from 'views/assets/ScanAssetEntry/ScanAssetEntry'
import ProtectedUserRoute from 'app/ProtectedUserRoute'
import { useUser } from 'app/User'
import dayjs from 'lib/dayjs'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { getAssetTypeCaps } from 'lib/utils/getAssetType'
import { MediaFile } from 'components/organisms/MediaUpload/MediaUpload.types'

interface Props {
  assetGroups: AssetData[]
  userCanScan: boolean
  surgery: Surgery
  mainIconFlowState: MainIconFlowState
  isBatchMode: boolean
  currentAssetType: MainIconFlowState['assetTypeLabel']
  setUserCanScan: (value: boolean) => void
}
function AddAsset({
  assetGroups,
  userCanScan,
  setUserCanScan,
  surgery,
  mainIconFlowState,
  isBatchMode,
  currentAssetType,
}: Props) {
  const navigate = useNavigate()
  const { surgeryId } = useParams()
  let location = useLocation()

  // State
  const [isScanHandlerActive, setIsScanHandlerActive] = useState(false)
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>([])

  const { currentScan, setCurrentScan } = useCortexDecoderContext()
  const scanDetails = useScanHandler(currentScan, isScanHandlerActive)
  const { selectedResult, setSelectedResult, setIsComplete } = scanDetails
  const {
    assetFormData,
    handleChangeAssetType,
    handleChangeTray,
    handleChangeSerialNumber,
    createDispositionsChangeHandler,
    resetAssetFormData,
  } = useAssetFormData()
  const { user } = useUser()
  const userName = `${user?.firstName} ${user?.lastName}`

  // Services
  const [addSurgeryAssetScans, addSurgeryAssetScanMutation] =
    useAddSurgeryAssetScans(surgeryId)

  // Handlers
  const handleMediaFilesChange = (
    media: MediaFile[],
    method: 'add' | 'delete'
  ) => {
    setMediaFiles((prev) => {
      if (method === 'add') {
        return [...prev, ...media]
      } else if (method === 'delete') {
        return prev.filter((item) =>
          media.some((newItem) => newItem.filename === item.filename)
        )
      }
      return prev
    })
  }

  const resetQuery = () => {
    setCurrentScan(undefined)
    scanDetails.setSelectedResult(undefined)
    setSelectedResult && setSelectedResult(undefined)
  }

  const resetQueryAndMutation = () => {
    resetQuery()
    addSurgeryAssetScanMutation.reset()
  }

  const handleAddToAssetList = async () => {
    const assetCount =
      assetFormData.assetType === 'consumable'
        ? 1
        : selectedResult?.deviceCount ?? 1

    const singleDisposition = assetFormData.dispositions[0]

    const dispositions = Array.from({ length: assetCount })
      .map((_, i) => {
        const disposition = assetFormData.dispositions[i]
        const finalDisposition =
          assetFormData.assetType !== 'consumable'
            ? disposition.finalDisposition
            : null
        const implantSite =
          finalDisposition !== 'IMPLANTED' && finalDisposition !== 'EXPLANTED'
            ? null
            : disposition.implantSite
        const wastedReason =
          finalDisposition !== 'WASTED' ? null : disposition.wastedReason
        const explantedReason =
          finalDisposition === 'EXPLANTED' ? disposition.explantedReason : null
        const explantedReasonNote =
          finalDisposition === 'EXPLANTED'
            ? disposition.explantedReasonNote
            : null
        const temperatureOk = disposition.temperatureOk
        const packagingOk = disposition.packagingOk
        const samplePreparation = disposition.samplePreparation
        const samplePreparationNote = disposition.samplePreparationNote || null
        const implantMemo = disposition.implantMemo || null
        const siteLocation = disposition.siteLocation || null

        return {
          implantSite,
          wastedReason: wastedReason?.toUpperCase() ?? null,
          implantStatus: finalDisposition,
          temperatureOk,
          packagingOk,
          samplePreparation,
          samplePreparationNote,
          implantMemo,
          siteLocation,
          explantedReason,
          explantedReasonNote,
        }
      })
      .filter((disposition) => disposition.implantStatus !== 'UNUSED')

    const consumableCount = parseInt(
      assetFormData.assetType === 'consumable' &&
        singleDisposition.consumableCount
        ? singleDisposition.consumableCount
        : '1',
      10
    )

    const { company } = selectedResult as BetterIdResult
    const { bidCompanyId, companyName } = selectedResult as ManualInputFormData

    let companyId = company?.id || bidCompanyId
    let dynamicCompanyName = company?.name || companyName
    const { isManualAddition } = selectedResult as NewAsset
    const scansInput = {
      userName,
      count: consumableCount,
      surgeryId,
      bidCompanyId: companyId || null,
      companyName: dynamicCompanyName || null,
      deviceId: selectedResult?.deviceId || null,
      deviceCount: selectedResult?.deviceCount ?? 1,
      assetTray: assetFormData.assetTray || null,
      assetType: assetFormData.assetType || null,
      lotBatch: selectedResult?.lotBatch || null,
      serialNumber: selectedResult?.serialNumber || null,
      udi: selectedResult?.udi || null,
      id: (selectedResult as BetterIdAsset)?.id || null,
      deviceDescription: selectedResult?.deviceDescription || null,
      catalogNumber: selectedResult?.catalogNumber || null,
      versionModelNumber: selectedResult?.versionModelNumber || null,
      sizeText: selectedResult?.sizeText || null,
      sizeString: (selectedResult as BetterIdAsset)?.sizeString || null,
      pkgQuantity: selectedResult?.pkgQuantity || null,
      secondaryDeviceId:
        (selectedResult as BetterIdAsset)?.secondaryDeviceId || null,
      secondaryDeviceIdType: selectedResult?.secondaryDeviceIdType || null,
      expirationDate: selectedResult?.expirationDate
        ? dayjs(selectedResult?.expirationDate).toISOString()
        : null,
      manufacturingDate: selectedResult?.manufacturingDate || null,
      isManualAddition: isManualAddition,
      dispositions,
      initialScanAssetGroupId: null,
      cost: (selectedResult as BetterIdAsset)?.cost || null,
      chargeable: (selectedResult as BetterIdAsset)?.chargeable || null,
      mediaFiles: mediaFiles.length > 0 ? mediaFiles : undefined,
    }

    const successfulAddAssetResult = await addSurgeryAssetScans({
      variables: { scans: scansInput },
    })

    if (successfulAddAssetResult.data?.addSurgeryAssetScans.scans[0]._id) {
      handleNavigateToAssetList()
    }
  }

  const handleNavigateToAssetList = () => {
    if (
      getAssetTypeLabel(getAssetTypeCaps(mainIconFlowState.assetTypeLabel)) ===
      'Implantable Biologic'
    ) {
      navigate(`/surgeries/${surgeryId}/list`)
    }
  }

  useEffect(() => {
    if (location.pathname.includes('/asset/scan')) {
      setIsScanHandlerActive(true)
    }
  }, [location])

  useEffect(() => {
    if (mainIconFlowState.multipackHardwareSelectedResult && !selectedResult) {
      setSelectedResult(mainIconFlowState.multipackHardwareSelectedResult)
      setIsComplete(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainIconFlowState.multipackHardwareSelectedResult, selectedResult])

  const entryProps = {
    addSurgeryAssetScanMutation,
    scanDetails,
    resetAssetFormData,
    resetQuery,
    resetQueryAndMutation,
    surgeryId: surgery._id,
    mainIconFlowState,
    isBatchMode,
    currentAssetType,
    assetFormData,
  }

  return (
    <Routes>
      <Route path="manual" element={<ManualAssetEntry {...entryProps} />} />
      <Route
        path="scan"
        element={
          <ProtectedUserRoute isPermitted={userCanScan}>
            <ScanAssetEntry {...entryProps} />
          </ProtectedUserRoute>
        }
      />
      <Route
        path="result"
        element={
          <Result
            onMediaFilesChange={handleMediaFilesChange}
            handleAddToAssetList={handleAddToAssetList}
            handleChangeAssetType={handleChangeAssetType}
            handleChangeTray={handleChangeTray}
            handleChangeSerialNumber={handleChangeSerialNumber}
            createDispositionsChangeHandler={createDispositionsChangeHandler}
            assetGroups={assetGroups}
            surgery={surgery}
            setUserCanScan={setUserCanScan}
            {...entryProps}
          />
        }
      />
      <Route
        path="/"
        element={
          userCanScan ? (
            <Navigate replace to={`${location.pathname}/scan`} />
          ) : (
            <Navigate replace to={`${location.pathname}/manual`} />
          )
        }
      />
    </Routes>
  )
}

export default AddAsset
