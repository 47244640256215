import { Button, Typography } from '@mui/material'
import './FilterButton.scss'
import { FilterIcon } from 'assets/icons/FilterIcon'

export interface FilterButtonProps {
  handleMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isDisabled: boolean
}

export const FilterButton = ({
  handleMenuClick,
  isDisabled,
}: FilterButtonProps) => {
  return (
    <Button
      data-testid="filter-button"
      onClick={handleMenuClick}
      disableElevation
      disabled={isDisabled}
      className="filter-button"
    >
      <FilterIcon active={!isDisabled} />
      <Typography variant="h3">Filters</Typography>
    </Button>
  )
}
