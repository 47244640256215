import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Surgery } from 'common/types'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import DeleteSubCategory from 'components/DeleteSubCategory/DeleteSubCategory'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import { useDeleteSurgeryAssetsByIds } from 'lib/apollo/hooks'
import {
  subCategoryConfig,
  groupAssetsBySubCategory,
  getCountFromScans,
} from 'lib/utils/data'
import styles from './deleteAssets.module.css'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider/Divider'
import List from '@mui/material/List/List'
import Typography from '@mui/material/Typography/Typography'

type Props = {
  surgery: Surgery
}

const compareSubCategoriesAlphabetically = (a: any[], b: any[]) =>
  a[0] > b[0] ? 1 : -1

function DeleteAssets({ surgery }: Props) {
  const [assetIdsToDelete, setAssetIdsToDelete] = useState<string[]>([])
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)

  const [deleteSurgeryAssetsByIds, deleteSurgeryAssetsByIdsMutation] =
    useDeleteSurgeryAssetsByIds({
      surgeryId: surgery._id,
      assetIds: assetIdsToDelete,
    })

  const assetScans = useMemo(
    () => surgery.assetGroups.map((assetGroup) => assetGroup.scans).flat(),
    [surgery.assetGroups]
  )

  const groupingProps = {
    procedureAssets: surgery.assetGroups,
  }
  const assetsGroupedBySubCategory = useMemo(
    () => groupAssetsBySubCategory(groupingProps),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    // Empty dependency to only take one snapshot of the current data when user lands on delete page
  )

  // Total count of assets to be deleted
  const deleteAssetCount = useMemo(() => {
    const scansToDelete = assetScans.filter((scan) =>
      assetIdsToDelete.includes(scan._id)
    )

    const count = getCountFromScans(scansToDelete)

    return count
  }, [assetIdsToDelete, assetScans])

  const isAssetsDeleted =
    deleteSurgeryAssetsByIdsMutation.data !== undefined &&
    deleteSurgeryAssetsByIdsMutation.error === undefined
  const assetNoun = assetIdsToDelete.length === 1 ? 'product' : 'product'

  const updateAssetIdsToDelete = (allAssetIdsInSubCategory: string[]) => {
    const existingAssetIds = [...assetIdsToDelete]

    allAssetIdsInSubCategory.forEach((assetId) => {
      if (existingAssetIds.includes(assetId)) {
        return existingAssetIds.splice(existingAssetIds.indexOf(assetId), 1)
      }
      existingAssetIds.push(assetId)
    })

    setAssetIdsToDelete(existingAssetIds)
  }

  const handleDeleteAssets = () => {
    deleteSurgeryAssetsByIds()
    setIsDeleteConfirmationOpen(false)
  }

  const handleCancelDelete = () => {
    deleteSurgeryAssetsByIdsMutation.reset()
    setIsDeleteConfirmationOpen(false)
  }

  return (
    <>
      <Helmet>
        <title>Delete</title>
      </Helmet>
      <Box className={styles.container}>
        <Box>
          {/* HEADER */}
          <PageDisplay>
            <Box>
              <Button
                sx={{ mb: 2, width: 'fit' }}
                startIcon={<ArrowBackIcon />}
                to="../list"
                component={Link}
              >
                Back
              </Button>
            </Box>
            <Typography variant="h1">Delete products</Typography>
            <Typography sx={{ fontWeight: 400 }}>
              Select the group(s) of products you want to delete
            </Typography>
          </PageDisplay>

          {/* SUB CATEGORY LIST */}
          <List sx={{ width: 1, py: 0 }}>
            <Divider light={isAssetsDeleted} />
            {subCategoryConfig.map((subCategoryOption) =>
              Object.entries(
                assetsGroupedBySubCategory[subCategoryOption.finalDisposition]
              )
                .sort(compareSubCategoriesAlphabetically)
                .map((assetsBySubCategory) => {
                  const labelId = `checkbox-list-label-${assetsBySubCategory[0]}`
                  return (
                    <DeleteSubCategory
                      key={assetsBySubCategory[0]}
                      assetScans={assetScans}
                      labelId={labelId}
                      assetsBySubCategory={assetsBySubCategory}
                      finalDisposition={subCategoryOption.finalDisposition}
                      updateAssetIdsToDelete={updateAssetIdsToDelete}
                      assetIdsToDelete={assetIdsToDelete}
                      isDisabled={
                        isAssetsDeleted ||
                        deleteSurgeryAssetsByIdsMutation.loading
                      }
                    />
                  )
                })
            )}
          </List>

          {/* CONFIRMATION OF ASSETS DELETED */}
          {isAssetsDeleted && (
            <Typography
              variant="h4"
              className={styles.deleteConfirmation}
              sx={{ my: 2 }}
            >
              <CheckIcon sx={{ mr: 1 }} />
              {`${assetIdsToDelete.length} ${assetNoun} deleted`}
            </Typography>
          )}
        </Box>

        {/* DELETE / RETURN BUTTON */}
        <Box
          sx={{
            display: 'flex',
            my: '1rem',
            width: 1,
          }}
        >
          <Box sx={{ width: 0.9, mx: 'auto' }}>
            {isAssetsDeleted ? (
              <Button
                to="../list"
                component={Link}
                variant="contained"
                fullWidth
                disableElevation
              >
                Return to product list
              </Button>
            ) : (
              <LoadingButton
                disabled={assetIdsToDelete.length === 0}
                mode="delete"
                onClick={() => setIsDeleteConfirmationOpen(true)}
                loading={deleteSurgeryAssetsByIdsMutation.loading}
                loadingText={`Deleting ${assetIdsToDelete.length} ${assetNoun}`}
              >{`Delete ${
                assetIdsToDelete.length >= 1 ? deleteAssetCount : ''
              } ${assetNoun}`}</LoadingButton>
            )}
          </Box>
        </Box>

        {/* DELETE CONFIRMATION */}
        <AlertDialog
          isOpen={isDeleteConfirmationOpen}
          title="Are you sure?"
          description="Deleting these products will permanently remove them from the product list. This action cannot be undone."
          positionAboveBottomNavigation
          primaryButtonAction={handleDeleteAssets}
          primaryButtonText="Delete"
          secondaryButtonAction={() => setIsDeleteConfirmationOpen(false)}
          isPrimaryDisabled={false}
          isPrimaryLoading={deleteSurgeryAssetsByIdsMutation.loading}
        />

        {/* MUTATION ERROR */}
        <AlertDialog
          isOpen={deleteSurgeryAssetsByIdsMutation.error !== undefined}
          description="An error was encountered while trying to delete the products."
          positionAboveBottomNavigation
          primaryButtonAction={handleDeleteAssets}
          secondaryButtonAction={handleCancelDelete}
          isPrimaryDisabled={false}
          isPrimaryLoading={deleteSurgeryAssetsByIdsMutation.loading}
        />
      </Box>
    </>
  )
}

export default DeleteAssets
