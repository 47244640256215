/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import Modal from 'components/molecules/Modal/Modal'
import './CaptureCamera.css'
import Button from 'components/molecules/Button/Button'
import { ICaptureCamera } from './CaptureCamera.types'
import { useIsTablet } from 'lib/utils/mediaQueries'

const CaptureCamera: FC<ICaptureCamera> = ({ open, onClose, onSave }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [image, setImage] = useState<string | null>(null)
  const isTablet = useIsTablet()

  const handleStopCapture = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop())
      setMediaStream(null)
    }
  }

  useEffect(() => {
    const handleStartCapture = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment',
          },
        })
        setMediaStream(stream)

        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      } catch (error) {
        console.error('Error accessing camera:', error)
      }
    }

    handleStartCapture()

    return () => {
      handleStopCapture()
    }
  }, [])

  const handleCaptureImage = () => {
    if (image) {
      onClose && onClose({}, 'escapeKeyDown')
      setImage(null)
      onSave(image)
    } else {
      if (videoRef.current) {
        const canvas = document.createElement('canvas')
        canvas.width = videoRef.current.videoWidth
        canvas.height = videoRef.current.videoHeight

        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
          const capturedImage = canvas.toDataURL('image/png')
          setImage(capturedImage)
        }
      }
    }
  }

  if (videoRef === null || mediaStream === null) {
    return (
      <div
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={`capture-container ${isTablet ? 'is-tablet' : ''}`}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {videoRef === null || mediaStream === null ? (
            <CircularProgress />
          ) : null}

          {image ? (
            <img src={image} alt="capture" style={{ width: '100%' }} />
          ) : (
            <video
              style={{ width: '100%', borderRadius: '5px' }}
              ref={videoRef}
              autoPlay
              muted
              playsInline
            />
          )}
        </Box>

        <Button fullWidth variant="contained" onClick={handleCaptureImage}>
          {image ? 'Save' : 'Capture'}
        </Button>
      </div>
    </Modal>
  )
}

export default CaptureCamera
