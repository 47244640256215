// Flags
export const FEATURE_FLAGS = {}

// General
export const POLL_INTERVAL = 15000 // milliseconds
export const POLL_INTERVAL_PROCEDURE = 3000 // milliseconds
export const LONGPRESS_DEFAULT_OPTIONS = {
  isPreventDefault: true,
  delay: 2000,
}
export const DOUBLE_VERIFY_KEY = 'NO-INDUSTRY-REP-AVAILABLE'

// Third Party
export const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  namespace: process.env.REACT_APP_AUTH0_NAMESPACE,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/home`,
  cacheLocation: 'localstorage' as any,
  useRefreshTokens: true,
  scope: 'openid profile email offline_access update:current_user_metadata',
  database: 'Username-Password-Authentication',
  organization: process.env.REACT_APP_AUTH0_ORG_ID_AT_S,
}
