import React, { useState } from 'react'

// Components
import Selector from 'components/molecules/Selector/Selector'

// Icons
import Temperature from 'assets/icons/svgs/Temperature.svg'
import Save from 'assets/icons/svgs/Save.svg'
import BiologicCalendar from 'assets/icons/svgs/BiologicCalendar.svg'
import { InspectionStepProps } from './steps.types'
import { NoteAdd } from '@mui/icons-material'

const InspectionStep: React.FC<InspectionStepProps> = ({
  setExpiryDate,
  setNote,
  setPackageIntegrity,
  setTemperature,
  expiryDate,
  note,
  packageIntegrity,
  temperature,
  hasExpiryDate,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [isTextBoxOpen, setIsTextBoxOpen] = useState(false)

  const handleOpenDatePicker = () => setIsDatePickerOpen(true)
  const handleCloseDatePicker = () => setIsDatePickerOpen(false)
  const handleCloseTextBox = () => setIsTextBoxOpen(false)
  const handleOpenTextBox = () => setIsTextBoxOpen(true)

  return (
    <div className="items">
      <Selector
        label="Package Integrity"
        icon={Save}
        onChange={(value) => setPackageIntegrity(value)}
        type="selector"
        selected={packageIntegrity}
      />
      <Selector
        label="Temperature"
        icon={Temperature}
        onChange={(value) => setTemperature(value)}
        type="selector"
        selected={temperature}
      />
      <Selector
        label="Comments"
        icon={
          <NoteAdd color="primary" sx={{ height: '40px', width: '40px' }} />
        }
        type="text-box"
        buttonLabel="Add Note"
        onChange={(value) => setNote(value)}
        selectedValue={note}
        onClose={handleCloseTextBox}
        handleOpen={handleOpenTextBox}
        isOpen={isTextBoxOpen}
      />
      <Selector
        label="Expiration"
        icon={BiologicCalendar}
        type="date-picker"
        buttonLabel="Add Date"
        selectedValue={expiryDate}
        onChange={(date) => setExpiryDate(date)}
        isOpen={isDatePickerOpen}
        handleOpen={handleOpenDatePicker}
        onClose={handleCloseDatePicker}
        disabled={hasExpiryDate}
      />
    </div>
  )
}

export default InspectionStep
