// MUI
import { ButtonGroup, Button, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

// Style
import './Counter.scss'

// Types
import { ICounter } from './Counter.types'
import useCounterLogic from './Counter.logic'

const Counter: React.FC<ICounter> = ({
  initialValue = 1,
  min = 1,
  max,
  step = 1,
  label,
  name,
  onChange,
}) => {
  const {
    count,
    isFocused,
    handleChange,
    handleIncrement,
    handleDecrement,
    handleFocus,
    handleBlur,
  } = useCounterLogic({
    initialValue,
    min,
    max,
    step,
    onChange,
  })

  return (
    <div className="counter-container">
      {label && (
        <Typography
          id="counter-label"
          component={'label'}
          htmlFor="counter-input"
          className={`counter-label ${isFocused ? 'active' : ''}`}
        >
          {label}
        </Typography>
      )}
      <ButtonGroup sx={{ width: '100%' }}>
        <Button
          id="decrement-button"
          className="button"
          onClick={handleDecrement}
          disabled={count === min}
        >
          <RemoveIcon fontSize="small" />
        </Button>
        <TextField
          name={name}
          inputMode="numeric"
          id="counter-input"
          fullWidth
          className="input"
          size="small"
          sx={{ padding: 0 }}
          onChange={handleChange}
          value={count}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <Button
          id="increment-button"
          className="button"
          onClick={handleIncrement}
          disabled={max !== undefined && count === max}
        >
          <AddIcon fontSize="medium" />
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default Counter
