import { Box, Stack, Typography } from '@mui/material'
import { QrCode } from '@mui/icons-material'

import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'

interface TrayProductProps extends BetterIDTrayScrew {
  showDispositionStatus?: boolean
}

const TrayProduct = ({
  deviceDescription: title,
  label,
  company,
  expirationDate,
  deviceId,
  row,
  column,
  wasted,
  showDispositionStatus = true,
}: TrayProductProps) => {
  const trayProductFields = [
    { title: '', value: company?.name as string },
    { title: 'Type', value: 'Screw' },
    { title: 'Device ID', value: deviceId as string },
  ]

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '15px 10px',
        border: '1px solid #D5D5D5',
        borderRadius: '5px',
        bgcolor: 'white',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '10%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <QrCode sx={{ color: '#5B5B5B', width: '25px', height: '25px' }} />
        </Box>

        <Box
          sx={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={500}
            color="#11142D"
            textAlign="left"
          >
            {title}
          </Typography>

          <Typography
            fontSize={16}
            fontWeight={300}
            color="#11142D"
            textAlign="left"
          >
            {expirationDate}
          </Typography>

          {trayProductFields.map(
            (field: { title: string; value: string }, _index) => (
              <Typography
                key={field.value}
                fontSize={field.title.length > 0 ? 14 : 15}
                fontWeight={field.title.length > 0 ? 400 : 300}
                color="#5B5B5B"
                textAlign="left"
              >
                {field.title.length > 0 ? `${field.title}: ` : ''} {field.value}
              </Typography>
            )
          )}
          <Typography
            fontSize={12}
            fontWeight={500}
            color="#5B5B5B"
            textAlign="left"
          >
            {`Location: ${label}, row: ${row}, size: ${column}`}
          </Typography>
        </Box>
      </Box>
      {showDispositionStatus && (
        <Stack
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ borderTop: '1px solid #D5D5D5', mt: '15px', pt: '10px' }}
        >
          <Typography
            fontSize={14}
            fontWeight={400}
            color="#000000"
            textAlign="left"
          >
            {wasted ? 'Wasted' : 'Implanted'}
          </Typography>
        </Stack>
      )}
    </Box>
  )
}

export default TrayProduct
