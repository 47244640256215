import { BetterIdCompany, Company } from 'common/types'
import { useCompanySearchQuery } from 'lib/apollo/hooks'

interface getBidCompanyIdProps {
  companyNameInput: Company['name']
}

export const useGetBidCompanyId = ({
  companyNameInput,
}: getBidCompanyIdProps) => {
  const { data } = useCompanySearchQuery(companyNameInput)

  const bidCompanyId: BetterIdCompany['id'] =
    data?.companySearch.find((company: BetterIdCompany) => {
      if (company.name === companyNameInput) {
        return company.id
      } else {
        return null
      }
    })?.id || null

  return bidCompanyId
}
