import { MutationResult } from '@apollo/client'
import { UseQueryResult } from 'react-query/types/react'
import { User as Auth0User } from '@auth0/auth0-react'
import { AssetType, EXPLANTED_REASONS } from './disposition'
import dayjs from 'lib/dayjs'
import { TRep } from 'components/ImplantableOtherRecordSender/Rep/Rep.types'
import { TRecordSenderAssetDetails } from 'components/ImplantableOtherRecordSender/ImplantableOtherRecordSender.types'

export type GenericObject = { [s: string]: any }

// CHECKME: maybe these will be Date's?
interface Timestamp {
  createdAt: string
  updatedAt: string
}

export enum AssetIdType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  UNIT_OF_USE = 'UNIT_OF_USE',
  PREVIOUS = 'PREVIOUS',
  DIRECT_MARKING = 'DIRECT_MARKING',
  PACKAGE = 'PACKAGE',
}

export enum IssuingAgency {
  GS1 = 'GS1',
  HIBCC = 'HIBCC',
  ICCBBA = 'ICCBBA',
  Unknown = 'Unknown',
}

export enum AssetGoodConditionCheck {
  YES = 'YES',
  NO = 'NO',
  NA = 'N/A',
}

export enum SamplePreparationType {
  NONE_REQUIRED = 'None Required',
  PER_MANUFACTURERS_GUIDELINES = 'Per Manufacturers Guidelines',
  OTHER = 'Other',
}

export interface BetterIdCompany {
  id: number | null
  name: string
  dunsNumber?: number | null
  fromAssetInstanceId?: number | null
  regEmail: string | null
}

export interface PaginatedCompanySearchResponse {
  companySearchPaginated: {
    companies: BetterIdCompany[]
    totalCount: number
  }
}

export interface BetterIdImplantSite {
  id: number
  name: string
}

export interface BetterIdAsset extends Timestamp {
  id: number
  catalogNumber?: string | null
  company: BetterIdCompany
  companyId: BetterIdCompany['id']
  deviceDescription?: string | null
  deviceCount: number
  deviceId?: string | null
  fromAssetInstanceId?: boolean | null
  issuingAgency?: IssuingAgency | null
  pkgQuantity?: number | null
  sizeString?: string | null
  sizeText?: string | null
  secondaryDeviceId?: string | null
  secondaryDeviceIdPkgQuantity?: string | null
  secondaryDeviceIdPkgType?: string | null
  secondaryDeviceIdType?: AssetIdType | null
  sourceId?: number | null
  versionModelNumber?: string | null
  gmdnPTDefinition?: string | null
  cost?: number | null
  chargeable?: boolean | null
}

export interface BetterIdProductionIdentifier {
  expirationDate: string | null
  lotBatch?: string | null
  manufacturingDate?: string | null
  serialNumber?: string | null
}

export interface BetterIdTerm {
  isUDI?: boolean | null
  value: string
  matches: Record<
    number,
    {
      field: string
      score: number
    }
  >
}

// TODO: Is there an error case to account for?
export interface BetterIdSearchResponse {
  assets: BetterIdAsset[]
  productionIdentifier: BetterIdProductionIdentifier
  term: BetterIdTerm
}

export interface BetterIdLookupQuery {
  betterIdLookup: BetterIdSearchResponse
}

export interface SearchBetterIdWithMetaDataProps {
  catalogNumber?: string | null
  companyId?: number | null
  deviceDescription?: string | null
  limit?: number | null
  cursor?: number | null
  versionModelNumber?: string | null
}

export interface SearchBetterIdWithMetaDataResponse {
  betterIdLookupByModelOrDD: {
    products: BetterIdAsset[]
    totalCount: number
  }
}

export interface PaginatedCompanySearchProps {
  limit: number
  name: string
  skip?: number
}

export type AssetTypeLabel =
  | 'Implantable Hardware/Associated Product'
  | 'Implantable Biologic'
  | 'Implantable Other'
  | 'Consumable'
  | 'Associated Product'
  | null

export interface BetterIdResult extends Timestamp {
  id: number
  udi?: string | null
  deviceId?: string | null
  catalogNumber?: string | null
  versionModelNumber?: string | null
  deviceDescription?: string | null
  deviceCount: number
  pkgQuantity?: number | null
  sizeString?: string | null
  sizeText?: string | null
  lotBatch?: string | null
  serialNumber?: string | null
  manufacturingDate?: string | null
  expirationDate: string | null
  issuingAgency?: string | null
  isUDI: boolean
  fromAssetInstanceId?: boolean | null
  sourceId?: number | null
  companyId?: BetterIdCompany['id'] | null
  secondaryDeviceId?: string | null
  secondaryDeviceIdPkgQuantity?: string | null
  secondaryDeviceIdPkgType?: string | null
  secondaryDeviceIdType?: AssetIdType | null
  company?: BetterIdCompany | null
  gmdnPTDefinition?: string | null
  cost?: number | null
  chargeable?: boolean | null
}

interface SurgeryAccess {
  id: string
  accessLevel: number
}

export interface NewAsset {
  deviceDescription?: string | null
  deviceId?: string | null
  deviceCount: number
  expirationDate: string | null
  lotBatch?: string | null
  versionModelNumber?: string | null
  serialNumber?: string | null
  catalogNumber?: string | null
  sizeText?: string | null
  pkgQuantity?: number | null
  manufacturingDate?: string | null
  secondaryDeviceIdType?: AssetIdType | null
  udi?: string | null
  company: {
    name: string
  }
  isManualAddition?: boolean
}

export interface AssetData {
  _id: string
  bidAssetId: number
  bidCompanyId?: number | null
  deviceDescription: string
  deviceId: string
  deviceCount: number
  assetTray?: string | null // also referred to as inventory location or active tray
  isManualAddition: boolean
  scans: Scan[]
  packsUsed: number
  total: number
  wasted: number
  implanted: number
  accurateDeviceCount: boolean
  deviceCountCorrectiveActionTaken: boolean
  actualDeviceCount?: number
  memo?: string
  totalCost?: number | null
}

export interface AssetGroupCount {
  _id: AssetData['_id']
  accurateDeviceCount?: AssetData['accurateDeviceCount']
  deviceCountCorrectiveActionTaken?: AssetData['deviceCountCorrectiveActionTaken']
  actualDeviceCount?: AssetData['actualDeviceCount']
  memo?: AssetData['memo']
}

export interface Facility {
  _id: string
  name: string
  assetTypes: OptionObjectPair[]
  implantSiteList: string[]
  useImplantSite: boolean
  hasWastedReasons: boolean
  wastedReasons: OptionObjectPair[]
}

export interface Company {
  name: string
  emailDomain?: string
  _id: string
  productRegistrationEmail: string | null
  authorizedProcedures?: Procedures[]
}

export type OptionObjectPair = {
  value: string | number
  label: string
}

export type ProcedureStatus = {
  name: 'SCANNING' | 'SUBMITTED'
  user: string
  dateTime: string
}

export type ProcedureInfo = {
  description?: string
  value?: string
}

export interface DerivedSurgeryStatus {
  name: 'SCANNING' | 'PRE_APPROVAL' | 'APPROVED' | 'SUBMITTED'
  workflow: 'rep' | 'no-rep'
  hasImplantableHardware: boolean
  hasImplantableBiologic: boolean
  hasImplantableOther: boolean
  scans: Scan[]
  implantableScans: Scan[] // only deals with hardwareImplantables, change name to: `implantableHardwareScans`?
  implantableScansByStatus: {
    SCANNED: Scan[]
    PRE_APPROVAL: Scan[]
    APPROVED: Scan[]
  }
  implantableScanIdsByStatus: {
    SCANNED: Scan['_id'][]
    PRE_APPROVAL: Scan['_id'][]
    APPROVED: Scan['_id'][]
  }
  totalCount: number
  totalHardwareCount: number // TODO: change name to: `totalImplantableHardwareCount` ?
  totalImplantableBiologicCount: number
  totalImplantableOtherCount: number
  totalConsumableCount: number
  totalExplantedCount: number
  totalAssociatedCount: number
  totalCost: number
  totalHardwareCost: number
  totalImplantableBiologicCost: number
  totalImplantableOtherCost: number
  totalConsumableCost: number
  totalExplantedCost: number
  totalAssociatedCost: number
}
export type Location = {
  type?: string
  department?: string
  room?: string
  bed?: string
}
export type SurgicalCase = {
  number?: string
  status?: string
}
export type SurgicalStaff = {
  firstName?: string
  lastName?: string
  credentials?: [string]
  roleDescription?: string
}

export type Visit = {
  visitNumber: string
  visitDateTime?: string
  attendingProvider?: Provider
  location?: Location
}

export type Procedures = {
  _id: string
  dateTime: string
  duration: number
  description?: string
  procedureInfo?: ProcedureInfo[]
}

export type ImplantReportStatus = {
  type: ImplantReportType
  isSent: boolean
  referringPhysicianLastName?: ReferringPhysician['lastName']
  referringPhysicianEmail?: ReferringPhysician['email']
  implantReport?: string
  createdAt: Date
}
export interface Surgery {
  isStarted: boolean | null
  _id: string
  patient: Patient
  location?: Location
  surgicalCase?: SurgicalCase
  surgeryStaff?: SurgicalStaff[]
  attendingSurgeon?: {
    provider: Provider
  }
  authorizedReps: SurgeryAccess[]
  authorizedCompanies: SurgeryAccess[]
  visit?: Visit
  assetTrays: string[]
  activeAssetTray?: string | null
  instrumentTrays: string[]
  status: ProcedureStatus
  statuses: ProcedureStatus[]
  assetGroups: AssetData[]
  procedures: Procedures[]
  isTestProcedure: boolean
  undocumentedScansCount: number
  totalSurgeryCost?: number
  implantReportStatus?: ImplantReportStatus[]
  implantRecordRegistration: {
    bidAssetId: BetterIdAsset['id']
    emails: RecordCompanyRep['email'][]
  }[]
  implantableOtherRepReconciliation: {
    assetDetails: TRecordSenderAssetDetails
    emails: RecordCompanyRep['email'][]
  }[]
}

export interface Provider {
  _id: string
  idNPI: string
  firstName: string
  lastName: string
  emailAddresses: string[]
  credentials: string[]
}

export interface Patient {
  _id: string
  idMR: string
  firstName: string
  middleName?: string
  lastName: string
  dateOfBirth: string
  providers: Provider[]
  surgeries: Surgery[]
}

export type AssetQuery = UseQueryResult<GenericObject | null, unknown>

export interface AssetDisposition {
  finalDisposition?: string
  implantSite?: string
  wastedReason?: string
  consumableCount?: string
  temperatureOk?: AssetGoodConditionCheck
  packagingOk?: AssetGoodConditionCheck
  samplePreparation?: SamplePreparationType
  samplePreparationNote?: string
  implantMemo?: string
  siteLocation?: string
  explantedReason?: ExplantedReason
  explantedReasonNote?: string
}

export type ExplantedReason = (typeof EXPLANTED_REASONS)[number]

export interface AssetFormData {
  assetType?: AssetType | null
  dispositions: Record<number, AssetDisposition>
  assetTray?: string
  serialNumber?: string
}

export interface RepApprovalFormData {
  repName: string
  approvedAt: string
}

export interface ManualInputFormData {
  assetType?: AssetType
  bidAssetId?: AssetData['bidAssetId'] | null
  deviceId?: AssetData['deviceId'] | null
  deviceCount: AssetData['deviceCount']
  lotBatch?: string | null
  serialNumber?: Scan['serialNumber'] | null
  expirationDate: Scan['expirationDate'] | null
  manufacturingDate?: Scan['manufacturingDate'] | null
  deviceDescription?: Scan['deviceDescription'] | null
  companyName: Scan['companyName']
  versionModelNumber?: Scan['versionModelNumber'] | null
  catalogNumber?: Scan['catalogNumber'] | null
  udi?: string | null
  bidCompanyId?: BetterIdCompany['id'] | null
  chargeable: Scan['chargeable'] | null
  companyId: Scan['bidCompanyId']
  cost: Scan['cost']
  id: number
  sizeString: Scan['sizeString']
  sizeText: Scan['sizeText']
  secondaryDeviceIdType: Scan['secondaryDeviceIdType']
  issuingAgency: UndocumentedScan['issuingAgency'] | null
  secondaryDeviceId: UndocumentedScan['secondaryDeviceId'] | null
  gmdnPTDefinition: UndocumentedScan['gmdnPTDefinition'] | null
  pkgQuantity: UndocumentedScan['pkgQuantity'] | null
  secondaryDeviceIdPkgQuantity:
    | BetterIdAsset['secondaryDeviceIdPkgQuantity']
    | null
  secondaryDeviceIdPkgType: BetterIdAsset['secondaryDeviceIdPkgType'] | null
  sourceId: BetterIdAsset['sourceId'] | null
  fromAssetInstanceId: BetterIdAsset['fromAssetInstanceId'] | null
  isManualAddition?: boolean
  count: Scan['count']
  hasLotSerial: boolean
}

export interface FormValidationProps {
  formData: GenericObject
  schema: any
}

export type AddProcedureAssetScanMutation = MutationResult
export type DeleteProcedureAssetGroupsMutation = MutationResult

export type AssetsGroupedByCategory = {
  plates: AssetData[]
  mesh: AssetData[]
  screws: AssetData[]
  other: AssetData[]
  consumables?: AssetData[]
}

export interface AssetsGroupedBySubCategory {
  [s: string]: GenericObject
}

export interface AssetsGroupedByAssetType {
  [s: string]: AssetData[]
}

export interface ScansGroupedBySubCategory {
  [s: string]: {
    [s: string]: {
      number: number
      expDate: Scan['expirationDate']
    }
  }
}

export type ImplantStatus =
  | 'IMPLANTED'
  | 'WASTED'
  | 'ASSOCIATED_ASSET'
  | 'EXPLANTED'

interface DTMScrewData {
  column: number
  row: number
  size: number
  label: string
  wasted: boolean
  trayId: string
}

export interface Scan {
  _id: string
  bidCompanyId?: BetterIdCompany['id'] | null
  assetType: AssetType
  category: string | null
  catalogNumber?: string | null
  udi?: string | null
  deviceId?: string | null
  deviceDescription: string | null
  deviceCount: number
  secondaryDeviceIdType?: AssetIdType | null
  expirationDate: string | null
  implantSite: string | null
  implantStatus: ImplantStatus
  implantTime: string
  companyName: string
  manufacturingDate: string | null
  versionModelNumber: string
  lotBatch: string | null
  serialNumber: string | null
  sizeText?: string | null
  sizeString?: string | null
  wastedReason: string | null
  count: number
  status: AssetStatus
  bidAssetId: number
  bidAssetInstanceId: number
  temperatureOk: AssetGoodConditionCheck
  packagingOk: AssetGoodConditionCheck
  samplePreparation: SamplePreparationType
  samplePreparationNote?: string | null
  implantMemo?: string | null
  media?: string[]
  cost?: number | null
  chargeable?: boolean | null
  createdAt?: string
  siteLocation?: string
  isManualAddition?: boolean
  explantedReason?: ExplantedReason
  explantedReasonNote?: string
  isDTMScrew?: boolean
  dtmScrewData?: DTMScrewData
}

export type AssetStatus = {
  name: 'SCANNED' | 'PRE_APPROVAL' | 'APPROVED'
  bidCompanyId: number | null
  userName: string
  userId: string
  signature: {
    signature: string
    date: string
  } | null
  sendToCompany: string | null
}

export type UserMetadata = {
  mobile_number?: string
}

export type AppMetadata = {
  bidCompanyId?: number
  firstName?: string
  lastName?: string
  invitePending?: boolean
  inviteCode?: string
  mobile?: string
  companyName?: string
}

export type Role =
  | 'MGAT_NURSE'
  | 'MGAT_REP'
  | 'MGAT_NURSEADMIN'
  | 'MGAT_INTERNAL'

export interface AuthUser extends Auth0User {
  // These optional properties are required, see defaults here: https://github.com/auth0/auth0-spa-js/blob/master/src/global.ts
  email: string
  sub: string

  // These properties are added within Auth.tsx by parsing custom claims
  user_metadata: UserMetadata
  app_metadata: AppMetadata
  roles: Role[]
}

export interface User {
  id: string
  firstName: string
  lastName: string
  bidCompanyId: number
  companyName: string
  email: string
  emailVerified: boolean
  mobileNumber: string
  name: string
  nickname: string
  orgId: string
  picture: string
  roles: string[]
  invitePending: boolean
  inviteCode: string
  updatedAt: string
}

export interface UserInput {
  firstName: string
  lastName: string
  email: string
  mobileNumber?: string
  companyName: String
}

export interface SalesRep {
  _id: string
  auth0id: string
  firstName: string
  lastName: string
  email: string
  mobileNumber: string
  companyName: string // will eventually retire this
  bidCompanyId?: BetterIdCompany['id']
  kind: string
}

export type LocationState = {
  isNewRep?: boolean
  from: {
    pathname: string
  }
}

export type Workflow = 'rep' | 'no-rep'

export type AlertTypes =
  | 'error'
  | 'errorInfo'
  | 'success'
  | 'info'
  | 'warning'
  | 'primary'

export enum FormValidationAlertType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CONFIRM = 'CONFIRM',
  INFO = 'INFO',
}

// keys will be alert / error pathnames and values will be the alert type
export enum FormValidationAlertTypeMapping {
  expirationDate = 'WARNING',
  numberOfExpired = 'WARNING',
  packagingOk = 'WARNING',
  temperatureOk = 'WARNING',
  rfMultipackConsumable = 'ERROR',
  submitToEmrConfirm = 'CONFIRM',
  numberOfExpiredHardware = 'WARNING',
  remainingUndocumentedScans = 'ERROR',
}

export interface PageInfo {
  totalCount?: number
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  count: number
}

export interface PagedQuery<T> {
  results: T[]
  pageInfo: PageInfo
}

export type AccessLogPage = PagedQuery<AccessLog>

export interface AccessLog {
  _id: string
  user: User
  operation: string
  inputData: string
  outputData: string
  createdAt: string
  updatedAt: string
}

export interface AccessLogFilterInput {
  user?: string
  before?: string
  after?: string
}

export enum LocalMediaType {
  'image/png' = 'image/png',
  'image/jpg' = 'image/jpg',
  'image/jpeg' = 'image/jpeg',
}

export interface Media {
  filename?: string
  size?: number
  type?: string
  url?: string
  srcBase64Str?: string
}

export interface UndocumentedScan {
  _id: string
  bidAssetId?: number
  bidAssetInstanceId?: number
  bidCompanyId?: BetterIdCompany['id'] | null
  catalogNumber?: string
  deviceDescription?: string
  deviceId?: string
  count: number
  deviceCount: number
  pkgQuantity?: number
  expirationDate: string | null
  lotBatch?: string
  companyName: string
  manufacturingDate?: string
  versionModelNumber?: string
  serialNumber?: string
  sizeText?: string
  sizeString?: string
  udi?: string
  issuingAgency?: IssuingAgency
  secondaryDeviceId?: string
  secondaryDeviceIdType?: AssetIdType
  assetType: AssetType
  gmdnPTDefinition?: string | null
  cost?: number | null
  chargeable?: boolean | null
  // isDocumented: boolean
  // user: UserProfile
  createdAt: Date
  updatedAt: Date
  isManualAddition?: boolean
  media?: string[]
  isDTMScrew?: boolean
  dtmScrewData?: DTMScrewData
}

export interface BatchAddAssetsToSurgeryResponse {
  data?: {
    batchAddAssetsToSurgery?: {
      addedScanIds?: string[]
    }
  }
}

export type BatchScanAssetsInput = Omit<
  UndocumentedScan,
  '_id' | 'user' | 'createdAt' | 'updatedAt' | 'isAssociatedProduct'
> & {
  scanId: string
  count: number
}

export interface ScanDisplayData {
  deviceDescription?: string | null
  deviceId?: string | null
  serialNumber?: string | null
  companyName?: string | null
  issuingAgency?: IssuingAgency | string | null
  udi?: string | null
}

export interface ScanState {
  isSelected?: boolean
  count?: number
  isExpireAccepted?: boolean
  isAssociatedProduct?: boolean
}

export type SelectedScan = UndocumentedScan & ScanState

// TODO: create enums for all of these sub-types
export type MainIconFlowState = {
  assetTypeLabel?:
    | 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT'
    | 'IMPLANTABLE'
    | 'IMPLANTABLE BIOLOGIC'
    | 'TRAYS'
    | 'CONSUMABLE PRODUCT'
    | 'IMPLANTABLE OTHER'
    | 'PREFERENCE CARD'
    | 'ADD SALES REP'
    | null
  trayType?: 'INSTRUMENT TRAY' | 'DIGITAL HARDWARE TRAY' | undefined
  isBatchModeEnabled?: boolean | undefined
  isMultipackHardware: boolean
  multipackHardwareSelectedResult?: BetterIdResult
}
export interface RecordCompanyRep {
  email: string
  cell?: string
  firstName: string
  lastName: string
}

export interface RecordCompanyResult {
  name: string
  bidCompanyId: number
  reps: RecordCompanyRep[]
}

// DTM Types
export interface AnalysisResultInput {
  size: number
  column: number
  row: number
  label: string
  wasted: boolean
}

export interface ItemResponse {
  id: number
  bidProductId?: number
  deviceId?: string
  catalogNumber?: string
  issuingAgency?: string
  companyName?: string
  companyId?: number
  productDetails?: string
  lotBatch?: string
  description?: string
  versionModelNumber?: string
  serialNumber?: string
  expirationDate?: Date | null
  manufacturingDate?: Date | null
  locationId?: number
  barcode?: string
  isTray: boolean
  isConsigned: boolean
  category: string
  quantity: number
  itemDetails?: any
  createdAt: Date
  updatedAt: Date
}

export interface TrayAnalysis {
  id: number
  tray_img: string
  analysis_results: AnalysisResultInput[]
  user_corrections: AnalysisResultInput[]
  processing_time: number
  accuracy_rate: number
  surgery_id: string
  user_id: string
  error: string
  createdAt: string
  updatedAt: string
}

export interface TrayAnalysisInput {
  tray_img: string
  processing_time: number
  accuracy_rate: number
  surgery_id: string
  user_id: string
  error: string
  analysis_results: AnalysisResultInput[]
  user_corrections: AnalysisResultInput[]
}

export interface CreateTrayAnalysisResponse {
  createTrayAnalysis: TrayAnalysis
}

export interface CreateTrayItemInput {
  catalogNumber?: string
  companyName: string
  companyId?: number
  locationId: number
  lotBatch?: string
  description: string
  versionModelNumber?: string
  serialNumber?: string
  expirationDate?: Date
  manufacturingDate?: Date
  barcode: string
  isConsigned: boolean
  productDetails?: string
  quantity: number
}

export interface MutationError {
  message: string
}

export interface UpdateUserCorrectionsMutationVariables {
  trayId: number
  userCorrections: AnalysisResultInput[]
}

export type Option = {
  name: string
  id: number | string
}

export enum ImplantReportType {
  EXTRACTION = 'EXTRACTION',
  FIRST_STAGE = 'FIRST_STAGE',
  SECOND_STAGE = 'SECOND_STAGE',
  SINGLE = 'SINGLE',
  FINAL = 'FINAL',
  OTHER = 'OTHER',
}

export type ImplantReportInputs = {
  assetIds: Scan['_id'][]
  extractionValues?: (string | number)[]
  nextApptDate?: Date | dayjs.Dayjs
  nextApptInMonths?: number
  secondStageAbutmentType?: string
  additionalComments?: string
  restorativePhaseDate?: Date | dayjs.Dayjs
  otherAbutmentType?: string
}

export type ImplantReportEmailInput = {
  surgeryId: Surgery['_id']
  referringPhysicianLastName?: ReferringPhysician['lastName']
  referringPhysicianEmail?: ReferringPhysician['email']
  reportType: ImplantReportType | undefined
  reportInputs: ImplantReportInputs
  submitOverride: boolean
}

export type useGetInviteRepQrCodeQueryResponse = {
  getInviteRepQrCode: {
    success: boolean
    message: string
    data: { jwt: string }
  }
}

export type SendProductRepInviteVariables = {
  email: Company['productRegistrationEmail']
  bidCompanyId: BetterIdCompany['id']
  surgeryId?: Surgery['_id']
  accessLevel: SurgeryAccess['accessLevel']
  firstName: User['firstName']
  lastName: User['lastName']
  mobile: User['mobileNumber']
  companyName: BetterIdCompany['name']
}

export type SendProductRepInviteResponse = {
  sendProductRepInvite: {
    success: boolean
    message: string
  }
}

export interface DeleteMediaFilesVariables {
  filenames: string[]
  scanId?: string
  assetId?: string
}

export interface DeleteMediaFilesResponse {
  deleteMediaFiles: {
    status: string
  }
}
export interface UploadInput {
  filename?: string
  mimetype?: string
  encoding: string
  content: string | null
}

export interface ResponseFile {
  filename: string
  base64Content: string
}
export interface UploadAssetMediaVariables {
  files: UploadInput[]
  assetId?: string
  scanId?: string
}

export interface UploadAssetMediaResponse {
  uploadAssetMedia: {
    message: string
    files: ResponseFile[]
  }
}

export interface GetMediaFilesResponse {
  getMediaFiles: {
    content: string
    filename: string
  }[]
}

export interface GetMediaFilesVariables {
  filenames: string[]
}

export interface AddSurgeryAssetScansResponse {
  addSurgeryAssetScans: {
    scans: {
      _id: Scan['_id']
    }[]
  }
}

export interface CreatePhysicianVariables {
  physician: ReferringPhysician
}

export interface CreatePhysicianResponse {
  createPhysician: ReferringPhysician
}

export interface EditPhysicianResponse {
  editPhysician: ReferringPhysician
}

export interface EditPhysicianVariables extends CreatePhysicianVariables {}

export type AddRepToSurgeryVariables = {
  surgeryId: Surgery['_id']
  repId: string
  accessLevel: number
}

export type AddRepToSurgeryResponse = {
  addRepToSurgery: {
    success: boolean
    message: string
  }
}

export type GetRepsQueryVariables = {
  bidCompanyId?: number
  firstName?: string
  lastName?: string
}

export type GetRepsQueryResponse = {
  getReps: {
    success: boolean
    message: string
    data: {
      id: string
      bidCompanyId: number
      accessLevel: number
      inviteCode: string
      firstName: string
      lastName: string
      mobile: string
      companyName: string
      invitePending: boolean
      email: string
    }[]
  }
}

export type SendAssetSmsEmailResponse = {
  sendAssetSmsEmail: {
    success: boolean
    message: string
  }
}

export type SendAssetSmsEmailVariables = {
  isSms: boolean
  surgeryId: Surgery['_id']
  rep: TRep
  assetDetails: TRecordSenderAssetDetails
}

export type ReferringPhysician = {
  _id?: string
  firstName?: string
  lastName?: string
  email?: string
  confirmEmail?: string
}

export interface BetterIdCreateProductEmailResponse {
  betterIdCreateProductEmail: {
    message: string
  }
}

export interface BetterIdCreateProductEmailVariables {
  productId: number
  email: RecordCompanyRep['email']
}
export interface BetterIdCreateProductEmailVariables {
  productId: number
  email: RecordCompanyRep['email']
}

export interface BetterIdFindProductsEmailsResponse {
  betterIdFindProductsEmails: ProductEmail[]
}

export interface ProductEmail {
  productId: number
  emails: ProductEmailDetails[]
}

export interface ProductEmailDetails {
  emailId: number
  productEmailId: number
  email: RecordCompanyRep['email']
}

export interface BetterIdUpdateProductEmailVariables {
  emailId: number
  email: RecordCompanyRep['email']
}

export interface BetterIdUpdateProductEmailResponse {
  betterIdUpdateProductEmail: {
    message: string
  }
}

export interface BetterIdDeleteProductEmailResponse {
  betterIdDeleteProductEmail: {
    message: string
  }
}
export interface SendProductRegistrationEmailsVariables {
  registrationInputs: SendProductRegistrationEmailsInputs[]
}

export interface SendProductRegistrationEmailsInputs {
  surgeryId: Surgery['_id']
  companyName?: string
  userFirstName?: string
  userLastName?: string
  scanId: Scan['_id']
  emailAddress?: RecordCompanyRep['email']
  bidAssetId?: AssetData['bidAssetId']
}

export interface SendProductRegistrationEmailsResponse {
  sendProductRegistrationEmails: {
    success: boolean
    __typename: string
  }
}

export interface CustomChangeEvent {
  target: {
    name: string
    value: string | Option
  }
}
