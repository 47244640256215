import { useSendAssetSmsEmailMutation } from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'
import { TSenderButtonProps, TSnackbarDataProps } from './SenderButton.types'
import { transformDate } from '../ImplantableOtherRecordSender.types'

export const useSenderButtonLogic = ({
  surgeryId,
  assetDetails,
  rep,
  isSms,
  emailsMobiles,
}: TSenderButtonProps) => {
  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false)
  const [isAlreadySentAlertOpen, setIsAlreadySentAlertOpen] = useState(false)

  const [snackbarData, setSnackbarData] = useState<TSnackbarDataProps>({
    message: '',
    severity: 'info',
    isOpen: false,
  })

  const { sendAssetSmsEmail, data, loading, error } =
    useSendAssetSmsEmailMutation(surgeryId)

  const handleSendToRep = () => {
    if (
      !isAlreadySentAlertOpen &&
      !isErrorDialogOpen &&
      ((!isSms && emailsMobiles.emails.includes(rep.email)) ||
        (isSms && emailsMobiles.mobiles.includes(rep.mobile)))
    ) {
      setIsAlreadySentAlertOpen(true)
      return
    }
    sendAssetSmsEmail({
      variables: {
        isSms,
        surgeryId,
        rep: {
          id: rep.id,
          firstName: rep.firstName,
          lastName: rep.lastName,
          email: rep.email,
          mobile: rep.mobile,
          bidCompanyId: rep.bidCompanyId,
          companyName: rep.companyName,
          inviteCode: rep.inviteCode,
          invitePending: rep.invitePending,
          accessLevel: rep.accessLevel,
        },
        assetDetails: {
          ...assetDetails,
          expirationDate: transformDate(assetDetails.expirationDate as string),
        },
      },
    })
  }

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, isOpen: false })
  }

  useEffect(() => {
    if (loading) {
      setSnackbarData({
        message: `Sending product to ${isSms ? rep.mobile : rep.email}`,
        severity: 'info',
        isOpen: true,
      })
    }
    if (error || (data && !data.success)) {
      setSnackbarData({
        message: `Sending product to ${isSms ? rep.mobile : rep.email}`,
        severity: 'info',
        isOpen: false,
      })
      setIsAlreadySentAlertOpen(false)
      setErrorDialogOpen(true)
    }
    if (data?.success) {
      setIsAlreadySentAlertOpen(false)
      setErrorDialogOpen(false)
      setSnackbarData({
        message: `Successfully sent product to ${
          isSms ? rep.mobile : rep.email
        }`,
        severity: 'success',
        isOpen: true,
      })
    }
  }, [data, data?.success, error, isSms, loading, rep.email, rep.mobile])

  return {
    isErrorDialogOpen,
    loading,
    snackbarData,
    isAlreadySentAlertOpen,
    setIsAlreadySentAlertOpen,
    setErrorDialogOpen,
    handleSendToRep,
    handleSnackbarClose,
  }
}
