import { FC, ChangeEvent } from 'react'
import { CircularProgress, InputAdornment, TextField } from '@mui/material'

interface ISearchInputProps {
  placeholder: string
  isLoading: boolean
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  icon?: JSX.Element
  sx?: any
}

const SearchInput: FC<ISearchInputProps> = ({
  placeholder,
  handleInputChange,
  isLoading,
  icon,
  sx,
}) => {
  return (
    <div>
      <TextField
        id="input-with-icon-textfield"
        autoComplete="off"
        placeholder={placeholder}
        fullWidth
        onChange={handleInputChange}
        InputProps={{
          startAdornment: icon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : null,
          endAdornment: isLoading && (
            <CircularProgress size={22} sx={{ marginRight: 1 }} />
          ),
        }}
        variant="outlined"
        sx={{
          ...sx,
        }}
      />
    </div>
  )
}

export default SearchInput
