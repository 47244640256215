import { Typography, Box } from '@mui/material'
import type { Company, RecordCompanyRep, Surgery } from 'common/types'
import { SendButton } from './RecordSendButton'
import { FC } from 'react'

type RepItemProps = {
  rep: RecordCompanyRep
  surgeryId: Surgery['_id']
  companyName: Company['name']
}

export const RepItem: FC<RepItemProps> = ({ rep, surgeryId, companyName }) => {
  return (
    <Box
      key={rep?.email}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:not(:first-of-type)': {
          borderTop: '1px solid #e0e0e0',
        },
      }}
      padding={2}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4">
          {rep.firstName} {rep.lastName}
        </Typography>
        <Typography variant="subtitle2">{rep.email}</Typography>
      </Box>
      <Box sx={{ display: 'flex', columnGap: 2, flexShrink: 0 }}>
        <Box>
          <SendButton
            aria-label="send record by email"
            variant="EMAIL"
            disabled={!rep.email}
            rep={rep}
            surgeryId={surgeryId}
            companyName={companyName}
          />
        </Box>
        <Box>
          <SendButton
            aria-label="send record by text message"
            variant="SMS"
            disabled={!rep.cell}
            rep={rep}
            surgeryId={surgeryId}
            companyName={companyName}
          />
        </Box>
      </Box>
    </Box>
  )
}
