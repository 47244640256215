import type { AssetData, Scan } from 'common/types'

export interface GroupedAssetData {
  explanted: AssetData[]
  hardware: {
    repScans: AssetData[]
    nurseScans: AssetData[]
    approved: AssetData[]
  }
  biologic: {
    repScans: AssetData[]
    nurseScans: AssetData[]
  }
  consumable: {
    repScans: AssetData[]
    nurseScans: AssetData[]
  }
  other: {
    repScans: AssetData[]
    nurseScans: AssetData[]
  }
}

export function getGroupedAssetData(
  assetGroups: AssetData[]
): GroupedAssetData {
  const assetGroupMap: Map<
    string,
    {
      assetGroup: AssetData
      scans: Set<Scan>
    }
  > = new Map()

  const addScan = ({
    assetGroup,
    scan,
    id,
  }: {
    assetGroup: AssetData
    scan: Scan
    id: string
  }) => {
    const existingGroup = assetGroupMap.get(id)
    if (existingGroup) {
      assetGroupMap.set(id, {
        assetGroup: {
          ...existingGroup.assetGroup,
        },
        scans: new Set([...Array.from(existingGroup.scans), scan]),
      })
      existingGroup.scans.add(scan)
    } else {
      assetGroupMap.set(id, {
        assetGroup: {
          ...assetGroup,
        },
        scans: new Set([scan]),
      })
    }
  }

  const groupedAssetData: GroupedAssetData = {
    explanted: [],
    hardware: {
      repScans: [],
      nurseScans: [],
      approved: [],
    },
    biologic: {
      repScans: [],
      nurseScans: [],
    },
    consumable: {
      repScans: [],
      nurseScans: [],
    },
    other: {
      repScans: [],
      nurseScans: [],
    },
  }

  for (const assetGroup of assetGroups) {
    for (const scan of assetGroup.scans) {
      if (scan.implantStatus === 'EXPLANTED') {
        addScan({
          id: `explanted-${assetGroup._id}`,
          assetGroup,
          scan,
        })
      } else if (scan.assetType === 'non-biological') {
        if (scan.status.name === 'APPROVED') {
          addScan({
            id: `hardware-approved-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        } else if (scan.status.bidCompanyId) {
          addScan({
            id: `hardware-rep-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        } else {
          addScan({
            id: `hardware-nurse-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        }
      } else if (scan.assetType === 'biological') {
        if (scan.status.bidCompanyId) {
          addScan({
            id: `biologic-rep-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        } else {
          addScan({
            id: `biologic-nurse-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        }
      } else if (scan.assetType === 'consumable') {
        // const { isExpired, expDate } = isDatePast({ date: scan.expirationDate })
        if (scan.status.bidCompanyId) {
          addScan({
            id: `consumable-rep-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        } else {
          addScan({
            id: `consumable-nurse-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        }
      } else {
        if (scan.status.bidCompanyId) {
          addScan({
            id: `other-rep-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        } else {
          addScan({
            id: `other-nurse-${assetGroup._id}`,
            assetGroup,
            scan,
          })
        }
      }
    }
  }

  assetGroupMap.forEach(({ assetGroup, scans }, id) => {
    if (id.startsWith('explanted')) {
      groupedAssetData.explanted.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('hardware-approved')) {
      groupedAssetData.hardware.approved.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('hardware-rep')) {
      groupedAssetData.hardware.repScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('hardware-nurse')) {
      groupedAssetData.hardware.nurseScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('biologic-rep')) {
      groupedAssetData.biologic.repScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('biologic-nurse')) {
      groupedAssetData.biologic.nurseScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('consumable-rep')) {
      groupedAssetData.consumable.repScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('consumable-nurse')) {
      groupedAssetData.consumable.nurseScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('other-rep')) {
      groupedAssetData.other.repScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    } else if (id.startsWith('other-nurse')) {
      groupedAssetData.other.nurseScans.push({
        ...assetGroup,
        scans: [...Array.from(scans)],
      })
    }
  })

  return groupedAssetData
}
