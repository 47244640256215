import {
  Cancel,
  CheckCircle,
  Close,
  Delete,
  GppMaybe,
  Handyman,
  ThumbDown,
} from '@mui/icons-material'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import Modal from 'components/molecules/Modal/Modal'
import { useState } from 'react'
import TableGuide from './TableGuide'
import ErrorBox from './ErrorBox/ErrorBox'

import { AnalysisResultModalProps } from './AnalysisResultModal.types'

const AnalysisResultModal = ({
  open,
  onClose,
  error,
  combinedResults,
}: AnalysisResultModalProps) => {
  const [showHelp, setShowHelp] = useState<boolean>(false)

  const handleGuideButtonClick = () => {
    setShowHelp((prev) => !prev)
  }

  return (
    <Modal open={open} onClose={onClose}>
      {error.length > 0 ? (
        <ErrorBox error={error} />
      ) : (
        <Box
          sx={{
            width: '95%',
            position: 'relative',
            maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'white',
            borderRadius: '20px',
            p: '10px 30px',
          }}
        >
          {showHelp && <TableGuide />}

          <Box
            sx={{
              borderRadius: '50%',
              bgcolor: '#5035ED',
              color: 'white',
              width: '30px',
              height: '30px',
              p: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={handleGuideButtonClick}
          >
            {showHelp ? <Close sx={{ width: '15px', height: '15px' }} /> : '?'}
          </Box>

          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table size="small" sx={{ p: '10px' }}>
              <TableHead>
                <TableRow>
                  {[
                    'Section',
                    'Size',
                    'Row',
                    'Column',
                    'Disposition',
                    'Status',
                  ].map((columnName: string) => (
                    <TableCell
                      key={columnName}
                      align="left"
                      sx={{ color: '#8594B9', fontSize: 14 }}
                    >
                      {columnName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {combinedResults.map((data, _index) => (
                  <TableRow
                    key={_index}
                    sx={{ cursor: 'pointer', backgroundColor: 'transparent' }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '150px',
                        color: '#142060',
                        fontSize: 14,
                        fontWeight: 500,
                        border: 'auto',
                      }}
                    >
                      {data.label}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '50px',
                        color: '#142060',
                        fontSize: 14,
                        fontWeight: 500,
                        border: 'auto',
                      }}
                    >
                      {data.size}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '50px',
                        color: '#142060',
                        fontSize: 14,
                        fontWeight: 500,
                        border: 'auto',
                      }}
                    >
                      {data.row}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '50px',
                        color: '#142060',
                        fontSize: 14,
                        fontWeight: 500,
                        border: 'auto',
                      }}
                    >
                      {data.column}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        minWidth: '50px',
                        color: '#142060',
                        fontSize: 14,
                        fontWeight: 500,
                        border: 'auto',
                      }}
                    >
                      {data.wasted ? (
                        <Delete
                          sx={{
                            width: '13px',
                            height: '13px',
                            mr: 1,
                          }}
                        />
                      ) : (
                        <Handyman
                          sx={{
                            width: '13px',
                            height: '13px',
                            mr: 1,
                          }}
                        />
                      )}
                      {data.wasted ? 'Wasted' : 'Implanted'}
                    </TableCell>

                    <TableCell align="right" sx={{ border: 'auto' }}>
                      <Tooltip
                        title={
                          data.status === 'correct'
                            ? 'Analysis successfully identified screw'
                            : data.status === 'incorrect'
                            ? 'Analysis added a wrong screw'
                            : data.status === 'missing'
                            ? 'Analysis failed to identify screw'
                            : 'User has not verified analysis'
                        }
                      >
                        <Typography
                          fontSize={12}
                          fontWeight={700}
                          sx={{
                            borderRadius: '15px',
                            p: '3px 1px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '83px',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            bgcolor:
                              data.status === 'correct'
                                ? '#E4FFF9'
                                : data.status === 'incorrect'
                                ? '#FFF7AB'
                                : data.status === 'missing'
                                ? '#FFE8E8'
                                : '#FFE9D5',
                            color:
                              data.status === 'correct'
                                ? '#07B88D'
                                : data.status === 'incorrect'
                                ? '#AA8100'
                                : data.status === 'missing'
                                ? '#FF0000'
                                : '#F57200',
                            borderColor:
                              data.status === 'correct'
                                ? '#07B88D'
                                : data.status === 'incorrect'
                                ? '#AA8100'
                                : data.status === 'missing'
                                ? '#FF0000'
                                : '#F57200',
                          }}
                        >
                          {data.status === 'correct' ? (
                            <CheckCircle
                              sx={{
                                width: '13px',
                                height: '13px',
                                mr: 1,
                              }}
                            />
                          ) : data.status === 'incorrect' ? (
                            <ThumbDown
                              sx={{
                                width: '13px',
                                height: '13px',
                                mr: 1,
                              }}
                            />
                          ) : data.status === 'missing' ? (
                            <Cancel
                              sx={{
                                width: '13px',
                                height: '13px',
                                mr: 1,
                              }}
                            />
                          ) : (
                            <GppMaybe
                              sx={{
                                width: '13px',
                                height: '13px',
                                mr: 1,
                              }}
                            />
                          )}{' '}
                          {data.status}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Modal>
  )
}

export default AnalysisResultModal
