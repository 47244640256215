import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material'
import { Patient } from 'common/types'

export interface PatientDetailsCardProps {
  patientMRN: Patient['idMR']
  patientName: string
  patientDOB: Patient['dateOfBirth']
  loading?: boolean
}

export const PatientDetailsCard = ({
  patientMRN,
  patientName,
  patientDOB,
  loading,
}: PatientDetailsCardProps) => {
  return (
    <Grid
      data-testid="patient-details-grid"
      item
      xs={3}
      md={3}
      sx={{
        backgroundColor: 'grayscale.light',
        borderRadius: '7px',
        p: 1,
      }}
    >
      <Typography variant="h3" sx={{ m: 1 }}>
        Patient Details
      </Typography>
      <Divider />

      <Grid item sx={{ m: 1 }}>
        <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
          <Typography variant="h5" sx={{ mr: 1 }}>
            Patient MRN:
          </Typography>
          {loading ? (
            <Skeleton width="100%" />
          ) : (
            <Typography variant="h6">#{patientMRN}</Typography>
          )}
        </Box>

        <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
          <Typography variant="h5" sx={{ mr: 1 }}>
            Patient Name:
          </Typography>
          {loading ? (
            <Skeleton width="100%" />
          ) : (
            <Typography variant="h6">{patientName}</Typography>
          )}
        </Box>

        <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
          <Typography variant="h5" sx={{ mr: 1 }}>
            Date of Birth:
          </Typography>
          {loading ? (
            <Skeleton width="100%" />
          ) : (
            <Typography variant="h6">{patientDOB}</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
