import { useEffect, useState } from 'react'

// Router
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

// Services
import { useGetProcedures } from 'lib/services/api/demo-reports-service/preference-card/getProcedures'
import { useGetPreferenceCards } from 'lib/services/api/demo-reports-service/preference-card/getPreferenceCards'
import { useDeletePrefCard } from 'lib/services/api/demo-reports-service/preference-card/deletePreferenceCard'

// Components
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import Button from 'components/molecules/Button/Button'

// MUI
import { Add, Delete, ReadMore } from '@mui/icons-material'
import { TableRowProps, Typography } from '@mui/material'

// Other
import { debounce } from 'lodash'
import dayjs from 'lib/dayjs'
import { useGetSurgeons } from 'lib/services/api/demo-reports-service/preference-card/getSurgeons'
import { useGetProcedureTypes } from 'lib/services/api/demo-reports-service/preference-card/getProcedureTypes'
import { IDeletePrefCard } from './PreferenceCards.types'

const prefCardHeadCells: HeadCell[] = [
  {
    id: 'surgeon',
    label: 'Surgeon name',
    numeric: true,
    sx: {
      minWidth: '10%',
      width: '20%',
    },
  },
  {
    id: 'procedure',
    label: 'Procedure type',
    numeric: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
  },
]

const proceduresHeadCells: HeadCell[] = [
  {
    id: 'date',
    label: 'Date',
    numeric: true,
    sx: {
      minWidth: '10%',
      width: '20%',
    },
  },
  {
    id: 'surgeon',
    label: 'Surgeon name',
    numeric: true,
  },
  {
    id: 'procedure',
    label: 'Procedure type',
    numeric: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
  },
]

const useLogic = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const initialBefore = dayjs().endOf('day').toDate()
  const initialAfter = dayjs().startOf('day').toDate()

  // States
  const [searchSurgeon, setSearchSurgeon] = useState<string>()
  const [searchProcedure, setSearchProcedure] = useState<string>()
  const [beforeDate, setBeforeDate] = useState(initialBefore)
  const [afterDate, setAfterDate] = useState(initialAfter)
  const [isAlertOpen, setIsAlertOpen] = useState<IDeletePrefCard>({
    isOpen: false,
  })
  const [searchParams, setSearchParams] = useState<{
    surgeonName?: string
    procedureType?: string
  }>()

  // Services
  const {
    data: prefCards,
    isLoading: isLoadingPrefCards,
    isError: isErrorPrefCard,
  } = useGetPreferenceCards({
    surgeonName: searchParams?.surgeonName,
    procedureType: searchParams?.procedureType,
  })
  const { mutateAsync: deletePrefCard, isLoading: isDeleting } =
    useDeletePrefCard()
  const {
    data: procedures,
    isLoading: isLoadingProcedures,
    isError,
  } = useGetProcedures({
    startDate: dayjs(beforeDate).format('YYYY-MM-DD'),
    endDate: dayjs(afterDate).format('YYYY-MM-DD'),
    pageSize: 100,
    checkPreferenceCard: true,
  })
  const { data: surgeonsData, isLoading: isLoadingSurgeons } = useGetSurgeons()
  const surgeonsOptions = surgeonsData?.surgeons.map((item) => ({
    id: item.id,
    name: `${item.firstName} ${item.lastName}`,
  }))
  const { data: procedureTypesData, isLoading: isLoadingProcedureTypes } =
    useGetProcedureTypes()
  const procedureTypesOptions = procedureTypesData?.procedures.map(
    (item, index) => ({
      id: index,
      name: item,
    })
  )

  const preferenceCards = prefCards?.preference_cards.map((item) => ({
    surgeon: item
      ? `${item?.surgeon.firstName}, ${item?.surgeon.lastName}`
      : '',
    procedure: item.procedure_type || '',
    actions: (
      <div className="actions">
        <Button
          data-testid="delete-pref-card-button"
          // isLoading={isDeleting && deletedId === item.id}
          onClick={() => setIsAlertOpen({ isOpen: true, id: item.id })}
          endIcon={<Delete color="error" />}
        >
          <Typography color="error" variant="h4">
            Delete
          </Typography>
        </Button>
        <Button
          data-testid="view-pref-card-button"
          onClick={() => handleCardClick(item.id)}
          endIcon={<ReadMore />}
        >
          <Typography variant="h4">View</Typography>
        </Button>
      </div>
    ),
  })) as TableRowProps[]

  const proceduresData = procedures?.surgeries.map((item) => ({
    date: dayjs(item?.procedures[0]?.dateTime).format('MMM DD YYYY'),
    surgeon: `${item?.surgeonLastName}, ${item?.surgeonFirstName}`,
    procedure: item.procedures[0]?.description || '',
    actions: item.preference_card_id ? (
      <div className="actions">
        <Button
          data-testid="delete-pref-card-button"
          onClick={() =>
            setIsAlertOpen({ isOpen: true, id: item?.preference_card_id })
          }
          endIcon={<Delete color="error" />}
        >
          <Typography color="error" variant="h4">
            Delete
          </Typography>
        </Button>
        <Button
          data-testid="view-pref-card-button"
          onClick={() => handleCardClick(item?.preference_card_id)}
          endIcon={<ReadMore />}
        >
          <Typography variant="h4">View</Typography>
        </Button>
      </div>
    ) : (
      <Button
        data-testid="create-pref-card-button"
        onClick={() =>
          handleCreatePrefCard(
            true,
            item.surgeonId,
            item.procedures[0].description
          )
        }
        endIcon={<Add />}
      >
        <Typography variant="h4">Create</Typography>
      </Button>
    ),
  })) as TableRowProps[]

  // Handlers
  const handleCardClick = (id: string) => {
    navigate(`/preference-card/${id}`)
  }

  const handleDelete = async () => {
    try {
      await deletePrefCard({ id: isAlertOpen.id as string })
      queryClient.invalidateQueries('preferenceCards')
      queryClient.invalidateQueries('surgeries')
      setIsAlertOpen({ isOpen: false })
    } catch (error) {
      console.error(error)
    }
  }

  const handleBackDay = () => {
    setBeforeDate((prev) => dayjs(prev).subtract(1, 'day').toDate())
    setAfterDate((prev) => dayjs(prev).subtract(1, 'day').toDate())
  }

  const handleForwardDay = () => {
    setBeforeDate((prev) => dayjs(prev).add(1, 'day').toDate())
    setAfterDate((prev) => dayjs(prev).add(1, 'day').toDate())
  }

  const handleCreatePrefCard = (
    hasCredentials: boolean,
    surgeonId?: string,
    procedureType?: string
  ) => {
    navigate(
      `/preference-cards/new`,
      hasCredentials ? { state: { surgeonId, procedureType } } : {}
    )
  }

  useEffect(() => {
    const debounced = debounce(() => {
      setSearchParams({
        surgeonName: searchSurgeon,
        procedureType: searchProcedure,
      })
    }, 500)
    debounced()
  }, [searchSurgeon, searchProcedure])

  return {
    isError,
    afterDate,
    isAlertOpen,
    searchSurgeon,
    proceduresData,
    isErrorPrefCard,
    searchProcedure,
    preferenceCards,
    prefCardHeadCells,
    isLoadingPrefCards,
    isLoadingProcedures,
    proceduresHeadCells,
    surgeonsOptions,
    isLoadingSurgeons,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    isDeleting,
    setIsAlertOpen,
    handleDelete,
    handleBackDay,
    setSearchSurgeon,
    handleForwardDay,
    setSearchProcedure,
    handleCreatePrefCard,
  }
}

export default useLogic
