import { useEffect, useState } from 'react'

// Types
import type { Surgery, Scan } from 'common/types'
import { Product } from './ProductRegistration.types'

// Services
import { useFindBetterIdProductsEmails } from 'lib/apollo/hooks'

const useProductRegistrationLogic = ({ surgery }: { surgery: Surgery }) => {
  const [products, setProducts] = useState<Product[]>([])
  const [hasProducts, setHasProducts] = useState(false)
  const productsIds = [...products.map((item) => item.scan.bidAssetId)]

  const {
    data: betterIdProductsEmails,
    loading: isLoadingProductsEmails,
    error,
  } = useFindBetterIdProductsEmails({
    productsIds,
  })
  const productEmails = betterIdProductsEmails?.betterIdFindProductsEmails

  useEffect(() => {
    const prods: Scan[] = surgery.assetGroups.flatMap((group) =>
      group.scans.filter(
        (scan) =>
          scan.assetType === 'biological' ||
          scan.assetType === 'other-non-biological'
      )
    )

    const uniqueScans: Scan[] = prods.reduce((acc: Scan[], current: Scan) => {
      const exists = acc.some((item) => item.bidAssetId === current.bidAssetId)
      if (!exists) {
        acc.push(current)
      }
      return acc
    }, [])

    const finalProducts: Product[] = uniqueScans.map((scan) => ({
      scan,
      productEmail: productEmails?.find(
        (email) => email.productId === scan.bidAssetId
      ),
      company: scan.companyName,
    }))

    setProducts(finalProducts)
    setHasProducts(finalProducts.length > 0)
  }, [surgery, productEmails])

  return {
    error,
    products,
    hasProducts,
    productsIds,
    isLoadingProductsEmails,
  }
}

export default useProductRegistrationLogic
