import { Button, CircularProgress, Skeleton, Typography } from '@mui/material'
import './AnalyzeTray.scss'
import { ArrowBack, CameraAlt, FlipCameraIos } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import useAnalyzeTrayLogic from './AnalyzeTray.logic'
import classNames from 'classnames'
import { LoadingButton } from 'components/mui'
import DialogDrawer from 'components/molecules/Drawer/DialogDrawer'
import TrayAnalysisContent from 'components/organisms/TrayAnalysisContent/TrayAnalysisContent'
import { useAddTrayContext } from '../AddTray.context'

const AnalyzeTray = () => {
  const {
    handleImageCapture,
    videoRef,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    isTrayMapDrawerOpen,
    handleTrayMapDrawerClose,
    isSubmitting,
    handleAnalysisClick,
  } = useAnalyzeTrayLogic()
  const { trayImage, selectedTrayType, isAnalysisComplete } =
    useAddTrayContext()

  return (
    <div className="analyze-tray-page_container">
      <div className="header_wrapper">
        <Button size="small" className="back-button" component={Link} to="..">
          <ArrowBack sx={{ mr: 0.5 }} /> BACK
        </Button>

        <Typography variant="h1">Capture & Analyze Tray</Typography>
      </div>

      <div className="main_content">
        <div className="image_container">
          {!trayImage ? (
            <Typography variant="body1">
              Capture and Analyze Your Tray
            </Typography>
          ) : (
            <div className="tray_img">
              {trayImage.isUploading && (
                <div className="loading_container">
                  <CircularProgress className="loader" />
                </div>
              )}

              <img src={trayImage.src} alt="Tray" />
            </div>
          )}

          {trayImage && (
            <LoadingButton
              dataTestId="add-tray-submit_button"
              loading={isSubmitting}
              disabled={!trayImage || isSubmitting || trayImage.isUploading}
              onClick={handleAnalysisClick}
              variant="outlined"
            >
              {isAnalysisComplete
                ? 'View Analysis'
                : 'Upload Implantable Tray Image for Analysis'}
            </LoadingButton>
          )}

          <DialogDrawer
            open={isTrayMapDrawerOpen}
            onClose={handleTrayMapDrawerClose}
            height="95%"
            overflowY
          >
            <TrayAnalysisContent
              trayType={selectedTrayType}
              onClose={handleTrayMapDrawerClose}
              trayImg={trayImage?.src}
            />
          </DialogDrawer>
        </div>

        <div className="capture_wrapper">
          <div className="camera_container">
            <Skeleton className="skeleton" />

            <div className="camera-select_container">
              <LoadingButton
                className="camera-menu_button"
                onClick={handleToggleCameraMenu}
              >
                <FlipCameraIos />
              </LoadingButton>

              <div
                className="camera-options"
                style={{
                  display: isCameraMenuOpen ? 'flex' : 'none',
                  opacity: isCameraMenuOpen ? 1 : 0,
                }}
              >
                {cameras.map((camera) => (
                  <div
                    key={camera.deviceId}
                    className={classNames('camera', {
                      active: camera.deviceId === activeCamera?.deviceId,
                    })}
                    onClick={() => handleSelectCamera(camera)}
                  >
                    <Typography variant="body2" className="camera-text">
                      {camera.label}
                    </Typography>

                    <Typography variant="body2" className="camera-text">
                      {camera?.cameraType}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>

            <LoadingButton
              disabled={trayImage?.isUploading}
              loading={trayImage?.isUploading}
              onClick={() => handleImageCapture(true)}
              className="capture-photo_button"
            >
              {!trayImage?.isUploading && <CameraAlt />}
            </LoadingButton>

            <video className="feed" ref={videoRef} autoPlay muted playsInline />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnalyzeTray
