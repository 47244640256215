import { ReceiveItemsResponse, ReceiveItemsVariables } from './types'
import { RECEIVE_ITEMS } from 'lib/services/api/schema'
import { MutationTuple, useMutation } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'

type UseAttachUDIsToProductResult = MutationTuple<
  ReceiveItemsResponse,
  ReceiveItemsVariables
>

const useReceiveItems = (): UseAttachUDIsToProductResult => {
  return useMutation<ReceiveItemsResponse, ReceiveItemsVariables>(
    RECEIVE_ITEMS,
    {
      onError: (error: Error) => {
        captureWarning(error)
      },
    }
  )
}

export default useReceiveItems
