import { z } from 'zod'
import { isDatePast } from 'lib/utils/isDatePast'
import { genFormValidationAlertMsg } from 'lib/utils/genFormValidationAlertMsg'

export const addToAssetListValidationSchema = (data: any): any => {
  const validateSchema = z
    .record(z.string(), z.string())
    .refine(
      // best for known key values
      () => isDatePast({ date: data.expirationDate }).isDateExpired === false,
      {
        message: genFormValidationAlertMsg({
          path: 'expirationDate',
          context: { expirationDate: data.expirationDate },
        }),
        path: ['expirationDate'],
      }
    )
    .superRefine((val, ctx) => {
      // best for dynamic key values i.e. (temperatureOk-1) ~ multipacks
      for (const [key, value] of Object.entries(val)) {
        if (key.includes('temperatureOk') && value === 'NO') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: genFormValidationAlertMsg({
              path: key,
              pathArray: Object.keys(val),
            }),
            path: [key],
          })
        }
        if (key.includes('packagingOk') && value === 'NO') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: genFormValidationAlertMsg({
              path: key,
              pathArray: Object.keys(val),
            }),
            path: [key],
          })
        }
      }
    })

  return validateSchema.safeParse(data)
}
