export const formatDate = (dateString: string): string => {
  const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const days: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const date: Date = new Date(dateString)
  const dayOfWeek: string = days[date.getDay()]
  const dayOfMonth: number = date.getDate()
  const month: string = months[date.getMonth()]
  const year: number = date.getFullYear()
  let hours: number = date.getHours()
  let minutes: number | string = date.getMinutes()
  const ampm: string = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12 // Handle midnight
  minutes = minutes < 10 ? '0' + minutes : minutes

  const formattedDate: string = `${dayOfWeek} ${dayOfMonth} ${month} ${year}, at ${hours}:${minutes} ${ampm}`

  return formattedDate
}
