import { useState } from 'react'

// Components
import { Option } from 'common/types'

// Services
import useGetCompanies from 'lib/services/api/product-service/getCompanies'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'

// Types
import {
  AssetType,
  Disposition,
} from 'views/ReceiveProducts/scan/process-modal/ProcessModal.types'

// Context
import { useInventoryReport } from '../InventoryReportProvider'
import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'
import { FiltersParams } from './InventoryFilters.types'

const useLogic = () => {
  const isTablet = useIsTablet()
  const { filters, setFilters } = useInventoryReport()
  const [consignment, setConsignment] = useState<Disposition | undefined>(
    filters.itemDisposition
  )
  const [type, setType] = useState<AssetType | undefined>(filters.type)
  const [selectedCompany, setSelectedCompany] = useState<Option | undefined>(
    filters.selectedCompany
  )
  const [selectedLocation, setSelectedLocation] = useState<Option | undefined>(
    filters.selectedLocation
  )
  const [productName, setProductName] = useState<string | undefined>(
    filters.productName
  )
  const [company, setCompany] = useState<string | undefined>(filters.company)

  const companySearch = company && company.length > 3 ? company : ''
  const { data: companiesData, loading: isLoadingCompanies } = useGetCompanies({
    searchByCompanyName: companySearch,
    enabled: companySearch.length > 3,
  })
  const { data, loading: isLoadingLocations } = useGetLocationsTree()

  const departments = (
    data?.getLocationsTree?.hospital?.locations || []
  ).filter((location) => location.parentLocationId === null)

  const locations = (data?.getLocationsTree?.hospital?.locations || [])
    .filter((location) => {
      const parent = data?.getLocationsTree.hospital.locations.find(
        (item) => item.id === location.parentLocationId
      )
      return parent?.parentLocationId === null
    })
    .map((location) => {
      const parent = departments?.find(
        (item) => item.id === location.parentLocationId
      )
      return {
        id: location.id,
        name: `${parent?.name} - ${location.name}`,
      }
    })

  const companies =
    companiesData?.searchCompanies.companies.map((item: any) => ({
      id: item.id,
      name: item.name,
    })) || []

  const handleSearchProductName = (value: string) => {
    setProductName(value)
  }

  const handleApply = (filters: FiltersParams) => {
    setFilters({
      open: false,
      type: filters?.type,
      productName: filters?.productName,
      itemDisposition: filters?.itemDisposition,
      selectedCompany: filters?.selectedCompany,
      company: filters?.company,
      selectedLocation: filters?.selectedLocation,
    })
  }

  return {
    selectedCompany,
    consignment,
    company,
    type,
    productName,
    isLoadingCompanies,
    companies,
    isTablet,
    locations,
    selectedLocation,
    isLoadingLocations,
    setSelectedLocation,
    setCompany,
    handleSearchProductName,
    setConsignment,
    setType,
    setSelectedCompany,
    handleApply,
  }
}

export default useLogic
