import { CustomModal } from 'components/molecules/Modal/Modal'
import dayjs from 'lib/dayjs'
import { IconButton } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ImplantReportStatus } from 'common/types'
import { useViewImplantReportModalLogic } from './ViewImplantReportModal.logic'
import '../ImplantReportSender.scss'
import { ViewImplantReportModalProps } from '../ImplantReportSender.types'

export const ViewImplantReportModal = ({
  implantReportStatus,
  formatEnumValue,
  viewReportsModalOpen,
  setViewReportsModalOpen,
}: ViewImplantReportModalProps) => {
  const {
    currentReport,
    handleNextReport,
    handlePreviousReport,
    currentReportIndex,
  } = useViewImplantReportModalLogic(implantReportStatus)

  return (
    <CustomModal
      open={viewReportsModalOpen}
      closeButtonOnly
      header="Sent Report(s)"
      isLoadingState={false}
      secondaryButtonAction={() => setViewReportsModalOpen(false)}
      subheader={`${
        currentReport.type && formatEnumValue(currentReport.type)
      } Report sent to Dr. ${currentReport.referringPhysicianLastName} - ${
        currentReport.referringPhysicianEmail
      } at ${dayjs(currentReport.createdAt).format('hh:mm A M-D-YY')}`}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          onClick={handlePreviousReport}
          disabled={
            (implantReportStatus as ImplantReportStatus[]).length <= 1 ||
            currentReportIndex === 0
          }
          color={'primary'}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={handleNextReport}
          disabled={
            (implantReportStatus as ImplantReportStatus[]).length <= 1 ||
            currentReportIndex ===
              (implantReportStatus as ImplantReportStatus[]).length - 1
          }
          color={'primary'}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
      <div
        className="view-reports-modal"
        dangerouslySetInnerHTML={{
          __html: currentReport.implantReport as string,
        }}
      />
    </CustomModal>
  )
}
