import { CustomIcon } from 'components/templates/CustomIcon'

type BarcodeIconProps = {
  active: boolean
  color?: string
}

export const BarcodeIcon = ({
  active,
  color = 'grayscale.dark',
}: BarcodeIconProps) => (
  <CustomIcon active={active} color={color} width="24" height="24">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z"
        fill="#111"
      />
    </svg>
  </CustomIcon>
)
