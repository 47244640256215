import { useMemo, useCallback } from 'react'
import ReactApexChart from 'react-apexcharts'
import { theme } from 'assets/styles/customTheme'
import { AssetsByMonth, SurgeryByMonthObject } from '../utils/getDashboardStats'
import { AssetsThisMonthChartProps } from './AssetsThisMonthChart'
import { useDashboardContext } from 'lib/context/DashboardContext'

interface UtilizedAssetsChartProps {
  assetsByMonth: AssetsByMonth
  surgeriesByMonth: SurgeryByMonthObject[]
}

export const UtilizedAssetsChart = ({
  assetsByMonth,
  surgeriesByMonth,
}: UtilizedAssetsChartProps) => {
  const { darkMode } = useDashboardContext()

  function calculateSurgeriesForQuarter(
    quarterData: SurgeryByMonthObject[]
  ): SurgeryByMonthObject[] {
    // parse quarters
    const quarters = Array.from({ length: 4 }, (_, i) =>
      quarterData.slice(i * 3, i * 3 + 3)
    )

    return quarters.map((quarter: SurgeryByMonthObject[], index: number) => {
      const surgeries = quarter.flatMap((data) => data.surgeries)
      return { month: index + 1, surgeries }
    })
  }

  // parse procedures by quarter
  const SurgeriesByQuarterStats: SurgeryByMonthObject[] = useMemo(
    () => calculateSurgeriesForQuarter(surgeriesByMonth),
    [surgeriesByMonth]
  )

  // get procedure count by quarter
  const surgeryLengthsByQuarter: number[] = SurgeriesByQuarterStats.map(
    (quarter: SurgeryByMonthObject) => quarter.surgeries.length
  )

  // paRse assets by month
  type DataPoint = {
    hardwareAssets: AssetsThisMonthChartProps['hardwareCount']
    biologicAssets: AssetsThisMonthChartProps['biologicCount']
    otherAssets: AssetsThisMonthChartProps['otherCount']
    consumableAssets: AssetsThisMonthChartProps['consumableCount']
  }

  const dataPoints: DataPoint[] = Object.entries(assetsByMonth).map(
    ([, assetCategory]) => ({
      hardwareAssets: assetCategory.hardwareAssets.length,
      biologicAssets: assetCategory.biologicAssets.length,
      otherAssets: assetCategory.otherAssets.length,
      consumableAssets: assetCategory.consumableAssets.length,
    })
  )

  // parse assets by quarter
  const quarterlyStats = useCallback((): number[][] => {
    function calculateStatsForQuarter(quarterData: DataPoint[]) {
      return Object.values(
        quarterData.reduce(
          (acc, curr) => ({
            hardwareAssets: acc.hardwareAssets + curr.hardwareAssets,
            biologicAssets: acc.biologicAssets + curr.biologicAssets,
            otherAssets: acc.otherAssets + curr.otherAssets,
            consumableAssets: acc.consumableAssets + curr.consumableAssets,
          }),
          {
            hardwareAssets: 0,
            biologicAssets: 0,
            otherAssets: 0,
            consumableAssets: 0,
          }
        )
      )
    }

    function generateQuarterlyStats(dataPoints: DataPoint[]) {
      const quarters: DataPoint[][] = Array.from({ length: 4 }, (_, i) =>
        dataPoints.slice(i * 3, i * 3 + 3)
      )
      return quarters.map((quarter: DataPoint[]) =>
        calculateStatsForQuarter(quarter)
      )
    }

    return generateQuarterlyStats(dataPoints)
  }, [dataPoints])

  const [hardwareData, biologicData, otherData, consumableData] =
    useCallback((): [number[], number[], number[], number[]] => {
      const quarterlyStatsData = quarterlyStats()
      const hardwareData = quarterlyStatsData.map(
        (quarter: number[]) => quarter[0]
      )
      const biologicData = quarterlyStatsData.map(
        (quarter: number[]) => quarter[1]
      )
      const otherData = quarterlyStatsData.map(
        (quarter: number[]) => quarter[2]
      )
      const consumableData = quarterlyStatsData.map(
        (quarter: number[]) => quarter[3]
      )
      return [hardwareData, biologicData, otherData, consumableData]
    }, [quarterlyStats])()

  // instantiate series array and state
  const seriesArray = useMemo(() => {
    return [
      {
        name: 'Implantable Hardware',
        type: 'bar',
        data: hardwareData,
      },
      {
        name: 'Implantable Biologic',
        type: 'bar',
        data: biologicData,
      },
      {
        name: 'Implantable Other',
        type: 'bar',
        data: otherData,
      },
      {
        name: 'Consumable',
        type: 'bar',
        data: consumableData,
      },
      {
        name: 'Procedures',
        type: 'area',
        data: surgeryLengthsByQuarter,
      },
    ]
  }, [
    biologicData,
    consumableData,
    hardwareData,
    otherData,
    surgeryLengthsByQuarter,
  ])

  // instantiate default chart options
  const defaultChartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
    colors: [
      theme.palette.secondary.main,
      theme.palette.primary.main,
      theme.palette.secondary.dark,
      theme.palette.primary.disabled,
      theme.palette.success.light,
    ],
    legend: {
      fontSize: '12px',
      labels: {
        colors: darkMode
          ? theme.palette.grayscale.lightest
          : theme.palette.grayscale.dark,
      },
      fontWeight: 500,
      markers: {
        fillColors: [
          theme.palette.secondary.main,
          theme.palette.primary.main,
          theme.palette.secondary.dark,
          theme.palette.primary.disabled,
          theme.palette.success.light,
        ],
      },
    },
    yaxis: [
      {
        type: 'numeric',
        floating: false,
        title: {
          text: 'Number of Procedures',
          style: {
            fontSize: '12px',
            color: darkMode
              ? theme.palette.grayscale.lightest
              : theme.palette.grayscale.dark,
          },
        },
        labels: {
          style: {
            fontSize: '12px',
            colors: darkMode
              ? theme.palette.grayscale.lightest
              : theme.palette.grayscale.dark,
          },
        },
      },
    ],
    xaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: darkMode
            ? theme.palette.grayscale.lightest
            : theme.palette.grayscale.dark,
        },
      },
    },
  }

  return (
    <ReactApexChart
      options={defaultChartOptions}
      series={seriesArray}
      height={350}
    />
  )
}
