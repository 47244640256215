export const getSubdomainName = () => {
  const inDevelopment = process.env.REACT_APP_NODE_ENV === 'development'

  let subdomainName = ''
  const url = new URL(window.location.href)

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    const orgName = url.searchParams.get('subdomain')
    if (orgName) {
      subdomainName = orgName
    }
  } else {
    const orgName = url.hostname.split('.')[0]
    subdomainName = orgName
  }

  return { inDevelopment, subdomainName }
}
