/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

// Router
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Services
import { useGetPreferenceCardById } from 'lib/services/api/demo-reports-service/preference-card/getPreferenceCardById'
import { useGetSurgeons } from 'lib/services/api/demo-reports-service/preference-card/getSurgeons'
import { useGetProcedureTypes } from 'lib/services/api/demo-reports-service/preference-card/getProcedureTypes'
import { useCreatePrefCard } from 'lib/services/api/demo-reports-service/preference-card/createPreferenceCard'
import { useUpdatePrefCard } from 'lib/services/api/demo-reports-service/preference-card/updatePreferenceCard'

// Types
import { Option } from 'common/types'
import {
  ConsumablesProps,
  EquipmentProps,
  ImplantsProps,
  InstrumentTraysProps,
  PreferenceCardProps,
  ProcedureDetails,
  Product,
  SurgeonInformation,
} from './CardView.types'
import { PositionChangeProps } from '../PreferenceCards.types'

// Other
import { debounce } from 'lodash'
import toast from 'react-hot-toast'
import { useIsMobile } from 'lib/utils/mediaQueries'

const useCardViewLogic = ({ mode }: { mode: 'view' | 'create' }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const isViewMode = mode === 'view'
  const isCreateMode = mode === 'create'
  const isMobile = useIsMobile()
  const DELAY = 1000

  // States
  const [preferenceCard, setPreferenceCard] = useState<PreferenceCardProps>({
    id: id ?? '',
  })
  const [surgeonInformation, setSurgeonInformation] =
    useState<SurgeonInformation>()
  const [procedureDetails, setProcedureDetails] = useState<ProcedureDetails>({
    urinaryDrain: false,
    tableRotationSide: 'Left',
  })
  const [equipmentItems, setEquipmentItems] = useState<EquipmentProps>({
    options: [],
    notes: [],
  })
  const [instrumentTrays, setInstrumentTrays] = useState<InstrumentTraysProps>()
  const [implants, setImplants] = useState<ImplantsProps>()
  const [biologics, setBiologics] = useState<Product[]>([])
  const [consumables, setConsumables] = useState<ConsumablesProps>({
    stapler: false,
  })

  // Services
  const { data: surgeonsData, isLoading: isLoadingSurgeons } = useGetSurgeons()
  const surgeonsOptions = surgeonsData?.surgeons.map((item) => ({
    id: item.id,
    name: `${item.firstName} ${item.lastName}`,
  }))

  const { data: procedureTypesData, isLoading: isLoadingProcedureTypes } =
    useGetProcedureTypes()
  const procedureTypesOptions = procedureTypesData?.procedures.map(
    (item, index) => ({
      id: index,
      name: item,
    })
  )

  const { data: currentPrefCard, isLoading: isLoadingCard } =
    useGetPreferenceCardById({
      id: id ?? '',
      enabled: Boolean(id),
    })
  const card_details = currentPrefCard?.card_details

  const { mutateAsync: create, isLoading: isCreatingCard } = useCreatePrefCard()
  const { mutateAsync: update, isLoading: isUpdatingCard } = useUpdatePrefCard()

  // Handlers
  const handleSurgeonInformationChange = useCallback(
    (field: keyof SurgeonInformation, value: string | string[] | Option) => {
      setSurgeonInformation((prevState) => ({ ...prevState, [field]: value }))
    },
    []
  )

  const handlePositionChange = useCallback(
    (selectedPosition: PositionChangeProps) => {
      setProcedureDetails((prevState) => ({
        ...prevState,
        selectedPosition,
      }))
    },
    []
  )

  const handleInputChange = useCallback(
    (field: keyof ProcedureDetails, value?: string | boolean | string[]) => {
      setProcedureDetails((prevState) => ({ ...prevState, [field]: value }))
    },
    []
  )

  const handleEquipmentItemChange = useCallback(
    (index: number, field: string, value: string | boolean) => {
      setEquipmentItems((prev) => {
        const updatedItems = [...(prev.options || [])]
        if (!updatedItems[index]) {
          updatedItems[index] = {}
        }
        updatedItems[index][field] = value
        return { ...prev, options: updatedItems }
      })
    },
    []
  )

  const handleCheckboxChange = (value: number, checked: boolean) => {
    setConsumables((prev) => {
      let updatedBladesCount = [...(prev.bladesCount || [])]
      if (checked) {
        updatedBladesCount.push(value)
      } else {
        updatedBladesCount = updatedBladesCount.filter(
          (count) => count !== value
        )
      }
      return { ...prev, bladesCount: updatedBladesCount }
    })
  }

  const handleClickBack = useCallback(() => {
    navigate('/preference-cards')
  }, [navigate])

  // Retrieve the current preference card logic
  useEffect(() => {
    if (currentPrefCard) {
      setSurgeonInformation({
        ...card_details?.surgeonInformation,
        physicianName: surgeonsOptions?.find(
          (item) => item.id === currentPrefCard.surgeon.id
        ),
        procedureName: currentPrefCard.procedure_type,
      })
      setProcedureDetails(card_details?.procedureDetails ?? {})
      setEquipmentItems(card_details?.equipment ?? { options: [] })
      setInstrumentTrays(card_details?.instrumentTrays ?? {})
      setImplants(card_details?.implants ?? {})
      setConsumables(card_details?.consumables ?? {})
      setBiologics(card_details?.biologics ?? [])
    }
  }, [currentPrefCard, currentPrefCard])

  // Retrieve the surgeon information from state
  // Create new pref card from procedures list logic
  useEffect(() => {
    if (state && isCreateMode) {
      const { surgeonId, procedureType } = state as {
        surgeonId: string
        procedureType: string
      }
      const surgeon = surgeonsOptions?.find((item) => item.id === surgeonId)
      setSurgeonInformation({
        physicianName: surgeon,
        procedureName: procedureType,
      })
    }
  }, [state, surgeonsData])

  // Updating logic
  const savePreferenceCards = debounce((data: PreferenceCardProps) => {
    if (isViewMode) {
      update({
        id: id as string,
        card_details: data,
      }).catch((error) => {
        console.error('ERROR', error)
      })
    }
  }, DELAY)

  const debouncedSavePreferenceCards = debounce(savePreferenceCards, DELAY)
  useEffect(() => {
    const updatedPreferenceCard: PreferenceCardProps = {
      id: id ?? '',
      surgeonInformation,
      procedureDetails,
      equipment: equipmentItems,
      instrumentTrays,
      implants,
      consumables,
      biologics,
    }
    setPreferenceCard(updatedPreferenceCard)
    debouncedSavePreferenceCards(updatedPreferenceCard)

    return () => {
      debouncedSavePreferenceCards.cancel()
    }
  }, [
    id,
    surgeonInformation,
    procedureDetails,
    equipmentItems,
    instrumentTrays,
    implants,
    consumables,
    biologics,
  ])

  // Creating logic
  const createPreferenceCard = () => {
    const data: PreferenceCardProps = {
      id: id ?? '',
      surgeonInformation,
      procedureDetails,
      equipment: equipmentItems,
      instrumentTrays,
      implants,
      consumables,
      biologics,
    }
    create({
      surgeon_id: surgeonInformation?.physicianName?.id as string,
      procedure_type: surgeonInformation?.procedureName ?? '',
      card_details: data,
    })
      .then(() => {
        navigate('/preference-cards')
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error)
      })
  }

  return {
    implants,
    biologics,
    consumables,
    isMobile,
    canSave:
      surgeonInformation?.physicianName?.id &&
      surgeonInformation?.procedureName,
    isLoadingCard: isLoadingCard,
    preferenceCard,
    equipmentItems,
    isCreatingCard,
    isUpdatingCard,
    isLoading: isCreatingCard || isUpdatingCard || isLoadingCard,
    surgeonsOptions,
    instrumentTrays,
    procedureDetails,
    isLoadingSurgeons,
    surgeonInformation,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    setBiologics,
    setImplants,
    setConsumables,
    handleClickBack,
    handleInputChange,
    setEquipmentItems,
    setInstrumentTrays,
    handlePositionChange,
    createPreferenceCard,
    handleCheckboxChange,
    handleEquipmentItemChange,
    handleSurgeonInformationChange,
  }
}
export default useCardViewLogic
