import { GET_LOCATIONS_TREE } from 'lib/services/api/schema'
import { useQuery, QueryResult } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'
import { GetLocationsTreeResponse } from './types'

const useGetLocationsTree = (): QueryResult<
  GetLocationsTreeResponse,
  Error
> => {
  return useQuery(GET_LOCATIONS_TREE, {
    onError: (error: Error) => {
      captureWarning(error)
    },
  })
}

export default useGetLocationsTree
