import { useAuth0 } from 'app/Auth'
import axios from 'axios'
import { useMutation } from 'react-query'

interface CreatePrefCardParams {
  id: string
  card_details: Record<string, any>
}

interface CreatePrefCardResponse {
  message: string
}

export const useUpdatePrefCard = () => {
  const { getAccessToken } = useAuth0()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL

  const mutation = useMutation<
    CreatePrefCardResponse,
    Error,
    CreatePrefCardParams
  >(async (data: CreatePrefCardParams) => {
    const token = await getAccessToken()
    try {
      const response = await axios.put(
        `${REPORTS_URL}api/v1/preference_cards/${data.id}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  })

  return mutation
}
