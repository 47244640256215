import dayjs, { humanizedFullYearDateFormat } from 'lib/dayjs'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TextField from '@mui/material/TextField/TextField'
import useDateInputLogic from './DateInput.logic'
import { IDateInput } from './DateInput.types'

function DateInput({
  value = null,
  handleChangeFormErrorField,
  handleChangeFormData,
  handleValidateForm,
  name,
  variant = 'standard',
  size = 'medium',
  openTo = 'year',
  minDate,
  hideTextField = false,
  shrink = true,
  readOnly = false,
  format = humanizedFullYearDateFormat,
  ...props
}: IDateInput) {
  const { handleAccept, handleManualChange } = useDateInputLogic({
    value,
    handleChangeFormErrorField,
    handleChangeFormData,
    handleValidateForm,
    name,
    variant,
    size,
    openTo,
    minDate,
    hideTextField,
    shrink,
    readOnly,
    ...props,
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={
          value
            ? dayjs(value as dayjs.ConfigType, humanizedFullYearDateFormat)
            : null
        }
        inputFormat={format}
        mask="____/__/__"
        onChange={() => null}
        onAccept={handleAccept}
        openTo={openTo}
        views={['year', 'month', 'day']}
        clearable
        clearText="Close"
        disableHighlightToday
        allowSameDateSelection
        reduceAnimations
        minDate={minDate ? dayjs(minDate as Date) : undefined}
        renderInput={(params) => {
          const parsedParams = { ...params }

          // When value gets reset to null (user clears form) update inputProps.value to empty string to clear the input
          if (value === null && parsedParams.inputProps?.value) {
            parsedParams.inputProps.value = ''
          }

          return (
            <TextField
              name={name}
              variant={variant}
              onBlur={() => handleValidateForm && handleValidateForm()}
              onChange={handleManualChange}
              InputLabelProps={{ shrink: shrink }}
              sx={{
                '& .MuiInputBase-input': {
                  textTransform: 'uppercase',
                },
                zIndex: hideTextField ? -100 : undefined,
                position: hideTextField ? 'absolute' : undefined,
                transform: hideTextField
                  ? 'translate(-50%, -50%) !important'
                  : 'none',
              }}
              {...parsedParams}
            />
          )
        }}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default DateInput
