import { CustomIcon } from 'components/templates/CustomIcon'
import { theme } from 'assets/styles/customTheme'
interface MedGEOLogoProps {
  color?: string
  scale?: number
  height?: string
  width?: string
}

export const MedGEOLogoComponent = ({
  color,
  scale,
  height,
  width,
}: MedGEOLogoProps) => {
  return (
    <CustomIcon
      color={color ? color : theme.palette.primary.main}
      active
      height={height ? height : '45px'}
      width={width ? width : '200px'}
      scale={scale ? scale : 0.8}
    >
      <path
        d="M10.2,37.9c-0.9,0.8-2.1-0.3-2.6-0.8c-0.9-1-3.2-2.4-1.9-0.3c0.5,0.6,2.8,3.5,3.3,2.7c0.4-0.2,1.7,0.9,2.3,1.5
	c1.1,1.1,2.8,1.9,2.2-0.5C13.2,39.2,11.3,37.1,10.2,37.9L10.2,37.9z"
      />
      <path
        d="M3.3,26.9c0.1-0.5,0.6-1,0.5-1.8c-0.1-0.8-0.5-1.3-0.9-1.9c-1.1-2.1,0-3.4,0.3-3.7c1-1.4,1-4-0.9-3.6h0
	c-1.5,0.2-2.5,2.3-1.9,3.9c1.6,4-1.9,3,0.2,6.5c2.2,3.5,0.4,2.3,0.9,4.4C2.1,32.7,6,35,5.1,31.9C4.4,29.7,2.6,29.9,3.3,26.9
	L3.3,26.9z"
      />
      <path
        d="M13.4,14.8c-1.9,2.1-3.6,0.3-3.6,0.3c-1.4-1.4-3.5,0.4-3.7,2.2c-0.3,2.2,2,3.1,3.5,1.6c1.3-1.3,3.1-1,3.7,0.1
	c1.6,3.2-0.9,3.8-0.8,6.2c0.8,5.6,6.7,2.1,3.9-2c-1.7-2.4,0-4.5,0-4.5c2.4-3.1-1.9-5.1,0.4-8.2c1.9-2.6,0.1-4.4-1-4.3h0
	C14,6.5,12.5,9,13.9,11C14.8,12.2,14,14.1,13.4,14.8L13.4,14.8z"
      />
      <path
        d="M37.2,36.7c-0.6,0.5-1.8,1.9-2.1,1.1c-0.9-2.2,1.2-3.3,2-5.5c0.6-2.3-1.7-2.6-3.1-1c-1.9,2.1-5.6,0.2-2.8-4h0
	c0.6-0.6,1.9-1.4,3.2-0.4c1.2,2,3.8,0.2,3.9-1.8c0.2-2.2-2-3.6-3.6-1.7c0,0-1.6,1.6-3.2-0.1c-2.2-2.3,0.5-4.5,0.4-6.2
	c-0.3-3.4-4.6-4.1-4.6-0.2v0c0,0.8,0.3,1.2,0.8,2.1c1,1.8,0.7,3.7-0.1,4.6c0,0-1.1,1.9-3.6-0.1c-0.5-0.4-1.1-0.9-1.7-0.9
	c-1.3,0-2.4,1.3-2.4,3c0,1.6,1.1,2.9,2.4,2.9c1.8,0.1,3-3.3,5.4-0.8c0.4,0.4,1.2,1.9-0.3,4.4c-1.7,2.8,0.2,5.7,2.7,3.3
	c1.9-1.8,5.1-1.6,2.2,3c-0.9,1.5-2,3.9-0.1,3.1c0.9-0.4,2.3-2.3,3.3-2.3c0.4,0.6,1.7-0.9,3.2-2.8C40.5,34.4,37.8,36.1,37.2,36.7
	L37.2,36.7z"
      />
      <path
        d="M10.9,33.2c-0.1-0.8-0.5-1.5-1.1-2.1c-1.9-3.7,0.4-3.3,0.2-5.6c-0.1-3.4-4.3-3.8-4.2-0.2h0
	c0.1,0.7,0.3,1.1,0.8,1.9c0.6,0.8,1.2,2.4,0.6,3.7C5.8,33.8,11.3,37.9,10.9,33.2L10.9,33.2z"
      />
      <path
        d="M17.7,4.2c1.2-1.6,2.8-0.4,3-0.1c0.8,1,0.7,2.8-0.3,4.1c-0.8,1.1-0.8,2.2,0.1,3.7c1.3,2.8,0,4.2,0,4.2
	c-1.5,1.5-0.5,5.1,1.7,4.8c2.4-0.2,3.1-3,1.2-5.5C22,13.6,24.2,9,27.5,12c2.8,2.6,4.6-1.9,2.5-3.8c-1.6-1.4-2.1-0.6-2.9-0.1
	c-3.3,2.2-4.8-2.8-3.5-4c1.5-1.7,3.1,0.1,3.2,0.3c1.1,1.4,3.3,1,2.8-1.1c-0.4-1.5-2.1-3.6-3.3-2.2c-1.6,1.1-2.5,0-2.7-0.2
	c-0.5-0.6-1.5-1.6-2.9,0.1h0c-0.4,0.4-1.5,1-2.4,0.3c-1.1-1.7-3.1,0.4-3.4,2C14.3,5.4,16.5,5.8,17.7,4.2L17.7,4.2z"
      />
      <path
        d="M26.7,39.8c-0.6,0.5-1.8,1.9-3.2,0.2c-0.2-0.3-1.2-1.9,0.2-3.8c0.5-0.6,0.7-1.2,0.7-1.9c0.2-2.2-2.3-3.6-3.8-2
	c0,0-1.6,1.8-3.4,0.1c-1.2-1.9-4.1-1.2-3.7,1.3h0c0.2,1.9,2.2,3.7,3.8,2.1c2.1-2,5.3,1.5,3.3,4.3c-1.3,1.9-3.4-1-4.5-1.1
	c-2.9-0.5-1,4.4,0.7,4.6c1.7,0.2,1.1-2,3.9-0.1s2.5-2.1,5.4-0.5c0.4,0.2,0.6,0.7,1.3,0.4C30.6,42.1,30.2,36.4,26.7,39.8z"
      />
      <path
        d="M5.4,11.9c0.2-2.2-0.2-2.7,1.4-4.3s2.4-1.3,2.6-1.1c1.8,1.4,5.4-4.8,2.5-4h0c-0.5,0.2-1,0.7-1.5,1.2
	C8.8,5.3,8.4,5.1,8.4,5C8,4.4,4.8,8,4.7,8.6C4.6,10,2.5,11.7,2,13C1.1,16.3,5.2,13.7,5.4,11.9L5.4,11.9z"
      />
      <path
        d="M43.4,23.5c-0.2-0.2-0.5-2.2-0.1-2.6c0.5-0.6,0.9-1.1,0.7-2.1c-0.3-1.3-2.4-2.5-1.6-4.5
	c0.1-0.3,0.1-0.7-0.1-1.2c-0.3-0.8-2.1-2.7-2.7-4.3c-0.2-0.5-3.2-4.1-3.7-3.8C35.2,5.1,34.1,4,33,2.9c-1.1-1-2.1-0.7-1.5,1
	c0.7,2.1,2.8,3.2,3.4,2.5c1.2-1.3,4.6,3.3,4.1,4.1c-1.1,1.7-3.1-1.2-4.5-1.8c-1-0.4-1.8,0.9-1.4,1.9c1.1,2.7,2.5,4.5,1.3,5.8
	c-1.3,1.4-0.2,4.7,1.7,4.6c2.2,0.1,2.6-3.4,1-4.6c-1-1.6-0.8-3-0.4-3.5c1-1.1,2.3-0.2,3,0.6c0.6,0.6,1.5,2.1,0.8,3.4
	c-1.6,2.9,2.3,3.8,0.3,6.7h0c-2.6,3.7,1.8,5.6,3,1.4C44.2,24.5,43.7,23.8,43.4,23.5z"
      />
      <path
        d="M66.9,29l-0.2,0.9L66.5,29l-6.1-17.8H54v23.4h4.5V18.5l-0.2-2.8l0.7,2.7l5.7,16.2h3.6l5.8-16.2l0.7-2.9l-0.2,3
	v16.1h4.5V11.2l-6.1,0L66.9,29z"
      />
      <path
        d="M96,28.4c-1.1,1.8-2.2,2.6-4.3,2.6c-2.8,0-4.4-1.5-4.8-4.1h13.5v-1.7c0-4.6-2.8-8.9-8.8-8.9
	c-5.7,0-9.2,4.4-9.2,9.4c0,5.3,3.2,9.2,9.4,9.2c3.9,0,6.5-1.7,8.2-4.4L96,28.4z M91.6,20.2c2.9,0,4.1,1.7,4.2,3.9H87
	C87.4,21.6,89,20.2,91.6,20.2z"
      />
      <path
        d="M102.5,25.7c0,6,3.3,9.1,7.6,9.1c2.9,0,4.9-1.4,6-3.8v3.6h4.6V9.1H116V20c-1.1-2.4-2.8-3.5-5.6-3.5
	C106.4,16.5,102.5,19.5,102.5,25.7z M111.8,31c-3.3,0-4.5-2.1-4.5-5.3c0-3.2,1.4-5.4,4.5-5.4c3.2,0,4.5,2.2,4.5,5.4
	C116.4,28.8,115.1,31,111.8,31z"
      />
      <path
        d="M128.8,23.1c0-2.5,0.6-4.4,1.9-5.8c1.3-1.4,2.9-2.1,5-2.1c3.3,0,5.2,1.4,6.6,4.2l4.3-2.8
	c-1.1-1.7-2.5-3.1-4.2-4.2c-1.7-1.1-3.8-1.6-6.4-1.6c-7.5,0-12,5.4-12,12.7c0,6,3.6,11.3,10.2,11.3c3.9,0,6.6-2,7.7-5.7v5.4h4.6
	V21.8h-11.8v3.5h7.1c-0.5,3.7-3.1,5.5-6.4,5.5C131.3,30.8,128.8,28,128.8,23.1z"
      />
      <path d="M167.7,34.6v-4.2h-12.9v-5.8h9.8V21h-9.8v-5.5h12.9v-4.2h-17.5v23.4H167.7z" />
      <path
        d="M180.3,34.9c7.6,0,11.5-5.4,11.5-12s-3.8-12-11.4-12c-7.2,0-11.4,5.5-11.4,12C169,29.6,172.6,34.9,180.3,34.9z
	 M180.3,30.6c-4.4,0-6.5-3.2-6.5-7.7s2.4-7.7,6.6-7.7c2.2,0,3.8,0.7,4.9,2.1c1.1,1.4,1.7,3.3,1.7,5.6s-0.5,4.1-1.7,5.6
	C184.2,29.9,182.6,30.6,180.3,30.6z"
      />
    </CustomIcon>
  )
}
