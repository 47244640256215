import { cloneElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'

import { NavbarItemProps } from '../MiniNavbar.types'
import './MiniNavbarItem.scss'
import Tooltip from 'components/molecules/Tooltip/Tooltip'
import classNames from 'classnames'

const NavbarItem = ({ title, route, icon }: NavbarItemProps) => {
  const { setProductData, setIsDrawerOpen } = useSPDScan()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const itemIcon = cloneElement(icon, { className: 'icon' })
  const isActive = pathname === `/spd/${route}`

  const handleClick = () => {
    if (isActive) {
      return
    }

    setIsDrawerOpen(false)
    setProductData([])
    navigate(`./${route}`)
  }

  return (
    <div className="spd-navbar_container">
      <Tooltip tooltipContent={title} onClick={handleClick}>
        <div className={classNames('navbar-item', { active: isActive })}>
          {itemIcon}
        </div>
      </Tooltip>
    </div>
  )
}

export default NavbarItem
