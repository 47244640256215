import Typography from '@mui/material/Typography'
import { Check, Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import TextInput from 'components/molecules/TextInput/TextInput'
import AutocompleteInput from '../AutocompleteInput/AutocompleteInput'
import { StatusBar } from 'components/molecules/StatusBar/StatusBar'
import { ImplantReportSenderModal } from './ImplantReportSenderModal/ImplantReportSenderModal'
import { LoadingButton } from 'components/mui'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { ViewImplantReportModal } from './ViewImplantReportModal/ViewImplantReportModal'
import { ImplantReportType } from 'common/types'
import { ImplantReportSenderProps } from './ImplantReportSender.types'
import { useImplantReportSenderLogic } from './ImplantReportSender.logic'
import './ImplantReportSender.scss'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

export const ImplantReportSender = (props: ImplantReportSenderProps) => {
  const {
    search,
    isMobile,
    assetIds,
    isAdding,
    openModal,
    isLoading,
    isEditing,
    modalHeader,
    errorMessage,
    isReportSent,
    inputModalOpen,
    hasUpdatedEmail,
    mutationSuccess,
    lastNameOptions,
    isSubmitEnabled,
    physicianLoading,
    physicianDetails,
    selectedPhysician,
    implantReportInputs,
    isReferringInfoValid,
    viewReportsModalOpen,
    formattedReportTypes,
    updatedPhysicianDetails,
    setSearch,
    disabledOption,
    formatEnumValue,
    setErrorMessage,
    handleEditClick,
    handleCloseModal,
    unFormatEnumValue,
    setInputModalOpen,
    setMutationSuccess,
    handleAddPhysician,
    handleSelectPhysician,
    setImplantReportInputs,
    setViewReportsModalOpen,
    handleAddPhysicianClick,
    setUpdatedPhysicianDetails,
  } = useImplantReportSenderLogic(props)

  const { surgeryId, implantReportStatus, surgeryDate } = props

  return (
    <div className="implant-report-sender-container">
      <div className="implant-report-header">
        <div className="implant-header-title">
          <Typography variant="h3" sx={{ transform: 'none' }}>
            Share Surgery Report(s)
          </Typography>
          <Typography variant="body2" className="secondary-text">
            Select a report template to send to the referring physician
          </Typography>
        </div>

        {isReportSent && (
          <LoadingButton
            fullwidth={false}
            wrapText
            onClick={() => {
              setViewReportsModalOpen(true)
            }}
            disabled={!implantReportStatus || implantReportStatus.length === 0}
          >
            View Report(s)
          </LoadingButton>
        )}
      </div>

      <div
        className={classNames('referring-input', {
          'is-mobile': isMobile,
        })}
      >
        <AutocompleteInput
          id="referringLastName"
          label="Physician Last Name"
          name="referringLastName"
          variant="outlined"
          value={selectedPhysician}
          onTextInputChange={(e) => setSearch(e.target.value as string)}
          handleChangeFormData={(e) => handleSelectPhysician(e)}
          required={false}
          fullWidth
          autoSelect={false}
          options={lastNameOptions}
          isLoading={physicianLoading}
          addOption
          disableClearable
          noOptionsText={
            lastNameOptions.length === 0 &&
            search.length > 1 &&
            !physicianLoading ? (
              <div className="implant-report-header-no-options">
                <Typography variant="body2" sx={{ mb: 1 }}>
                  No options found
                </Typography>
                <LoadingButton onClick={handleAddPhysicianClick}>
                  Add Referring Physician
                </LoadingButton>
              </div>
            ) : (
              'Please enter a last name to begin search'
            )
          }
        />
        <div className="physician-container">
          <TextInput
            id="referringEmail"
            label="Physician Email"
            variant="outlined"
            value={physicianDetails?.email}
            fullWidth
            disabled
          />
          {physicianDetails?.email && (
            <IconButton onClick={handleEditClick}>
              <Edit />
            </IconButton>
          )}
        </div>
      </div>

      <AutocompleteInput
        id={'implant-report-input'}
        className="implant-report-menu"
        handleChangeFormData={(e) => {
          const value = unFormatEnumValue(e.target.value as string)

          setImplantReportInputs((prevState) => ({
            ...prevState,
            reportType: value,
          }))
          setInputModalOpen(true)
        }}
        name={'implant-report'}
        label="Select Report Template"
        required={false}
        variant="outlined"
        value={formatEnumValue(
          implantReportInputs.reportType as ImplantReportType
        )}
        options={formattedReportTypes}
        autoSelect={false}
        disableClearable
        disabled={!isReferringInfoValid}
        getOptionDisabled={(option) => {
          return !isEmpty(implantReportStatus) && disabledOption(option)
        }}
        toolTip={(option) => {
          return !isEmpty(implantReportStatus) && disabledOption(option)
            ? 'Please select "Other" if you would like to send a different report type'
            : ''
        }}
      />

      {mutationSuccess && (
        <StatusBar
          icon={<Check color="success" fontSize="small" />}
          message={`${formatEnumValue(
            implantReportInputs?.[
              'reportType'
            ] as keyof typeof ImplantReportType
          )} Report Sent Successfully`}
          color="success"
        />
      )}

      {inputModalOpen && (
        <ImplantReportSenderModal
          assetIds={assetIds}
          inputModalOpen={inputModalOpen}
          formatEnumValue={formatEnumValue}
          mutationSuccess={mutationSuccess}
          errorMessage={errorMessage}
          surgeryDate={surgeryDate}
          surgeryId={surgeryId}
          implantReportStatus={implantReportStatus}
          implantReportInputs={implantReportInputs}
          setImplantReportInputs={setImplantReportInputs}
          unformatEnumValue={unFormatEnumValue}
          setMutationSuccess={setMutationSuccess}
          setErrorMessage={setErrorMessage}
          setInputModalOpen={setInputModalOpen}
        />
      )}

      {viewReportsModalOpen && (
        <ViewImplantReportModal
          implantReportStatus={implantReportStatus || []}
          formatEnumValue={formatEnumValue}
          viewReportsModalOpen={viewReportsModalOpen}
          setViewReportsModalOpen={setViewReportsModalOpen}
        />
      )}

      <CustomModal
        open={openModal.open}
        handleOnSubmit={() => {
          handleAddPhysician()
        }}
        icon={<></>}
        header={modalHeader}
        isLoadingState={isAdding || isEditing}
        secondaryButtonAction={handleCloseModal}
        primaryButtonDisabled={!isSubmitEnabled}
      >
        <TextInput
          disabled={isLoading}
          id="referringFirstName"
          label="Physician First Name"
          name="referringFirstName"
          variant="outlined"
          value={updatedPhysicianDetails?.firstName || ''}
          fullWidth
          handleChangeFormData={(e) =>
            setUpdatedPhysicianDetails((prev) => ({
              ...prev,
              firstName: e.target.value,
            }))
          }
        />

        <TextInput
          disabled={isLoading}
          id="referringLastName"
          label="Physician Last Name"
          name="referringLastName"
          variant="outlined"
          value={updatedPhysicianDetails?.lastName}
          required
          fullWidth
          handleChangeFormData={(e) =>
            setUpdatedPhysicianDetails((prev) => ({
              ...prev,
              lastName: e.target.value,
            }))
          }
        />

        <TextInput
          disabled={isLoading}
          id="referringEmail"
          label="Physician Email"
          name="referringEmail"
          variant="outlined"
          value={updatedPhysicianDetails?.email}
          handleChangeFormData={(e) => {
            setUpdatedPhysicianDetails((prev) => ({
              ...prev,
              email: e.target.value.toLocaleLowerCase(),
            }))
          }}
          required
          fullWidth
          validateEmail
        />

        {hasUpdatedEmail && (
          <TextInput
            disabled={isLoading}
            id="confirmEmail"
            label="Confirm Email"
            name="confirmEmail"
            variant="outlined"
            value={updatedPhysicianDetails?.confirmEmail}
            handleChangeFormData={(e) => {
              setUpdatedPhysicianDetails((prev) => ({
                ...prev,
                confirmEmail: e.target.value.toLocaleLowerCase(),
              }))
            }}
            required
            fullWidth
            validateEmail
          />
        )}
      </CustomModal>
    </div>
  )
}
