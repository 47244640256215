import { FormatType, useCortexDecoder } from 'lib/cortex'
import { useState } from 'react'
import { useSPDCortexScannerContext } from '../SPDCortexScanner.context'

export const useSPDCortexVideo = ({
  onScan,
  isEnabled = true,
}: {
  onScan: (newScan: CortexDecoder.CDResult) => void
  isEnabled?: boolean
}) => {
  const { setSelectedCamera } = useSPDCortexScannerContext()
  const { formatType, setFormatType } = useCortexDecoder({ onScan, isEnabled })

  const [isSwitchDisabled, setIsSwitchDisabled] = useState<boolean>(false)
  const [isCameraMenuOpen, setIsCameraMenuOpen] = useState<boolean>(false)

  const handleCameraChange = (selectedCamera: MediaDeviceInfo) => {
    setSelectedCamera(selectedCamera)
  }

  const handleFormatChange = (checked: boolean) => {
    if (!isSwitchDisabled) {
      setIsSwitchDisabled(true)
      const updatedFormatType = checked ? FormatType['1d'] : FormatType['2d']
      setFormatType(updatedFormatType)
      localStorage.setItem('formatType', updatedFormatType)
      setTimeout(() => setIsSwitchDisabled(false), 1000)
    }
  }

  const handleToggleCameraMenu = () => {
    setIsCameraMenuOpen((prev) => !prev)
  }

  return {
    formatType,
    handleFormatChange,
    isSwitchDisabled,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleCameraChange,
  }
}
