import { useEffect, useRef, useState } from 'react'

import {
  isTablet,
  isMobile,
  isIPad13,
  isAndroid,
  isIOS,
} from 'react-device-detect'

const usePaginatedSearchLogic = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isMobileOrTablet =
    isMobile || isTablet || isIPad13 || isAndroid || isIOS

  const [viewport, setViewport] = useState<{
    maxHeight: number
    maxWidth: number
  }>({
    maxHeight: window.visualViewport?.height ?? window.innerHeight,
    maxWidth: window.visualViewport?.width ?? window.innerWidth,
  })

  const isKeyboardOpen =
    (isMobile || isTablet) && viewport.maxHeight < window.innerHeight
  const height = isKeyboardOpen ? viewport.maxHeight - 95 : '87dvh'

  const updateViewport = () => {
    setViewport({
      maxHeight: window.visualViewport?.height ?? window.innerHeight,
      maxWidth: window.visualViewport?.width ?? window.innerWidth,
    })
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof window.visualViewport !== 'undefined'
    ) {
      updateViewport()
      window.visualViewport?.addEventListener('resize', updateViewport)

      return () => {
        window.visualViewport?.removeEventListener('resize', updateViewport)
      }
    }
  }, [])

  return {
    isMobileOrTablet,
    height,
    ref,
  }
}

export default usePaginatedSearchLogic
