import { useCallback, useEffect, useState } from 'react'
import { FormValidationAlertToaster } from '../../../components/AlertDialog/FormValidationAlertToaster'
import { FormValidationAlertType } from 'common/types'
import { getAlertType } from './utils'
import toast from 'react-hot-toast'

export interface FormValidationProps {
  validationSchema: (
    data: FormData | { [k: string]: FormDataEntryValue }
  ) => any
}

export type FormAlerts = {
  code: string
  message: string
  path: [string]
  acknowledged: boolean
  cancelled: boolean
}

const useFormValidation = ({ validationSchema }: FormValidationProps) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loadingAlerts, setLoadingAlerts] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [formAlerts, setFormAlerts] = useState<FormAlerts[]>([])
  const [isAcknowledgingIndex, setIsAcknowledgingIndex] = useState<
    null | number
  >(null)
  const [isCancelingIndex, setIsCancelingIndex] = useState<null | number>(null)
  const [isReadyToConfirm, setIsReadyToConfirm] = useState(false)
  const [isFormValidated, setIsFormValidated] = useState(false)

  const getErrors = useCallback(() => {
    return formAlerts.length > 0
      ? formAlerts.filter(
          (alert) => getAlertType(alert) === FormValidationAlertType.ERROR
        )
      : []
  }, [formAlerts])
  const getWarnings = useCallback(() => {
    return formAlerts.length > 0
      ? formAlerts.filter(
          (alert) => getAlertType(alert) === FormValidationAlertType.WARNING
        )
      : []
  }, [formAlerts])
  const getConfirms = useCallback(() => {
    return formAlerts.length > 0
      ? formAlerts.filter(
          (alert) => getAlertType(alert) === FormValidationAlertType.CONFIRM
        )
      : []
  }, [formAlerts])

  const acknowledgeAlert = useCallback(
    (message: string | Element) => {
      const index = formAlerts.findIndex((alert) => alert.message === message)
      setIsAcknowledgingIndex(index)
    },
    [formAlerts]
  )

  const cancelAlert = useCallback(
    (message: string | Element) => {
      const index = formAlerts.findIndex((alert) => alert.message === message)
      setIsCancelingIndex(index)
    },
    [formAlerts]
  )

  const handleValidation = (e: any) => {
    // clear all old/duplicate alerts from last submission
    toast.remove()

    setIsFormSubmitted(true)
    setLoadingAlerts(true)

    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    const formValidationState = validationSchema(data)

    if (!formValidationState.success) {
      setIsSubmitDisabled(true)
      setFormAlerts(formValidationState.error.issues)
    } else {
      setIsFormValidated(true)
    }
  }

  /**
   * manage all formAlerts updates here
   */
  useEffect(() => {
    if (loadingAlerts && formAlerts.length > 0) {
      setLoadingAlerts(false)
    }

    if (isAcknowledgingIndex !== null) {
      const updatedFormAlerts = formAlerts
      updatedFormAlerts[isAcknowledgingIndex].acknowledged = true
      setIsAcknowledgingIndex(null)
      setFormAlerts(updatedFormAlerts)
    }

    if (isCancelingIndex !== null) {
      const updatedFormAlerts = formAlerts
      updatedFormAlerts[isCancelingIndex].cancelled = true
      setIsCancelingIndex(null)
      setFormAlerts(updatedFormAlerts)
    }
  }, [
    formAlerts,
    isFormSubmitted,
    isAcknowledgingIndex,
    isCancelingIndex,
    loadingAlerts,
  ])

  /**
   * After each acknowledgement/cancellation, check for end of alerts
   */
  useEffect(() => {
    // handle errors
    const alertsIncludeError = formAlerts.find(
      (alert) => getAlertType(alert) === FormValidationAlertType.ERROR
    )
    const error = alertsIncludeError ?? null
    if (
      isFormSubmitted &&
      !loadingAlerts &&
      alertsIncludeError &&
      error?.cancelled
    ) {
      // only first error will pop up, cancelling it will reset form
      clearAlerts()
    }

    // happy path (warnings and confirms) all acknowledged
    // after each one, check for final acknowledgement
    if (
      isFormSubmitted &&
      formAlerts.filter((alert) => !alert.acknowledged).length === 0
    ) {
      setIsFormValidated(true)
    }

    // there was at leaset one warning cancellation, so not validated, submission blocked
    // check for final cancellation / acknowledgement in order to re-enable the submit button
    if (
      isFormSubmitted &&
      formAlerts.filter(
        (alert) =>
          getAlertType(alert) === FormValidationAlertType.WARNING &&
          alert.cancelled
      ).length > 0 &&
      formAlerts.filter(
        (alert) =>
          getAlertType(alert) === FormValidationAlertType.WARNING &&
          !alert.cancelled &&
          !alert.acknowledged
      ).length === 0
    ) {
      clearAlerts()
    }

    // no warnings, and at least one confirm
    if (
      isFormSubmitted &&
      formAlerts.filter(
        (alert) => getAlertType(alert) === FormValidationAlertType.WARNING
      ).length === 0 &&
      formAlerts.filter(
        (alert) => getAlertType(alert) === FormValidationAlertType.CONFIRM
      ).length > 0
    ) {
      setIsReadyToConfirm(true)
      setLoadingAlerts(true)
    }

    // at least one warning, all warnings acknowledged and there is at least one confirm
    if (
      isFormSubmitted &&
      formAlerts.filter(
        (alert) => getAlertType(alert) === FormValidationAlertType.WARNING
      ).length > 0 &&
      formAlerts.filter(
        (alert) =>
          getAlertType(alert) === FormValidationAlertType.WARNING &&
          !alert.acknowledged
      ).length === 0 &&
      formAlerts.filter(
        (alert) => getAlertType(alert) === FormValidationAlertType.CONFIRM
      ).length > 0
    ) {
      setIsReadyToConfirm(true)
      setLoadingAlerts(true)
    }

    // an error was cancelled, reset form
    if (
      isFormSubmitted &&
      formAlerts.find(
        (alert) =>
          getAlertType(alert) === FormValidationAlertType.ERROR &&
          alert.cancelled
      )
    ) {
      clearAlerts()
    }

    // a confirm was cancelled, reset form
    if (
      isFormSubmitted &&
      isReadyToConfirm &&
      formAlerts.find(
        (alert) =>
          getAlertType(alert) === FormValidationAlertType.CONFIRM &&
          alert.cancelled
      )
    ) {
      clearAlerts()
    }
  }, [
    isFormSubmitted,
    formAlerts,
    isAcknowledgingIndex,
    isCancelingIndex,
    loadingAlerts,
    getConfirms,
    isReadyToConfirm,
  ])

  const clearAlerts = () => {
    setIsSubmitDisabled(false) // allow user to press submit again
    setIsFormSubmitted(false) // prevent automatic re-submission after last alert is cancelled / acknowledged
    setFormAlerts([]) // clear all alerts and start fresh
    setLoadingAlerts(false)
    setIsReadyToConfirm(false)
  }

  const FormValidationAlertToasters = () => {
    const errors = getErrors()
    const warnings = getWarnings()
    const confirms = getConfirms()

    return (
      <>
        <div>
          {errors.length > 0 &&
            errors.map((alert) => {
              return (
                <div key={alert.message}>
                  {!alert.acknowledged && (
                    <FormValidationAlertToaster
                      formValidationAlertType={FormValidationAlertType.ERROR}
                      loadingAlerts={loadingAlerts}
                      setLoadingAlerts={setLoadingAlerts}
                      message={alert.message}
                      onCancel={cancelAlert}
                    />
                  )}
                </div>
              )
            })}
        </div>
        <div>
          {/* don't bother showing any warnings when an error is present */}
          {errors.length === 0 &&
            warnings.length > 0 &&
            warnings.map((alert) => {
              return (
                <div key={alert.message}>
                  {!alert.acknowledged && (
                    <FormValidationAlertToaster
                      formValidationAlertType={FormValidationAlertType.WARNING}
                      loadingAlerts={loadingAlerts}
                      setLoadingAlerts={setLoadingAlerts}
                      message={alert.message}
                      onCancel={cancelAlert}
                      onAcknowledge={acknowledgeAlert}
                    />
                  )}
                </div>
              )
            })}
        </div>
        <div>
          {/* don't show confirm when an error is present */}
          {errors.length === 0 &&
            isReadyToConfirm &&
            confirms.map((alert) => {
              return (
                <div key={alert.message}>
                  {!alert.acknowledged && (
                    <FormValidationAlertToaster
                      formValidationAlertType={FormValidationAlertType.CONFIRM}
                      loadingAlerts={loadingAlerts}
                      setLoadingAlerts={setLoadingAlerts}
                      message={alert.message}
                      onCancel={cancelAlert}
                      onAcknowledge={acknowledgeAlert}
                    />
                  )}
                </div>
              )
            })}
        </div>
      </>
    )
  }

  return {
    isFormSubmitted,
    isSubmitDisabled,
    handleValidation,
    formAlerts,
    loadingAlerts,
    cancelAlert,
    acknowledgeAlert,
    isReadyToConfirm,
    FormValidationAlertToasters,
    isFormValidated,
  }
}

export default useFormValidation
