import * as React from 'react'
import { Helmet } from 'react-helmet'
import MedGEOlogo from 'assets/MedGEOlogo.svg'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box/Box'
import { TextField } from '@mui/material'
import styles from './login.module.css'
import Typography from '@mui/material/Typography/Typography'
import { Grid } from '@mui/material'

function MobileLogin() {
  const [email, setEmail] = React.useState<string>('')
  const [emailError, setEmailError] = React.useState<boolean>(false)

  const handleContinue = (e: any) => {
    e?.preventDefault()
    if (!email || !email.includes('@')) {
      setEmailError(true)
      return
    }

    // == Add logic to route based on email here ==
    // For example, this may pull in a JSON map of email suffixes
    // so if the email endsWith("chla.com") then subdomain = chla.medgeo.com
    // As a simple example, below we are routing based on if it's a staging username
    let subdomain = 'at'
    const [emailName] = email.split('@')
    if (emailName.endsWith('-s')) {
      subdomain = 'at-s'
    }

    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      window.location.replace(`https://${subdomain}.medgeo.com?email=${email}`)
    } else {
      window.location.replace(`/?subdomain=${subdomain}&email=${email}`)
    }
  }

  return (
    <Grid container direction="column">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <form onSubmit={handleContinue}>
        <Grid item>
          <Box className={styles.root} sx={{ backgroundColor: 'primary.main' }}>
            <PageDisplay>
              <img src={MedGEOlogo} alt="MedGEO logo" className={styles.logo} />
              <Typography
                variant="h1"
                className={styles.typography}
                sx={{ mt: 6, mx: 'auto' }}
              >
                Welcome to the MedGEO Platform!
              </Typography>
              <Typography
                className={styles.typography}
                sx={{ my: 1, mx: 'auto' }}
              >
                Please enter your email below to log in
              </Typography>
              <TextField
                style={{ marginTop: 24, background: 'white' }}
                label="Email"
                variant="filled"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                error={emailError}
                helperText={emailError ? 'Please enter a valid email' : ''}
              />
              <Button
                variant="contained"
                disableElevation
                onClick={handleContinue}
                sx={{
                  my: 5,
                  color: 'primary.main',
                  backgroundColor: 'grayscale.lightest',
                  '&:hover': {
                    backgroundColor: 'primary.hover',
                  },
                }}
              >
                Continue
              </Button>
            </PageDisplay>
          </Box>
        </Grid>
      </form>
    </Grid>
  )
}

export default MobileLogin
