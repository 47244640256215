import { CustomIcon } from 'components/templates/CustomIcon'

type FilterIconProps = {
  active: boolean
  color?: string
}

export const FilterIcon = ({ active, color }: FilterIconProps) => (
  <CustomIcon active={active} color={color} width="24" height="24">
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="FilterListIcon"
    >
      <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
    </svg>
  </CustomIcon>
)
