import { useEffect, useMemo } from 'react'

// Components
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { FilterBar } from 'components/organisms/FilterBar/FilterBar'
import Header from 'components/molecules/Header/Header'

// MUI
import { Box } from '@mui/material'

// Services
import { useGetAssetReports } from 'lib/services/api/demo-reports-service/assetReports'

// Context
import { useReportFiltersContext } from 'lib/context/ReportFiltersContext/ReportFiltersContext'

// Types
import { DefaultFilterParams } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'

// Other
import dayjs from 'lib/dayjs'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { AssetTypeLabel, ImplantStatus } from 'common/types'
import { getAssetType } from 'lib/utils/getAssetType'
import {
  AssetReportOutput,
  AssetReportsInput,
  MappedAssets,
} from './AssetsReports.types'
import { getReadableImplantStatus } from 'lib/utils/getReadableImplantStatus'

const dateFormat = 'MMM DD YYYY'
const headCells: HeadCell[] = [
  {
    id: 'date',
    label: 'Date/Time',
    numeric: true,
  },
  {
    id: 'location',
    label: 'Location',
    numeric: false,
  },
  {
    id: 'caseId',
    label: 'Case ID',
    numeric: true,
  },
  {
    id: 'procedureType',
    label: 'Procedure Type',
    numeric: false,
  },
  {
    id: 'surgeon',
    label: 'Surgeon',
    numeric: false,
  },
  {
    id: 'manufacturer',
    label: 'Manufacturer',
    numeric: false,
  },
  {
    id: 'productDescription',
    label: 'Product Description',
    numeric: false,
  },
  {
    id: 'modelNumber',
    label: 'Model Number',
    numeric: false,
  },
  {
    id: 'lotSerial',
    label: 'Lot/Serial',
    numeric: false,
  },
  {
    id: 'expiration',
    label: 'Expiration',
    numeric: false,
  },
  {
    id: 'udi',
    label: 'UDI',
    numeric: false,
  },
  {
    id: 'disposition',
    label: 'Disposition',
    numeric: false,
  },
  {
    id: 'assetType',
    label: 'Product Type',
    numeric: false,
  },
  {
    id: 'itemCost',
    label: 'Item Cost',
    numeric: true,
  },
]

const AssetsReports = () => {
  const { beforeDate, afterDate, filters, setFilters, setReportData } =
    useReportFiltersContext()

  const queryParams = useMemo(() => {
    const assetFilters = (filters as DefaultFilterParams) || {}
    const params = {} as AssetReportsInput

    if (assetFilters?.patientName) params.patientName = assetFilters.patientName
    if (assetFilters?.surgeonName) params.surgeonName = assetFilters.surgeonName
    if (assetFilters?.procedureType)
      params.procedureType = assetFilters.procedureType
    if (afterDate)
      params.startDate = afterDate.format(
        'YYYY-MM-DD'
      ) as unknown as dayjs.Dayjs
    if (beforeDate)
      params.endDate = beforeDate.format('YYYY-MM-DD') as unknown as dayjs.Dayjs
    if (assetFilters?.deviceDescription)
      params.deviceDescription = assetFilters.deviceDescription
    if (assetFilters?.disposition)
      params.disposition =
        assetFilters.disposition.toUpperCase() as ImplantStatus
    if (assetFilters.assetType)
      params.assetType = getAssetType(assetFilters.assetType as AssetTypeLabel)
    if (assetFilters.department) params.location = assetFilters.department

    params.pageSize = 1000
    params.status = 'SUBMITTED'

    return params
  }, [filters, afterDate, beforeDate])

  const { data, isLoading, isError } = useGetAssetReports({
    pageSize: 100,
    ...queryParams,
  })

  const assets = data as AssetReportOutput[]

  useEffect(() => {
    setFilters({
      open: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (assets) {
      setReportData(assets)
    }
  }, [assets, setReportData])

  const mapped: MappedAssets[] = assets?.map((asset: AssetReportOutput) => {
    return {
      date: asset.createdAt
        ? dayjs(asset.createdAt).format('MMM DD YYYY hh:mm A')
        : '-',
      location: asset.surgery.visit?.location?.department ?? '-',
      caseId: asset.surgery.surgicalCase?.number ?? '-',
      procedureType: asset.surgery?.procedures?.[0]?.description ?? '-',
      surgeon:
        asset.surgeon?.firstName || asset.surgeon?.lastName
          ? `${asset.surgeon?.firstName} ${asset.surgeon?.lastName}`
          : '-',
      manufacturer: asset.companyName ?? '-',
      productDescription: asset.deviceDescription ?? '-',
      modelNumber: asset.versionModelNumber ?? '-',
      lotSerial:
        asset.assets?.[0]?.serialNumber || asset.lotBatch
          ? `${asset.assets?.[0]?.serialNumber || 'NA'} / ${
              asset.lotBatch || 'NA'
            }`
          : '-',
      expiration: asset?.expirationDate
        ? dayjs(asset.expirationDate).format(dateFormat)
        : '-',
      udi: asset.assets?.[0]?.udi ?? '-',
      disposition:
        (getReadableImplantStatus(
          asset.assets?.[0]?.implantStatus as ImplantStatus
        ) as ImplantStatus) ?? '-',
      assetType: getAssetTypeLabel(asset.assets?.[0]?.assetType) ?? '-',
      itemCost: asset.totalCost ?? 0,
    }
  })

  return (
    <Box>
      <Header title="Product report" parent="Usage" />
      <FilterBar
        renderDeviceDescription
        renderDisposition
        renderSurgeonFilter
        renderProcedureTypeFilter
        renderDatePicker
        renderAssetTypeFilter
        renderDepartmentFilter
        condenseButtons
        isLoading={isLoading}
      />
      <DataTable
        tableHeaders={headCells}
        tableRows={mapped || []}
        isLoading={isLoading}
        isErrored={isError}
      />
    </Box>
  )
}

export default AssetsReports
