/* eslint-disable no-restricted-imports */
import { useState, createContext, useContext, useMemo } from 'react'
import {
  useAuth0 as useAuth0source,
  Auth0Provider as Auth0ProviderSource,
  Auth0ContextInterface,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-react'
import { useQuery } from 'react-query'

import { auth0Config } from 'lib/config'
import { AppMetadata, AuthUser, UserMetadata } from 'common/types'

export type UpdateAuth0User = (user: {
  email?: string
  userMetadata?: UserMetadata
  appMetadata?: AppMetadata
}) => Promise<AuthUser>

interface Auth0EnhancedInterface extends Auth0ContextInterface {
  isSessionExpired: boolean
  getAccessToken: (
    options?: GetTokenSilentlyOptions | undefined
  ) => Promise<string>
  user?: AuthUser
  resetPassword: (email: string) => Promise<string>
  updateAuth0User: UpdateAuth0User
  orgDomains: string[]
}

export const PROVIDER_PROPS = {
  domain: auth0Config.domain!,
  clientId: auth0Config.clientId!,
  audience: auth0Config.audience!,
  organization: auth0Config.organization,
  redirectUri: auth0Config.redirectUri,
  cacheLocation: auth0Config.cacheLocation,
  useRefreshTokens: auth0Config.useRefreshTokens,
  scope: auth0Config.scope,
  login_hint: undefined,
}

export const getOrgId = (orgName: string) =>
  process.env[
    `REACT_APP_AUTH0_ORG_ID_${orgName?.toUpperCase().replaceAll('-', '_')}`
  ]

const Auth0EnhancedContext = createContext(
  ({} as Auth0EnhancedInterface) || undefined
)

function Auth0ProviderInner({ children }: { children: JSX.Element }) {
  const auth0 = useAuth0source()
  const [isSessionExpired, setIsSessionExpired] = useState(false)

  const orgDomains: Auth0EnhancedInterface['orgDomains'] = Object.keys(
    process.env
  )
    .filter((key: string) => key.startsWith('REACT_APP_AUTH0_ORG_ID_'))
    .map((key: string) =>
      key.substring(23, key.length).toLowerCase().replaceAll('_', '-')
    )

  async function getAccessToken(options?: GetTokenSilentlyOptions | undefined) {
    try {
      const token = await auth0.getAccessTokenSilently(options)
      return token
    } catch (e: any) {
      if (e?.error === 'login_required') {
        return setIsSessionExpired(true)
      }

      throw Error(e)
    }
  }

  async function resetPassword(email: string): Promise<string> {
    try {
      const body = {
        client_id: auth0Config.clientId,
        email: email,
        connection: 'Username-Password-Authentication',
      }

      const response = await (
        await fetch(
          `https://${auth0Config.domain}/dbconnections/change_password`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        )
      ).text()

      return response
    } catch (e: any) {
      throw Error(e)
    }
  }

  const updateAuth0User: UpdateAuth0User = async ({
    email,
    appMetadata = {},
    userMetadata = {},
  }) => {
    try {
      const accessToken = await getAccessToken()

      const userDetailsByIdUrl = `${auth0Config.audience}users/${
        auth0.user!.sub
      }`

      const body: {
        email?: string
        user_metadata: UserMetadata
        app_metadata: AppMetadata
      } = {
        user_metadata: userMetadata,
        app_metadata: appMetadata,
      }

      if (email) {
        body.email = email
      }

      const user = await (
        await fetch(userDetailsByIdUrl, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
      ).json()

      // Refetch ID token (auth0.user)
      await getAccessToken({
        ignoreCache: true,
      })

      return user
    } catch (e: any) {
      throw Error(e)
    }
  }

  // Call getAccessToken with react-query
  // This will automatically re-fetch tokens on window focus, etc.
  // A convenient/efficient way to track when refresh token is expired, vs waiting for getAccessToken to get called when the user is performing an action
  // This will also keep a token "active" while a user is using the app in focus
  useQuery(['accessToken'], () => getAccessToken(), {
    enabled: auth0.isAuthenticated,
    refetchInterval: 600000,
  })

  const user = useMemo(() => {
    if (!auth0.user) return auth0.user

    // Remove namespacing from user properties
    return Object.keys(auth0.user).reduce((acc, key) => {
      const parsedKey = key.replace(`${auth0Config.namespace}`, '')
      acc[parsedKey] = auth0.user![key]
      return acc
    }, {} as AuthUser)
  }, [auth0.user])

  const contextValue = {
    ...auth0,
    user,
    isSessionExpired,
    getAccessToken,
    resetPassword,
    updateAuth0User,
    orgDomains,
  } as Auth0EnhancedInterface

  return (
    <Auth0EnhancedContext.Provider value={contextValue}>
      {children}
    </Auth0EnhancedContext.Provider>
  )
}

function Auth0Provider({
  children,
  orgId,
  email,
}: {
  children: JSX.Element
  orgId?: string
  email?: string
}) {
  const providerProps = { ...PROVIDER_PROPS }
  if (orgId) {
    providerProps.organization = orgId
  }
  if (email) {
    // @ts-ignore
    providerProps.login_hint = email
  }
  return (
    <Auth0ProviderSource {...providerProps}>
      <Auth0ProviderInner>{children}</Auth0ProviderInner>
    </Auth0ProviderSource>
  )
}
function useAuth0() {
  const context = useContext(Auth0EnhancedContext)

  if (context === undefined) {
    throw new Error('useAuth0 must be used with Auth0Provider')
  }

  return context
}

export { useAuth0, Auth0Provider }
