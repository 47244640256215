import { useAuth0 } from 'app/Auth'
import axios from 'axios'
import { useMutation } from 'react-query'

interface DeletePrefCardParams {
  id: string
}

interface DeletePrefCardResponse {
  message: string
}

export const useDeletePrefCard = () => {
  const { getAccessToken } = useAuth0()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL

  const mutation = useMutation<
    DeletePrefCardResponse,
    Error,
    DeletePrefCardParams
  >(async (data: DeletePrefCardParams) => {
    const token = await getAccessToken()
    try {
      const response = await axios.delete(
        `${REPORTS_URL}api/v1/preference_cards/${data.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  })

  return mutation
}
