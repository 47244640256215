/**
 * Enum Utils
 * @param key_or_val - key or value within the enum that you want to search by
 * @param entries - Object.entries(NameOfEnum) - will be two-by-two array
 * @returns
 */

export const getEnumKeyByKey = (key: string, entries: string[][]) => {
  let res = entries.find(([k, v]) => key === k)
  if (res) {
    return res[0]
  } else {
    throw new Error(
      `No enum key found matching '${key}' within the following entries: ${entries}`
    )
  }
}

export const getEnumKeyByValue = (val: string, entries: string[][]) => {
  let res = entries.find(([k, v]) => val === v)
  if (res) {
    return res[0]
  } else {
    throw new Error(
      `No enum value found matching '${val}' within the following entries: ${entries}`
    )
  }
}

// This isn't really necessary given how enums work but just here for consistency
export const getEnumValueByKey = (key: string, entries: string[][]) => {
  let res = entries.find(([k, v]) => key === k)
  if (res) {
    return res[1]
  } else {
    throw new Error(
      `No enum key found matching '${key}' within the following entries: ${entries}`
    )
  }
}

export const getEnumValueByValue = (val: string, entries: string[][]) => {
  let res = entries.find(([k, v]) => val === v)
  if (res) {
    return res[1]
  } else {
    throw new Error(
      `No enum value found matching '${val}' within the following entries: ${entries}`
    )
  }
}
