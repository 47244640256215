// useLogic.ts
import { useSendProductRepInviteMutation } from 'lib/apollo/hooks'
import { useGetBidCompanyId } from 'lib/utils/getBidCompanyId'
import {
  ValidateFormResponse,
  signUpRepSchema,
  validateForm,
} from 'validations'
import { FormEvent, useEffect, useState } from 'react'
import { RepSignUpFormData, RepSignUpFormProps } from './repSignupForm.types'

const initialFormData: RepSignUpFormData = {
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  companyName: '',
  bidCompanyId: null,
}

const formatPhoneNumber = (value: string) => {
  if (!value) {
    return value
  }

  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) {
    return phoneNumber
  }

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

const formatForSubmission = (phone: string) => {
  const cleaned = phone.replace(/[()\s-]/g, '')

  return `+1${cleaned}`
}

export const useRepSignupFormLogic = (
  surgeryId: RepSignUpFormProps['surgeryId']
) => {
  const [formData, setFormData] = useState<RepSignUpFormData>(initialFormData)
  const [formErrors, setFormErrors] = useState<ValidateFormResponse>()
  const [canAddAsset, setCanAddAsset] = useState(false)

  const [
    sendProductRepInviteMutation,
    {
      data: productRepInviteMutationData,
      loading: productRepInviteMutationIsLoading,
      error: productRepInviteMutationError,
    },
  ] = useSendProductRepInviteMutation()

  const bidCompanyResult = useGetBidCompanyId({
    companyNameInput: formData.companyName,
  })

  bidCompanyResult
    ? (formData.bidCompanyId = bidCompanyResult)
    : (formData.bidCompanyId = null)

  const isSubmitDisabled =
    productRepInviteMutationData?.sendProductRepInvite.success ||
    formData.companyName === '' ||
    formData.email === '' ||
    formData.firstName === '' ||
    formData.lastName === '' ||
    formData.mobile === ''

  const updateFormData = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleMobileInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const input = e.target.value.replace(/\D/g, '')

    updateFormData(e.target.name, formatPhoneNumber(input))
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    validateForm({
      formData,
      schema: signUpRepSchema,
    }).then((errors) => {
      if (errors) {
        setFormErrors(errors)
        return
      }
      sendProductRepInviteMutation({
        variables: {
          email: formData.email,
          bidCompanyId: formData.bidCompanyId,
          surgeryId,
          accessLevel: canAddAsset ? 1 : 0,
          firstName: formData.firstName,
          lastName: formData.lastName,
          mobile: formatForSubmission(formData.mobile),
          companyName: formData.companyName,
        },
        onCompleted: (data) => {
          if (!data.sendProductRepInvite.success) {
            setFormErrors({
              email: data.sendProductRepInvite.message,
            })
          }
        },
      })
    })
  }

  useEffect(() => {
    setFormErrors(null)
  }, [formData])

  return {
    formData,
    formErrors,
    canAddAsset,
    productRepInviteMutationData,
    productRepInviteMutationIsLoading,
    productRepInviteMutationError,
    isSubmitDisabled,
    setCanAddAsset,
    handleSubmit,
    updateFormData,
    handleMobileInputChange,
  }
}
