import { AccessAlarm } from '@mui/icons-material'
import { Box, Stack, TextField, Typography } from '@mui/material'
import Modal from 'components/molecules/Modal/Modal'
import './ConfirmCountdownDurationModal.scss'
import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'
import { LoadingButton } from '@mui/lab'
import useConfirmCountdownDurationModal from './ConfirmCountdownDurationModal.logic'

interface ConfirmCountdownDurationModalProps {
  open: boolean
  onClose: () => void
}

const ConfirmCountdownDurationModal = ({
  open,
  onClose,
}: ConfirmCountdownDurationModalProps) => {
  const { count } = useSPD()
  const { handleChangeCount, resetCount } = useConfirmCountdownDurationModal()

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="confirmation-countdown_modal">
        <Box className="header-box">
          <AccessAlarm />

          <Typography variant="h3">
            Please confirm SPD capture countdown duration
          </Typography>
        </Box>
        <TextField
          type="number"
          value={count}
          onChange={handleChangeCount}
          sx={{ mt: '20px' }}
        />

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt="20px"
        >
          <LoadingButton
            variant="outlined"
            disabled={false}
            loading={false}
            onClick={resetCount}
          >
            Close
          </LoadingButton>

          <LoadingButton
            disabled={false}
            loading={false}
            variant="contained"
            onClick={onClose}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ConfirmCountdownDurationModal
