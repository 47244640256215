import { Toast, Renderable } from 'react-hot-toast'
import { Typography, Box } from '@mui/material'
import { ScanDisplayData } from 'common/types'

interface BatchScanToastProps {
  toast: Toast
  icon: Renderable
  message: Renderable
  scan?: ScanDisplayData
}

export function BatchScanToast({
  toast,
  icon,
  message,
  scan,
}: BatchScanToastProps) {
  if (toast.type === 'success' && scan) {
    const title = scan.deviceDescription || scan.deviceId || scan.serialNumber
    return (
      <Box display="flex" alignItems="center">
        {icon}
        <Box>
          {message}
          <Box p={1}>
            <Typography variant="h5" color="theme.palette.primary.main">
              {title}
            </Typography>
            {scan.udi && (
              <Typography variant="body2" color="text.secondary" fontSize={12}>
                {scan.udi}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    )
  }
  return (
    <>
      {icon}
      {message}
    </>
  )
}
