import Modal from 'components/molecules/Modal/Modal'

import { ConfirmScrewRemovalModalProps } from './ConfirmScrewRemovalModal.types'
import './ConfirmScrewRemovalModal.scss'
import { Typography } from '@mui/material'
import { Handyman } from '@mui/icons-material'
import { LoadingButton } from 'components/mui'

export const ConfirmScrewRemovalModal = ({
  open,
  onClose,
  screw,
  handleRemoveScrew,
}: ConfirmScrewRemovalModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="confirm-screw-removal_modal">
        <div className="header-box">
          <Handyman />

          <Typography variant="h3">
            Are you sure you want to remove the following screw?
          </Typography>
        </div>

        <Typography variant="body1">{screw?.deviceDescription}</Typography>

        <Typography variant="body2">- Section: {screw?.label}</Typography>

        <Typography variant="body2">- Row: {screw?.row}</Typography>

        <Typography variant="body2">- Size: {screw?.column}</Typography>

        <div className="buttons-container">
          <LoadingButton
            disabled={false}
            loading={false}
            onClick={onClose}
            variant="outlined"
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            mode="delete"
            variant="contained"
            disabled={false}
            loading={false}
            onClick={handleRemoveScrew}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}
