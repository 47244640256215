import { DataCard } from 'components/molecules/DataCard'
import { LinearProgressBar } from 'components/molecules/LinearProgressBar'

interface LinearProgressBarCardProps {
  primaryData: string | number
  primaryDataCaption: string
  completedValue: number
  totalValue: number
  valueBuffer: number
  valueKey?: string
  valueBufferKey?: string
}

export const LinearProgressBarCard = ({
  primaryData,
  primaryDataCaption,
  valueBuffer,
  valueKey,
  valueBufferKey,
  completedValue,
  totalValue,
}: LinearProgressBarCardProps) => {
  return (
    <DataCard
      aria-label="linear-progress-bar-card"
      primaryData={primaryData}
      primaryDataCaption={primaryDataCaption}
    >
      <LinearProgressBar
        totalValue={totalValue}
        completedValue={completedValue}
        remainingValue={primaryData as number}
        valueBuffer={valueBuffer}
        label="end"
        valueKey={valueKey}
        valueBufferKey={valueBufferKey}
      />
    </DataCard>
  )
}
