// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'
// import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
// dayjs.extend(quarterOfYear)

export const baseDateFormat = 'YYYYMMDD'
export const humanizedDateFormat = 'YY/MM/DD'
export const humanizedFullYearDateFormat = 'YYYY/MM/DD'
export const humanizedMonthDateTimeFormat = 'MMMM D, h:mm A'

export default dayjs
