// Router
import { useNavigate } from 'react-router'

// Service
import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'

// Context
import { useReceiving } from './receiveContext'

export const useLogic = () => {
  const navigate = useNavigate()
  const { modal, setModal } = useReceiving()
  const { data, loading: isLoading } = useGetLocationsTree()
  const locations = data?.getLocationsTree.hospital.locations

  return {
    processModalState: modal,
    setModal,
    navigate,
    data: locations,
    locations,
    isLoading,
  }
}
