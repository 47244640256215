import { useEffect, useRef, useState } from 'react'
import { useSPDCortexScannerContext } from './SPDCortexScanner.context'

const useSPDCortexScanner = ({
  onScan,
}: {
  onScan: (code: string) => void
}) => {
  const { selectedCamera, mediaStream } = useSPDCortexScannerContext()

  const [currentScan, setCurrentScan] = useState<
    CortexDecoder.CDResult | undefined
  >()
  const isMounted = useRef(true)

  useEffect(() => {
    window.cortexScanner?.startDecoding()

    return () => {
      isMounted.current = true
    }
  }, [selectedCamera, mediaStream])

  const handleScan = (newScan: CortexDecoder.CDResult) => {
    if (isMounted.current) {
      onScan(newScan.barcodeData)
    } else {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.warn('read a barcode but component was not mounted')
      }
    }
  }

  return {
    isMounted,
    handleScan,
    currentScan,
    setCurrentScan,
  }
}

export default useSPDCortexScanner
