import { Typography } from '@mui/material'

import { useAddTraySelectType } from './AddTraySelectType.logic'

import { trayTypes } from './AddTraySelectType.data'
import './AddTraySelectType.scss'

export const AddTraySelectType = () => {
  const { handleTypeSelection } = useAddTraySelectType()

  return (
    <div className="add-tray-select-type_container">
      <Typography variant="h2" className="title">
        Select Tray Type:
      </Typography>

      {trayTypes.map((trayType) => (
        <div
          key={trayType.category}
          className="tray-type"
          onClick={() => handleTypeSelection(trayType.category)}
        >
          <img src={trayType.icon} alt={trayType.label} />

          <Typography variant="body2">{trayType.label}</Typography>
        </div>
      ))}
    </div>
  )
}
