import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Create, ReadMore } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'

import { HeadCell } from 'components/molecules/DataTable/TableHeader'

import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'

import { MappedTrays } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.types'
import { InventoryLocation } from 'views/LocationManagement/LocationManagement.types'
import { ReactComponent as TrayIcon } from 'assets/icons/svgs/inbox.svg'
import {
  TrayStatus,
  trayStatusDisplayMap,
} from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { SPDContextProps } from 'views/SPDLayout/SPD/SPD.types'
import { ItemResponse } from 'common/types'
import { formatSurgeryProviderName } from 'lib/utils/formatSurgeryProviderName'

/**
 * Custom hook for managing tray logic in a tray management feature.
 * Provides state and functions to handle tray operations.
 * @returns Object containing state variables and functions for tray management.
 */
const useTrayManagementLogic = () => {
  const navigate = useNavigate()
  const { data, loading: isLoadingLocations } = useGetLocationsTree()
  const {
    setSelectedTrayCategory,
    setSelectedTray,
    selectedTray,
    isListOfSurgeriesOpen,
    setIsListOfSurgeriesOpen,
    isAddTrayModalOpen,
    setIsAddTrayModalOpen,
  } = useTrayConfigurationContext()

  const [locations, setLocations] = useState<InventoryLocation[]>([])
  const [locationsWithParents, setLocationsWithParents] = useState<
    InventoryLocation[]
  >([])
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false)

  /**
   * Defines the header cells for the tray management table.
   */
  const trayTableHeaderCells: HeadCell[] = [
    { id: 'trayIcon', label: '', numeric: false },
    { id: 'trayID', label: 'Tray Id', numeric: false },
    { id: 'vendor', label: 'Vendor', numeric: false },
    { id: 'surgeon', label: 'Surgeon', numeric: false },
    { id: 'case', label: 'Case', numeric: false },
    { id: 'status', label: 'Status', numeric: false },
    { id: 'location', label: 'Location', numeric: false },
    { id: 'trayDetails', label: 'Tray Details', numeric: false },
  ]

  /**
   * Maps tray data into structured cells for the tray management table body.
   * @param trays Array of tray items to be mapped into table rows.
   * @returns Mapped tray data formatted for table display.
   */
  const trayTableBodyCells = (trays: ItemResponse[]): MappedTrays[] => {
    return trays.map((tray) => {
      const trayDetails = extractTrayJSONData(tray)
      const surgery = trayDetails?.surgeryDetails
      const hasCaseNumber = surgery?.surgicalCase?.number
      const caseDisplayText = hasCaseNumber
        ? `CN-${surgery?.surgicalCase?.number}`
        : surgery?._id || 'Unassigned'

      return {
        trayIcon: (
          <div
            className={`tray-icon-svg_container ${trayDetails?.trayCategory}`}
          >
            <TrayIcon className="tray-icon_svg" />
          </div>
        ),
        trayId: `${tray.barcode}`,
        vendor: `${tray.companyName}`,
        surgeon:
          surgery && surgery.visit?.attendingProvider
            ? formatSurgeryProviderName(surgery)
            : 'Unassigned',
        case: (
          <div className="case-number_container">
            <Button onClick={() => handleAssignToSurgeryClick(tray)}>
              <Create />
            </Button>

            <Typography variant="body2">{caseDisplayText}</Typography>
          </div>
        ),
        status: getTrayStatusDisplay(trayDetails?.status),
        location: isLoadingLocations
          ? 'Loading...'
          : `${findLocation(tray?.locationId as number)?.name}`,
        trayDetails: (
          <Button onClick={() => handleViewDetailsClick(tray)}>
            <ReadMore />
          </Button>
        ),
      }
    })
  }

  /**
   * Effect to update locations state when data changes.
   */
  useEffect(() => {
    if (data) {
      const { hospital } = data.getLocationsTree
      const allLocations = hospital.locations
      const locationsWithParents = allLocations.filter(
        (location) => location.parentLocationId
      )

      setLocationsWithParents(locationsWithParents)
      setLocations(allLocations)
    }
  }, [data])

  /**
   * Effect to open details modal when selected tray changes.
   */
  useEffect(() => {
    if (selectedTray && !isListOfSurgeriesOpen) {
      setIsDetailsModalOpen(true)
    }
  }, [selectedTray, isListOfSurgeriesOpen])

  /**
   * Opens the modal for adding a new tray.
   */
  const handleAddTrayModalOpen = () => {
    setIsAddTrayModalOpen(true)
  }

  /**
   * Redirects user to the add tray page.
   */
  const handleAddTrayClick = () => {
    navigate('/tray-management/add-tray')
  }

  /**
   * Closes the modal for adding a new tray.
   */
  const handleAddTrayModalClose = () => {
    setSelectedTrayCategory(null)
    setIsAddTrayModalOpen(false)
  }

  /**
   * Finds a location by its ID.
   * @param locationId The ID of the location to find.
   * @returns The location object if found, otherwise undefined.
   */
  const findLocation = (locationId: number): InventoryLocation | undefined => {
    return locations.find((location) => location.id === locationId)
  }

  /**
   * Closes the details modal for the selected tray.
   */
  const handleDetailsModalClose = () => {
    setSelectedTray(null)
    setIsDetailsModalOpen(false)
  }

  /**
   * Opens the list of surgeries modal and sets the selected tray.
   * @param selectedTray The tray to be assigned to a surgery.
   */
  const handleAssignToSurgeryClick = (tray: ItemResponse) => {
    const spdTray: SPDContextProps['trayData'] = {
      trayID: `${tray.id}`,
      trayItem: tray,
    }

    setIsListOfSurgeriesOpen(true)
    setIsDetailsModalOpen(false)
    setSelectedTray(spdTray)
  }

  /**
   * Closes the list of surgeries modal.
   */
  const handleListOfSurgeriesClose = () => {
    setIsListOfSurgeriesOpen(false)
    setSelectedTray(null)
  }

  /**
   * Navigates to view details of the selected tray.
   * @param tray The tray item for which details are to be viewed.
   */
  const handleViewDetailsClick = (tray: ItemResponse) => {
    navigate(`./${tray.barcode}`)
  }

  /**
   * Retrieves the display name for a given tray status.
   * @param trayStatus The status of the tray.
   * @returns The display name of the tray status.
   */
  const getTrayStatusDisplay = (trayStatus: TrayStatus): string => {
    if (trayStatus === undefined) {
      return 'Unknown Status'
    }
    return trayStatusDisplayMap[trayStatus] ?? 'Unknown Status'
  }

  return {
    isAddTrayModalOpen,
    setIsAddTrayModalOpen,
    handleAddTrayModalOpen,
    handleAddTrayModalClose,
    locations,
    locationsWithParents,
    isLoadingLocations,
    findLocation,
    isDetailsModalOpen,
    setIsDetailsModalOpen,
    handleDetailsModalClose,
    handleAssignToSurgeryClick,
    handleListOfSurgeriesClose,
    trayTableBodyCells,
    trayTableHeaderCells,
    getTrayStatusDisplay,
    handleAddTrayClick,
  }
}

export default useTrayManagementLogic
