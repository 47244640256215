import { ChangeEvent, useEffect, useState } from 'react'
import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'
import { useGetTrayItemByBarcode } from 'lib/apollo/hooks'
import { BetterIdResult } from 'common/types'
import { useNavigate } from 'react-router-dom'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'

const useSelectTrayIDModal = ({
  shouldBeFacilityOwned,
}: {
  shouldBeFacilityOwned: boolean
}) => {
  const navigate = useNavigate()

  const { setTrayData, setTrayScrews } = useSPD()
  const { loading, error, trayItem, getTrayItem } = useGetTrayItemByBarcode()

  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [scannedCode, setScannedCode] = useState<string>('')
  const [productData, setProductData] = useState<BetterIdResult[]>([])

  const trayDetails: TrayItemProductDetails | undefined = trayItem
    ? (JSON.parse(trayItem.productDetails as string) as TrayItemProductDetails)
    : undefined

  const trayCategory =
    trayDetails?.trayCategory === 'consigned'
      ? 'Consigned'
      : trayDetails?.trayCategory === 'loaner'
      ? 'Loaner'
      : trayDetails?.trayCategory === 'facility'
      ? 'Facility Owned Tray'
      : 'Tray Category Unassigned'

  const shouldBeDisabled = shouldBeFacilityOwned
    ? trayCategory !== 'Facility Owned Tray'
    : trayCategory === 'Facility Owned Tray'

  const handleGetTrayItem = (barcode: string) => {
    getTrayItem({ variables: { barcode } })
  }

  useEffect(() => {
    if (scannedCode.length > 0) {
      handleGetTrayItem(scannedCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedCode])

  const handleTrayIDChange = (e: ChangeEvent<HTMLInputElement>) => {
    const scannedCodeValue = e.target.value

    setScannedCode(scannedCodeValue)
  }

  const handleConfirmTray = () => {
    if (scannedCode.length > 0) {
      const trayItemScrews = JSON.parse(
        trayItem.productDetails as string
      )?.screws

      setTrayScrews(trayItemScrews)
      setTrayData({
        trayID: scannedCode,
        trayItem,
      })
      navigate('./scan')
    } else {
      alert('You have not entered or captured a tray ID.')
      throw new Error('You have not entered or captured a tray ID.')
    }
  }

  return {
    handleTrayIDChange,
    loading,
    error,
    isComplete,
    setIsComplete,
    productData,
    setProductData,
    scannedCode,
    setScannedCode,
    trayItem,
    handleConfirmTray,
    trayDetails,
    trayCategory,
    shouldBeDisabled,
    shouldBeFacilityOwned,
  }
}

export default useSelectTrayIDModal
