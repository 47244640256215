import { useApolloClient } from '@apollo/client'
import { Box, Button, Typography } from '@mui/material'
import {
  AssetType,
  DispositionData,
  dispositionSchema,
} from 'common/disposition'
import {
  UndocumentedScan,
  ScanState,
  SelectedScan,
  AssetData,
  BatchAddAssetsToSurgeryResponse,
} from 'common/types'
import { ListEmptyState } from 'components/ListEmptyState'
import { UndocumentedScanCard } from 'components/UndocumentedScanCard'
import { GET_SURGERY_QUERY } from 'lib/apollo/schema'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import _ from 'lodash'
import { useState, useEffect, useCallback } from 'react'
import toast from 'react-hot-toast'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { UndocumentedListLogicProps } from './UndocumentedList.types'
import { useRfSpongeCount } from 'lib/context/RfSpongeCountContext/RfSpongeCountContext'
import { BatchDispositionModalLogicProps } from 'components/BatchDispositionModal/BatchDispositionModal.types'

export const useUndocumentedListLogic = ({
  surgeryId,
  batchAddAssetsToSurgery,
}: UndocumentedListLogicProps) => {
  // --------------------  Utils --------------------
  const client = useApolloClient()
  const { undocumentedScans, setUndocumentedScans } =
    useUndocumentedAssetsContext()
  const { matchToAssetGroup, addAssetGroupsCount } = useRfSpongeCount()

  // --------------------  States --------------------
  const [uploadedMedia, setUploadedMedia] = useState<string[]>([])
  const [isDisposeOpen, setIsDisposeOpen] = useState(false)
  const [isAssociatedModalOpen, setIsAssociatedModalOpen] = useState(false)
  const [scanStateMap, setScanStateMap] = useState<Record<string, ScanState>>(
    (undocumentedScans || []).reduce(
      (acc: Record<string, ScanState>, scan: UndocumentedScan) => {
        acc[scan._id] = { isSelected: false }
        acc[scan._id] = { isAssociatedProduct: false }
        return acc
      },
      {}
    )
  )

  // Array for storing the undocumentedScans that are coming from DTM
  const [dtmScrewAssets, setDTMScrewAssets] = useState<UndocumentedScan[]>([])
  const areThereDTMScrews = dtmScrewAssets.length > 0

  const filterDTMAssets = (isWasted: boolean) => {
    return dtmScrewAssets.filter((dtmScrewAsset) =>
      isWasted
        ? dtmScrewAsset.dtmScrewData?.wasted
        : !dtmScrewAsset.dtmScrewData?.wasted
    )
  }

  const areAllAssetsSelected = (assets: UndocumentedScan[]) => {
    if (assets.length === 0) {
      return false
    }

    return assets.every((asset) =>
      Object.keys(scanStateMap).some(
        (scanId) => scanId === asset._id && scanStateMap[scanId].isSelected
      )
    )
  }

  const wastedDTMScrews = filterDTMAssets(true)
  const implantedDTMScrews = filterDTMAssets(false)
  const areAllDTMImplantedSelected = areAllAssetsSelected(implantedDTMScrews)
  const areAllDTMWastedSelected = areAllAssetsSelected(wastedDTMScrews)

  // --------------------  Constants --------------------
  const scanMap = undocumentedScans?.reduce(
    (acc: Record<string, UndocumentedScan>, scan: UndocumentedScan) => {
      acc[scan._id] = scan
      return acc
    },
    {}
  )

  const selectedScans: SelectedScan[] = Object.entries(scanStateMap)
    .filter(([_, s]) => s?.isSelected)
    .map(([scanId, _]) => ({ ...scanMap[scanId], ...scanStateMap[scanId] }))

  const filterByAssetType = (
    scans: UndocumentedScan[],
    assetType: AssetType
  ) => {
    return scans?.filter(
      (scan: UndocumentedScan) => scan.assetType === assetType
    )
  }

  const areAllSelected = (
    assets: UndocumentedScan[],
    stateMap: Record<string, ScanState>
  ) => {
    return (
      assets?.length > 0 &&
      assets.every((scan: UndocumentedScan) => stateMap[scan._id]?.isSelected)
    )
  }

  const hardwareAssets = filterByAssetType(undocumentedScans, 'non-biological')
  const consumableAssets = filterByAssetType(undocumentedScans, 'consumable')
  const otherAssets = filterByAssetType(
    undocumentedScans,
    'other-non-biological'
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let associatedProducts = [] as UndocumentedScan[]
  undocumentedScans?.map((scan: UndocumentedScan) => {
    const { isAssociatedProduct } = parseAssetIdentifiers({
      deviceDescription: scan.deviceDescription,
      deviceCount: scan.deviceCount,
      idType: scan.secondaryDeviceIdType,
      assetType: scan.assetType,
      gmdnPTDefinition: scan.gmdnPTDefinition,
    })

    if (isAssociatedProduct) {
      associatedProducts.push(scan)
    }
    return associatedProducts
  })

  const isAssetTypeSelected = (assetType: AssetType) => {
    return undocumentedScans?.some(
      (scan: UndocumentedScan) =>
        scan.assetType === assetType && scanStateMap[scan._id]?.isSelected
    )
  }

  const isConsumableSelected = isAssetTypeSelected('consumable')
  const isHardwareSelected = isAssetTypeSelected('non-biological')
  const isOtherSelected = isAssetTypeSelected('other-non-biological')
  const isAssociatedSelected = useCallback(() => {
    return associatedProducts.some(
      (scan: UndocumentedScan) => scanStateMap[scan._id]?.isAssociatedProduct
    )
  }, [associatedProducts, scanStateMap])
  const isNonAssociatedHardwareSelected = hardwareAssets?.some(
    (hardwareScan: UndocumentedScan) => {
      const { isAssociatedProduct } = parseAssetIdentifiers({
        deviceDescription: hardwareScan.deviceDescription,
        deviceCount: hardwareScan.deviceCount,
        idType: hardwareScan.secondaryDeviceIdType,
        assetType: hardwareScan.assetType,
        gmdnPTDefinition: hardwareScan.gmdnPTDefinition,
      })

      return scanStateMap[hardwareScan._id]?.isSelected && !isAssociatedProduct
    }
  )

  const selectedCount = selectedScans.length
  const isAllConsumableSelected = areAllSelected(consumableAssets, scanStateMap)
  const isAnySelected = selectedCount > 0

  // --------------------  Component Lifestyle Methods --------------------
  useEffect(() => {
    setScanStateMap((s: Record<string, ScanState>) => {
      const newScanStateMap = undocumentedScans.reduce(
        (acc: Record<string, ScanState>, scan: UndocumentedScan) => ({
          ...acc,
          [scan._id]: {
            isSelected: s[scan._id]?.isSelected ?? false,
          },
        }),
        {}
      )
      return newScanStateMap
    })
  }, [undocumentedScans])

  // set all associated products as selected on modal mount
  useEffect(() => {
    if (associatedProducts.length > 0) {
      associatedProducts.forEach((scan: UndocumentedScan) => {
        setScanStateMap((prevState) => ({
          ...prevState,
          [scan._id]: {
            isSelected: true,
            isAssociatedProduct: true,
          },
        }))
      })
      setIsAssociatedModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get all of the undocumented scans that came from the DTM usage workflow
  useEffect(() => {
    const getDTMScrewAssets = () => {
      const dtmAssets = undocumentedScans.filter((scan) => scan.isDTMScrew)

      setDTMScrewAssets(dtmAssets)
    }

    getDTMScrewAssets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undocumentedScans.length])

  // --------------------  Handles --------------------
  const toggleSelection = (scanId: string) => {
    setScanStateMap((s) => ({
      ...s,
      [scanId]: {
        ...s[scanId],
        isSelected: (!s[scanId]?.isSelected as boolean) ?? false,
      },
    }))
  }

  const deleteScansLocal = (scanIds: string[]) => {
    setScanStateMap((prevStateMap) => {
      const newStateMap = { ...prevStateMap }

      scanIds.forEach((scanId) => {
        delete newStateMap[scanId]
      })

      return newStateMap
    })

    setUndocumentedScans((prevScans) =>
      prevScans.filter((scan) => !scanIds.includes(scan._id))
    )
  }

  const handleToggleHardware = () => {
    setScanStateMap((s) => toggleAssetTypeSelection(s, 'non-biological'))
  }

  const handleToggleDTMHardware = (
    dispositionStatus: 'wasted' | 'implanted'
  ) => {
    setScanStateMap((s) =>
      toggleDTMSelection(s, 'non-biological', dispositionStatus)
    )
  }

  const handleToggleConsumable = () => {
    setScanStateMap((s) => toggleAssetTypeSelection(s, 'consumable'))
  }

  const handleToggleOther = () => {
    setScanStateMap((s) => toggleOtherSelection(s))
  }

  const submitDisposition = (
    data: DispositionData,
    assetGroupMatch?: AssetData
  ) => {
    let selected = [] as UndocumentedScan[]
    if (isAssociatedModalOpen) {
      selectedScans.filter((scan: UndocumentedScan) => {
        const isAssociatedProduct = associatedProducts.some(
          (associatedProduct: UndocumentedScan) =>
            associatedProduct._id === scan._id
        )
        if (isAssociatedProduct) {
          selected.push(scan)
        }
        return selected
      })
      const scans = selected.map((scan: UndocumentedScan) => ({
        scanId: scan._id,
        media: uploadedMedia,
        ..._.omit(scan, [
          'isSelected',
          'isExpireAccepted',
          'assetType',
          'user',
          'createdAt',
          'updatedAt',
          '__typename',
          '_id',
          'isAssociatedProduct',
        ]),
        dtmScrewData: scan.dtmScrewData
          ? _.omit(scan.dtmScrewData, ['__typename'])
          : undefined,
      }))

      deleteScansLocal(selectedScans.map((scan: UndocumentedScan) => scan._id))
      batchAddAssetsToSurgery({
        variables: {
          disposition: _.omit(data, 'count'),
          scans,
          deleteUndocumentedScansIds: selected.map(
            (scan: UndocumentedScan) => scan._id
          ),
        },
      })
        ?.then(
          (result: {
            data: { batchAddAssetsToSurgery: { addedScanIds: never[] } }
          }) => {
            setIsAssociatedModalOpen(false)

            if (isNonAssociatedHardwareSelected) {
              setIsDisposeOpen(true)
            }

            client.refetchQueries({
              include: [GET_SURGERY_QUERY],
            })
          }
        )
        .catch(() => {
          // NOTE: might want to handle this in the disposition modal
          toast.error('Failed to document and add assets to procedure')
        })
    } else {
      const scans = selectedScans.map((scan: UndocumentedScan) => ({
        scanId: scan._id,
        media: uploadedMedia,
        ..._.omit(scan, [
          'isSelected',
          'isExpireAccepted',
          'assetType',
          'user',
          'createdAt',
          'updatedAt',
          '__typename',
          '_id',
          'isAssociatedProduct',
        ]),
        dtmScrewData: scan.dtmScrewData
          ? _.omit(scan.dtmScrewData, ['__typename'])
          : undefined,
      }))

      deleteScansLocal(selectedScans.map((scan: UndocumentedScan) => scan._id))
      batchAddAssetsToSurgery({
        variables: {
          disposition: _.omit(data, 'count'),
          scans,
          deleteUndocumentedScansIds: selectedScans.map(
            (scan: UndocumentedScan) => scan._id
          ),
        },
      })
        .then((result: BatchAddAssetsToSurgeryResponse) => {
          if (isDisposeOpen) {
            setIsDisposeOpen(false)
          }

          if (assetGroupMatch && addAssetGroupsCount) {
            addAssetGroupsCount()
          }
          client.refetchQueries({
            include: [GET_SURGERY_QUERY],
          })
        })
        .catch(() => {
          // NOTE: might want to handle this in the disposition modal
          toast.error('Failed to document and add assets to procedure')
        })
        .finally(() => {
          setUploadedMedia([])
        })
    }
  }

  const handleConsumableSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    const assetGroupMatch =
      (matchToAssetGroup && matchToAssetGroup(selectedScans)) || undefined

    const result = dispositionSchema.safeParse({
      assetTray: undefined,
      assetType: 'consumable',
      implantStatus: undefined,
    })
    if (result.success) {
      submitDisposition(result.data, assetGroupMatch)
    }
  }

  const handleAssociatedSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const result = dispositionSchema.safeParse({
      assetTray: undefined,
      assetType: 'non-biological',
      implantStatus: 'ASSOCIATED_ASSET',
    })
    if (result.success) {
      submitDisposition(result.data)
    }
  }

  const toggleAssetTypeSelection = (
    stateMap: Record<string, ScanState>,
    assetType: string
  ) => {
    return Object.keys(stateMap).reduce((acc: Record<string, ScanState>, k) => {
      acc[k] = {
        ...stateMap[k],
        isSelected:
          scanMap[k].assetType === assetType
            ? !areAllSelected(
                filterByAssetType(undocumentedScans, assetType),
                stateMap
              )
            : false,
      }
      return acc
    }, {})
  }

  const toggleDTMSelection = (
    stateMap: Record<string, ScanState>,
    assetType: string,
    dispositionStatus: 'wasted' | 'implanted'
  ) => {
    return Object.keys(stateMap).reduce((acc: Record<string, ScanState>, k) => {
      const dtmAsset = dtmScrewAssets.find((dtmAsset) => dtmAsset._id === k)
      const shouldBeWasted = dispositionStatus === 'wasted'

      acc[k] = {
        ...stateMap[k],
        isSelected:
          scanMap[k].assetType === assetType && dtmAsset
            ? !stateMap[k].isSelected
              ? shouldBeWasted
                ? dtmAsset.dtmScrewData?.wasted
                : !dtmAsset.dtmScrewData?.wasted
              : false
            : false,
      }

      return acc
    }, {})
  }

  const getDispositionStatus =
    (): BatchDispositionModalLogicProps['dispositionStatus'] => {
      if (
        areAllDTMImplantedSelected &&
        areAllDTMWastedSelected &&
        implantedDTMScrews.length > 0
      ) {
        return 'IMPLANTED'
      }

      if (areAllDTMWastedSelected) {
        return 'WASTED'
      }
    }

  const toggleOtherSelection = (stateMap: Record<string, ScanState>) => {
    return Object.keys(stateMap).reduce((acc: Record<string, ScanState>, k) => {
      acc[k] = {
        ...stateMap[k],
        isSelected:
          scanMap[k].assetType !== 'non-biological' &&
          scanMap[k].assetType !== 'consumable'
            ? !areAllSelected(
                filterByAssetType(undocumentedScans, 'other-non-biological'),
                stateMap
              )
            : false,
      }
      return acc
    }, {})
  }

  const renderAssetCards = (
    assets: UndocumentedScan[],
    surgeryId: string,
    toggleSelection: (id: string) => void,
    isAssetTypeSelected: boolean
  ) =>
    assets.map((scan: UndocumentedScan) => {
      const isSelected = scanStateMap[scan._id]?.isSelected
      const isExpireAccepted = scanStateMap[scan._id]?.isExpireAccepted
      return (
        <UndocumentedScanCard
          key={scan._id}
          isManualAddition={scan?.isManualAddition}
          dataTestId={scan._id}
          surgeryId={surgeryId}
          onClick={() => toggleSelection(scan._id)}
          scan={scan}
          isExpireAccepted={isExpireAccepted}
          cardStyles={{
            my: 1,
            display: 'block',
            borderColor: isSelected ? 'success.main' : 'grey.300',
            bgcolor: isSelected ? 'success.lightest' : 'white',
          }}
          disabled={isAssetTypeSelected}
        />
      )
    })

  const AssetSection = ({
    title,
    assets,
    toggle,
    isSelected,
    surgeryId,
    toggleSelection,
    isAssetTypeSelected,
  }: any) => (
    <Box width="100%" my={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" data-testid={`${title.toLowerCase()}-header`}>
          {title}
        </Typography>
        <Button
          data-testid={`toggle-${title.toLowerCase()}-button`}
          onClick={toggle}
          size="small"
          sx={{ mt: 0.25 }}
        >
          {isSelected ? 'Unselect All' : 'Select All'}
        </Button>
      </Box>
      {renderAssetCards(
        assets,
        surgeryId,
        toggleSelection,
        isAssetTypeSelected
      )}
    </Box>
  )

  const renderAssetBox = (
    title: string,
    assets: UndocumentedScan[],
    handleToggle: () => void,
    isAssetTypeSelected: boolean
  ) => {
    const isHardWare = title.includes('Implantable Hardware/Associated Product')
    const nonDTMAssets = assets.filter((asset) => !asset.isDTMScrew)

    return (
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h2"
            data-testid={`${title.toLowerCase()}-header`}
          >
            {title}
          </Typography>
          <Button
            data-testid={`toggle-${title.toLowerCase()}-button`}
            onClick={handleToggle}
            size="small"
            sx={{ mt: 0.25 }}
          >
            {areAllAssetsSelected(assets) ? 'Unselect All' : 'Select All'}
          </Button>
        </Box>
        {renderAssetCards(
          nonDTMAssets,
          surgeryId,
          toggleSelection,
          isAssetTypeSelected
        )}
        {isHardWare && implantedDTMScrews.length > 0 && (
          <AssetSection
            title="DTM Implanted"
            assets={implantedDTMScrews}
            toggle={() => handleToggleDTMHardware('implanted')}
            isSelected={areAllDTMImplantedSelected}
            scanStateMap={scanStateMap}
            surgeryId={surgeryId}
            toggleSelection={toggleSelection}
            isAssetTypeSelected={isAssetTypeSelected}
          />
        )}
        {isHardWare && wastedDTMScrews.length > 0 && (
          <AssetSection
            title="DTM Wasted"
            assets={wastedDTMScrews}
            toggle={() => handleToggleDTMHardware('wasted')}
            isSelected={areAllDTMWastedSelected}
            scanStateMap={scanStateMap}
            surgeryId={surgeryId}
            toggleSelection={toggleSelection}
            isAssetTypeSelected={isAssetTypeSelected}
          />
        )}
      </Box>
    )
  }

  const renderEmptyState = (showEmptyState: boolean) => {
    if (showEmptyState) {
      return (
        <ListEmptyState
          containerStyles={{ my: 12 }}
          message={`Undocumented list is empty!\nProceed to Home page to capture a new product.\nNavigate to Documented list to view products ready for EMR submission.`}
        />
      )
    }
    return null
  }

  const handleSaveUploadedMedia = (media: string[]) => {
    setUploadedMedia([...uploadedMedia, ...media])
  }

  return {
    renderAssetBox,
    renderEmptyState,
    isDisposeOpen,
    setIsDisposeOpen,
    selectedScans,
    hardwareAssets,
    consumableAssets,
    otherAssets,
    isConsumableSelected,
    isHardwareSelected,
    isOtherSelected,
    isAllConsumableSelected,
    isAnySelected,
    submitDisposition,
    handleToggleHardware,
    handleToggleConsumable,
    handleToggleOther,
    handleConsumableSubmit,
    undocumentedScans,
    isAssociatedModalOpen,
    setIsAssociatedModalOpen,
    toggleSelection,
    scanStateMap,
    setScanStateMap,
    associatedProducts,
    isAssociatedSelected,
    handleAssociatedSubmit,
    isNonAssociatedHardwareSelected,
    dtmScrewAssets,
    areAllDTMImplantedSelected,
    areAllDTMWastedSelected,
    handleSaveUploadedMedia,
    areThereDTMScrews,
    getDispositionStatus,
  }
}
