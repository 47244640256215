import { Box, Grid, Typography, TableRowProps } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { deriveSurgeryStatus } from 'lib/utils/data'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import dayjs from 'lib/dayjs'
import { BreadcrumbHeader } from 'components/molecules/BreadcrumbHeader/BreadcrumbHeader'
import {
  AssetData,
  Patient,
  Procedures,
  Provider,
  Surgery,
  SurgicalCase,
  SurgicalStaff,
  Visit,
} from 'common/types'
import { AssetDetailsTable } from '../../../components/organisms/AssetDetailsTable/AssetDetailsTable'
import { LoadingButton } from 'components/mui'
import './ProcedureDetailsReport.scss'
import { TabPanel } from 'components/molecules/TablPanel/TabPanel'
import { ProcedureDetailsCard } from 'components/organisms/ProcedureDetailsCard/ProcedureDetailsCard'
import { PatientDetailsCard } from 'components/organisms/PatientDetailsCard/PatientDetailsCard'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import useGetUsageReports from 'lib/services/api/demo-reports-service/usageReports/getUsageReports'
export interface SurgeryDetailsOutput {
  assetTrays: Surgery['assetTrays']
  visitDate: Visit['visitDateTime']
  surgicalCase: SurgicalCase
  patient: Patient
  totalSurgeryCost: Surgery['totalSurgeryCost']
  surgeryStaff: SurgicalStaff[]
  assetGroups: AssetData[]
  procedureType: Procedures['description']
  procedureDuration: Procedures['duration']
  status: Surgery['status']
  surgeon: Provider
}

export const ProcedureDetailsReport = () => {
  const { surgeryId } = useParams()

  const { getSurgeryDetails, surgeryDetails, isLoading, error } =
    useGetUsageReports()

  useEffect(() => {
    getSurgeryDetails(surgeryId as string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dateFormat = 'MMM DD, YYYY'

  const procedureDate: SurgeryDetailsOutput['visitDate'] = dayjs(
    surgeryDetails?.visitDate
  ).format('hh:mm A , MMM DD, YYYY')
  const caseNumber: SurgeryDetailsOutput['surgicalCase']['number'] =
    surgeryDetails?.surgicalCase?.number
  const procedureType: SurgeryDetailsOutput['procedureType'] =
    surgeryDetails?.procedureType
  const procedureDuration: SurgeryDetailsOutput['procedureDuration'] =
    surgeryDetails?.procedureDuration
  const surgeon: SurgeryDetailsOutput['surgeon']['lastName'] =
    surgeryDetails?.surgeon?.lastName

  const patientName = `${
    surgeryDetails?.patient?.lastName === undefined
      ? ''
      : surgeryDetails?.patient?.lastName
  }, ${
    surgeryDetails?.patient?.firstName === undefined
      ? ''
      : surgeryDetails?.patient?.firstName
  }`
  const patientDOB: Patient['dateOfBirth'] = dayjs(
    surgeryDetails?.patient?.dateOfBirth
  ).format(dateFormat)
  const patientMRN: Patient['idMR'] = surgeryDetails?.patient?.idMR

  const derivedSurgeryStatus = useMemo(
    () =>
      deriveSurgeryStatus(
        surgeryDetails?.status?.name,
        _.uniq(surgeryDetails?.assetGroups)
      ),
    [surgeryDetails?.assetGroups, surgeryDetails?.status?.name]
  )

  const {
    totalHardwareCount,
    totalImplantableBiologicCount,
    totalImplantableOtherCount,
    totalConsumableCount,
    totalExplantedCount,
    totalAssociatedCount,
    totalHardwareCost,
    totalExplantedCost,
    totalConsumableCost,
    totalAssociatedCost,
    totalImplantableBiologicCost,
    totalImplantableOtherCost,
  } = derivedSurgeryStatus

  const headCells: HeadCell[] = [
    {
      id: 'assetType',
      label: 'Product Type',
      numeric: true,
    },
    {
      id: 'quantity',
      label: 'Total Quantity',
      numeric: false,
    },
    {
      id: 'totalCost',
      label: 'Total Cost',
      numeric: true,
    },
  ]

  interface ProcedureDetailsRowsData extends TableRowProps {
    assetType: string
    quantity: number
    cost: string
  }

  function createData(assetType: string, quantity: number, cost: string) {
    return { assetType, quantity, cost }
  }

  const mappedData: ProcedureDetailsRowsData[] = [
    createData(
      'Implantable Hardware',
      totalHardwareCount,
      totalHardwareCost ? String(totalHardwareCost) : '0'
    ),
    createData(
      'Associated Products',
      totalAssociatedCount,
      totalAssociatedCost ? String(totalAssociatedCost) : '0'
    ),
    createData(
      'Implantable Biologic',
      totalImplantableBiologicCount,
      totalImplantableBiologicCost ? String(totalImplantableBiologicCost) : '0'
    ),
    createData(
      'Implantable Other',
      totalImplantableOtherCount,
      totalImplantableOtherCost ? String(totalImplantableOtherCost) : '0'
    ),
    createData(
      'Consumable',
      totalConsumableCount,
      totalConsumableCost ? String(totalConsumableCost) : '0'
    ),
    createData(
      'Explanted',
      totalExplantedCount,
      totalExplantedCost ? String(totalExplantedCost) : '0'
    ),
  ]

  return (
    <Box className={'parent-details-box'}>
      <BreadcrumbHeader />
      <Box className={'details-header-box'}>
        <Box display="flex" flexDirection={'row'} sx={{ width: '100%' }}>
          <Typography variant="h2">Case Number: </Typography>
          <Typography variant="h2" sx={{ fontWeight: '400', ml: 1 }}>
            #{caseNumber}
          </Typography>
        </Box>
        <LoadingButton
          disabled={true}
          variant="contained"
          loading={false}
          sx={{
            width: '5%',
          }}
        >
          Print
        </LoadingButton>
      </Box>
      <Box className={`details-child-box`}>
        <Grid
          data-testid="procedure-details-container"
          container
          direction={'column'}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            container
            direction={'row'}
            columns={7.7}
            justifyContent={'space-between'}
            xs={2}
            md={2}
            lg={2}
          >
            <ProcedureDetailsCard
              procedureDate={procedureDate}
              procedureType={procedureType}
              procedureDuration={procedureDuration}
              surgeon={surgeon || '-'}
              totalSurgeryCost={surgeryDetails?.totalSurgeryCost}
              loading={isLoading}
            />
            <PatientDetailsCard
              patientMRN={patientMRN}
              patientName={patientName}
              patientDOB={patientDOB}
              loading={isLoading}
            />
          </Grid>

          <Grid
            data-testid="asset-details-grid"
            item
            container
            xs={8}
            md={8}
            lg={8}
            direction={'column'}
            sx={{
              backgroundColor: 'grayscale.light',
              borderRadius: '7px',
              p: 1,
              mt: 2,
            }}
          >
            <Grid item>
              <TabPanel
                tabsConfig={[
                  {
                    label: 'Product Overview',
                    content: (
                      <DataTable
                        tableHeaders={headCells}
                        tableRows={mappedData || []}
                        isLoading={isLoading}
                        isErrored={!!error}
                      />
                    ),
                  },
                  {
                    label: 'Product Details',
                    content: (
                      <AssetDetailsTable
                        assetGroups={surgeryDetails?.assetGroups || []}
                        isLoading={isLoading}
                        isErrored={!!error}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
