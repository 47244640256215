import { Mail, Sms } from '@mui/icons-material'
import { Snackbar, Alert, IconButton } from '@mui/material'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { TSenderButtonProps } from './SenderButton.types'
import { FC } from 'react'
import { useSenderButtonLogic } from './SenderButton.logic'

const SenderButton: FC<TSenderButtonProps> = ({
  surgeryId,
  rep,
  isSms,
  assetDetails,
  disabled,
  emailsMobiles,
}) => {
  const {
    isErrorDialogOpen,
    snackbarData,
    loading,
    isAlreadySentAlertOpen,
    setIsAlreadySentAlertOpen,
    handleSendToRep,
    setErrorDialogOpen,
    handleSnackbarClose,
  } = useSenderButtonLogic({
    surgeryId,
    assetDetails,
    rep,
    isSms,
    emailsMobiles,
  })

  return (
    <>
      <Snackbar
        open={snackbarData.isOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarData.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>

      <AlertDialog
        isOpen={isErrorDialogOpen}
        mode="error"
        title="Failed to send product"
        description={`There was an error when attempting to send the record to the rep via ${
          isSms ? 'sms' : 'email'
        }. Please try again later.`}
        position={{
          bottom: '40%',
        }}
        positionAboveBottomNavigation
        primaryButtonAction={handleSendToRep}
        primaryButtonText="Retry Send"
        secondaryButtonAction={() => setErrorDialogOpen(false)}
        secondaryButtonText="Cancel"
        zIndex={6}
        isPrimaryDisabled={disabled}
        isPrimaryLoading={loading}
      />

      <AlertDialog
        isOpen={isAlreadySentAlertOpen}
        mode="primary"
        title={isSms ? 'Sms Already Sent' : 'Email Already Sent'}
        description="This product has already been sent to the rep. Are you sure you want to send it again?"
        primaryButtonText="Send Again"
        primaryButtonAction={handleSendToRep}
        secondaryButtonAction={() => setIsAlreadySentAlertOpen(false)}
        isPrimaryLoading={loading}
        isPrimaryDisabled={disabled}
        zIndex={7}
      />

      <IconButton
        onClick={handleSendToRep}
        disabled={disabled || loading}
        color="primary"
        size="small"
      >
        {isSms ? <Sms /> : <Mail />}
      </IconButton>
    </>
  )
}

export default SenderButton
