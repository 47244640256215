import { AssetType } from 'common/disposition'
import { AssetData, Scan } from 'common/types'

export const getExpiredAssetCount = (assetData: AssetData[]) => {
  const countExpiredScans = (scans: Scan[], assetTypeFilter?: AssetType) =>
    scans.filter(
      (scan: Scan) =>
        (!assetTypeFilter || scan.assetType === assetTypeFilter) &&
        scan.expirationDate &&
        new Date(scan.expirationDate) < new Date()
    ).length

  const expiredTotal = assetData
    .map((group: AssetData) => countExpiredScans(group.scans))
    .reduce((a, b) => a + b, 0)

  const expiredHardware = assetData
    .map((group: AssetData) => countExpiredScans(group.scans, 'non-biological'))
    .reduce((a, b) => a + b, 0)

  return {
    expiredTotal,
    expiredHardware,
  }
}
