import React, { useState } from 'react'
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { Memo } from 'components/molecules/Memo/Memo'
import { AssetData, AssetGroupCount } from 'common/types'
import { ButtonSet } from 'components/atoms/ButtonSet/ButtonSet'

interface AccurateDeviceCountModuleProps {
  radioButtonDisabled: boolean
  isLoading: boolean
  handleSubmit: () => void
  memo: AssetGroupCount['memo']
  radioButtonLabel: string
  assetGroupId: AssetData['_id']
  assetGroupCountMap: AssetGroupCount
  updateAssetGroupCount: (id: string, updates: Partial<AssetGroupCount>) => void
}

export const AccurateDeviceCountModule = ({
  radioButtonDisabled,
  isLoading,
  handleSubmit,
  memo,
  radioButtonLabel,
  assetGroupId,
  assetGroupCountMap,
  updateAssetGroupCount,
}: AccurateDeviceCountModuleProps) => {
  const isMobile = useIsMobile()

  const [radioValue, setRadioValue] = useState(
    assetGroupCountMap?.accurateDeviceCount
      ? 'accurateDeviceCount'
      : assetGroupCountMap?.deviceCountCorrectiveActionTaken
      ? 'deviceCountCorrectiveActionTaken'
      : ''
  )

  const clearButtonDisabled =
    radioButtonDisabled ||
    isLoading ||
    (assetGroupCountMap?.memo as string)?.length === 0

  const isSubmitDisabled: boolean =
    // form validation to go here when it is fixed
    radioButtonDisabled ||
    isLoading ||
    (assetGroupCountMap?.memo as string)?.length === 0

  // handles
  const memoOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const updates = {
      memo: e.target.value,
    }
    updateAssetGroupCount(assetGroupId, updates)
  }

  const handleClearMemo = () => {
    updateAssetGroupCount(assetGroupId, { memo: '' })
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (newValue === 'accurateDeviceCount') {
      handleClearMemo()
    }
    setRadioValue(newValue)
    updateAssetGroupCount(assetGroupId, {
      accurateDeviceCount: newValue === 'accurateDeviceCount',
      deviceCountCorrectiveActionTaken:
        newValue === 'deviceCountCorrectiveActionTaken',
    })
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      rowSpacing={1}
      sx={{ my: isMobile ? 1 : 0 }}
    >
      {isMobile && (
        <Divider orientation="horizontal" sx={{ maxWidth: '90%' }} flexItem />
      )}

      <Grid item>
        <Grid item>
          <FormControl>
            <RadioGroup
              value={radioValue}
              name="multipack-consumables-radio-buttons-group"
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={'accurateDeviceCount'}
                control={<Radio />}
                label={radioButtonLabel}
                disabled={radioButtonDisabled}
              />
              <FormControlLabel
                value={'deviceCountCorrectiveActionTaken'}
                control={<Radio />}
                label="Corrective Action Taken"
                disabled={radioButtonDisabled}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Memo
          backendValue={memo}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => memoOnChange(e)}
          disabled={radioButtonDisabled || isLoading}
        />
      </Grid>
      <Grid item>
        <ButtonSet
          isLoadingState={isLoading}
          primaryButtonText="Save Note"
          secondaryButtonText="Clear Note"
          dataTestId="rf-sponge-count"
          handleOnSubmit={handleSubmit}
          primaryButtonDisabled={isSubmitDisabled}
          secondaryButtonAction={handleClearMemo}
          secondaryButtonDisabled={clearButtonDisabled || isLoading}
          wrapText={false}
        />
      </Grid>
    </Grid>
  )
}
