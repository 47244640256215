import React, { createContext, useState, useContext } from 'react'

import { Alert, CaptureTrayContextType } from './CaptureTray.types'

const CaptureTrayContext = createContext<CaptureTrayContextType | undefined>(
  undefined
)

export const useCaptureTrayContext = () => {
  const context = useContext(CaptureTrayContext)
  if (!context) {
    throw new Error(
      'useCaptureTrayContext must be used within an CaptureTrayContextProvider'
    )
  }
  return context
}

export const CaptureTrayContextProvider: React.FC = ({ children }) => {
  const [trayImage, setTrayImage] = useState<string>('')
  const [baseImage, setBaseImage] = useState<number>(1890)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [alertData, setAlertData] = useState<Alert>({
    description: '',
    mode: undefined,
  })

  const contextValue: CaptureTrayContextType = {
    trayImage,
    setTrayImage,
    baseImage,
    setBaseImage,
    isSubmitting,
    setIsSubmitting,
    isAlertOpen,
    setIsAlertOpen,
    alertData,
    setAlertData,
  }

  return (
    <CaptureTrayContext.Provider value={contextValue}>
      {children}
    </CaptureTrayContext.Provider>
  )
}
