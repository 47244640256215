import { ATTACH_UDIS_TO_PRODUCT } from 'lib/services/api/schema'
import { useMutation, MutationTuple } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'
import {
  AttachUDIsToProductResponse,
  AttachUDIsToProductVariables,
} from './types'

type UseAttachUDIsToProductResult = MutationTuple<
  AttachUDIsToProductResponse,
  AttachUDIsToProductVariables
>

const useAttachUDIsToProduct = (): UseAttachUDIsToProductResult => {
  return useMutation<AttachUDIsToProductResponse, AttachUDIsToProductVariables>(
    ATTACH_UDIS_TO_PRODUCT,
    {
      onError: (error: Error) => {
        captureWarning(error)
      },
    }
  )
}

export default useAttachUDIsToProduct
