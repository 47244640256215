import Button from '@mui/material/Button'
import PageDisplay from 'components/PageDisplay/PageDisplay'

interface Props {
  pathForward: () => void
  pathForwardText?: string
  message?: string
  secondaryPathForward?: () => void
  secondaryPathForwardText?: string
}

function ErrorDisplay({
  pathForward,
  pathForwardText = 'Retry',
  message = `We're having difficulty connecting to the server right now. Check your connection or try again later.`,
  secondaryPathForward,
  secondaryPathForwardText,
}: Props) {
  return (
    <PageDisplay>
      <h4>{message}</h4>
      <Button
        color="error"
        variant="outlined"
        sx={{
          color: 'error.contrastText',
          mr: 1,
          '&:hover': {
            backgroundColor: 'error.hover',
          },
        }}
        onClick={() => pathForward()}
      >
        {pathForwardText}
      </Button>
      {secondaryPathForward && secondaryPathForwardText ? (
        <Button
          variant="contained"
          onClick={() => secondaryPathForward()}
          sx={{
            color: 'primary.light',
            mt: 1,
            mr: 1,
            '&:hover': {
              color: 'primary.main',
              backgroundColor: 'primary.hover',
            },
          }}
        >
          {secondaryPathForwardText}
        </Button>
      ) : null}
    </PageDisplay>
  )
}

export default ErrorDisplay
