import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { BetterIDTrayScrew, TrayPlate } from '../SPD.types'

export interface TrayMapProps {
  trayType?: TrayType
  loadWithUDIs?: boolean
  isVertical?: boolean
  mapContent?: MapContent
  onClickActions?: OnClickActions
  isScrewEnabled?: ScrewStatusCheck
  isScrewImplanted?: ScrewStatusCheck
  isScrewWasted?: ScrewStatusCheck
  handlePlateUsage?: (plateName: PlateKey, newCount: number) => void
  getPlateCount?: (plateName: PlateKey) => number
  disablePlateIncrease?: boolean
}

export interface MapContent {
  screws?: BetterIDTrayScrew[]
  plates?: TrayPlate[]
}

export interface OnClickActions {
  screw?: (label: string, rowIndex: number, column: number, x: number) => void
  plates?: (plateName: PlateKey, newCount: number) => void
}

export type ScrewStatusCheck = (
  label: string,
  rowIndex: number,
  x: number
) => boolean

export type PlateCounts = {
  [plateName: string]: number
}

export const plateUDIMap = {
  malleablePlate24: '04546540365484',
  malleablePlate18: '04546540365415',
  malleablePlate7: '04546540365460',
  malleablePlate2x2: '04546540365422',
  malleablePlate6x2: '04546540365439',
  curved10Hole: '04546540365477',
  standardPlate7: '04546540365811',
  standardPlate2x2: '04546540365774',
  standardPlate6x2: '04546540365781',
  standard7DoubleY: '04546540365866',
  standardPlate24: '04546540365767',
  standardPlate8: '07613154170920',
  standardOrbital4: '04546540365835',
  standardPlate8Deg90Left: '04546540365842',
  standardPlate8Deg90Right: '04546540365859',
  standardCurved12: '04546540365828',
  drillBit12: '04546540366481',
  drillBit6: '04546540390578',
  drillBit4: '04546540366467',
} as const

export const plateDescriptionMap = {
  malleablePlate24: '24-hole straight, Malleable 0.5mm plate',
  malleablePlate18: '18-hole straight, Malleable 0.5mm plate',
  malleablePlate7: '7-hole double Y, Malleable 0.5mm plate',
  malleablePlate2x2: '3D Plate, 2x2-hole, Malleable 0.5mm plate',
  malleablePlate6x2: '3D Plate, 6x2-hole, Malleable 0.5mm plate',
  curved10Hole: '10-hole curved, Malleable 0.5mm plate',
  standardPlate7: '7-hole double Y, standard 0.6mm plate',
  standardPlate2x2: '3D Plate, 2x2-hole, standard 0.6mm plate',
  standardPlate6x2: '3D Plate, 6x2-hole, standard 0.6mm plate',
  standard7DoubleY: '7-hole T, standard 0.6mm plate',
  standardPlate24: '24-hole straight, standard 0.6mm plate',
  standardPlate8: '8-hole straight, standard 0.6mm plate',
  standardOrbital4: '4-hole orbital curved, standard 0.6mm plate',
  standardPlate8Deg90Left: '8-hole 90 degree, left, standard 0.6mm plate',
  standardPlate8Deg90Right: '8-hole 90 degree, right, standard 0.6mm plate',
  standardCurved12: '10-hole curved, standard 0.6mm plate',
  drillBit12: '1.05x54mm, 12mm working length drill bit',
  drillBit6: '1.0x50mm, 6mm working length drill bit',
  drillBit4: '0.9x46mm, 4mm working length drill bit',
} as const

export type PlateKey = keyof typeof plateUDIMap
