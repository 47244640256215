import { flexBox } from 'common/styles/mixins'
import styled from 'styled-components'
import { TMainContainerProps } from './ImplantableOtherRecordSender.types'

export const MainContainer = styled.div<TMainContainerProps>`
  ${({ $isRepDataContainer = false }) =>
    flexBox(
      'flex',
      $isRepDataContainer ? 'space-between' : 'center',
      'center',
      $isRepDataContainer ? 'column' : 'unset',
      'unset'
    )}
  max-height: ${({ $isRepDataContainer }) =>
    $isRepDataContainer ? '126px' : 'unset'};

  overflow: ${({ $isRepDataContainer }) =>
    $isRepDataContainer ? 'auto' : 'unset'};

  margin-top: 1rem;
  width: 100%;
  padding: 0 1rem 0.5rem 1rem;
`
