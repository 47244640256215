import { useEffect, useState } from 'react'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'

// Context
import { useInventoryReport } from './InventoryReportProvider'
import useUpdateParValue from 'lib/services/api/product-service/updateParValue'
import useGetInventoryReport from 'lib/services/api/product-service/getInventoryReport'
import { GetInventoryReportResponse } from 'lib/services/api/product-service/getInventoryReport/types'
import { FiltersParams } from './inventory-filters/InventoryFilters.types'

export const useLogic = () => {
  const { setParValueModal, setDetailsModal, filters, setFilters } =
    useInventoryReport()

  const isTablet = useIsTablet()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [reportData, setReportData] = useState<GetInventoryReportResponse>()
  const [updateParValue, { loading: isLoadingUpdateParValue }] =
    useUpdateParValue()

  const {
    data,
    loading: isLoadingReport,
    error: reportError,
    fetchMore,
    networkStatus,
  } = useGetInventoryReport({
    limit: 10,
    skip: 0,
    companiesIds: filters.selectedCompany
      ? [filters.selectedCompany?.id]
      : undefined,
    type: filters.type ? [filters.type] : undefined,
    itemDisposition: filters.itemDisposition,
    productName: filters.productName,
    locationsIds: filters.selectedLocation
      ? [filters?.selectedLocation?.id as number]
      : undefined,
  })
  const isRefetching = networkStatus === 3

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleFiltersActions = (
    action: 'reset' | 'remove',
    filterType?: keyof FiltersParams
  ) => {
    switch (action) {
      case 'reset':
        setFilters({})
        break
      case 'remove':
        if (filterType) {
          setFilters((prevFilters: any) => {
            const newFilters = { ...prevFilters }
            delete newFilters[filterType]
            return newFilters
          })
        }
        break
      default:
        break
    }
  }

  const handleInfiniteScroll = () => {
    if (!reportData) return
    if (
      reportData.getInventoryReport.items.length ===
      reportData.getInventoryReport.totalCount
    )
      return
    fetchMore({
      variables: {
        limit: reportData.getInventoryReport.items.length,
        skip: reportData.getInventoryReport.items.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        const newItems = fetchMoreResult.getInventoryReport.items.filter(
          (newItem) =>
            !prev.getInventoryReport.items.some(
              (prevItem) => prevItem.parValueId === newItem.parValueId
            )
        )
        return {
          getInventoryReport: {
            ...prev.getInventoryReport,
            items: [...prev.getInventoryReport.items, ...newItems],
          },
        }
      },
    })
  }

  const handleUpdateParValue = async (parValueId: number, parValue: number) => {
    try {
      await updateParValue({
        variables: {
          newParValue: parValue || 0,
          parValueId: parValueId,
        },
      })

      // This logic is to avoid refetching the whole data again
      const rowIndex = reportData?.getInventoryReport.items.findIndex(
        (item) => item.parValueId === parValueId
      )

      if (rowIndex !== -1 && reportData) {
        const updatedItems = [...reportData.getInventoryReport.items]
        updatedItems[rowIndex as number] = {
          ...updatedItems[rowIndex as number],
          parValue,
        }

        setReportData({
          ...reportData,
          getInventoryReport: {
            ...reportData.getInventoryReport,
            items: updatedItems,
          },
        })
      }

      setParValueModal({ open: false })
    } catch (e) {
      console.error('Failed to update par value', e)
    }
  }

  useEffect(() => {
    handleMenuClose()
  }, [filters])

  useEffect(() => {
    setReportData(data)
  }, [data])

  return {
    reportData,
    isLoadingReport,
    reportError,
    isTablet,
    isRefetching,
    menuAnchorEl,
    isLoadingUpdateParValue,
    filters,
    handleInfiniteScroll,
    setDetailsModal,
    setParValueModal,
    handleMenuClick,
    handleMenuClose,
    handleUpdateParValue,
    handleFiltersActions,
  }
}

export default useLogic
