import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Mask from '../Mask'
import styles from './loadingIndicator.module.css'

interface Props {
  text?: string
}

function LoadingIndicator({ text }: Props) {
  return (
    <Mask open backgroundColor="rgba(255,255,255,0.9)">
      <Box className={styles.container}>
        <CircularProgress />
        {text && <Typography sx={{ pt: 5 }}>{text}</Typography>}
      </Box>
    </Mask>
  )
}

export default LoadingIndicator
