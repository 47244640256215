import { Option } from 'common/types'
import { createContext, Dispatch, FC, useContext, useState } from 'react'

interface Values {
  lotBatch: string
  modelNumber: string
  serialNumber: string
  udi: string
  description: string
  expiryDate: Date
  manufacturingDate: Date
  selectedCompany: Option | undefined
}

interface ProcessModalContextType {
  lotBatch: string
  modelNumber: string
  serialNumber: string
  udi: string
  description: string
  expiryDate: Date
  manufacturingDate: Date
  selectedCompany?: Option | undefined
  searchCompany: string
  setLotBatch: Dispatch<React.SetStateAction<string>>
  setModelNumber: Dispatch<React.SetStateAction<string>>
  setSerialNumber: Dispatch<React.SetStateAction<string>>
  setUdi: Dispatch<React.SetStateAction<string>>
  setDescription: Dispatch<React.SetStateAction<string>>
  setExpiryDate: Dispatch<React.SetStateAction<Date>>
  setManufacturingDate: Dispatch<React.SetStateAction<Date>>
  setSelectedCompany: Dispatch<React.SetStateAction<Option | undefined>>
  setSearchCompany: Dispatch<React.SetStateAction<string>>
  onSaveCreateValues: (values: Values) => void
}

const ProcessModalContext = createContext<ProcessModalContextType | undefined>(
  undefined
)

export const useProcessModalContext = () => {
  const context = useContext(ProcessModalContext)
  if (!context) {
    throw new Error(
      'useProcessModalContext must be used within a ProcessModalProvider'
    )
  }
  return context
}

export const ProcessModalProvider: FC = ({ children }) => {
  const [lotBatch, setLotBatch] = useState('')
  const [modelNumber, setModelNumber] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const [udi, setUdi] = useState('')
  const [description, setDescription] = useState('')
  const [expiryDate, setExpiryDate] = useState<Date>(new Date())
  const [manufacturingDate, setManufacturingDate] = useState<Date>(new Date())
  const [selectedCompany, setSelectedCompany] = useState<Option | undefined>()
  const [searchCompany, setSearchCompany] = useState<string>('')

  // I want a function that on save, returns all the above state values
  const onSaveCreateValues = () => {
    return {
      lotBatch,
      modelNumber,
      serialNumber,
      udi,
      description,
      expiryDate,
      manufacturingDate,
      selectedCompany,
    }
  }

  const contextValue: ProcessModalContextType = {
    lotBatch,
    modelNumber,
    serialNumber,
    udi,
    description,
    expiryDate,
    manufacturingDate,
    searchCompany,
    selectedCompany,
    setLotBatch,
    setModelNumber,
    setSerialNumber,
    setUdi,
    setDescription,
    setExpiryDate,
    setManufacturingDate,
    setSearchCompany,
    setSelectedCompany,
    onSaveCreateValues,
  }

  return (
    <ProcessModalContext.Provider value={contextValue}>
      {children}
    </ProcessModalContext.Provider>
  )
}
