// Components
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import { CustomModal } from 'components/molecules/Modal/Modal'

// Logic
import { useBatchDispositionModalLogic } from './BatchDispositionModal.logic'

// Types
import { BatchDispositionModalProps } from './BatchDispositionModal.types'
import {
  ASSET_TYPES,
  EXPLANTED_REASON_LIST,
  WASTED_REASON_LIST,
  getDispositionList,
} from 'common/disposition'
import { BarcodeIcon } from 'assets/icons/BarcodeIcon'
import MediaUpload from 'components/organisms/MediaUpload/MediaUpload'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import Button from 'components/molecules/Button/Button'

export const BatchDispositionModal = ({
  activeTray,
  assetTrays,
  assetType,
  isOpen,
  isSubmitting,
  dispositionStatus,
  onSave,
  onClose,
  setIsTrayScannerOpen,
  registerTrayScanHandler,
  isManualAddition,
  scan,
  onSaveMedia,
  selectedScans,
}: BatchDispositionModalProps) => {
  const {
    formState,
    isFormValid,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSiteLocationEnabled,
  } = useBatchDispositionModalLogic({
    activeTray,
    assetType,
    onSave,
    registerTrayScanHandler,
    dispositionStatus,
    scan,
    isManualAddition,
    selectedScans,
  })

  return (
    <CustomModal
      open={isOpen}
      secondaryButtonAction={onClose}
      dataTestId="batch-disposition"
      header="Add Disposition to Selected Products"
      isLoadingState={isSubmitting || false}
      primaryButtonDisabled={!isFormValid}
      handleOnSubmit={(e) => {
        if (e) handleSubmit(e)
      }}
      primaryButtonText={isSubmitting ? 'Saving' : 'Disposition'}
    >
      <FormControl fullWidth sx={{ my: 2 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Box flexGrow="1">
            <AutocompleteInput
              id="assetTray"
              name="assetTray"
              label="Inventory Location"
              placeholder="N/A"
              value={formState.assetTray}
              options={assetTrays.filter(Boolean)}
              handleChangeFormData={handleChangeFormData}
              onTextInputChange={(e) => {
                handleChangeFormData({
                  target: {
                    name: 'assetTray',
                    value: e.target.value,
                  },
                })
              }}
              variant="outlined"
              sx={{
                my: 0,
                backgroundColor: 'grayscale.lightest',
              }}
              autoSelect={false}
              autoHighlight={false}
              required={false}
              disabled={isSubmitting}
            />
          </Box>
          <Button
            sx={{
              height: 40,
              pt: 1.5,
            }}
            variant="outlined"
            color="primary"
            onClick={() => setIsTrayScannerOpen && setIsTrayScannerOpen(true)}
            className="barcode-button"
          >
            <BarcodeIcon active color="var(--primary)" />
          </Button>
        </Box>
      </FormControl>

      <FormControl fullWidth sx={{ my: 2 }}>
        <div>
          <InputLabel id="asset-type-label">Product Type</InputLabel>
          <Select
            fullWidth
            id="assetType"
            name="assetType"
            variant="outlined"
            labelId="asset-type-label"
            label="Product Type"
            value={formState.assetType}
            onChange={handleChangeFormData}
            disabled={isSubmitting}
            sx={{
              width: '100%',
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            {/* the styling for these menu items is necessary to prevent frozen modal bug, alter at your own risk */}
            <MenuItem
              style={{ cursor: 'pointer', fontSize: 16 }}
              key={ASSET_TYPES[0]}
              value={ASSET_TYPES[0]}
            >
              Implantable Hardware/Assoc. Product
            </MenuItem>
            <MenuItem
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[2]}
              value={ASSET_TYPES[2]}
            >
              Implantable Other
            </MenuItem>
          </Select>
        </div>
      </FormControl>

      {formState.assetType && formState.assetType !== 'consumable' && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="asset-tray-label">Disposition</InputLabel>
          <Select
            id="implantStatus"
            name="implantStatus"
            variant="outlined"
            labelId="disposition-label"
            label="Disposition"
            value={formState.implantStatus}
            onChange={handleChangeFormData}
            disabled={isSubmitting}
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            {getDispositionList({
              assetType: formState.assetType,
            }).map((disposition) => (
              <MenuItem key={disposition.value} value={disposition.value}>
                {disposition.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {(formState.implantStatus === 'IMPLANTED' ||
        formState.implantStatus === 'EXPLANTED' ||
        !formState.implantStatus) && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <AutocompleteInput
            id="implantSite"
            name="implantSite"
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Site'
                : 'Explant Site'
            }
            value={formState.implantSite}
            options={implantSiteList}
            handleChangeFormData={handleChangeFormData}
            sx={{
              my: 0,
              backgroundColor: 'grayscale.lightest',
            }}
            variant="outlined"
            disabled={isSubmitting}
          />
        </FormControl>
      )}

      {isSiteLocationEnabled && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            id={`implant-site`}
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location'
                : 'Explant Location'
            }
            name={`siteLocation`}
            value={formState.siteLocation ?? ''}
            placeholder={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location'
                : 'Explant Location'
            }
            inputMode="text"
            sx={{ backgroundColor: 'grayscale.lightest' }}
            onChange={handleChangeFormData}
            disabled={isSubmitting}
          />
        </FormControl>
      )}

      {formState.implantStatus === 'WASTED' && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="wasted-reason-label">Wasted Reason</InputLabel>
          <Select
            id="wastedReason"
            name="wastedReason"
            variant="outlined"
            labelId="wasted-reason-label"
            label="Wasted Reason"
            value={formState.wastedReason ?? ''}
            onChange={handleChangeFormData}
            disabled={isSubmitting}
          >
            {WASTED_REASON_LIST.map((wastedReason) => (
              <MenuItem key={wastedReason.value} value={wastedReason.value}>
                {wastedReason.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {formState.implantStatus === 'EXPLANTED' && isManualAddition && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="explanted-reason-label">Explanted Reason</InputLabel>
          <Select
            id="explantedReason"
            name="explantedReason"
            variant="outlined"
            labelId="explanted-reason-label"
            label="Explanted Reason"
            value={formState.explantedReason ?? ''}
            onChange={handleChangeFormData}
            disabled={isSubmitting}
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            {EXPLANTED_REASON_LIST.map((reason) => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {formState.explantedReason === 'OTHER' && isManualAddition && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            id={`explanted-reason-note`}
            label="Other reason"
            name="explantedReasonNote"
            value={formState.explantedReasonNote ?? ''}
            placeholder="Other Reason"
            inputMode="text"
            sx={{ backgroundColor: 'grayscale.lightest' }}
            onChange={handleChangeFormData}
            disabled={isSubmitting}
          />
        </FormControl>
      )}

      <MediaUpload
        disableActions={isSubmitting}
        hasActions
        scanId={scan?._id as string}
        fileNames={[]}
        onSave={(media) => onSaveMedia && onSaveMedia(media)}
      />
    </CustomModal>
  )
}
