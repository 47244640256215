import { useEffect, useState } from 'react'
import { TRepProps, TSurgeryResult } from './Rep.types'
import { useGetSurgeryQuery } from 'lib/apollo/hooks'
import { transformDate } from '../ImplantableOtherRecordSender.types'

export const useRepLogic = ({
  surgeryId,
  assetDetails,
}: Partial<TRepProps>) => {
  const [emailsMobiles, setEmailsMobiles] = useState<{
    emails: string[]
    mobiles: string[]
  }>({
    emails: [],
    mobiles: [],
  })
  const response = useGetSurgeryQuery(surgeryId, {
    disablePolling: true,
  })

  const surgery: TSurgeryResult = response?.data?.getSurgery

  useEffect(() => {
    const implantableOtherRepReconciliation =
      surgery?.implantableOtherRepReconciliation ?? []

    if (implantableOtherRepReconciliation.length > 0) {
      const index = implantableOtherRepReconciliation.findIndex(
        (item) =>
          assetDetails &&
          item.assetDetails.assetTitle === assetDetails.assetTitle &&
          item.assetDetails.deviceId === assetDetails.deviceId &&
          item.assetDetails.lotNumber === assetDetails.lotNumber &&
          item.assetDetails.modelNumber === assetDetails.modelNumber &&
          item.assetDetails.expirationDate ===
            transformDate(assetDetails.expirationDate as string) &&
          item.assetDetails.deviceCount === assetDetails.deviceCount &&
          item.assetDetails.usedCount === assetDetails.usedCount
      )
      if (index !== -1) {
        setEmailsMobiles({
          emails: implantableOtherRepReconciliation[index].emails,
          mobiles: implantableOtherRepReconciliation[index].mobiles,
        })
      }
    }
  }, [assetDetails, surgery?.implantableOtherRepReconciliation])

  return {
    emailsMobiles,
  }
}
