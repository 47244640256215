import './MiniNavbar.scss'
import { NavbarItemProps, NavbarProps } from './MiniNavbar.types'
import NavbarItem from './MiniNavbarItem/MiniNavbarItem'
import { navbarItems } from './miniNavbarItems'

const Navbar = ({ floatingBarHeight }: NavbarProps) => {
  return (
    <div
      className="spd-navbar"
      style={{ height: `calc(100vh - ${floatingBarHeight}px)` }}
    >
      <div className="container">
        {navbarItems.map((navbarItem: NavbarItemProps) => (
          <NavbarItem key={navbarItem.title} {...navbarItem} />
        ))}
      </div>
    </div>
  )
}

export default Navbar
