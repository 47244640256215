import { Typography, Button, Box } from '@mui/material'
import styles from '../assetList.module.css'
import { Link } from 'react-router-dom'
import { Surgery } from 'common/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
interface AssetListHeaderProps {
  demoMenuEvent: {
    readonly onMouseDown: (e: any) => void
    readonly onTouchStart: (e: any) => void
    readonly onMouseUp: () => void
    readonly onMouseLeave: () => void
    readonly onTouchEnd: () => void
  }
  heading: string
  procedureStatus: string
  isNurseUser?: boolean
  surgeryRepCount: number
  setIsQrOpen: React.Dispatch<React.SetStateAction<boolean>>
  surgery: Surgery
}
export const AssetListHeader = ({
  demoMenuEvent,
  heading,
  procedureStatus,
  isNurseUser,
  surgeryRepCount,
  setIsQrOpen,
  surgery,
}: AssetListHeaderProps) => {
  const flags = useFlags()

  // instrument trays
  const instrumentTrayDataReturn = (surgery.instrumentTrays || []).filter(
    (element) => element !== '' && element !== null
  )

  const instrumentTrayLength = instrumentTrayDataReturn.length

  type ButtonRoutes = '$addInstrumentTrays' | '$addSalesRep'

  const Buttons: {
    [K in ButtonRoutes]: {
      label: string
      to?: string
      component?: any
      onClick?: () => void
      dataTestId?: string
      isVisible?: boolean
    }
  } = {
    $addInstrumentTrays: {
      label:
        instrumentTrayLength > 0
          ? `Edit Instrument Trays (${instrumentTrayLength})`
          : 'Add Instrument Trays',
      to: '../instrument-trays',
      component: Link,
      dataTestId: 'add-instrument-tray-button',
      isVisible: flags.documentedListAddInstrumentTraysButton,
    },
    $addSalesRep: {
      label: 'Add Sales Rep',
      onClick: () => setIsQrOpen(true),
      dataTestId: 'add-sales-rep-button',
      isVisible: flags.documentedListAddSalesRepButton,
    },
  }

  return (
    <>
      <Box sx={{ mr: 1 }}>
        <Typography
          variant="h1"
          {...demoMenuEvent}
          sx={{ userSelect: 'none', lineHeight: 1.3, my: 0.5 }}
        >
          {heading}
        </Typography>
        {isNurseUser && flags.documentedListActiveSalesRepCount === true && (
          <>
            <Typography
              variant="body2"
              textTransform="capitalize"
              color="grey.800"
              lineHeight={1.5}
            >
              Active Sales Reps: {surgeryRepCount}
            </Typography>
          </>
        )}
      </Box>
      {/* Header buttons */}
      {isNurseUser && procedureStatus !== 'SUBMITTED' && (
        <Box
          className={styles.buttonContainer}
          sx={{ minWidth: 'fit-content' }}
        >
          {Object.entries(Buttons).map(
            ([
              key,
              { label, to, component, onClick, dataTestId, isVisible },
            ]) => {
              if (isVisible === false) {
                return null
              }

              return (
                <Button
                  data-testid={dataTestId}
                  key={key}
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1, mr: 1 }}
                  to={to ? to : undefined}
                  component={component ? component : undefined}
                  onClick={onClick ? onClick : undefined}
                >
                  {label}
                </Button>
              )
            }
          )}
        </Box>
      )}
    </>
  )
}
