import { AssetData, Surgery, Scan } from 'common/types'
import _ from 'lodash'

export type SurgeryByMonthObject = {
  month: number
  surgeries: Surgery[]
}

export type AssetCategory = {
  hardwareAssets: Scan[]
  biologicAssets: Scan[]
  consumableAssets: Scan[]
  otherAssets: Scan[]
}

export type AssetsByMonth = {
  [month: number]: AssetCategory
}
interface DashboardStatsProps {
  dateToday: Date
  surgeryList?: Surgery[]
  assetGroups?: Surgery['assetGroups']
  currentYearSurgeryList?: Surgery[]
}

export const getDashboardStats = ({
  dateToday,
  surgeryList,
  currentYearSurgeryList,
  assetGroups,
}: DashboardStatsProps) => {
  // months array starts at index 0 for January
  const currentMonth = dateToday.getMonth()
  const previousMonth = dateToday.getMonth() - 1
  const thisYear = dateToday.getFullYear()

  // ==============================||  Parsing assets ||============================== //

  const assets: Scan[] =
    assetGroups
      ?.map((assetGroup: AssetData) => {
        return assetGroup.scans
      })
      .flat() || []

  let wastedAssets: Scan[] = []
  let consumedAssets: Scan[] = []

  if (assets.length > 0) {
    assets.map((asset: Scan) => {
      if (asset.implantStatus === 'WASTED') {
        wastedAssets.push(asset)
      } else {
        consumedAssets.push(asset)
      }
      return asset
    })
  }
  // ==============================||  Parsing procedures today |============================== //

  let surgeriesToday: Surgery[] = []
  let activeSurgeriesToday: Surgery[] = []
  let completedSurgeriesToday: Surgery[] = []

  surgeryList?.map((surgery: Surgery) => {
    const procedure = surgery.procedures[0]
    const procedureDate = new Date(procedure.dateTime)
    const procedureMonth = procedureDate.getMonth()

    const isToday: boolean =
      procedureDate.getDate() === dateToday.getDate() &&
      procedureMonth === dateToday.getMonth() &&
      procedureDate.getFullYear() === dateToday.getFullYear()

    if (isToday) {
      surgeriesToday.push(surgery)
      if (surgery.status.name === 'SCANNING' && surgery.assetGroups.length > 0)
        activeSurgeriesToday.push(surgery)
      if (surgery.status.name === 'SUBMITTED')
        completedSurgeriesToday.push(surgery)
    }
  })

  // ==============================||  Parsing procedures by month ||============================== //
  const initialSurgeriesPerMonth: SurgeryByMonthObject[] = []

  for (let i = 0; i < 12; i++) {
    initialSurgeriesPerMonth[i] = { month: i, surgeries: [] }
  }

  let surgeriesByMonth: SurgeryByMonthObject[] = initialSurgeriesPerMonth
  let parsedSurgeriesByMonth: SurgeryByMonthObject[] = initialSurgeriesPerMonth

  currentYearSurgeryList?.map((surgery: Surgery) => {
    const procedure = surgery?.procedures[0]
    const procedureMonth = new Date(procedure.dateTime).getMonth()

    surgeriesByMonth.push({
      month: procedureMonth,
      surgeries: [surgery],
    })
  })

  parsedSurgeriesByMonth = _(surgeriesByMonth)
    .groupBy('month')
    .map((objs: SurgeryByMonthObject[]) =>
      _.mergeWith(
        {},
        ...objs,
        (
          a: SurgeryByMonthObject['surgeries'],
          b: SurgeryByMonthObject['surgeries']
        ) => {
          if (Array.isArray(a)) {
            return a.concat(b)
          }
        }
      )
    )
    .value()

  // ==============================||  Parsing assets by month ||============================== //
  const initialAssetsByMonth: AssetsByMonth = {}

  for (let i = 0; i < 12; i++) {
    initialAssetsByMonth[i] = {
      hardwareAssets: [],
      biologicAssets: [],
      consumableAssets: [],
      otherAssets: [],
    }
  }

  const assetsByMonth: AssetsByMonth = (
    assets as Scan[]
  )?.reduce<AssetsByMonth>(
    (acc: any, asset: Scan) => {
      const implantDate = new Date(asset.implantTime)
      const assetMonth = implantDate.getMonth()
      const assetYear = implantDate.getFullYear()
      const isCurrentYear = assetYear === thisYear

      if (isCurrentYear && asset.assetType === 'non-biological')
        acc[assetMonth]?.hardwareAssets.push(asset)
      else if (isCurrentYear && asset.assetType === 'biological')
        acc[assetMonth]?.biologicAssets.push(asset)
      else if (isCurrentYear && asset.assetType === 'consumable')
        acc[assetMonth]?.consumableAssets.push(asset)
      else isCurrentYear && acc[assetMonth]?.otherAssets.push(asset)
      return acc as AssetsByMonth
    },
    Array.from({ length: 12 }).reduce<AssetsByMonth>(
      (acc, _, i) => ({
        ...acc,
        [i]: {
          hardwareAssets: [],
          biologicAssets: [],
          consumableAssets: [],
          otherAssets: [],
        },
      }),
      {} as AssetsByMonth
    )
  )

  // ==============================||  Parsing current and previous procedures per month ||============================== //

  const currentMonthSurgeryCount: number = parsedSurgeriesByMonth?.filter(
    (obj: SurgeryByMonthObject) => obj?.month === currentMonth
  )?.[0]?.surgeries?.length

  const previousMonthSurgeryCount: number = parsedSurgeriesByMonth?.filter(
    (obj: SurgeryByMonthObject) => obj?.month === previousMonth
  )?.[0]?.surgeries?.length

  let surgeriesMonthOverMonth = '0%'
  const monthChange: number =
    previousMonthSurgeryCount === 0
      ? 100
      : ((currentMonthSurgeryCount - previousMonthSurgeryCount) /
          previousMonthSurgeryCount) *
        100
  if (monthChange > 0) {
    surgeriesMonthOverMonth = `+${monthChange.toFixed(2)}%`
  } else if (monthChange < 0) {
    surgeriesMonthOverMonth = `${monthChange.toFixed(2)}%`
  }

  // ==============================||  Parsing total assets for current month ||============================== //

  const totalAssetsCurrentMonth: number = Object.values(
    (assetsByMonth as AssetsByMonth)[currentMonth]
  ).reduce((acc: number, assetType: Scan[]) => {
    return acc + assetType.length
  }, 0)

  const stats = {
    totalSurgeriesToday: surgeriesToday.length,
    activeSurgeriesToday: activeSurgeriesToday.length,
    completedSurgeriesToday: completedSurgeriesToday.length,
    surgeriesRemainingToday:
      surgeriesToday.length -
      (activeSurgeriesToday.length + completedSurgeriesToday.length),
    totalSurgeriesCurrentMonth: currentMonthSurgeryCount,
    totalAssetsCurrentMonth,
    totalHardwareAssetsCurrentMonth: (assetsByMonth as AssetsByMonth)[
      currentMonth
    ].hardwareAssets.length,
    totalBiologicAssetsCurrentMonth: (assetsByMonth as AssetsByMonth)[
      currentMonth
    ].biologicAssets.length,
    totalConsumableAssetsCurrentMonth: (assetsByMonth as AssetsByMonth)[
      currentMonth
    ].consumableAssets.length,
    totalOtherAssetsCurrentMonth: (assetsByMonth as AssetsByMonth)[currentMonth]
      .otherAssets.length,
    surgeriesMonthOverMonth,
  }

  return {
    parsedSurgeriesByMonth,
    wastedAssets,
    totalAssets: assets,
    consumedAssets,
    surgeriesToday,
    assetsByMonth,
    stats,
  }
}
