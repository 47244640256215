import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'
import { TrayType } from './AddTraySelectType.types'

export const useAddTraySelectType = () => {
  const { setSelectedTrayCategory } = useTrayConfigurationContext()

  const handleTypeSelection = (type: TrayType['category']) => {
    setSelectedTrayCategory(type)
  }

  return {
    handleTypeSelection,
  }
}
