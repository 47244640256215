import { FC } from 'react'
import { Helmet } from 'react-helmet'

// MUI
import { Box, ButtonBase, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// Type
import { IHeader } from './Header.types'

// Style
import './Header.scss'
import { LoadingButton } from '@mui/lab'
import classNames from 'classnames'

const Header: FC<IHeader> = ({
  title,
  actions,
  parent,
  hasBack,
  isMobile = false,
  onBackClick,
}) => (
  <>
    <Helmet>
      <title>
        {title} {parent}
      </title>
    </Helmet>
    <div
      className={classNames('top-header-container', {
        'is-mobile': isMobile,
      })}
    >
      <div className="left-container">
        {hasBack ? (
          <ButtonBase disableRipple onClick={onBackClick}>
            <KeyboardBackspaceIcon color="primary" height={20} scale={20} />
          </ButtonBase>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          {parent && (
            <>
              <Typography variant="subtitle1">{parent}</Typography>
              <Typography variant="h5">/</Typography>
            </>
          )}
          <Typography variant="h5" fontWeight={500}>
            {title}
          </Typography>
        </Box>
      </div>
      <div className="actions">
        {actions?.map((action, index) => (
          <LoadingButton
            size="small"
            key={index}
            variant={action.variant || 'contained'}
            startIcon={action.icon}
            onClick={action.onClick}
            loading={action.isLoading}
            disabled={action.disabled}
          >
            {action.title}
          </LoadingButton>
        ))}
      </div>
    </div>
  </>
)

export default Header
