import FloatingBar from 'components/FloatingBar/FloatingBar'
import './SPDLayout.scss'
import Navbar from '../../components/molecules/MiniNavbar/MiniNavbar'
import { useSPD } from './SPD/SPD.context'
import { SPDScanProvider } from './SPD/Scan/Scan.context'

interface SPDLayoutProps {
  children: JSX.Element
}

const SPDLayout = ({ children }: SPDLayoutProps) => {
  const { navbarHeight } = useSPD()

  return (
    <SPDScanProvider>
      <div className="spd-layout">
        <FloatingBar />
        <div
          className="container"
          style={{
            paddingTop: navbarHeight,
            minHeight: `calc(100vh - ${navbarHeight}px)`,
          }}
        >
          <Navbar floatingBarHeight={navbarHeight} />

          {children}
        </div>
      </div>
    </SPDScanProvider>
  )
}

export default SPDLayout
