import { SelectChangeEvent, Skeleton, Typography } from '@mui/material'
import dayjs from 'lib/dayjs'
import { useCallback } from 'react'
import SPDCortexScanner from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner'
import { useUser } from 'app/User'
import { useNavigate } from 'react-router-dom'
import { useAddTrayContext } from 'views/TrayManagementLayout/AddTray/AddTray.context'
import { CreateTrayItemInput } from 'common/types'
import { useCreateTrayItemMutation } from 'lib/apollo/hooks'
import { useInventorySheetsContext } from 'views/TrayManagementLayout/AddTray/InventorySheets/InventorySheets.context'
import { TrayType } from '../EditTrayModal/EditTrayModal.types'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useAddTrayDetailsLogic = () => {
  const navigate = useNavigate()
  const { isRep } = useUser()
  const {
    trayBarcode,
    setTrayBarcode,
    showScanner,
    setShowScanner,
    trayDescription,
    setTrayDescription,
    selectedLocationId,
    setSelectedLocationId,
    trayVendor,
    setTrayVendor,
    trayWeight,
    setTrayWeight,
    selectedTrayCategory,
    trayImage,
    trayAnalysisScrews,
    traySurgeryId,
    traySurgery,
    selectedTrayType,
    setSelectedTrayType,
  } = useAddTrayContext()
  const { trayPlates } = useSPD()
  const { createTrayItem, loading: isCreatingTrayItem } =
    useCreateTrayItemMutation()
  const { inventorySheetFiles } = useInventorySheetsContext()

  /**
   * Handles changes to the tray barcode.
   * @param e - The event object for the input change.
   */
  const handleTrayBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrayBarcode(e.target.value)
  }

  /**
   * Toggles the visibility of the scanner.
   */
  const handleShowScannerButtonClick = () => {
    setShowScanner((prev) => !prev)
  }

  /**
   * Handles the scanning of a barcode.
   * @param code - The scanned barcode.
   */
  const handleCortexScan = (code: string) => {
    setTrayBarcode(code)
    setShowScanner(false)
  }

  /**
   * Handles changes to the tray description.
   * @param e - The event object for the input change.
   */
  const handleTrayDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTrayDescription(e.target.value)
  }

  /**
   * Handles changes to the selected location.
   * @param e - The event object for the select change.
   */
  const handleSelectedLocationChange = (e: SelectChangeEvent<number>) => {
    setSelectedLocationId(e.target.value as number)
  }

  /**
   * Handles changes to the tray vendor.
   * @param e - The event object for the select change.
   */
  const handleTrayVendorChange = (e: SelectChangeEvent<string>) => {
    setTrayVendor(e.target.value)
  }

  /**
   * Handles changes to the tray type.
   * @param e - The event object for the select change.
   */
  const handleTrayTypeChange = (e: SelectChangeEvent<string>) => {
    setSelectedTrayType(e.target.value.toLowerCase() as TrayType)
  }

  /**
   * Handles changes to the tray weight.
   * @param e - The event object for the input change.
   */
  const handleTrayWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = !Number.isNaN(e.target.valueAsNumber)
      ? e.target.valueAsNumber
      : undefined
    if (typeof value === 'number' && value >= 0) {
      setTrayWeight(value)
    }
  }

  /**
   * Renders the drop-off date and time.
   */
  const renderDropOffDateTime = useCallback(() => {
    if (isRep) {
      return (
        <Typography variant="h3" className="drop-off_date">
          Drop off Date/ Time: {dayjs(new Date()).format('LLLL')}
        </Typography>
      )
    }
    return null
  }, [isRep])

  /**
   * Renders the scanner component.
   */
  const renderScanner = useCallback(() => {
    if (showScanner) {
      return (
        <div className="scanner-container">
          <SPDCortexScanner canScan={showScanner} onScan={handleCortexScan} />
          <Skeleton className="skeleton" variant="rounded" animation="wave" />
        </div>
      )
    }
    return null
  }, [showScanner, handleCortexScan])

  /**
   * Navigates to the inventory sheets page.
   */
  const handleInventorySheetsClick = () => {
    navigate('./inventory-sheets')
  }

  /**
   * Navigates to the analyze tray page.
   */
  const handleAnalyzeTrayClick = () => {
    navigate('./analyze')
  }

  /**
   * Handles the creation of a tray item.
   */
  const handleCreateTrayItem = async () => {
    if (!isInputValid()) {
      alert('Please add all the required fields')
      return
    }

    const itemInput: CreateTrayItemInput = createTrayItemInput()

    try {
      const trayItem = await createTrayItem(itemInput)
      if (trayItem) {
        navigateToTrayManagement()
      } else {
        alert('Something went wrong. Could not create tray.')
      }
    } catch (error) {
      console.error('Error creating tray item:', error)
      alert(
        'An error occurred while creating the tray item. Please try again later.'
      )
    }
  }

  /**
   * Validates the input fields for creating a tray item.
   * @returns Whether the input is valid.
   */
  const isInputValid = (): boolean => {
    return (
      trayBarcode !== '' &&
      trayDescription !== '' &&
      !!selectedTrayCategory &&
      !!trayImage
    )
  }

  /**
   * Creates the input object for creating a tray item.
   * @returns The input object for creating a tray item.
   */
  const createTrayItemInput = (): CreateTrayItemInput => {
    return {
      companyName: 'Stryker',
      locationId: selectedLocationId as number,
      description: trayDescription,
      barcode: trayBarcode,
      isConsigned: selectedTrayCategory === 'consigned',
      productDetails: JSON.stringify({
        screws: trayAnalysisScrews,
        surgeryId: traySurgeryId,
        surgeryDetails: traySurgery,
        trayCategory: selectedTrayCategory,
        trayImage: trayImage?.src,
        inventorySheetFiles,
        trayWeight,
        trayType: selectedTrayType,
        plates: trayPlates,
      }),
      quantity: 1,
    }
  }

  /**
   * Navigates to the tray management page.
   */
  const navigateToTrayManagement = () => {
    navigate('/tray-management')
  }

  return {
    trayBarcode,
    setTrayBarcode,
    handleTrayBarcodeChange,
    showScanner,
    setShowScanner,
    handleShowScannerButtonClick,
    handleCortexScan,
    trayDescription,
    handleTrayDescriptionChange,
    selectedLocationId,
    handleSelectedLocationChange,
    trayVendor,
    setTrayVendor,
    handleTrayVendorChange,
    trayWeight,
    setTrayWeight,
    handleTrayWeightChange,
    renderDropOffDateTime,
    renderScanner,
    handleInventorySheetsClick,
    handleAnalyzeTrayClick,
    isCreatingTrayItem,
    handleCreateTrayItem,
    navigateToTrayManagement,
    selectedTrayType,
    handleTrayTypeChange,
  }
}

export default useAddTrayDetailsLogic
