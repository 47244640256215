import { Helmet } from 'react-helmet'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ErrorBoundary } from 'lib/monitoring'
import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import Routes from './Routes'
import AppProviders from './Providers'
import '../assets/styles/global.css'
import { getCSPContent } from 'common/cspHeaders'

function App() {
  return (
    <>
      <Helmet
        titleTemplate="MedGEO Platform | %s"
        defaultTitle="Welcome to the MedGEO Platform"
      >
        <meta httpEquiv="Content-Security-Policy" content={getCSPContent()} />
      </Helmet>
      <ErrorBoundary fallback={ErrorFallback}>
        <AppProviders>
          <Routes />
          <ReactQueryDevtools />
        </AppProviders>
      </ErrorBoundary>
    </>
  )
}

export default App
