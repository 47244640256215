import HomeIcon from 'assets/icons/svgs/Home Icon.svg'
import ConsignedIcon from 'assets/icons/svgs/Consigned Icon.svg'
import LoanerIcon from 'assets/icons/svgs/Loaner Icon.svg'
import { TrayType } from './AddTraySelectType.types'

export const trayTypes: TrayType[] = [
  {
    icon: HomeIcon,
    label: 'Facility Owned Tray',
    category: 'facility',
  },
  {
    icon: ConsignedIcon,
    label: 'Consigned Tray',
    category: 'consigned',
  },
  {
    icon: LoanerIcon,
    label: 'Loaner Tray',
    category: 'loaner',
  },
]
