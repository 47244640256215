import { ValidationError } from 'yup'
import { ValidateFormProps, ValidateFormResponse } from './types'

export const validateForm = async ({
  formData,
  schema,
}: ValidateFormProps): Promise<ValidateFormResponse> => {
  try {
    await schema.validate(formData, { abortEarly: false })
    return null
  } catch (error: unknown) {
    if (error instanceof ValidationError) {
      const errors = error.inner.reduce(
        (acc: Record<string, string>, err: ValidationError) => {
          if (err.path && formData.hasOwnProperty(err.path)) {
            acc[err.path] = err.message
          }
          return acc
        },
        {}
      )
      return errors
    }
    throw error
  }
}
