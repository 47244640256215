import { FC } from 'react'

// MUI
import { Typography } from '@mui/material'

// Types
import { IReportDetail } from './ReportDetail.types'

// Style
import './ReportDetail.scss'

const ReportDetail: FC<IReportDetail> = ({
  title,
  value,
  alignItems = 'center',
  valueStyle = { textAlign: 'start' },
  titleStyle = { color: 'var(--gray-3)' },
  action,
}) => (
  <div className="report-detail" style={{ alignItems: alignItems }}>
    <Typography style={valueStyle} className="value">
      {value} {action}
    </Typography>
    <Typography className="title" style={titleStyle}>
      {title}
    </Typography>
  </div>
)

export default ReportDetail
