import useTrayManagementFiltersLogic from 'components/organisms/TrayManagementFilters/TrayManagementFilters.logic'
import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'

import { SubFilter } from 'components/organisms/TrayManagementFilters/TrayManagementFilters.types'
import { TrayManagementFilterProps } from './TrayManagementFilter.types'

const useTrayManagementFilterLogic = ({
  filter,
}: TrayManagementFilterProps) => {
  const { filterValue, trayStatusFilterValue, setTrayStatusFilterValue } =
    useTrayConfigurationContext()
  const { handleFilterClick } = useTrayManagementFiltersLogic()

  const isOpen = filter.filterValue === filterValue

  /**
   * Check if a subfilter is active
   * @param subFilterValue - The value of the subfilter
   * @returns boolean indicating if the subfilter is active
   */
  const isSubfilterActive = (
    subFilterValue: SubFilter['filterValue']
  ): boolean => {
    return subFilterValue === trayStatusFilterValue
  }

  /**
   * Handle filter click event
   */
  const handleClick = (): void => {
    handleFilterClick(filter.filterValue)
  }

  /**
   * Handle subfilter click event
   * @param subFilterValue - The value of the subfilter
   */
  const handleSubFilterClick = (
    subFilterValue: SubFilter['filterValue']
  ): void => {
    const newSubFilterValue =
      subFilterValue === trayStatusFilterValue ? undefined : subFilterValue
    setTrayStatusFilterValue(newSubFilterValue)
  }

  return {
    isOpen,
    handleClick,
    isSubfilterActive,
    handleSubFilterClick,
  }
}

export default useTrayManagementFilterLogic
