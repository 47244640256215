import {
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import {
  ASSET_TYPES,
  EXPLANTED_REASON_LIST,
  getDispositionList,
  WASTED_REASON_LIST,
} from 'common/disposition'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { useEditDispositionModalLogic } from './EditDispositionModal.logic'
import MediaUpload from '../MediaUpload/MediaUpload'
import TextInput from 'components/molecules/TextInput/TextInput'
import { EditDispositionModalProps } from 'lib/context/EditDocumentedListContext/EditDispositionList.types'

export const EditDispositionModal = ({
  assetTray,
  dataTestId,
  isManualAddition,
  scans,
  isMultipack,
}: EditDispositionModalProps) => {
  const {
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSavingEditedAsset,
    setIsEditModalOpen,
    renderImplantSites,
    isConsumable,
    isEditModalOpen,
    handleReset,
    isSiteLocationEnabled,
    selectedAssetId,
    consumableAssetGroup,
    selectedAssetGroupId,
    isLoading,
  } = useEditDispositionModalLogic({ assetTray, isMultipack })

  return (
    <CustomModal
      open={isEditModalOpen}
      dataTestId={dataTestId}
      secondaryButtonAction={() => {
        handleReset()
        setIsEditModalOpen(false)
      }}
      header="Edit Disposition for Selected Product"
      primaryButtonDisabled={isSavingEditedAsset(selectedAssetGroupId)}
      isLoadingState={isLoading}
      handleOnSubmit={handleSubmit}
      primaryButtonText={'Save'}
    >
      <FormControl fullWidth sx={{ my: 2 }}>
        <Box>
          <InputLabel id="asset-type-label">Product Type</InputLabel>
          <Select
            fullWidth
            data-testid="asset-type-input"
            id="assetType"
            name="assetType"
            variant="outlined"
            labelId="asset-type-label"
            label="Product Type"
            value={formState?.assetType}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId) || isConsumable}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
              },
            }}
          >
            <MenuItem
              data-testid="hardware-menuitem"
              style={{ cursor: 'pointer', fontSize: 16 }}
              key={ASSET_TYPES[0]}
              value={ASSET_TYPES[0]}
            >
              Implantable Hardware/Assoc. Product
            </MenuItem>
            <MenuItem
              data-testid="other-menuitem"
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[2]}
              value={ASSET_TYPES[2]}
            >
              Implantable Other
            </MenuItem>
            <MenuItem
              data-testid="consumable-menuitem"
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[3]}
              value={ASSET_TYPES[3]}
            >
              Consumable
            </MenuItem>
          </Select>
        </Box>
      </FormControl>

      {formState?.assetType && !isConsumable && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="asset-tray-label">Disposition</InputLabel>
          <Select
            data-testid="implant-status-input"
            id="implantStatus"
            name="implantStatus"
            variant="outlined"
            labelId="disposition-label"
            label="Disposition"
            value={formState.implantStatus}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            sx={{
              backgroundColor: 'grayscale.lightest',
            }}
          >
            {getDispositionList({
              assetType: formState.assetType,
            }).map((disposition) => (
              <MenuItem
                data-testid={`${disposition.value}-menuitem`}
                key={disposition.value}
                value={disposition.value}
              >
                {disposition.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {renderImplantSites && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <AutocompleteInput
            dataTestId="implant-site-input"
            id="implantSite"
            name="implantSite"
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Site'
                : 'Explant Site'
            }
            value={formState?.implantSite}
            options={implantSiteList}
            handleChangeFormData={handleChangeFormData}
            sx={{
              my: 0,
              backgroundColor: 'grayscale.lightest',
            }}
            variant="outlined"
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            required={formState.implantStatus === 'IMPLANTED' ? true : false}
          />
        </FormControl>
      )}

      {isSiteLocationEnabled && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            id={`implant-site`}
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location'
                : 'Explant Location'
            }
            name={`siteLocation`}
            value={formState.siteLocation ?? ''}
            placeholder={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location'
                : 'Explant Location'
            }
            inputMode="text"
            sx={{ backgroundColor: 'grayscale.lightest' }}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
          />
        </FormControl>
      )}

      {formState?.implantStatus === 'WASTED' && !isConsumable && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="wasted-reason-label">Wasted Reason</InputLabel>
          <Select
            data-testid="wasted-reason-input"
            id="wastedReason"
            name="wastedReason"
            variant="outlined"
            labelId="wasted-reason-label"
            label="Wasted Reason"
            value={formState.wastedReason ?? ''}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            sx={{
              my: 0,
              backgroundColor: 'grayscale.lightest',
            }}
          >
            {WASTED_REASON_LIST.map((wastedReason) => (
              <MenuItem
                data-testid={`${wastedReason.value}-menuitem`}
                key={wastedReason.value}
                value={wastedReason.value}
              >
                {wastedReason.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {isManualAddition && formState?.implantStatus === 'EXPLANTED' && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="explanted-reason-label">Explant Reason</InputLabel>
          <Select
            data-testid="explant-reason-input"
            id="explantedReason"
            name="explantedReason"
            variant="outlined"
            labelId="explant-reason-label"
            label="Explant Reason"
            value={formState.explantedReason ?? ''}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            sx={{
              my: 0,
              backgroundColor: 'grayscale.lightest',
            }}
          >
            {EXPLANTED_REASON_LIST.map((reason) => (
              <MenuItem
                data-testid={`${reason.value}-menuitem`}
                key={reason.value}
                value={reason.value}
              >
                {reason.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {isManualAddition && formState?.explantedReason === 'OTHER' && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            id={`explanted-reason-note`}
            label="Other Reason"
            placeholder="Other Reason"
            name={`explantedReasonNote`}
            value={formState.explantedReasonNote ?? ''}
            inputMode="text"
            sx={{ backgroundColor: 'grayscale.lightest' }}
            onChange={handleChangeFormData}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
          />
        </FormControl>
      )}

      {isConsumable && (
        <TextInput
          handleChangeFormData={handleChangeFormData}
          id={`consumable-count-${consumableAssetGroup?.bidAssetId}`}
          label={'Items Used'}
          name={`count`}
          value={formState.count ?? ''}
          inputMode="numeric"
          variant="outlined"
          type="number"
          required
          disabled={isSavingEditedAsset(selectedAssetGroupId)}
          sx={{ mb: 1 }}
        />
      )}

      {!isConsumable && (
        <MediaUpload
          hasActions
          assetId={selectedAssetId || ''}
          fileNames={
            selectedAssetId
              ? (scans?.filter((item) => item._id === selectedAssetId)[0]
                  .media as string[])
              : []
          }
        />
      )}
    </CustomModal>
  )
}
