import { parseAssetIdentifiers } from '../ParseAssetIdentifiers/parseAssetIdentifiers'
import { GroupedAssetData } from '../grouped-asset-data'

export const getSpongeAssetGroups = (groupedAssetData: GroupedAssetData) => {
  return groupedAssetData.consumable.nurseScans
    .map((consumable) => {
      const { isRfMultipackConsumable } = parseAssetIdentifiers({
        deviceDescription: consumable.deviceDescription,
        deviceCount: consumable.deviceCount,
        idType: consumable.scans[0].secondaryDeviceIdType,
        assetType: consumable.scans[0].assetType,
      })
      return isRfMultipackConsumable ? consumable : null
    })
    .filter((sponge) => sponge !== null)
}
