import React, { FC } from 'react'
import { ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

import './Button.css'

interface IButton extends ButtonProps {
  icon?: React.ReactNode
  label?: string
  isLoading?: boolean
}

const Button: FC<IButton> = ({
  icon,
  children,
  variant,
  color,
  isLoading,
  ...props
}) => {
  return (
    <LoadingButton
      loading={isLoading}
      variant={variant}
      className={`custom-button ${color}`}
      {...props}
    >
      {icon ? icon : null}
      <Typography variant="body2">{children}</Typography>
    </LoadingButton>
  )
}

export default Button
