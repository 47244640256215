import { MenuItem, TextField, Typography } from '@mui/material'
import { ISelectInput } from './SelectInput.types'
import { makeStyles } from '@material-ui/core/styles'

function SelectInput({
  id,
  label,
  name,
  disabled = false,
  handleChangeFormData,
  options,
  placeholder = 'Select',
  required = false,
  value = '',
  itemStyles = {},
  sx = {},
}: ISelectInput) {
  const useStyles = makeStyles({
    select: {
      '& ul': {
        backgroundColor: 'white',
      },
      '& li': {
        position: 'relative',
      },
    },
  })

  const classes = useStyles()

  return (
    <div className="select-input-container">
      <TextField
        select
        fullWidth
        disabled={disabled}
        required={required}
        id={id}
        label={label}
        classes={{
          root: `select {
            background-color: "red"
          }`,
        }}
        name={name}
        value={value}
        onChange={handleChangeFormData}
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          displayEmpty: true,
          renderValue: value
            ? undefined
            : () => <Typography color={'gray'}>{placeholder}</Typography>,
          MenuProps: {
            classes: { paper: classes.select },
          },
        }}
        sx={{
          my: 2,
          textTransform: 'capitalize',
          ...sx,
        }}
        variant="standard"
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value}
            sx={{
              textTransform: 'capitalize',
              position: 'absolute',
              ...itemStyles,
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default SelectInput
