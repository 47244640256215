import * as yup from 'yup'

const usPhoneRegExp = /^\([2-9][0-9]{2}\) [2-9][0-9]{2}-[0-9]{4}$/
const nameRegExp = /^[a-zA-Z\s]+$/

export const signUpRepSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstName: yup
    .string()
    .required()
    .matches(nameRegExp, 'First name can only contain alphabetic characters'),
  lastName: yup
    .string()
    .required()
    .matches(nameRegExp, 'Last name can only contain alphabetic characters'),
  mobile: yup
    .string()
    .required()
    .matches(
      usPhoneRegExp,
      'Please enter your correct 10 digit number (XXX) XXX-XXXX'
    ),
  companyName: yup.string().required(),
})
