// Router
import { useLocation, useNavigate } from 'react-router-dom'

export const useLogic = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const parentPath = location.pathname.split('/')[1]
  const isRouteActive = (route: string) =>
    parentPath?.endsWith(`/${route}`) || location.pathname === route

  return {
    navigate,
    name: 'MedGEO User',
    picture: '',
    roles: ['Internal Team'],
    isRouteActive,
  }
}
