import { Helmet } from 'react-helmet'
import { DashboardIndex } from 'components/Dashboard/DashboardIndex'

function DashboardView() {
  return (
    <>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <DashboardIndex />
    </>
  )
}

export default DashboardView
