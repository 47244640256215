import { ArrowBack } from '@mui/icons-material'
import { Button, CircularProgress, Skeleton, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import ConfirmChangesModal from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayVisualization/Modals/ConfirmChangesModal/ConfirmChangesModal'
import { LoadingButton } from 'components/mui'

import useTrayApproval from './TrayApproval.logic'

import './TrayApproval.scss'
import TrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap'
import { ConfirmLoadMultipleScrewsModal } from 'components/organisms/ConfirmLoadMultipleScrewsModal/ConfirmLoadMultipleScrewsModal'

const TrayApproval = ({ isApproving }: { isApproving: boolean }) => {
  const {
    isInEditMode,
    handleEditModeChange,
    loading,
    error,
    trayItem,
    trayDetails,
    updating,
    handleUpdateTrayItem,
    handleViewDetailsClick,
    isConfirmationOpen,
    handleConfirmationClick,
    isLoaner,
    handleLoadWithUDIsClick,
    isLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
  } = useTrayApproval({ status: isApproving ? 'approved' : undefined })

  return (
    <div className="tray-approval_wrapper">
      <div className="tray-approval_header">
        <div className="left_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            to=".."
            component={Link}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <Typography variant="h1">
            {isApproving ? 'Approve' : 'Edit'} Tray Content
          </Typography>
        </div>

        <div className="right_container">
          {!trayItem || loading || error ? (
            <Skeleton variant="rounded" animation="wave" className="skeleton" />
          ) : (
            <img src={trayDetails?.trayImage} alt={trayItem.description} />
          )}
        </div>
      </div>

      {loading && <CircularProgress />}

      {!loading && error && (
        <Typography className="error" variant="body1">
          {error.message}
        </Typography>
      )}

      <ConfirmChangesModal
        open={isConfirmationOpen}
        onClose={handleConfirmationClick}
        handleConfirm={handleUpdateTrayItem}
        handleSaveChangesCancellation={handleConfirmationClick}
      />

      <ConfirmLoadMultipleScrewsModal
        open={isAdditionalDeviceCountModalOpen}
        onClose={() => {
          return
        }}
      />

      {!loading && !error && trayItem && (
        <>
          <div
            className="map_container"
            style={{ pointerEvents: isInEditMode ? 'auto' : 'none' }}
          >
            <TrayMap trayType={trayDetails?.trayType} />
          </div>

          <div className="buttons-container">
            <div className="left-container">
              <LoadingButton
                disabled={false}
                loading={false}
                variant="outlined"
                onClick={handleEditModeChange}
              >
                {isInEditMode ? 'View' : 'Edit'}
              </LoadingButton>

              {!isInEditMode ? (
                <LoadingButton
                  disabled={false}
                  loading={false}
                  variant="outlined"
                  onClick={handleViewDetailsClick}
                >
                  View Details
                </LoadingButton>
              ) : (
                !isLoaner && (
                  <LoadingButton
                    disabled={false}
                    loading={false}
                    variant="outlined"
                    onClick={handleLoadWithUDIsClick}
                  >
                    Load Tray with{' '}
                    {isLoadingWithUDIs ? 'Device Identifiers' : 'UDIs'}
                  </LoadingButton>
                )
              )}
            </div>

            <div className="right-container">
              <LoadingButton
                disabled={updating}
                loading={updating}
                variant="contained"
                onClick={handleConfirmationClick}
              >
                {isApproving ? 'Approve' : 'Confirm'}
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TrayApproval
