import { CircularProgress } from '@mui/material'

import SPDCortexVideo from './SPDCortexVideo/SPDCortexVideo'

import useSPDCortexScanner from './SPDCortexScanner.logic'
import { CortexDecoderProvider } from 'lib/cortex'
import { useSPDCortexScannerContext } from './SPDCortexScanner.context'

const SPDCortexScanner = ({
  canScan,
  onScan,
}: {
  canScan: boolean
  onScan: (code: string) => void
}) => {
  const { handleScan, currentScan, setCurrentScan } = useSPDCortexScanner({
    onScan,
  })
  const { selectedCamera } = useSPDCortexScannerContext()

  return (
    <CortexDecoderProvider
      canScan={canScan}
      onScan={handleScan}
      currentScan={currentScan}
      setCurrentScan={setCurrentScan}
      selectedCamera={selectedCamera}
    >
      {canScan ? <SPDCortexVideo onScan={handleScan} /> : <CircularProgress />}
    </CortexDecoderProvider>
  )
}

export default SPDCortexScanner
