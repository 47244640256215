// MUI
import {
  AppBar,
  Menu,
  Avatar,
  Divider,
  Box,
  Tooltip,
  ButtonBase,
  IconButton,
} from '@mui/material'

// Icons
import {
  Logout,
  NotificationsRounded,
  Password,
  Person,
  Search,
  SettingsRounded,
  Menu as MenuIcon,
} from '@mui/icons-material'

// Logic
import { useLogic } from './Navbar.logic'

// Style
import './Navbar.css'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'

const Navbar = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const {
    handleClick,
    handleClose,
    open,
    anchorElMenu,
    handleLogout,
    email,
    picture,
    roles,
    renderMenuItem,
  } = useLogic()
  const isTablet = useIsTablet()
  return (
    <AppBar sx={{ color: 'white' }}>
      <div
        className="toolbar"
        style={{ justifyContent: isTablet ? 'space-between' : 'end' }}
      >
        {isTablet && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Box className="right-container">
          <Search />
          <Tooltip sx={{ p: 0, m: 0 }} title="Account settings">
            <ButtonBase sx={{ p: 0, m: 0 }} onClick={handleClick}>
              <SettingsRounded />
            </ButtonBase>
          </Tooltip>
          <NotificationsRounded />
          <Menu
            anchorEl={anchorElMenu}
            id="account-menu"
            open={open}
            onClose={handleClose}
            className="account-menu"
            sx={{
              ml: -2,
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {renderMenuItem(<Avatar src={picture} />, email)}
            {roles && renderMenuItem(<Person />, roles.join(', '))}
            <Divider />
            {renderMenuItem(<Password fontSize="small" />, 'Change password')}
            {renderMenuItem(
              <Logout fontSize="small" />,
              'Logout',
              handleLogout
            )}
          </Menu>
        </Box>
      </div>
    </AppBar>
  )
}

export default Navbar
