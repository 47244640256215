import { useQuery, QueryResult } from '@apollo/client'
import { GetCompaniesParams, GetCompaniesResponse } from './types'
import { captureWarning } from 'lib/monitoring'
import { GET_COMPANIES } from 'lib/services/api/schema'

const useGetCompanies = (
  params: GetCompaniesParams
): QueryResult<GetCompaniesResponse, GetCompaniesParams> =>
  useQuery(GET_COMPANIES, {
    variables: params,
    skip: !params.enabled,
    onError: (error: Error) => {
      captureWarning(error)
    },
  })

export default useGetCompanies
