import { Grid, Typography, Divider, Box, Skeleton } from '@mui/material'
import { Procedures, Provider, Surgery } from 'common/types'

export interface ProcedureDetailsCardProps {
  procedureDate: Procedures['dateTime']
  procedureType: Procedures['description']
  procedureDuration: Procedures['duration']
  surgeon: Provider['lastName']
  totalSurgeryCost: Surgery['totalSurgeryCost']
  loading?: boolean
}

export const ProcedureDetailsCard = ({
  procedureDate,
  procedureType,
  procedureDuration,
  surgeon,
  totalSurgeryCost,
  loading,
}: ProcedureDetailsCardProps) => {
  return (
    <Grid
      data-testid="procedure-details-grid"
      item
      xs={4.5}
      md={4.5}
      sx={{
        backgroundColor: 'grayscale.light',
        borderRadius: '7px',
        p: 1,
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h3" sx={{ m: 1 }}>
            Procedure Details
          </Typography>
          <Divider />
        </Grid>
        <Grid item sx={{ m: 1 }}>
          <Box flexDirection={'row'} display={'flex'}>
            <Typography variant="h5" sx={{ mr: 1 }}>
              Procedure Date/Time:
            </Typography>
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Typography variant="h6">{procedureDate}</Typography>
            )}
          </Box>

          <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
            <Typography variant="h5" sx={{ mr: 1 }}>
              Procedure Type:
            </Typography>
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Typography variant="h6">{procedureType}</Typography>
            )}
          </Box>

          <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
            <Typography variant="h5" sx={{ mr: 1 }}>
              Procedure Duration:
            </Typography>
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Typography variant="h6">{procedureDuration} minutes</Typography>
            )}
          </Box>

          <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
            <Typography variant="h5" sx={{ mr: 1 }}>
              Surgeon:
            </Typography>
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Typography variant="h6">{surgeon}</Typography>
            )}
          </Box>

          <Box flexDirection={'row'} display={'flex'} sx={{ mt: 1 }}>
            <Typography variant="h5" sx={{ mr: 1 }}>
              Total Surgery Cost:
            </Typography>
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <Typography variant="h6">
                {totalSurgeryCost || '$XXXX.00'}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
