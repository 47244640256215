import { Link } from 'react-router-dom'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

function NoProcedure() {
  return (
    <PageDisplay>
      <Typography variant="h1">No such procedure exists</Typography>
      <Typography>
        You must join an existing procedure to view or add products.
      </Typography>
      <Button
        to="/home"
        component={Link}
        variant="contained"
        sx={{ my: 1 }}
        disableElevation
      >
        Select a procedure
      </Button>
    </PageDisplay>
  )
}

export default NoProcedure
