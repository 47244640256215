import { genFormValidationAlertMsg } from 'lib/utils/genFormValidationAlertMsg'
import { z } from 'zod'

export const submitToEmrValidationSchema = (data: Record<string, any>) => {
  const validateSchema = z
    .record(z.string(), z.any())
    .refine(
      // best for known key values
      () => data.numberOfExpired === '0' || data.numberOfExpired === undefined,
      {
        message: genFormValidationAlertMsg({
          path: 'numberOfExpired',
          context: { numberOfExpired: data.numberOfExpired },
        }),
        path: ['numberOfExpired'],
      }
    )
    // TODO: move this into validation for RfSpongeCountPanel oncee a <form> can be recognized by the DOM on that component
    // .refine( // sponge count validation
    //   () =>
    //     data.hasRfMultipackConsumable === 'false' ||
    //     data.accurateDeviceCount === 'true' ||
    //     (data.deviceCountCorrectiveActionTaken === 'true' && data.memo),
    //   {
    //     message: genFormValidationAlertMsg({
    //       path: 'rfMultipackConsumable',
    //     }),
    //     path: ['rfMultipackConsumable'],
    //   }
    // )
    .refine(
      () => data.undocumentedScansCount === '0', // should trigger when undocumentedScansCount is not 0
      {
        message: genFormValidationAlertMsg({
          path: 'remainingUndocumentedScans',
        }),
        path: ['remainingUndocumentedScans'],
      }
    )
    .refine(
      () => data.submitToEmrConfirm === 'true', // should always trigger
      {
        message: genFormValidationAlertMsg({
          path: 'submitToEmrConfirm',
        }),
        path: ['submitToEmrConfirm'],
      }
    )

  return validateSchema.safeParse(data)
}
