import { ChangeEvent } from 'react'

import { useUser } from 'app/User'
import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'
import useInventoryFiltersLogic from 'views/InventoryReport/inventory-filters/InventoryFilters.logic'

import { Filter, FilterValue, SubFilter } from './TrayManagementFilters.types'

const GENERAL_SUBFILTERS: SubFilter[] = [
  { title: 'Ready for Pickup', filterValue: 'readyForPickup' },
  { title: 'Approved', filterValue: 'approved' },
  { title: 'Rejected', filterValue: 'rejected' },
  { title: 'Dropped Off', filterValue: 'droppedOff' },
  { title: 'Ready to Fill', filterValue: 'readyToFill' },
  { title: 'Assigned', filterValue: 'assigned' },
]

const NURSE_FILTERS: Filter[] = [
  {
    title: 'Hospital Trays',
    filterValue: 'facility',
    subFilters: GENERAL_SUBFILTERS,
  },
]

const useTrayManagementFiltersLogic = () => {
  const { isNurse } = useUser()
  const {
    setFilterValue,
    trayIdFilterValue,
    setTrayIdFilterValue,
    companyFilterValue,
    setCompanyFilterValue,
  } = useTrayConfigurationContext()
  const { companies, setCompany, isLoadingCompanies } =
    useInventoryFiltersLogic()

  const listOfFilters: Filter[] = [
    { title: 'All Trays', filterValue: '', subFilters: GENERAL_SUBFILTERS },
    ...(isNurse ? NURSE_FILTERS : []),
    {
      title: 'Loaner Trays',
      filterValue: 'loaner',
      subFilters: GENERAL_SUBFILTERS,
    },
    {
      title: 'Consignment Trays',
      filterValue: 'consigned',
      subFilters: GENERAL_SUBFILTERS,
    },
  ]

  /**
   * Handle filter click event
   * @param filter - The filter value to set
   */
  const handleFilterClick = (filter: FilterValue): void => {
    setFilterValue(filter)
  }

  /**
   * Handle tray ID filter change event
   * @param e - The change event from the input
   */
  const handleTrayIdFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setTrayIdFilterValue(e.target.value)
  }

  return {
    listOfFilters,
    handleFilterClick,
    trayIdFilterValue,
    handleTrayIdFilterChange,
    companies,
    setCompany,
    isLoadingCompanies,
    companyFilterValue,
    setCompanyFilterValue,
  }
}

export default useTrayManagementFiltersLogic
