// Components
import Header from 'components/molecules/Header/Header'

// Logic
import AddLocation from './AddLocation/AddLocation'
import useLogic from './LocationManagement.logic'

// Types
import { InventoryLocation } from './LocationManagement.types'

// Styles
import './LocationManagement.scss'
import { PlusCircleOutlined } from '@ant-design/icons'

// DND
import { Droppable, DragDropContext, Draggable } from '@hello-pangea/dnd'
import { CircularProgress } from '@mui/material'

const LocationManagement = () => {
  const {
    selectedLocations,
    locations,
    maxDepth,
    mapName,
    addLocationModalOpen,
    isDragging,
    isLoadingLocations,
    isLoadingCreateLocations,
    isEditing,
    isLoadingUpdateLocations,
    locationModalId,
    handleCancelEditLocations,
    handleUpdateLocationsTree,
    handleDragStart,
    handleAddLocationClick,
    handleCloseAddLocation,
    handleLocationClick,
    onDragEnd,
    handleCreateLocation,
  } = useLogic()

  return (
    <div className="location-management-container">
      <Header
        title="Location Management"
        parent="Reports"
        actions={
          isEditing
            ? [
                {
                  title: 'Cancel',
                  onClick: () => handleCancelEditLocations(),
                  variant: 'outlined',
                },
                {
                  title: 'Save',
                  onClick: () => handleUpdateLocationsTree(),
                  isLoading: isLoadingUpdateLocations,
                },
              ]
            : []
        }
      />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
        {locations && !isLoadingLocations && (
          <div className="columns-container">
            {Array.from({ length: maxDepth }).map((_, index) => {
              const filteredLocations = locations.filter((dep) =>
                index === 0
                  ? dep.parentLocationId === null
                  : dep.parentLocationId === selectedLocations[index - 1]
              )
              const colParentIndex =
                index === 0 ? null : selectedLocations[index - 1]

              const colParent = locations.find(
                (location) => location.id === colParentIndex
              )

              const canAdd =
                maxDepth === 1 ||
                filteredLocations.length > 0 ||
                (selectedLocations[index - 1] && index <= 3)

              const isDragDisabled =
                index === 0 || !selectedLocations[index - 1]

              const isDropDisabled = selectedLocations[index] === colParentIndex

              const showDisabledColumns =
                isDragging && (isDropDisabled || isDragDisabled || !canAdd)

              return (
                <div
                  key={index}
                  className={`column ${
                    showDisabledColumns ? 'is-dragging-disabled' : ''
                  }`}
                >
                  {/* Render column header */}
                  <div className="column-header">
                    <div>{mapName[index]}</div>
                    <div className="column-actions">
                      {canAdd && !isEditing && (
                        <PlusCircleOutlined
                          className="add-icon"
                          onClick={() => {
                            handleAddLocationClick(
                              mapName[index],
                              colParent as InventoryLocation
                            )
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <Droppable
                    droppableId={index.toString()}
                    type="list"
                    direction="vertical"
                    isDropDisabled={isDropDisabled}
                  >
                    {(provided) => (
                      <div
                        className="column-body"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {/* Render rows inside the Droppable */}
                        {locations
                          .filter((dep) =>
                            index === 0
                              ? dep.parentLocationId === null
                              : dep.parentLocationId ===
                                selectedLocations[index - 1]
                          )
                          .map((row, rowIndex) => (
                            <Draggable
                              key={row.id}
                              draggableId={row.id.toString()}
                              index={rowIndex}
                              isDragDisabled={isDragDisabled}
                            >
                              {(provided) => (
                                <div
                                  className={`row ${
                                    selectedLocations[index] === row.id
                                      ? 'selected'
                                      : `${
                                          selectedLocations[index] === undefined
                                            ? ''
                                            : 'unselected'
                                        }`
                                  }`}
                                  onClick={() =>
                                    handleLocationClick(index, row.id)
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {row.name}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )
            })}
          </div>
        )}
        {isLoadingLocations && (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
      </DragDropContext>

      <AddLocation
        key={locationModalId}
        open={addLocationModalOpen?.open as boolean}
        parent={addLocationModalOpen?.parent as InventoryLocation}
        locationName={addLocationModalOpen?.locationName as string}
        onClose={handleCloseAddLocation}
        onSave={(params) => handleCreateLocation(params)}
        isLoading={isLoadingCreateLocations}
      />
    </div>
  )
}

export default LocationManagement
