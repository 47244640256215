import { FC } from 'react'

// MUI
import { Box, Stack } from '@mui/material'

// Utils
import { useIsScannerScreenMidSize } from 'lib/utils/mediaQueries'

// Types
import { CreateProductRequestResponse } from 'lib/services/api/product-service/requestProducts/types'
import { ProcessedProduct } from '../process-modal/ProcessModal.types'

// Components
import ScannedItem from '../scanned-item/ScannedItem'
import SummaryHeader from '../summary-header/SummaryHeader'
import StaticSkeleton from 'components/molecules/StaticSkeleton/StaticSkeleton'

interface IScannedItems {
  processedItems: ProcessedProduct[]
  implantItemsCount: number
  otherItemsCount: number
  hardwareItemsCount: number
  totalCount: number
  handelDeleteItem: (index: number) => void
  errors: CreateProductRequestResponse[]
}

const ScannedItems: FC<IScannedItems> = ({
  processedItems,
  implantItemsCount,
  otherItemsCount,
  hardwareItemsCount,
  totalCount,
  handelDeleteItem,
  errors,
}) => {
  const isScannerScreenMidSize = useIsScannerScreenMidSize()

  return (
    <div className="result">
      <Stack padding={isScannerScreenMidSize ? 0 : 2.5} gap={1}>
        <Box padding={isScannerScreenMidSize ? 2.5 : 0}>
          <SummaryHeader
            totalImplants={implantItemsCount}
            totalOtherProducts={otherItemsCount}
            totalScanned={totalCount}
            totalHardwareProducts={hardwareItemsCount}
          />
        </Box>
        {processedItems.map((item, index) => (
          <ScannedItem
            key={`${item.udi}-${index}`}
            index={index}
            udi={item.udi}
            expiryDate={item.expiryDate}
            flag={item.flag}
            lotBatch={item.lotBatch}
            modelNumber={item.modelNumber}
            name={item.description}
            serialNumber={item.serialNumber}
            comment={item.comment}
            disposition={item.disposition}
            description={item.description}
            packageIntegrity={item.packageIntegrity}
            productType={item.productType}
            temperature={item.temperature}
            quantity={item.quantity}
            onDelete={() => handelDeleteItem(index)}
            isNew={item.type === 'new'}
            isError={errors[0]?.udi === item.udi}
            extraCodes={item.extraCodes}
          />
        ))}
        {!isScannerScreenMidSize &&
          Array.from(
            { length: Math.max(6 - (processedItems?.length ?? 0), 0) },
            (_, index) => <StaticSkeleton key={`skeleton-${index}`} />
          )}
      </Stack>
    </div>
  )
}

export default ScannedItems
