import ReactApexChart from 'react-apexcharts'
import dayjs from 'lib/dayjs'
import { Box } from '@mui/material'
import { theme } from 'assets/styles/customTheme'
import { SurgeryByMonthObject } from '../utils/getDashboardStats'
import { useDashboardContext } from 'lib/context/DashboardContext'

interface ProceduresThisMonthChartProps {
  surgeriesByMonth: SurgeryByMonthObject[]
}

export const ProceduresThisMonthChart = ({
  surgeriesByMonth,
}: ProceduresThisMonthChartProps) => {
  const { darkMode } = useDashboardContext()

  const xAxisLabels: Array<string> = Object.values(surgeriesByMonth).map(
    (month: SurgeryByMonthObject) => {
      return dayjs().month(month.month).format('MMM')
    }
  )

  const dataPoints: Array<number> = Object.values(surgeriesByMonth).map(
    (month: SurgeryByMonthObject) => {
      return month.surgeries.length
    }
  )

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      style: {
        fontSize: '12px',
        color: darkMode
          ? theme.palette.grayscale.lightest
          : theme.palette.grayscale.dark,
        enabled: true,
        enabledOnSeries: [1],
      },
    },
    labels: xAxisLabels,
    series: [
      {
        name: 'Procedures',
        data: dataPoints,
      },
    ],
    yaxis: {
      type: 'numeric',
      title: {
        text: 'Procedures',
        style: {
          fontSize: '12px',
          color: darkMode
            ? theme.palette.grayscale.lightest
            : theme.palette.grayscale.dark,
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          colors: darkMode
            ? theme.palette.grayscale.lightest
            : theme.palette.grayscale.dark,
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: darkMode
            ? theme.palette.grayscale.lightest
            : theme.palette.grayscale.dark,
        },
      },
    },
  }
  return (
    <Box aria-label="procedures-this-month-box">
      <ReactApexChart options={options} series={options?.series} type="line" />
    </Box>
  )
}
