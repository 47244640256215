import { Button, Stack, Typography } from '@mui/material'
import { Surgery } from 'common/types'
import Dot from 'components/atoms/@extended/Dot'
import { Link } from 'react-router-dom'

interface AssetStatusProps {
  status: number
  surgeryId: Surgery['_id']
}

export const AssetStatus = ({ status, surgeryId }: AssetStatusProps) => {
  let color
  let title

  switch (status) {
    case 0:
      color = 'success'
      title = 'Submitted'
      break
    case 1:
      color = 'warning'
      title = 'Scanning'
      break
    default:
      color = 'primary'
      title = 'Pending'
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Button
        variant="text"
        size="large"
        component={Link}
        to={`/surgeries/${surgeryId}/list`}
      >
        <Typography variant="h4">{title}</Typography>
      </Button>
    </Stack>
  )
}
