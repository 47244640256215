import React, { FC, Fragment, useState } from 'react'
// MUI
import { Stack } from '@mui/material'

// Components
import Navbar from 'components/organisms/navbar/Navbar'
import SideBar from 'components/organisms/sidebar/Sidebar'

// Style
import './InternalManagamentLayout.css'

import { Outlet } from 'react-router-dom'

// Icons
import DashboardIcon from 'assets/icons/svgs/Dashboard.svg'
import DashboardActiveIcon from 'assets/icons/svgs/Dashboard-active.svg'
import { Insights } from '@mui/icons-material'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'

const InternalManagamentLayout: FC = () => {
  const iconSize = '20px'
  const isTablet = useIsTablet()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const onDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev)
  }

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      onDrawerToggle()
    }
  return (
    <Fragment>
      <Navbar toggleDrawer={onDrawerToggle} />
      <Stack direction="row">
        <SideBar
          toggleDrawer={toggleDrawer}
          open={isDrawerOpen}
          onDrawerToggle={onDrawerToggle}
          groups={[
            {
              title: 'Main',
              item: [
                {
                  key: 'Dashboard',
                  route: '/',
                  icon: {
                    active: (
                      <img
                        src={DashboardActiveIcon}
                        alt="icon"
                        height={iconSize}
                        width={iconSize}
                      />
                    ),
                    normal: (
                      <img
                        src={DashboardIcon}
                        alt="icon"
                        height={iconSize}
                        width={iconSize}
                      />
                    ),
                  },
                },
              ],
            },
            {
              title: 'DTM',
              item: [
                {
                  key: 'DTM Results',
                  route: 'dtm-results',
                  icon: {
                    active: (
                      <Insights
                        sx={{
                          width: iconSize,
                          height: iconSize,
                          color: '#5035ED',
                        }}
                      />
                    ),
                    normal: (
                      <Insights
                        sx={{
                          width: iconSize,
                          height: iconSize,
                          color: 'black',
                        }}
                      />
                    ),
                  },
                },
              ],
            },
            // {
            //   title: 'Reports',
            //   item: [
            //     {
            //       key: 'Product Requests',
            //       route: PRODUCT_REQUESTS,
            //       icon: {
            //         active: (
            //           <img src={SalesRepActiveIcon} alt="icon" height={iconSize} width={iconSize} />
            //         ),
            //         normal: (
            //           <img src={SalesRepIcon} alt="icon" height={iconSize} width={iconSize} />
            //         ),
            //       },
            //     },
            //   ],
            // },
            // {
            //   title: 'Analysis',
            //   item: [
            //     {
            //       key: 'Digital Tray Mapping',
            //       route: DTM,
            //       icon: {
            //         active: <Memory sx={{ fontSize: 20, color: '#4C36E4' }} />,
            //         normal: <Memory sx={{ fontSize: 20 }} />,
            //       },
            //     },
            //   ],
            // },
          ]}
        />
        <div className={`children ${isTablet ? 'is-tablet' : ''}`}>
          <Outlet />
        </div>
      </Stack>
    </Fragment>
  )
}

export default InternalManagamentLayout
