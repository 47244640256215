import { useNavigate } from 'react-router-dom'
import {
  Box,
  Modal,
  Typography,
  Button,
  useMediaQuery,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Check, Close, CopyAll } from '@mui/icons-material'
import { useCopyToClipboard } from 'react-use'
import { CurrentScanDetails } from 'lib/scan-handler'
import { BetterIdResultItem } from './BetterIdResultItem'

interface BetterIdResultsModalProps {
  currentScanValue?: string
  results: CurrentScanDetails['results']
  selectedResult: CurrentScanDetails['selectedResult']
  setSelectedResult: any // CurrentScanDetails['setSelectedResult']
  valueType: CurrentScanDetails['type']
  onClose: () => void
}

const BetterIdResultsModal = ({
  currentScanValue,
  valueType,
  results,
  selectedResult,
  setSelectedResult,
  onClose,
}: BetterIdResultsModalProps) => {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))
  const [isCopied, copyToClipboard] = useCopyToClipboard()
  const navigate = useNavigate()

  const isOpen = Boolean(
    results && results.length > 1 && typeof selectedResult === 'undefined'
  )

  return (
    <Modal
      open={isOpen}
      BackdropProps={{ sx: { backgroundColor: 'rgba(40, 40, 40, 0.90)' } }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      disableAutoFocus
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          position: 'relative',
          overflow: 'hidden',
          maxWidth: '90%',
        }}
      >
        <Box p={2}>
          <Typography
            variant="h3"
            sx={{
              position: 'relative',
              top: 8,
              left: 2,
              fontSize: 20,
            }}
          >
            Capture Lookup Results{' '}
            <Typography
              color="gray"
              fontSize={14}
              display="inline-block"
              sx={{
                position: 'relative',
                bottom: 2,
              }}
            >
              ({results?.length})
            </Typography>
          </Typography>
          <Box mt={2.5} mr={5} pl={0.5}>
            <Typography
              variant="body1"
              fontSize={14}
              fontWeight={600}
              sx={{ color: '#555' }}
            >
              Capture Value{' '}
              <Typography
                color="gray"
                fontSize={13}
                display="inline-block"
                fontStyle="italic"
              >
                (
                {valueType === 'udi'
                  ? 'Looks like a UDI'
                  : 'Does not look like a UDI'}
              </Typography>{' '}
              -{' '}
              <Typography
                color="gray"
                fontSize={13}
                display="inline-block"
                fontStyle="italic"
              >
                {currentScanValue?.length} characters)
              </Typography>
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                color="#555"
                fontSize={13}
                sx={{
                  wordWrap: 'break-word',
                  width: '100%',
                }}
              >
                {currentScanValue}{' '}
                <Tooltip title="Copy Capture Value">
                  <IconButton
                    aria-label="copy"
                    size="small"
                    onClick={() => copyToClipboard(currentScanValue ?? '')}
                    sx={{ position: 'relative', left: 2, bottom: 2 }}
                  >
                    {Boolean(isCopied.value) ? (
                      <Check fontSize="small" />
                    ) : (
                      <CopyAll fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>
          </Box>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, zIndex: 5 }}
        >
          <Close fontSize="large" />
        </IconButton>
        <Box
          sx={{
            maxWidth: 600,
            width: '100%',
            mx: 'auto',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              px: isSm ? 4 : 2,
              py: 2,
              maxHeight: 'calc(90vh - 200px)',
              overflowY: 'auto',
              background: '#f5f5f5',
              borderTop: '1px solid #eee',
            }}
          >
            {results?.map((result, i) => (
              <BetterIdResultItem
                key={result.id}
                result={result}
                index={i}
                onSelect={setSelectedResult}
              />
            ))}
          </Box>
        </Box>
        <Box
          p={1.25}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          columnGap={2}
          sx={{
            borderTop: '1px solid #eee',
          }}
        >
          <Button
            onClick={() => {
              navigate('../manual', {
                state: {
                  scannedCode: currentScanValue,
                },
              })
            }}
          >
            Add manually
          </Button>
          <Button
            variant="text"
            sx={{
              color: '#333',
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default BetterIdResultsModal
