import React, { FC } from 'react'

// MUI
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { Avatar, ButtonBase, Drawer, Stack, Typography } from '@mui/material'

// Style
import './SideBar.css'

// Logic
import { useLogic } from './SideBar.logic'

// Types
import { ISideBar } from './SideBar.types'

// utils
import { useIsTablet } from 'lib/utils/mediaQueries'

// Icons
import MedGeoLogo from 'assets/Logo-web.svg'

const SideNav: FC<ISideBar> = ({ groups, onDrawerToggle }) => {
  const { name, roles, picture, isRouteActive, navigate } = useLogic()
  const isTablet = useIsTablet()

  const handleClick = (route: string) => {
    navigate(route)
    if (onDrawerToggle) onDrawerToggle(false)
  }

  return (
    <Box className="side-nav" sx={{ position: isTablet ? '' : 'fixed' }}>
      <CssBaseline />
      <Stack gap={2}>
        <Box>
          <img src={MedGeoLogo} alt="MedGeo Logo" height={35} />
        </Box>

        {/* PROFILE */}
        <div className="dashboard-group">
          <Typography variant="caption" color="var(--gray-3)">
            Hospital
          </Typography>
          <div className="avatar-container">
            <Avatar sx={{ width: 83, height: 83 }} src={picture} />
            <Typography mt={1} variant="body1">
              {name}
            </Typography>
            <Typography variant="caption" color="gray">
              {roles?.join(', ')}
            </Typography>
          </div>
        </div>

        {groups?.map((groupItem, index) => (
          <div className="dashboard-group" key={index}>
            <Typography variant="caption" color="var(--gray-3)">
              {groupItem.title || ''}
            </Typography>
            {groupItem.item.map((item, index) => (
              <ButtonBase
                key={index}
                onClick={() => handleClick(item.route)}
                className="dashboard-item"
              >
                {isRouteActive(
                  `/internal${item.route !== '/' ? `/${item.route}` : ''}`
                )
                  ? item.icon.active
                  : item.icon.normal}
                <Typography
                  variant="body1"
                  color={`${
                    isRouteActive(
                      `/internal${item.route !== '/' ? `/${item.route}` : ''}`
                    )
                      ? 'var(--primary)'
                      : 'inherit'
                  }`}
                >
                  {item.key}
                </Typography>
              </ButtonBase>
            ))}
          </div>
        ))}
      </Stack>
    </Box>
  )
}

const SideBar: FC<ISideBar> = ({ open, onDrawerToggle, groups }) => {
  const isTablet = useIsTablet()
  const toggleDrawer = (open: boolean) => () =>
    onDrawerToggle && onDrawerToggle(open)

  return (
    <>
      {isTablet ? (
        <Drawer
          anchor="left"
          open={open}
          onClose={toggleDrawer(false)}
          sx={{ height: '100vh' }}
          transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
        >
          <SideNav onDrawerToggle={onDrawerToggle} groups={groups} />
        </Drawer>
      ) : (
        <SideNav groups={groups} />
      )}
    </>
  )
}

export default SideBar
