import { Alert, Typography } from '@mui/material'
import { flexBox } from 'common/styles/mixins'
import styled from 'styled-components'

export const MainContainer = styled.div`
  ${flexBox('flex', 'flex-start', 'center', 'column', '2rem')}
  padding: 2rem;
  max-width: 500px;
`

export const Header = styled.div`
  ${flexBox('flex', 'unset', 'unset', 'column', '0.5rem')}
`

export const Title = styled.div`
  font-weight: bold;
`

export const Subtitle = styled.div`
  font-size: 0.85rem;
`

export const FormContainer = styled.form`
  ${flexBox('flex', 'center', 'unset', 'column', '1rem')}
  width: 100%;
`

export const StyledTypography = styled(Typography)`
  text-align: center;
`

export const StyledAlert = styled(Alert)`
  ${flexBox('flex', 'center', 'center', 'column', 'unset')}
  &.css-1qfg3q2-MuiPaper-root-MuiAlert-root {
    border: 2.5px solid var(--primary);
  }
  &.css-1qfg3q2-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
    color: var(--primary);
  }
`

export const AlertContainer = styled.div`
  ${flexBox('flex', 'center', 'center', 'column', '1rem')}
`
