import { FC } from 'react'

// Components
import { ButtonBase, CircularProgress, Typography } from '@mui/material'

// Types
import { ImageDetailsProps } from './ImageDetails.types'

// Icons
import { Delete } from '@mui/icons-material'

// Style
import './ImageDetails.scss'

// Other
import classNames from 'classnames'

const ImageWithDetails: FC<ImageDetailsProps> = ({
  file,
  alt,
  src,
  disableActions,
  handleDelete,
}) => {
  return (
    <div
      className={classNames('image-details-container', {
        disabled: disableActions,
      })}
    >
      <div className="image-details">
        <img className="image" src={src} alt={alt} />
        <div className="details">
          {file && <Typography className="filename">{file.name}</Typography>}
          {file && (
            <Typography variant="caption">
              {(file.size / 1024).toFixed(2)} KB
            </Typography>
          )}
        </div>
      </div>
      <Delete
        className="delete-icon"
        onClick={() => {
          if (disableActions) return
          handleDelete()
        }}
      />
    </div>
  )
}

export const ImagePreview: FC<ImageDetailsProps> = ({
  alt,
  src,
  isDeleting,
  hasActions,
  disableActions,
  handleDelete,
  onClick,
}) => {
  return (
    <ButtonBase
      className="image-preview-container"
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      <div className="image-container">
        <img className="image" src={src} alt={alt} />
        {hasActions && (
          <div
            className={classNames('delete-icon-container', {
              'is-deleting': isDeleting,
              disabled: disableActions,
            })}
          >
            {isDeleting ? (
              <CircularProgress size={28} />
            ) : (
              <Delete
                className="delete-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  if (disableActions) return
                  handleDelete()
                }}
              />
            )}
          </div>
        )}
      </div>
    </ButtonBase>
  )
}

export default ImageWithDetails
