import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { CustomModal } from '../Modal/Modal'
import { CustomModalProps } from '../Modal/Modal.types'

interface StatusBarProps {
  icon: React.ReactNode
  message: string
  color?: string
  moreInfo?: string
  isLoadingState?: CustomModalProps['isLoadingState']
  primaryButtonText?: CustomModalProps['primaryButtonText']
  handleOnSubmit?: CustomModalProps['handleOnSubmit']
}

export const StatusBar = ({
  icon,
  message,
  color = 'primary',
  moreInfo,
  isLoadingState = false,
  primaryButtonText = 'Submit',
  handleOnSubmit,
}: StatusBarProps) => {
  const [moreInfoModal, setMoreInfoModal] = useState(false)
  const renderCustomModal = moreInfo && moreInfoModal

  return (
    <Box
      alignItems={'center'}
      justifyContent={'flex-start'}
      sx={{
        width: '100%',
        my: 1,
        p: 1,
        borderRadius: 1,
        display: 'flex',
        bgcolor: `${color}.lightest`,
        color: `${color}.dark`,
      }}
    >
      {icon}
      <Typography
        variant="h6"
        sx={{ lineHeight: 1.2, ml: 1, alignSelf: 'center', textWrap: 'pretty' }}
      >
        {message}
      </Typography>
      {moreInfo && (
        <Box
          onClick={() => setMoreInfoModal(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            ml: 'auto',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              mr: 1,
              textWrap: 'nowrap',
            }}
          >
            Error Details
          </Typography>
        </Box>
      )}
      {renderCustomModal && (
        <CustomModal
          header={'Error Details'}
          open={moreInfoModal}
          primaryButtonText={primaryButtonText}
          handleOnSubmit={handleOnSubmit && handleOnSubmit}
          secondaryButtonAction={() => setMoreInfoModal(false)}
          isLoadingState={isLoadingState}
          closeButtonOnly={handleOnSubmit ? false : true}
        >
          <Typography
            variant="h4"
            color="primary.contrastText"
            sx={{ textAlign: 'center' }}
          >
            {moreInfo}
          </Typography>
        </CustomModal>
      )}
    </Box>
  )
}
