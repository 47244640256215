// @typescript-eslint/no-explicit-any

import { FC } from 'react'

// Logic
import { useLogic } from './ProcessModal.logic'

// MUI
import {
  Box,
  ButtonBase,
  Fade,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// Icons
import DoneIcon from '@mui/icons-material/Done'

// Components
import Modal from 'components/molecules/Modal/Modal'
import Button from 'components/molecules/Button/Button'

// Types
import { IProcessModal } from './ProcessModal.types'

// Style
import './ProcessModal.scss'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'
import dayjs from 'lib/dayjs'

// Other

const ProcessModal: FC<IProcessModal> = ({
  open,
  onClose,
  description,
  expirationDate,
  manufacturingDate,
  lotBatch,
  modelNumber,
  serialNumber,
  udi,
  company,
  quantity,
  onCancel,
  onSave,
  companyId,
  type,
}) => {
  const {
    handleBack,
    handleDisposition,
    steps,
    productType,
    localExpiryDate,
    localSerialNumber,
    localLot,
    localModelNumber,
    localCompany,
    comment,
    disposition,
    activeStep,
    handleActionButtonLabel,
    localDescription,
    isNew,
    hasMultiScanning,
    setHasMultiScanning,
    isNextDisabled,
    handleActionButton,
    getStepContent,
  } = useLogic({
    company,
    serialNumber,
    expirationDate,
    manufacturingDate,
    description,
    onSave,
    lotBatch,
    modelNumber,
    open,
    udi,
    quantity,
    companyId,
    type,
  })

  const isTablet = useIsTablet()

  const renderHeader = () => (
    <div className={`header ${isTablet ? 'is-tablet' : ''}`}>
      <div className="top">
        {localDescription && (
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {localDescription}
          </Typography>
        )}
      </div>
      <div className="bottom">
        <div className="fields">
          {renderInfo('Company', localCompany?.name)}
          {renderInfo('Serial', localSerialNumber)}
          {renderInfo('Model', localModelNumber)}
          {renderInfo('Lot', localLot)}
          {productType && renderInfo('Product type', productType)}
          {disposition &&
            renderInfo('Consignment', handleDisposition[disposition])}
          {renderExpirationInfo('Expiration', localExpiryDate?.toString())}

          {comment && renderInfo('Comment', comment)}
        </div>
        {!isTablet && (
          <div className="switch">
            <Typography variant="h4" textAlign="center">
              Attach codes
            </Typography>
            <Switch
              checked={hasMultiScanning}
              onChange={(e, checked) => setHasMultiScanning(checked)}
            />
          </div>
        )}
      </div>
    </div>
  )

  const renderInfo = (
    label: string,
    value: string | undefined,
    isExpired?: boolean
  ) => {
    const style = isExpired ? { color: 'red' } : {}

    return (
      <p key={label} style={style}>
        <span className="key">{label} </span>
        {value || '-'}
      </p>
    )
  }

  const renderExpirationInfo = (label: string, date: string | undefined) => {
    if (!date) {
      return renderInfo(label, '-')
    }

    const formattedDate = dayjs(date).format('MM/DD/YYYY')
    const isExpired = dayjs(date).isBefore(dayjs())

    return renderInfo(label, formattedDate, isExpired)
  }

  const renderStepper = () =>
    isTablet ? (
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        orientation={isTablet ? 'vertical' : 'horizontal'}
        className={`stepper is-tablet`}
      >
        {steps.map((step, index) => {
          const isCurrentStep = activeStep === index
          const isUpcoming = index > activeStep
          const isComplete = index < activeStep

          return (
            <div key={step}>
              <Step>
                <StepLabel
                  className={`${isCurrentStep ? 'active' : ''} ${
                    isUpcoming ? 'upcoming' : ''
                  }`}
                >
                  <div
                    className={`label-container ${
                      isCurrentStep ? 'active' : ''
                    } ${isUpcoming ? 'upcoming' : ''}`}
                  >
                    <div className="details">
                      <div className="label">{step}</div>
                    </div>
                    <div className={`step-number`}>
                      {isComplete ? <DoneIcon className="icon" /> : index + 1}
                    </div>
                  </div>
                </StepLabel>
              </Step>
              {getStepContent(index) && (
                <div
                  className={`options ${
                    isNew && activeStep === 0 ? 'form' : ''
                  } ${isTablet ? 'tablet' : ''} ${
                    isCurrentStep ? 'active' : ''
                  } ${index + 1 === steps.length ? 'inspection' : ''}`}
                >
                  <div className={`${activeStep === 0 ? 'step-content' : ''}`}>
                    {getStepContent(index)}
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </Stepper>
    ) : (
      <Box sx={{ width: '100%' }} paddingTop={4} className={`stepper`}>
        <ButtonBase
          disabled={activeStep === 0}
          className={`back-icon ${activeStep === 0 ? 'disabled' : ''}`}
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon
            color={activeStep === 0 ? 'disabled' : 'primary'}
          />
        </ButtonBase>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          orientation={isTablet ? 'vertical' : 'horizontal'}
        >
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )

  const renderOptions = () => (
    <div
      className={`options ${activeStep === 0 ? 'form' : ''} ${
        isTablet ? 'tablet' : ''
      }`}
    >
      {steps.map((step, index) => (
        <Fade
          key={index}
          in={activeStep === index}
          mountOnEnter
          unmountOnExit
          easing={{
            enter: 'ease',
            exit: 'ease',
          }}
        >
          <div
            className={activeStep === 0 ? 'step-content' : ''}
            style={{ height: '' }}
          >
            {getStepContent(index)}
          </div>
        </Fade>
      ))}
    </div>
  )

  const renderActions = () =>
    isTablet ? (
      <div className="actions">
        <ButtonBase className={`back-icon`} onClick={handleBack}>
          {activeStep === 0 ? (
            <Button
              sx={{ paddingInline: '40px' }}
              color="error"
              onClick={handleBack}
              variant="contained"
            >
              Cancel
            </Button>
          ) : (
            <KeyboardBackspaceIcon color="primary" />
          )}
        </ButtonBase>
        <Button
          sx={{ paddingInline: '40px' }}
          color="primary"
          disabled={isNextDisabled()}
          onClick={handleActionButton}
        >
          {handleActionButtonLabel}
        </Button>
      </div>
    ) : (
      <div className="actions">
        <Button
          color="primary"
          sx={{ paddingInline: '40px' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{ paddingInline: '40px' }}
          color="primary"
          disabled={isNextDisabled()}
          onClick={handleActionButton}
        >
          {handleActionButtonLabel}
        </Button>
      </div>
    )

  return isTablet ? (
    <Modal open={open} onClose={onClose as any}>
      <div className={`process-modal is-tablet`}>
        <div className="top">
          {renderHeader()}
          <div className="switch">
            <Typography variant="h2">Attach codes</Typography>
            <Switch
              checked={hasMultiScanning}
              onChange={(e, checked) => setHasMultiScanning(checked)}
            />
          </div>
          <Stack gap={7}>{renderStepper()}</Stack>
        </div>
        {renderActions()}
      </div>
    </Modal>
  ) : (
    <Modal open={open} onClose={onClose}>
      <div className="process-modal">
        {renderHeader()}
        <Stack gap={isNew && activeStep === 0 ? 2 : 5}>
          {renderStepper()}
          {renderOptions()}
          {renderActions()}
        </Stack>
      </div>
    </Modal>
  )
}

export default ProcessModal
