import React from 'react'
import { Grid, TextField } from '@mui/material'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { AssetGroupCount } from 'common/types'

interface MemoProps {
  backendValue?: AssetGroupCount['memo']
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  disabled: boolean
  required?: boolean
}

export const Memo = ({
  backendValue,
  onChange,
  disabled,
  required,
}: MemoProps) => {
  const isMobile = useIsMobile()

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowSpacing={1}
    >
      <Grid item sx={{ width: isMobile ? '100%' : '50%' }}>
        <TextField
          value={backendValue}
          placeholder="Note"
          onChange={onChange}
          multiline
          sx={{ width: '100%' }}
          disabled={disabled}
          required={required}
        />
      </Grid>
    </Grid>
  )
}
