// MUI
import { CircularProgress, Stack } from '@mui/material'

// Components
import Header from 'components/molecules/Header/Header'
import LocationHierarchy from './location-hierarchy/LocationHierarchy'
import Select from 'components/molecules/Select/Select'

// Style
import './Receive.scss'

// Router
import { SCAN } from 'app/ROUTES_CONSTANTS'

// Logic
import { useLogic } from './Receive.logic'
import { ReceivingProvider } from './receiveContext'

const ReceiveContent = () => {
  const { navigate, data, isLoading } = useLogic()

  return (
    <div className="receiving-container">
      <Header title="Select Location" parent="Receiving" />
      <Stack padding={2.5} gap={2.5}>
        <Select
          hasSearchIcon
          options={
            (data &&
              data
                ?.filter((item) => item.parentLocationId)
                .map((item) => ({ name: item.name, id: item.id }))) ||
            []
          }
          onSelect={(item) => navigate(SCAN(String(item.id)))}
          label="Search locations"
        />
        {isLoading && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        {!isLoading && data && (
          <div className="list">
            <LocationHierarchy
              locations={data}
              onSelect={(item) => navigate(SCAN(String(item.id)))}
            />
          </div>
        )}
      </Stack>
    </div>
  )
}

const Receive = () => {
  return (
    <ReceivingProvider>
      <ReceiveContent />
    </ReceivingProvider>
  )
}

export default Receive
