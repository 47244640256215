import * as TooltipComponent from '@radix-ui/react-tooltip'

import { TooltipProps } from './Tooltip.types'
import './Tooltip.scss'

const Tooltip = ({
  children,
  tooltipContent,
  onClick = () => {},
  side = 'right',
}: TooltipProps) => {
  return (
    <div className="tooltip-molecule_container">
      <TooltipComponent.Provider delayDuration={200}>
        <TooltipComponent.Root>
          <TooltipComponent.Trigger asChild onClick={onClick}>
            {children}
          </TooltipComponent.Trigger>

          <TooltipComponent.Portal>
            <TooltipComponent.Content
              className="TooltipContent"
              side={side}
              sideOffset={10}
            >
              {tooltipContent}
              <TooltipComponent.Arrow className="TooltipArrow" />
            </TooltipComponent.Content>
          </TooltipComponent.Portal>
        </TooltipComponent.Root>
      </TooltipComponent.Provider>
    </div>
  )
}

export default Tooltip
