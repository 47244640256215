import { Dialog } from '@mui/material'

import { FullScreenDialogProps } from './FullScreenDialog.types'

const FullScreenDialog = ({
  open,
  onClose,
  children,
}: FullScreenDialogProps) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      {children}
    </Dialog>
  )
}

export default FullScreenDialog
