import Box from '@mui/material/Box'
import { DashboardSideBar } from './DashboardSideBar'
import Layout from 'components/Layout'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { Outlet, useLocation } from 'react-router-dom'
import { ReportFiltersProvider } from 'lib/context/ReportFiltersContext/ReportFiltersContext'

type Props = {
  children?: JSX.Element
  color?: string
}

export function DashboardLayout({ children, color = 'primary.dark' }: Props) {
  const location = useLocation()
  const isScannerPage = location.pathname.includes('scan')

  // This is only for scanning into receiving.
  // The screen is too small to have a side bar when scanning.
  const isMobile = useIsMobile()
  const sidebarWidth = 56
  const hideSideBar = isMobile && isScannerPage

  return (
    <ReportFiltersProvider>
      <Layout color={color}>
        <div>
          <DashboardSideBar sidebarWidth={sidebarWidth} />
          <Box
            display="flex"
            flexDirection="column"
            minWidth={`calc(100vw - ${sidebarWidth}px)`}
            height="100%"
            pl={`${hideSideBar ? 0 : sidebarWidth}px`}
          >
            {children || <Outlet />}
          </Box>
        </div>
      </Layout>
    </ReportFiltersProvider>
  )
}
