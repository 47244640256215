import { ItemResponse } from 'common/types'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'

export const extractTrayJSONData = (trayItem: ItemResponse) => {
  const trayDetails: TrayItemProductDetails | undefined = trayItem
    ? (JSON.parse(trayItem.productDetails as string) as TrayItemProductDetails)
    : undefined

  return trayDetails
}
