import {
  Box,
  Divider,
  Typography,
  Tabs,
  Tab,
  SxProps,
  Theme,
} from '@mui/material'
import { useState } from 'react'

interface CustomTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  sx?: SxProps<Theme>
}

function CustomTabPanel(props: CustomTabPanelProps) {
  const { children, value, index, sx, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={sx}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export interface TabPanelProps {
  sx?: SxProps<Theme>
  customTabPanelSx?: SxProps<Theme>
  tabsConfig: {
    label: string
    content: React.ReactNode
  }[]
}

export const TabPanel = ({
  tabsConfig,
  customTabPanelSx = { p: 3, mt: 2 },
  sx,
}: TabPanelProps) => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={sx}
      >
        {tabsConfig.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Divider />
      {tabsConfig.map((tab, index) => (
        <CustomTabPanel
          key={index}
          value={value}
          index={index}
          sx={customTabPanelSx}
        >
          {tab.content}
        </CustomTabPanel>
      ))}
    </>
  )
}
