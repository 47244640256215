import Box from '@mui/material/Box'
import classNames from 'classnames'
import styles from './pageDisplay.module.css'

type Props = {
  children: React.ReactNode
  className?: string
}

function PageDisplay({ children, className }: Props) {
  return (
    <Box className={classNames(styles.pageDisplay, className)}>
      <Box
        maxWidth="768px"
        width="100%"
        display="flex"
        flexDirection="column"
        flex={1}
        position="relative"
      >
        {children}
      </Box>
    </Box>
  )
}

export default PageDisplay
