import { SelectChangeEvent } from '@mui/material'
import { TrayAnalysis } from 'common/types'
import dayjs from 'lib/dayjs'
import { useGetAllTrayAnalysesQuery } from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'

const useDTMAnalysisResults = () => {
  const [analysisResults, setAnalysisResults] = useState<TrayAnalysis[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trayId, setTrayId] = useState<string>('')
  const [selectedHospital, setSelectedHospital] = useState<string>('all')
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedResult, setSelectedResult] = useState<TrayAnalysis | null>(
    null
  )

  const [skip, setSkip] = useState<number>(0)
  const { trayAnalyses, totalCount, loading, error, loadMore } =
    useGetAllTrayAnalysesQuery({
      skip,
      id: trayId.length > 0 ? Number(trayId) : null,
    })

  useEffect(() => {
    setAnalysisResults((prevResults) => [...prevResults, ...trayAnalyses])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const handleTrayIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrayId(event.target.value)
  }

  const handleHospitalChange = (event: SelectChangeEvent<string>) => {
    setSelectedHospital(event.target.value)
  }

  const handleChangeDay = (date: Date) => {
    setSelectedDate(dayjs(date).startOf('day').toDate())
  }

  const handleClearFilters = () => {
    setTrayId('')
    setSelectedHospital('all')
    setSelectedDate(null)
  }

  const handleSelectedResultClose = () => {
    setSelectedResult(null)
  }

  const handleLoadMore = () => {
    // Increment skip by take value
    setSkip((prevSkip) => prevSkip + 4)
    if (!loading) {
      loadMore()
    }
  }
  return {
    analysisResults,
    setAnalysisResults,
    totalCount,
    loading,
    error,
    selectedHospital,
    setSelectedHospital,
    selectedDate,
    setSelectedDate,
    selectedResult,
    setSelectedResult,
    handleChangeDay,
    handleClearFilters,
    handleHospitalChange,
    handleSelectedResultClose,
    handleLoadMore,
    handleTrayIdChange,
  }
}

export default useDTMAnalysisResults
