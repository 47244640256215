import { useEffect } from 'react'
import { Typography } from '@mui/material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'
import { ListOfSurgeries } from '../ListOfSurgeries/ListOfSurgeries'

import Provider from 'lib/apollo'
import useEditTrayModal from './EditTrayModal.logic'

import TraySVG from 'assets/icons/svgs/Tray.svg'
import { EditTrayModalProps } from './EditTrayModal.types'
import './EditTrayModal.scss'

const EditTrayModal = ({ open, onClose, trayData }: EditTrayModalProps) => {
  const {
    traySurgeryId,
    setTraySurgeryId,
    handleSurgeryChange,
    handleUpdateTrayItem,
    updating,
    success,
    setSuccess,
  } = useEditTrayModal({
    trayData,
  })

  useEffect(() => {
    if (success) {
      setSuccess(false)
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <Modal
      open={open}
      onClose={() => {
        setTraySurgeryId('')
        onClose()
      }}
    >
      <div data-testid="edit-tray-modal_wrapper" className="edit-tray-modal">
        <div data-testid="edit-tray-modal-header" className="header-box">
          <img src={TraySVG} alt="tray" />

          <Typography data-testid="edit-tray-modal-header_title" variant="h3">
            Assign Tray to Surgery
          </Typography>
        </div>

        <Provider>
          <ListOfSurgeries
            traySurgeryId={traySurgeryId}
            handleSurgeryChange={handleSurgeryChange}
            trayData={trayData}
          />
        </Provider>

        <LoadingButton
          dataTestId="edit-tray-modal_submit-surgery-id-button"
          data-testid="edit-tray-modal_submit-surgery-id-button"
          disabled={updating || !traySurgeryId}
          loading={updating}
          onClick={handleUpdateTrayItem}
        >
          Confirm Selection
        </LoadingButton>
      </div>
    </Modal>
  )
}

export default EditTrayModal
