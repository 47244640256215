import { SxProps, Theme, Box, Typography } from '@mui/material'
import ListAltIcon from '@mui/icons-material/ListAltOutlined'

interface ListEmptyStateProps {
  containerStyles?: SxProps<Theme>
  message?: string
}

export function ListEmptyState({
  containerStyles = {},
  message,
}: ListEmptyStateProps) {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...containerStyles,
      }}
    >
      <ListAltIcon sx={{ fontSize: 100, color: 'text.secondary' }} />
      {message ? (
        message.split('\n').map((line, index) => (
          <Typography
            key={index}
            variant="h6"
            sx={{ mt: 2, textAlign: 'center' }}
            fontSize={20}
            color="text.secondary"
          >
            {line}
          </Typography>
        ))
      ) : (
        <Typography
          variant="h6"
          sx={{ mt: 2, textAlign: 'center' }}
          fontSize={20}
          color="text.secondary"
        >
          No items to display
        </Typography>
      )}
    </Box>
  )
}
