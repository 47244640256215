import { MutationResult } from '@apollo/client'
import { Surgery } from 'common/types'

export enum ButtonClicked {
  SEND_FOR_REP_APPROVAL,
  NO_REP_AVAILABLE,
}

export type TSendForApprovalProps = {
  surgery: Surgery
  handleSetAssetStatus: (
    status: 'PRE_APPROVAL' | 'APPROVED' | 'SCANNED',
    source?: any,
    sendToCompany?: string | null
  ) => void
  setAssetStatusMutation: MutationResult<any>
  setAssetStatusSecondaryMutation: MutationResult<any>
  setIsQrOpen: React.Dispatch<React.SetStateAction<boolean>>
}
