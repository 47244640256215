import * as yup from 'yup'

export const editRepSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  mobileNumber: yup
    .string()
    .notRequired()
    .min(12)
    .nullable()
    .transform((value) => (!!value ? value : null)),
})
