import { useUser } from 'app/User'
import { facility } from 'common/facilityMigration'
import {
  RepApprovalFormData,
  AssetStatus,
  Scan,
  ProcedureStatus,
  AssetData,
} from 'common/types'
import dayjs from 'lib/dayjs'
import { useSetSurgeryStatus, useSetAssetStatus } from 'lib/apollo/hooks'
import { LONGPRESS_DEFAULT_OPTIONS } from 'lib/config'
import { useBottomNavigation } from 'lib/context/BottomNavigation'
import { isEqual } from 'lodash'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { usePrevious, useLongPress } from 'react-use'
import { groupAssetsByAssetType, getStatusDetails } from 'lib/utils/data'
import { getGroupedAssetData } from 'lib/utils/grouped-asset-data'
import { AssetListLogicProps, initialFormData } from './AssetList.types'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import RepApproval from './forms/repApproval/RepApproval'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const useAssetListLogic = ({
  surgery,
  derivedSurgeryStatus,
}: AssetListLogicProps) => {
  // hooks
  const {
    name: procedureStatus,
    workflow,
    hasImplantableHardware,
    hasImplantableBiologic,
    hasImplantableOther,
    totalHardwareCount,
    implantableScanIdsByStatus,
    totalImplantableBiologicCount,
    totalImplantableOtherCount,
    totalConsumableCount,
    totalExplantedCount,
  } = derivedSurgeryStatus

  const { user, isNurse, isNurseAdmin, isRep } = useUser()
  const { assetTypes } = facility
  const flags = useFlags()

  const [setSurgeryStatus, setSurgeryStatusMutation] = useSetSurgeryStatus(
    surgery._id
  )
  const [setSurgeryStatusSubmitted, setSurgeryStatusSubmittedMutation] =
    useSetSurgeryStatus(surgery._id)
  const [setAssetStatus, setAssetStatusMutation] = useSetAssetStatus(
    surgery._id
  )
  const [setAssetStatusSecondary, setAssetStatusSecondaryMutation] =
    useSetAssetStatus(surgery._id)

  const { resetBottomNavigation, setShowBottomNavigation } =
    useBottomNavigation()
  const [dtmHardwareAssetData, setDTMHardwareAssetData] = useState<AssetData[]>(
    []
  )

  const prevProcedureStatus = usePrevious(procedureStatus)
  const previousWorkflow = usePrevious(workflow)
  const onLongPress = () => setShowDemoMenu(true)
  const demoMenuEvent = useLongPress(onLongPress, LONGPRESS_DEFAULT_OPTIONS)

  const initialGroupedAssets = useMemo(
    () => groupAssetsByAssetType([], assetTypes),
    [assetTypes]
  )

  const groupedAssets = useMemo(
    () => groupAssetsByAssetType(surgery.assetGroups, assetTypes),
    [assetTypes, surgery.assetGroups]
  )
  const isGroupedAssetsEmpty = isEqual(groupedAssets, initialGroupedAssets)

  const groupedAssetData = useMemo(() => {
    return getGroupedAssetData(surgery.assetGroups)
  }, [surgery.assetGroups])

  // states
  const [isQrOpen, setIsQrOpen] = useState(false)
  const [showGenericAlertDialog, setShowGenericAlertDialog] = useState(false)

  const [alertDialogDetails, setAlertDialogDetails] = useState<any>({
    title: 'Products ready for approval',
    mode: 'info',
    description:
      'The nurse has marked these products as complete and ready for your approval.',
    primaryButtonAction: () => setShowGenericAlertDialog(false),
    primaryButtonText: 'Continue',
  })
  const [showDemoMenu, setShowDemoMenu] = useState(false)
  const [repApprovalFormData] = useState<RepApprovalFormData>(initialFormData)

  // constants
  let heading = 'Documented List'

  const isNurseUser = isNurse || isNurseAdmin

  const surgeryRepCount = surgery?.authorizedReps?.length || 0

  const statusSubmittedDetails = useMemo(
    () => getStatusDetails(surgery, 'SUBMITTED'),
    [surgery]
  )
  const timeSubmitted = statusSubmittedDetails?.dateTime

  const userName = `${user?.firstName} ${user?.lastName}`

  const submittedAndNurseUser = procedureStatus === 'SUBMITTED' && isNurseUser

  const enableSendToRep =
    procedureStatus === 'SCANNING' && hasImplantableHardware

  const enableImplantRecordSender =
    submittedAndNurseUser &&
    hasImplantableHardware &&
    flags.implantSurgeryReports === false

  const enableSendProductRegistration =
    submittedAndNurseUser && (hasImplantableBiologic || hasImplantableOther)

  const enableSendImplantReport =
    submittedAndNurseUser && flags.implantSurgeryReports !== false

  const renderCloseCase = isNurseUser && flags.closeCaseButton !== false

  // life cycle methods
  if (procedureStatus === 'SUBMITTED') {
    heading = 'Record Submitted to EMR'
  }
  if (procedureStatus === 'PRE_APPROVAL') {
    heading = 'Product Approval'
  }

  const handleSetProcedureStatus = useCallback(
    (status: ProcedureStatus['name'], overrideRepname?: string) => {
      setSurgeryStatus({
        variables: {
          surgeryId: surgery._id,
          status,
          rep: overrideRepname ?? repApprovalFormData.repName,
          dateTime: dayjs().toISOString(),
        },
      })
    },
    [surgery._id, repApprovalFormData.repName, setSurgeryStatus]
  )

  useEffect(() => {
    if (procedureStatus !== 'SCANNING' && procedureStatus !== 'APPROVED') {
      return setShowBottomNavigation(false)
    }
    resetBottomNavigation()
  }, [procedureStatus, resetBottomNavigation, setShowBottomNavigation])

  // Alerts users when procedure status changes
  useEffect(() => {
    if (isRep) {
      // Alerts rep when nurse cancels approval process
      if (
        prevProcedureStatus === 'PRE_APPROVAL' &&
        procedureStatus === 'SCANNING' &&
        workflow === 'rep' &&
        previousWorkflow !== 'no-rep'
      ) {
        setShowGenericAlertDialog(true)
        setAlertDialogDetails({
          title: 'The approval process was cancelled by the nurse',
          mode: 'errorInfo',
          description:
            'You will be redirected to the product list screen while the nurse updates the record. When it is complete, you will be able to approve products again.',
          primaryButtonAction: () => {
            setShowGenericAlertDialog(false)
          },
          primaryButtonText: 'Continue',
        })
      }

      // Alerts rep when assets are ready for approval
      if (
        procedureStatus === 'PRE_APPROVAL' &&
        prevProcedureStatus !== 'PRE_APPROVAL' &&
        workflow === 'rep'
      ) {
        setShowGenericAlertDialog(true)
        setAlertDialogDetails({
          title: 'Products ready for approval',
          mode: 'info',
          description:
            'The nurse has marked these products as complete and ready for your approval.',
          primaryButtonAction: () => setShowGenericAlertDialog(false),
          primaryButtonText: 'Continue',
        })
      }
    }

    if (isNurseUser && workflow === 'rep') {
      // Alerts nurse when rep approves assets
      if (procedureStatus === 'APPROVED' && hasImplantableHardware) {
        setShowGenericAlertDialog(true)
        setAlertDialogDetails({
          title: 'Approval complete',
          mode: 'info',
          description: `All products have been approved, and it is now ready to submit to the EMR.`,
          primaryButtonAction: () => setShowGenericAlertDialog(false),
          primaryButtonText: 'Continue',
        })
      }
    }
  }, [
    handleSetProcedureStatus,
    hasImplantableHardware,
    hasImplantableBiologic,
    prevProcedureStatus,
    previousWorkflow,
    procedureStatus,
    isNurseUser,
    isRep,
    workflow,
  ])

  useEffect(() => {
    if (groupedAssetData) {
      handleGetDTMAssetData(groupedAssetData.hardware.nurseScans)
    }
  }, [groupedAssetData.hardware.nurseScans.length])

  // handles
  const handleGetDTMAssetData = (assetData: AssetData[]) => {
    try {
      if (!assetData) {
        throw new Error('Please pass the assetData array to the function.')
      }

      if (assetData.length === 0) {
        return
      }

      const dtmAssetData = assetData.filter((asset) =>
        asset.scans.some((scan) => scan.isDTMScrew)
      )

      setDTMHardwareAssetData(dtmAssetData)
    } catch (error) {
      console.error(
        "Couldn't get DTM asset data from the groupedAssetData array.",
        error
      )
    }
  }

  const handleSetAssetStatus = useCallback(
    (
      status: AssetStatus['name'],
      source = 'primary',
      sendToCompany: string | null = null,
      signature: AssetStatus['signature'] = null
    ) => {
      const sourceFunction =
        source === 'primary' ? setAssetStatus : setAssetStatusSecondary
      let assetIds: Scan['_id'][] = []

      if (status === 'PRE_APPROVAL') {
        assetIds = implantableScanIdsByStatus.SCANNED
      } else if (status === 'APPROVED') {
        assetIds = implantableScanIdsByStatus.PRE_APPROVAL
      } else if (status === 'SCANNED') {
        assetIds = implantableScanIdsByStatus.PRE_APPROVAL
      }

      if (!assetIds) {
        throw Error('No products to update')
      }

      sourceFunction({
        variables: {
          assetIds,
          status,
          userName,
          sendToCompany,
          signature,
        },
      })
    },
    [
      implantableScanIdsByStatus.PRE_APPROVAL,
      implantableScanIdsByStatus.SCANNED,
      setAssetStatus,
      setAssetStatusSecondary,
      userName,
    ]
  )

  const handleCloseModal = () => {
    setIsQrOpen(false)
  }

  const handleCancelApproval = () => {
    setShowGenericAlertDialog(true)
    setAlertDialogDetails({
      title: 'Cancel the approval process?',
      mode: 'errorInfo',
      description:
        'Cancelling the approval process will return you to the product list screen where you can delete and re-capture products.',
      primaryButtonAction: () => {
        setShowGenericAlertDialog(false)
        handleSetAssetStatus('SCANNED', 'secondary')
      },
      primaryButtonText: 'Yes, cancel',
      dataTestIdPrimary: 'popup-cancel-button',
      secondaryButtonAction: () => setShowGenericAlertDialog(false),
      secondaryButtonText: 'Back',
    })
  }

  const handleSubmitRecord = useCallback(
    () =>
      setSurgeryStatusSubmitted({
        variables: {
          surgeryId: surgery._id,
          status: 'SUBMITTED',
          rep: userName,
          dateTime: dayjs().toISOString(),
        },
      }),
    [surgery._id, setSurgeryStatusSubmitted, userName]
  )

  const renderRecordApprovalForm = () => {
    return (
      <RepApproval
        derivedSurgeryStatus={derivedSurgeryStatus}
        handleSetAssetStatus={handleSetAssetStatus}
        setShowGenericAlertDialog={setShowGenericAlertDialog}
        setAlertDialogDetails={setAlertDialogDetails}
        setAssetStatusMutation={setAssetStatusMutation}
      />
    )
  }

  const CancelApprovalButton = () => (
    <LoadingButton
      dataTestId="cancel-approval-process-button"
      mode="cancel"
      variant="outlined"
      loading={setAssetStatusSecondaryMutation.loading}
      disabled={procedureStatus !== 'PRE_APPROVAL'}
      loadingText="Cancelling approval process"
      onClick={handleCancelApproval}
    >
      Cancel approval process
    </LoadingButton>
  )

  return {
    isRep,
    setSurgeryStatusMutation,
    setSurgeryStatusSubmittedMutation,
    setAssetStatusMutation,
    setAssetStatusSecondaryMutation,
    demoMenuEvent,
    isGroupedAssetsEmpty,
    groupedAssetData,
    isQrOpen,
    setIsQrOpen,
    showGenericAlertDialog,
    alertDialogDetails,
    showDemoMenu,
    setShowDemoMenu,
    heading,
    isNurseUser,
    surgeryRepCount,
    procedureStatus,
    workflow,
    hasImplantableHardware,
    totalHardwareCount,
    totalImplantableBiologicCount,
    totalImplantableOtherCount,
    totalConsumableCount,
    totalExplantedCount,
    timeSubmitted,
    enableSendToRep,
    handleSetAssetStatus,
    handleSetProcedureStatus,
    handleCloseModal,
    handleSubmitRecord,
    renderRecordApprovalForm,
    CancelApprovalButton,
    enableImplantRecordSender,
    enableSendProductRegistration,
    enableSendImplantReport,
    renderCloseCase,
    dtmHardwareAssetData,
  }
}
