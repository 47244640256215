// MUI
import { Typography } from '@mui/material'

// Style
import './SummaryHeader.css'

// Utils
import { useIsScannerScreenMidSize } from 'lib/utils/mediaQueries'

interface SummaryHeaderProps {
  totalScanned: number
  totalImplants: number
  totalOtherProducts: number
  totalHardwareProducts: number
}

const SummaryHeader: React.FC<SummaryHeaderProps> = ({
  totalScanned,
  totalImplants,
  totalOtherProducts,
  totalHardwareProducts,
}) => {
  const isScannerScreenMidSize = useIsScannerScreenMidSize()

  return (
    <div className={`summary ${isScannerScreenMidSize ? 'is-tablet' : ''}`}>
      <div className="item">
        <Typography sx={{ color: 'var(--light-blue-2)' }} variant="h5">
          {totalHardwareProducts}
        </Typography>
        <Typography variant="body2">Hardware</Typography>
      </div>
      <div className="item">
        <Typography sx={{ color: 'var(--light-blue-2)' }} variant="h5">
          {totalImplants}
        </Typography>
        <Typography variant="body2">Biologic</Typography>
      </div>
      <div className="item">
        <Typography sx={{ color: 'var(--light-blue-2)' }} variant="h5">
          {totalOtherProducts}
        </Typography>
        <Typography variant="body2">Other</Typography>
      </div>
      <div className="item">
        <Typography sx={{ color: 'var(--light-blue-2)' }} variant="h5">
          {totalScanned}
        </Typography>
        <Typography variant="body2">Total Captured</Typography>
      </div>
    </div>
  )
}

export default SummaryHeader
