import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { useAuth0 } from 'app/Auth'
import { useMemo } from 'react'

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
const AWS_REGION = process.env.REACT_APP_AWS_REGION ?? 'us-east-1'

export const useCreateApolloClient = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getAccessToken } = useAuth0()

  const auth = {
    type: 'OPENID_CONNECT',
    jwtToken: async () => await getAccessToken(),
  }

  const httpLink = createHttpLink({ uri: GRAPHQL_URL })

  const link = ApolloLink.from([
    createAuthLink({ apiUrl: GRAPHQL_URL, region: AWS_REGION, auth }),
    createSubscriptionHandshakeLink(
      { apiUrl: GRAPHQL_URL, region: AWS_REGION, auth },
      httpLink
    ),
  ])

  const client = useMemo(
    () =>
      new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
      }),
    [link]
  )

  return {
    client,
  }
}

const Provider = ({ children }) => {
  const { client } = useCreateApolloClient()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apolloClient = useMemo(() => client, [])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default Provider
