import { IconButton } from '@mui/material'
import { flexBox } from 'common/styles/mixins'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { TabPanelProps } from './types'

export const ModalContainer = styled.div`
  ${flexBox('flex', 'unset', 'unset', 'column', 'unset')}
  height: 100%;
  background-color: var(--white);
`

export const HeaderContainer = styled.div`
  ${flexBox('flex', 'center', 'unset', 'row', 'unset')}
  border-bottom: 1px solid var(--grayscale-hover);
  background-color: var(--grayscale-lightest);
  position: relative;
  height: 49px;
`
export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`
export const BodyContainer = styled.div`
  ${flexBox('flex', 'center', 'center', 'column', 'unset')}
  flex: 1;
`
export const TabPanel = styled.div<TabPanelProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
`

export const StyledCloseIcon = styled(CloseIcon)`
  color: var(--primary);
`
