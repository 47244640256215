import { CustomIcon } from 'components/templates/CustomIcon'

type PreferenceCardIconProps = {
  active?: boolean
  onClick?: () => void
  color?: string
  dark?: boolean
}

export const PreferenceCardIcon = ({
  active,
  onClick,
  color,
  dark,
}: PreferenceCardIconProps) => (
  <CustomIcon
    active={active}
    dark={dark}
    color={color}
    onClick={onClick}
    height={'29'}
    width={'29'}
    viewBox={'0 0 29.305 29.83'}
  >
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 250.000000 322.000000"
      fill={color}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,322.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M1116 3110 c-77 -23 -138 -91 -160 -178 l-12 -47 -114 -3 c-63 -1 -120 -8 -127 -14 -18 -15 -17 -151 1 -186 16 -31 61 -74 93 -91 15 -7 137 -11 390 -11 413 0 407 -1 468 74 26 33 30 47 33 110 6 115 5 116 -138 116 l-119 0-7 38 c-15 85 -89 171 -163 192 -43 12 -105 12 -145 0z m132 -155 c17 -14 33 -39 37 -55 14 -62 -53 -125 -118 -113 -15 3 -38 19 -52 35 -75 89 44 208 133 133z" />
        <path d="M291 2792 c-19 -9 -44 -30 -55 -45 -21 -28 -21 -31 -21 -1180 0 -1116 1 -1153 19 -1180 10 -16 36 -37 57 -48 36 -18 63 -19 519 -19 265 0 480 3 478 8 -2 4 -19 39 -38 79 l-35 72 -420 3 -420 3 0 1080 0 1080 100 5 100 5 3 78 3 77 -128 0 c-102 0 -135 -4 -162 -18z" />
        <path d="M1800 2730 l0 -80 100 0 100 0 2 -657 3 -656 70 -29 c39 -16 75 -30 80 -31 7 -1 10 242 10 713 0 697 -1 716 -20 749 -35 59 -72 71 -217 71 l-128 0 0 -80z" />
        <path d="M790 2325 c-47 -47 -89 -85 -95 -85 -6 0 -26 16 -45 35 -19 19 -41 35 -50 35 -22 0 -60 -37 -60 -58 0 -21 129 -152 150 -152 19 0 250 230 250 248 0 14 -44 62 -57 62 -5 0 -46 -38 -93 -85z" />
        <path d="M1057 2213 c-4 -3 -7 -28 -7 -55 l0 -48 375 0 375 0 0 55 0 55 -368 0 c-203 0 -372 -3 -375 -7z" />
        <path d="M791 1886 c-48 -47 -91 -86 -95 -86 -5 0 -26 16 -47 35 -22 19 -45 35 -52 35 -18 0 -57 -42 -57 -61 0 -19 131 -149 151 -149 16 0 249 225 249 241 0 19 -20 49 -41 59 -18 10 -31 1 -108 -74z" />
        <path d="M1058 1774 c-5 -4 -8 -29 -8 -56 l0 -48 375 0 375 0 0 53 0 52 -368 3 c-202 2 -370 0 -374 -4z" />
        <path d="M780 1435 l-84 -84 -43 39 c-23 22 -49 40 -56 40 -18 0 -57 -42 -57 -61 0 -21 132 -149 154 -149 21 0 233 203 242 233 7 22 -30 67 -55 67 -9 0 -54 -38 -101 -85z" />
        <path d="M1052 1278 l3 -53 200 0 200 1 52 32 c29 18 76 42 105 52 l53 19 -308 1 -308 0 3 -52z" />
        <path d="M1790 1224 c-276 -31 -480 -257 -480 -532 0 -106 22 -188 75 -277 68 -115 207 -217 333 -244 75 -16 197 -14 267 5 240 62 413 309 392 559 -16 187 -130 353 -302 438 -76 37 -201 60 -285 51z m335 -319 c43 -42 54 -24 -209 -335 -76 -91 -144 -168 -150 -172 -6 -4 -22 -8 -36 -8 -20 0 -48 25 -129 118 -123 139 -130 152 -107 187 32 48 62 36 156 -65 l85 -91 159 188 c183 217 188 222 231 178z" />
        <path d="M779 995 l-85 -86 -42 41 c-24 22 -49 40 -56 40 -17 0 -56 -42 -56 -61 0 -22 132 -149 155 -149 24 0 245 218 245 241 0 19 -41 59 -61 59 -8 0 -53 -38 -100 -85z" />
        <path d="M1052 838 l3 -53 64 0 c71 0 64 -6 85 78 l6 27 -80 0 -81 0 3 -52z" />
      </g>
    </svg>
  </CustomIcon>
)
