import { TextFieldProps } from '@mui/material'
import { Grid, TextField } from '@mui/material'
import { ISelectInput } from './SelectInput/SelectInput.types'
import SelectInput from './SelectInput/SelectInput'

interface CustomSelectProps extends ISelectInput {
  secondInputType?: string
  inputProps?: TextFieldProps['inputProps']
  secondInputLabel?: string
  secondInputDefaultValue?: number | string
  renderSecondInput?: boolean
}

export const CustomSelect = ({
  id,
  label,
  name,
  disabled = false,
  handleChangeFormData,
  options,
  placeholder = 'Select',
  required = false,
  value = '',
  itemStyles = {},
  sx = {},
  secondInputType,
  inputProps = {},
  secondInputLabel,
  secondInputDefaultValue,
  renderSecondInput = false,
}: CustomSelectProps) => {
  return (
    <>
      <Grid item xs={renderSecondInput ? 6 : 9}>
        <SelectInput
          id={id}
          label={label}
          name={name}
          disabled={disabled}
          handleChangeFormData={handleChangeFormData}
          options={options}
          placeholder={placeholder}
          required={required}
          value={value}
          itemStyles={itemStyles}
          sx={sx}
        />
      </Grid>
      {renderSecondInput ? (
        <Grid item xs={3}>
          <TextField
            variant="standard"
            type={secondInputType}
            required={required}
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: inputProps }}
            label={secondInputLabel}
            defaultValue={secondInputDefaultValue}
            sx={{ textTransform: 'capitalize', ...sx }}
            onChange={handleChangeFormData}
          />
        </Grid>
      ) : null}
    </>
  )
}
