import { AssetData, Scan } from 'common/types'
import { UndocumentedScanCard } from 'components/UndocumentedScanCard'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { useEffect, useState } from 'react'
import { useEditDispositionModalLogic } from '../EditDispositionModal/EditDispositionModal.logic'

interface SelectScanModalProps {
  scans: Scan[]
  surgeryId: any
  assetTray: AssetData['assetTray']
  dataTestId?: string
}

export const SelectScanModal = ({
  scans,
  surgeryId,
  assetTray,
  dataTestId,
}: SelectScanModalProps) => {
  const {
    toggleSelection,
    scanStateMap,
    setScanStateMap,
    isSelectModalOpen,
    setIsSelectModalOpen,
    setIsEditModalOpen,
    handleReset,
  } = useEditDispositionModalLogic({
    assetTray,
  })

  const [disabledScans, setDisabledScans] = useState<(string | null)[]>([])

  const isAnyScanSelected = Object.values(scanStateMap).some(
    (scanState) => scanState.isSelected
  )

  useEffect(() => {
    setScanStateMap((prevState) => {
      const newState = { ...prevState }
      scans.forEach((scan) => {
        if (!newState[scan._id]) {
          newState[scan._id] = { isSelected: false }
        }
      })
      return newState
    })
    // set scanStateMap to include all scans only at initial modal open
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [])

  useEffect(() => {
    const disabledScanIds = Object.keys(scanStateMap).filter(
      (key) => !scanStateMap[key].isSelected
    )
    setDisabledScans(disabledScanIds.length > 0 ? disabledScanIds : [null])
  }, [scanStateMap])

  const handleOnSubmit = () => {
    setScanStateMap(scanStateMap)
    setIsSelectModalOpen(false)
    setIsEditModalOpen(true)
  }

  const handleOnClose = () => {
    setIsSelectModalOpen(false)
    handleReset()
  }

  return (
    <CustomModal
      open={isSelectModalOpen}
      dataTestId={dataTestId}
      secondaryButtonAction={handleOnClose}
      header={'Select Product to Edit'}
      isLoadingState={false}
      primaryButtonDisabled={!isAnyScanSelected}
      handleOnSubmit={handleOnSubmit}
      primaryButtonText={'Continue'}
    >
      {scans.map((scan: Scan) => {
        const isSelected = scanStateMap[scan._id]?.isSelected
        const isDisabled = disabledScans.includes(scan._id)

        return (
          <UndocumentedScanCard
            key={scan._id}
            dataTestId={scan._id + 'selectScan'}
            surgeryId={surgeryId}
            onClick={() => toggleSelection(scan._id)}
            scan={scan}
            cardStyles={{
              my: 1,
              display: 'block',
              borderColor: isSelected ? 'success.main' : 'grey.300',
              bgcolor: isSelected ? 'success.lightest' : 'white',
            }}
            renderDelete={false}
            disabled={isDisabled && isAnyScanSelected}
            isSelected={isSelected}
          />
        )
      })}
    </CustomModal>
  )
}
