import { FC } from 'react'

// MUI
import './CardItem.scss'

// Types
import { ICardItem } from './CardItem.types'

// Style
import { CircularProgress, Typography } from '@mui/material'

const CardItem: FC<ICardItem> = ({
  procedure,
  surgeon,
  isHeader,
  isSaving,
  isLoading,
  onClick,
}) => {
  window.addEventListener('scroll', () => {
    const header = document.querySelector(
      '.preference-card-header'
    ) as HTMLElement | null
    if (header) {
      if (window.scrollY > 60) {
        header.classList.add('scrolled')
      } else {
        header.classList.remove('scrolled')
      }
    }
  })

  return (
    <div
      className={`preference-card-item ${isHeader ? 'header' : ''}`}
      onClick={onClick}
    >
      <div className="details">
        <div className="section">
          <Typography variant="h3">Surgeon: </Typography>
          <Typography variant="h4">{surgeon}</Typography>
        </div>
        <div className="section">
          <Typography variant="h3">Procedure: </Typography>
          <Typography variant="h4">{procedure || ''}</Typography>
        </div>
      </div>

      {!isLoading && isSaving && (
        <div className="saving">
          <CircularProgress size={20} className="progress" />
          <Typography variant="h4">Saving...</Typography>
        </div>
      )}
      {isLoading && (
        <div className="saving">
          <CircularProgress size={20} className="progress" />
        </div>
      )}
    </div>
  )
}

export default CardItem
