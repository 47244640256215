import { useMemo } from 'react'
import dayjs, { humanizedMonthDateTimeFormat } from 'lib/dayjs'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import { StatusBar } from './molecules/StatusBar/StatusBar'

type Props = {
  procedureStatus: string
  hasImplantableHardware?: boolean
  userName?: string
  timeSubmitted?: string
}

const formatDate = (date: any) =>
  dayjs(date).format(humanizedMonthDateTimeFormat)

const getStatusIndicatorDetails = ({
  procedureStatus,
  hasImplantableHardware,
  userName,
  timeSubmitted,
}: Props) => {
  const formattedDateTimeSubmitted = formatDate(timeSubmitted)

  // Initial configuration for procedureStatus === 'APPROVED'
  let message = hasImplantableHardware
    ? `All products in this record have been approved.`
    : `No products in this record require approval`
  let color = 'success'
  let icon = <CheckIcon color="success" fontSize="small" />

  if (procedureStatus === 'PRE_APPROVAL') {
    message = 'Waiting for rep approval'
    color = 'warning'
    icon = <CircularProgress size={16} color="warning" />
  }

  if (procedureStatus === 'SUBMITTED') {
    message = `Submitted on ${formattedDateTimeSubmitted} by ${userName}`
  }

  return { color, icon, message, isRendered: true }
}

function StatusIndicator(props: Props) {
  const { color, icon, message, isRendered } = useMemo(
    () => getStatusIndicatorDetails(props),
    [props]
  )

  if (!isRendered) {
    return null
  }

  return <StatusBar icon={icon} message={message} color={color} />
}

export default StatusIndicator
