import { GraphCard } from 'components/molecules/GraphCard'
import { UtilizedAssetsChart } from '../charts/UtilizedAssetsChart'
import { AssetsByMonth, SurgeryByMonthObject } from '../utils/getDashboardStats'

interface UtilizedAssetsCardProps {
  assetsByMonth: AssetsByMonth
  surgeriesByMonth: SurgeryByMonthObject[]
}

export const UtilizedAssetsCard = ({
  assetsByMonth,
  surgeriesByMonth,
}: UtilizedAssetsCardProps) => {
  const title = 'Utilized Products'
  return (
    <GraphCard title={title}>
      <UtilizedAssetsChart
        assetsByMonth={assetsByMonth}
        surgeriesByMonth={surgeriesByMonth}
      />
    </GraphCard>
  )
}
