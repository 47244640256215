import { useEffect } from 'react'
import { useAuth0 } from 'app/Auth'

function Logout() {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: `${window.location.origin}/?status=loggedout` })
  }, [logout])

  return null
}

export default Logout
