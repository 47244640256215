import { AssetData, AssetGroupCount, SelectedScan } from 'common/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { RfSpongeCountProviderValue } from './RfSpongeCountContext.types'

export const useRfSpongeCountContextLogic = () => {
  const useValidSpongeAssetGroups = (
    spongeAssetGroups: (AssetData | null)[]
  ) => {
    return useMemo(() => {
      return spongeAssetGroups.filter(
        (assetGroup): assetGroup is AssetData => assetGroup !== null
      )
    }, [spongeAssetGroups])
  }

  const useRfSpongeAssetGroupCount = (spongeAssetGroups: AssetData[]) => {
    const [rfSpongeAssetGroupCount, setRfSpongeAssetGroupCount] = useState<
      AssetGroupCount[]
    >([])

    useEffect(() => {
      const newRfSpongeAssetGroupCount = spongeAssetGroups.map(
        (assetGroup) => ({
          _id: assetGroup._id,
          accurateDeviceCount: assetGroup.accurateDeviceCount,
          deviceCountCorrectiveActionTaken:
            assetGroup.deviceCountCorrectiveActionTaken,
          memo: assetGroup.memo,
        })
      )
      setRfSpongeAssetGroupCount(newRfSpongeAssetGroupCount)
    }, [spongeAssetGroups])

    return [rfSpongeAssetGroupCount, setRfSpongeAssetGroupCount] as const
  }

  const useAddAssetGroupsCountEffect = (
    rfSpongeAssetGroupCount: AssetGroupCount[],
    addAssetGroupsCount: () => void
  ) => {
    const prevRfSpongeAssetGroupCountRef = useRef<AssetGroupCount[]>([])

    useEffect(() => {
      const prevRfSpongeAssetGroupCount = prevRfSpongeAssetGroupCountRef.current

      const nonMemoChange = rfSpongeAssetGroupCount.some(
        (currentGroup, index) => {
          const prevGroup = prevRfSpongeAssetGroupCount[index]
          return (
            currentGroup.accurateDeviceCount !==
              prevGroup?.accurateDeviceCount ||
            currentGroup.deviceCountCorrectiveActionTaken !==
              prevGroup?.deviceCountCorrectiveActionTaken
          )
        }
      )

      if (nonMemoChange) {
        addAssetGroupsCount()
      }

      prevRfSpongeAssetGroupCountRef.current = rfSpongeAssetGroupCount
    }, [rfSpongeAssetGroupCount, addAssetGroupsCount])
  }

  const useProviderValue = ({
    rfSpongeAssetGroupCount,
    setRfSpongeAssetGroupCount,
    spongeAssetGroups,
    addAssetGroupsCountMutation,
    addAssetGroupsCount,
  }: RfSpongeCountProviderValue) => {
    return useMemo(() => {
      const updateAssetGroupCounts = (
        id: AssetData['_id'],
        updates: Partial<AssetGroupCount>
      ) => {
        setRfSpongeAssetGroupCount(
          rfSpongeAssetGroupCount.map((group) =>
            group._id === id ? { ...group, ...updates } : group
          )
        )
      }

      // match asset groups going from undocumented to documented list and resets sponge count validation
      const matchToAssetGroup = (selectedScans: SelectedScan[]) => {
        const bidAssetIds = selectedScans.map((scan) => scan.bidAssetId)

        const assetGroupMatch = spongeAssetGroups.find((assetGroup) =>
          bidAssetIds.includes(assetGroup.bidAssetId)
        )

        if (assetGroupMatch) {
          updateAssetGroupCounts(assetGroupMatch._id, {
            accurateDeviceCount: false,
            deviceCountCorrectiveActionTaken: false,
            memo: '',
          })
        }

        return assetGroupMatch
      }

      const saveMemoNote = () => {
        addAssetGroupsCount()
      }

      return {
        updateAssetGroupCounts,
        rfSpongeAssetGroupCount,
        setRfSpongeAssetGroupCount,
        saveMemoNote,
        addAssetGroupsCountMutation,
        matchToAssetGroup,
        addAssetGroupsCount,
      }
    }, [
      rfSpongeAssetGroupCount,
      setRfSpongeAssetGroupCount,
      spongeAssetGroups,
      addAssetGroupsCountMutation,
      addAssetGroupsCount,
    ])
  }

  return {
    useValidSpongeAssetGroups,
    useRfSpongeAssetGroupCount,
    useAddAssetGroupsCountEffect,
    useProviderValue,
  }
}
