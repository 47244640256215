import { GET_INVENTORY_REPORT_DETAILS } from 'lib/services/api/schema'
import { QueryResult, useQuery } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'
import {
  GetInventoryReportDetailsParams,
  GetInventoryReportDetailsResponse,
} from './types'

const useGetInventoryReportDetails = (
  variables: GetInventoryReportDetailsParams
): QueryResult<
  GetInventoryReportDetailsResponse,
  GetInventoryReportDetailsParams
> => {
  return useQuery(GET_INVENTORY_REPORT_DETAILS, {
    variables,
    onError: (error: Error) => {
      captureWarning(error)
    },
  })
}

export default useGetInventoryReportDetails
