import * as HoverCard from '@radix-ui/react-hover-card'

import './HoverCard.scss'

const HoverCardComponent = ({
  open,
  children,
  content,
}: {
  open?: boolean
  children: JSX.Element
  content: JSX.Element
}) => {
  const options = open ? { open } : {}

  return (
    <HoverCard.Root {...options}>
      <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
      {open !== false && (
        <HoverCard.Portal>
          <HoverCard.Content className="HoverCardContent" sideOffset={5}>
            {content}

            <HoverCard.Arrow className="HoverCardArrow" />
          </HoverCard.Content>
        </HoverCard.Portal>
      )}
    </HoverCard.Root>
  )
}

export default HoverCardComponent
