import { AssetType } from 'common/disposition'
import { AssetTypeLabel } from 'common/types'

// converts assettype (ex: non-biological)  to assettype label (ex: hardware)

export function getAssetTypeLabel(assetType: AssetType): AssetTypeLabel {
  if (assetType === null) {
    return null
  }

  switch (assetType?.toLowerCase()) {
    case 'non-biological':
      return 'Implantable Hardware/Associated Product'
    case 'biological':
      return 'Implantable Biologic'
    case 'other-non-biological':
      return 'Implantable Other'
    case 'consumable':
      return 'Consumable'
    case 'associated_asset':
      return 'Associated Product'
    default:
      return null
  }
}
