// Types
import { Buttons, MainIconLandingProps } from './MainIconLanding.types'
import { MainIconFlowState } from 'common/types'

// Icons
import Implantable from 'assets/icons/svgs/Implantable.svg'
import PreferenceCardIcon from 'assets/icons/svgs/prefCardInactive_new.svg'
import ConsumableProducts from 'assets/icons/svgs/ConsumableProducts.svg'
import BiologicIcon from 'assets/icons/svgs/ImplantableBiological.svg'
import SalesRepIcon from 'assets/icons/svgs/SalesRepIcon.svg'
import TrayIcon from 'assets/icons/svgs/Tray.svg'
import ImplantableOtherIcon from 'assets/icons/svgs/ImplantableOther.svg'

// Other
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'app/User'
import toast from 'react-hot-toast'

export const useMainIconLanding = ({
  setMainIconFlowState,
  setOnMainIconLanding,
  setOnTrayIconLanding,
  setIsBatchMode,
  setIsSalesRepQrOpen,
  mainIconFlowState,
  setCurrentAssetType,
}: MainIconLandingProps) => {
  const flags = useFlags()
  const navigate = useNavigate()
  const { user } = useUser()
  const isMobile = useIsMobile()
  const isRepUser = user?.roles?.includes('MGAT_REP')

  const repButtons: Buttons = {
    hardware: {
      IconComponent: Implantable,
      label: 'Implantable Hardware / Associated Product',
      dataTestId: 'implantable-hardware-button',
    },
    implantableOther: {
      IconComponent: ImplantableOtherIcon,
      label: 'Implantable Other',
      dataTestId: 'implantable-other-button',
    },
  }

  const nurseButtons: Buttons = {
    hardware: {
      IconComponent: Implantable,
      label: 'Implantable Hardware / Associated Product',
      dataTestId: 'implantable-hardware-button',
    },
    implantableOther: {
      IconComponent: ImplantableOtherIcon,
      label: 'Implantable Other',
      dataTestId: 'implantable-other-button',
    },
    biologic: {
      IconComponent: BiologicIcon,
      label: 'Implantable Biologic',
      dataTestId: 'implantable-biologic-button',
    },
    consumables: {
      IconComponent: ConsumableProducts,
      label: 'Consumable Product',
      dataTestId: 'consumable-product-button',
    },
    trays: {
      IconComponent: TrayIcon,
      label: 'Trays',
      dataTestId: 'tray-button',
      isVisible: flags.assetTypeTraysButton,
    },
    preferenceCard: {
      IconComponent: PreferenceCardIcon,
      label: 'Preference Card',
      dataTestId: 'preference-card-button',
      isVisible: flags.assetTypePreferenceCardButton,
    },
    salesRep: {
      IconComponent: SalesRepIcon,
      label: 'Add Sales Rep',
      dataTestId: 'add-sales-rep-button',
      isVisible: flags.assetTypeAddSalesRepButton,
    },
  }

  const handleIconClick = (type: MainIconFlowState['assetTypeLabel']) => {
    setCurrentAssetType(type as MainIconFlowState['assetTypeLabel'])
    toast.dismiss()
    if (type === 'ADD SALES REP') {
      setIsSalesRepQrOpen(true)
      return
    }
    const setToBatchScan =
      (type === 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT' &&
        mainIconFlowState.isMultipackHardware === false) ||
      type === 'CONSUMABLE PRODUCT' ||
      type === 'IMPLANTABLE OTHER'
        ? true
        : false

    setMainIconFlowState({
      assetTypeLabel: type,
      isBatchModeEnabled: setToBatchScan,
      isMultipackHardware: false,
      multipackHardwareSelectedResult: undefined,
    })

    if (setToBatchScan) {
      setIsBatchMode(true)
    }

    if (type === 'PREFERENCE CARD') {
      navigate(`${window.location.pathname}/preference-card`)
      setOnMainIconLanding(false)
      return
    }

    setOnMainIconLanding(false)
    if (type === 'TRAYS') {
      setOnTrayIconLanding(true)
    } else {
      // Incase the selected icon is not a tray, user needs to navigate to the scanner screen
      if (setToBatchScan) {
        // If the scan is supposed to be a batch scan, navigate to subroute /asset/batch
        navigate(`${window.location.pathname}/asset/batch`)
      } else {
        // If it's a regular scan, navigate to subroute /asset/scan
        navigate(`${window.location.pathname}/asset/scan`)
      }
    }
  }

  return {
    icons: isRepUser ? repButtons : nurseButtons,
    handleIconClick,
    isMobile,
  }
}
