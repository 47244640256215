import { AssetData, AssetTypeLabel, Scan } from 'common/types'
import { TableRowProps } from '@mui/material'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { getReadableImplantStatus } from 'lib/utils/getReadableImplantStatus'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { SurgeryDetailsOutput } from 'views/UsageReports/ProcedureDetailsReport/ProcedureDetailsReport'

interface AssetDetailsTableProps {
  assetGroups: SurgeryDetailsOutput['assetGroups']
  isLoading: boolean
  isErrored: boolean
}

export const AssetDetailsTable = ({
  assetGroups,
  isLoading,
  isErrored,
}: AssetDetailsTableProps) => {
  const headCells: HeadCell[] = [
    {
      id: 'assetType',
      label: 'Product Type',
      numeric: false,
    },
    {
      id: 'disposition',
      label: 'Disposition',
      numeric: false,
    },
    {
      id: 'deviceDescription',
      label: 'Device',
      numeric: false,
    },
    {
      id: 'companyName',
      label: 'Company',
      numeric: false,
    },
    {
      id: 'quantity',
      label: 'Quantity',
      numeric: false,
    },
    {
      id: 'cost',
      label: 'Cost',
      numeric: false,
    },
  ]

  interface AssetDetailsRowsData extends TableRowProps {
    assetType: AssetTypeLabel
    disposition: string | null
    deviceDescription: Scan['deviceDescription']
    companyName: Scan['companyName']
    quantity: Scan['count']
    cost: Scan['cost'] | string
  }

  const mappedData: AssetDetailsRowsData[] = assetGroups
    .map((assetGroup: AssetData) => {
      return assetGroup.scans.map((scan: Scan) => {
        return {
          assetType: getAssetTypeLabel(scan.assetType),
          disposition: getReadableImplantStatus(scan.implantStatus) || '-',
          deviceDescription: scan.deviceDescription || '-',
          companyName: scan.companyName,
          quantity: scan.count,
          cost: scan.cost || '$XXX.00',
        }
      })
    })
    .flat()

  return (
    <DataTable
      tableHeaders={headCells}
      tableRows={mappedData || []}
      isLoading={isLoading}
      selectable={false}
      isErrored={isErrored}
    />
  )
}
