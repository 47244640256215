import { createContext, useContext, useState } from 'react'

interface BottomNavigationState {
  showBottomNavigation: boolean
  setShowBottomNavigation: (showBottomNavigation: boolean) => void
  resetBottomNavigation: () => void
}

const BottomNavigationContext = createContext(
  ({} as BottomNavigationState) || undefined
)

function BottomNavigationProvider({ children }: { children: React.ReactNode }) {
  const [showBottomNavigation, setShowBottomNavigation] = useState(true)

  const resetBottomNavigation = () => setShowBottomNavigation(true)

  const value = {
    showBottomNavigation,
    setShowBottomNavigation,
    resetBottomNavigation,
  }

  return (
    <BottomNavigationContext.Provider value={value}>
      {children}
    </BottomNavigationContext.Provider>
  )
}

function useBottomNavigation() {
  const context = useContext(BottomNavigationContext)
  if (context === undefined) {
    throw new Error(
      'useBottomNavigation must be used with BottomNavigationProvider'
    )
  }

  return context
}

export { BottomNavigationProvider, useBottomNavigation }
