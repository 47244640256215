import dayjs from 'lib/dayjs'
import { Alert, AlertTitle, TableRowProps } from '@mui/material'
import {
  Provider,
  Surgery,
  SurgicalCase,
  SurgicalStaff,
  Location,
  Procedures,
} from 'common/types'
import { useDashboardContext } from 'lib/context/DashboardContext'
import { AssetStatus } from 'components/molecules/AssetStatus'
import { GraphCard } from 'components/molecules/GraphCard'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'

const dateFormat = 'HH:mm'

const headCells: HeadCell[] = [
  {
    id: 'date',
    label: 'Scheduled Time',
    numeric: true,
  },
  {
    id: 'room',
    label: 'OR',
    numeric: false,
  },
  {
    id: 'patient',
    label: 'Patient',
    numeric: false,
  },
  {
    id: 'surgeon',
    label: 'Surgeon',
    numeric: false,
  },
  {
    id: 'procedureType',
    label: 'Procedure',
    numeric: false,
  },
  {
    id: 'scrub',
    label: 'Scrub',
    numeric: false,
  },
  {
    id: 'circ',
    label: 'Circ.',
    numeric: false,
  },
  {
    id: 'rep',
    label: 'Industry Rep.',
    numeric: false,
  },
  {
    id: 'surgeryStatus',
    label: 'Surgery Status',
    numeric: false,
  },
  {
    id: 'status',
    label: 'Product Capturing Status',
    numeric: true,
  },
]

export interface TodaysProceduresRowsData extends TableRowProps {
  status: JSX.Element
  date: Date | string
  room: Location['room']
  patient: string
  surgeon: Provider['lastName']
  procedureType: Procedures['description']
  scrub: SurgicalStaff['lastName']
  circ: SurgicalStaff['lastName']
  rep: string
  surgeryStatus: SurgicalCase['status']
}

export const TodaysProcedures = () => {
  const { todaysSurgeries, isLoading, error } = useDashboardContext()

  const title = `Today's Procedures: ${dayjs().format('ddd, DD MMM YYYY')}`
  const mappedData: TodaysProceduresRowsData[] = todaysSurgeries?.map(
    (surgery: Surgery) => {
      let status = 2
      if (
        (surgery?.status?.name === 'SCANNING' &&
          surgery?.assetGroups?.length > 0) ||
        (surgery?.status?.name === 'SCANNING' &&
          surgery?.undocumentedScansCount > 0)
      ) {
        status = 1
      }
      if (surgery?.status?.name === 'SUBMITTED') {
        status = 0
      }

      const room = surgery?.visit?.location?.room || ''
      const bed = surgery?.visit?.location?.bed || ''

      return {
        date: dayjs(surgery?.procedures[0].dateTime).format(dateFormat), // TODO: will need to specify which procedure
        room: room.concat(bed),
        patient: `${surgery?.patient?.lastName}, ${surgery?.patient?.firstName}`,
        surgeon: surgery?.visit?.attendingProvider?.lastName || '-',
        procedureType: surgery?.procedures[0]?.description || '-',
        scrub:
          surgery?.surgeryStaff?.find(
            (staff: any) => staff.roleDescription === 'scrub'
          )?.lastName || '-',
        circ:
          surgery?.surgeryStaff?.find(
            (staff: SurgicalStaff) => staff.roleDescription === 'circ'
          )?.lastName || '-',
        rep: surgery?.authorizedReps?.[0]
          ? // FIXME: Need to lookup rep user to get their name
            `${surgery?.authorizedReps[0]?.id}` /*`${surgery?.authorizedProductReps[0]?.firstName} ${surgery?.authorizedProductReps[0]?.lastName}`*/
          : '-',
        surgeryStatus: surgery?.surgicalCase?.status || '-',
        status: <AssetStatus status={status} surgeryId={surgery?._id} />,
      }
    }
  )

  //TODO: change error handling to alert dialog
  if (error) {
    return (
      <Alert severity="error" variant="filled">
        <AlertTitle>Error</AlertTitle>
        Failed to load recent procedures. Please contact support if the problem
        persists.
      </Alert>
    )
  }

  return (
    <GraphCard title={title}>
      <DataTable
        tableHeaders={headCells}
        tableRows={mappedData || []}
        isLoading={isLoading}
      />
    </GraphCard>
  )
}
