import React from 'react'
import { Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import { alpha } from '@mui/material'
import { RiseOutlined, FallOutlined } from '@ant-design/icons'
import { useDashboardContext } from 'lib/context/DashboardContext'

interface DataCardProps {
  primaryData: string | number
  primaryDataCaption: string
  children: React.ReactNode
  minimizeChild?: boolean
  minimizeText?: boolean
  comparisonStat?: string | number
  comparisonStatLabel?: string
}

export const DataCard = ({
  primaryData,
  primaryDataCaption,
  children,
  minimizeChild = false,
  minimizeText = false,
  comparisonStat,
  comparisonStatLabel,
}: DataCardProps) => {
  const { darkMode } = useDashboardContext()
  return (
    <Card
      aria-label="data-card"
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        backgroundColor: darkMode
          ? alpha('rgb(80, 53, 237)', 0.3)
          : 'grayscale.light',
        borderRadius: 3,
        maxHeight: '310px',
      }}
    >
      <CardContent
        aria-label="data-card-content"
        sx={{
          minHeight: '300px',
          '&.MuiCardContent-root:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Grid
          container
          aria-label="data-card-container"
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          rowSpacing={2}
          sx={{ minHeight: '300px' }}
        >
          <Grid item aria-label="data-card-primary-data">
            <Typography
              sx={{
                fontSize: minimizeText ? '3rem' : '5rem',
                fontWeight: 400,
                lineHeight: 1,
                color: darkMode ? 'grayscale.light' : 'grayscale.dark',
                textTransform: 'capitalize',
              }}
            >
              {primaryData}
            </Typography>
          </Grid>
          <Grid
            item
            aria-label="data-card-primary-data-caption"
            sx={{ '&.MuiGrid-item, &.MuiGrid-root': { paddingTop: 0 } }}
          >
            <Typography
              variant={minimizeText ? 'h4' : 'h3'}
              sx={{
                color: darkMode ? 'grayscale.light' : 'grayscale.dark',
                letterSpacing: 0.5,
                textTransform: 'capitalize',
              }}
            >
              {primaryDataCaption}
            </Typography>
          </Grid>
          {comparisonStat && (
            <Grid
              item
              aria-label="data-card-comparison"
              sx={{ '&.MuiGrid-item, &.MuiGrid-root': { paddingTop: 1 } }}
            >
              <Chip
                variant="outlined"
                color={comparisonStat === '+' ? 'success' : 'warning'}
                icon={
                  comparisonStat === '+' ? (
                    <RiseOutlined
                      style={{ fontSize: '0.75rem', color: 'inherit' }}
                    />
                  ) : (
                    <FallOutlined
                      style={{ fontSize: '0.75rem', color: 'inherit' }}
                    />
                  )
                }
                label={comparisonStatLabel}
                sx={{ pl: 1, '&.MuiChip-root': { fontSize: '0.9rem' } }}
                size="small"
              />
            </Grid>
          )}
          <Grid
            item
            aria-label="data-card-children"
            sx={{
              minWidth: minimizeChild ? null : '90%',
              maxWidth: minimizeChild ? null : '100%',
              '&.MuiGrid-item, &.MuiGrid-root': {
                paddingTop: 0,
              },
            }}
          >
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
