import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from 'app/User'
import PermissionDenied from 'components/PermissionDenied'
import { Role } from 'common/types'

interface Props {
  children: JSX.Element
  roles?: Role[]
  isPermitted?: boolean
}

const ProtectedUserRoute = ({ children, roles, isPermitted }: Props) => {
  let location = useLocation()

  const { user, isAuthenticated } = useUser()

  const userIsPermitted =
    isPermitted || (roles && roles?.some((role) => user?.roles?.includes(role)))

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />
  }

  if (!userIsPermitted) {
    return <PermissionDenied />
  }

  return children
}

export default ProtectedUserRoute
