import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

// Components
import { TextField } from '@mui/material'
import Select from 'components/molecules/Select/Select'
import { DateInput } from 'components/mui'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'

// Types
import { Option } from 'common/types'
import { ChildRef } from '../ProcessModal.types'

import { CreateProductProps } from './steps.types'
// Services
import useGetCompanies from 'lib/services/api/product-service/getCompanies'

const ChildComponent: React.ForwardRefRenderFunction<
  ChildRef,
  CreateProductProps
> = (props, ref) => {
  const {
    hasExpiryDate,
    hasLotBatch,
    hasModelNumber,
    hasSerialNumber,
    hasManufacturingDate,
    description: propDescription,
    modelNumber: propModelNumber,
    lotBatch: propLotBatch,
    serialNumber: propSerialNumber,
    expiryDate: propExpiryDate,
    manufacturingDate: propManufacturingDate,
    selectedCompany: propSelectedCompany,
    udi: propUdi,
    handleNext,
  } = props

  const isTablet = useIsTablet()
  const [localLotBatch, setLocalLotBatch] = useState('')
  const [localModelNumber, setLocalModelNumber] = useState('')
  const [localSerialNumber, setLocalSerialNumber] = useState('')
  const [localUdi, setLocalUdi] = useState('')
  const [localDescription, setLocalDescription] = useState('')
  const [localExpiryDate, setLocalExpiryDate] = useState<Date | undefined>()
  const [localManufacturingDate, setLocalManufacturingDate] = useState<
    Date | undefined
  >(new Date())
  const [localCompany, setLocalCompany] = useState<Option | undefined>()
  const [searchCompany, setSearchCompany] = useState<string>('')

  const { data: companiesData, loading: isLoadingCompanies } = useGetCompanies({
    searchByCompanyName: searchCompany.length > 3 ? searchCompany : '',
    enabled: searchCompany.length > 3,
  })

  const companies = companiesData?.searchCompanies?.companies.map((item) => ({
    name: item.name,
    id: item.id,
  }))

  useEffect(() => {
    setLocalDescription(propDescription || '')
    setLocalModelNumber(propModelNumber || '')
    setLocalLotBatch(propLotBatch || '')
    setLocalSerialNumber(propSerialNumber || '')
    setLocalExpiryDate(propExpiryDate)
    setLocalManufacturingDate(propManufacturingDate)
    setLocalCompany(propSelectedCompany)
    setLocalUdi(propUdi || '')
  }, [
    propDescription,
    propModelNumber,
    propLotBatch,
    propSerialNumber,
    propExpiryDate,
    propManufacturingDate,
    propSelectedCompany,
    propUdi,
  ])

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      handleNext &&
        handleNext({
          description: localDescription,
          modelNumber: localModelNumber,
          lotBatch: localLotBatch,
          serialNumber: localSerialNumber,
          expiryDate: localExpiryDate,
          manufacturingDate: localManufacturingDate,
          selectedCompany: localCompany,
        })
    },
  }))

  return (
    <div className={`create-product ${isTablet ? 'is-tablet' : ''}`}>
      <div className="section">
        <Select
          label="Company *"
          options={companies || []}
          onSelect={setLocalCompany}
          isLoading={isLoadingCompanies}
          onSearch={setSearchCompany}
          selectedValue={localCompany?.name || propSelectedCompany?.name}
        />
        <TextField
          placeholder="Description"
          required
          label="Description"
          variant="outlined"
          value={localDescription}
          onChange={(e) => setLocalDescription(e.target.value)}
        />
        <DateInput
          id="expiryDate"
          label="Expiry date"
          name="expiryDate"
          variant="outlined"
          minDate={new Date()}
          fullWidth
          openTo="day"
          value={localExpiryDate}
          disabled={hasExpiryDate}
          handleChangeFormData={(e) => setLocalExpiryDate(e.target.value)}
        />
        <DateInput
          id="manufacturingDate"
          name="manufacturingDate"
          variant="outlined"
          fullWidth
          openTo="day"
          label="Manufacturing date"
          disabled={hasManufacturingDate}
          value={localManufacturingDate}
          handleChangeFormData={(e) =>
            setLocalManufacturingDate(e.target.value)
          }
        />
      </div>
      <div className="section">
        <TextField
          placeholder="Lot"
          label="Lot"
          value={localLotBatch}
          onChange={(e) => setLocalLotBatch(e.target.value)}
          disabled={hasLotBatch}
        />
        <TextField
          placeholder="Serial number"
          label="Serial number"
          value={localSerialNumber}
          onChange={(e) => setLocalSerialNumber(e.target.value)}
          disabled={hasSerialNumber}
        />
        <TextField
          placeholder="Model number"
          required
          label="Model number"
          value={localModelNumber}
          onChange={(e) => setLocalModelNumber(e.target.value)}
          disabled={hasModelNumber}
        />
        <TextField label="Barcode" disabled value={localUdi} />
      </div>
    </div>
  )
}

export default forwardRef(ChildComponent)
