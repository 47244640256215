import { MenuItem, ListItemIcon } from '@mui/material'
import { useUser } from 'app/User'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useLogic = () => {
  const navigate = useNavigate()

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorElMenu)
  const { user } = useUser()

  const handleLogout = () => {
    navigate('/logout')
    handleClose()
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElMenu(null)
  }

  const renderMenuItem = (
    icon: React.ReactElement,
    text: string | undefined,
    onClick?: () => void
  ) => {
    return (
      <MenuItem onClick={onClick} sx={{ gap: user?.roles ? 2 : 0.5 }}>
        <ListItemIcon>{icon}</ListItemIcon>
        {text}
      </MenuItem>
    )
  }

  return {
    handleClick,
    handleClose,
    open,
    anchorElMenu,
    handleLogout,
    name: user?.nickname,
    picture: user?.picture,
    email: user?.email,
    roles: user?.roles,
    renderMenuItem,
  }
}
