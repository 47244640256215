import { FC } from 'react'

// MUI
import Typography from '@mui/material/Typography'

// Icons
import { Info } from '@mui/icons-material'

// Style
import './Warning.css'

// Types
import { WarningTypes, IWarning } from './Warning.types'

const colorMap: Record<WarningTypes, string> = {
  warning: 'var(--light-red)',
  info: 'var(--blue)',
  success: 'var(--light-green)',
  danger: 'var(--light-red)',
}

const bgColorMap: Record<WarningTypes, string> = {
  warning: 'var(--light-red-bg)',
  info: 'var(--light-blue-bg)',
  success: 'var(--light-green-bg)',
  danger: 'var(--light-red-bg)',
}

const Warning: FC<IWarning> = ({ text, type }) => {
  const color = colorMap[type] || 'var(--black)'
  const bgColor = bgColorMap[type] || 'var(--gray-4)'

  return (
    <div
      className="warning-container"
      style={{ backgroundColor: bgColor, color }}
    >
      <Typography>{text}</Typography>
      <Info color={type as any} />
    </div>
  )
}

export default Warning
