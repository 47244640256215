import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Box, Typography, LinearProgress } from '@mui/material'
import { useCortexDecoderContext } from 'lib/batchScanner'
import { useBatchScanHandler } from 'lib/batch-scan-handler'
import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import { ScannerFormatSwitcher } from 'components/BatchScanFormatSwitcher'
import BetterIdResultsModal from 'components/BetterIdResultsModal'
import { AlternateScanResultModal } from 'components/AlternateScanResultModal'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { BatchSetCountModal } from 'components/BatchSetCountModal'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { MainIconFlowState } from 'common/types'
import { getAssetTypeCaps } from 'lib/utils/getAssetType'
import { UndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useUser } from 'app/User'
import { LoadingButton } from 'components/mui'

declare global {
  interface Window {
    mockScan: (text: string) => void
  }
}

interface BatchScanProps {
  mainIconFlowState: MainIconFlowState
  setMainIconFlowState: (val: MainIconFlowState) => void
  setIsBatchMode: React.Dispatch<React.SetStateAction<boolean>>
  currentAssetType: MainIconFlowState['assetTypeLabel']
}

const TOAST_DURATION = 1800

export function BatchScan({
  mainIconFlowState,
  setMainIconFlowState,
  setIsBatchMode,
  currentAssetType,
}: BatchScanProps) {
  const user = useUser()
  const { addUndocumentedScan, resetAddMutation } = React.useContext(
    UndocumentedAssetsContext
  )

  const navigate = useNavigate()
  const {
    resetScan,
    setEnabled,
    setCurrentScan,
    isActive,
    error: scannerError,
  } = useCortexDecoderContext()

  const [isRestrictedAsset, setIsRestrictedAsset] =
    React.useState<boolean>(false)

  // Add mock scanner for testing
  React.useEffect(() => {
    if (window && process.env.REACT_APP_NODE_ENV !== 'production') {
      window.mockScan = (text: string) => {
        setCurrentScan({
          barcodeData: text,
          decodeTime: Date.now(),
          symbologyName: 'DataMatrix',
          barcodeCoordinates: {
            TopLeft: { X: '0', Y: '0' },
            TopRight: { X: '0', Y: '0' },
            BottomRight: { X: '0', Y: '0' },
            BottomLeft: { X: '0', Y: '0' },
          },
        })
      }
    }
  }, [setCurrentScan])

  const {
    activeValue,
    results,
    selectedResult,
    setSelectedResult,
    details,
    lookupState,
    _lookupQuery,
    reset,
  } = useBatchScanHandler()

  const [isExpiredAlert, setIsExpiredAlert] = React.useState<
    boolean | undefined
  >()
  const [resultCount, setResultCount] = React.useState<number | undefined>()
  const loadingToastRef = React.useRef<string | undefined>()
  const isAddingScanRef = React.useRef(false)

  const navigateToManualAdd = () => navigate('../../asset/manual')

  const dismissLoadingToast = React.useCallback(() => {
    if (loadingToastRef.current) {
      toast.dismiss(loadingToastRef.current)
      loadingToastRef.current = undefined
    }
  }, [])

  const resetScanner = () => {
    reset()
    setSelectedResult(undefined)
    setIsExpiredAlert(undefined)
    toast.dismiss(loadingToastRef.current)
    loadingToastRef.current = undefined
  }

  // sponge counts and multipack assets
  const assetType = getAssetTypeCaps(mainIconFlowState.assetTypeLabel)

  const {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isMultipackHardware,
    isMultipackAsset,
  } = parseAssetIdentifiers({
    deviceDescription: selectedResult?.deviceDescription,
    deviceCount: selectedResult?.deviceCount ?? 1,
    idType: selectedResult?.secondaryDeviceIdType,
    assetType: assetType,
  })

  // Enable scanner on mount, disable scanner on unmount (camera still active
  // in the background)
  React.useEffect(() => {
    setEnabled(true)
    return () => {
      setEnabled(false)
      resetScan()
    }
  }, [setEnabled, resetScan])

  // Loading and error toasts
  React.useEffect(() => {
    if (lookupState.error) {
      toast.error('Failed to get result from BetterID & GUDID databases', {
        id: loadingToastRef.current,
      })
    } else if (lookupState.isLoading && !loadingToastRef.current) {
      loadingToastRef.current = toast.loading(
        'Image captured: Looking up and adding for documentation...'
      )
    } else if (lookupState.isComplete) {
      toast.dismiss(loadingToastRef.current)
    }
  }, [lookupState, dismissLoadingToast])

  // Handle pausing batch add for multi-device and expired assets
  React.useEffect(() => {
    if (
      user.isRep &&
      selectedResult &&
      !isAddingScanRef.current &&
      selectedResult.companyId !== user.user?.bidCompanyId
    ) {
      setIsRestrictedAsset(true)
      return
    }

    if (
      typeof isExpiredAlert === 'undefined' &&
      selectedResult &&
      !isAddingScanRef.current
    ) {
      if (
        selectedResult?.expirationDate &&
        new Date(selectedResult.expirationDate) < new Date()
      ) {
        setIsExpiredAlert(true)
      } else {
        setIsExpiredAlert(false)
      }
    }
  }, [selectedResult, isExpiredAlert, user])

  React.useEffect(
    () => {
      if (selectedResult) {
        if (
          (selectedResult.deviceCount > 1 &&
            !resultCount &&
            !isMultipackAsset) ||
          isExpiredAlert !== false ||
          (user.isRep && selectedResult.companyId !== user.user?.bidCompanyId)
        ) {
          // paused, dismiss toast
          toast.dismiss(loadingToastRef.current)
          return
        }

        if (!resultCount && isMultipackHardware) {
          setMainIconFlowState({
            assetTypeLabel: 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT',
            isBatchModeEnabled: false,
            isMultipackHardware: true,
            multipackHardwareSelectedResult: {
              ...selectedResult,
            },
          })
          setIsBatchMode(false)
          navigate('../../asset/result')
        }

        if (
          (!isAddingScanRef.current &&
            !isMultipackHardware &&
            !isMultipackConsumable) ||
          (isMultipackConsumable && resultCount)
        ) {
          isAddingScanRef.current = true

          // @ts-ignore - being dumb
          const addPromise = addUndocumentedScan({
            variables: {
              // @ts-ignore - being dumb
              data: {
                bidAssetId: selectedResult.id,
                bidCompanyId: selectedResult.companyId,
                // bidAssetInstanceId: selectedResult.fromAssetInstanceId,
                catalogNumber: selectedResult.catalogNumber,
                deviceDescription: selectedResult.deviceDescription,
                deviceId: selectedResult.deviceId,
                deviceCount: selectedResult.deviceCount,
                count: resultCount ?? 1,
                pkgQuantity: selectedResult.pkgQuantity,
                expirationDate: selectedResult.expirationDate,
                lotBatch: selectedResult.lotBatch,
                companyName: selectedResult.company?.name,
                manufacturingDate: selectedResult.manufacturingDate,
                versionModelNumber: selectedResult.versionModelNumber,
                serialNumber: selectedResult.serialNumber,
                sizeText: selectedResult.sizeText,
                sizeString: selectedResult.sizeString,
                udi: selectedResult.udi,
                issuingAgency: selectedResult.issuingAgency,
                secondaryDeviceIdType: selectedResult.secondaryDeviceIdType,
                assetType: assetType,
                gmdnPTDefinition: selectedResult.gmdnPTDefinition || null,
                cost: selectedResult.cost,
                chargeable: selectedResult.chargeable,
              },
            },
          })

          setResultCount(undefined)
          setIsExpiredAlert(undefined)

          addPromise
            .then(() => {
              toast.success(
                `${
                  selectedResult.deviceDescription ?? selectedResult.deviceId
                } - Added and ready for documentation.`,
                {
                  id: loadingToastRef.current,
                }
              )
            })
            .catch((err: any) => {
              console.error(err)
              toast.error('Failed to add image capture to procedure', {
                id: loadingToastRef.current,
              })
            })
            .finally(() => {
              // the reset timeout needs to match the duration of the success toast,
              // because the toast shows data from the selectedResult that gets reset
              // this shouldn't cause a memory leak since the data being reset is not
              // local to this component but in a higher scoped context
              isAddingScanRef.current = false
              setTimeout(() => {
                reset()
                resetAddMutation()
                dismissLoadingToast()
              }, TOAST_DURATION)
            })
        }
      }
    },
    // do not add multipack hardware conditions to these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedResult,
      addUndocumentedScan,
      reset,
      resetAddMutation,
      dismissLoadingToast,
      resultCount,
      isExpiredAlert,
      isMultipackAsset,
      isRfMultipackConsumable,
      isMultipackHardware,
      lookupState,
    ]
  )

  return (
    <>
      <Helmet>
        <title>Batch Image Capture</title>
      </Helmet>
      <BetterIdResultsModal
        currentScanValue={activeValue}
        results={results}
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
        valueType={details.type}
        onClose={resetScanner}
      />
      <AlternateScanResultModal
        currentScanValue={activeValue}
        results={results}
        lookupQuery={_lookupQuery}
        isComplete={lookupState.isComplete}
        valueType={details.type}
        onClose={resetScanner}
      />
      <AlertDialog
        title="Product Expired"
        description="This product has expired and cannot be added to the procedure."
        isOpen={isExpiredAlert ?? false}
        secondaryButtonAction={resetScanner}
        secondaryButtonText="Cancel"
        primaryButtonAction={() => {
          loadingToastRef.current = toast.loading(
            'Adding captured product to undocumented list'
          )
          setIsExpiredAlert(false)
        }}
        primaryButtonText="Add Anyways"
        dismissable={false}
      />
      <AlertDialog
        title="Restriced Product"
        description={`This product does not belong to ${user.user?.companyName}, it belongs to ${selectedResult?.company?.name} and can not be added to the procedure.`}
        isOpen={isRestrictedAsset ?? false}
        primaryButtonText="OK"
        primaryButtonAction={() => {
          resetScanner()
          setIsRestrictedAsset(false)
        }}
        dismissable={false}
      />
      {selectedResult &&
        !isMultipackHardware &&
        isMultipackConsumable &&
        !resultCount && (
          <BatchSetCountModal
            isOpen={
              isMultipackConsumable && !resultCount && isExpiredAlert === false
            }
            scan={selectedResult}
            onClose={() => {
              resetScanner()
            }}
            onSave={(count) => {
              loadingToastRef.current = toast.loading(
                'Adding captured product to undocumented list'
              )
              setResultCount(count)
            }}
            mainIconFlowState={mainIconFlowState}
          />
        )}

      <Box display="inline-flex" flexDirection="column" alignItems="center">
        {scannerError ? (
          <ErrorFallback
            title="Failed to initiate Smart Vision"
            description="Our team has been notified of the issue."
          />
        ) : (
          <>
            {!isActive && (
              <Box
                width="100vw"
                height="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                position="fixed"
                sx={{
                  bgcolor: 'background.paper',
                }}
              >
                <Box textAlign="center" mb={28}>
                  <Typography
                    variant="h3"
                    color="grey.800"
                    my={6}
                    letterSpacing={1}
                    fontWeight={500}
                    fontSize={18}
                  >
                    Preparing Smart Vision
                  </Typography>
                  <LinearProgress />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                position: 'fixed',
                top: '100px',
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '4px',
                color: 'white',
                padding: '12px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <ScannerFormatSwitcher />
              {currentAssetType && (
                <Box
                  sx={{
                    color: 'white',
                    fontWeight: 900,
                    background: 'black',
                    opacity: '75%',
                    padding: '10px',
                    borderRadius: '6px',
                    maxWidth: '245px',
                    wordWrap: 'break-word',
                    textAlign: 'center',
                  }}
                >
                  {currentAssetType}
                </Box>
              )}
            </Box>
            <Box
              position="fixed"
              bottom="80px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              columnGap={1}
            >
              <LoadingButton onClick={navigateToManualAdd} variant="contained">
                Add Manually
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}
