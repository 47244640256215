import * as React from 'react'

export function useDisablePolling() {
  const [disablePolling, setDisablePolling] = React.useState(false)

  // Hack to disable query polling for development purposes
  React.useEffect(() => {
    if (typeof window.__isPollingDisabled === 'boolean') {
      setDisablePolling(window.__isPollingDisabled)
    }

    if (window) {
      window.__togglePolling = () => {
        setDisablePolling((c) => {
          const newVal = !c
          window.__isPollingDisabled = newVal
          return newVal
        })
        return window.__isPollingDisabled
          ? 'Polling has been disabled'
          : 'Polling has been enabled'
      }
    }
  }, [setDisablePolling])

  return disablePolling
}
