import { FC, ReactNode, useState } from 'react'

// Components
import ReportDetail from 'components/molecules/ReportDetail/ReportDetail'

// MUI
import { GridMoreVertIcon } from '@mui/x-data-grid'
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from '@mui/material'

// Types
import { IReportCard } from './ReportCard.types'

// Style
import './ReportCard.scss'

const disposition = {
  Consigned: 'Yes',
  Purchased: 'No',
  Mixed: 'Mixed',
}

const renderWidth = {
  small: '50px',
  medium: '100px',
  large: '250px',
}

const ReportDetailWithPopover = ({ locations }: { locations: string[] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOpenLocations = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Chip
        label={`Found in ${locations.length} locations`}
        className="locations-chip"
        onClick={handleOpenLocations}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          mt: 1,
        }}
      >
        <Box className="locations-pop-up">
          {locations.map((location, index) => (
            <Box className="location" key={index}>{`- ${location}`}</Box>
          ))}
        </Box>
      </Popover>
    </>
  )
}

const renderReportDetail = (
  title: string,
  value: string | number | ReactNode
) => (
  <Grid item xs={6} md={6}>
    <ReportDetail title={title} value={value || '-'} alignItems="flex-start" />
  </Grid>
)

const MobileReportCard: FC<IReportCard> = ({
  data,
  reportOptions,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Box onClick={onClick} className="mobile-report-card-container">
      <Grid item xs={12} md={12} className="header">
        <ReportDetail
          title="Description"
          titleStyle={{
            color: 'var(--text-gray)',
            fontWeight: 100,
          }}
          value={data.description || '-'}
          alignItems="flex-start"
        />
        <Box className="options">
          <IconButton
            aria-label="options"
            aria-controls="options-menu"
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation()
              handleOpenMenu(e)
            }}
          >
            <GridMoreVertIcon />
          </IconButton>
          <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {reportOptions?.map((option, index) => (
              <MenuItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  option.onClick(data)
                  setAnchorEl(null)
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Grid>
      <Box className="body">
        {data && (
          <Grid container spacing={1}>
            {renderReportDetail('Company', data.vendorName)}
            {renderReportDetail('Product type', data.type)}
            {renderReportDetail('Consigned', disposition[data.disposition])}
            {renderReportDetail(
              'Version Model Number',
              data.versionModelNumber
            )}
            {renderReportDetail('PAR value', data.parValue)}
            {renderReportDetail('Quantity', data.quantity)}
            {renderReportDetail(
              'Locations',
              data.inventoryLocationsNames?.length > 1 ? (
                <ReportDetailWithPopover
                  locations={data.inventoryLocationsNames}
                />
              ) : (
                String(...data.inventoryLocationsNames) || '-'
              )
            )}
          </Grid>
        )}
      </Box>
    </Box>
  )
}

const RegularReportCard: FC<IReportCard> = ({ data, reportOptions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const renderReportDetail = (
    title: string,
    value: string | number | ReactNode,
    width: 'small' | 'medium' | 'large'
  ) => {
    return (
      <Box sx={{ width: '100%', maxWidth: renderWidth[width] }}>
        <ReportDetail
          valueStyle={{
            fontSize: '18px',
          }}
          titleStyle={{
            fontSize: '12px',
          }}
          title={title}
          value={value || '-'}
          alignItems="flex-start"
        />
      </Box>
    )
  }

  return (
    <Box className="report-card-container">
      <Box className="header">
        <ReportDetail
          value={data.description || '-'}
          title="Description"
          alignItems="flex-start"
          titleStyle={{
            color: 'var(--text-gray)',
            fontWeight: 100,
          }}
          valueStyle={{
            fontSize: '19px',
          }}
        />
        <Box className="options">
          <IconButton
            aria-label="options"
            aria-controls="options-menu"
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation()
              handleOpenMenu(e)
            }}
          >
            <GridMoreVertIcon />
          </IconButton>
          <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {reportOptions?.map((option, index) => (
              <MenuItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  option.onClick(data)
                  setAnchorEl(null)
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box className="body">
        {data && (
          <>
            <Box className="detail-row">
              {renderReportDetail('Company', data.vendorName, 'large')}
              {renderReportDetail(
                'Version Model Number',
                data.versionModelNumber,
                'large'
              )}
              {renderReportDetail(
                'Locations',
                data.inventoryLocationsNames?.length > 1 ? (
                  <ReportDetailWithPopover
                    locations={data.inventoryLocationsNames}
                  />
                ) : (
                  String(...data.inventoryLocationsNames) || '-'
                ),
                'large'
              )}
              {renderReportDetail('Product type', data.type, 'medium')}
              {renderReportDetail(
                'Consigned',
                disposition[data.disposition],
                'medium'
              )}
              {renderReportDetail('PAR value', data.parValue, 'medium')}
              {renderReportDetail('Quantity', data.quantity, 'medium')}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

const ReportCard: FC<IReportCard> = ({
  data,
  reportOptions,
  isTablet,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return isTablet ? (
    <MobileReportCard
      data={data}
      onClick={onClick}
      reportOptions={reportOptions}
    />
  ) : (
    <RegularReportCard
      data={data}
      onClick={onClick}
      handleCloseMenu={handleCloseMenu}
      handleOpenMenu={handleOpenMenu}
      anchorEl={anchorEl}
      reportOptions={reportOptions}
    />
  )
}

export default ReportCard
