/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react'

// Router
import { useLocation, useNavigate } from 'react-router-dom'

// MUI
import { ButtonBase, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// Components
import { ManualAddAssetForm } from './ManualAddAssetForm/ManualAddAssetForm'
import { TrayScanner } from 'components/TrayScanner'

// Types
import { ManualAssetEntryProps } from './ManualAssetEntry.type'

// Styles
import './ManualAssetEntry.scss'

// Other
import { Helmet } from 'react-helmet'
import { CortexDecoderProvider, CortexVideo } from 'lib/batchScanner'

const ManualAssetEntry: FC<ManualAssetEntryProps> = ({
  scanDetails,
  assetFormData,
  currentAssetType,
  resetQueryAndMutation,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { scannedCode } = (location.state || {}) as any
  const [currentScan, setCurrentScan] = useState<
    CortexDecoder.CDResult | undefined
  >()
  const [assetCode, setAssetCode] = useState<string | undefined>(scannedCode)
  const showAddNewView = typeof assetCode === 'string'
  const isScannedCode = Boolean(scannedCode)

  const title = showAddNewView
    ? 'Add New Product Form - MGAT'
    : 'Manual Product Lookup - MGAT'

  useEffect(() => resetQueryAndMutation(), [location])

  return (
    <CortexDecoderProvider
      onScan={setCurrentScan}
      currentScan={currentScan}
      setCurrentScan={setCurrentScan}
    >
      <CortexVideo />
      <TrayScanner>
        {(props) => (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>

            <div className="manual-add-container">
              <div className="manual-add-header">
                <ButtonBase disableRipple onClick={() => navigate('../scan')}>
                  <KeyboardBackspaceIcon
                    color="primary"
                    className="back-icon"
                  />
                </ButtonBase>
                <Typography variant="h2">Manual Add</Typography>
                <div />
              </div>
              <ManualAddAssetForm
                assetCode={assetCode}
                setAssetCode={setAssetCode}
                scanDetails={scanDetails}
                isScannedCode={isScannedCode}
                mainAssetFormData={assetFormData}
                currentAssetType={currentAssetType}
                {...props}
              />
            </div>
          </>
        )}
      </TrayScanner>
    </CortexDecoderProvider>
  )
}
export default ManualAssetEntry
