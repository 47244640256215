import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { alpha } from '@mui/material'
import { TableHeader } from './TableHeader'
import { useDashboardContext } from 'lib/context/DashboardContext'

interface GraphCardProps {
  title: string
  children: React.ReactNode
}

export const GraphCard = ({ title, children }: GraphCardProps) => {
  const { darkMode } = useDashboardContext()

  return (
    <Card
      aria-label="graph-card"
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        backgroundColor: darkMode
          ? alpha('rgb(80, 53, 237)', 0.3)
          : 'grayscale.light',
        borderRadius: 3,
      }}
    >
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item>
            <TableHeader title={title} renderBackgroundColor={false} />
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
