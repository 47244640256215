import React from 'react'

// Logic
import useCaptureCameraLogic from './CameraCapture.logic'

// Style
import './CameraCapture.scss'

// Other
import classNames from 'classnames'
import { CameraAlt } from '@mui/icons-material'

const CameraCapture: React.FC<{
  onCapture: (file: File) => void
  canCapture: boolean
}> = ({ canCapture, onCapture }) => {
  const { videoRef, isStreaming, captureImage } = useCaptureCameraLogic({
    onCapture,
  })

  return (
    <div className="camera-capture">
      <video ref={videoRef} autoPlay playsInline className="video-canvas" />
      {isStreaming && (
        <div className="camera-actions">
          <CameraAlt
            fontSize="large"
            className={classNames('camera-icon', {
              disabled: !isStreaming || !canCapture,
            })}
            onClick={() => {
              if (!canCapture) return
              captureImage()
            }}
          />
          {/* {cameras.length > 1 && (
            <div className="camera-switch">
            <span>Switch camera:</span>
            {cameras.map((camera) => (
              <button
              key={camera.deviceId}
              onClick={() => switchCamera(camera)}
              disabled={camera.deviceId === activeCamera?.deviceId}
              >
              {camera.label}
              </button>
              ))}
              </div>
            )} */}
        </div>
      )}
    </div>
  )
}

export default CameraCapture
