import * as yup from 'yup'
import { FormValidationProps } from 'common/types'

export const manualAssetInputSchema = yup.object().shape({
  deviceDescription: yup.string().min(3).optional(),
  companyName: yup.string().optional(),
  deviceId: yup.string().optional(),
  deviceCount: yup.number().required().default(1),
  lotBatch: yup.string().optional(),
  serialNumber: yup.string().optional(),
  manufacturingDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  expirationDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  udi: yup.string().optional(),
})

export const repRecordApprovalSchema = yup.object().shape({
  repName: yup.string().required(),
})

export const editRepSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  mobileNumber: yup
    .string()
    .notRequired()
    .min(12)
    .nullable()
    .transform((value) => (!!value ? value : null)),
})

export const signUpRepSchema = yup.object().shape({
  email: yup.string().email().required(),
  company: yup.string().required(),
})

export const validateForm = async ({
  formData,
  schema,
}: FormValidationProps) => {
  const isFormValid = await schema?.isValid(formData, {
    abortEarly: false,
  })

  if (isFormValid) {
    return {}
  }

  if (!isFormValid) {
    const formErrors = schema
      ?.validate(formData, { abortEarly: false })
      .catch((err: { inner: any[] }) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          if (!formData.hasOwnProperty(error.path)) {
            return null
          } else {
            return {
              ...acc,
              [error.path]: true,
            }
          }
        }, {})
        return errors
      })
    return formErrors
  }
}
