import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'

// MUI
import {
  Avatar,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

// Components
import Button from 'components/molecules/Button/Button'

// Types
import {
  AvatarWithAddPhotoProps,
  InstrumentsProps,
  NotesProps,
  ProductsProps,
  Product,
} from './CardView.types'
import { PositionSelectorProps } from '../PreferenceCards.types'

// Icons
import { PlusCircleFilled } from '@ant-design/icons'
import { Close, Delete } from '@mui/icons-material'

// Images
import pos1 from 'assets/icons/positions/pos-1.png'
import pos2 from 'assets/icons/positions/pos-2.png'
import pos3 from 'assets/icons/positions/pos-3.png'
import pos4 from 'assets/icons/positions/pos-4.png'
import pos5 from 'assets/icons/positions/pos-5.png'
import pos6 from 'assets/icons/positions/pos-6.png'
import pos7 from 'assets/icons/positions/pos-7.png'
import pos8 from 'assets/icons/positions/pos-8.png'
import pos9 from 'assets/icons/positions/pos-9.png'
import pos10 from 'assets/icons/positions/pos-10.png'
import pos11 from 'assets/icons/positions/pos-11.png'
import pos12 from 'assets/icons/positions/pos-12.png'
import imageCompression from 'browser-image-compression'

const positions: { image: string; name: string }[] = [
  {
    image: pos1,
    name: 'Supine Position',
  },
  {
    image: pos2,
    name: 'Trendelenburg Position',
  },
  {
    image: pos3,
    name: 'Reverse Trendelenburg Position',
  },
  {
    image: pos4,
    name: 'Fracture Table Position',
  },
  {
    image: pos5,
    name: 'Lithotomy Position',
  },
  {
    image: pos6,
    name: 'Prone Position',
  },
  {
    image: pos7,
    name: 'Jackknife Position',
  },
  {
    image: pos8,
    name: "Fowler's Position",
  },
  {
    image: pos9,
    name: 'Knee-Chest Position',
  },
  {
    image: pos10,
    name: 'Kidney Position',
  },
  {
    image: pos11,
    name: 'Lateral Position',
  },
  {
    image: pos12,
    name: 'Wilson Frame Position',
  },
]

export const AvatarWithAddPhoto: FC<AvatarWithAddPhotoProps> = ({
  defaultImage,
  disabled: initialDisabled = false,
  onImageChange = () => {},
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [avatarImage, setAvatarImage] = useState<string | undefined>(
    defaultImage
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (initialDisabled || isLoading) return

    const file = event.target.files?.[0]
    if (file) {
      setIsLoading(true)
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.02,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        })

        const reader = new FileReader()
        reader.onloadend = () => {
          const imageBase64 = reader.result as string
          setAvatarImage(imageBase64)
          onImageChange(imageBase64)
        }
        reader.readAsDataURL(compressedFile)
      } catch (error) {
        console.error('Error compressing file:', error)
      } finally {
        setTimeout(() => setIsLoading(false), 2500)
      }
    }
  }

  const handleAddPhotoClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  useEffect(() => {
    setAvatarImage(defaultImage)
  }, [defaultImage])

  return (
    <div className="section center">
      <Avatar
        alt="Avatar"
        src={avatarImage || undefined}
        sx={{ width: 100, height: 100 }}
      />
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
      <div>
        <Button
          isLoading={isLoading}
          variant="contained"
          onClick={handleAddPhotoClick}
          disabled={initialDisabled || isLoading}
        >
          Add Photo
        </Button>
      </div>
    </div>
  )
}

export const Notes: FC<NotesProps> = ({
  title = 'Notes',
  initialNotes,
  onNotesChange = () => {},
}) => {
  const [notes, setNotes] = useState<string[]>([])

  useMemo(() => {
    setNotes(initialNotes || [''])
  }, [initialNotes])

  const addNote = () => {
    setNotes([...notes, ''])
  }

  const handleNoteChange = (index: number, value: string) => {
    const updatedNotes = [...notes]
    updatedNotes[index] = value
    setNotes(updatedNotes)
    onNotesChange(updatedNotes)
  }

  const removeNote = (index: number) => {
    if (notes.length === 1) return
    const updatedNotes = [...notes]
    updatedNotes.splice(index, 1)
    setNotes(updatedNotes)
    onNotesChange(updatedNotes)
  }

  return (
    <div className="notes-container">
      <Typography variant="h3">
        {title}
        <IconButton onClick={addNote} color="primary" aria-label="add">
          <PlusCircleFilled />
        </IconButton>
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {notes.map((note, index) => (
          <div key={index}>
            <TextField
              label={`Note ${index + 1}`}
              value={note}
              onChange={(e) => handleNoteChange(index, e.target.value)}
              multiline
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <ButtonBase onClick={() => removeNote(index)}>
                    <Close />
                  </ButtonBase>
                ),
              }}
              InputLabelProps={
                notes.length
                  ? {
                      shrink: true,
                    }
                  : {
                      shrink: false,
                    }
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export const PositionSelector: FC<PositionSelectorProps> = ({
  onChange,
  selected,
}) => {
  const [selectedPosition, setSelectedPosition] = useState<number | null>(
    selected?.index || null
  )
  const [showOtherPosition, setShowOtherPosition] = useState<boolean>(
    selected?.other ? true : false
  )
  const [otherPosition, setOtherPosition] = useState<string>(
    selected?.other || ''
  )

  useMemo(() => {
    if (selected?.index !== undefined) setSelectedPosition(selected.index)
    if (selected?.other) {
      setShowOtherPosition(true)
      setOtherPosition(selected.other)
    }
  }, [selected])

  const handlePositionClick = (index: number) => {
    setSelectedPosition(index)
    onChange({ index })
  }

  const handleOtherPositionToggle = () => {
    setShowOtherPosition(!showOtherPosition)
    setOtherPosition('') // Reset other position text field when toggled
    setSelectedPosition(null) // Deselect any selected position when toggled
    onChange(null)
  }

  const handleOtherPositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOtherPosition(event.target.value)
    setSelectedPosition(null) // Deselect any selected position when other position is typed
    onChange({ other: event.target.value })
  }

  return (
    <div className="positions-container">
      <Typography variant="h3">Select position</Typography>
      <div className="positions">
        {positions.map((position, index) => (
          <ButtonBase
            key={index}
            className={`position ${
              selectedPosition === index ? 'selected' : ''
            }`}
            onClick={() => handlePositionClick(index)}
          >
            <img
              src={position.image}
              alt={`Position ${index + 1}`}
              className="position-image"
            />
          </ButtonBase>
        ))}
      </div>
      {selectedPosition === 9 || selectedPosition === 10 ? (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected?.side === 'Right' ? true : false}
                onChange={(e) => {
                  onChange({
                    index: selectedPosition,
                    side: e.target.checked ? 'Right' : undefined,
                  })
                }}
              />
            }
            label="Right"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selected?.side === 'Left' ? true : false}
                onChange={(e) => {
                  onChange({
                    index: selectedPosition,
                    side: e.target.checked ? 'Left' : undefined,
                  })
                }}
              />
            }
            label="Left"
          />
        </div>
      ) : null}
      <div>
        <FormControlLabel
          control={<Checkbox />}
          label="Other"
          checked={showOtherPosition}
          onChange={handleOtherPositionToggle}
        />
        {showOtherPosition && (
          <TextField
            value={otherPosition}
            onChange={handleOtherPositionChange}
            label="Enter Other Position"
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={
              otherPosition
                ? {
                    shrink: true,
                  }
                : {
                    shrink: false,
                  }
            }
          />
        )}
      </div>
    </div>
  )
}

export const InstrumentTrays: FC<InstrumentsProps> = ({
  selectedInstruments,
  options,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const handleCheckboxChange = (option: string) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option)
      : [...selectedOptions, option]
    setSelectedOptions(updatedOptions)
    onChange(updatedOptions)
  }

  useMemo(() => {
    setSelectedOptions(selectedInstruments || [])
  }, [selectedInstruments])

  return (
    <div>
      <Grid container spacing={1}>
        {options.map((option, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
              }
              label={option}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export const Products: FC<ProductsProps> = ({
  selectedProducts,
  title = 'Products',
  onChange,
}) => {
  const [products, setProducts] = useState<Product[]>([
    { company: '', description: '' },
  ])

  useMemo(() => {
    setProducts(selectedProducts || [{ company: '', description: '' }])
  }, [selectedProducts])

  const addProduct = () => {
    setProducts([...products, { company: '', description: '' }])
  }

  const handleProductChange = (
    index: number,
    field: keyof Product,
    value: string
  ) => {
    const updatedProducts = [...products]
    updatedProducts[index][field] = value
    setProducts(updatedProducts)
    onChange(updatedProducts)
  }

  const removeProduct = (index: number) => {
    if (products.length === 1) return
    const updatedProducts = [...products]
    updatedProducts.splice(index, 1)
    setProducts(updatedProducts)
    onChange(updatedProducts)
  }

  return (
    <div>
      <Typography variant="h3">
        {title}
        <IconButton onClick={addProduct} color="primary" aria-label="add">
          <PlusCircleFilled />
        </IconButton>
      </Typography>
      <div className="products-col">
        {products.map((product, index) => (
          <div key={index} className="product-row">
            <div className="left">
              <TextField
                label={`Add Company ${index + 1}`}
                value={product.company}
                onChange={(e) =>
                  handleProductChange(index, 'company', e.target.value)
                }
                fullWidth
                variant="outlined"
                InputLabelProps={
                  Boolean(product.company)
                    ? {
                        shrink: true,
                      }
                    : {
                        shrink: false,
                      }
                }
              />
              <TextField
                label={`Add Product Description ${index + 1}`}
                value={product.description}
                onChange={(e) =>
                  handleProductChange(index, 'description', e.target.value)
                }
                fullWidth
                variant="outlined"
                InputLabelProps={
                  Boolean(product.description)
                    ? {
                        shrink: true,
                      }
                    : {
                        shrink: false,
                      }
                }
              />
            </div>
            <div className="right">
              <ButtonBase onClick={() => removeProduct(index)}>
                <Delete />
              </ButtonBase>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
