import * as React from 'react'
import { CortexDecoderProvider, CortexVideo } from 'lib/cortex'

export function SingleScanProvider({
  children,
  canScan = true,
}: {
  children: React.ReactNode
  canScan: boolean
}) {
  const [currentScan, setCurrentScan] = React.useState<
    CortexDecoder.CDResult | undefined
  >()
  const returnOnDisabledScan = () => {
    return
  }
  return (
    <CortexDecoderProvider
      canScan={canScan}
      onScan={canScan ? setCurrentScan : returnOnDisabledScan}
      currentScan={currentScan}
      setCurrentScan={setCurrentScan}
    >
      <CortexVideo />
      {children}
    </CortexDecoderProvider>
  )
}
