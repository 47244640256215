import { DataCard } from 'components/molecules/DataCard'
import { ProceduresThisMonthChart } from '../charts/ProceduresThisMonthChart'
import { SurgeryByMonthObject } from '../utils/getDashboardStats'

interface ProceduresThisMonthCardProps {
  primaryData: string | number
  primaryDataCaption: string
  comparisonStat: string | number
  comparisonStatLabel: string
  surgeriesByMonth: SurgeryByMonthObject[]
}

export const ProceduresThisMonthCard = ({
  primaryData,
  primaryDataCaption,
  comparisonStat,
  comparisonStatLabel,
  surgeriesByMonth,
}: ProceduresThisMonthCardProps) => {
  return (
    <DataCard
      aria-label="procedures-this-month-card"
      primaryData={primaryData}
      primaryDataCaption={primaryDataCaption}
      minimizeChild
      minimizeText
      comparisonStat={comparisonStat}
      comparisonStatLabel={comparisonStatLabel}
    >
      <ProceduresThisMonthChart surgeriesByMonth={surgeriesByMonth} />
    </DataCard>
  )
}
