import * as React from 'react'
import { QueryResult, OperationVariables } from '@apollo/client'
import { isProbablyGS1, isMaybeHIBCC, isMaybeICCBBA } from 'lib/udi'
import { bidSearchResponseToResults } from 'lib/utils/betterid'
import {
  BetterIdLookupQuery,
  BetterIdResult,
  ManualInputFormData,
  NewAsset,
} from 'common/types'
import { useBetterIdLookupQuery } from 'lib/apollo/hooks'

type ScanDataType = 'url' | 'udi' | 'unknown'
type IssuingAgency = 'GS1' | 'HIBCC' | 'ICCBBA' | 'unknown'

interface ScanValueDetails {
  type: ScanDataType
  suspectedIssuer: IssuingAgency
}

export interface CurrentScanDetails {
  type?: ScanDataType
  suspectedIssuer?: IssuingAgency
  isComplete: boolean
  setIsComplete: (isComplete: boolean) => void
  lookupQuery: QueryResult<BetterIdLookupQuery, OperationVariables>
  results?: BetterIdResult[]
  selectedResult?: BetterIdResult | NewAsset | ManualInputFormData
  setSelectedResult: (
    result?: BetterIdResult | NewAsset | ManualInputFormData
  ) => void
  reset: () => void
}

function getScanValueDetails(scanData?: string): ScanValueDetails {
  const ret: ScanValueDetails = {
    type: 'unknown',
    suspectedIssuer: 'unknown',
  }

  if (!scanData) {
    return ret
  }

  try {
    new URL(scanData)
    ret.type = 'url'
    return ret
  } catch (_) {}

  if (isProbablyGS1(scanData)) {
    ret.type = 'udi'
    ret.suspectedIssuer = 'GS1'
  } else if (isMaybeHIBCC(scanData)) {
    ret.type = 'udi'
    ret.suspectedIssuer = 'HIBCC'
  } else if (isMaybeICCBBA(scanData)) {
    ret.type = 'udi'
    ret.suspectedIssuer = 'ICCBBA'
  }

  return ret
}

export function useScanHandler(
  scanResult?: CortexDecoder.CDResult,
  enabled: boolean = true
): CurrentScanDetails {
  const [results, setResults] = React.useState<BetterIdResult[]>()
  const [isComplete, setIsComplete] = React.useState(false)
  const [selectedResult, setSelectedResult] = React.useState<
    BetterIdResult | NewAsset | ManualInputFormData
  >()
  const valueDetails = getScanValueDetails(scanResult?.barcodeData)

  const reset = React.useCallback(() => {
    setIsComplete(false)
    setResults(undefined)
  }, [])

  const lookupQuery = useBetterIdLookupQuery(scanResult?.barcodeData, {
    onCompleted: (data) => {
      setResults(bidSearchResponseToResults(data.betterIdLookup))
      setIsComplete(true)
      return data
    },
    onError: () => {
      setIsComplete(false)
    },
  })

  React.useEffect(() => {
    if (scanResult && valueDetails.type === 'url' && enabled) {
      setIsComplete(true)
    }
  }, [scanResult, valueDetails.type, enabled])

  React.useEffect(() => {
    // If we have a single result, select it
    if (results?.length === 1) {
      setSelectedResult(results[0])
    }
  }, [results])

  return {
    ...valueDetails,
    lookupQuery,
    selectedResult,
    results,
    isComplete,
    setIsComplete,
    setSelectedResult,
    reset,
  }
}
