import {
  Surgery,
  DerivedSurgeryStatus,
  RepApprovalFormData,
} from 'common/types'
export interface AssetListProps {
  surgery: Surgery
  derivedSurgeryStatus: DerivedSurgeryStatus
}

export interface AssetListLogicProps extends AssetListProps {}

export const initialFormData = {} as RepApprovalFormData
