export function isProbablyGS1(value: string): boolean {
  const regexBrackets =
    /(\(\d{1,3}[0-9,a-z,A-Z]{1}?\)[\d,a-z,A-Z]{1,70})|(\+H[A-Z0-9/$]+)|^$/g
  const regexNoBrackets = /01\d{14,}/g // eg 0100888480585220
  return regexBrackets.test(value) || regexNoBrackets.test(value)
}

export function isMaybeHIBCC(value: string): boolean {
  const firstChar = value.charAt(0)
  return firstChar === '*' || firstChar === '+' || firstChar === '$'
}

export function isMaybeICCBBA(value: string): boolean {
  const firstChar = value.charAt(0)
  return firstChar === '=' || firstChar === '&'
}
