/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

// Router
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

// Types
import { ProcessedProduct } from './process-modal/ProcessModal.types'
import { SearchProductsByUdiResult } from 'lib/services/api/product-service/searchProduct/types'
import {
  CreateProductRequestResponse,
  ProductRequest,
  RequestProductVariables,
} from 'lib/services/api/product-service/requestProducts/types'

// Services
import useReceiveItems from 'lib/services/api/product-service/receiveItems'
import useSearchProduct from 'lib/services/api/product-service/searchProduct'
import useRequestProducts from 'lib/services/api/product-service/requestProducts'

// Utils
import { useIsScannerScreenMidSize, useIsTablet } from 'lib/utils/mediaQueries'

// Other
import toast from 'react-hot-toast'
import { useReceiving } from '../receiveContext'

export const useLogic = () => {
  const { id } = useParams()
  const isTablet = useIsTablet()
  const isScannerScreenMidSize = useIsScannerScreenMidSize()
  const { modal: processModalState, setModal } = useReceiving()

  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(0)
  const [scannedItem, setScannedItem] = useState<SearchProductsByUdiResult>()
  const [processedItems, setProcessedItems] = useState<ProcessedProduct[]>([])
  const [scannedCode, setScannedCode] = useState<string | null>(null)
  const [randomKey, setRandomKey] = useState(() =>
    Math.random().toString(36).substring(7)
  )
  const [itemType, setItemType] = useState<'existed' | 'new'>()
  const [productRequestErrors, setProductRequestErrors] = useState<
    CreateProductRequestResponse[]
  >([])
  const [isProcessing, setIsProcessing] = useState(false)

  const totalScannedItemsCount = processedItems.reduce(
    (prev, current) => prev + current.quantity,
    0
  )
  const implantItemsCount = processedItems
    .filter((item) => item.productType === 'Biologic')
    .map((item) => item.quantity)
    .reduce((a, b) => a + b, 0)

  const hardWareItemsCount = processedItems
    .filter((item) => item.productType === 'Hardware')
    .map((item) => item.quantity)
    .reduce((a, b) => a + b, 0)

  const otherItemsCount = processedItems
    .filter((item) => item.productType === 'Other')
    .map((item) => item.quantity)
    .reduce((a, b) => a + b, 0)

  const hasNewItems = processedItems.some((item) => item.type === 'new')

  // Services
  const [receiveItems, { loading: isLoadingReceiveItems }] = useReceiveItems()

  const {
    data: searchProductResult,
    loading: isLoadingSearch,
    error,
  } = useSearchProduct({
    udi: scannedCode || '',
    enabled: !!scannedCode,
  })

  const [requestProducts, { loading: isLoadingRequestProducts }] =
    useRequestProducts()

  // Assuming CortexDecoder is a dependency, ensure it's properly imported
  const startDecoding = async () => {
    await CortexDecoder.CDDecoder.setDecoding(true)
  }

  const stopDecoding = async () => {
    await CortexDecoder.CDDecoder.setDecoding(false)
  }

  const handleProcessScan = () => {
    // Skip scanning if the modal is open
    if (processModalState.isOpen) return
    setScannedCode(null)

    if (searchProductResult?.searchProductsByUdi) {
      setProcessedItems((prevItems) => {
        // Extract UDI and find the index of the existing item, if any
        const existedItemUdi =
          searchProductResult.searchProductsByUdi.term.value
        const foundItemIndex = prevItems.findIndex(
          (item) => item.udi === existedItemUdi
        )

        if (
          searchProductResult.searchProductsByUdi.assets &&
          searchProductResult.searchProductsByUdi.assets.length > 0
        ) {
          // Item exists
          if (foundItemIndex !== -1) {
            // Existing item found, increase quantity by 1
            prevItems[foundItemIndex].quantity += 1
            toast.success(
              `${prevItems[foundItemIndex].description} is incremented!`
            )

            setTimeout(() => {
              startDecoding()
            }, 2000)

            // Create an updated array with the modified item
            const updatedItems = [...prevItems]
            return updatedItems
          } else {
            // Existing item not found in processed items, set it as 'existed'
            setItemType('existed')
            setScannedItem(searchProductResult.searchProductsByUdi)
            setModal({ isOpen: true })
            stopDecoding()
          }
        } else {
          // NEW ITEM LOGIC
          setItemType('new')

          // Find the index of the existing item with the same UDI, if any
          const existingItemIndex = prevItems.findIndex(
            (item) =>
              item.udi === searchProductResult.searchProductsByUdi.term.value
          )

          if (existingItemIndex !== -1) {
            // Existing item found, scanned before, increase quantity by 1
            prevItems[existingItemIndex].quantity += 1
            // Create an updated array with the modified item
            const updatedItems = [...prevItems]
            // Notify user that you incremented the item
            return updatedItems
          } else {
            // Item is new
            setScannedItem(searchProductResult.searchProductsByUdi)
            setModal({ isOpen: true })
          }
        }
        return prevItems
      })
    }
  }

  const handleScan = (code: string) => {
    if (isProcessing) return
    if (code) {
      stopDecoding()
      setIsProcessing(true)
      setScannedCode(code)
    }
  }

  const handleCloseProcessModal = () => {
    setModal({ isOpen: false })
    setRandomKey(() => Math.random().toString(36).substring(7))
    setScannedCode(null)
    startDecoding()
  }

  const handleSave = (data: ProcessedProduct) => {
    setProcessedItems((prev) => [...prev, data])
    setModal({ isOpen: false })
    setRandomKey(() => Math.random().toString(36).substring(7))
    setScannedCode(null)
    startDecoding()
  }

  const handelDeleteItem = (index: number) => {
    setProcessedItems((prev) => {
      const updatedItems = [...prev]
      updatedItems.splice(index, 1)
      return updatedItems
    })
  }

  const handleDeleteItem = (index: number) => {
    setProcessedItems((prev) => {
      const updatedItems = [...prev]
      updatedItems.splice(index, 1)
      return updatedItems
    })
  }

  const handleReceiveItems = async () => {
    const existedItems = processedItems.filter(
      (item) => item.type === 'existed'
    )
    const newItems = processedItems.filter((item) => item.type === 'new')

    if (existedItems.length) {
      try {
        const processedItemsPayload = existedItems.map((item) => {
          const commonItemProps = {
            disposition: item.disposition || 'Consigned',
            quantity: item.quantity,
            type: item.productType || 'Other',
            udi: item.udi || '',
          }

          if (item.productType === 'Biologic') {
            return {
              ...commonItemProps,
              biologicExtraInfo: {
                comment: item.comment,
                manualExpiration: item.expiryDate,
                packageIntegrity: item.packageIntegrity,
                temperature: item.temperature,
              },
            }
          }

          return commonItemProps
        })

        const response = await receiveItems({
          variables: {
            locationId: Number(id),
            items: processedItemsPayload,
          },
        })

        if (response.data?.receiveItems?.message) {
          toast.success('Items stored in inventory')
        }
        setRandomKey(() => Math.random().toString(36).substring(7))
        setProcessedItems((prev) => [
          ...prev.filter((item) => item.type === 'new'),
        ])
      } catch (error) {
        console.error(error)
      }
    }
    if (newItems.length) {
      try {
        const processedItemsPayload: RequestProductVariables = {
          locationId: Number(id),
          productRequests: newItems.map((item) => {
            const commonItemProps: ProductRequest = {
              disposition: item.disposition || 'Consigned',
              quantity: item.quantity,
              type: item.productType || 'Other',
              uniqueIdentifiers: item.udi || '',
              productDescription: item.description || '',
              expirationDate: item.expiryDate || undefined,
              lotBatch: item.lotBatch || '',
              manufacturingDate: item.manufacturingDate || undefined,
              serialNumber: item.serialNumber || '',
              versionModelNumber: item.modelNumber || '',
              companyId: item.companyId || undefined,
            }

            if (item.productType === 'Biologic') {
              return {
                ...commonItemProps,
                biologicExtraInfo: {
                  comment: item.comment || '',
                  manualExpiration: item.expiryDate || undefined,
                  packageIntegrity: item.packageIntegrity || false,
                  temperature: item.temperature || false,
                },
              }
            }

            return commonItemProps
          }),
        }
        const response = await requestProducts({
          variables: processedItemsPayload,
        })

        if (response.errors) {
          toast.error('Something is wrong. ')
        } else {
          setProcessedItems((prev) => [
            ...prev.filter((item) => item.type === 'existed'),
          ])
          toast.success('New items requested successfully.')
        }
        setRandomKey(() => Math.random().toString(36).substring(7))
      } catch (error) {
        console.error(error)
      }
    }
  }

  const playBeep = () => {
    // Error handling for AudioContext creation
    if (typeof AudioContext === 'undefined') return

    const audioCtx = new AudioContext()

    // Create an oscillator node
    const oscillator = audioCtx.createOscillator()
    oscillator.type = 'square' // Sine wave for a beep sound
    oscillator.frequency.setValueAtTime(600, audioCtx.currentTime) // Set frequency (Hz)

    // Create a gain node to control volume
    const gainNode = audioCtx.createGain()
    gainNode.gain.setValueAtTime(0.1, audioCtx.currentTime) // Set volume (0-1)

    // Connect oscillator to gain node and then to the audio context destination
    oscillator.connect(gainNode)
    gainNode.connect(audioCtx.destination)

    // Play the sound for a short duration and then stop
    oscillator.start(audioCtx.currentTime)
    oscillator.stop(audioCtx.currentTime + 0.08) // Beep duration (seconds)
  }

  // Add mock scanner for testing
  useEffect(() => {
    if (window && process.env.REACT_APP_NODE_ENV !== 'production') {
      window.mockScan = (text: string) => {
        handleScan(text)
      }
    }
  }, [handleScan])

  useEffect(() => {
    if (searchProductResult?.searchProductsByUdi) {
      handleProcessScan()
    }
    setScannedCode(null)
  }, [searchProductResult])

  useEffect(() => {
    if (!processedItems.length) {
      setProductRequestErrors([])
    }
  }, [processedItems])

  useEffect(() => {
    // Reset randomKey and scannedCode only when modal is closed to prevent race conditions
    if (!processModalState.isOpen) {
      setRandomKey(() => Math.random().toString(36).substring(7))
      setScannedCode(null)
      startDecoding()
    }
  }, [processModalState.isOpen])

  useEffect(() => {
    if (scannedCode) {
      playBeep()
      return
    }
  }, [scannedCode])

  useEffect(() => {
    if (error) {
      toast.error('An error occurred while searching for the product.')
      setScannedCode(null)
    }
  }, [error])

  return {
    processModalState,
    isLoadingSearch,
    scannedItem,
    processedItems,
    isTablet,
    isScannerScreenMidSize,
    handleScan,
    randomKey,
    isLoadingReceiveItems,
    scannedCode,
    implantItemsCount,
    otherItemsCount,
    totalScannedItemsCount,
    hardWareItemsCount,
    itemType,
    hasNewItems,
    productRequestErrors,
    isLoadingRequestProducts,
    selectedTab,
    handelDeleteItem,
    navigate,
    handleCloseProcessModal,
    handleSave,
    handleReceiveItems,
    handleDeleteItem,
    setSelectedTab,
    playBeep,
  }
}
