const regions = [
  {
    region: 0,
    product: {
      title: 'Undefined',
      subtitle: 'Undefined',
      type: 'Undefined',
      dimensions: 'Undefined',
      deviceID: 'Undefined',
      lotNumber: 'Undefined',
    },
  },
  {
    region: 1,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:4mm',
      deviceID: '37613252250037',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 2,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:5mm',
      deviceID: '37613252250044',
      lotNumber: '(10)1000528831',
    },
  },
  {
    region: 3,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:6mm',
      deviceID: '37613252250051',
      lotNumber: '(10)1000534071',
    },
  },
  {
    region: 4,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:8mm',
      deviceID: '34546540602573',
      lotNumber: '(10)1000493558',
    },
  },
  {
    region: 5,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:10mm',
      deviceID: '34546540602580',
      lotNumber: '(10)1000531197',
    },
  },
  {
    region: 6,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:12mm',
      deviceID: '34546540602597',
      lotNumber: '(10)1000510762',
    },
  },
  {
    region: 7,
    product: {
      title: 'Bone Screws, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:14mm',
      deviceID: '34546540602603',
      lotNumber: '(10)1000530105',
    },
  },
  {
    region: 8,
    product: {
      title: 'Bone Screws, Cross Pin, MP, self-drilling',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:4mm',
      deviceID: '34546540602979',
      lotNumber: '(10)1000529529',
    },
  },
  {
    region: 9,
    product: {
      title: 'Bone Screws, Cross Pin, MP, self-drilling',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:5mm',
      deviceID: '34546540602986',
      lotNumber: '(10)1000536368',
    },
  },
  {
    region: 10,
    product: {
      title: 'Bone Screws, Cross Pin, MP, self-drilling',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2mm,L:6mm',
      deviceID: '34546540602993',
      lotNumber: '(10)1000519506',
    },
  },
  {
    region: 11,
    product: {
      title: 'Bone Screws, emergency, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2.3mm,L:6mm',
      deviceID: '34546540602641',
      lotNumber: '(10)1000520888',
    },
  },
  {
    region: 12,
    product: {
      title: 'Bone Screws, emergency, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2.3mm,L:8mm',
      deviceID: '37613154184768',
      lotNumber: '(10)1000528052',
    },
  },
  {
    region: 13,
    product: {
      title: 'Bone Screws, emergency, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2.3mm,L:10mm',
      deviceID: '37613154184775',
      lotNumber: '(10)1000530385',
    },
  },
  {
    region: 14,
    product: {
      title: 'Bone Screws, emergency, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2.3mm,L:12mm',
      deviceID: '37613154184782',
      lotNumber: '(10)1000531030',
    },
  },
  {
    region: 15,
    product: {
      title: 'Bone Screws, emergency, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2.3mm,L:14mm',
      deviceID: '37613154184775',
      lotNumber: '(10)1000530385',
    },
  },
  {
    region: 16,
    product: {
      title: 'Bone Screws, emergency, MP, Cross Pin',
      subtitle: 'UNIVERSAL 2.0 MP',
      type: 'Medical Implant',
      dimensions: 'Ø2.3mm,L:16mm',
      deviceID: '37613154184805',
      lotNumber: '(10)1000490178',
    },
  },
]

const regionsUpperFace = [
  {
    region: 0,
    product: {
      title: 'Undefined',
      subtitle: 'Undefined',
      type: 'Undefined',
      dimensions: 'Undefined',
      deviceID: 'Undefined',
      lotNumber: 'Undefined',
    },
  },
  {
    region: 1,
    product: {
      title: 'Self-drilling AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 3mm',
      deviceID: '37613327358644',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 2,
    product: {
      title: 'Self-drilling AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 4mm',
      deviceID: '37613327358781',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 3,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 3mm',
      deviceID: '37613327359320',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 4,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 4mm',
      deviceID: '37613327359306',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 5,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 5mm',
      deviceID: '37613327359405',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 6,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 6mm',
      deviceID: '37613327359337',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 7,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 8mm',
      deviceID: '37613327358620',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 8,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 10mm',
      deviceID: '37613327358712',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 9,
    product: {
      title: 'Self-tapping AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.2mm x 12mm',
      deviceID: '37613327358668',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 10,
    product: {
      title: 'Emergency AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.4mm x 3mm',
      deviceID: '37613327358750',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 11,
    product: {
      title: 'Emergency AXS screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.4mm x 5mm',
      deviceID: '37613327358729',
      lotNumber: '(10)1000529311',
    },
  },
]

const regionsMiddleFace = [
  {
    region: 0,
    product: {
      title: 'Undefined',
      subtitle: 'Undefined',
      type: 'Undefined',
      dimensions: 'Undefined',
      deviceID: 'Undefined',
      lotNumber: 'Undefined',
    },
  },
  {
    region: 1,
    product: {
      title: 'Self-drilling screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 3mm',
      deviceID: '37613327359368',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 2,
    product: {
      title: 'Self-drilling screw',
      subtitle: 'LINE_EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 4mm',
      deviceID: '37613327359351',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 3,
    product: {
      title: 'Self-drilling screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 5mm',
      deviceID: '37613327358378',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 4,
    product: {
      title: 'Self-drilling screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 6mm',
      deviceID: '37613327358385',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 5,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 3mm',
      deviceID: '37613327358767',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 6,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 4mm',
      deviceID: '37613327358941',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 7,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 5mm',
      deviceID: '37613327358903',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 8,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 6mm',
      deviceID: '37613327359009',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 9,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 8mm',
      deviceID: '37613327359047',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 10,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 10mm',
      deviceID: '37613327359245',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 11,
    product: {
      title: 'Self-tapping screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.7mm x 12mm',
      deviceID: '37613327359153',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 12,
    product: {
      title: 'Emergency screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.9mm x 3mm',
      deviceID: '37613327358392',
      lotNumber: '(10)1000529311',
    },
  },
  {
    region: 13,
    product: {
      title: 'Emergency screw',
      subtitle: 'LINE EXTENSION',
      type: 'Medical Implant',
      dimensions: '1.9mm x 5mm',
      deviceID: '37613327358446',
      lotNumber: '(10)1000529311',
    },
  },
]

export const matchProduct = (label, column) => {
  if (label.includes('1.2') || label.includes('1.4')) {
    return matchUpperFaceProduct(label, column)
  }

  if (label.includes('1.7') || label.includes('1.9')) {
    return matchMiddleFaceProduct(label, column)
  }

  return matchScrewCaddyProduct(label, column)
}

export const matchScrewCaddyProduct = (label, column) => {
  if (label === '2.0mm Bone Screws (top)') {
    if (column === 4) {
      return regions[1].product
    }

    if (column === 5) {
      return regions[2].product
    }

    if (column === 6) {
      return regions[3].product
    }
  }

  if (label === '2.0mm Bone Screws (bottom)') {
    if (column === 6) {
      return regions[3].product
    }

    if (column === 8) {
      return regions[4].product
    }

    if (column === 10) {
      return regions[5].product
    }

    if (column === 12) {
      return regions[6].product
    }

    if (column === 14) {
      return regions[7].product
    }
  }

  if (label === '2.0mm Self-Drilling Screws (top)') {
    if (column === 4) {
      return regions[8].product
    }

    if (column === 5) {
      return regions[9].product
    }
  }

  if (label === '2.0mm Self-Drilling Screws (bottom)') {
    if (column === 5) {
      return regions[9].product
    }

    if (column === 6) {
      return regions[10].product
    }
  }

  if (label === '2.3mm Emergency (top)') {
    if (column === 6) {
      return regions[11].product
    }

    if (column === 8) {
      return regions[12].product
    }

    if (column === 10) {
      return regions[13].product
    }
  }

  if (label === '2.3mm Emergency (bottom)') {
    if (column === 10) {
      return regions[13].product
    }

    if (column === 12) {
      return regions[14].product
    }

    if (column === 14) {
      return regions[15].product
    }

    if (column === 16) {
      return regions[16].product
    }
  }

  return regions[0].product
}

export const matchUpperFaceProduct = (label, column) => {
  if (label === '1.2mm Self-Drilling Screws') {
    if (column === 3) {
      return regionsUpperFace[1].product
    }

    if (column === 4) {
      return regionsUpperFace[2].product
    }
  }

  if (label === '1.2mm Self-Tapping Screws (top)') {
    if (column === 3) {
      return regionsUpperFace[3].product
    }

    if (column === 4) {
      return regionsUpperFace[4].product
    }

    if (column === 5) {
      return regionsUpperFace[5].product
    }
  }

  if (label === '1.2mm Self-Tapping Screws (bottom)') {
    if (column === 5) {
      return regionsUpperFace[5].product
    }

    if (column === 6) {
      return regionsUpperFace[6].product
    }

    if (column === 8) {
      return regionsUpperFace[7].product
    }

    if (column === 10) {
      return regionsUpperFace[8].product
    }

    if (column === 12) {
      return regionsUpperFace[9].product
    }
  }

  if (label === '1.4mm Emergency (top)') {
    if (column === 3) {
      return regionsUpperFace[10].product
    }
  }

  if (label === '1.4mm Emergency (bottom)') {
    if (column === 5) {
      return regionsUpperFace[11].product
    }
  }

  return regionsUpperFace[0].product
}

export const matchMiddleFaceProduct = (label, column) => {
  if (label === '1.7mm Self-Drilling Screws') {
    if (column === 3) {
      return regionsMiddleFace[1].product
    }

    if (column === 4) {
      return regionsMiddleFace[2].product
    }

    if (column === 5) {
      return regionsMiddleFace[3].product
    }

    if (column === 6) {
      return regionsMiddleFace[4].product
    }
  }

  if (label === '1.7mm Self-Tapping Screws (top)') {
    if (column === 3) {
      return regionsMiddleFace[5].product
    }

    if (column === 4) {
      return regionsMiddleFace[6].product
    }

    if (column === 5) {
      return regionsMiddleFace[7].product
    }
  }

  if (label === '1.7mm Self-Tapping Screws (bottom)') {
    if (column === 5) {
      return regionsMiddleFace[7].product
    }

    if (column === 6) {
      return regionsMiddleFace[8].product
    }

    if (column === 8) {
      return regionsMiddleFace[9].product
    }

    if (column === 10) {
      return regionsMiddleFace[10].product
    }

    if (column === 12) {
      return regionsMiddleFace[11].product
    }
  }

  if (label === '1.9mm Emergency (top)') {
    if (column === 3) {
      return regionsMiddleFace[12].product
    }
  }

  if (label === '1.9mm Emergency (bottom)') {
    if (column === 5) {
      return regionsMiddleFace[13].product
    }
  }

  return regionsMiddleFace[0].product
}
