import { FC } from 'react'

// Components
import { Typography, CircularProgress } from '@mui/material'
import ProductItem from './ProductItem/ProductItem'

// Logic
import useProductRegistrationLogic from './ProductRegistration.logic'

// Types
import { ProductRegistrationProps } from './ProductRegistration.types'

// Style
import './ProductRegistration.scss'

// Other
import classNames from 'classnames'
import { theme } from 'assets/styles/customTheme'

const ProductRegistration: FC<ProductRegistrationProps> = ({ surgery }) => {
  const { products, hasProducts, isLoadingProductsEmails, productsIds, error } =
    useProductRegistrationLogic({
      surgery,
    })

  if (isLoadingProductsEmails)
    return (
      <div className="product-registration-loader">
        <CircularProgress />
      </div>
    )

  if (!hasProducts && !isLoadingProductsEmails && error) {
    return (
      <div className="product-registration-loader">
        <Typography variant="h4" color={theme.palette.error.main}>
          {error.message ||
            'Something went wrong while fetching products emails.'}
        </Typography>
      </div>
    )
  }

  return hasProducts ? (
    <div className={classNames('product-registration-container')}>
      <Typography variant="h3">Register Implantable Record(s)</Typography>
      <Typography variant="body2" className="subtitle">
        Send product details to the vendor
      </Typography>

      <div className="products-container">
        {products
          ?.filter((item) => item.company)
          .map((product, index) => (
            <ProductItem
              productsIds={productsIds}
              key={index}
              product={product}
              surgery={surgery}
              scan={product?.scan}
            />
          ))}
      </div>
    </div>
  ) : null
}

export default ProductRegistration
