import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'

const useConfirmCountdownDurationModal = () => {
  const {
    setCount,
    setChosenCount,
    setDisplayCount,
    setChosenDisplayCount,
    setConfirmDurationModalOpen,
  } = useSPD()

  const handleChangeCount = (e: any) => {
    const number = e.target.value
    setCount(number)
    setChosenCount(number)
    const stringCount = number > 9 ? `00:${number}` : `00:0${number}`
    setDisplayCount(stringCount)
    setChosenDisplayCount(stringCount)
  }

  const resetCount = () => {
    setCount(45)
    setDisplayCount('00:45')
    setConfirmDurationModalOpen(false)
  }

  return {
    handleChangeCount,
    resetCount,
  }
}

export default useConfirmCountdownDurationModal
