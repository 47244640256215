import { Button, CircularProgress, Skeleton, Typography } from '@mui/material'
import {
  ArrowBack,
  AttachFile,
  CameraAlt,
  Close,
  FlipCameraIos,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { LoadingButton } from 'components/mui'

import useInventorySheetsLogic from './InventorySheets.logic'

import './InventorySheets.scss'

const InventorySheets = () => {
  const {
    videoRef,
    isCameraMenuOpen,
    cameras,
    handleToggleCameraMenu,
    activeCamera,
    handleSelectCamera,
    handleImageCapture,
    inventorySheetFiles,
    handleDeleteImg,
    areInventorySheetsEmpty,
    handleFileUpload,
    renderFilePreview,
  } = useInventorySheetsLogic()

  return (
    <div className="inventory-sheets-page_container">
      <div className="images_container">
        <div className="header_wrapper">
          <Button size="small" className="back-button" component={Link} to="..">
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <Typography variant="h1">Inventory Sheet Images</Typography>
        </div>

        {areInventorySheetsEmpty ? (
          <Typography variant="body1">
            Start Capturing Your Inventory Sheets
          </Typography>
        ) : (
          <div className="images_wrapper">
            {inventorySheetFiles.map((file) => (
              <div key={file.id} className="inventory-sheet_img">
                {file.isUploading && (
                  <div className="loading_container">
                    <CircularProgress className="loader" />
                  </div>
                )}

                {renderFilePreview(file)}

                <LoadingButton
                  className="delete_button"
                  onClick={() => handleDeleteImg(file.id)}
                >
                  <Close className="delete_icon" />
                </LoadingButton>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="capture_wrapper">
        <div className="camera_container">
          <Skeleton className="skeleton" />

          <div className="camera-select_container">
            <LoadingButton
              className="camera-menu_button"
              onClick={handleToggleCameraMenu}
            >
              <FlipCameraIos />
            </LoadingButton>

            <div
              className="camera-options"
              style={{
                display: isCameraMenuOpen ? 'flex' : 'none',
                opacity: isCameraMenuOpen ? 1 : 0,
              }}
            >
              {cameras.map((camera) => (
                <div
                  key={camera.deviceId}
                  className={classNames('camera', {
                    active: camera.deviceId === activeCamera?.deviceId,
                  })}
                  onClick={() => handleSelectCamera(camera)}
                >
                  <Typography variant="body2" className="camera-text">
                    {camera.label}
                  </Typography>

                  <Typography variant="body2" className="camera-text">
                    {camera?.cameraType}
                  </Typography>
                </div>
              ))}
            </div>
          </div>

          <LoadingButton
            onClick={() => handleImageCapture(true)}
            className="capture-photo_button"
          >
            <CameraAlt />
          </LoadingButton>

          <video className="feed" ref={videoRef} autoPlay muted playsInline />
        </div>

        <div className="file-attachment_container">
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg, application/pdf, image/tiff, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel"
            onChange={handleFileUpload}
            id="file-upload"
          />

          <label htmlFor="file-upload">
            <LoadingButton component="span" startIcon={<AttachFile />}>
              Attach Inventory Sheet
            </LoadingButton>
          </label>
        </div>
      </div>
    </div>
  )
}

export default InventorySheets
