import { useState } from 'react'

import trayData from './trayData.json'
import { BetterIDTrayScrew } from '../SPDVendor.types'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { matchProduct } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'

const useTrayMap = () => {
  const {
    trayScrews,
    setTrayScrews,
    selectedScrew,
    setSelectedScrew,
    selectedScrews,
    setSelectedScrews,
    setScrewLoadModalOpen,
  } = useSPD()
  const {
    setScannedCode,
    setManualCode,
    productData,
    setProductData,
    setIsAdditionalDeviceCountModalOpen,
    setIsLoadMultipleBoxVisible,
  } = useSPDScan()

  const [confirmChangesModalOpen, setConfirmChangesModalOpen] = useState(false)
  const [isConfirmScrewRemovalModalOpen, setIsConfirmScrewRemovalModalOpen] =
    useState(false)

  const isScrewLoaded = (label: string, row: number, column: number) => {
    return trayScrews.some(
      (screw: any) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }

  const findScrew = (label: string, row: number, column: number) => {
    return trayScrews.find(
      (screw: any) =>
        screw.label === label && screw.row === row && screw.x === column
    )
  }

  const screwAreas = trayData.regions[0].areas

  const handleScrewSelection = (
    label: string,
    rowIndex: number,
    column: number,
    x: number
  ) => {
    const newSelectedScrew = {
      label: label,
      row: rowIndex,
      column: column,
      x,
    }

    // see if screw is already in trayScrews array
    if (
      isScrewLoaded(
        newSelectedScrew.label,
        newSelectedScrew.row,
        newSelectedScrew.x
      )
    ) {
      // remove screw from list
      handleRemoveScrew(newSelectedScrew)
    } else {
      handleStatusChange(newSelectedScrew)
    }
  }

  const handleConfirmLoadMultiple = () => {
    setSelectedScrews([selectedScrew as BetterIDTrayScrew])
    setIsLoadMultipleBoxVisible(true)
    setIsAdditionalDeviceCountModalOpen(false)
    setSelectedScrew(null)
    setScrewLoadModalOpen(false)
  }

  const handleStatusChange = (selectedScrew: BetterIDTrayScrew) => {
    // Update status of selected screw
    const screwData = matchProduct(selectedScrew?.label, selectedScrew?.column)

    const updatedScrews: BetterIDTrayScrew[] = [
      ...trayScrews,
      {
        ...(selectedScrew as BetterIDTrayScrew),
        deviceId: screwData.deviceID,
        deviceDescription: screwData.title,
        company: {
          name: 'Stryker',
          id: 0,
          regEmail: '',
        },
        expirationDate: null,
        wasted: false,
      },
    ]
    setIsAdditionalDeviceCountModalOpen(false)
    setTrayScrews(updatedScrews)
    setScrewLoadModalOpen(false)
    setSelectedScrew(null)
    setProductData([])
  }

  const handleCancelScrewsSelection = () => {
    setSelectedScrews([])
    setIsLoadMultipleBoxVisible(false)
    setProductData([])
    setManualCode('')
    setScannedCode('')
  }

  const handleConfirmScrewsSelection = () => {
    const newSelectedScrews = selectedScrews.map((screw) => ({
      ...(screw as BetterIDTrayScrew),
      deviceId: productData[0].deviceId,
      deviceDescription: productData[0].deviceDescription,
      company: productData[0].company,
      expirationDate: productData[0].expirationDate,
      wasted: false,
    }))

    setTrayScrews((prevTrayScrews) => [...prevTrayScrews, ...newSelectedScrews])
    setSelectedScrew(null)
    setSelectedScrews([])
    setProductData([])
    setScannedCode('')
    setManualCode('')
    setIsLoadMultipleBoxVisible(false)
  }

  const handleCancelSelection = () => {
    setSelectedScrew(null)
    setManualCode('')
    setScannedCode('')
    setProductData([])
    setScrewLoadModalOpen(false)
  }

  const handleConfirmScrewRemovalModalClose = () => {
    setIsConfirmScrewRemovalModalOpen(false)
    setSelectedScrew(null)
  }

  const handleRemoveScrew = (screw: BetterIDTrayScrew) => {
    const newTrayScrews = trayScrews.filter(
      (trayScrew) =>
        !(
          trayScrew.x === screw.x &&
          trayScrew.row === screw.row &&
          trayScrew.label === screw.label
        )
    )

    setTrayScrews(newTrayScrews)
    handleConfirmScrewRemovalModalClose()
  }

  return {
    confirmChangesModalOpen,
    setConfirmChangesModalOpen,
    isConfirmScrewRemovalModalOpen,
    setIsConfirmScrewRemovalModalOpen,
    trayScrews,
    setTrayScrews,
    screwAreas,
    handleScrewSelection,
    handleStatusChange,
    isScrewLoaded,
    handleCancelSelection,
    findScrew,
    handleConfirmScrewRemovalModalClose,
    handleRemoveScrew,
    handleConfirmLoadMultiple,
    handleConfirmScrewsSelection,
    handleCancelScrewsSelection,
  }
}

export default useTrayMap
