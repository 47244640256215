import { MutationTuple, useMutation } from '@apollo/client'
import { UpdateParValueResponse, UpdateParValueVariables } from './types'
import { captureWarning } from 'lib/monitoring'
import { UPDATE_PAR_VALUE } from 'lib/services/api/schema'

type IUpdateParValue = MutationTuple<
  UpdateParValueResponse,
  UpdateParValueVariables
>

const useUpdateParValue = (): IUpdateParValue => {
  return useMutation<UpdateParValueResponse, UpdateParValueVariables>(
    UPDATE_PAR_VALUE,
    {
      onError: (error: Error) => {
        captureWarning(error)
      },
    }
  )
}

export default useUpdateParValue
