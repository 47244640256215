import { useEffect, useState } from 'react'
import { TextInputProps } from './TextInput'
import { isEmailValid } from 'lib/utils/isEmailValid'

export const useTextInputLogic = ({
  value,
  required,
  validateEmail,
}: TextInputProps) => {
  const [isFilled, setIsFilled] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const caption = () => {
    if (required && !isFilled) {
      return 'Required'
    }

    if (
      error &&
      validateEmail &&
      typeof value === 'string' &&
      value.length > 0
    ) {
      return 'Invalid email address'
    }

    if (typeof value === 'number' && value < 0) {
      return 'Value must be greater than 0'
    }
  }

  const validateEmailFunction = (email: string) => {
    return isEmailValid(email) ? null : 'Invalid email address'
  }

  useEffect(() => {
    setIsFilled(
      (typeof value === 'string' && value.length >= 2) ||
        (typeof value === 'number' && value !== 0)
    )
    if (validateEmail && typeof value === 'string' && value.length > 0) {
      setError(validateEmailFunction(value))
    }
    if (typeof value === 'number' && value < 0) {
      setError('Value must be greater than 0')
    }
  }, [value, validateEmail])

  return {
    caption,
  }
}
