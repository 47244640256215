import { useState } from 'react'

// Router
import { useNavigate } from 'react-router-dom'

// MUI
import { SelectChangeEvent } from '@mui/material'

// Utils
import { GS1UDI } from 'lib/utils/udi'
import { manualAssetInputSchema, validateForm } from 'lib/validation'

// Types
import { ManualAddAssetFormProps } from './ManualAddAssetForm.types'
import { GenericObject, ManualInputFormData } from 'common/types'
import { ParsedUDI } from 'lib/services/api/gudid/types'
import { AssetType } from 'common/disposition'

// Other
import dayjs from 'lib/dayjs'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'

export const quickParseGS1ToFormData = (
  data: GS1UDI
): Partial<ManualInputFormData> => {
  const parseDate = (dateString: string): string | undefined => {
    try {
      return dayjs(dateString, 'YYMMDD').toISOString()
    } catch {
      return undefined
    }
  }

  const expirationDate = data['17'] ? parseDate(data['17']) : undefined
  const manufacturingDate = data['11'] ? parseDate(data['11']) : undefined

  return {
    deviceId: data['01'],
    lotBatch: data['10'],
    expirationDate,
    manufacturingDate,
    serialNumber: data['21'],
  }
}

export const parsedUDIToFormData = (
  data: ParsedUDI
): Partial<ManualInputFormData> => ({
  deviceId: data.di ?? undefined,
  lotBatch: data.lotNumber ?? undefined,
  expirationDate: data.expirationDate ?? null,
  manufacturingDate: data.manufacturingDate ?? undefined,
  serialNumber: data.serialNumber ?? undefined,
})

const initialFormData = {
  companyName: '',
  deviceDescription: '',
  deviceId: '',
  count: 1,
  lotBatch: '',
  serialNumber: '',
  catalogNumber: '',
  versionModelNumber: '',
  expirationDate: '',
  manufacturingDate: undefined,
  udi: '',
} as ManualInputFormData

export function useManualAddAssetFormLogic({
  assetCode,
  scanDetails,
}: ManualAddAssetFormProps) {
  const navigate = useNavigate()
  const { setSelectedResult, reset } = scanDetails

  // States
  const [assignedField, setAssignedField] = useState<string>('')
  const [manualAssetInputFormData, setManualAssetInputFormData] =
    useState<ManualInputFormData>(initialFormData)
  const [manualAssetInputFormErrors, setManualAssetInputFormErrors] =
    useState<GenericObject>({})
  const [isExpiredAlert, setIsExpiredAlert] = useState<boolean>()
  const { addUndocumentedScan, resetAddMutation } =
    useUndocumentedAssetsContext()

  // Handlers
  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (typeof isExpiredAlert === 'undefined' && manualAssetInputFormData) {
      if (
        manualAssetInputFormData?.expirationDate &&
        new Date(manualAssetInputFormData.expirationDate) < new Date()
      ) {
        setIsExpiredAlert(true)
        return
      } else {
        setIsExpiredAlert(false)
      }
    }

    if (!isExpiredAlert) {
      handleValidation()
    }
  }

  const handleValidation = () => {
    validateForm({
      formData: manualAssetInputFormData,
      schema: manualAssetInputSchema,
    }).then((errors) => {
      if (Object.keys(errors ?? {}).length > 0) {
        setManualAssetInputFormErrors({ ...errors })
        return
      } else {
        const { isMultipackHardware } = parseAssetIdentifiers({
          deviceDescription: manualAssetInputFormData.deviceDescription,
          deviceCount: manualAssetInputFormData.deviceCount,
          idType: manualAssetInputFormData.secondaryDeviceIdType,
          assetType: manualAssetInputFormData.assetType as AssetType,
        })

        if (
          manualAssetInputFormData.assetType === 'biological' ||
          (manualAssetInputFormData.assetType === 'non-biological' &&
            isMultipackHardware)
        ) {
          setSelectedResult(manualAssetInputFormData)
          navigate(`../result`)
        } else {
          const addPromises = []
          // Loop over the quantity and create a promise for each item
          for (let i = 0; i < manualAssetInputFormData.count; i++) {
            const addPromise = addUndocumentedScan({
              variables: {
                data: {
                  bidAssetId: manualAssetInputFormData.bidAssetId,
                  bidCompanyId: manualAssetInputFormData.bidCompanyId,
                  catalogNumber: manualAssetInputFormData.catalogNumber,
                  deviceDescription: manualAssetInputFormData.deviceDescription,
                  deviceId: manualAssetInputFormData.deviceId,
                  deviceCount: 1,
                  count: 1,
                  pkgQuantity: 1,
                  expirationDate: manualAssetInputFormData.expirationDate
                    ? dayjs(
                        manualAssetInputFormData.expirationDate
                      ).toISOString()
                    : undefined,
                  lotBatch: manualAssetInputFormData.lotBatch || undefined,
                  companyName: manualAssetInputFormData.companyName,
                  manufacturingDate: manualAssetInputFormData.manufacturingDate,
                  versionModelNumber:
                    manualAssetInputFormData.versionModelNumber,
                  serialNumber:
                    manualAssetInputFormData.serialNumber || undefined,
                  udi: manualAssetInputFormData.udi || undefined,
                  assetType: manualAssetInputFormData.assetType,
                  sizeText: manualAssetInputFormData.sizeText,
                  sizeString: manualAssetInputFormData.sizeString,
                  issuingAgency: manualAssetInputFormData.issuingAgency,
                  secondaryDeviceIdType:
                    manualAssetInputFormData.secondaryDeviceIdType,
                  gmdnPTDefinition:
                    manualAssetInputFormData.gmdnPTDefinition || null,
                  cost: manualAssetInputFormData.cost,
                  chargeable: manualAssetInputFormData.chargeable,
                  isManualAddition: true,
                },
              },
            })

            addPromises.push(addPromise)
          }

          // Wait for all promises to resolve
          Promise.all(addPromises)
            .then(() => {
              resetAddMutation()
              navigate('..')
            })
            .catch((error) => {
              console.error('Error adding undocumented scans:', error)
              // Handle error appropriately
            })
        }
      }
    })
  }

  const resetScanner = () => {
    reset()
    setSelectedResult(undefined)
    setIsExpiredAlert(undefined)
  }

  const handleAssignCode = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    setAssignedField(value)
    setManualAssetInputFormData((prev) => ({
      ...prev,
      lotBatch:
        assetCode === manualAssetInputFormData.lotBatch
          ? ''
          : manualAssetInputFormData.lotBatch,
      serialNumber:
        assetCode === manualAssetInputFormData.serialNumber
          ? ''
          : manualAssetInputFormData.serialNumber,
      catalogNumber:
        assetCode === manualAssetInputFormData.catalogNumber
          ? ''
          : manualAssetInputFormData.catalogNumber,
      versionModelNumber:
        assetCode === manualAssetInputFormData.versionModelNumber
          ? ''
          : manualAssetInputFormData.versionModelNumber,
      [value]: assetCode,
    }))
  }

  const handleClearForm = () => {
    setManualAssetInputFormData(initialFormData)
  }

  const handleChangeFormData = (e: any) => {
    setManualAssetInputFormData({
      ...manualAssetInputFormData,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeFormErrorField = (inputName: string, value: boolean) => {
    setManualAssetInputFormErrors({
      ...manualAssetInputFormErrors,
      [inputName]: value,
    })
  }

  return {
    assetFormData: manualAssetInputFormData,
    isExpiredAlert,
    assignedField,
    manualAssetInputFormErrors,
    handleSubmit,
    resetScanner,
    handleClearForm,
    handleAssignCode,
    handleValidation,
    setIsExpiredAlert,
    handleChangeFormData,
    handleChangeFormErrorField,
    setManualAssetInputFormData,
  }
}
