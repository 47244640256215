import dayjs from 'lib/dayjs'
import { createContext, useContext, useMemo, useState } from 'react'
import {
  DefaultFilterParams,
  ReportFiltersContextProps,
} from './ReportFiltersContext.types'

export const initialBefore = dayjs(dayjs().endOf('day').toDate())
export const initialAfter = dayjs().startOf('day').subtract(1, 'day')

const ReportFiltersContext = createContext<ReportFiltersContextProps>({
  filters: {
    open: false,
  },
  setFilters: () => {},
  beforeDate: initialBefore,
  afterDate: initialAfter,
  setBeforeDate: () => {},
  setAfterDate: () => {},
  reportData: [],
  setReportData: () => {},
})

interface ReportFiltersProviderProps {
  children: React.ReactNode
}

export const ReportFiltersProvider = ({
  children,
}: ReportFiltersProviderProps) => {
  const [beforeDate, setBeforeDate] = useState<dayjs.Dayjs>(initialBefore)
  const [afterDate, setAfterDate] = useState<dayjs.Dayjs>(initialAfter)

  const [filters, setFilters] = useState<DefaultFilterParams>({
    open: false,
  })

  const [reportData, setReportData] = useState<
    ReportFiltersContextProps['reportData']
  >([])

  const value = useMemo(() => {
    return {
      filters,
      setFilters,
      beforeDate,
      afterDate,
      setBeforeDate,
      setAfterDate,
      reportData,
      setReportData,
    }
  }, [afterDate, beforeDate, filters, reportData])

  return (
    <ReportFiltersContext.Provider value={value}>
      {children}
    </ReportFiltersContext.Provider>
  )
}

export function useReportFiltersContext() {
  const context = useContext(ReportFiltersContext)
  if (context === undefined) {
    throw new Error(
      'useReportFiltersContext must be used with ReportFiltersProvider'
    )
  }
  return context
}
