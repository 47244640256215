import { CircularProgress, TextField, Typography } from '@mui/material'

import './TrayCaseDetails.scss'
import useTrayCaseDetailsLogic from './TrayCaseDetails.logic'
import SurgeryBox from 'components/molecules/SurgeryBox/SurgeryBox'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import Select from 'components/molecules/Select/Select'

const TrayCaseDetails = () => {
  const {
    filteredSurgeries,
    isNurse,
    caseInformation,
    loading,
    afterDate,
    beforeDate,
    handleAfterDateChange,
    handleBeforeDateChange,
    surgeonOptions,
    surgeonFilter,
    handleSurgeonFilterChange,
    handleClearSurgeonFilter,
    handleTraySurgeryChange,
    isSurgerySelected,
  } = useTrayCaseDetailsLogic()

  return (
    <div className="tray-case-details-organism_container">
      <Typography variant="h1">Case Details</Typography>

      <div className="filters_container">
        <div className="row">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="After"
              value={afterDate}
              onChange={(date) => handleAfterDateChange(date as Date)}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Before"
              value={beforeDate}
              onChange={(date) => handleBeforeDateChange(date as Date)}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>

        <Select
          fullWidth
          label="Filter by Surgeon"
          options={surgeonOptions}
          onClear={handleClearSurgeonFilter}
          onSelect={handleSurgeonFilterChange}
          isLoading={loading}
          onSearch={() => {}}
          selectedValue={surgeonFilter}
        />
      </div>

      {loading && <CircularProgress className="loader" />}

      <div className="surgery-list_wrapper">
        {filteredSurgeries.map((surgery) => (
          <SurgeryBox
            key={surgery._id}
            surgery={surgery}
            onClick={() => handleTraySurgeryChange(surgery)}
            isSelected={isSurgerySelected(surgery._id)}
            isNurse={isNurse}
            caseInformation={caseInformation}
          />
        ))}
      </div>
    </div>
  )
}

export default TrayCaseDetails
