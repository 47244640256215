import React from 'react'

// Components
import Modal from 'components/molecules/Modal/Modal'
import Button from 'components/molecules/Button/Button'

// Style
import './PreviewModal.scss'

// Types
import { MediaUploadModalModalProps } from './PreviewModal.types'

const PreviewModal: React.FC<MediaUploadModalModalProps> = ({
  src,
  isOpen,
  onClose,
}) => {
  if (!src) return null
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="image-preview-modal-container">
        <img src={src} alt="preview" className="image-preview" />

        <Button fullWidth variant="outlined" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default PreviewModal
