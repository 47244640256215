import { createContext, useContext, useEffect, useState } from 'react'

import { useGetAllTrayItems } from 'lib/apollo/hooks'

import { ItemResponse } from 'common/types'
import { TrayConfigurationContextProps } from './TrayConfiguration.types'
import { TrayType } from 'components/organisms/AddTraySelectType/AddTraySelectType.types'
import { SPDContextProps } from 'views/SPDLayout/SPD/SPD.types'

const TrayConfigurationContext = createContext<
  TrayConfigurationContextProps | undefined
>(undefined)

export const TrayConfigurationProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const { trayItems, loading, error } = useGetAllTrayItems()

  const [trays, setTrays] = useState<ItemResponse[]>([])
  const [selectedTrayCategory, setSelectedTrayCategory] = useState<
    TrayType['category'] | null
  >(null)
  const [selectedTray, setSelectedTray] = useState<
    SPDContextProps['trayData'] | null
  >(null)

  useEffect(() => {
    if (!loading && !error) {
      setTrays(trayItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <TrayConfigurationContext.Provider
      value={{
        trays,
        setTrays,
        loadingTrays: loading,
        error,
        selectedTrayCategory,
        setSelectedTrayCategory,
        selectedTray,
        setSelectedTray,
      }}
    >
      {children}
    </TrayConfigurationContext.Provider>
  )
}

export const useTrayConfigurationContext = () => {
  const context = useContext(TrayConfigurationContext)

  if (context === undefined) {
    throw new Error(
      'useTrayConfigurationContext must be used with TrayConfigurationProvider'
    )
  }

  return context
}
