import { useState } from 'react'

const useTrayAnalysisContentLogic = () => {
  const [shouldShowGuide, setShouldShowGuide] = useState<boolean>(false)

  const handleGuideClick = () => {
    setShouldShowGuide((prev) => !prev)
  }

  return {
    shouldShowGuide,
    setShouldShowGuide,
    handleGuideClick,
  }
}

export default useTrayAnalysisContentLogic
