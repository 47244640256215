import {
  CortexDecoderProvider,
  CortexReceivingVideo,
  CortexVideo,
} from 'lib/ReceivingCortex'
import { useIsScannerScreenMidSize } from 'lib/utils/mediaQueries'
import { useRef, useState, useEffect, useCallback } from 'react'
import { ReceivingSingleScanProviderProps } from './ReceivingSingleScanProvider.types'

export function ReceivingSingleScanProvider({
  onScan,
  canScan = true,
}: ReceivingSingleScanProviderProps) {
  const isScannerScreenMidSize = useIsScannerScreenMidSize()
  const isMounted = useRef(true)
  const [currentScan, setCurrentScan] = useState<
    CortexDecoder.CDResult | undefined
  >()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleScan = useCallback(
    (newScan: CortexDecoder.CDResult) => {
      if (isMounted.current) {
        setCurrentScan(newScan)
        onScan && onScan(newScan.barcodeData)
      } else if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.warn('Read a barcode but component was not mounted')
      }
    },
    [onScan]
  )

  return (
    <CortexDecoderProvider
      hasROI={isScannerScreenMidSize}
      canScan={canScan}
      onScan={handleScan}
      currentScan={currentScan}
      setCurrentScan={setCurrentScan}
    >
      {isScannerScreenMidSize ? <CortexVideo /> : <CortexReceivingVideo />}
    </CortexDecoderProvider>
  )
}
