// ReceivingContext.tsx
import React, { createContext, useState, useContext } from 'react'

interface ReceivingContextType {
  modal: {
    isOpen: boolean
    data?: any
  }
  setModal: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; data?: any }>
  >
}

const ReceivingContext = createContext<ReceivingContextType | undefined>(
  undefined
)

export const ReceivingProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<{ isOpen: boolean; data?: any }>({
    isOpen: false,
    data: {},
  })

  return (
    <ReceivingContext.Provider value={{ modal, setModal }}>
      {children}
    </ReceivingContext.Provider>
  )
}

export const useReceiving = (): ReceivingContextType => {
  const context = useContext(ReceivingContext)
  if (!context) {
    throw new Error('useReceiving must be used within a ReceivingProvider')
  }
  return context
}
