import './StaticSkeleton.css'

const StaticSkeleton = () => (
  <div className="static-skeleton">
    <div className="long-space" />
    <div className="bottom">
      <div className="short-space" />
      <div className="short-space" />
    </div>
  </div>
)

export default StaticSkeleton
