import { useMemo, useContext } from 'react'
import { useAssetGroupsCounts } from 'lib/apollo/hooks'
import { getGroupedAssetData } from 'lib/utils/grouped-asset-data'
import { getSpongeAssetGroups } from 'lib/utils/GetSpongeAssetGroups/getSpongeAssetGroups'
import {
  RfSpongeCountContext,
  RfSpongeCountProviderProps,
  RfSpongeCountProviderValue,
} from './RfSpongeCountContext.types'
import { useRfSpongeCountContextLogic } from './RfSpongeCountContextLogic'

export function RfSpongeCountProvider({
  children,
  surgery,
}: RfSpongeCountProviderProps) {
  const {
    useValidSpongeAssetGroups,
    useRfSpongeAssetGroupCount,
    useAddAssetGroupsCountEffect,
    useProviderValue,
  } = useRfSpongeCountContextLogic()

  const groupedAssetData = useMemo(
    () => getGroupedAssetData(surgery?.assetGroups),
    [surgery?.assetGroups]
  )
  const spongeAssetGroups = useMemo(
    () => getSpongeAssetGroups(groupedAssetData),
    [groupedAssetData]
  )

  const validSpongeAssetGroups = useValidSpongeAssetGroups(spongeAssetGroups)
  const [rfSpongeAssetGroupCount, setRfSpongeAssetGroupCount] =
    useRfSpongeAssetGroupCount(validSpongeAssetGroups)

  const [addAssetGroupsCount, addAssetGroupsCountMutation] =
    useAssetGroupsCounts(surgery._id, rfSpongeAssetGroupCount)

  useAddAssetGroupsCountEffect(rfSpongeAssetGroupCount, addAssetGroupsCount)

  const providerValueArgs: RfSpongeCountProviderValue = {
    rfSpongeAssetGroupCount,
    setRfSpongeAssetGroupCount,
    spongeAssetGroups: validSpongeAssetGroups,
    addAssetGroupsCountMutation,
    addAssetGroupsCount,
  }

  const value = useProviderValue(providerValueArgs)

  return (
    <RfSpongeCountContext.Provider value={value}>
      {children}
    </RfSpongeCountContext.Provider>
  )
}

export function useRfSpongeCount() {
  const context = useContext(RfSpongeCountContext)
  if (context === undefined) {
    throw new Error(
      'useRfSpongeCount must be used within a RfSpongeCountContextProvider'
    )
  }
  return context
}
