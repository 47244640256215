import { z } from 'zod'

export type DispositionData = z.infer<typeof dispositionSchema>
type Disposition = (typeof DISPOSITIONS)[number]
type DispositionList = {
  value: Disposition
  label: string
}[]
export type AssetType = (typeof ASSET_TYPES)[number]

export const ASSET_TYPES = [
  'non-biological',
  'biological',
  'other-non-biological',
  'consumable',
] as const

export const ASSET_TYPE_LIST = [
  { value: ASSET_TYPES[0], label: 'Implantable Hardware/Associated Asset' },
  // { value: ASSET_TYPES[1], label: 'Implantable Biological' },
  { value: ASSET_TYPES[2], label: 'Implantable Other' },
  { value: ASSET_TYPES[3], label: 'Consumable' },
]

export type Dispositions = (typeof DISPOSITIONS)[number]
export const DISPOSITIONS = [
  'IMPLANTED',
  'WASTED',
  'EXPLANTED',
  'ASSOCIATED_ASSET',
] as const

const DISPOSITION_LIST: DispositionList = [
  { value: DISPOSITIONS[0], label: 'Implanted' },
  { value: DISPOSITIONS[1], label: 'Wasted' },
  { value: DISPOSITIONS[2], label: 'Explanted' },
]

export const WASTED_REASONS = [
  'defective',
  'contaminated',
  'incorrectsize',
  'temporary-in-out',
  'other',
] as const

export const EXPLANTED_REASONS = [
  'SCHEDULED',
  'INFECTION',
  'FAILED_DEVICE',
  'RECALL',
  'OTHER',
] as const

export const WASTED_REASON_LIST = [
  { value: WASTED_REASONS[0], label: 'Defective' },
  { value: WASTED_REASONS[1], label: 'Contaminated' },
  { value: WASTED_REASONS[2], label: 'Incorrect Size' },
  { value: WASTED_REASONS[3], label: 'Temporary In/Out' },
  { value: WASTED_REASONS[4], label: 'Other' },
]

export const EXPLANTED_REASON_LIST = [
  { value: EXPLANTED_REASONS[0], label: 'Scheduled' },
  { value: EXPLANTED_REASONS[1], label: 'Infection' },
  { value: EXPLANTED_REASONS[2], label: 'Failed Device' },
  { value: EXPLANTED_REASONS[3], label: 'Recall' },
  { value: EXPLANTED_REASONS[4], label: 'Other' },
]

export const dispositionSchema = z.object({
  assetTray: z.string().optional(),
  assetType: z.enum(ASSET_TYPES),
  implantStatus: z.enum(DISPOSITIONS).optional(),
  implantSite: z.string().optional(),
  siteLocation: z.string().optional().nullish(),
  wastedReason: z.enum(WASTED_REASONS).optional(),
  explantedReason: z.enum(EXPLANTED_REASONS).optional().nullish(),
  explantedReasonNote: z.string().optional().nullish(),
  count: z
    .union([z.string(), z.number()])
    .optional()
    .transform((val) => (typeof val === 'string' ? Number(val) : val)),
})

export const getDispositionList = ({
  assetType,
  isNurseUser,
}: {
  isNurseUser?: boolean
  assetType?: AssetType
}): DispositionList => {
  const list = [...DISPOSITION_LIST]
  if (assetType === 'non-biological') {
    list.splice(1, 0, {
      value: 'ASSOCIATED_ASSET',
      label: 'Utilized associated product',
    })
  }
  if (isNurseUser) {
    list.push({
      value: 'EXPLANTED',
      label: 'Explanted',
    })
  }
  return list
}
