import { Cancel, CheckCircle, GppMaybe, ThumbDown } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

const renderGuideline = (status: React.ReactNode, label: string) => (
  <Stack
    width="95%"
    direction="row"
    alignItems={{ xs: 'flex-start', md: 'center' }}
    justifyContent="flex-start"
    alignSelf="center"
    mb={2}
  >
    - {status}:
    <Typography fontSize={15} color="#11142D" fontWeight={400} ml={1}>
      {label}
    </Typography>
  </Stack>
)

const TableGuide = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      mb: 3,
    }}
  >
    <Typography fontSize={25} color="#11142D" fontWeight={700} mb={2}>
      Guide:
    </Typography>

    {renderGuideline(
      <Typography
        fontSize={12}
        fontWeight={700}
        sx={{
          borderRadius: '15px',
          p: '3px 1px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '83px',
          borderWidth: '1px',
          borderStyle: 'solid',
          bgcolor: '#E4FFF9',
          color: '#07B88D',
          borderColor: '#07B88D',
          ml: 1,
          mr: 0.3,
        }}
      >
        <CheckCircle
          sx={{
            width: '13px',
            height: '13px',
            mr: 1,
          }}
        />{' '}
        correct
      </Typography>,
      'The computer vision analysis successfully identified a screw that is approved by the user.'
    )}

    {renderGuideline(
      <Typography
        fontSize={12}
        fontWeight={700}
        sx={{
          borderRadius: '15px',
          p: '3px 1px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '83px',
          borderWidth: '1px',
          borderStyle: 'solid',
          bgcolor: '#FFF7AB',
          color: '#AA8100',
          borderColor: '#AA8100',
          ml: 1,
          mr: 0.3,
        }}
      >
        <ThumbDown
          sx={{
            width: '13px',
            height: '13px',
            mr: 1,
          }}
        />{' '}
        incorrect
      </Typography>,
      'The computer vision analysis added a screw that was not approved by the user.'
    )}

    {renderGuideline(
      <Typography
        fontSize={12}
        fontWeight={700}
        sx={{
          borderRadius: '15px',
          p: '3px 1px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '83px',
          borderWidth: '1px',
          borderStyle: 'solid',
          bgcolor: '#FFE8E8',
          color: '#FF0000',
          borderColor: '#FF0000',
          ml: 1,
          mr: 0.3,
        }}
      >
        <Cancel
          sx={{
            width: '13px',
            height: '13px',
            mr: 1,
          }}
        />{' '}
        missing
      </Typography>,
      'The computer vision analysis failed to identify a screw that was confirmed by the user.'
    )}

    {renderGuideline(
      <Typography
        fontSize={12}
        fontWeight={700}
        sx={{
          borderRadius: '15px',
          p: '3px 1px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '83px',
          borderWidth: '1px',
          borderStyle: 'solid',
          bgcolor: '#FFE9D5',
          color: '#F57200',
          borderColor: '#F57200',
          ml: 1,
          mr: 0.3,
        }}
      >
        <GppMaybe
          sx={{
            width: '13px',
            height: '13px',
            mr: 1,
          }}
        />{' '}
        unverified
      </Typography>,
      "The computer vision analysis identified a screw but it's accuracy has not been confirmed by the user."
    )}
  </Box>
)

export default TableGuide
