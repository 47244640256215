import { FC, useState } from 'react'
import { Modal, Tab, Tabs } from '@mui/material'
import {
  BodyContainer,
  CloseButton,
  HeaderContainer,
  ModalContainer,
  StyledCloseIcon,
  TabPanel,
} from './styles'
import { AddRepModalProps, TabValue } from './types'
import RepSignupForm from './RepSignupForm'
import InviteRep from './InviteRep'

const AddRepModal: FC<AddRepModalProps> = ({ isOpen, onClose, surgeryId }) => {
  const [value, setValue] = useState<TabValue>(TabValue.InviteRep)
  const handleChange = (_: React.SyntheticEvent, newValue: TabValue) => {
    setValue(newValue)
  }

  const handleClose = () => {
    setValue(TabValue.InviteRep)
    onClose()
  }

  return (
    <Modal open={isOpen}>
      <ModalContainer>
        <HeaderContainer>
          <CloseButton onClick={handleClose}>
            <StyledCloseIcon />
          </CloseButton>
          <Tabs value={value} onChange={handleChange}>
            <Tab value={TabValue.InviteRep} label="Invite Rep" />
            <Tab value={TabValue.SignUpRep} label="Signup Rep" />
          </Tabs>
        </HeaderContainer>
        <BodyContainer>
          <TabPanel $isHidden={value !== TabValue.SignUpRep}>
            <RepSignupForm surgeryId={surgeryId} onClose={handleClose} />
          </TabPanel>
          <TabPanel $isHidden={value !== TabValue.InviteRep}>
            <InviteRep surgeryId={surgeryId} />
          </TabPanel>
        </BodyContainer>
      </ModalContainer>
    </Modal>
  )
}

export default AddRepModal
