import { useEffect } from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CheckCircle, RestartAlt } from '@mui/icons-material'

import TrayProduct from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/TrayProduct'
import useEditTrayModal from 'components/organisms/EditTrayModal/EditTrayModal.logic'
import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'

import { BetterIDTrayScrew } from '../../../views/SPDLayout/SPD/SPD.types'
import TraySVG from 'assets/icons/svgs/Tray.svg'
import { ItemResponse } from 'common/types'
import './SelectedTrayModal.scss'

interface SelectedTrayModalProps {
  open: boolean
  onClose: () => void
}

const SelectedTrayModal = ({ open, onClose }: SelectedTrayModalProps) => {
  const navigate = useNavigate()
  const {
    trayData,
    setTrayData,
    trayScrews,
    setTrayScrews,
    setSelectedScrew,
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarState,
    setSnackbarIcon,
  } = useSPD()
  const { setProductData, setIsDrawerOpen } = useSPDScan()
  const { handleUpdateTrayItem, updating, success, setSuccess } =
    useEditTrayModal({
      trayData: trayData?.trayItem as ItemResponse,
      trayScrews,
    })

  const previousScrews = trayData?.trayItem?.productDetails
    ? (JSON.parse(trayData?.trayItem?.productDetails as string)
        .screws as BetterIDTrayScrew[])
    : []

  const newScrews = trayScrews.filter(
    (trayScrew) =>
      !previousScrews.some(
        (previousScrew) =>
          trayScrew.column === previousScrew.column &&
          trayScrew.x === previousScrew.x &&
          trayScrew.row === previousScrew.row &&
          trayScrew.label === previousScrew.label &&
          trayScrew.deviceId === previousScrew.deviceId
      )
  )

  const handleReset = () => {
    const hasUserConfirmedReset = window.confirm(
      `Are you sure you want to reset the tray?\nThis will delete all tray data and reset the tray to its initial state.`
    )

    if (hasUserConfirmedReset) {
      setTrayScrews([])
      setSelectedScrew(null)
      setTrayData(null)
      setProductData([])
      onClose()
    }
  }

  useEffect(() => {
    if (success) {
      setSuccess(false)
      setSnackbarMessage('Successfully saved changes')
      setSnackbarState('success')
      setSnackbarIcon(<CheckCircle />)
      setSnackbarOpen(true)
      setSelectedScrew(null)
      setIsDrawerOpen(false)
      setProductData([])
      navigate('/spd')
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <Modal open={open} onClose={onClose}>
      <div className="selected-tray-modal-container">
        <div className="header-box">
          <img src={TraySVG} alt="tray" />

          <Typography variant="h3">Tray Details</Typography>
        </div>

        {trayData && (
          <div className="product-container">
            <Typography variant="body2">
              <b>Tray UDI:</b> {trayData.trayItem.barcode}
            </Typography>
            <Typography variant="body2">
              <b>Description:</b> {trayData.trayItem.description}
            </Typography>
          </div>
        )}

        <div className="screws_container">
          {newScrews.length === 0 ? (
            <Typography variant="body1">
              No screws were newly added to tray
            </Typography>
          ) : (
            newScrews.map((screw: BetterIDTrayScrew, _index: number) => (
              <TrayProduct
                key={`${screw.deviceId}${_index}`}
                {...screw}
                showDispositionStatus={false}
              />
            ))
          )}
        </div>

        <div className="buttons-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            customIcon={<RestartAlt />}
            onClick={handleReset}
          >
            Reset Tray
          </LoadingButton>

          <LoadingButton
            variant="contained"
            disabled={updating}
            loading={updating}
            onClick={handleUpdateTrayItem}
          >
            Save Changes
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default SelectedTrayModal
