import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItemText } from '@mui/material'
import { useState } from 'react'
import { TreeLocation } from 'lib/services/api/product-service/getLocationsTree/types'
import './LocationHierarchy.scss'

export const Location = ({
  location,
  onSelect,
  isOpen,
}: {
  location: TreeLocation
  onSelect: (location: TreeLocation) => void
  isOpen: boolean
  handleToggle: () => void
}) => {
  const isParent = !location.parentLocationId

  const handleSelect = () => {
    onSelect(location)
  }

  return (
    <div
      onClick={handleSelect}
      className={`location-item ${isParent ? 'is-parent' : ''}`}
    >
      {isParent && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      <ListItemText>{location.name}</ListItemText>
    </div>
  )
}

const LocationHierarchy = ({
  locations,
  onSelect,
}: {
  locations?: TreeLocation[]
  onSelect: (item: TreeLocation) => void
}) => {
  const [open, setOpen] = useState<number[]>([])

  const handleClick = (location: TreeLocation) => {
    if (location.parentLocationId) {
      onSelect(location)
    }
    setOpen((prevOpen) => {
      const index = prevOpen.indexOf(location?.id)
      if (index === -1) {
        return [...prevOpen, location.id]
      } else {
        return [...prevOpen.slice(0, index), ...prevOpen.slice(index + 1)]
      }
    })
  }

  const renderNestedLocations = (parentId: number, depth: number) => {
    if (!locations) return null

    const nestedLocations = locations.filter(
      (loc) => loc.parentLocationId === parentId
    )

    if (nestedLocations.length === 0) return null

    return (
      <List disablePadding>
        {nestedLocations.map((location) => (
          <div key={location.id} style={{ marginLeft: depth * 16 }}>
            <Location
              location={location}
              onSelect={() => handleClick(location)}
              isOpen={open.includes(location.id)}
              handleToggle={() => handleClick(location)}
            />
            <Collapse
              in={open.includes(location.id)}
              timeout="auto"
              unmountOnExit
            >
              {renderNestedLocations(location.id, depth + 1)}
            </Collapse>
          </div>
        ))}
      </List>
    )
  }

  return (
    <List>
      {locations &&
        locations
          .filter((location) => location.parentLocationId === null)
          .map((rootLocation) => (
            <div key={rootLocation.id}>
              <Location
                location={rootLocation}
                onSelect={() => handleClick(rootLocation)}
                isOpen={open.includes(rootLocation.id)}
                handleToggle={() => handleClick(rootLocation)}
              />
              <Collapse
                in={open.includes(rootLocation.id)}
                timeout="auto"
                unmountOnExit
              >
                {renderNestedLocations(rootLocation.id, 1)}
              </Collapse>
            </div>
          ))}
      {!locations && <p>No locations available.</p>}{' '}
    </List>
  )
}

export default LocationHierarchy
