import { Box, Button, Typography } from '@mui/material'
import { BetterIdResult } from 'common/types'

interface BetterIdResultItemProps {
  variant?: 'shadow' | 'border'
  result: BetterIdResult
  index?: number
  onSelect: (result: BetterIdResult) => void
}

interface DisplayField {
  label: string
  value: keyof BetterIdResult
}

const displayFields: DisplayField[] = [
  {
    label: 'Device Identifier',
    value: 'deviceId',
  },
  {
    label: 'Catalog Number',
    value: 'catalogNumber',
  },
  {
    label: 'Package Quantity',
    value: 'pkgQuantity',
  },
  {
    label: 'Size',
    value: 'sizeString',
  },
]

export function BetterIdResultItem({
  variant = 'shadow',
  result,
  index,
  onSelect,
}: BetterIdResultItemProps) {
  const createSelectHandler = (result: BetterIdResult) => () => {
    onSelect(result)
  }

  return (
    <Box
      key={result.id}
      sx={{
        p: 2,
        background: 'white',
        borderRadius: 2,
        mb: 2,
        boxShadow:
          variant === 'shadow' ? '0 1px 4px rgba(0, 0, 0, 0.05)' : 'none',
        border: variant === 'border' ? '1px solid #ddd' : 'none',
      }}
    >
      <Typography variant="h5">{result.deviceDescription}</Typography>
      <Box mt={1.5} display="flex" flexWrap="wrap" columnGap={2}>
        {displayFields.map((field) =>
          result[field.value] ? (
            <Box
              key={field.value}
              display="flex"
              alignItems="center"
              columnGap={0.5}
              mb={0.25}
            >
              <Typography variant="body2" color="#666" fontSize={13}>
                {field.label}:
              </Typography>
              <Typography variant="body2" color="#333" fontSize={13}>
                {result[field.value]}
              </Typography>
            </Box>
          ) : null
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Typography variant="body2">{result.company?.name}</Typography>
        <Button
          data-testid="select-asset-button"
          sx={{ mt: 0.5 }}
          tabIndex={index ? index + 1 : undefined}
          size="small"
          variant={variant === 'shadow' ? 'outlined' : 'text'}
          onClick={createSelectHandler(result)}
        >
          Select Product
        </Button>
      </Box>
    </Box>
  )
}
