/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

const useCaptureCameraLogic = ({
  onCapture,
}: {
  onCapture: (file: File) => void
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [isStreaming, setIsStreaming] = useState<boolean>(false)
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])
  const [activeCamera, setActiveCamera] = useState<MediaDeviceInfo | null>(null)

  const fetchCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const cameras = devices.filter((device) => device.kind === 'videoinput')
      setCameras(cameras)
      setActiveCamera(cameras[0])
    } catch (error) {
      console.error('Error fetching cameras:', error)
    }
  }

  const accessCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: activeCamera?.deviceId,
        },
      })
      setIsStreaming(true)
      if (videoRef.current) {
        videoRef.current.srcObject = stream
      }
      return stream
    } catch (error) {
      console.error('Error accessing camera:', error)
      throw error
    }
  }

  const stopCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop())
      setMediaStream(null)
      setIsStreaming(false)
    }
  }

  const switchCamera = (camera: MediaDeviceInfo) => {
    setActiveCamera(camera)
    stopCamera()
    accessCamera()
  }

  const captureImage = async () => {
    if (!videoRef.current || !mediaStream) return

    const { videoWidth, videoHeight } = videoRef.current
    const canvas = document.createElement('canvas')
    canvas.width = videoWidth
    canvas.height = videoHeight

    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight)

      // Convert canvas content to blob
      canvas.toBlob(async (blob) => {
        if (blob) {
          const timestamp = moment(new Date())
            .format('MM-DD-yyyy-HH-SSS')
            .replace(/[-:]/g, '')
            .replace('T', '_')
            .split('.')[0]
          const fileName = `image_${timestamp}.${
            blob.type.split('/')[1] || 'jpeg'
          }`

          // Convert blob to file
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
          })

          onCapture(file)
        }
      }, 'image/jpeg')
    }
  }

  useEffect(() => {
    const startCamera = async () => {
      try {
        const initialStream = await accessCamera()
        setMediaStream(initialStream)
      } catch (error) {
        console.error('Error accessing camera:', error)
      }
    }

    startCamera()

    return () => {
      stopCamera()
    }
  }, [])

  useEffect(() => {
    const handleDeviceChange = () => {
      fetchCameras()
    }

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      )
    }
  }, [])

  return {
    videoRef,
    isStreaming,
    cameras,
    captureImage,
    switchCamera,
  }
}

export default useCaptureCameraLogic
