import { Mail, Sms } from '@mui/icons-material'
import { IconButtonProps, Snackbar, Alert, IconButton } from '@mui/material'
import { Company, RecordCompanyRep, Surgery } from 'common/types'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { useSendSurgeryToRepMutation } from 'lib/apollo/hooks'
import React from 'react'

type SendButtonProps = IconButtonProps & {
  surgeryId: Surgery['_id']
  rep: RecordCompanyRep
  companyName: Company['name']
  variant: 'EMAIL' | 'SMS'
}

export function SendButton({
  surgeryId,
  rep,
  companyName,
  variant,
  disabled = false,
  ...rest
}: SendButtonProps) {
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false)

  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
  const [sendSurgeryToRep, { data, loading, error }] =
    useSendSurgeryToRepMutation({
      surgeryId,
      repEmail: rep?.email ? rep.email : '',
      repCell: rep?.cell ? rep.cell : '',
      repFirstName: rep.firstName ? rep.firstName : '',
      repLastName: rep.lastName ? rep.lastName : '',
      repCompanyName: companyName,
      type: variant,
    })

  const success = data?.sendProcedureToRep?.success

  React.useEffect(() => {
    if (loading || success) {
      setIsSnackbarOpen(true)
    }
    if (error || success === false) {
      setIsSnackbarOpen(false)
      setErrorDialogOpen(true)
    }
  }, [error, success, loading])

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setIsSnackbarOpen(false)
  }

  const destinationText =
    variant === 'EMAIL'
      ? (rep.firstName && rep.email) || (rep.lastName && rep.email)
        ? `${rep.firstName} ${rep.lastName} at ${rep.email}`
        : rep.email
      : rep.cell

  const messageToast =
    loading && !error
      ? `Sending procedure to ${destinationText}`
      : `Successfully sent procedure to ${destinationText}`

  return (
    <>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={8000}
        onClose={!loading ? handleCloseSnackbar : () => undefined}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          top: '70px !important',
        }}
      >
        <Alert
          elevation={8}
          variant="filled"
          onClose={!loading ? handleCloseSnackbar : undefined}
          severity={loading && !error ? 'info' : 'success'}
          sx={{ width: '100%' }}
        >
          {messageToast}
        </Alert>
      </Snackbar>
      <AlertDialog
        isOpen={errorDialogOpen}
        mode="error"
        title="Failed to send record"
        description={`There was an error when attempting to send the record to the rep via ${variant}. Please try again.`}
        position={{
          bottom: '40%',
        }}
        positionAboveBottomNavigation
        primaryButtonAction={() => sendSurgeryToRep()}
        primaryButtonText="Retry Send"
        secondaryButtonAction={() => setErrorDialogOpen(false)}
        secondaryButtonText="Cancel"
        zIndex={6}
        isPrimaryDisabled={disabled}
        isPrimaryLoading={loading}
      />
      <IconButton
        edge="end"
        onClick={() => {
          if (!success) {
            sendSurgeryToRep()
          }
        }}
        {...rest}
        disabled={disabled || loading}
        color={error ? 'error' : success ? 'success' : 'primary'}
      >
        {variant === 'EMAIL' ? <Mail /> : <Sms />}
      </IconButton>
    </>
  )
}
