import { ProcedureStatus } from 'common/types'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { useResetSurgeryStatus } from 'lib/apollo/hooks'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton/IconButton'
import Typography from '@mui/material/Typography'
import styles from './demoMenu.module.css'

interface Props {
  procedureStatus: ProcedureStatus['name']
  surgeryId: string
  handleSetProcedureStatus: (procedureStatus: ProcedureStatus['name']) => void
  handleClose: () => void
  isLoading: boolean
}

function DemoMenu({
  surgeryId,
  procedureStatus,
  // handleSetProcedureStatus,
  handleClose,
}: // isLoading,
Props) {
  const [resetSurgeryStatus, resetSurgeryStatusMutation] =
    useResetSurgeryStatus(surgeryId)

  const handleResetSurgeryStatus = () =>
    resetSurgeryStatus({
      variables: { surgeryId: surgeryId },
    })

  const subHeadingStyles = {
    fontWeight: 500,
    fontSize: '1.2rem',
    mt: 1.5,
  }

  return (
    <Box className={`${styles.demoMenu} fullScreenWrapper`}>
      <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-start', m: 2 }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: '85%', mx: 'auto' }}>
        <Typography variant="h1" sx={{ textTransform: 'capitalize' }}>
          Demo menu
        </Typography>

        {/* Procedure status  */}
        <Typography sx={subHeadingStyles}>Procedure status</Typography>
        <Box className={styles.statusContainer}>
          <Typography variant="h4">Current procedure status:</Typography>
          <Typography sx={{ ml: 1, textTransform: 'uppercase' }}>
            {procedureStatus}
          </Typography>
        </Box>
        <LoadingButton
          disabled={false}
          loading={resetSurgeryStatusMutation.loading}
          loadingText="Resetting Surgery status"
          onClick={handleResetSurgeryStatus}
          sx={{ my: 1 }}
        >
          Reset procedure status
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default DemoMenu
