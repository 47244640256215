import Modal from 'components/molecules/Modal/Modal'
import './AddLocation.scss'
import { DialogProps, TextField, Typography } from '@mui/material'
import Button from 'components/molecules/Button/Button'
import { useState } from 'react'
import { CreateLocationParams } from 'lib/services/api/locations-service/createLocation/types'
import { InventoryLocation } from '../LocationManagement.types'

interface AddLocationProps extends DialogProps {
  locationName?: string
  parent: InventoryLocation
  isLoading?: boolean
  onSave?: (params: CreateLocationParams) => void
}

const AddLocation = ({
  open,
  locationName,
  parent,
  isLoading,
  onSave,
  onClose,
}: AddLocationProps) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const parentName = parent?.name
  const handleSave = () => {
    if (!name) return
    onSave &&
      onSave({ name, description, parentLocationId: parent?.id || null })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="add-location-modal-container">
        <div className="header">
          <Typography variant="h2">
            {`Add ${locationName} ${parentName ? `into ${parentName}` : ''}`}
          </Typography>
        </div>
        <div className="body">
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            placeholder="Enter name"
            required
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            placeholder="Enter description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className="actions">
            <Button
              onClick={(e) => onClose && onClose(e, 'backdropClick')}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              disabled={!name}
              onClick={handleSave}
              variant="outlined"
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddLocation
