import {
  FormControl,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import {
  CameraAlt,
  Close,
  FlipCameraIos,
  PlayArrow,
  Replay,
  Send,
  Stop,
} from '@mui/icons-material'
import { Player } from '@lottiefiles/react-lottie-player'

import { LoadingButton } from 'components/mui'

import { useCaptureTrayContext } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import { useSPDScan } from '../../../views/SPDLayout/SPD/Scan/Scan.context'
import useCaptureTrayDialog from './CaptureTrayDialog.logic'
import { formatDate } from 'lib/utils/formatDate'

import './CaptureTrayDialog.scss'

const CaptureTrayDialog = () => {
  const { isDrawerOpen, productData } = useSPDScan()
  const {
    isComplete,
    handleReset,
    handleStopClick,
    handleCaptureClick,
    shouldStop,
    displayCount,
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleSelectCamera,
    manualImage,
    handleManualImageChange,
    isValidUrl,
    handleManualImageSubmit,
    handleSubmitImage,
    handleClose,
  } = useCaptureTrayDialog()
  const { isSubmitting } = useCaptureTrayContext()

  return (
    <div className="capture-tray">
      <LoadingButton
        disabled={!isDrawerOpen}
        loading={false}
        variant="contained"
        onClick={handleClose}
        className="close_button"
      >
        <Close />
      </LoadingButton>

      <div className="product-container">
        <Typography variant="h1" className="description">
          {productData[0]?.deviceDescription}
        </Typography>
        <Typography variant="body1">
          Company: {productData[0]?.company?.name}
        </Typography>
        <Typography variant="body1">
          Expires at: {formatDate(productData[0]?.expirationDate as string)}
        </Typography>
        <Typography variant="body1">
          Device count: {productData[0]?.deviceCount}
        </Typography>

        <div className="manual-input_container">
          <TextField
            fullWidth
            name="trayImageURL"
            placeholder="Tray image URL"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadingButton
                    className="manual-submit_button"
                    disabled={!manualImage || isSubmitting}
                    loading={isSubmitting}
                    onClick={handleManualImageSubmit}
                  >
                    <Send />
                  </LoadingButton>
                </InputAdornment>
              ),
            }}
            value={manualImage}
            onChange={handleManualImageChange}
          />

          {!isValidUrl && manualImage && (
            <Typography variant="body2" className="image-error">
              Invalid image URL
            </Typography>
          )}

          {isValidUrl && manualImage && (
            <div className="manual-image_container">
              <Skeleton className="image-loading_background" />
              <img src={manualImage} alt="Tray" />
            </div>
          )}
        </div>
      </div>

      {image.length > 0 && (
        <img className="captured-img" src={image} alt="Tray" />
      )}

      <div className="camera-container">
        {videoRef === null || mediaStream === null ? (
          <Skeleton className="feed" />
        ) : (
          <>
            <FormControl fullWidth className="camera-select_container">
              <LoadingButton
                loading={false}
                disabled={false}
                onClick={handleToggleCameraMenu}
                sx={{ zIndex: 9999 }}
              >
                <FlipCameraIos />
              </LoadingButton>

              <div
                className="camera-options"
                style={{
                  display: isCameraMenuOpen ? 'flex' : 'none',
                  opacity: isCameraMenuOpen ? 1 : 0,
                }}
              >
                {cameras.map((camera: any) => (
                  <div
                    key={camera.deviceId}
                    className="camera"
                    style={{
                      backgroundColor:
                        camera.deviceId === activeCamera?.deviceId
                          ? '#5035ED'
                          : '#BCB7E1',
                    }}
                    onClick={() => handleSelectCamera(camera)}
                  >
                    <p
                      className="label"
                      style={{
                        color:
                          camera.deviceId === activeCamera?.deviceId
                            ? 'white'
                            : '#11142D',
                      }}
                    >
                      {camera.label}
                    </p>

                    <p
                      className="type"
                      style={{
                        color:
                          camera.deviceId === activeCamera?.deviceId
                            ? 'white'
                            : '#11142D',
                      }}
                    >
                      {camera.cameraType}
                    </p>
                  </div>
                ))}
              </div>
            </FormControl>

            <video className="feed" ref={videoRef} autoPlay muted playsInline />
          </>
        )}

        {!isSubmitting ? (
          <div className="counter-container">
            <div className="buttons">
              {isComplete ? (
                <button className="retake" onClick={handleReset}>
                  <Replay className="icon" />
                </button>
              ) : (
                <>
                  <button className="stop" onClick={handleStopClick}>
                    {shouldStop ? (
                      <PlayArrow className="icon" />
                    ) : (
                      <Stop className="icon" />
                    )}
                  </button>
                  <button className="capture" onClick={handleCaptureClick}>
                    <CameraAlt className="icon" />
                  </button>
                </>
              )}
            </div>

            <p className="count">{displayCount}</p>
          </div>
        ) : (
          <div className="loading-atom_container">
            <Player
              autoplay
              loop
              src="https://lottie.host/70fc766e-3d02-4e03-be88-a640ee0daaed/ewUXiG4fm5.json"
              className="loading-atom"
            />
          </div>
        )}

        {image.length > 0 ? (
          <div className="submit-button_container">
            <LoadingButton
              disabled={isSubmitting}
              loading={isSubmitting}
              variant="contained"
              onClick={handleSubmitImage}
              className="submit"
            >
              Submit
            </LoadingButton>
          </div>
        ) : (
          !isSubmitting && (
            <Typography variant="h2" className="description" mt="30px">
              Please place your captured product in the tray and capture an
              image
            </Typography>
          )
        )}
      </div>
    </div>
  )
}

export default CaptureTrayDialog
