import { UPDATE_LOCATIONS_TREE } from 'lib/services/api/schema'
import { MutationTuple, useMutation } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'
import { UpdateLocationsTreeParams, UpdateLocationsTreeResponse } from './types'

type IUpdateLocationsTree = MutationTuple<
  UpdateLocationsTreeResponse,
  UpdateLocationsTreeParams
>

const useUpdateLocationsTree = (): IUpdateLocationsTree => {
  return useMutation<UpdateLocationsTreeResponse, UpdateLocationsTreeParams>(
    UPDATE_LOCATIONS_TREE,
    {
      onError: (error: Error) => {
        captureWarning(error)
      },
    }
  )
}

export default useUpdateLocationsTree
