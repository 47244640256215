import { FC, ChangeEvent } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import { ISearchInputProps } from './SearchInput.types'
import './SearchInput.scss'

const SearchInput: FC<ISearchInputProps> = ({
  onSearch,
  placeholder,
  isLoading,
  ...rest
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    onSearch(query)
  }

  return (
    <TextField
      id="input-with-icon-textfield"
      className="search-input-textfield"
      placeholder={placeholder}
      fullWidth
      onChange={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: isLoading && (
          <CircularProgress
            style={{
              width: 29,
              height: 27,
            }}
            className="end-icon"
          />
        ),
      }}
      variant="outlined"
      {...rest}
    />
  )
}

export default SearchInput
