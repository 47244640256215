import { Grid, Typography, Toolbar, alpha } from '@mui/material'
import { useDashboardContext } from 'lib/context/DashboardContext'

interface TableHeaderProps {
  title: string
  renderBackgroundColor?: boolean
}

export const TableHeader = ({
  title,
  renderBackgroundColor = true,
}: TableHeaderProps) => {
  const { darkMode } = useDashboardContext()

  return (
    <Toolbar
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        backgroundColor: renderBackgroundColor
          ? alpha('rgb(80, 53, 237)', 0.3)
          : 'transparent',
        borderRadius: 3,
      }}
    >
      <Grid
        container
        aria-label="table-header-container"
        direction="row"
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid item>
          <Typography
            variant="h2"
            color={darkMode ? 'grayscale.light' : 'grayscale.dark'}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  )
}
