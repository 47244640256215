import {
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'

import SPDCortexScanner from '../SPDCortexScanner/SPDCortexScanner'
import CaptureTrayDialog from '../../../../components/organisms/CaptureTrayDialog/CaptureTrayDialog'
import DialogDrawer from 'components/molecules/Drawer/DialogDrawer'
import ConfirmCountdownDurationModal from '../../../../components/organisms/ConfirmCountdownDurationModal/ConfirmCountdownDurationModal'

import { useSPDScan } from './Scan.context'
import { useSPD } from '../SPD.context'

import ScanGuide from 'assets/icons/svgs/ScanGuid.svg'

import './Scan.scss'
import InventoryProvider from 'lib/apollo/inventory-config'
import { ChangeEvent } from 'react'

const Scan = () => {
  const {
    scannedCode,
    setScannedCode,
    lookupQuery,
    isDrawerOpen,
    setIsDrawerOpen,
    manualCode,
    setManualCode,
  } = useSPDScan()

  const { confirmDurationModalOpen, setConfirmDurationModalOpen } = useSPD()

  return (
    <div className="spd-scan">
      <div className="scanner">
        <SPDCortexScanner
          canScan={true}
          onScan={(code) => {
            setScannedCode(code)
          }}
        />

        <Skeleton
          className="skeleton"
          variant="rounded"
          width="100%"
          height="100%"
          animation="wave"
        />

        {(scannedCode.length > 0 || manualCode.length > 0) &&
          lookupQuery.loading && (
            <div className="scanned-code_wrapper">
              <span className="scanned-text_wrapper">
                <CircularProgress size="0.8em" className="spinner" />
                <p className="scanned-text">Searching for product</p>
              </span>
              <p className="scanned-code">{scannedCode || manualCode}</p>
            </div>
          )}

        <InventoryProvider>
          <DialogDrawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            height="95%"
          >
            <CaptureTrayDialog />
          </DialogDrawer>
        </InventoryProvider>

        <ConfirmCountdownDurationModal
          open={confirmDurationModalOpen}
          onClose={() => {
            setConfirmDurationModalOpen(false)
          }}
        />
      </div>
      <div className="input_wrapper">
        <TextField
          fullWidth
          autoComplete="off"
          placeholder="Product UDI"
          variant="outlined"
          type="text"
          className="manual-input"
          value={manualCode}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setManualCode(e.target.value)
          }
        />

        <div className="instructions_container">
          <img src={ScanGuide} alt="Capture Guide" className="image" />

          <Typography variant="body1">
            Please document your product's barcode
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Scan
