import { flexBox } from 'common/styles/mixins'
import styled from 'styled-components'

export const MainContainer = styled.div`
  ${flexBox('flex', 'center', 'center', 'column', '2rem')}
  width: 310px;
`

export const SwitchContainer = styled.div`
  ${flexBox('flex', 'unset', 'center', 'row', '0.5rem')}
  background-color: var(--secondary-lightest);
  border: 1px solid var(--grayscale-light);
  border-radius: 0.25rem;
  padding-left: 1em;
`

export const Info = styled.div`
  text-align: center;
`

export const QRCodeContainer = styled.div`
  ${flexBox('flex', 'center', 'center', 'unset', 'unset')}
  height: 256px;
  aspect-ratio: 1;
`

export const ErrorMessage = styled.p`
  color: var(--error);
  text-align: center;
`
