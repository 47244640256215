import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  Box,
  LinearProgress,
  TextField,
  List,
  ListItem,
  IconButton,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { AddBox, ArrowBack, Delete } from '@mui/icons-material'
import { Surgery } from 'common/types'
import { ScannerFormatSwitcher } from 'components/ScannerFormatSwitcher'
import {
  useAddSurgeryInstrumentTrayScans,
  useDeleteSurgeryInstrumentTray,
} from 'lib/apollo/hooks'
import { useAssetFormData } from 'lib/context/AssetFormData'
import { useCortexDecoderContext, FormatType } from 'lib/cortex'
import { LoadingButton } from '@mui/lab'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { toast, Toaster, ToastBar } from 'react-hot-toast'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FetchResult } from '@apollo/client'
import { BarcodeIcon } from 'assets/icons/BarcodeIcon'

// INSTRUMENT TRAY SCANNER WITH SINGLE SCANNER PROVIDER
interface InstrumentTraysProps {
  surgery: Surgery
}
export const InstrumentTrays = ({ surgery }: InstrumentTraysProps) => {
  // instrument tray scanner: cannot abstract due to scanner state & zindex
  const {
    isActive,
    formatType,
    setFormatType,
    trayScannerState,
    currentScan,
    setCurrentScan,
  } = useCortexDecoderContext()
  const originalFormatRef = useRef(formatType)
  const maybeSetTrayScanner = trayScannerState?.[1]
  const isTrayScannerOpen = trayScannerState?.[0] ?? false
  const setIsTrayScannerOpen = useMemo(
    () => maybeSetTrayScanner ?? (() => {}),
    [maybeSetTrayScanner]
  )

  const { assetFormData } = useAssetFormData()

  useEffect(() => {
    if (isTrayScannerOpen) {
      const originalFormat = originalFormatRef.current
      setFormatType(FormatType['1d'])
      return () => {
        if (originalFormat) {
          setFormatType(originalFormat)
        }
      }
    }
  }, [setFormatType, isTrayScannerOpen])

  useEffect(() => {
    return () => {
      setIsTrayScannerOpen(false)
    }
  }, [setIsTrayScannerOpen])

  const navigate = useNavigate()
  const [manualInstrumentTray, setManualInstrumentTray] = useState<string>()
  const [requiredValueAlertOpen, setRequiredValueAlertOpen] = useState(false)
  const [isDuplicateTrayAlertOpen, setIsDuplicateTrayAlertOpen] =
    useState(false)
  const [instrumentTrayScanned, setInstrumentTrayScanned] = useState(false)

  const isAssetTrayCurrentScan = assetFormData?.assetType !== undefined

  const surgeryId = surgery._id

  const [addSurgeryInstrumentTrayScans, setSurgeryInstrumentTrayMutation] =
    useAddSurgeryInstrumentTrayScans(surgeryId)

  const handleInstrumentTrayValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setManualInstrumentTray(e.target.value)
  }

  const instrumentTrayDataReturn = (surgery.instrumentTrays || []).filter(
    (element) => element !== '' && element !== null
  )

  const duplicateTray = useCallback(
    (value: CortexDecoder.CDResult['barcodeData']) => {
      return (
        value ===
        instrumentTrayDataReturn?.find(
          (tray: CortexDecoder.CDResult['barcodeData']) => {
            return tray === value
          }
        )
      )
    },
    [instrumentTrayDataReturn]
  )

  const handleInstrumentTraySubmit = () => {
    if (!!manualInstrumentTray && !duplicateTray(manualInstrumentTray)) {
      addSurgeryInstrumentTrayScans({
        variables: {
          surgeryId: surgeryId,
          instrumentTrays: [manualInstrumentTray],
        },
      }).then((value: FetchResult<any>) => {
        if (value.data) {
          toast.success('Instrument tray added')
          setManualInstrumentTray('')
        } else {
          toast.error('Failed to add instrument tray, please try again.')
          throw new Error(`${value.errors}`)
        }
      })
    } else if (
      manualInstrumentTray !== undefined &&
      duplicateTray(manualInstrumentTray)
    ) {
      setIsDuplicateTrayAlertOpen(true)
    } else {
      setRequiredValueAlertOpen(true)
    }
  }

  useEffect(() => {
    if (!instrumentTrayScanned) {
      if (
        currentScan !== undefined &&
        !duplicateTray(
          currentScan as unknown as CortexDecoder.CDResult['barcodeData']
        ) &&
        !isAssetTrayCurrentScan
      ) {
        setIsTrayScannerOpen(false)
        addSurgeryInstrumentTrayScans({
          variables: {
            surgeryId: surgeryId,
            instrumentTrays: [currentScan?.barcodeData],
          },
        })
        setCurrentScan(undefined)
        setInstrumentTrayScanned(true)
      } else if (
        currentScan !== undefined &&
        !!duplicateTray(
          currentScan as unknown as CortexDecoder.CDResult['barcodeData']
        ) &&
        isTrayScannerOpen
      ) {
        setIsDuplicateTrayAlertOpen(true)
      }
    }
  }, [
    instrumentTrayScanned,
    isTrayScannerOpen,
    setCurrentScan,
    setIsTrayScannerOpen,
    isDuplicateTrayAlertOpen,
    instrumentTrayDataReturn,
    addSurgeryInstrumentTrayScans,
    isAssetTrayCurrentScan,
    currentScan,
    surgeryId,
    manualInstrumentTray,
    duplicateTray,
  ])

  // reset instrumentTrayScanned flag after slight delay
  useEffect(() => {
    if (instrumentTrayScanned) {
      setTimeout(() => {
        setInstrumentTrayScanned(false)
      }, 1000)
    }
  }, [instrumentTrayScanned])

  const [deleteSurgeryInstrumentTray, setDeleteSurgeryInstrumentTrayMutation] =
    useDeleteSurgeryInstrumentTray({
      surgeryId: surgeryId,
    })

  const handleInstrumentTrayDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    deleteSurgeryInstrumentTray({
      variables: {
        surgeryId: surgeryId,
        instrumentTray: e.currentTarget.value,
      },
    })
  }

  if (isTrayScannerOpen) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 1,
        }}
      >
        {!isActive && (
          <Box
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            position="absolute"
            sx={{
              backgroundColor: 'white',
            }}
          >
            <Box textAlign="center">
              <Typography
                variant="h3"
                color="grey.800"
                my={6}
                letterSpacing={1}
                fontWeight={500}
                fontSize={18}
              >
                Preparing Image Capture
              </Typography>
              <LinearProgress />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            position: 'fixed',
            top: 100,
            zIndex: 99999,
            padding: 1.5,
            borderRadius: 2,
            backgroundColor: 'rgba(0, 0, 0, .5)',
          }}
        >
          <ScannerFormatSwitcher noPersist />
        </Box>

        {isDuplicateTrayAlertOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 100,
              zIndex: 99999,
              padding: 1,
              borderRadius: 2,
              backgroundColor: 'rgba(0, 0, 0, .5)',
            }}
          >
            <AlertDialog
              isOpen={isDuplicateTrayAlertOpen}
              title="Tray Duplicate"
              description="This tray has already been captured"
              primaryButtonAction={() => {
                setIsDuplicateTrayAlertOpen(false)
                setIsTrayScannerOpen(false)
                setCurrentScan(undefined)
              }}
              primaryButtonText="Close image capture"
            />
          </Box>
        )}
        <Button
          sx={{ bottom: '72px', position: 'fixed', zIndex: 999999999999999 }}
          variant="contained"
          onClick={() => {
            setIsDuplicateTrayAlertOpen(false)
            setIsTrayScannerOpen(false)
          }}
          disabled={!isActive}
        >
          Close Image Capture
        </Button>
      </div>
    )
  }

  const addButton = setSurgeryInstrumentTrayMutation.loading ? (
    <LoadingButton
      loading={setSurgeryInstrumentTrayMutation.loading}
      disabled={false}
    />
  ) : (
    <IconButton onClick={() => handleInstrumentTraySubmit()}>
      <AddBox sx={{ color: 'primary.main' }} />
    </IconButton>
  )

  const deleteButton = setDeleteSurgeryInstrumentTrayMutation.loading ? (
    <LoadingButton
      loading={setDeleteSurgeryInstrumentTrayMutation.loading}
      disabled={false}
    />
  ) : (
    <Delete />
  )

  return (
    <>
      <Helmet>
        <title>Instrument Trays</title>
      </Helmet>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        aria-label="instrument-page-container"
        px={1.5}
        pt={1.5}
      >
        <Grid item>
          <Button
            sx={{ mb: 2, width: 'fit' }}
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Typography
            variant="h1"
            sx={{ userSelect: 'none', lineHeight: 1.3, my: 0.5 }}
          >
            Instrument Trays
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            aria-label="instrument-input-container"
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            width="100%"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={10}>
              <TextField
                label="Instrument Tray ID"
                id="instrument-tray"
                name="instrument-tray"
                placeholder="Capture image or enter instrument tray ID"
                value={manualInstrumentTray}
                onChange={(e) => handleInstrumentTrayValueChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                autoComplete="off"
                variant="standard"
                InputProps={{
                  endAdornment: addButton,
                }}
              />
              <AlertDialog
                zIndex={99999}
                isOpen={requiredValueAlertOpen}
                title="No Instrument Tray Value"
                description="Instrument tray field cannot be empty"
                primaryButtonAction={() => setRequiredValueAlertOpen(false)}
                primaryButtonText="Close"
              />
              <AlertDialog
                zIndex={99999}
                isOpen={isDuplicateTrayAlertOpen}
                title="Tray Duplicate"
                description="This tray has already been added"
                primaryButtonAction={() => {
                  setIsDuplicateTrayAlertOpen(false)
                }}
                primaryButtonText="Close"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{
                  ml: 2,
                }}
                variant="outlined"
                color="primary"
                onClick={() => setIsTrayScannerOpen(true)}
                className="barcode-button"
              >
                <BarcodeIcon active color="var(--primary)" />
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <List sx={{ pt: 0 }}>
              {(instrumentTrayDataReturn as Array<string>)?.length > 0 ? (
                instrumentTrayDataReturn?.map((tray: string, index: number) => (
                  <ListItem key={`${tray}-${index}`}>
                    <Typography
                      sx={{
                        display: 'inline-flex',
                        overflow: 'hidden',
                        wordBreak: 'break-all',
                        textOverflow: 'ellipsis',
                        color: 'grey.800',
                        lineHeight: '1.5',
                      }}
                    >
                      {tray}
                    </Typography>
                    <Button
                      aria-label="delete-instrument-tray"
                      endIcon={deleteButton}
                      key={`${tray}-${index}`}
                      onClick={(e) => handleInstrumentTrayDelete(e)}
                      size="small"
                      sx={{ color: 'grey.800', p: 0 }}
                      value={tray}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography
                  variant="body2"
                  textTransform="capitalize"
                  color="grey.800"
                  lineHeight={1.5}
                >
                  No instrument trays captured
                </Typography>
              )}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Toaster
        containerStyle={{
          bottom: 100,
        }}
        position="bottom-center"
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button onClick={() => toast.dismiss(t.id)}>X</button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  )
}
