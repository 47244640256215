import { useState, useEffect, useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'
import { theme } from 'assets/styles/customTheme'
import { useDashboardContext } from 'lib/context/DashboardContext'

export interface AssetsThisMonthChartProps {
  hardwareCount: number
  biologicCount: number
  otherCount: number
  consumableCount: number
}

export const AssetsThisMonthChart = ({
  hardwareCount,
  biologicCount,
  otherCount,
  consumableCount,
}: AssetsThisMonthChartProps) => {
  const { darkMode } = useDashboardContext()
  const legendColor = darkMode
    ? theme.palette.grayscale.lightest
    : theme.palette.grayscale.dark

  const zeroAssets = useMemo(() => {
    return (
      hardwareCount === 0 &&
      biologicCount === 0 &&
      otherCount === 0 &&
      consumableCount === 0
    )
  }, [hardwareCount, biologicCount, otherCount, consumableCount])

  const pieChartOptions = {
    series: [hardwareCount, otherCount, biologicCount, consumableCount],
    labels: [
      'Implantable Hardware',
      'Implantable Other',
      'Implantable Biologic',
      'Consumable',
    ],
    colors: [
      theme.palette.secondary.main,
      theme.palette.primary.main,
      theme.palette.secondary.dark,
      theme.palette.primary.disabled,
    ],
    dataLabels: {
      style: {
        colors: [
          darkMode
            ? theme.palette.grayscale.lightest
            : theme.palette.grayscale.dark,
        ],
      },
      dropShadow: {
        enabled: true,
        color: darkMode
          ? theme.palette.primary.dark
          : theme.palette.primary.light,
        opacity: 0.3,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: [
        darkMode ? theme.palette.primary.dark : theme.palette.primary.light,
      ],
    },
    legend: {
      fontSize: '12px',
      labels: {
        colors: legendColor,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      fontWeight: 800,
      markers: {
        strokeColor: darkMode
          ? theme.palette.grayscale.lightest
          : theme.palette.grayscale.dark,
        fillColors: [
          theme.palette.secondary.main,
          theme.palette.primary.main,
          theme.palette.secondary.dark,
          theme.palette.primary.disabled,
        ],
        radius: 12,
        width: 7,
        height: 7,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 0,
      },
    },
    noData: {
      text: 'No data available',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: darkMode
          ? theme.palette.grayscale.lightest
          : theme.palette.grayscale.dark,
        fontSize: '14px',
        fontFamily: 'Roboto',
      },
    },
  }

  const [options, setOptions] = useState({
    ...pieChartOptions,
    series: zeroAssets
      ? [1]
      : [hardwareCount, otherCount, biologicCount, consumableCount],
    dataLabels: {
      enabled: zeroAssets ? false : true,
    },
    tooltip: {
      enabled: zeroAssets ? false : true,
      fillSeriesColor: false,
    },
    labels: zeroAssets
      ? ['No Products Consumed']
      : [
          'Implantable Hardware',
          'Implantable Other',
          'Implantable Biologic',
          'Consumable',
        ],
  })

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      series: zeroAssets
        ? [1]
        : [hardwareCount, otherCount, biologicCount, consumableCount],
      dataLabels: {
        enabled: zeroAssets ? false : true,
      },
      tooltip: {
        enabled: zeroAssets ? false : true,
        fillSeriesColor: false,
      },
      labels: zeroAssets
        ? ['No Products Consumed']
        : [
            'Implantable Hardware',
            'Implantable Other',
            'Implantable Biologic',
            'Consumable',
          ],
    }))
  }, [hardwareCount, otherCount, biologicCount, consumableCount, zeroAssets])

  return (
    <ReactApexChart
      height="210"
      options={options}
      series={options.series}
      type="pie"
    />
  )
}
