import React, { createContext, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { Surgery } from 'common/types'

interface DashboardContextProps {
  isLoading: boolean
  todaysSurgeries: Surgery[]
  error: ApolloError | undefined
  surgeryList?: Surgery[]
  assetGroups?: Surgery['assetGroups']
  currentYearSurgeriesLoading?: boolean
  currentYearSurgeriesError?: ApolloError
  currentYearSurgeryList?: Surgery[]
  darkMode: boolean
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const DashboardContext = createContext<DashboardContextProps>({
  isLoading: false,
  todaysSurgeries: [] as Surgery[],
  error: undefined,
  surgeryList: [] as Surgery[],
  assetGroups: [] as Surgery['assetGroups'],
  currentYearSurgeriesLoading: false,
  currentYearSurgeriesError: undefined,
  currentYearSurgeryList: [] as Surgery[],
  darkMode: false,
  setDarkMode: () => {},
})
interface DashboardProviderProps {
  children: React.ReactNode
}
export function DashboardProvider({ children }: DashboardProviderProps) {
  const [darkMode, setDarkMode] = useState(false)

  return (
    <DashboardContext.Provider
      value={{
        isLoading: false,
        todaysSurgeries: [],
        error: undefined,
        surgeryList: [],
        assetGroups: [],
        darkMode,
        setDarkMode,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export function useDashboardContext() {
  const context = React.useContext(DashboardContext)
  if (context === undefined) {
    throw new Error('useDashboardContext must be used with DashboardProvider')
  }
  return context
}
