import { useState } from 'react'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { Grid } from '@mui/material'
import TextInput from 'components/molecules/TextInput/TextInput'
import { AssetFormData } from 'common/types'
import { CustomSelect } from 'components/CustomSelect'

interface ConsumableModalProps {
  consumableModalOpen: boolean
  deviceCount: number
  handleConsumableModalClose: () => void
  index: number
  setConsumableModalOpen: (e: boolean) => void
  createDispositionsChangeHandler: (index: number) => (e: any) => void
  isAssetFieldDisabled: boolean
  assetFormData: AssetFormData
  multipackItemsUsed: number
  handleSetMultipackItemsUsed: (e: number) => void
  isRfMultipackConsumable: boolean
  isSpongeConsumable: boolean
  isLoading: boolean
}

export const ConsumableModal = ({
  consumableModalOpen,
  deviceCount,
  handleConsumableModalClose,
  index,
  setConsumableModalOpen,
  createDispositionsChangeHandler,
  isAssetFieldDisabled,
  assetFormData,
  multipackItemsUsed,
  handleSetMultipackItemsUsed,
  isRfMultipackConsumable,
  isSpongeConsumable,
  isLoading,
}: ConsumableModalProps) => {
  const [customMultipackInputOpen, setCustomMultipackInputOpen] =
    useState(false)
  const [customMultipackItemsUsedValue, setCustomMultipackItemsUsedValue] =
    useState(2)

  const handleMultipackItemsUsedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name?: any
  ) => {
    if (
      (name?.props?.children === multipackConsumables[2].label &&
        !customMultipackInputOpen) ||
      e.type === 'change'
    ) {
      setCustomMultipackInputOpen(true)
      setCustomMultipackItemsUsedValue(Math.max(Number(e.target.value), 1))
    } else {
      setCustomMultipackInputOpen(false)
    }
    handleSetMultipackItemsUsed(Math.max(Number(e.target.value), 1))
  }

  enum MultipackConsumableModalSelection {
    Shelfpack = deviceCount,
    OneItem = 1,
    Custom = customMultipackItemsUsedValue,
  }

  const multipackConsumables = [
    {
      value: MultipackConsumableModalSelection.Shelfpack,
      label: `Entire Shelfpack Used (${deviceCount} items used)`,
    },
    {
      value: MultipackConsumableModalSelection.OneItem,
      label: '1 Individual Item Used',
    },
    {
      value: MultipackConsumableModalSelection.Custom,
      label: 'Add Custom Amount Used',
    },
  ]

  return (
    <>
      <AlertDialog
        isOpen={consumableModalOpen}
        zIndex={6}
        mode="info"
        title="Multipack Consumable"
        description="This product is a multipack. Please select the amount of individual items used."
        primaryButtonAction={handleConsumableModalClose}
        primaryButtonText="Submit"
        secondaryButtonAction={() => setConsumableModalOpen(false)}
        secondaryButtonText="Cancel"
        isPrimaryDisabled={false}
        isPrimaryLoading={isLoading}
      >
        <Grid
          aria-label="multipack-container"
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <CustomSelect
            id={`multipack-items-used-${index}`}
            label="Items Used"
            name={`multipackItemsUsed-${index}`}
            disabled={isAssetFieldDisabled}
            value={multipackItemsUsed}
            handleChangeFormData={(e, name) =>
              handleMultipackItemsUsedChange(e, name)
            }
            options={multipackConsumables}
            required
            placeholder="Select"
            secondInputType="number"
            inputProps={{
              min: 1,
            }}
            secondInputLabel="Custom Amount"
            secondInputDefaultValue={1}
            renderSecondInput={customMultipackInputOpen}
          />
        </Grid>
      </AlertDialog>
      <TextInput
        handleChangeFormData={createDispositionsChangeHandler(index)}
        id={`consumable-count-${index}`}
        label={
          isRfMultipackConsumable || isSpongeConsumable
            ? 'Pack(s) Used'
            : 'Items Used'
        }
        name={`consumableCount-${index}`}
        error={
          'consumableCount' in assetFormData &&
          !(Number(assetFormData.dispositions[index]?.consumableCount) > 0)
        }
        value={Number(assetFormData.dispositions[index]?.consumableCount)}
        inputMode="numeric"
        type="number"
        required
        disabled={isAssetFieldDisabled}
        sx={{ my: 1 }}
        helperText={
          isRfMultipackConsumable || isSpongeConsumable
            ? `Number of total packs used. 1 Pack has ${deviceCount} items.`
            : 'Number of individual items/devices used'
        }
      />
    </>
  )
}
