import { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'

import { useCreateTrayItemMutation, useGetAllTrayItems } from 'lib/apollo/hooks'
import { useTrayConfigurationContext } from '../../../views/TrayManagementLayout/TrayManagement/TrayManagement.context'
import useCaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera.logic'
import useSubmitTrayCapture from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/SubmitTrayCapture.logic'

import { CreateTrayItemInput } from 'common/types'
import { ErrorIcon } from 'react-hot-toast'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import useTrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { matchProduct } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'
import { useCaptureTrayContext } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'

/**
 * useAddTrayModal is the hook responsible for the business logic of adding a new tray item in the mgat-iservices database.
 */
const useAddTrayModal = ({ onClose }: { onClose: () => void }) => {
  const {
    setTrays,
    isListOfSurgeriesOpen,
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarState,
  } = useTrayConfigurationContext()
  const { trayItems, loading, refetch } = useGetAllTrayItems()
  const { createTrayItem, loading: isCreatingTrayItem } =
    useCreateTrayItemMutation()
  const { selectedTrayCategory, setSelectedTrayCategory } =
    useTrayConfigurationContext()
  const {
    handleSPDCaptureImage,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
  } = useCaptureCamera()
  const {
    submitImage,
    analysisScrews,
    isComplete: isAnalysisComplete,
    setIsComplete: setIsAnalysisComplete,
    setIsAnalysisFromSPD,
    analysisError,
    setAnalysisError,
    handleBase64UploadToS3,
  } = useSubmitTrayCapture()
  const { isSubmitting } = useCaptureTrayContext()
  const { trayScrews, setTrayScrews, findScrew } = useTrayMap()

  const [trayDeviceUDI, setTrayDeviceUDI] = useState<string>('')
  const [trayDescription, setTrayDescription] = useState<string>('')
  const [trayLotBatch, setTrayLotBatch] = useState<string>('')
  const [traySerialNumber, setTraySerialNumber] = useState<string>('')
  const [traySurgeryId, setTraySurgeryId] = useState<string>('')
  const [selectedLocationId, setSelectedLocationId] = useState<
    number | undefined
  >(undefined)
  const [trayVendor, setTrayVendor] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState<boolean>(false)
  const [showScanner, setShowScanner] = useState<boolean>(false)
  const [showCameraCapture, setShowCameraCapture] = useState<boolean>(false)
  const [dropoffTime, setDropoffTime] = useState<number | null>()
  const [trayImageUrl, setTrayImageUrl] = useState<string | null>(null)
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false)
  const [shouldCreateItem, setShouldCreateItem] = useState<boolean>(false)
  const [isTrayMapDrawerOpen, setIsTrayMapDrawerOpen] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)

  useEffect(() => {
    if (!error && !loading) {
      setTrays(trayItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, trayItems])

  useEffect(() => {
    if (cameras.length > 0) {
      handleSelectCamera(cameras[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showCameraCapture,
    cameras.length,
    showScanner,
    image,
    selectedTrayCategory,
  ])

  useEffect(() => {
    setDropoffTime(Date.now())
  }, [])

  useEffect(() => {
    if (trayImageUrl && shouldCreateItem) {
      handleCreateTrayItem()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayImageUrl, shouldCreateItem])

  useEffect(() => {
    if (!isListOfSurgeriesOpen) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListOfSurgeriesOpen])

  useEffect(() => {
    const handleAddScrewsToVendorSPDMap = () => {
      const mappedAnalysisScrews: BetterIDTrayScrew[] = analysisScrews.map(
        (analysisScrew: any) => {
          const productData = matchProduct(
            analysisScrew.label,
            analysisScrew.column
          )

          return {
            row: analysisScrew.row + 1,
            column: analysisScrew.column,
            label: analysisScrew.label,
            wasted: analysisScrew.wasted,
            x: analysisScrew.x,
            deviceId: productData.deviceID,
            deviceDescription: productData.title,
            company: {
              name: 'Stryker',
              id: 0,
              regEmail: '',
            },
            expirationDate: null,
          }
        }
      )

      const newScrewsFromAnalysis: BetterIDTrayScrew[] =
        mappedAnalysisScrews.filter(
          (mappedAnalysisScrew) =>
            !findScrew(
              mappedAnalysisScrew.label,
              mappedAnalysisScrew.row,
              mappedAnalysisScrew.x
            )
        )

      setTrayScrews((prevTrayScrews) => [
        ...prevTrayScrews,
        ...newScrewsFromAnalysis,
      ])
      setIsAnalysisComplete(false)
      setIsComplete(true)
      setIsTrayMapDrawerOpen(true)
    }

    if (isAnalysisComplete) {
      handleAddScrewsToVendorSPDMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnalysisComplete])

  useEffect(() => {
    if (analysisError) {
      setSnackbarMessage(
        'Analysis failed. Please try again with a different image'
      )
      setSnackbarIcon(<ErrorIcon color="error" />)
      setSnackbarState('error')
      setSnackbarOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisError])

  useEffect(() => {
    setIsAnalysisFromSPD(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTrayUDIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined)
    setTrayDeviceUDI(e.target.value)
  }

  const handleTrayDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError(undefined)
    setTrayDescription(e.target.value)
  }

  const handleTrayLotBatchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined)
    setTrayLotBatch(e.target.value)
  }

  const handleTraySurgeryIdChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError(undefined)
    setTraySurgeryId(e.target.value)
  }

  const handleTraySerialNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError(undefined)
    setTraySerialNumber(e.target.value)
  }

  const handleSelectedLocationChange = (e: SelectChangeEvent<number>) => {
    setSelectedLocationId(e.target.value as number)
  }

  const handleTrayVendorChange = (e: SelectChangeEvent<string>) => {
    setTrayVendor(e.target.value)
  }

  const handleCreateTrayItem = async () => {
    if (
      trayDeviceUDI === '' ||
      trayDescription === '' ||
      !selectedTrayCategory ||
      !trayImageUrl
    ) {
      return alert('Please add all the required fields')
    }

    const itemInput: CreateTrayItemInput = {
      companyName: 'Stryker',
      locationId: selectedLocationId as number,
      description: trayDescription,
      barcode: trayDeviceUDI,
      isConsigned: selectedTrayCategory === 'consigned',
      productDetails: JSON.stringify({
        screws: trayScrews,
        surgeryId: traySurgeryId,
        trayCategory: selectedTrayCategory,
        trayImage: trayImageUrl,
      }),
      quantity: 1,
    }

    try {
      const trayItem = await createTrayItem(itemInput)

      if (!trayItem) {
        setSuccess(false)
        setError('Something is wrong. ')
      } else {
        setSuccess(true)
        await refetch()
        resetDataFields()
        onClose()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setShouldCreateItem(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsUploadingImage(true)

      const uploadedImage = await handleBase64UploadToS3(image)

      if (!uploadedImage) {
        alert('Failed to upload tray image')
        throw new Error('Failed to upload tray image')
      }

      setTrayImageUrl(uploadedImage)
      setIsUploadingImage(false)
      setShouldCreateItem(true)
    } catch (error) {
      console.error(error)
    }
  }

  const resetDataFields = () => {
    setTrayDeviceUDI('')
    setTrayDescription('')
    setTrayLotBatch('')
    setTraySerialNumber('')
    setSelectedLocationId(undefined)
  }

  const resetTrayCategoryType = () => {
    setSelectedTrayCategory(null)
  }

  const handleShowScannerButtonClick = () => {
    setShowScanner((prev) => !prev)
  }

  const handleCortexScan = (code: string) => {
    setTrayDeviceUDI(code)
    setShowScanner(false)
  }

  const handleShowCameraCaptureClick = () => {
    setShowScanner(false)
    if (image) {
      setShowCameraCapture(true)
      setIsComplete(false)
      setImage('')
      return
    }
    setShowCameraCapture((prev) => !prev)
  }

  const handleAnalysisClick = () => {
    if (isComplete) {
      setIsTrayMapDrawerOpen(true)
      return
    }
    setAnalysisError('')
    submitImage(image)
  }

  const handleTrayMapDrawerClose = () => {
    setIsTrayMapDrawerOpen(false)
  }

  return {
    trayDeviceUDI,
    setTrayDeviceUDI,
    handleTrayUDIChange,
    trayDescription,
    setTrayDescription,
    handleTrayDescriptionChange,
    trayLotBatch,
    setTrayLotBatch,
    handleTrayLotBatchChange,
    traySurgeryId,
    setTraySurgeryId,
    handleTraySurgeryIdChange,
    traySerialNumber,
    setTraySerialNumber,
    handleTraySerialNumberChange,
    selectedLocationId,
    setSelectedLocationId,
    handleSelectedLocationChange,
    handleCreateTrayItem,
    error,
    success,
    isCreatingTrayItem,
    resetTrayCategoryType,
    showScanner,
    setShowScanner,
    handleShowScannerButtonClick,
    handleCortexScan,
    showCameraCapture,
    setShowCameraCapture,
    handleShowCameraCaptureClick,
    handleSPDCaptureImage,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    dropoffTime,
    handleSubmit,
    isUploadingImage,
    trayVendor,
    handleTrayVendorChange,
    handleAnalysisClick,
    isTrayMapDrawerOpen,
    setIsTrayMapDrawerOpen,
    isSubmitting,
    isComplete,
    handleTrayMapDrawerClose,
    trayScrews,
  }
}

export default useAddTrayModal
