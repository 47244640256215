import {
  MenuItem,
  Select,
  FormControl,
  Typography,
  FormHelperText,
} from '@mui/material'
import { Handyman } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import { RemoveScrewModalProps } from '../../TrayVisualization.types'
import './RemoveScrewModal.scss'

const RemoveScrewModal = ({
  open,
  onClose,
  selectedScrew,
  selectedStatus,
  setSelectedStatus,
  handleStatusChange,
  selectedPlate,
}: RemoveScrewModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="tray-visualization-remove-screw_modal">
        <div className="header-box">
          <Handyman />

          <Typography variant="h3">
            Confirm {selectedPlate ? 'plate' : 'screw'} status
          </Typography>
        </div>

        <div className="screw-data-container">
          {selectedPlate ? (
            <Typography variant="body2">{selectedPlate.plateName}</Typography>
          ) : (
            <>
              <Typography variant="body2">- {selectedScrew?.label}</Typography>
              <Typography variant="body2">
                - Row: {(selectedScrew?.row as number) + 1}
              </Typography>
              <Typography variant="body2">
                - Size: {selectedScrew?.column}
              </Typography>
            </>
          )}
        </div>

        <FormControl fullWidth>
          <Select
            data-testid="add-tray-location-storage_input"
            fullWidth
            variant="outlined"
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography variant="body1" color="gray">
                    Select {selectedPlate ? 'plate' : 'screw'} disposition
                  </Typography>
                )
              }

              return `${selectedStatus
                .charAt(0)
                .toUpperCase()}${selectedStatus.slice(1)}`
            }}
            value={selectedStatus}
            onChange={(e: any) => setSelectedStatus(e.target.value)}
          >
            <MenuItem disabled value="">
              Select {selectedPlate ? 'plate' : 'screw'} disposition
            </MenuItem>

            <MenuItem value="implanted">Implanted</MenuItem>
            <MenuItem value="wasted">Wasted</MenuItem>
          </Select>

          <FormHelperText>
            Select {selectedPlate ? 'plate' : 'screw'} disposition
          </FormHelperText>
        </FormControl>

        <div className="buttons-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            mode="cancel"
            onClick={onClose}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            disabled={false}
            loading={false}
            onClick={handleStatusChange}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default RemoveScrewModal
