import type { BetterIdSearchResponse, BetterIdResult } from 'common/types'

export function bidSearchResponseToResults(
  response: BetterIdSearchResponse
): BetterIdResult[] {
  return response?.assets?.map((asset) => {
    return {
      ...asset,
      ...response.productionIdentifier,
      isUDI: response.term.isUDI ?? false,
      udi: response.term.isUDI ? response.term.value : undefined,
    }
  })
}

export function newBidSearchResponseToResults(
  response: BetterIdSearchResponse
): BetterIdResult[] | BetterIdResult {
  // const matches = response.term.matches
  const assets = response.assets
  const exactMatch = assets?.length === 1 && assets[0]
  if (exactMatch) {
    return {
      ...exactMatch,
      ...response.productionIdentifier,
      isUDI: response.term.isUDI ?? false,
      udi: response.term.isUDI ? response.term.value : undefined,
    }
  }

  return response?.assets?.map((asset) => {
    return {
      ...asset,
      ...response.productionIdentifier,
      isUDI: response.term.isUDI ?? false,
      udi: response.term.isUDI ? response.term.value : undefined,
    }
  })
}
