/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { ErrorIcon } from 'react-hot-toast'

import { v4 as uuidv4 } from 'uuid'
import {
  matchMiddleFaceProduct,
  matchProduct,
  matchUpperFaceProduct,
} from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'
import useCaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera.logic'
import useSubmitTrayCapture from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/SubmitTrayCapture.logic'
import { useCaptureTrayContext } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import useTrayMap from 'views/SPDVendorLayout/SPDVendor/TrayMap/TrayMap.logic'
import { useInventorySheetsContext } from '../InventorySheets/InventorySheets.context'
import { useAddTrayContext } from '../AddTray.context'

import { InventorySheetFile } from '../InventorySheets/InventorySheets.types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { getAnalysisJSONFileName } from 'lib/utils/getAnalysisJSONFile'
import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'

const useAnalyzeTrayLogic = () => {
  const {
    handleSPDCaptureImage,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
  } = useCaptureCamera()
  const { isSubmitting } = useCaptureTrayContext()
  const { setTrayScrews, findScrew } = useTrayMap()
  const {
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarState,
    setTrayAnalysisScrews,
    trayImage,
    setTrayImage,
    selectedTrayType,
    isAnalysisComplete,
    setIsAnalysisComplete,
    setAnalysisFileName,
    handleSubmitAnalysis,
    analysisScrews,
    setIsAnalysisFromSPD,
    isComplete,
    setIsComplete,
    analysisError,
    setAnalysisError,
  } = useAddTrayContext()
  const { uploadFile } = useInventorySheetsContext()

  const [isTrayMapDrawerOpen, setIsTrayMapDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    if (selectedTrayType) {
      const analysisFileName = getAnalysisJSONFileName(selectedTrayType)

      setAnalysisFileName(analysisFileName)
    }
  }, [selectedTrayType])

  useEffect(() => {
    if (image) {
      const saveTrayImage = async () => {
        const imgId: string = uuidv4()
        const newTrayImage: InventorySheetFile = {
          id: imgId,
          src: image,
          isUploading: true,
          isUploaded: false,
          mimeType: 'image/jpeg',
        }
        setTrayImage(newTrayImage)

        await uploadAndUpdateTrayImage(imgId)
      }

      saveTrayImage()
    }
  }, [image])

  useEffect(() => {
    if (isComplete) {
      handleAddScrewsToVendorSPDMap()
    }
  }, [isComplete])

  useEffect(() => {
    if (analysisError) {
      setSnackbarMessage(
        'Analysis failed. Please try again with a different image'
      )
      setSnackbarIcon(<ErrorIcon color="error" />)
      setSnackbarState('error')
      setSnackbarOpen(true)
    }
  }, [analysisError])

  useEffect(() => {
    setIsAnalysisFromSPD(true)
  }, [])

  /**
   * Uploads the tray image and updates the tray image state.
   * @param {string} imgId - The ID of the image to upload.
   */
  const uploadAndUpdateTrayImage = async (imgId: string) => {
    const trayImageURL = await uploadFile(imgId, image, 'image/jpeg', false)

    if (trayImageURL) {
      const newTrayImage: InventorySheetFile = {
        id: imgId,
        src: trayImageURL,
        isUploading: false,
        isUploaded: true,
        mimeType: 'image/jpeg',
      }

      setTrayImage(newTrayImage)
    }
  }

  /**
   * Selects the correct function to match the pre-indexed product data to the analyzed screws.
   */
  const getProductMatchingFunction = (trayType: TrayType) => {
    switch (trayType) {
      case 'stryker upper face':
        return matchUpperFaceProduct
      case 'stryker middle face':
        return matchMiddleFaceProduct
      default:
        return matchProduct
    }
  }

  /**
   * Handles adding screws to the vendor SPD map.
   */
  const handleAddScrewsToVendorSPDMap = () => {
    const matchScrewsToProductData =
      getProductMatchingFunction(selectedTrayType)

    const mappedAnalysisScrews: BetterIDTrayScrew[] = analysisScrews.map(
      (analysisScrew: any) => {
        const productData = matchScrewsToProductData(
          analysisScrew.label,
          analysisScrew.column
        )

        return {
          row: analysisScrew.row + 1,
          column: analysisScrew.column,
          label: analysisScrew.label,
          wasted: analysisScrew.wasted,
          x: analysisScrew.x,
          deviceId: productData.deviceID,
          deviceDescription: productData.title,
          company: {
            name: 'Stryker',
            id: 0,
            regEmail: '',
          },
          expirationDate: null,
        }
      }
    )

    const newScrewsFromAnalysis: BetterIDTrayScrew[] =
      mappedAnalysisScrews.filter(
        (mappedAnalysisScrew) =>
          !findScrew(
            mappedAnalysisScrew.label,
            mappedAnalysisScrew.row,
            mappedAnalysisScrew.x
          )
      )

    setTrayScrews((prevTrayScrews) => [
      ...prevTrayScrews,
      ...newScrewsFromAnalysis,
    ])
    setTrayAnalysisScrews((prevTrayScrews) => [
      ...prevTrayScrews,
      ...newScrewsFromAnalysis,
    ])
    setIsComplete(false)
    setIsAnalysisComplete(true)
    setIsTrayMapDrawerOpen(true)
  }

  /**
   * Handles click event for analysis.
   */
  const handleAnalysisClick = () => {
    if (isAnalysisComplete) {
      setIsTrayMapDrawerOpen(true)
      return
    }
    setAnalysisError('')
    if (trayImage) {
      handleSubmitAnalysis(trayImage.src)
    }
  }

  /**
   * Handles closing the tray map drawer.
   */
  const handleTrayMapDrawerClose = () => {
    setIsTrayMapDrawerOpen(false)
  }

  /**
   * Handles capturing the tray image.
   */
  const handleImageCapture = (skipRotate?: boolean) => {
    setIsAnalysisComplete(false)
    handleSPDCaptureImage(skipRotate)
  }

  return {
    handleImageCapture,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    isTrayMapDrawerOpen,
    handleTrayMapDrawerClose,
    handleAnalysisClick,
    isSubmitting,
    isAnalysisComplete,
  }
}

export default useAnalyzeTrayLogic
