import type { ProcedureStatus } from 'common/types'
import * as React from 'react'
import { IconButton, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { useGetSurgeriesQuery } from 'lib/apollo/hooks'
import ErrorDisplay from 'components/ErrorDisplay'
import Typography from '@mui/material/Typography/Typography'
import dayjs from 'lib/dayjs'

// @ts-ignore
import { useDisablePolling } from 'lib/useDisablePolling'
import { ProcedureListTable } from './ProcedureListTable'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import CustomDatePicker from 'components/molecules/DatePicker/DatePicker'

const initialBefore = dayjs().endOf('day').toDate()
const initialAfter = dayjs().startOf('day').toDate()

function ProceduresRep() {
  const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const disablePolling = useDisablePolling()
  const [beforeDate, setBeforeDate] = React.useState<Date>(initialBefore)
  const [afterDate, setAfterDate] = React.useState<Date>(initialAfter)
  const [status, setStatus] = React.useState<
    ProcedureStatus['name'] | 'ALL' | 'READY'
  >('ALL')

  const { loading, error, data, refetch } = useGetSurgeriesQuery(
    {
      before: beforeDate,
      after: afterDate,
      status: status === 'ALL' ? undefined : status,
      limit: 0,
    },
    {
      disablePolling,
    }
  )

  const handleBackDay = () => {
    setBeforeDate(dayjs(beforeDate).subtract(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).subtract(1, 'day').toDate())
  }

  const handleForwardDay = () => {
    setBeforeDate(dayjs(beforeDate).add(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).add(1, 'day').toDate())
  }

  const surgeries = data && data.getSurgeries?.results
  const isErrored = !loading && error

  return (
    <>
      <Box
        display="flex"
        alignItems={'flex-start'}
        justifyContent="space-between"
        flexDirection={'column'}
      >
        <Typography variant="h1" fontSize={isMd ? 20 : 24}>
          Sales Rep
        </Typography>
        <Typography variant="h1" fontSize={isMd ? 20 : 24}>
          Invited Procedure List
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isMd ? 'center' : 'space-between'}
        my={2}
        columnGap={4}
        rowGap={2}
        sx={{
          flexDirection: isMd ? 'column-reverse' : 'row',
        }}
      >
        <FormControl
          margin="none"
          sx={isMd ? {} : { width: 300 }}
          fullWidth={isMd}
        >
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            label="Status"
            inputProps={{
              name: 'status',
              id: 'status',
            }}
            sx={{ background: 'white' }}
            value={status}
            onChange={(e) => setStatus(e.target.value as any)}
          >
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="SCANNING">Capturing</MenuItem>
            <MenuItem value="SUBMITTED">Submitted</MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" alignItems="center" columnGap={2}>
          <IconButton onClick={handleBackDay} size="small">
            <NavigateBefore fontSize="large" />
          </IconButton>

          <CustomDatePicker
            label="Procedure List Date"
            date={afterDate}
            renderDatePicker={false}
          />

          <IconButton onClick={handleForwardDay} size="small">
            <NavigateNext fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      {isErrored && <ErrorDisplay pathForward={refetch} />}
      {!isErrored && (
        <ProcedureListTable events={surgeries} loading={loading} />
      )}
    </>
  )
}

export default ProceduresRep
