import * as React from 'react'
import { Box } from '@mui/system'
import { Stack, IconButton } from '@mui/material'
import { Switch } from 'components/Switch/Switch'
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined'
import { FormatType, useCortexDecoderContext } from 'lib/batchScanner'

interface ScannerFormatSwitcherProps {
  noPersist?: boolean
}

export function ScannerFormatSwitcher({
  noPersist = false,
}: ScannerFormatSwitcherProps) {
  const [isSwitchDisabled, setIsSwitchDisabled] = React.useState(false)
  const { formatType, setFormatType, isActive, toggleViewfinderOrientation } =
    useCortexDecoderContext()

  React.useEffect(() => {
    const defaultFormatType = localStorage?.getItem('formatType') as
      | FormatType
      | undefined
    setFormatType(defaultFormatType ?? FormatType['2d'])
  }, [setFormatType])

  const handleSwitchChange = (checked: boolean) => {
    setIsSwitchDisabled(true)
    if (!isSwitchDisabled) {
      const updatedFormatType = checked ? FormatType['1d'] : FormatType['2d']
      if (noPersist === false) {
        localStorage?.setItem('formatType', updatedFormatType)
      }
      setFormatType(updatedFormatType)
      setTimeout(() => setIsSwitchDisabled(false), 2000)
    }
  }

  // We can't render the switch until we have an initial value
  // for formatType or it won't be set properly
  if (!formatType) {
    return null
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      rowGap={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M16 17v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1h1zm5 4h-4v-2h2v-2h2v4zM3 3h8v8H3V3zm2 2v4h4V5H5zm8-2h8v8h-8V3zm2 2v4h4V5h-4zM3 13h8v8H3v-8zm2 2v4h4v-4H5zm13-2h3v2h-3v-2zM6 6h2v2H6V6zm0 10h2v2H6v-2zM16 6h2v2h-2V6z"
            fill="#fff"
          />
        </svg>
        <Switch
          onChange={handleSwitchChange}
          checked={formatType === FormatType['1d']}
          disabled={!isActive || isSwitchDisabled}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z"
            fill="#fff"
          />
        </svg>
        {formatType === FormatType['1d'] && (
          <IconButton
            size="small"
            onClick={toggleViewfinderOrientation}
            style={{
              backgroundColor: '#0247A1',
              marginLeft: 12,
            }}
          >
            <RotateLeftOutlinedIcon htmlColor="#fff" />
          </IconButton>
        )}
      </Stack>
    </Box>
  )
}
