import { Helmet } from 'react-helmet'
import { Box } from '@mui/system'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import { useUser } from 'app/User'
import ProceduresNurse from 'components/ProcedureList/ProceduresNurse'
import ProceduresRep from 'components/ProcedureList/ProceduresRep'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const Procedures: React.FC = () => {
  const { isRep } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    const inviteUrl = localStorage.getItem('inviteUrl')
    if (inviteUrl) {
      localStorage.removeItem('inviteUrl')
      navigate(inviteUrl)
    }
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>Procedures</title>
      </Helmet>
      <PageDisplay>
        <Box px={1.5} pt={1.5}>
          {isRep ? <ProceduresRep /> : <ProceduresNurse />}
        </Box>
      </PageDisplay>
    </>
  )
}

export default Procedures
