import { useLocation } from 'react-router-dom'

export const useIsRouteActive = () => {
  const location = useLocation()
  const parentPath = location.pathname.split('/')[1]
  return {
    isActive: (route: string) =>
      parentPath?.endsWith(route) || location.pathname === route,
  }
}
