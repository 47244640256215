import { useRef, useState, useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import imageCompression from 'browser-image-compression'

// Types
import { MediaUploadModalProps } from './MediaUploadModal.types'
import moment from 'moment'
import {
  isAndroid,
  isIOS,
  isIPad13,
  isMobile,
  isTablet,
} from 'react-device-detect'

const useMediaUploadModalLogic = ({
  isOpen,
  onSave,
}: MediaUploadModalProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const uploadInputRef = useRef<HTMLInputElement>(null)
  const isEmpty = selectedFiles.length === 0
  const isMobileOrTablet =
    isMobile || isTablet || isIPad13 || isAndroid || isIOS

  const handleCaptureFromCamera = async (file: File) => {
    const compressedFile = await compressFile(file)

    setSelectedFiles((prev) => [compressedFile, ...prev])
  }

  const handleDelete = (index: number) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((_, i) => i !== index)
    )
  }

  const handleSave = () => {
    if (selectedFiles.length === 0) {
      toast.error('No images selected')
      return
    }
    onSave(selectedFiles)
  }

  const compressFile = (
    file: File,
    targetSizeKB: number = 100
  ): Promise<File> => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          if (!ctx) {
            return reject(new Error('Failed to get canvas context'))
          }

          const width = img.width
          const height = img.height

          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0, width, height)

          const compress = (quality: number) =>
            new Promise<Blob>((resolveBlob) => {
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    resolveBlob(blob)
                  } else {
                    reject(new Error('Canvas is empty'))
                  }
                },
                'image/jpeg',
                quality
              )
            })

          ;(async () => {
            let quality = 1
            let compressedBlob = await compress(quality)
            while (compressedBlob.size / 1024 > targetSizeKB && quality > 0.1) {
              quality -= 0.1
              compressedBlob = await compress(quality)
            }

            const compressedFile = new File([compressedBlob], file.name, {
              type: 'image/jpeg',
            })
            resolve(compressedFile)
          })()
        }

        if (event.target && event.target.result) {
          img.src = event.target.result as string
        } else {
          reject(new Error('Failed to load image from file'))
        }
      }

      reader.onerror = () => {
        reject(new Error('Failed to read file'))
      }

      reader.readAsDataURL(file)
    })
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const compressedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          try {
            const compressedFile = await imageCompression(file, {
              maxSizeMB: 0.05,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            })

            // Generate a unique timestamp
            const timestamp = moment(new Date())
              .format('MM-DD-yyyy-SSS')
              .replace(/[-:]/g, '')
              .replace('T', '_')
              .split('.')[0]

            const fileName = `image_${timestamp}.${
              compressedFile.type.split('/')[1] || 'jpeg'
            }`

            // Create a new File object with the generated filename
            return new File([compressedFile], fileName, {
              type: compressedFile.type,
            })
          } catch (error) {
            console.error('Error compressing file:', error)
            return file
          }
        })
      )

      setSelectedFiles((prev) => [...compressedFiles, ...prev])
    }
  }

  const openCamera = useCallback(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (uploadInputRef.current) {
          uploadInputRef.current.click()
        }
      }, 100)
      return () => clearTimeout(timer)
    }
  }, [isOpen])

  useEffect(() => {
    openCamera()
  }, [openCamera])

  return {
    isEmpty,
    selectedFiles,
    uploadInputRef,
    isMobileOrTablet,
    openCamera,
    handleFileChange,
    handleDelete,
    handleSave,
    handleCaptureFromCamera,
  }
}

export default useMediaUploadModalLogic
