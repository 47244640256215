import { z } from 'zod'

export const noRepAvailValidationSchema = (data: Record<string, any>) => {
  const validateSchema = z.record(z.string(), z.any()).refine(
    // best for known key values
    () =>
      data.numberOfExpiredHardware !== '0' ||
      data.numberOfExpiredHardware !== undefined,
    {
      message: `The product list contains ${data.numberOfExpiredHardware} expired hardware product(s). Are you sure you want to continue?`,
      path: ['numberOfExpiredHardware'],
    }
  )

  return validateSchema.safeParse(data)
}
