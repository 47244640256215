import { useEffect, useMemo, useState } from 'react'
import { AssetsGroupedBySubCategory, Scan } from 'common/types'
import styles from './deleteSubCategory.module.css'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import Collapse from '@mui/material/Collapse/Collapse'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton/IconButton'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'
import ListItemButton from '@mui/material/ListItemButton/ListItemButton'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import Typography from '@mui/material/Typography/Typography'

import { getCountFromScans } from 'lib/utils/data'

type Props = {
  assetsBySubCategory: any[]
  labelId: string
  finalDisposition: string
  updateAssetIdsToDelete: (allAssetIdsInSubCategory: string[]) => void
  assetIdsToDelete: string[]
  isDisabled: boolean
  assetScans: Scan[]
}

const groupAllAssetIdsInSubCategory = (
  assetsGroupedBySubcategory: AssetsGroupedBySubCategory
) => {
  let combinedAssetIds: string[] = []
  Object.values(assetsGroupedBySubcategory).forEach((assetGroup) => {
    combinedAssetIds.push(...assetGroup.scanIds)
  })
  return combinedAssetIds
}

const compareDescriptionAlphabetically = (a: any[], b: any[]) =>
  a[1].assetDetails.assetDescription > b[1].assetDetails.assetDescription
    ? 1
    : -1

function DeleteSubCategory({
  assetsBySubCategory,
  labelId,
  finalDisposition,
  updateAssetIdsToDelete,
  assetIdsToDelete,
  isDisabled,
  assetScans,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const subCategory = assetsBySubCategory[0]
  const assetData = assetsBySubCategory[1]

  // Asset Ids to delete if checked
  const allAssetIdsInSubCategory = useMemo(
    () => groupAllAssetIdsInSubCategory(assetData),
    [assetData]
  )

  const scansInSubcategory = useMemo(
    () =>
      [...assetScans].filter((scan) =>
        allAssetIdsInSubCategory.includes(scan._id)
      ),
    [allAssetIdsInSubCategory, assetScans]
  )

  const assetGroupCount = useMemo(
    () => getCountFromScans(scansInSubcategory),
    [scansInSubcategory]
  )

  const handleCheckboxToggle = () => {
    const checkedAssets = [...allAssetIdsInSubCategory]

    if (isPartiallyChecked && !isChecked) {
      assetIdsToDelete.forEach((asset) => {
        if (checkedAssets.includes(asset)) {
          return checkedAssets.splice(checkedAssets.indexOf(asset), 1)
        }
      })
    }
    updateAssetIdsToDelete(checkedAssets)
  }

  const toggleOpen = () => {
    if (isDisabled) return
    setIsOpen(!isOpen)
  }

  // Close collapse during delete mutation load or success
  useEffect(() => {
    if (isDisabled) setIsOpen(false)
  }, [isDisabled])

  const expandColor = isDisabled ? 'gray.main' : 'black'

  const isChecked = useMemo(
    () =>
      allAssetIdsInSubCategory.every((assetId) =>
        assetIdsToDelete.includes(assetId)
      ),
    [allAssetIdsInSubCategory, assetIdsToDelete]
  )

  const isPartiallyChecked = useMemo(
    () =>
      allAssetIdsInSubCategory.some((assetId) =>
        assetIdsToDelete.includes(assetId)
      ),
    [allAssetIdsInSubCategory, assetIdsToDelete]
  )

  return (
    <Box sx={{ backgroundColor: isChecked ? 'primary.light' : 'white' }}>
      <ListItem
        disablePadding
        divider={!isOpen}
        secondaryAction={
          <IconButton onClick={toggleOpen} disabled={isDisabled}>
            {isOpen ? (
              <ExpandLess sx={{ color: expandColor }} />
            ) : (
              <ExpandMore sx={{ color: expandColor }} />
            )}
          </IconButton>
        }
      >
        <ListItemButton
          onClick={handleCheckboxToggle}
          sx={{ px: 3.5 }}
          disabled={isDisabled}
        >
          <Checkbox
            edge="start"
            checked={isChecked}
            indeterminate={isPartiallyChecked && !isChecked}
            disabled={isDisabled}
          />
          <ListItemText
            id={labelId}
            primary={`${finalDisposition} - ${subCategory} (${assetGroupCount})`}
            sx={{ textTransform: 'capitalize' }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={isOpen}>
        <List component="div" disablePadding>
          <ListItem sx={{ pl: 4, py: 0 }}>
            <Box sx={{ ml: 2, width: 1 }}>
              {Object.entries(assetData)
                .sort(compareDescriptionAlphabetically)
                .map(([deviceIdentifier, assetObject]: any) => {
                  const assetDetails = assetObject.assetDetails
                  const currentAssetId = assetObject.scanIds
                  const isChildChecked = currentAssetId.every(
                    (assetId: string) => assetIdsToDelete.includes(assetId)
                  )
                  const consumableCount = getCountFromScans(
                    assetData[deviceIdentifier].scans
                  )
                  const assetCount =
                    subCategory === 'Consumable'
                      ? consumableCount
                      : assetObject?.scanIds.length

                  return (
                    <Box
                      key={deviceIdentifier}
                      onClick={() =>
                        updateAssetIdsToDelete(assetObject.scanIds)
                      }
                    >
                      {isOpen && <Divider variant="middle" sx={{ mx: 1 }} />}
                      <Box
                        sx={{
                          my: 0.5,
                          backgroundColor: isChildChecked
                            ? 'primary.light'
                            : 'white',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Checkbox
                            checked={isChildChecked}
                            disabled={isDisabled}
                          />
                          <Box
                            className={styles.count}
                            sx={{
                              backgroundColor: isChecked
                                ? 'grayscale.main'
                                : 'grayscale.lightest',
                              m: 1,
                              p: 0.3,
                            }}
                          >
                            <Typography variant="h3">{assetCount}</Typography>
                          </Box>

                          <Box className={styles.assetOverview}>
                            <Typography variant="h5">
                              {assetDetails.assetDescription}
                              {assetDetails.assetSize &&
                                `, ${assetDetails.assetSize}`}
                            </Typography>
                            {assetDetails.manufacturer && (
                              <Typography
                                variant="subtitle2"
                                sx={{ color: 'text.secondary' }}
                              >{`Manufacturer: ${assetDetails.manufacturer}`}</Typography>
                            )}
                            {assetDetails.modelNumber && (
                              <Typography
                                variant="subtitle2"
                                sx={{ color: 'text.secondary' }}
                              >{`Reference number: ${assetDetails.modelNumber}`}</Typography>
                            )}
                            {deviceIdentifier && (
                              <Typography
                                variant="subtitle2"
                                sx={{ color: 'text.secondary' }}
                              >{`Device identifier: ${deviceIdentifier}`}</Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )
                })}
            </Box>
          </ListItem>
          <Divider light={isDisabled} />
        </List>
      </Collapse>
    </Box>
  )
}

export default DeleteSubCategory
