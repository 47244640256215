import { Typography } from '@mui/material'

import { LoadingButton } from 'components/mui'

import useTrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

import './LoadMultipleScrewsBox.scss'

export const LoadMultipleScrewsBox = ({ open }: { open: boolean }) => {
  const { selectedScrews } = useSPD()
  const { productData } = useSPDScan()
  const { handleConfirmScrewsSelection, handleCancelScrewsSelection } =
    useTrayMap()

  const asset = productData[0]

  return (
    <div className={`load-multiple-screws_box ${open ? 'open' : ''}`}>
      <Typography variant="h3" className="device-description">
        {asset?.deviceDescription}
      </Typography>

      <Typography variant="body1" className="device-description">
        {selectedScrews.length} /{asset?.deviceCount} screws selected
      </Typography>

      <div className="confirmation_buttons-container">
        <LoadingButton
          disabled={false}
          loading={false}
          variant="outlined"
          mode="cancel"
          onClick={handleCancelScrewsSelection}
        >
          Cancel
        </LoadingButton>

        <LoadingButton
          variant="contained"
          disabled={false}
          loading={false}
          onClick={handleConfirmScrewsSelection}
        >
          Confirm
        </LoadingButton>
      </div>
    </div>
  )
}
