import useEditTrayModal from 'components/organisms/EditTrayModal/EditTrayModal.logic'
import { useGetTrayItemByBarcode } from 'lib/apollo/hooks'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useTrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { useTrayConfigurationContext } from '../TrayManagement/TrayManagement.context'
import { Check } from '@mui/icons-material'
import { TrayStatus } from 'components/organisms/EditTrayModal/EditTrayModal.types'

const useTrayApproval = ({ status }: { status?: TrayStatus }) => {
  const navigate = useNavigate()
  const { trayId } = useParams()
  const { pathname } = useLocation()

  const {
    setTrayScrews,
    trayScrews,
    isLoadingWithUDIs,
    setIsLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
    setTrayPlates,
  } = useTrayMap()
  const { loading, error, trayItem, getTrayItem, refetch } =
    useGetTrayItemByBarcode()
  const { updating, handleUpdateTrayItem, success, setSuccess, setTrayStatus } =
    useEditTrayModal({
      trayData: trayItem,
      trayScrews,
    })
  const {
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarState,
  } = useTrayConfigurationContext()

  const [isInEditMode, setIsInEditMode] = useState<boolean>(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)

  const trayDetails: TrayItemProductDetails | undefined = trayItem
    ? (JSON.parse(trayItem.productDetails as string) as TrayItemProductDetails)
    : undefined
  const isLoaner = trayDetails?.trayCategory === 'loaner'

  useEffect(() => {
    setTrayStatus(status)
    setIsLoadingWithUDIs(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (success) {
      setSuccess(false)
      setSnackbarIcon(<Check />)
      setSnackbarState('success')
      setSnackbarMessage('Successfully Approved Tray')
      setSnackbarOpen(true)
      navigate('/tray-management')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  useEffect(() => {
    getTrayItem({ variables: { barcode: trayId as string } })
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId, pathname, status])

  useEffect(() => {
    if (trayId && !loading && !error && trayItem && trayDetails) {
      setTrayScrews(trayDetails?.screws)

      setTrayPlates(trayDetails?.plates || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, loading, trayId, error, trayItem])

  const handleEditModeChange = () => {
    setIsInEditMode((prevMode) => !prevMode)
  }

  const handleViewDetailsClick = () => {
    navigate(`../${trayId}`)
  }

  const handleConfirmationClick = () => {
    setIsConfirmationOpen((prev) => !prev)
  }

  const handleLoadWithUDIsClick = () => {
    setIsLoadingWithUDIs((prev) => !prev)
  }

  return {
    isInEditMode,
    setIsInEditMode,
    handleEditModeChange,
    loading,
    error,
    trayItem,
    trayDetails,
    updating,
    handleUpdateTrayItem,
    handleViewDetailsClick,
    isConfirmationOpen,
    setIsConfirmationOpen,
    handleConfirmationClick,
    handleLoadWithUDIsClick,
    isLoaner,
    isLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
  }
}

export default useTrayApproval
