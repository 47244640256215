import {
  ImplantReportInputs,
  ImplantReportStatus,
  ImplantReportType,
} from 'common/types'
import { useEffect, useState } from 'react'
import { useSendImplantReportEmailMutation } from 'lib/apollo/hooks'
import {
  ImplantReportInputValue,
  ImplantReportSenderModalLogicProps,
} from '../ImplantReportSender.types'

export const useImplantReportSenderModalLogic = ({
  assetIds,
  surgeryId,
  implantReportStatus,
  implantReportInputs,
  setImplantReportInputs,
  unformatEnumValue,
  setMutationSuccess,
  setErrorMessage,
  setInputModalOpen,
}: ImplantReportSenderModalLogicProps) => {
  // Hooks
  const [sendImplantReport, sendImplantReportMutation] =
    useSendImplantReportEmailMutation([implantReportInputs])

  // States
  const [reportAlreadySentModal, setReportAlreadySentModal] =
    useState<boolean>(false)
  const [extractionValues, setExtractionValues] = useState<
    Array<string | number>
  >([''])

  // Constants
  const reportAlreadySent = (option: keyof typeof ImplantReportType) => {
    const enumType = unformatEnumValue(option)
    return (implantReportStatus as ImplantReportStatus[]).some(
      (status) => status.type === enumType && status.isSent
    )
  }

  const getRequiredInputs = (
    reportType: keyof typeof ImplantReportType | undefined
  ): string[] => {
    const requiredInputsMap: {
      [key in keyof typeof ImplantReportType]?: string[]
    } = {
      EXTRACTION: ['extractionValues'],
      FIRST_STAGE: [
        'nextApptDate',
        'nextApptInMonths',
        'secondStageAbutmentType',
      ],
      SINGLE: ['nextApptDate', 'nextApptInMonths'],
      OTHER: ['additionalComments'],
    }

    return requiredInputsMap[reportType as keyof typeof ImplantReportType] || []
  }

  const isExtractionValuesEmpty =
    !extractionValues[0] && implantReportInputs['reportType'] === 'EXTRACTION'

  const loadingButtonDisabled = (() => {
    const requiredInputs = getRequiredInputs(implantReportInputs['reportType'])

    return requiredInputs.some(
      (key) =>
        !implantReportInputs.reportInputs[
          key as keyof typeof implantReportInputs.reportInputs
        ]
    )
  })()

  const doNotRenderSubmitOverride =
    assetIds.length === 0 &&
    implantReportInputs.reportType &&
    ['FIRST_STAGE', 'SINGLE', 'SECOND_STAGE'].includes(
      implantReportInputs.reportType
    )

  // Handlers
  const handleChangeFormData = <Key extends keyof ImplantReportInputs>(
    reportType: Key,
    value: ImplantReportInputValue<Key>
  ) => {
    setImplantReportInputs((prevState) => ({
      ...prevState,
      reportInputs: {
        ...prevState.reportInputs,
        [reportType]: value,
      },
    }))
  }

  const handleAddExtractionInput = () => {
    setExtractionValues([...extractionValues, ''])
  }

  const handleChangeExtractionInput = (index: number, value: string) => {
    if (value.length <= 4) {
      const newValues = [...extractionValues]
      newValues[index] = value
      setExtractionValues(newValues)
      handleChangeFormData('extractionValues', newValues)
    }
  }

  const validateAndSubmit = () => {
    const reportType =
      implantReportInputs.reportType as keyof typeof ImplantReportType
    if (reportAlreadySent(reportType)) {
      setReportAlreadySentModal(true)
      return
    }
    submitReport()
    setMutationSuccess(undefined)
  }

  const submitOverride = () => {
    try {
      setImplantReportInputs((prevState) => ({
        ...prevState,
        submitOverride: true,
      }))
    } catch (error) {
      console.error('Error in submitOverride:', error)
      setMutationSuccess(false)
    }
  }

  // solves async state update on submitOverride flag issue
  useEffect(() => {
    if (implantReportInputs.submitOverride) {
      submitReport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implantReportInputs.submitOverride])

  const handleOnClose = () => {
    setInputModalOpen(false)
    setImplantReportInputs({
      surgeryId,
      reportType: undefined,
      referringPhysicianEmail: implantReportInputs.referringPhysicianEmail,
      referringPhysicianLastName:
        implantReportInputs.referringPhysicianLastName,
      reportInputs: {
        assetIds: assetIds,
      },
      submitOverride: false,
    })
    setMutationSuccess(undefined)
    setExtractionValues([''])
  }

  const submitReport = async () => {
    try {
      setImplantReportInputs((prevState) => ({
        ...prevState,
        reportInputs: {
          ...prevState.reportInputs,
        },
      }))

      const result = await sendImplantReport()

      if (result.data?.sendImplantReportEmail?.success) {
        setMutationSuccess(true)
        setInputModalOpen(false)
        setReportAlreadySentModal(false)
        setImplantReportInputs((prevState) => ({
          ...prevState,
          submitOverride: false,
        }))
      } else {
        setMutationSuccess(false)
        setReportAlreadySentModal(false)
        setErrorMessage(result.data?.sendImplantReportEmail?.message)
      }
    } catch (error) {
      setMutationSuccess(false)
    }
  }

  return {
    extractionValues,
    handleOnClose,
    handleChangeFormData,
    disableSubmit: loadingButtonDisabled || isExtractionValuesEmpty,
    validateAndSubmit,
    sendImplantReportMutation,
    handleAddExtractionInput,
    handleChangeExtractionInput,
    reportAlreadySentModal,
    setReportAlreadySentModal,
    submitReport,
    submitOverride,
    doNotRenderSubmitOverride,
  }
}
