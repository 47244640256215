import { Typography } from '@mui/material'
import { flexBox } from 'common/styles/mixins'
import styled from 'styled-components'

export const MainContainer = styled.div`
  ${flexBox('flex', 'unset', 'unset', 'column', 'unset')}
  align-self: stretch;
  padding: 0.75rem;
  border-top: 1px solid #e0e0e0;
`

export const InfoRow = styled.div`
  ${flexBox('flex', 'space-between', 'center', 'row', 'unset')}
`

export const EmailMobile = styled(Typography)`
  ${flexBox('flex', 'space-between', 'center', 'row', 'unset')}
  padding: 3px 8px;
  border-radius: 4px;
  width: 392px;
`
