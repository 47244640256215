import * as yup from 'yup'

export const manualAssetInputSchema = yup.object().shape({
  deviceDescription: yup.string().min(3).required(),
  companyName: yup.string().min(2).required(),
  deviceId: yup.string().optional(),
  deviceCount: yup.number().required().default(1),
  lotBatch: yup.string().optional(),
  serialNumber: yup.string().optional(),
  manufacturingDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  expirationDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  udi: yup
    .string()
    // .matches(
    //   /(\(\d{1,3}[0-9,a-z,A-Z]{1}?\)[\d,a-z,A-Z]{1,70})|(\+H[A-Z0-9/$]+)|^$/g
    // )
    .optional(),
})
