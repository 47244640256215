import cn from 'classnames'
import styles from './switch.module.css'

interface SwitchProps {
  checked?: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
}

export function Switch({ checked, onChange, disabled }: SwitchProps) {
  return (
    <label
      className={cn(styles.formSwitch, disabled && styles.disabled)}
      onClick={() => {
        if (!disabled) {
          onChange?.(!checked)
        }
      }}
    >
      <input
        type="checkbox"
        checked={Boolean(checked)}
        disabled={disabled}
        readOnly
      />
      <i />
    </label>
  )
}
