import Typography from '@mui/material/Typography/Typography'
import { useUser } from 'app/User'
import { theme } from 'assets/styles/customTheme'
import styles from './ProcedureStatusBar.module.css'
import { Surgery } from 'common/types'

interface Props {
  surgery: Surgery
  height?: number
}

export const procedureStatusBarHeight = 26

function ProcedureStatusBar({
  surgery,
  height = procedureStatusBarHeight,
}: Props) {
  const { isNurse, isNurseAdmin } = useUser()
  const isNurseUser = isNurse || isNurseAdmin

  return (
    <div id="procedure-status-bar" style={{ height: `${height}px` }}>
      <div
        className={styles.root}
        style={{
          height: `${height}px`,
          backgroundColor: theme.palette.primary.lightest,
        }}
      >
        <div className={styles.row}>
          <div className={styles.column}>
            <Typography variant="subtitle2">
              <strong>MRN:</strong> #{surgery.patient.idMR}
            </Typography>
          </div>
          <div className={styles.column}>
            {isNurseUser ? (
              <Typography variant="subtitle2">
                <strong>Patient:</strong>{' '}
                {`${
                  surgery.patient.lastName
                }, ${surgery.patient.firstName.charAt(0)}`}
                .
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcedureStatusBar
