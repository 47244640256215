import { FC, useState, useEffect } from 'react'

// MUI
import { ButtonBase, Typography, Dialog, TextField, Box } from '@mui/material'

// Icons
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

// Types
import { ISelector } from './Selector.types'

// Style
import './Selector.css'
import Button from '../Button/Button'
import { useIsTablet } from 'lib/utils/mediaQueries'
import { DateInput } from 'components/mui'

const Selector: FC<ISelector> = ({
  icon,
  label,
  type = 'selector',
  buttonLabel,
  isOpen,
  selectedValue,
  onChange,
  handleOpen,
  onClose,
  selected,
  disabled,
}) => {
  const isTablet = useIsTablet()
  const [selectedOption, setSelectedOption] = useState<boolean | null>(null)
  const [note, setNote] = useState<string>()

  useEffect(() => {
    // Set initial selected option based on the selected prop
    if (type === 'selector') {
      setSelectedOption(
        selected === true ? true : selected === false ? false : null
      )
    }
  }, [selected, type])

  const handleOptionClick = (option: boolean) => {
    setSelectedOption(option)
    onChange(option)
  }

  const handleSave = () => {
    onChange(note)
    if (onClose) onClose()
  }

  return (
    <div className="selector-container">
      <div className="icon">
        {typeof icon === 'string' ? (
          <img src={icon} alt="Package Integrity" height={45} />
        ) : (
          <Box
            sx={{
              bgcolor: '#f2f2f2',
              borderRadius: '4px',
              p: '2.5px',
            }}
          >
            {icon}
          </Box>
        )}
      </div>
      {type === 'date-picker' && (
        <>
          <ButtonBase
            disableRipple
            className={`button ${disabled ? 'disabled' : ''} `}
            onClick={() => {
              if (!disabled && handleOpen) handleOpen()
            }}
            sx={{
              backgroundColor: `${disabled ? 'var(--gray-2)' : ''}`,
            }}
          >
            <Typography
              sx={{
                color: `${disabled ? 'white' : 'var(--primary)'}`,
              }}
            >
              {buttonLabel}
            </Typography>
          </ButtonBase>
          <div>
            <DateInput
              open={isOpen as boolean}
              onClose={onClose}
              minDate={new Date()}
              id="manufacturingDate"
              name="manufacturingDate"
              variant="outlined"
              fullWidth
              openTo="day"
              label="Select Date"
              value={selectedValue}
              hideTextField
              handleChangeFormData={(e) => onChange(e.target.value)}
            />
          </div>
        </>
      )}
      {type === 'selector' && (
        <div className="selector">
          <div
            className={`option ${
              selectedOption === true ? 'selected-yes' : ''
            }`}
            onClick={() => handleOptionClick(true)}
          >
            <DoneIcon
              sx={{ color: selectedOption === true ? 'white' : 'gray' }}
            />
          </div>
          <div
            className={`option ${
              selectedOption === false ? 'selected-no' : ''
            }`}
            onClick={() => handleOptionClick(false)}
          >
            <CloseIcon
              sx={{ color: selectedOption === false ? 'white' : 'gray' }}
            />
          </div>
        </div>
      )}
      {type === 'text-box' && (
        <>
          <ButtonBase disableRipple className="button" onClick={handleOpen}>
            <Typography sx={{ color: 'var(--primary)' }}>
              {buttonLabel}
            </Typography>
          </ButtonBase>
          <Dialog open={isOpen as boolean} onClose={onClose}>
            <Box
              sx={{
                padding: '20px 20px 10px 20px',
                background: 'white',
                width: isTablet ? '80vw' : '500px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <TextField
                id="outlined-multiline-flexible"
                label="Note"
                multiline
                minRows={3}
                maxRows={8}
                fullWidth
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'end',
                  gap: 1,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  disableRipple
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  disableRipple
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      <>
        <Typography sx={{ color: 'var(--text-black)' }}>{label}</Typography>
      </>
    </div>
  )
}

export default Selector
