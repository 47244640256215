import { BetterIdResult, Scan } from 'common/types'

export interface isDatePastProps {
  date: BetterIdResult['expirationDate'] | Scan['expirationDate'] | Date
}

export const isDatePast = ({ date }: isDatePastProps) => {
  const isValidDate =
    typeof date === 'string' && new Date(date).toString() !== 'Invalid Date'

  let displayValue = isValidDate ? new Date(date).toLocaleDateString() : date

  const expiredDate = isValidDate && new Date(date) < new Date() ? date : null
  const isExpired = Boolean(expiredDate)

  return {
    isDateExpired: isExpired,
    expDate: expiredDate
      ? new Date(expiredDate).toLocaleDateString('en-US', { timeZone: 'UTC' })
      : displayValue,
  }
}
