import { Typography, Box } from '@mui/material'
import type {
  RecordCompanyRep,
  RecordCompanyResult,
  Surgery,
} from 'common/types'
import { RepItem } from './RepItem'

export function CompanyRepsList({
  selectedCompany,
  surgeryId,
}: {
  selectedCompany: RecordCompanyResult
  surgeryId: Surgery['_id']
}) {
  if (selectedCompany.reps.length === 0) {
    return (
      <Box padding={1}>
        <Typography variant="body2" color="error" fontWeight="medium">
          No reps found for {selectedCompany.name}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        maxHeight: 300,
        overflow: 'auto',
        background: 'white',
      }}
      borderRadius={1}
    >
      {selectedCompany?.reps?.map((rep: RecordCompanyRep) => (
        <RepItem
          key={rep?.email}
          rep={rep}
          surgeryId={surgeryId}
          companyName={selectedCompany.name}
        />
      ))}
    </Box>
  )
}
