import { Box, Dialog, Stack } from '@mui/material'
import { CropRotate } from '@mui/icons-material'

import AlertDialog from 'components/AlertDialog/AlertDialog'
import AtomLoadingModal from '../AtomLoadingModal/AtomLoadingModal'

import { useCaptureTrayContext } from '../../CaptureTray.context'
import useSubmitImageDialog from './SubmitImageDialog.logic'
import { TrayFullScreenReloadingProps } from './SubmitImageDialog.types'
import { LoadingButton } from 'components/mui'

const SubmitImageDialog = ({
  open,
  handleClose,
  trayImage,
  setTrayImage,
}: TrayFullScreenReloadingProps) => {
  const { handleSubmit, rotateImage, cancelSubmit } = useSubmitImageDialog({
    trayImage,
    setTrayImage,
  })
  const { isSubmitting, alertData, setAlertData, isAlertOpen, setIsAlertOpen } =
    useCaptureTrayContext()

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          minWidth: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            borderRadius: '50%',
            border: 'none',
            backgroundColor: '#5035ED',
            padding: '10px',
          }}
          onClick={rotateImage}
        >
          <CropRotate sx={{ width: '35px', height: '35px' }} />
        </button>

        <AlertDialog
          isOpen={isAlertOpen}
          mode={alertData.mode}
          description={alertData.description}
          primaryButtonAction={() => {
            setIsAlertOpen(false)
            setAlertData({
              description: '',
              mode: undefined,
            })
            handleClose()
          }}
          zIndex={9000}
        />

        <AtomLoadingModal
          open={isSubmitting}
          onClose={() => null}
          onCancel={cancelSubmit}
        />

        <img
          src={trayImage}
          alt="Tray"
          style={{
            width: '100%',
            maxHeight: '60%',
            objectFit: 'cover',
          }}
        />

        <Stack
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          px="20px"
        >
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            sx={{
              width: 'auto',
            }}
            onClick={handleClose}
          >
            Back
          </LoadingButton>

          <LoadingButton
            disabled={isSubmitting}
            loading={isSubmitting}
            variant="contained"
            sx={{
              width: 'auto',
            }}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default SubmitImageDialog
