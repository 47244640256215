import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import './BreadcrumbHeader.scss'
import { theme } from 'assets/styles/customTheme'
import styled from '@emotion/styled'
import { USAGE_REPORTS } from 'app/ROUTES_CONSTANTS'
import { useIsRouteActive } from 'lib/utils/isRouteActive'

interface BreadcrumbHeaderProps {}

export const BreadcrumbHeader = ({}: BreadcrumbHeaderProps) => {
  const { surgeryId } = useParams()
  const { isActive } = useIsRouteActive()
  const StyledLink = styled(Link)(
    `
 font-size: ${theme.typography.h5.fontSize}; 
    font-weight: ${theme.typography.h5.fontWeight};
    line-height: ${theme.typography.h5.lineHeight};
  `
  )

  return (
    <div className={'breadcrumb-header-container'}>
      <div className={'breadcrumb-left-container'}>
        <div className={'breadcrumb-header-title'}>
          <Typography variant="subtitle1">Reports</Typography>
          <Typography variant="h5">/</Typography>

          <Typography variant="h5">Usage</Typography>
          <Typography variant="h5">/</Typography>

          <Breadcrumbs>
            <StyledLink
              variant="h5"
              underline="hover"
              color="inherit"
              href={`/${USAGE_REPORTS}`}
            >
              Procedures View
            </StyledLink>
            {isActive(`/${USAGE_REPORTS}/${surgeryId}`) && (
              <StyledLink
                variant="h5"
                underline="hover"
                color="inherit"
                href={`${USAGE_REPORTS}/:surgeryId/*`}
              >
                Procedure Details
              </StyledLink>
            )}
          </Breadcrumbs>
        </div>
      </div>
    </div>
  )
}
