import React from 'react'
import {
  LinearProgressProps,
  LinearProgress,
  Box,
  Typography,
  alpha,
  Grid,
} from '@mui/material'
import { Square } from '@mui/icons-material'
import { useDashboardContext } from 'lib/context/DashboardContext'

type OmitLinear = Omit<LinearProgressProps, 'color'>

interface LinearProps extends OmitLinear {
  completedValue: number
  totalValue: number
  remainingValue: number
  label?: 'end' | 'under'
  valueBuffer: number
  valueKey?: string
  valueBufferKey?: string
}

export const LinearProgressBar = ({
  label = 'end',
  valueBuffer,
  valueKey,
  valueBufferKey,
  completedValue,
  totalValue,
  remainingValue,
}: LinearProps) => {
  const { darkMode } = useDashboardContext()

  const [progress, setProgress] = React.useState(0)
  const [buffer, setBuffer] = React.useState(0)

  const parsedProgress = (completedValue / totalValue) * 100
  const parsedBuffer = ((completedValue + valueBuffer) / totalValue) * 100

  React.useEffect(() => {
    if (completedValue === totalValue) {
      setProgress(0)
    } else {
      setProgress(parsedProgress)
      setBuffer(parsedBuffer)
    }
  }, [
    completedValue,
    totalValue,
    remainingValue,
    valueBuffer,
    parsedProgress,
    parsedBuffer,
  ])

  return (
    <Grid
      aria-label="linear-progress-bar-container"
      container
      direction="column"
    >
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box aria-label="linear-progress-bar" sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              variant="buffer"
              value={progress}
              valueBuffer={buffer}
              sx={{
                borderRadius: 5,
                height: 10,
                // '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)':
                //   {
                //     backgroundColor: darkMode ?  alpha('rgb(80, 53, 237)', 0.7)  : 'grayscale.dark',
                //   },
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: darkMode
                    ? 'secondary.main'
                    : 'secondary.dark',
                },
                //  '&.MuiLinearProgress-root , &.MuiLinearProgress-buffer ,&.MuiLinearProgress-dashed, &.MuiLinearProgress-dashedColorPrimary': {
                //   backgroundColor: darkMode ?'secondary.main'  : 'secondary.dark',
                // },
                '& .MuiLinearProgress-root, & .MuiLinearProgress-bar, & .MuiLinearProgress-bar2':
                  {
                    color: darkMode ? 'primary.hover' : 'primary.main',
                    backgroundColor: darkMode
                      ? 'primary.hover'
                      : 'primary.main',
                  },
                '& .MuiLinearProgress-root, & .MuiLinearProgress-dashed ': {
                  backgroundImage: darkMode
                    ? 'linear-gradient(90deg, rgb(223, 217, 252) 50%, transparent 50%)'
                    : 'linear-gradient(90deg, rgb(80, 53, 237), transparent 50%)',
                },
              }}
            />
          </Box>
          {label === 'end' || label === 'under' ? (
            <Box aria-label="progress-bar-end-number" sx={{ minWidth: 35 }}>
              <Typography
                variant="h5"
                color={darkMode ? ' white' : 'grayscale.dark'}
              >
                {`${Math.round(parsedProgress)}%`}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Grid>
      <Grid item aria-label="linear-progress-bar-key" sx={{ mt: 2 }}>
        <Grid
          container
          aria-label="value-key"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Square
            sx={{
              color: darkMode ? 'secondary.main' : 'secondary.dark',
              borderRadius: 5,
              mr: 1,
            }}
          />
          <Typography
            color={darkMode ? 'grayscale.light' : 'grayscale.dark'}
            variant="h4"
            sx={{ letterSpacing: 0.5, textTransform: 'capitalize' }}
          >
            {valueKey}
          </Typography>
        </Grid>
      </Grid>
      {valueBufferKey && (
        <Grid item>
          <Grid
            container
            aria-label="value-buffer-key"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Square
              sx={{
                color: darkMode ? 'primary.hover' : 'primary.main',
                borderRadius: 5,
                mr: 1,
              }}
            />
            <Typography
              color={darkMode ? 'grayscale.light' : 'grayscale.dark'}
              variant="h4"
              sx={{ letterSpacing: 0.5, textTransform: 'capitalize' }}
            >
              {valueBufferKey}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
