import { Search } from '@mui/icons-material'
import QrCode2Icon from '@mui/icons-material/QrCode2'

const AssetDetailsStartIcon = ({
  isManualAddition,
}: {
  isManualAddition: boolean
}) => {
  return isManualAddition ? (
    <Search fontSize="large" />
  ) : (
    <QrCode2Icon fontSize="large" />
  )
}

export default AssetDetailsStartIcon
