import { Surgery } from 'common/types'

export type TRecordSenderAssetDetails = {
  assetTitle: string
  deviceId: string | null
  lotNumber: string | null
  modelNumber: string | null
  expirationDate: string | null
  deviceCount: number
  usedCount: number
}

export type TImplantableOtherRecordSenderProps = {
  bidCompanyId: number
  manufacturerName: string
  surgeryId: Surgery['_id']
  assetDetails: TRecordSenderAssetDetails
}

export type TMainContainerProps = {
  $isRepDataContainer?: boolean
}

export function isIso8601(dateString: string) {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  return iso8601Regex.test(dateString)
}

export function transformDate(dateString: string) {
  if (isIso8601(dateString)) {
    const date = new Date(dateString)

    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0')
    const year = date.getUTCFullYear()

    return `${month}/${day}/${year}`
  } else {
    return dateString
  }
}
