import CaptureCamera from './CaptureCamera/CaptureCamera'

const CaptureTray = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <CaptureCamera />
    </div>
  )
}

export default CaptureTray
