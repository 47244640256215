import * as React from 'react'
import { AlertTypes, GenericObject } from 'common/types'
import Mask from 'components/Mask'
import PaperModal from 'components/PaperModal/PaperModal'
import { Box, Typography, Grid, Modal, Button } from '@mui/material'
import { Check, ErrorOutline, NotificationsNone } from '@mui/icons-material'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'

export type ModeConfigProps = {
  [key in AlertTypes]: {
    backgroundColor: string
    textColor: string
    textDescriptionColor?: string
    hover: string
    icon: React.ReactNode
    label?: AlertTypes
  }
}

export const modeConfig = {
  primary: {
    backgroundColor: 'primary.lightest',
    textColor: 'primary.contrastText',
    hover: 'primary.hover',
    icon: NotificationsNone,
  },
  error: {
    backgroundColor: 'error.lightest',
    textColor: 'error.contrastText',
    hover: 'error.hover',
    icon: ErrorOutline,
  },
  errorInfo: {
    label: 'error',
    backgroundColor: 'grayscale.lightest',
    textColor: 'error.contrastText',
    hover: 'error.hover',
    icon: ErrorOutline,
  },
  warning: {
    backgroundColor: 'warning.lightest',
    textColor: 'warning.contrastText',
    hover: 'warning.hover',
    icon: ErrorOutline,
  },
  info: {
    label: 'primary',
    backgroundColor: 'grayscale.lightest',
    textColor: 'primary.dark',
    textDescriptionColor: 'primary.contrastText', // or 'grayscale.darkest'
    hover: 'primary.hover',
    icon: NotificationsNone,
  },
  success: {
    backgroundColor: 'grayscale.lightest', // or 'grayscale.white'
    textColor: 'success.dark',
    hover: 'success.hover',
    icon: Check,
  },
  // @TODO: make sure this correct, added to make TS happy
} as ModeConfigProps

interface AlertDialogProps {
  isOpen: boolean
  mode?: AlertTypes
  title?: string
  description?: string | React.ReactNode
  positionAboveBottomNavigation?: boolean
  position?: GenericObject
  isPrimaryDisabled?: boolean // require both disabled and loading state booleans so there are no style overlaps
  isPrimaryLoading?: boolean
  primaryButtonAction: () => void
  primaryButtonText?: string
  isSecondaryDisabled?: boolean
  isSecondaryLoading?: boolean
  secondaryButtonAction?: () => void
  secondaryButtonText?: string
  zIndex?: number
  children?: React.ReactNode
  loadingText?: string
  showIcon?: boolean
  dismissable?: boolean
  dataTestIdPrimary?: string
  dataTestIdSecondary?: string
}

/**
 * Perhaps we rename this component to: AsyncAlertDialog?
 * @param param0
 * @returns
 */
function AlertDialog({
  isOpen,
  mode = 'error',
  title = 'Something went wrong',
  description = 'An error was encountered while searching for the product.',
  positionAboveBottomNavigation = false,
  position = undefined,
  isPrimaryDisabled = false,
  isPrimaryLoading = false,
  primaryButtonAction,
  primaryButtonText = 'Retry',
  isSecondaryDisabled = false,
  secondaryButtonAction,
  secondaryButtonText = 'Cancel',
  zIndex,
  children,
  loadingText,
  showIcon = true,
  dismissable = true,
  dataTestIdPrimary,
  dataTestIdSecondary,
}: AlertDialogProps) {
  const Icon = mode && modeConfig[mode].icon

  const loadingButtonMode = () => {
    if (mode === 'primary' || mode === 'info') {
      return 'default'
    } else if (mode === 'success') {
      return 'success'
    } else {
      return 'cancel'
    }
  }

  const content = (
    <PaperModal
      backgroundColor={modeConfig[mode].backgroundColor}
      positionAboveBottomNavigation={positionAboveBottomNavigation}
      position={position}
    >
      <Box sx={{ display: 'flex' }} zIndex={(zIndex ?? 1) + 1}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          aria-label="main-container"
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            aria-label="title-container"
          >
            <Grid item>
              {/* @ts-ignore error due to ts wanting call signature */}
              <Icon color={modeConfig[mode].label ?? mode} sx={{ mr: 1 }} />
            </Grid>
            <Grid item>
              <Typography
                sx={{ mt: 0.3, mb: 1 }}
                variant="h3"
                color={modeConfig[mode].textColor}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            sx={{ pl: 4 }}
            aria-label="body-container"
          >
            <Grid item>
              <Typography
                variant="h6"
                color={
                  modeConfig[mode].textDescriptionColor ??
                  modeConfig[mode].textColor
                }
                sx={{ lineHeight: 1.3, letterSpacing: 0.3 }}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
          {children ? <Grid container>{children}</Grid> : null}
          <Grid
            container
            justifyContent="flex-start"
            direction={'row-reverse'}
            alignItems="flex-end"
            sx={{ mt: 1 }}
          >
            <Grid
              item
              sx={{
                ml: 1,
              }}
            >
              <LoadingButton
                data-testid={dataTestIdPrimary}
                mode={loadingButtonMode()}
                variant="contained"
                disableElevation
                sx={{ letterSpacing: 0.7 }}
                onClick={primaryButtonAction}
                disabled={isPrimaryDisabled}
                loadingText={loadingText}
                loading={isPrimaryLoading}
                showIcon={showIcon}
              >
                {primaryButtonText}
              </LoadingButton>
            </Grid>
            {secondaryButtonAction && (
              <Grid item>
                <Button
                  data-testid={dataTestIdSecondary}
                  variant="outlined"
                  sx={{
                    border: 'none',
                    letterSpacing: 0.7,
                    padding: '6px 16px 6px 16px',
                    height: 36.5,
                  }}
                  onClick={secondaryButtonAction}
                  disabled={isSecondaryDisabled}
                >
                  {secondaryButtonText}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </PaperModal>
  )

  return dismissable ? (
    <Mask open={isOpen} zIndex={zIndex}>
      {content}
    </Mask>
  ) : (
    <Modal open={isOpen}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {content}
      </Box>
    </Modal>
  )
}

export default AlertDialog
