import { Stack, Typography } from '@mui/material'
import { IntegrationInstructions, NotInterested } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'

import { TrayVisualizationModal } from '../../TrayVisualization.types'
import './HelpModal.scss'

export const renderGuideline = (
  label: string,
  backgroundColor: string,
  border: string
) => (
  <Stack
    width="95%"
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    mt={2}
  >
    <Typography
      width="60%"
      fontSize={16}
      fontWeight={400}
      color="#11142D"
      textAlign="left"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {label}
    </Typography>
    <div
      style={{
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        backgroundColor,
        border,
        marginLeft: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation:
          backgroundColor === '#36FFF8'
            ? 'neon 0.5s infinite alternate'
            : 'none',
      }}
    >
      {label.toLowerCase().includes('disabled') && (
        <NotInterested
          sx={{
            width: '15px',
            height: '15px',
            color: 'black',
          }}
        />
      )}
    </div>
  </Stack>
)

const HelpModal = ({ open, onClose }: TrayVisualizationModal) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="tray-visualization-help_modal">
        <div className="header-box">
          <IntegrationInstructions />

          <Typography variant="h3">Guideline</Typography>
        </div>

        {renderGuideline('- Implanted screws:', 'black', '0.5px solid black')}
        {renderGuideline('- Wasted screws:', 'black', '2px solid red')}
        {renderGuideline('- Selected screw:', '#36FFF8', '0.5px solid black')}
        {renderGuideline(
          '- Disabled screw:',
          'var(--grayscale-disabled)',
          '0.5px solid black'
        )}
      </div>
    </Modal>
  )
}

export default HelpModal
