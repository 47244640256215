import { ChangeEvent, FC } from 'react'
import {
  CircularProgress,
  DialogProps,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { QrCode2 } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import useTrayMap from '../../../views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import SPDCortexScanner from '../../../views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner'
import { useSPDScan } from '../../../views/SPDLayout/SPD/Scan/Scan.context'
import { formatDate } from 'lib/utils/formatDate'

import './ConfirmScrewLoadModal.scss'

const ConfirmScrewLoadModal: FC<DialogProps> = ({ open, onClose }) => {
  const { handleLoadScrew, handleCancelSelection } = useTrayMap()
  const {
    setScannedCode,
    lookupQuery,
    productData,
    manualCode,
    setManualCode,
  } = useSPDScan()

  return (
    <Modal open={open} onClose={onClose}>
      <div className="confirm-load-screw_modal">
        <div className="header-box">
          <QrCode2 />

          <Typography variant="h3">Document the product's code</Typography>
        </div>

        <div className="scanner-container">
          <SPDCortexScanner
            canScan={open}
            onScan={(code) => {
              if (code.length > 0) {
                setManualCode(code)
              }
              setScannedCode(code)
            }}
          />

          <Skeleton
            className="skeleton"
            variant="rounded"
            width="100%"
            height="100%"
            animation="wave"
          />
        </div>

        <TextField
          fullWidth
          placeholder="Product UDI"
          autoComplete="off"
          type="text"
          variant="outlined"
          value={manualCode}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setManualCode(e.target.value)
          }
        />

        {lookupQuery.loading && (
          <div className="scanned-code_wrapper">
            <span className="scanned-text_wrapper">
              <CircularProgress size="0.8em" className="spinner" />
              <p className="scanned-text">Searching for product</p>
            </span>
          </div>
        )}

        {lookupQuery.error && !lookupQuery.loading && (
          <Typography variant="body2" className="error">
            {lookupQuery.error.message}
          </Typography>
        )}

        {productData.length > 0 &&
          !lookupQuery.loading &&
          !lookupQuery.error && (
            <>
              <div className="product-container">
                <Typography variant="h2" className="description">
                  {productData[0].deviceDescription}
                </Typography>
                <Typography variant="body1" className="details">
                  Company: {productData[0].company?.name}
                </Typography>
                <Typography variant="body1" className="details">
                  Expires at:{' '}
                  {formatDate(productData[0].expirationDate as string)}
                </Typography>
                <Typography variant="body1" className="details">
                  Device count: {productData[0].deviceCount}
                </Typography>
              </div>

              <div className="buttons_container">
                <LoadingButton
                  disabled={false}
                  loading={false}
                  variant="outlined"
                  onClick={handleCancelSelection}
                >
                  Cancel
                </LoadingButton>

                <LoadingButton
                  disabled={false}
                  loading={false}
                  variant="contained"
                  onClick={handleLoadScrew}
                >
                  Confirm
                </LoadingButton>
              </div>
            </>
          )}
      </div>
    </Modal>
  )
}

export default ConfirmScrewLoadModal
