import { useEffect } from 'react'

import { useSurgeryItemsQuery } from 'lib/apollo/hooks'
import { useAssignedDigitalTrays } from './AssignedDigitalTrays.context'

import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'

const useAssignedDigitalTraysLogic = () => {
  const { trays, setTrays, surgeryId } = useAssignedDigitalTrays()
  const { loading, error, surgeryItems } = useSurgeryItemsQuery(
    surgeryId as string
  )

  const filteredTrays = trays.filter((surgeryTray) => {
    const trayProductDetails = extractTrayJSONData(surgeryTray)

    // If tray is consigned or loaned, it needs to be approved before showing it to the user
    if (trayProductDetails?.trayCategory !== 'facility') {
      return trayProductDetails?.status === 'approved'
    }

    return true
  })

  useEffect(() => {
    if (!loading && !error) {
      setTrays(surgeryItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return {
    trays,
    setTrays,
    surgeryId,
    loading,
    error,
    surgeryItems,
    filteredTrays,
  }
}

export default useAssignedDigitalTraysLogic
