import { CustomIcon } from 'components/templates/CustomIcon'

type DigitalTrayIconProps = {
  active: boolean
  onClick?: () => void
}

export const DigitalTrayIcon = ({ active, onClick }: DigitalTrayIconProps) => (
  <CustomIcon
    active={active}
    addStatusColor={false}
    onClick={onClick}
    width="173.388"
    height="142.136"
    viewBox="0 0 173.388 142.136"
  >
    <g
      id="Digital_Hardware_Tray"
      data-name="Digital Hardware Tray"
      transform="translate(-17.633 -9.954)"
    >
      <g id="Group_11884" data-name="Group 11884">
        <path
          id="Path_16698"
          data-name="Path 16698"
          d="M24.544,133.8V27.711a7.966,7.966,0,0,1,7.743-8.171H174.4a8.1,8.1,0,0,0,5.877-2.568l.594-.627a7.845,7.845,0,0,1,5.694-2.489h4.7a5.667,5.667,0,0,1,5.509,5.813V59.174l-2.912,4.3v45.779l4.077,5.223V149.95a5.892,5.892,0,0,1-5.727,6.043h-3.576a7.205,7.205,0,0,1-7-7.39v-2.115a6.346,6.346,0,0,0-6.169-6.509H30.4a6.024,6.024,0,0,1-5.857-6.179"
          transform="translate(-6.911 -3.902)"
          fill="#a6a9af"
        />
        <path
          id="Path_16699"
          data-name="Path 16699"
          d="M184.852,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-50.001 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16700"
          data-name="Path 16700"
          d="M199.1,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-54.013 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16701"
          data-name="Path 16701"
          d="M213.343,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-58.024 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16702"
          data-name="Path 16702"
          d="M227.588,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-62.035 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16703"
          data-name="Path 16703"
          d="M241.833,44.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-66.046 -11.531)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_2756"
          data-name="Rectangle 2756"
          width="47.551"
          height="2.995"
          transform="translate(127.902 31.561)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16704"
          data-name="Path 16704"
          d="M184.852,117.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-50.001 -32.023)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16705"
          data-name="Path 16705"
          d="M199.1,117.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-54.013 -32.023)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16706"
          data-name="Path 16706"
          d="M213.343,117.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-58.024 -32.023)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16707"
          data-name="Path 16707"
          d="M227.588,117.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-62.035 -32.023)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16708"
          data-name="Path 16708"
          d="M241.833,117.363a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-66.046 -32.023)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_2757"
          data-name="Rectangle 2757"
          width="47.551"
          height="2.995"
          transform="translate(127.902 83.843)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16709"
          data-name="Path 16709"
          d="M106.238,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-27.865 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16710"
          data-name="Path 16710"
          d="M120.484,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-31.876 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16711"
          data-name="Path 16711"
          d="M134.729,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-35.888 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16712"
          data-name="Path 16712"
          d="M148.974,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-39.899 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16713"
          data-name="Path 16713"
          d="M163.22,44.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-43.91 -11.531)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_2758"
          data-name="Rectangle 2758"
          width="47.551"
          height="2.995"
          transform="translate(71.426 31.561)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16714"
          data-name="Path 16714"
          d="M33.479,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-7.377 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16715"
          data-name="Path 16715"
          d="M47.724,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-11.388 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16716"
          data-name="Path 16716"
          d="M61.969,44.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-15.4 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16717"
          data-name="Path 16717"
          d="M76.215,44.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-19.411 -11.531)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16718"
          data-name="Path 16718"
          d="M90.46,44.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-23.422 -11.531)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_2759"
          data-name="Rectangle 2759"
          width="55.059"
          height="2.995"
          transform="translate(19.154 31.561)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16719"
          data-name="Path 16719"
          d="M106.238,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-27.865 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16720"
          data-name="Path 16720"
          d="M120.484,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-31.876 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16721"
          data-name="Path 16721"
          d="M134.729,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-35.888 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16722"
          data-name="Path 16722"
          d="M148.974,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-39.899 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16723"
          data-name="Path 16723"
          d="M163.22,118.816a3.64,3.64,0,1,1-3.639-3.64,3.639,3.639,0,0,1,3.639,3.64"
          transform="translate(-43.91 -32.432)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_2760"
          data-name="Rectangle 2760"
          width="47.551"
          height="2.995"
          transform="translate(71.426 84.887)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16724"
          data-name="Path 16724"
          d="M33.479,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-7.377 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16725"
          data-name="Path 16725"
          d="M47.724,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-11.388 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16726"
          data-name="Path 16726"
          d="M61.969,118.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-15.4 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16727"
          data-name="Path 16727"
          d="M76.215,118.816a3.64,3.64,0,1,1-3.639-3.64,3.639,3.639,0,0,1,3.639,3.64"
          transform="translate(-19.411 -32.432)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16728"
          data-name="Path 16728"
          d="M90.46,118.816a3.64,3.64,0,1,1-3.639-3.64,3.639,3.639,0,0,1,3.639,3.64"
          transform="translate(-23.422 -32.432)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_2761"
          data-name="Rectangle 2761"
          width="55.059"
          height="2.995"
          transform="translate(19.154 84.887)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16729"
          data-name="Path 16729"
          d="M107.084,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16730"
          data-name="Path 16730"
          d="M107.084,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16731"
          data-name="Path 16731"
          d="M107.084,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16732"
          data-name="Path 16732"
          d="M107.084,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16733"
          data-name="Path 16733"
          d="M107.084,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16734"
          data-name="Path 16734"
          d="M121.279,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16735"
          data-name="Path 16735"
          d="M121.279,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16736"
          data-name="Path 16736"
          d="M121.279,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16737"
          data-name="Path 16737"
          d="M121.279,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16738"
          data-name="Path 16738"
          d="M121.279,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16739"
          data-name="Path 16739"
          d="M135.475,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16740"
          data-name="Path 16740"
          d="M135.475,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16741"
          data-name="Path 16741"
          d="M135.475,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16742"
          data-name="Path 16742"
          d="M135.475,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16743"
          data-name="Path 16743"
          d="M135.475,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16744"
          data-name="Path 16744"
          d="M149.67,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16745"
          data-name="Path 16745"
          d="M149.67,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16746"
          data-name="Path 16746"
          d="M149.67,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16747"
          data-name="Path 16747"
          d="M149.67,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16748"
          data-name="Path 16748"
          d="M149.67,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16749"
          data-name="Path 16749"
          d="M163.866,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16750"
          data-name="Path 16750"
          d="M163.866,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16751"
          data-name="Path 16751"
          d="M163.866,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16752"
          data-name="Path 16752"
          d="M163.866,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16753"
          data-name="Path 16753"
          d="M163.866,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16754"
          data-name="Path 16754"
          d="M34.679,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16755"
          data-name="Path 16755"
          d="M34.679,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16756"
          data-name="Path 16756"
          d="M34.679,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16757"
          data-name="Path 16757"
          d="M34.679,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16758"
          data-name="Path 16758"
          d="M34.679,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16759"
          data-name="Path 16759"
          d="M48.875,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16760"
          data-name="Path 16760"
          d="M48.875,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16761"
          data-name="Path 16761"
          d="M48.875,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16762"
          data-name="Path 16762"
          d="M48.875,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16763"
          data-name="Path 16763"
          d="M48.875,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16764"
          data-name="Path 16764"
          d="M63.07,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16765"
          data-name="Path 16765"
          d="M63.07,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16766"
          data-name="Path 16766"
          d="M63.07,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16767"
          data-name="Path 16767"
          d="M63.07,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16768"
          data-name="Path 16768"
          d="M63.07,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16769"
          data-name="Path 16769"
          d="M77.266,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16770"
          data-name="Path 16770"
          d="M77.266,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16771"
          data-name="Path 16771"
          d="M77.266,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16772"
          data-name="Path 16772"
          d="M77.266,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16773"
          data-name="Path 16773"
          d="M77.266,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16774"
          data-name="Path 16774"
          d="M91.461,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16775"
          data-name="Path 16775"
          d="M91.461,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16776"
          data-name="Path 16776"
          d="M91.461,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16777"
          data-name="Path 16777"
          d="M91.461,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16778"
          data-name="Path 16778"
          d="M91.461,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16779"
          data-name="Path 16779"
          d="M107.084,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16780"
          data-name="Path 16780"
          d="M107.084,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16781"
          data-name="Path 16781"
          d="M107.084,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16782"
          data-name="Path 16782"
          d="M107.084,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16783"
          data-name="Path 16783"
          d="M107.084,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.635 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16784"
          data-name="Path 16784"
          d="M121.279,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16785"
          data-name="Path 16785"
          d="M121.279,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16786"
          data-name="Path 16786"
          d="M121.279,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16787"
          data-name="Path 16787"
          d="M121.279,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16788"
          data-name="Path 16788"
          d="M121.279,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.632 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16789"
          data-name="Path 16789"
          d="M135.475,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16790"
          data-name="Path 16790"
          d="M135.475,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16791"
          data-name="Path 16791"
          d="M135.475,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16792"
          data-name="Path 16792"
          d="M135.475,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16793"
          data-name="Path 16793"
          d="M135.475,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.63 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16794"
          data-name="Path 16794"
          d="M149.67,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16795"
          data-name="Path 16795"
          d="M149.67,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16796"
          data-name="Path 16796"
          d="M149.67,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16797"
          data-name="Path 16797"
          d="M149.67,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16798"
          data-name="Path 16798"
          d="M149.67,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.627 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16799"
          data-name="Path 16799"
          d="M163.866,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16800"
          data-name="Path 16800"
          d="M163.866,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16801"
          data-name="Path 16801"
          d="M163.866,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16802"
          data-name="Path 16802"
          d="M163.866,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16803"
          data-name="Path 16803"
          d="M163.866,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.624 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16804"
          data-name="Path 16804"
          d="M34.679,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16805"
          data-name="Path 16805"
          d="M34.679,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16806"
          data-name="Path 16806"
          d="M34.679,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16807"
          data-name="Path 16807"
          d="M34.679,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16808"
          data-name="Path 16808"
          d="M34.679,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.247 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16809"
          data-name="Path 16809"
          d="M48.875,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16810"
          data-name="Path 16810"
          d="M48.875,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16811"
          data-name="Path 16811"
          d="M48.875,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16812"
          data-name="Path 16812"
          d="M48.875,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16813"
          data-name="Path 16813"
          d="M48.875,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.244 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16814"
          data-name="Path 16814"
          d="M63.07,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16815"
          data-name="Path 16815"
          d="M63.07,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16816"
          data-name="Path 16816"
          d="M63.07,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16817"
          data-name="Path 16817"
          d="M63.07,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16818"
          data-name="Path 16818"
          d="M63.07,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.241 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16819"
          data-name="Path 16819"
          d="M77.266,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16820"
          data-name="Path 16820"
          d="M77.266,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16821"
          data-name="Path 16821"
          d="M77.266,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16822"
          data-name="Path 16822"
          d="M77.266,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16823"
          data-name="Path 16823"
          d="M77.266,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.239 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16824"
          data-name="Path 16824"
          d="M91.461,59.353a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -15.954)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16825"
          data-name="Path 16825"
          d="M91.461,70.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -19.185)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16826"
          data-name="Path 16826"
          d="M91.461,82.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -22.417)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16827"
          data-name="Path 16827"
          d="M91.461,93.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -25.649)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16828"
          data-name="Path 16828"
          d="M91.461,105.261a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.236 -28.881)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16829"
          data-name="Path 16829"
          d="M184.258,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16830"
          data-name="Path 16830"
          d="M184.258,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16831"
          data-name="Path 16831"
          d="M184.258,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16832"
          data-name="Path 16832"
          d="M184.258,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16833"
          data-name="Path 16833"
          d="M184.258,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16834"
          data-name="Path 16834"
          d="M198.454,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16835"
          data-name="Path 16835"
          d="M198.454,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16836"
          data-name="Path 16836"
          d="M198.454,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16837"
          data-name="Path 16837"
          d="M198.454,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16838"
          data-name="Path 16838"
          d="M198.454,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16839"
          data-name="Path 16839"
          d="M212.649,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16840"
          data-name="Path 16840"
          d="M212.649,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16841"
          data-name="Path 16841"
          d="M212.649,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16842"
          data-name="Path 16842"
          d="M212.649,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16843"
          data-name="Path 16843"
          d="M212.649,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16844"
          data-name="Path 16844"
          d="M226.845,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16845"
          data-name="Path 16845"
          d="M226.845,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16846"
          data-name="Path 16846"
          d="M226.845,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16847"
          data-name="Path 16847"
          d="M226.845,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16848"
          data-name="Path 16848"
          d="M226.845,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16849"
          data-name="Path 16849"
          d="M241.04,131.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -36.142)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16850"
          data-name="Path 16850"
          d="M241.04,142.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -39.373)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16851"
          data-name="Path 16851"
          d="M241.04,154a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -42.605)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16852"
          data-name="Path 16852"
          d="M241.04,165.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -45.837)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16853"
          data-name="Path 16853"
          d="M241.04,176.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -49.069)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16854"
          data-name="Path 16854"
          d="M184.258,58.573a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -15.734)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16855"
          data-name="Path 16855"
          d="M184.258,70.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -18.966)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16856"
          data-name="Path 16856"
          d="M184.258,81.529a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -22.198)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16857"
          data-name="Path 16857"
          d="M184.258,93.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -25.43)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16858"
          data-name="Path 16858"
          d="M184.258,104.482a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.366 -28.662)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16859"
          data-name="Path 16859"
          d="M198.454,58.573a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -15.734)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16860"
          data-name="Path 16860"
          d="M198.454,70.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -18.966)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16861"
          data-name="Path 16861"
          d="M198.454,81.529a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -22.198)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16862"
          data-name="Path 16862"
          d="M198.454,93.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -25.43)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16863"
          data-name="Path 16863"
          d="M198.454,104.482a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.363 -28.662)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16864"
          data-name="Path 16864"
          d="M212.649,58.573a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -15.734)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16865"
          data-name="Path 16865"
          d="M212.649,70.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -18.966)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16866"
          data-name="Path 16866"
          d="M212.649,81.529a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -22.198)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16867"
          data-name="Path 16867"
          d="M212.649,93.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -25.43)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16868"
          data-name="Path 16868"
          d="M212.649,104.482a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.361 -28.662)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16869"
          data-name="Path 16869"
          d="M226.845,58.573a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -15.734)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16870"
          data-name="Path 16870"
          d="M226.845,70.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -18.966)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16871"
          data-name="Path 16871"
          d="M226.845,81.529a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -22.198)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16872"
          data-name="Path 16872"
          d="M226.845,93.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -25.43)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16873"
          data-name="Path 16873"
          d="M226.845,104.482a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.358 -28.662)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16874"
          data-name="Path 16874"
          d="M241.04,58.573a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -15.734)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16875"
          data-name="Path 16875"
          d="M241.04,70.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -18.966)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16876"
          data-name="Path 16876"
          d="M241.04,81.529a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -22.198)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16877"
          data-name="Path 16877"
          d="M241.04,93.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -25.43)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16878"
          data-name="Path 16878"
          d="M241.04,104.482a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.355 -28.662)"
          fill="#bfbfbf"
        />
        <path
          id="Path_16879"
          data-name="Path 16879"
          d="M185.852,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-50.283 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16880"
          data-name="Path 16880"
          d="M200.1,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-54.294 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16881"
          data-name="Path 16881"
          d="M214.343,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-58.306 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16882"
          data-name="Path 16882"
          d="M228.588,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-62.317 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16883"
          data-name="Path 16883"
          d="M242.833,43.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-66.328 -11.249)"
          fill="#4c36e4"
        />
        <rect
          id="Rectangle_2762"
          data-name="Rectangle 2762"
          width="47.551"
          height="2.995"
          transform="translate(128.621 30.843)"
          fill="#4c36e4"
        />
        <path
          id="Path_16884"
          data-name="Path 16884"
          d="M185.852,116.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-50.283 -31.741)"
          fill="#4c36e4"
        />
        <path
          id="Path_16885"
          data-name="Path 16885"
          d="M200.1,116.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-54.294 -31.741)"
          fill="#4c36e4"
        />
        <path
          id="Path_16886"
          data-name="Path 16886"
          d="M214.343,116.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-58.306 -31.741)"
          fill="#4c36e4"
        />
        <path
          id="Path_16887"
          data-name="Path 16887"
          d="M228.588,116.363a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-62.317 -31.741)"
          fill="#4c36e4"
        />
        <path
          id="Path_16888"
          data-name="Path 16888"
          d="M242.833,116.363a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-66.328 -31.741)"
          fill="#4c36e4"
        />
        <rect
          id="Rectangle_2763"
          data-name="Rectangle 2763"
          width="47.551"
          height="2.995"
          transform="translate(128.621 83.125)"
          fill="#4c36e4"
        />
        <path
          id="Path_16889"
          data-name="Path 16889"
          d="M107.238,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-28.147 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16890"
          data-name="Path 16890"
          d="M121.484,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-32.158 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16891"
          data-name="Path 16891"
          d="M135.729,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-36.169 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16892"
          data-name="Path 16892"
          d="M149.974,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-40.18 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16893"
          data-name="Path 16893"
          d="M164.22,43.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-44.192 -11.249)"
          fill="#4c36e4"
        />
        <rect
          id="Rectangle_2764"
          data-name="Rectangle 2764"
          width="47.551"
          height="2.995"
          transform="translate(72.144 30.843)"
          fill="#4c36e4"
        />
        <path
          id="Path_16894"
          data-name="Path 16894"
          d="M34.479,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-7.659 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16895"
          data-name="Path 16895"
          d="M48.724,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-11.67 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16896"
          data-name="Path 16896"
          d="M62.969,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-15.681 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16897"
          data-name="Path 16897"
          d="M77.215,43.59a3.64,3.64,0,1,1-3.639-3.64,3.64,3.64,0,0,1,3.639,3.64"
          transform="translate(-19.693 -11.249)"
          fill="#4c36e4"
        />
        <path
          id="Path_16898"
          data-name="Path 16898"
          d="M91.46,43.59a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-23.704 -11.249)"
          fill="#4c36e4"
        />
        <rect
          id="Rectangle_2765"
          data-name="Rectangle 2765"
          width="55.059"
          height="2.995"
          transform="translate(19.873 30.843)"
          fill="#4c36e4"
        />
        <path
          id="Path_16899"
          data-name="Path 16899"
          d="M107.238,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-28.147 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16900"
          data-name="Path 16900"
          d="M121.484,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-32.158 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16901"
          data-name="Path 16901"
          d="M135.729,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-36.169 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16902"
          data-name="Path 16902"
          d="M149.974,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-40.18 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16903"
          data-name="Path 16903"
          d="M164.22,117.816a3.64,3.64,0,1,1-3.639-3.64,3.639,3.639,0,0,1,3.639,3.64"
          transform="translate(-44.192 -32.15)"
          fill="#4c36e4"
        />
        <rect
          id="Rectangle_2766"
          data-name="Rectangle 2766"
          width="47.551"
          height="2.995"
          transform="translate(72.144 84.169)"
          fill="#4c36e4"
        />
        <path
          id="Path_16904"
          data-name="Path 16904"
          d="M34.479,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-7.659 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16905"
          data-name="Path 16905"
          d="M48.724,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-11.67 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16906"
          data-name="Path 16906"
          d="M62.969,117.816a3.64,3.64,0,1,1-3.64-3.64,3.64,3.64,0,0,1,3.64,3.64"
          transform="translate(-15.681 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16907"
          data-name="Path 16907"
          d="M77.215,117.816a3.64,3.64,0,1,1-3.639-3.64,3.639,3.639,0,0,1,3.639,3.64"
          transform="translate(-19.693 -32.15)"
          fill="#4c36e4"
        />
        <path
          id="Path_16908"
          data-name="Path 16908"
          d="M91.46,117.816a3.64,3.64,0,1,1-3.64-3.64,3.639,3.639,0,0,1,3.64,3.64"
          transform="translate(-23.704 -32.15)"
          fill="#4c36e4"
        />
        <rect
          id="Rectangle_2767"
          data-name="Rectangle 2767"
          width="55.059"
          height="2.995"
          transform="translate(19.873 84.169)"
          fill="#4c36e4"
        />
        <path
          id="Path_16909"
          data-name="Path 16909"
          d="M108.084,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16910"
          data-name="Path 16910"
          d="M108.084,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16911"
          data-name="Path 16911"
          d="M108.084,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16912"
          data-name="Path 16912"
          d="M108.084,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16913"
          data-name="Path 16913"
          d="M108.084,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16914"
          data-name="Path 16914"
          d="M122.279,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16915"
          data-name="Path 16915"
          d="M122.279,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16916"
          data-name="Path 16916"
          d="M122.279,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16917"
          data-name="Path 16917"
          d="M122.279,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16918"
          data-name="Path 16918"
          d="M122.279,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16919"
          data-name="Path 16919"
          d="M136.475,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16920"
          data-name="Path 16920"
          d="M136.475,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16921"
          data-name="Path 16921"
          d="M136.475,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16922"
          data-name="Path 16922"
          d="M136.475,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16923"
          data-name="Path 16923"
          d="M136.475,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16924"
          data-name="Path 16924"
          d="M150.67,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16925"
          data-name="Path 16925"
          d="M150.67,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16926"
          data-name="Path 16926"
          d="M150.67,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16927"
          data-name="Path 16927"
          d="M150.67,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16928"
          data-name="Path 16928"
          d="M150.67,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16929"
          data-name="Path 16929"
          d="M164.866,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16930"
          data-name="Path 16930"
          d="M164.866,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16931"
          data-name="Path 16931"
          d="M164.866,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16932"
          data-name="Path 16932"
          d="M164.866,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16933"
          data-name="Path 16933"
          d="M164.866,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16934"
          data-name="Path 16934"
          d="M35.679,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16935"
          data-name="Path 16935"
          d="M35.679,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16936"
          data-name="Path 16936"
          d="M35.679,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16937"
          data-name="Path 16937"
          d="M35.679,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16938"
          data-name="Path 16938"
          d="M35.679,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16939"
          data-name="Path 16939"
          d="M49.875,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16940"
          data-name="Path 16940"
          d="M49.875,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16941"
          data-name="Path 16941"
          d="M49.875,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16942"
          data-name="Path 16942"
          d="M49.875,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16943"
          data-name="Path 16943"
          d="M49.875,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16944"
          data-name="Path 16944"
          d="M64.07,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16945"
          data-name="Path 16945"
          d="M64.07,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16946"
          data-name="Path 16946"
          d="M64.07,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16947"
          data-name="Path 16947"
          d="M64.07,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16948"
          data-name="Path 16948"
          d="M64.07,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16949"
          data-name="Path 16949"
          d="M78.266,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16950"
          data-name="Path 16950"
          d="M78.266,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16951"
          data-name="Path 16951"
          d="M78.266,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16952"
          data-name="Path 16952"
          d="M78.266,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16953"
          data-name="Path 16953"
          d="M78.266,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16954"
          data-name="Path 16954"
          d="M92.461,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_16955"
          data-name="Path 16955"
          d="M92.461,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_16956"
          data-name="Path 16956"
          d="M92.461,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_16957"
          data-name="Path 16957"
          d="M92.461,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_16958"
          data-name="Path 16958"
          d="M92.461,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_16959"
          data-name="Path 16959"
          d="M108.084,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16960"
          data-name="Path 16960"
          d="M108.084,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16961"
          data-name="Path 16961"
          d="M108.084,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16962"
          data-name="Path 16962"
          d="M108.084,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16963"
          data-name="Path 16963"
          d="M108.084,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-28.917 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16964"
          data-name="Path 16964"
          d="M122.279,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16965"
          data-name="Path 16965"
          d="M122.279,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16966"
          data-name="Path 16966"
          d="M122.279,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16967"
          data-name="Path 16967"
          d="M122.279,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16968"
          data-name="Path 16968"
          d="M122.279,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-32.914 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16969"
          data-name="Path 16969"
          d="M136.475,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16970"
          data-name="Path 16970"
          d="M136.475,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16971"
          data-name="Path 16971"
          d="M136.475,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16972"
          data-name="Path 16972"
          d="M136.475,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16973"
          data-name="Path 16973"
          d="M136.475,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-36.911 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16974"
          data-name="Path 16974"
          d="M150.67,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16975"
          data-name="Path 16975"
          d="M150.67,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16976"
          data-name="Path 16976"
          d="M150.67,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16977"
          data-name="Path 16977"
          d="M150.67,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16978"
          data-name="Path 16978"
          d="M150.67,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-40.908 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16979"
          data-name="Path 16979"
          d="M164.866,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16980"
          data-name="Path 16980"
          d="M164.866,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16981"
          data-name="Path 16981"
          d="M164.866,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16982"
          data-name="Path 16982"
          d="M164.866,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16983"
          data-name="Path 16983"
          d="M164.866,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-44.906 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16984"
          data-name="Path 16984"
          d="M35.679,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16985"
          data-name="Path 16985"
          d="M35.679,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16986"
          data-name="Path 16986"
          d="M35.679,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16987"
          data-name="Path 16987"
          d="M35.679,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16988"
          data-name="Path 16988"
          d="M35.679,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-8.529 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16989"
          data-name="Path 16989"
          d="M49.875,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16990"
          data-name="Path 16990"
          d="M49.875,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16991"
          data-name="Path 16991"
          d="M49.875,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16992"
          data-name="Path 16992"
          d="M49.875,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16993"
          data-name="Path 16993"
          d="M49.875,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-12.526 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16994"
          data-name="Path 16994"
          d="M64.07,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_16995"
          data-name="Path 16995"
          d="M64.07,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_16996"
          data-name="Path 16996"
          d="M64.07,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_16997"
          data-name="Path 16997"
          d="M64.07,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_16998"
          data-name="Path 16998"
          d="M64.07,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-16.523 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_16999"
          data-name="Path 16999"
          d="M78.266,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_17000"
          data-name="Path 17000"
          d="M78.266,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_17001"
          data-name="Path 17001"
          d="M78.266,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_17002"
          data-name="Path 17002"
          d="M78.266,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_17003"
          data-name="Path 17003"
          d="M78.266,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-20.52 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_17004"
          data-name="Path 17004"
          d="M92.461,58.352a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -15.672)"
          fill="#77797a"
        />
        <path
          id="Path_17005"
          data-name="Path 17005"
          d="M92.461,69.829a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -18.904)"
          fill="#77797a"
        />
        <path
          id="Path_17006"
          data-name="Path 17006"
          d="M92.461,81.306a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -22.136)"
          fill="#77797a"
        />
        <path
          id="Path_17007"
          data-name="Path 17007"
          d="M92.461,92.783a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -25.367)"
          fill="#77797a"
        />
        <path
          id="Path_17008"
          data-name="Path 17008"
          d="M92.461,104.26a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-24.518 -28.599)"
          fill="#77797a"
        />
        <path
          id="Path_17009"
          data-name="Path 17009"
          d="M185.258,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_17010"
          data-name="Path 17010"
          d="M185.258,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_17011"
          data-name="Path 17011"
          d="M185.258,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_17012"
          data-name="Path 17012"
          d="M185.258,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_17013"
          data-name="Path 17013"
          d="M185.258,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_17014"
          data-name="Path 17014"
          d="M199.454,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_17015"
          data-name="Path 17015"
          d="M199.454,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_17016"
          data-name="Path 17016"
          d="M199.454,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_17017"
          data-name="Path 17017"
          d="M199.454,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_17018"
          data-name="Path 17018"
          d="M199.454,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_17019"
          data-name="Path 17019"
          d="M213.649,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_17020"
          data-name="Path 17020"
          d="M213.649,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_17021"
          data-name="Path 17021"
          d="M213.649,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_17022"
          data-name="Path 17022"
          d="M213.649,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_17023"
          data-name="Path 17023"
          d="M213.649,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_17024"
          data-name="Path 17024"
          d="M227.845,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_17025"
          data-name="Path 17025"
          d="M227.845,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_17026"
          data-name="Path 17026"
          d="M227.845,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_17027"
          data-name="Path 17027"
          d="M227.845,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_17028"
          data-name="Path 17028"
          d="M227.845,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_17029"
          data-name="Path 17029"
          d="M242.04,130.046a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -35.86)"
          fill="#77797a"
        />
        <path
          id="Path_17030"
          data-name="Path 17030"
          d="M242.04,141.523a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -39.092)"
          fill="#77797a"
        />
        <path
          id="Path_17031"
          data-name="Path 17031"
          d="M242.04,153a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -42.324)"
          fill="#77797a"
        />
        <path
          id="Path_17032"
          data-name="Path 17032"
          d="M242.04,164.479a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -45.556)"
          fill="#77797a"
        />
        <path
          id="Path_17033"
          data-name="Path 17033"
          d="M242.04,175.955a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -48.787)"
          fill="#77797a"
        />
        <path
          id="Path_17034"
          data-name="Path 17034"
          d="M185.258,57.574a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -15.453)"
          fill="#77797a"
        />
        <path
          id="Path_17035"
          data-name="Path 17035"
          d="M185.258,69.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -18.685)"
          fill="#77797a"
        />
        <path
          id="Path_17036"
          data-name="Path 17036"
          d="M185.258,80.528a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -21.917)"
          fill="#77797a"
        />
        <path
          id="Path_17037"
          data-name="Path 17037"
          d="M185.258,92.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -25.148)"
          fill="#77797a"
        />
        <path
          id="Path_17038"
          data-name="Path 17038"
          d="M185.258,103.483a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-50.648 -28.38)"
          fill="#77797a"
        />
        <path
          id="Path_17039"
          data-name="Path 17039"
          d="M199.454,57.574a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -15.453)"
          fill="#77797a"
        />
        <path
          id="Path_17040"
          data-name="Path 17040"
          d="M199.454,69.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -18.685)"
          fill="#77797a"
        />
        <path
          id="Path_17041"
          data-name="Path 17041"
          d="M199.454,80.528a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -21.917)"
          fill="#77797a"
        />
        <path
          id="Path_17042"
          data-name="Path 17042"
          d="M199.454,92.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -25.148)"
          fill="#77797a"
        />
        <path
          id="Path_17043"
          data-name="Path 17043"
          d="M199.454,103.483a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-54.645 -28.38)"
          fill="#77797a"
        />
        <path
          id="Path_17044"
          data-name="Path 17044"
          d="M213.649,57.574a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -15.453)"
          fill="#77797a"
        />
        <path
          id="Path_17045"
          data-name="Path 17045"
          d="M213.649,69.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -18.685)"
          fill="#77797a"
        />
        <path
          id="Path_17046"
          data-name="Path 17046"
          d="M213.649,80.528a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -21.917)"
          fill="#77797a"
        />
        <path
          id="Path_17047"
          data-name="Path 17047"
          d="M213.649,92.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -25.148)"
          fill="#77797a"
        />
        <path
          id="Path_17048"
          data-name="Path 17048"
          d="M213.649,103.483a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-58.642 -28.38)"
          fill="#77797a"
        />
        <path
          id="Path_17049"
          data-name="Path 17049"
          d="M227.845,57.574a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -15.453)"
          fill="#77797a"
        />
        <path
          id="Path_17050"
          data-name="Path 17050"
          d="M227.845,69.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -18.685)"
          fill="#77797a"
        />
        <path
          id="Path_17051"
          data-name="Path 17051"
          d="M227.845,80.528a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -21.917)"
          fill="#77797a"
        />
        <path
          id="Path_17052"
          data-name="Path 17052"
          d="M227.845,92.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -25.148)"
          fill="#77797a"
        />
        <path
          id="Path_17053"
          data-name="Path 17053"
          d="M227.845,103.483a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-62.64 -28.38)"
          fill="#77797a"
        />
        <path
          id="Path_17054"
          data-name="Path 17054"
          d="M242.04,57.574a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -15.453)"
          fill="#77797a"
        />
        <path
          id="Path_17055"
          data-name="Path 17055"
          d="M242.04,69.051a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -18.685)"
          fill="#77797a"
        />
        <path
          id="Path_17056"
          data-name="Path 17056"
          d="M242.04,80.528a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -21.917)"
          fill="#77797a"
        />
        <path
          id="Path_17057"
          data-name="Path 17057"
          d="M242.04,92.005a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -25.148)"
          fill="#77797a"
        />
        <path
          id="Path_17058"
          data-name="Path 17058"
          d="M242.04,103.483a2.7,2.7,0,1,1-2.7-2.7,2.7,2.7,0,0,1,2.7,2.7"
          transform="translate(-66.637 -28.38)"
          fill="#77797a"
        />
        <path
          id="Path_17059"
          data-name="Path 17059"
          d="M118.643,32.6H44.265a2.949,2.949,0,1,1,0-5.9h74.378a2.949,2.949,0,1,1,0,5.9"
          transform="translate(-11.634 -7.518)"
          fill="#77797a"
        />
        <path
          id="Path_17060"
          data-name="Path 17060"
          d="M218.8,32.6h-28.81a2.949,2.949,0,1,1,0-5.9H218.8a2.949,2.949,0,1,1,0,5.9"
          transform="translate(-52.666 -7.518)"
          fill="#77797a"
        />
      </g>
    </g>
  </CustomIcon>
)
