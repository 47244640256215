import {
  DataUsage,
  Delete,
  Download,
  Error,
  Fullscreen,
  Speed,
} from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { useDeleteTray } from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'

import AnalysisResultModal from '../AnalysisResultModal/AnalysisResultModal'

import { formatDate } from 'lib/utils/formatDate'
import useDTMAnalysisResults from '../DTMAnalysisResults.logic'

import { CombinedResults, DTMAnalysisBoxProps } from './DTMAnalysisBox.types'

const DTMAnalysisBox = ({
  id,
  user_id,
  surgery_id,
  tray_img,
  accuracy_rate,
  createdAt,
  updatedAt,
  processing_time,
  analysis_results,
  user_corrections,
  error,
}: DTMAnalysisBoxProps) => {
  const {
    setAnalysisResults,
    selectedResult,
    setSelectedResult,
    handleSelectedResultClose,
  } = useDTMAnalysisResults()

  const { deleteTray, loading: deleting } = useDeleteTray()
  const [isTrayImageLoading, setIsTrayImageLoading] = useState<boolean>(true)
  const [combinedResults, setCombinedResults] = useState<CombinedResults[]>([])
  const [calculatingAccuracy, setCalculatingAccuracy] = useState<boolean>(true)
  const [accuracyRate, setAccuracyRate] = useState<number>(0)

  const handleResultClick = () => {
    setSelectedResult({
      id,
      user_id,
      analysis_results,
      user_corrections,
      processing_time,
      accuracy_rate,
      tray_img,
      error,
      createdAt,
      updatedAt,
      surgery_id,
    })
  }

  const handleDownloadImage = (imageUrl: string, imageName: string) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', imageName)
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
      })
      .catch((error) => console.error('Error downloading image:', error))
  }

  const handleDelete = async () => {
    if (deleting) {
      return
    }

    const confirmDeletion = window.confirm(
      'Are you sure you want to delete this tray analysis?'
    )

    if (!confirmDeletion) {
      return
    }

    const success = await deleteTray(id)
    if (success) {
      setAnalysisResults((prevResults) =>
        prevResults.filter((result) => result.id !== id)
      )
    } else {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.error('Failed to delete tray')
      }
    }
  }

  useEffect(() => {
    const combineAndDetermineStatus = () => {
      const combined: CombinedResults[] = []

      if (analysis_results?.length > 0 && user_corrections?.length > 0) {
        // Add all analysisResults with status 'incorrect'
        for (const analysisResult of analysis_results) {
          const foundInUserCorrections = user_corrections.find(
            (correction) =>
              correction.size === analysisResult.size &&
              correction.column === analysisResult.column &&
              correction.row === analysisResult.row &&
              correction.label === analysisResult.label &&
              correction.wasted === analysisResult.wasted
          )
          if (!foundInUserCorrections) {
            combined.push({
              ...analysisResult,
              status: 'incorrect',
            })
          }
        }

        // Add all userCorrections with status 'correct' or 'missing'
        for (const correction of user_corrections) {
          const foundInAnalysisResults = analysis_results.find(
            (analysisResult) =>
              correction.size === analysisResult.size &&
              correction.column === analysisResult.column &&
              correction.row === analysisResult.row &&
              correction.label === analysisResult.label &&
              correction.wasted === analysisResult.wasted
          )
          if (foundInAnalysisResults) {
            combined.push({
              ...correction,
              status: 'correct',
            })
          } else {
            combined.push({
              ...correction,
              status: 'missing',
            })
          }
        }

        setCombinedResults(combined)
      } else if (
        selectedResult &&
        selectedResult?.analysis_results?.length > 0
      ) {
        setCombinedResults(
          analysis_results.map((result) => ({
            ...result,
            status: 'unverified',
          }))
        )
      } else if (
        selectedResult &&
        selectedResult?.user_corrections?.length > 0
      ) {
        setCombinedResults(
          user_corrections.map((result) => ({
            ...result,
            status: 'unverified',
          }))
        )
      }
    }

    combineAndDetermineStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedResult?.analysis_results?.length,
    selectedResult?.user_corrections?.length,
  ])

  useEffect(() => {
    const calculateAccuracyRate = () => {
      const correctCount = combinedResults.filter(
        (result) => result.status === 'correct'
      ).length
      const totalResultsCount = combinedResults.length
      const rate =
        totalResultsCount === 0 ? 0 : (correctCount / totalResultsCount) * 100
      setCalculatingAccuracy(false)
      setAccuracyRate(rate)
    }

    calculateAccuracyRate()
  }, [combinedResults, combinedResults.length, setCombinedResults])

  function getDeviceSpecifications() {
    const deviceSpecs = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      devicePixelRatio: window.devicePixelRatio,
      // Add more specifications as needed
    }
    return deviceSpecs
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        p: '10px 15px',
        transition: 'all 0.2s ease-in-out',
        borderRadius: '10px',
        boxShadow: '0px 0px 30px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '350px' },
          height: '200px',
          position: 'relative',
        }}
      >
        {isTrayImageLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
            }}
          >
            <CircularProgress sx={{ width: '30px', height: '30px' }} />
          </div>
        )}
        <Box
          sx={{
            width: { xs: '100%', md: '350px' },
            height: '200px',
            borderRadius: '5px',
            objectFit: 'cover',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            alt="Analyzed tray Image"
            src={tray_img}
            sx={{
              width: { xs: '100%', md: '350px' },
              height: '200px',
              borderRadius: '5px',
              objectFit: 'cover',
            }}
            onLoad={() => setIsTrayImageLoading(false)}
          />

          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 10,
              borderRadius: '5px',
              bgcolor: '#5035ED',
              color: 'white',
              width: '30px',
              height: '30px',
              p: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() =>
              handleDownloadImage(tray_img, `Tray Analysis ${id}.png`)
            }
          >
            <Download sx={{ width: '17px', height: '17px' }} />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'auto', md: '10px' },
          right: { xs: '50%', md: '10px' },
          bottom: { xs: '10px', md: 'auto' },
          transform: { xs: 'translateX(50%)', md: 'none' },
          zIndex: 10,
          p: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: '5px',
            bgcolor: 'gray',
            color: 'white',
            width: deleting ? '50px' : '30px',
            height: '30px',
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            mr: 2,
          }}
          onClick={handleDelete}
        >
          {deleting && (
            <CircularProgress size="0.8em" sx={{ color: 'white', mr: 1 }} />
          )}{' '}
          <Delete sx={{ width: '17px', height: '17px' }} />
        </Box>

        <Box
          sx={{
            borderRadius: '5px',
            bgcolor: '#5035ED',
            color: 'white',
            width: '30px',
            height: '30px',
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleResultClick}
        >
          <Fullscreen sx={{ width: '17px', height: '17px' }} />
        </Box>
      </Box>

      <AnalysisResultModal
        open={selectedResult !== null && selectedResult.id === id}
        error={error}
        onClose={handleSelectedResultClose}
        combinedResults={combinedResults}
      />

      <Typography fontSize={16} fontWeight={600} color="#11142D">
        Tray ID {id} |{' '}
        <span style={{ fontSize: 13, fontWeight: 400 }}>
          {formatDate(createdAt)}
        </span>
      </Typography>

      <Stack
        width={{ xs: '90%', md: '20%' }}
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        mt={{ xs: 3, md: 0 }}
      >
        <Stack
          width="90%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {error.length > 0 ? (
            <Error sx={{ color: 'red' }} />
          ) : (
            <DataUsage sx={{ color: '#5A43E9' }} />
          )}

          <Speed />
        </Stack>

        <Divider sx={{ color: '#11142D', width: '100%' }} />
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {error.length > 0 ? (
            <Typography
              fontSize={16}
              fontWeight={600}
              color="red"
              textAlign="center"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Failed
              <span style={{ fontSize: 12, fontWeight: 300 }}>(error)</span>
            </Typography>
          ) : (
            <Typography
              fontSize={16}
              fontWeight={600}
              color="#11142D"
              textAlign="center"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {user_corrections.length === 0
                ? 'Unverified'
                : calculatingAccuracy
                ? 'Calculating...'
                : `${Math.floor(accuracyRate)}%`}
              <span style={{ fontSize: 12, fontWeight: 300 }}>(accuracy)</span>
            </Typography>
          )}

          <Typography
            fontSize={16}
            fontWeight={600}
            color="#11142D"
            textAlign="center"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {processing_time}s
            <span style={{ fontSize: 12, fontWeight: 300 }}>(speed)</span>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default DTMAnalysisBox
