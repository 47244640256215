import { useCallback, useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import {
  GenericObject,
  RepApprovalFormData,
  DerivedSurgeryStatus,
  AssetStatus,
} from 'common/types'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { repRecordApprovalSchema, validateForm } from 'lib/validation'
import dayjs from 'lib/dayjs'
import { repApprovalValidationSchema } from './repApprovalValidationSchema'
import useFormValidation from 'lib/hooks/useFormValidation/useFormValidation'
import { useUser } from 'app/User'
import { MutationResult } from '@apollo/client'

interface Props {
  derivedSurgeryStatus: DerivedSurgeryStatus
  handleSetAssetStatus: (
    status: 'PRE_APPROVAL' | 'APPROVED' | 'SCANNED',
    source?: any,
    sendToCompany?: string | null,
    signature?: AssetStatus['signature']
  ) => void
  setShowGenericAlertDialog: (val: boolean) => void
  setAlertDialogDetails: (val: Record<string, any>) => void
  setAssetStatusMutation: MutationResult<any>
}

const initialFormData = {} as RepApprovalFormData

const RepApproval = ({
  derivedSurgeryStatus,
  handleSetAssetStatus,
  setShowGenericAlertDialog,
  setAlertDialogDetails,
  setAssetStatusMutation,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [repApprovalFormData, setRepApprovalFormData] =
    useState<RepApprovalFormData>(initialFormData)
  const [repApprovalFormErrors, setRepApprovalFormErrors] =
    useState<GenericObject>({})

  const { isNurse, isNurseAdmin } = useUser()
  const isNurseUser = isNurse || isNurseAdmin

  const { name: procedureStatus, workflow } = derivedSurgeryStatus

  const handleChangeFormData = (e: any) => {
    setRepApprovalFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleValidateForm = async () => {
    const errors = await validateForm({
      formData: repApprovalFormData,
      schema: repRecordApprovalSchema,
    })
    setRepApprovalFormErrors({ ...errors })
  }

  const handleRepApproval = useCallback(
    (e: any) => {
      e.preventDefault()

      if (workflow === 'no-rep') {
        handleSetAssetStatus('APPROVED', 'primary', null, {
          signature: repApprovalFormData.repName,
          date: dayjs().toISOString(),
        })
        return
      }

      setShowGenericAlertDialog(true)
      setAlertDialogDetails({
        title: 'Approve the products?',
        mode: 'success',
        description:
          'Approving the products will enable the nurse to submit the record to the EMR. Once the record has been submitted, no further edits will be possible.',
        primaryButtonAction: () => {
          setShowGenericAlertDialog(false)
          setRepApprovalFormData((prevFormData) => ({
            ...prevFormData,
            approvedAt: dayjs().toISOString(),
          }))
          handleSetAssetStatus('APPROVED', 'primary', null, {
            signature: repApprovalFormData.repName,
            date: dayjs().toISOString(),
          })
        },
        primaryButtonText: 'Yes, approve',
        secondaryButtonAction: () => setShowGenericAlertDialog(false),
        secondaryButtonText: 'Back',
      })
    },
    [
      handleSetAssetStatus,
      repApprovalFormData,
      setAlertDialogDetails,
      setShowGenericAlertDialog,
      workflow,
    ]
  )

  // BEGIN form validation / alerts for rep approval form
  const {
    isFormSubmitted,
    handleValidation,
    isSubmitDisabled,
    FormValidationAlertToasters,
    isFormValidated,
  } = useFormValidation({
    validationSchema: repApprovalValidationSchema,
  })
  const [isApprovalSubmitted, setIsApprovalSubmitted] = useState(false)
  const [formEvent, setFormEvent] = useState({})
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setFormEvent(e)
    handleValidation(e)
    if (isFormValidated) {
      handleRepApproval(e)
    }
  }
  useEffect(() => {
    if (isFormSubmitted && isFormValidated && !isApprovalSubmitted) {
      handleRepApproval(formEvent)
      setIsApprovalSubmitted(true)
    }
  }, [
    isFormSubmitted,
    isFormValidated,
    handleRepApproval,
    isApprovalSubmitted,
    formEvent,
  ])
  // END form validation / alerts for rep approval form

  return (
    <>
      {FormValidationAlertToasters()}

      <form onSubmit={handleSubmit}>
        <TextField
          id="repName"
          name="repName"
          label={
            isNurseUser
              ? 'Type your name to approve'
              : 'Name of approving sales rep'
          }
          disabled={procedureStatus !== 'PRE_APPROVAL'}
          value={repApprovalFormData.repName ?? ''}
          onChange={handleChangeFormData}
          // Show initial error only onBlur but once input is touched validate on input change
          error={
            repApprovalFormData.repName?.length !== 0 ||
            !repApprovalFormErrors.repName
              ? false
              : true
          }
          onBlur={handleValidateForm}
          required
          size="small"
          fullWidth
          variant="standard"
          helperText="Your first and last name are required to approve the products"
          sx={{ my: 2 }}
        />
        <LoadingButton
          dataTestId="approve-assets-button"
          type="submit"
          disabled={
            setAssetStatusMutation.loading
              ? false
              : !repApprovalFormData.repName || isSubmitDisabled
          }
          loading={setAssetStatusMutation.loading}
          loadingText="Approving products"
          sx={{ mb: 1.5 }}
        >
          Approve products
        </LoadingButton>
      </form>
    </>
  )
}

export default RepApproval
