import { useMutation } from 'react-query'
import { DecrementUsageParams } from './types'
import { captureWarning } from 'lib/monitoring'
import { useAuth0 } from 'app/Auth'

const useDecrementUsageMutation = () => {
  const { getAccessToken } = useAuth0()

  const mutationFn = async (data: DecrementUsageParams) => {
    const token = await getAccessToken()

    try {
      const response = await fetch(
        `${process.env.REACT_APP_GRAPHQL_PROD}api/usage`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      )

      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(`Error: ${response.statusText} - ${errorText}`)
      }

      return response.json()
    } catch (error: any) {
      captureWarning(error)
      throw error
    }
  }

  return useMutation<any, Error, DecrementUsageParams>(mutationFn)
}

export default useDecrementUsageMutation
