// Components
import MediaUploadModal from './UploadMediaModal/MediaUploadModal'
import PreviewModal from './PreviewModal/PreviewModal'
import { ImagePreview } from './ImageDetails/ImageDetails'
import { LoadingButton } from '@mui/lab'
import { LinearProgress } from '@mui/material'
import AlertDialog from 'components/AlertDialog/AlertDialog'

// Logic
import useMediaUploadLogic from './MediaUpload.logic'

// Types
import { MediaUploadProps } from './MediaUpload.types'

// Style
import './MediaUpload.scss'

// Icons
import { CameraAlt } from '@mui/icons-material'

// Other
import classNames from 'classnames'

const MediaUpload: React.FC<MediaUploadProps> = ({
  assetId,
  scanId,
  fileNames,
  hasActions,
  disableActions,
  isLocal,
  onSave,
  onMediaFilesChange,
}) => {
  const {
    images,
    modalId,
    isEmpty,
    showMediaBlock,
    deletedImageName,
    isUploadModalOpen,
    isPreviewModalOpen,
    isLoadingFileNames,
    isLoadingDeleteFiles,
    isLoadingUploadFiles,
    isDeleteAlertOpen,
    handleSaveImages,
    handleDeleteImage,
    setIsDeleteAlertOpen,
    setIsUploadModalOpen,
    setIsPreviewModalOpen,
  } = useMediaUploadLogic({
    assetId,
    scanId,
    fileNames,
    isLocal,
    hasActions,
    onSave,
    onMediaFilesChange,
  })

  return (
    <>
      {showMediaBlock && (
        <div
          className={classNames('media-upload-container', {
            'view-only': !isEmpty && !hasActions,
            'is-loading': isLoadingFileNames,
          })}
        >
          <div
            className={classNames('images', {
              'has-images': images.length,
              'has-actions': hasActions,
            })}
          >
            {!isLoadingFileNames &&
              images.length > 0 &&
              images.map((img, index) => (
                <ImagePreview
                  key={img.file?.name}
                  disableActions={disableActions}
                  hasActions={hasActions}
                  onClick={() =>
                    setIsPreviewModalOpen({ isOpen: true, src: img.preview })
                  }
                  isDeleting={
                    deletedImageName === img.file?.name && isLoadingDeleteFiles
                  }
                  src={img.preview}
                  alt={`preview-${index}`}
                  handleDelete={() =>
                    setIsDeleteAlertOpen({
                      isOpen: true,
                      fileName: img.file?.name,
                    })
                  }
                />
              ))}
          </div>

          {hasActions && (
            <div className="actions">
              <LoadingButton
                disabled={disableActions}
                variant="outlined"
                onClick={() => setIsUploadModalOpen(true)}
              >
                <CameraAlt />
              </LoadingButton>
            </div>
          )}

          {/* Modals */}
          <PreviewModal
            isOpen={isPreviewModalOpen.isOpen}
            onClose={() =>
              setIsPreviewModalOpen({ isOpen: false, src: undefined })
            }
            src={isPreviewModalOpen.src}
          />
          <MediaUploadModal
            key={modalId}
            isLoading={isLoadingUploadFiles}
            isOpen={isUploadModalOpen}
            onClose={() => {
              setIsUploadModalOpen(false)
            }}
            onSave={handleSaveImages}
          />

          <AlertDialog
            position={{
              bottom: '40%',
            }}
            isOpen={isDeleteAlertOpen?.isOpen as boolean}
            mode="error"
            title="Delete Image"
            description={`Are you sure you want to delete this image?`}
            positionAboveBottomNavigation
            primaryButtonAction={() =>
              handleDeleteImage(isDeleteAlertOpen?.fileName)
            }
            primaryButtonText="Delete"
            secondaryButtonAction={() =>
              setIsDeleteAlertOpen({ isOpen: false, fileName: undefined })
            }
            secondaryButtonText="Cancel"
            zIndex={6}
          />
        </div>
      )}

      {isLoadingFileNames && (
        <div className="media-upload-loading">
          <LinearProgress className="loading-progress" />
        </div>
      )}
    </>
  )
}

export default MediaUpload
