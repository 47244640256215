import { Edit } from '@mui/icons-material'
import { SelectScanModal } from '../SelectScanModal/SelectScanModal'
import { useEditDispositionModalLogic } from '../EditDispositionModal/EditDispositionModal.logic'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { EditDispositionModal } from '../EditDispositionModal/EditDispositionModal'
import { EditAssetButtonProps } from 'lib/context/EditDocumentedListContext/EditDispositionList.types'

export const EditAssetButton = ({
  assetgroupId,
  scansArray,
  isProcedureSubmitted,
  assetTray,
  surgeryId,
  bidAssetId,
  scanIds,
  isManualAddition,
  consumableAssetGroup,
  isMultipack,
}: EditAssetButtonProps) => {
  const {
    handleEditOnOpen,
    isSelectModalOpen,
    scans,
    isEditModalOpen,
    selectedAssetGroupId,
    scanIsMatching,
  } = useEditDispositionModalLogic({ assetTray })

  return (
    <>
      <LoadingButton
        data-testid={`${bidAssetId}-edit-scan-button`}
        variant="outlined"
        value={scanIds}
        onClick={(e) => {
          handleEditOnOpen(
            assetgroupId,
            scansArray,
            e.currentTarget.value,
            consumableAssetGroup
          )
        }}
        disabled={isProcedureSubmitted}
        loading={false}
        size="small"
        sx={{ padding: 0 }}
      >
        <Edit sx={{ transform: 'scale(0.75)' }} />
      </LoadingButton>

      {isSelectModalOpen &&
        scans.length > 1 &&
        assetgroupId === selectedAssetGroupId && (
          <>
            <SelectScanModal
              dataTestId={`${bidAssetId}-select-scan`}
              assetTray={assetTray}
              scans={scans}
              surgeryId={surgeryId}
            />
            {scanIsMatching() && (
              <EditDispositionModal
                scans={scans}
                isManualAddition={isManualAddition}
                assetTray={assetTray}
                dataTestId={`${bidAssetId}-edit-disposition-modal`}
              />
            )}
          </>
        )}

      {isEditModalOpen &&
        assetgroupId === selectedAssetGroupId &&
        scans?.[0]?._id === scanIds?.[0] && (
          <EditDispositionModal
            scans={scans}
            isManualAddition={isManualAddition}
            assetTray={assetTray}
            dataTestId={`${bidAssetId}-edit-disposition`}
            isMultipack={isMultipack}
          />
        )}
    </>
  )
}
