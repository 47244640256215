import PageDisplay from 'components/PageDisplay/PageDisplay'
import styles from './errorFallback.module.css'

interface Props {
  error?: Error
  title?: string
  description?: string
}

//NOTE: ErrorFallback can't have context dependencies
function ErrorFallback({
  error,
  title = 'An Error Has Occured',
  description,
}: Props) {
  error && console.warn('ErrorFallback', error?.message)

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div role="alert">
      <PageDisplay>
        <h1>{title}</h1>
        {description && <p>{description}</p>}
        <div>
          <button type="button" className={styles.reload} onClick={reloadPage}>
            Reload page
          </button>
          <a href="/">Return To Homepage</a>
        </div>
      </PageDisplay>
    </div>
  )
}

export default ErrorFallback
