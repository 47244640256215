import { Helmet } from 'react-helmet'
import dayjs from 'lib/dayjs'
import {
  Typography,
  Table,
  Skeleton,
  Box,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material'
import FloatingBar from 'components/FloatingBar/FloatingBar'
import { StatusValue, useStatusCheck } from 'lib/hooks/useStatusCheck'

const formatDate = (date: Date) => dayjs(date).format('MM/DD/YYYY, HH:mm:ss')

function StatusText({ status }: { status: StatusValue }) {
  return status === StatusValue.OK ? (
    <Typography color="success">✅</Typography>
  ) : (
    <Typography fontWeight="bold">❌</Typography>
  )
}

function StatusCheck() {
  const statusState = useStatusCheck()

  return (
    <Box sx={{ minHeight: '100vh', background: '#fafafa' }}>
      <Helmet>
        <title>Service Status</title>
      </Helmet>
      <FloatingBar />
      <Box marginTop={10} textAlign="center">
        <Typography variant="h1" marginBottom={6}>
          MedGEO Platform Service Status
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: 600, margin: '0 auto' }}
        >
          <Table aria-label="MedGEO Platform Service Status">
            <TableHead>
              <TableRow>
                <TableCell>System</TableCell>
                <TableCell align="right">Last Checked</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  AppSync (GraphQL)
                </TableCell>
                <TableCell align="right">
                  {statusState.appsync?.lastChecked ? (
                    formatDate(statusState.appsync.lastChecked)
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </TableCell>
                <TableCell align="right">
                  {statusState.appsync?.status === undefined ? (
                    <Skeleton variant="text" />
                  ) : (
                    <StatusText status={statusState.appsync.status} />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  MongoDB
                </TableCell>
                <TableCell align="right">
                  {statusState.mongodb?.lastChecked ? (
                    formatDate(statusState.mongodb.lastChecked)
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </TableCell>
                <TableCell align="right">
                  {statusState.mongodb?.status === undefined ? (
                    <Skeleton variant="text" />
                  ) : (
                    <StatusText status={statusState.mongodb.status} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default StatusCheck
