// utils
import { getDashboardStats } from './utils/getDashboardStats'

// components
import { Grid, Box, CircularProgress } from '@mui/material'
import { TodaysProcedures } from './tables/TodaysProceduresTable'
import { LinearProgressBarCard } from './components/LinearProgressBarCard'
import { AssetsThisMonthCard } from './components/AssetsThisMonthCard'
import { ProceduresThisMonthCard } from './components/ProceduresThisMonthCard'
import { UtilizedAssetsCard } from './components/UtilizedAssetsCard'

// context
import { useDashboardContext } from 'lib/context/DashboardContext'
import { theme } from 'assets/styles/customTheme'
import Header from 'components/molecules/Header/Header'

export const DashboardIndex = () => {
  const today = new Date()
  const {
    isLoading,
    surgeryList,
    assetGroups,
    currentYearSurgeryList,
    darkMode,
  } = useDashboardContext()

  // const [assetTypeFilter, setAssetTypeFilter] = React.useState('ALL')
  // const [dispositionTypeFilter, setDispositionTypeFilter] =
  //   React.useState('ALL')
  // const [wastedReasonFilter, setWastedReasonFilter] = React.useState('ALL')
  // const [assetDataDate, setAssetDataDate] = React.useState(today)
  // const [assetSurgeonFilter, setAssetSurgeonFilter] = React.useState('')

  const dashboard = getDashboardStats({
    dateToday: today,
    surgeryList,
    assetGroups,
    currentYearSurgeryList,
  })

  return (
    <>
      <Header parent="Dashboard" title="Dashboard Home" />

      <Box
        component="main"
        aria-label="dashboard-index"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          maxWidth: '1400px',
          margin: '0 auto',
          backgroundColor: darkMode ? 'primary.dark' : 'grayscale.lightest',
        }}
      >
        {isLoading ? (
          <CircularProgress
            size={70}
            sx={{
              color: darkMode
                ? theme.palette.grayscale.light
                : theme.palette.primary.main,
            }}
          />
        ) : (
          <Grid
            container
            aria-label="dashboard-container-column"
            direction="column"
            justifyContent="space-between"
            rowSpacing={1}
          >
            {/*   // ==============================|| ROW 1 ||============================== // */}
            <Grid item aria-label="dashboard-item-1">
              <Grid
                container
                aria-label="dashboard-container-row-1"
                direction={'row'}
                columnSpacing={2.75}
                columns={6}
              >
                <Grid
                  item
                  aria-label="dashboard-row-1-surgeries-remaining"
                  xs={2}
                  sm={2}
                  lg={2}
                >
                  <LinearProgressBarCard
                    primaryData={dashboard.stats.surgeriesRemainingToday}
                    primaryDataCaption={'Surgeries Remaining Today'}
                    completedValue={dashboard.stats.completedSurgeriesToday}
                    valueBuffer={dashboard.stats.activeSurgeriesToday}
                    valueKey={`procedures completed (${dashboard.stats.completedSurgeriesToday} of ${dashboard.stats.totalSurgeriesToday})`}
                    valueBufferKey={`procedures in progress (${dashboard.stats.activeSurgeriesToday})`}
                    totalValue={dashboard.stats.totalSurgeriesToday}
                  />
                </Grid>
                <Grid
                  item
                  aria-label="dashboard-row-1-procedures-this-month"
                  xs={2}
                  sm={2}
                  lg={2}
                >
                  <ProceduresThisMonthCard
                    primaryData={dashboard.stats.totalSurgeriesCurrentMonth}
                    primaryDataCaption={'Procedures this month'}
                    comparisonStat={dashboard.stats.surgeriesMonthOverMonth.charAt(
                      0
                    )}
                    comparisonStatLabel={`${dashboard.stats.surgeriesMonthOverMonth} from last month`}
                    surgeriesByMonth={dashboard.parsedSurgeriesByMonth}
                  />
                </Grid>
                <Grid
                  item
                  aria-label="dashboard-row-1-assets-this-month"
                  xs={2}
                  sm={2}
                  lg={2}
                >
                  <AssetsThisMonthCard
                    primaryData={dashboard.stats.totalAssetsCurrentMonth}
                    primaryDataCaption={'Products Consumed This Month'}
                    totalHardwareAssetsCurrentMonth={
                      dashboard.stats.totalHardwareAssetsCurrentMonth
                    }
                    totalOtherAssetsCurrentMonth={
                      dashboard.stats.totalOtherAssetsCurrentMonth
                    }
                    totalBiologicAssetsCurrentMonth={
                      dashboard.stats.totalBiologicAssetsCurrentMonth
                    }
                    totalConsumableAssetsCurrentMonth={
                      dashboard.stats.totalConsumableAssetsCurrentMonth
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*   // ==============================|| ROW 2 ||============================== // */}
            <Grid item aria-label="dashboard-item-2">
              <Grid
                container
                aria-label="dashboard-container-row-2"
                direction={'row'}
                // columnSpacing={2.75}
                // columns={6}
              />
              <Grid
                item
                aria-label="dashboard-row-2-utilized-assets"
                // xs={2}
                // sm={2}
                // lg={2}
              >
                <UtilizedAssetsCard
                  assetsByMonth={dashboard.assetsByMonth}
                  surgeriesByMonth={dashboard.parsedSurgeriesByMonth}
                />
              </Grid>
            </Grid>
            {/*   // ==============================|| ROW 3 ||============================== // */}
            <Grid
              item
              aria-label="dashboard-item-3-todays-procedures-table"
              xs={12}
              sm={6}
              lg={6}
            >
              <TodaysProcedures />
            </Grid>
            {/* <Grid item>
        <DashboardDefault />
      </Grid> */}
          </Grid>
        )}
      </Box>
    </>
  )
}
