import { FC } from 'react'
import { Container } from './SendForApproval.styles'
import { ButtonClicked, TSendForApprovalProps } from './SendForApproval.types'

import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { useSendForApprovalLogic } from './SendForApproval.logic'

const SendForApproval: FC<TSendForApprovalProps> = ({
  surgery,
  handleSetAssetStatus,
  setAssetStatusMutation,
  setAssetStatusSecondaryMutation,
  setIsQrOpen,
}) => {
  const {
    isGroupedAssetsEmpty,
    surgeryRepCount,
    numberOfExpiredHardware,
    disableSendForApproval,
    handleSendForApproval,
    FormValidationAlertToasters,
    setButtonClicked,
  } = useSendForApprovalLogic({
    surgery,
    handleSetAssetStatus,
    setAssetStatusMutation,
    setAssetStatusSecondaryMutation,
    setIsQrOpen,
  })

  return (
    <>
      {FormValidationAlertToasters()}

      <form onSubmit={handleSendForApproval}>
        <input
          type="hidden"
          name="numberOfExpiredHardware"
          value={numberOfExpiredHardware}
        />
        <Container>
          {surgeryRepCount > 0 && (
            <LoadingButton
              type="submit"
              dataTestId="send-for-rep-approval-button"
              loading={setAssetStatusMutation.loading}
              loadingText="Sending for rep approval"
              sx={{ mb: 1.5 }}
              disabled={disableSendForApproval}
              onClick={() =>
                setButtonClicked(ButtonClicked.SEND_FOR_REP_APPROVAL)
              }
            >
              Send for rep approval
            </LoadingButton>
          )}
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <LoadingButton
              id="no-industry-rep-available-button"
              type="submit"
              dataTestId="no-industry-rep-available-button"
              mode="secondary"
              loading={setAssetStatusSecondaryMutation.loading}
              loadingText="Marking ready for approval"
              variant="outlined"
              disabled={isGroupedAssetsEmpty}
              onClick={() => setButtonClicked(ButtonClicked.NO_REP_AVAILABLE)}
            >
              Approve without a rep
            </LoadingButton>
          </div>
        </Container>
      </form>
    </>
  )
}

export default SendForApproval
