export const uploadImageToS3 = async (
  file: File,
  presignedUrl: string,
  key: string
) => {
  const bucketName = process.env.REACT_APP_DTM_S3_BUCKET_NAME as string
  const region = process.env.REACT_APP_DTM_S3_REGION as string

  if (!file || !presignedUrl) return

  try {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
    const imageUrl = `https://${bucketName}.s3.${region}.amazonaws.com/${key}`
    return imageUrl
  } catch (error) {
    console.error('Error uploading file:', error)
  }
}
