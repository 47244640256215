import {
  AssetIdType,
  BetterIdAsset,
  Scan,
  UndocumentedScan,
} from 'common/types'
import { AssetType } from 'common/disposition'

const SPONGE_DESCRIPTORS = ['sponge', 'sponges']

const RF_CONSUMABLE_DESCRIPTORS = [
  'rf',
  'x-ray',
  'xray',
  'detectable',
  'radiofrequency',
]

const ASSOCIATED_PRODUCT_DESCRIPTORS = [
  'cutting guide',
  'drill bit',
  'tap',
  'model',
  'screw driver',
  'splint',
  'battery',
  'j notch',
  'hex',
  'twist',
  'marking guide',
  'drill',
  'reusable',
  'guide wire',
  'reamer',
  'template',
  'guide',
]

const NON_ASSOCIATED_DESCRIPTIONS = ['drill free']

export interface ParseAssetIdentifiersArgs {
  deviceDescription?:
    | Scan['deviceDescription']
    | UndocumentedScan['deviceDescription']
  deviceCount: Scan['count'] | UndocumentedScan['count']
  idType?: AssetIdType | null
  assetType: AssetType | null | string
  gmdnPTDefinition?: BetterIdAsset['gmdnPTDefinition']
}

export const parseAssetIdentifiers = ({
  deviceDescription,
  deviceCount,
  idType,
  assetType,
  gmdnPTDefinition,
}: ParseAssetIdentifiersArgs) => {
  const isMatch = (descriptors: Array<string>, target?: string) => {
    if (!target) return false

    const caseInsensitive = descriptors.map(
      (descriptor) => new RegExp(`\\b${descriptor}\\b`, 'i')
    )

    return caseInsensitive.some((regex) => regex.test(target))
  }

  const isRfMultipackConsumable =
    isMatch(RF_CONSUMABLE_DESCRIPTORS, deviceDescription ?? undefined) ||
    isMatch(RF_CONSUMABLE_DESCRIPTORS, gmdnPTDefinition ?? undefined) ||
    (['lap', 'laparotomy'].some((descriptor) =>
      deviceDescription?.toLowerCase().includes(descriptor)
    ) &&
      SPONGE_DESCRIPTORS.some((spongeDesc) =>
        deviceDescription?.toLowerCase().includes(spongeDesc)
      ))

  const containsAssist = gmdnPTDefinition?.includes('assist') || false

  const isMultipackAsset = deviceCount > 1 || idType === AssetIdType.UNIT_OF_USE

  const isMultipackHardware =
    isMultipackAsset &&
    assetType === 'non-biological' &&
    !isRfMultipackConsumable

  const isSpongeConsumable =
    isMatch(SPONGE_DESCRIPTORS, deviceDescription ?? undefined) &&
    !isRfMultipackConsumable

  // is a multipack consumable and NOT an rf multipack consumable or sponge
  const isMultipackConsumable =
    isMultipackAsset &&
    assetType === 'consumable' &&
    !isRfMultipackConsumable &&
    !isSpongeConsumable

  const isMultipackNonConsumable =
    isMultipackAsset &&
    assetType !== 'consumable' &&
    !isRfMultipackConsumable &&
    !isSpongeConsumable

  const matchesNonAssociated = isMatch(
    NON_ASSOCIATED_DESCRIPTIONS,
    deviceDescription ?? undefined
  )
  const matchesAssociated = isMatch(
    ASSOCIATED_PRODUCT_DESCRIPTORS,
    deviceDescription ?? undefined
  )

  const isAssociatedProduct =
    (matchesAssociated &&
      assetType === 'non-biological' &&
      !matchesNonAssociated) ||
    (containsAssist && assetType === 'non-biological')

  return {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackHardware,
    isMultipackNonConsumable,
    isMultipackAsset,
    isAssociatedProduct,
  }
}
