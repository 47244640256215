import { useEffect } from 'react'
import toast, { Toast, Toaster } from 'react-hot-toast'
import FormValidationAlert from './FormValidationAlert'
import { FormValidationAlertType } from 'common/types'

type OmitToastProp = Omit<
  Toast,
  | 'message'
  | 'id'
  | 'type'
  | 'pauseDuration'
  | 'ariaProps'
  | 'createdAt'
  | 'visible'
>
export interface FormValidationAlertToasterProps extends OmitToastProp {
  formValidationAlertType: FormValidationAlertType
  id?: string | Element
  loadingAlerts: boolean
  setLoadingAlerts: (val: boolean) => void
  message: string | Element
  onCancel: (message: string | Element) => void
  onAcknowledge?: (message: string | Element) => void
  onOk?: (message: string | Element) => void
}

export function FormValidationAlertToaster({
  formValidationAlertType,
  loadingAlerts,
  message,
  onCancel,
  onAcknowledge,
  onOk,
  setLoadingAlerts,
}: FormValidationAlertToasterProps) {
  const handleCancel = (id: string) => {
    // condition here bc onCancel is optional param
    if (onCancel) {
      onCancel(message)
      toast.dismiss(id)
      setLoadingAlerts(false)
    }
  }

  const handleAcknowledge = (id: string) => {
    if (onAcknowledge) {
      onAcknowledge(message)
      toast.dismiss(id)
      setLoadingAlerts(false)
    }
  }

  const handleOk = (id: string) => {
    if (onOk) {
      onOk(message)
      toast.dismiss(id)
      setLoadingAlerts(false)
    }
  }

  useEffect(() => {
    if (loadingAlerts) {
      toast(
        (t) => (
          <FormValidationAlert
            formValidationAlertType={formValidationAlertType}
            mode={'error'}
            message={message}
            handleAcknowledge={() => handleAcknowledge(t.id)}
            handleCancel={() => handleCancel(t.id)}
            handleOk={() => handleOk(t.id)}
          />
        ),
        {
          id: message as string,
          duration: Infinity,
        }
      )
    }
  })

  return <Toaster />
}
