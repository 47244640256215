import { Typography } from '@mui/material'
import { FC } from 'react'
import { TRepProps } from './Rep.types'
import { EmailMobile, InfoRow, MainContainer } from './Rep.styles'
import SenderButton from '../SenderButton'
import { useRepLogic } from './Rep.logic'

const Rep: FC<TRepProps> = ({ rep, surgeryId, assetDetails }) => {
  const { emailsMobiles } = useRepLogic({ surgeryId, assetDetails })
  return (
    <MainContainer>
      <Typography variant="h4">
        {rep.firstName} {rep.lastName}
      </Typography>
      {rep.email && (
        <InfoRow>
          <EmailMobile
            variant="subtitle2"
            sx={{
              bgcolor: emailsMobiles.emails.includes(rep.email)
                ? 'var(--success-light)'
                : 'var(--primary-light)',
            }}
          >
            {rep.email}
            {emailsMobiles.emails.includes(rep.email) ? <span>✅</span> : null}
          </EmailMobile>
          <SenderButton
            emailsMobiles={emailsMobiles}
            aria-label="send record by email"
            isSms={false}
            disabled={!rep.email}
            rep={rep}
            surgeryId={surgeryId}
            assetDetails={assetDetails}
          />
        </InfoRow>
      )}
      {rep.mobile && (
        <InfoRow>
          <EmailMobile
            variant="subtitle2"
            sx={{
              bgcolor: emailsMobiles.mobiles.includes(rep.mobile)
                ? 'var(--success-light)'
                : 'var(--primary-light)',
            }}
          >
            {rep.mobile}
            {emailsMobiles.mobiles.includes(rep.mobile) ? (
              <span>✅</span>
            ) : null}
          </EmailMobile>
          <SenderButton
            emailsMobiles={emailsMobiles}
            aria-label="send record by text message"
            isSms={true}
            disabled={!rep.mobile}
            rep={rep}
            surgeryId={surgeryId}
            assetDetails={assetDetails}
          />
        </InfoRow>
      )}
    </MainContainer>
  )
}

export default Rep
