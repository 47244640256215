import { FC, useMemo, useState } from 'react'

// MUI
import { Box, ButtonBase, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// Components
import SearchInput from 'components/molecules/SearchInput/SearchInput'
import Modal from 'components/molecules/Modal/Modal'
import Button from 'components/molecules/Button/Button'

// Types
import { IUpdateParValueModal } from './UpdateParValueModal.types'

// Style
import './UpdateParValueModal.scss'

// Context
import { useInventoryReport } from '../InventoryReportProvider'

const UpdateParValueDialog: FC<IUpdateParValueModal> = ({
  onChange,
  isLoading,
}) => {
  const [parValue, setParValue] = useState<number>()
  const { parValueModal, setParValueModal } = useInventoryReport()
  const defaultParValue = parValueModal.data?.parValue
  const parValueId = parValueModal.data?.parValueId
  const open = parValueModal.open as boolean

  useMemo(() => {
    setParValue(defaultParValue)
  }, [defaultParValue])

  const onClose = () => setParValueModal({ open: false })

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="update-par-value-container">
        <Box className="header">
          <Typography variant="h2">Edit PAR value</Typography>
          <ButtonBase onClick={onClose}>
            <CloseIcon />
          </ButtonBase>
        </Box>

        <Box className="body">
          <SearchInput
            value={String(parValue)}
            label="PAR value"
            onSearch={(value) => setParValue(Number(value))}
            placeholder="PAR value"
            type="number"
          />
        </Box>

        <Box className="actions">
          <Button
            variant="contained"
            isLoading={isLoading}
            onClick={() => onChange && onChange(parValueId || 0, parValue || 0)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpdateParValueDialog
