import { useEffect, useState } from 'react'

import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'

import { InventoryLocation } from 'views/LocationManagement/LocationManagement.types'
import { useTrayConfigurationContext } from './TrayConfiguration.context'

/**
 * Responsible for the business logic behind opening the add new tray view
 * @returns tray configuration logic
 */
const useTrayConfiguration = () => {
  const { data, loading: isLoadingLocations } = useGetLocationsTree()
  const { setSelectedTrayCategory, setSelectedTray, selectedTray } =
    useTrayConfigurationContext()

  const [isAddTrayModalOpen, setIsAddTrayModalOpen] = useState<boolean>(false)
  const [locations, setLocations] = useState<InventoryLocation[]>([])
  const [locationsWithParents, setLocationsWithParents] = useState<
    InventoryLocation[]
  >([])
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (data) {
      const allLocations = data.getLocationsTree.hospital.locations
      const locationsWithParents = allLocations.filter(
        (location) => location.parentLocationId
      )

      setLocationsWithParents(locationsWithParents)
      setLocations(allLocations)
    }
  }, [data])

  useEffect(() => {
    if (selectedTray) {
      setIsDetailsModalOpen(true)
    }
  }, [selectedTray])

  const handleAddTrayModalOpen = () => {
    setIsAddTrayModalOpen(true)
  }

  const handleAddTrayModalClose = () => {
    setSelectedTrayCategory(null)
    setIsAddTrayModalOpen(false)
  }

  const findLocation = (locationId: number) => {
    return locations.find((location) => location.id === locationId)
  }

  const handleDetailsModalClose = () => {
    setSelectedTray(null)
    setIsDetailsModalOpen(false)
  }

  return {
    isAddTrayModalOpen,
    setIsAddTrayModalOpen,
    handleAddTrayModalOpen,
    handleAddTrayModalClose,
    locations,
    locationsWithParents,
    isLoadingLocations,
    findLocation,
    isDetailsModalOpen,
    setIsDetailsModalOpen,
    handleDetailsModalClose,
  }
}

export default useTrayConfiguration
