import { Person } from '@mui/icons-material'
import SearchInput from 'components/molecules/TextField/TextField'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import DTMAnalysisBox from './DTMAnalysisBox/DTMAnalysisBox'
import useDTMAnalysisResults from './DTMAnalysisResults.logic'

const DTMAnalysisResults = () => {
  const {
    handleTrayIdChange,
    selectedHospital,
    handleHospitalChange,
    analysisResults,
    handleClearFilters,
    handleLoadMore,
    error,
    selectedDate,
    handleChangeDay,
    loading,
    totalCount,
  } = useDTMAnalysisResults()

  return (
    <div style={{ width: '100%', padding: '10px 20px' }}>
      <h1>DTM Analysis Results</h1>

      <div style={{ maxWidth: '700px' }}>
        <SearchInput
          placeholder="Seach User ID"
          handleInputChange={handleTrayIdChange}
          isLoading={false}
          icon={<Person />}
        />

        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <FormControl sx={{ width: { xs: '100%', md: '40%' } }}>
            <InputLabel id="hospital-selection">Hospitals</InputLabel>

            <Select
              id="selectedHopsital"
              name="selectedHospital"
              variant="outlined"
              labelId="hospital-label"
              label="Hopsitals"
              value={selectedHospital}
              onChange={handleHospitalChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="CHLA">CHLA</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Analysis Date"
              value={selectedDate}
              onChange={(date) => handleChangeDay(date as Date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: { xs: '100%', md: '40%' } }}
                />
              )}
              PaperProps={{
                sx: {
                  '& .MuiPickersDay-root': {
                    '&.Mui-selected': {
                      backgroundColor: '#5035ED',
                      color: 'white',
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>

          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#5A43E9',
              border: 'none',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            onClick={handleClearFilters}
          >
            CLEAR
          </button>
        </Stack>
      </div>

      <Stack
        width="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        my={4}
        gap={2.5}
      >
        {error ? (
          <Typography
            fontSize={15}
            fontWeight={300}
            color="#11142D"
            fontStyle="italic"
          >
            There was an error loading the DTM analysis results. Please try
            again later.
          </Typography>
        ) : analysisResults.length === 0 && !loading && !error ? (
          <Typography
            fontSize={15}
            fontWeight={300}
            color="#11142D"
            fontStyle="italic"
          >
            There are no DTM analysis results in our database.
          </Typography>
        ) : (
          analysisResults.map((analysis) => (
            <DTMAnalysisBox key={analysis.id} {...analysis} />
          ))
        )}

        {loading ? (
          <CircularProgress />
        ) : totalCount !== analysisResults.length ? (
          <Button
            sx={{
              bgcolor: '#5035ED',
              color: 'white',
              fontSize: 15,
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: '5px',
              '&:hover': {
                bgcolor: '#5035ED',
                opacity: 0.8,
              },
            }}
            onClick={handleLoadMore}
          >
            {error ? 'Refresh' : 'Load more'}
          </Button>
        ) : !error ? (
          <Typography
            fontSize={15}
            fontWeight={300}
            color="#11142D"
            fontStyle="italic"
          >
            You've reached the total number of analysis results in the database
          </Typography>
        ) : null}
      </Stack>
    </div>
  )
}

export default DTMAnalysisResults
