import { DataCard } from 'components/molecules/DataCard'
import { AssetsThisMonthChart } from '../charts/AssetsThisMonthChart'

interface AssetsThisMonthCardProps {
  primaryData: number
  primaryDataCaption: string
  totalHardwareAssetsCurrentMonth: number
  totalOtherAssetsCurrentMonth: number
  totalBiologicAssetsCurrentMonth: number
  totalConsumableAssetsCurrentMonth: number
}

export const AssetsThisMonthCard = ({
  primaryData,
  primaryDataCaption,
  totalHardwareAssetsCurrentMonth,
  totalOtherAssetsCurrentMonth,
  totalBiologicAssetsCurrentMonth,
  totalConsumableAssetsCurrentMonth,
}: AssetsThisMonthCardProps) => {
  return (
    <DataCard primaryData={primaryData} primaryDataCaption={primaryDataCaption}>
      <AssetsThisMonthChart
        hardwareCount={totalHardwareAssetsCurrentMonth}
        otherCount={totalOtherAssetsCurrentMonth}
        biologicCount={totalBiologicAssetsCurrentMonth}
        consumableCount={totalConsumableAssetsCurrentMonth}
      />
    </DataCard>
  )
}
