import React from 'react'

// Components
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'

// Types
import { AssetType } from '../ProcessModal.types'
import { ProductTypeStepProps } from './steps.types'

// Icons
import Biologic from 'assets/icons/svgs/ImplantableBiological.svg'
import Hardware from 'assets/icons/svgs/ImplantableHardware.svg'
import Other from 'assets/icons/svgs/ImplantableOther.svg'

// Utils
import { useIsTablet } from 'lib/utils/mediaQueries'

const ProductTypeStep: React.FC<ProductTypeStepProps> = ({
  setSelected,
  selected,
}) => {
  const items: Array<{ label: string; value: AssetType; icon: string }> = [
    { label: 'Biologic', value: 'Biologic', icon: Biologic },
    { label: 'Hardware', value: 'Hardware', icon: Hardware },
    { label: 'Other', value: 'Other', icon: Other },
  ]
  const isMobile = useIsTablet()

  return (
    <div className="items">
      {items.map((item) => (
        <ButtonBase
          key={item.label}
          className={`item ${selected ? 'selected' : ''}`}
          disableRipple
          onClick={() => setSelected(item.value)}
        >
          <div className="circle">
            {item.value === selected ? <DoneIcon className="icon" /> : null}
            <img
              style={{
                objectFit: 'cover',
                height: isMobile ? 35 : 70,
                width: isMobile ? 35 : 70,
              }}
              src={item.icon}
              alt={item.label}
            />
          </div>
          <Typography>{item.label}</Typography>
        </ButtonBase>
      ))}
    </div>
  )
}

export default ProductTypeStep
