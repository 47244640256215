import { GET_INVENTORY_REPORT } from 'lib/services/api/schema'
import { GetInventoryParams, GetInventoryReportResponse } from './types'
import { QueryResult, useQuery } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'

const useGetInventoryReport = (
  params: GetInventoryParams
): QueryResult<GetInventoryReportResponse, GetInventoryParams> => {
  return useQuery(GET_INVENTORY_REPORT, {
    variables: params,
    onError: (error: Error) => {
      captureWarning(error)
    },
    skip: params.enabled,
    notifyOnNetworkStatusChange: true,
  })
}

export default useGetInventoryReport
