import { useDeleteTrayItem, useGetTrayItemByBarcode } from 'lib/apollo/hooks'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTrayConfigurationContext } from '../TrayManagement/TrayManagement.context'

export const useTrayDetails = () => {
  const { trayId } = useParams()
  const navigate = useNavigate()

  const { setTrays } = useTrayConfigurationContext()
  const { loading, error, trayItem, getTrayItem } = useGetTrayItemByBarcode()
  const { deleteTray, loading: deleting } = useDeleteTrayItem()

  const trayDetails: TrayItemProductDetails | undefined = trayItem
    ? (JSON.parse(trayItem.productDetails as string) as TrayItemProductDetails)
    : undefined

  useEffect(() => {
    if (!trayId) {
      navigate('..')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId])

  useEffect(() => {
    if (!loading) {
      getTrayItem({ variables: { barcode: trayId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId])

  const handleDeleteTray = async () => {
    const hasUserConfirmed = window.confirm(
      'Are you sure you want to delete this tray?'
    )

    if (hasUserConfirmed) {
      const isTrayDeleted = await deleteTray(trayItem?.id)

      if (isTrayDeleted) {
        setTrays((prevTrays) =>
          prevTrays.filter((tray) => tray.id !== trayItem?.id)
        )
        navigate('/tray-management')
      }
    }
  }

  const handleEditTrayClick = () => {
    if (trayId) {
      navigate(`../edit/${trayId}`)
    }
  }

  return {
    trayId,
    trayItem,
    loading,
    error,
    trayDetails,
    deleteTray,
    deleting,
    handleDeleteTray,
    handleEditTrayClick,
  }
}
