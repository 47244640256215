import React from 'react'

// Components
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'

// Types
import { Disposition } from '../ProcessModal.types'
import { ConsignmentStepProps } from './steps.types'

const ConsignmentStep: React.FC<ConsignmentStepProps> = ({
  setSelected,
  selected,
}) => {
  const items: Array<{ label: string; value: Disposition }> = [
    { label: 'Consigned', value: 'Consigned' },
    { label: 'Purchased', value: 'Purchased' },
  ]

  return (
    <div className="items">
      {items.map((item) => (
        <ButtonBase
          key={item.label}
          className={`item ${selected ? 'selected' : ''}`}
          disableRipple
          onClick={() => setSelected(item.value)}
        >
          <div className="circle">
            {item.value === selected ? <DoneIcon className="icon" /> : null}
          </div>
          <Typography>{item.label}</Typography>
        </ButtonBase>
      ))}
    </div>
  )
}

export default ConsignmentStep
