import { CustomIcon } from 'components/templates/CustomIcon'

type InstrumentTrayIconProps = {
  active: boolean
  onClick?: () => void
}

export const InstrumentTrayIcon = ({
  active,
  onClick,
}: InstrumentTrayIconProps) => (
  <CustomIcon
    active={active}
    addStatusColor={false}
    onClick={onClick}
    width="189.917"
    height="135.014"
    viewBox="0 0 189.917 135.014"
  >
    <g
      id="Instrument_Tray"
      data-name="Instrument Tray"
      transform="translate(-344.891 -111.051)"
    >
      <g
        id="Instrument_Tray-2"
        data-name="Instrument Tray"
        transform="translate(344.891 111.051)"
      >
        <g
          id="Group_11824"
          data-name="Group 11824"
          transform="translate(189.917) rotate(90)"
        >
          <path
            id="Path_16200"
            data-name="Path 16200"
            d="M135.014,166.989c0,12.61-9.346,22.928-20.771,22.928H20.772C9.348,189.917,0,179.6,0,166.989V22.928C0,10.318,9.348,0,20.772,0h93.471c11.424,0,20.771,10.318,20.771,22.928Zm0,0"
            fill="#c5c6c8"
          />
          <path
            id="Path_16201"
            data-name="Path 16201"
            d="M51.927,8.1v144.06a12.038,12.038,0,0,1-3.045,8.1H0V0H48.882A12.038,12.038,0,0,1,51.927,8.1Zm0,0"
            transform="translate(72.7 14.827)"
            fill="#83878c"
          />
          <path
            id="Path_16202"
            data-name="Path 16202"
            d="M103.857,0H3.047A12.032,12.032,0,0,0,0,8.1v144.06a12.032,12.032,0,0,0,3.047,8.1h100.81Zm0,0"
            transform="translate(10.386 14.827)"
            fill="#a4a6aa"
          />
          <path
            id="Path_16203"
            data-name="Path 16203"
            d="M10.386,0a9.878,9.878,0,0,1,7.339,3.363l-7.339,8.1C10.386,5.144,5.726,0,0,0Zm0,0"
            transform="translate(103.857 11.464)"
            fill="#62676f"
          />
          <path
            id="Path_16204"
            data-name="Path 16204"
            d="M10.386,0l7.339,8.1a9.877,9.877,0,0,1-7.339,3.363H0C5.726,11.464,10.386,6.32,10.386,0Zm0,0"
            transform="translate(103.857 166.989)"
            fill="#62676f"
          />
          <path
            id="Path_16205"
            data-name="Path 16205"
            d="M73.963,83.494,90.424,22.927,100.81,11.464C100.81,5.144,96.15,0,90.424,0H7.339A9.877,9.877,0,0,0,0,3.363L17.724,22.927,34.186,83.494,17.724,144.062,0,163.626a9.877,9.877,0,0,0,7.339,3.363H90.424c5.726,0,10.386-5.144,10.386-11.464L90.424,144.062Zm0,0"
            transform="translate(13.433 11.464)"
            fill="#83878c"
          />
          <path
            id="Path_16206"
            data-name="Path 16206"
            d="M41.543,0V121.135H31.157L0,60.568,31.157,0Zm0,0"
            transform="translate(62.314 34.391)"
            fill="#e2e3e3"
          />
          <path
            id="Path_16207"
            data-name="Path 16207"
            d="M0,0H92.977V144.061H0ZM0,0"
            transform="translate(21.266 22.928)"
            fill="#f4f4f4"
          />
        </g>
      </g>
      <g id="Group_11841" data-name="Group 11841" transform="translate(11)">
        <path
          id="Path_16225"
          data-name="Path 16225"
          d="M431.382,160v44.06a3.672,3.672,0,0,1-7.343,0V162.448l1.848-.624h.013l4.675-1.554Zm0,0"
          transform="translate(47.726 6.319)"
          fill="#4c36e4"
        />
        <path
          id="Path_16226"
          data-name="Path 16226"
          d="M431.382,8V28.806a4.256,4.256,0,0,1-.343,1.652l-.465,1.065L425.9,33.078h-.013c-.722-.8-1.848-2.166-1.848-3.048C424.039,14.119,431.382,8,431.382,8Zm0,0"
          transform="translate(47.726 135.065)"
          fill="#ccc"
        />
      </g>
      <g id="Group_11840" data-name="Group 11840" transform="translate(-27)">
        <path
          id="Path_16227"
          data-name="Path 16227"
          d="M16.784,19.585h1.575a1.224,1.224,0,0,0,0-2.448H16.368L14.68,7.2a7.343,7.343,0,1,0-1.667,4.812L23.272,72.417a1.225,1.225,0,0,0,1.207,1.02,1.251,1.251,0,0,0,.206-.017,1.223,1.223,0,0,0,1-1.411Zm-9.44-7.343a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,7.344,12.242Zm0,0"
          transform="translate(406.898 141.838)"
          fill="#999"
        />
        <path
          id="Path_16228"
          data-name="Path 16228"
          d="M108.844,0a7.344,7.344,0,0,0-7.335,7.174l-2.067,9.96H97.83a1.224,1.224,0,1,0,0,2.448h1.1L88.064,71.964a1.224,1.224,0,0,0,.95,1.447,1.237,1.237,0,0,0,.249.022,1.224,1.224,0,0,0,1.2-.979L103.044,11.83A7.343,7.343,0,1,0,108.844,0Zm0,12.239a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,108.844,12.239Zm0,0"
          transform="translate(332.323 141.841)"
          fill="gray"
        />
      </g>
      <g id="Group_11855" data-name="Group 11855" transform="translate(-15)">
        <path
          id="Path_16229"
          data-name="Path 16229"
          d="M340.934,290.269a1.223,1.223,0,0,1-1.04-1.87c.079-.123,7.71-12.671.083-21.824-3.227-3.876-3.923-7.221-3.938-9.342A1.223,1.223,0,0,1,337.254,256h.009a1.224,1.224,0,0,1,1.224,1.216,12.726,12.726,0,0,0,3.372,7.792c3.645,4.378,4.812,10.055,3.373,16.416a29.278,29.278,0,0,1-3.258,8.268A1.223,1.223,0,0,1,340.934,290.269Zm0,0"
          transform="translate(122.263 -74.994)"
          fill="gray"
        />
        <path
          id="Path_16230"
          data-name="Path 16230"
          d="M260.867,290.269a1.223,1.223,0,0,1-1.039-.577,29.346,29.346,0,0,1-3.266-8.267c-1.443-6.364-.28-12.039,3.364-16.417a12.706,12.706,0,0,0,3.372-7.792A1.224,1.224,0,0,1,264.521,256h.008a1.224,1.224,0,0,1,1.216,1.232c-.015,2.124-.711,5.467-3.937,9.342-7.622,9.152.019,21.7.1,21.824a1.224,1.224,0,0,1-1.039,1.87Zm0,0"
          transform="translate(190.092 -74.994)"
          fill="gray"
        />
        <path
          id="Path_16231"
          data-name="Path 16231"
          d="M310.158,66.727h-4.9a1.224,1.224,0,0,1-1.218-1.346L306.492,40.9a1.224,1.224,0,0,1,2.442,0l2.448,24.478a1.224,1.224,0,0,1-1.224,1.346Zm0,0"
          transform="translate(149.368 108.159)"
          fill="#999"
        />
        <path
          id="Path_16232"
          data-name="Path 16232"
          d="M305.263,200h4.9a1.224,1.224,0,0,1,1.224,1.224v9.791a1.224,1.224,0,0,1-1.224,1.224h-4.9a1.224,1.224,0,0,1-1.224-1.224v-9.791A1.224,1.224,0,0,1,305.263,200Zm0,0"
          transform="translate(149.367 -27.562)"
          fill="#4c36e4"
        />
      </g>
    </g>
  </CustomIcon>
)
