import * as React from 'react'
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import type { RecordCompanyResult, Surgery } from 'common/types'
import { useGetRecordCompanyRepsQuery } from 'lib/apollo/hooks'
import styles from './RecordSender.module.css'
import { CompanyRepsList } from './CompanyRepsList'

function RecordSender({ surgeryId }: { surgeryId: Surgery['_id'] }) {
  const [currentCompany, setCurrentCompany] =
    React.useState<RecordCompanyResult | null>(null)

  const companiesQuery = useGetRecordCompanyRepsQuery(surgeryId)

  return (
    <Box className={styles.root}>
      <Typography variant="h3">Share Implant Record</Typography>
      <Typography variant="body2" color="text.secondary" marginTop={0.5}>
        Find product representitives by selecting a company
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel id="company-select-label">Company</InputLabel>
        <Select
          labelId="company-select-label"
          id="company-select"
          label="Company"
          inputProps={{
            name: 'company',
            id: 'company',
          }}
          sx={{ background: 'white' }}
          value={currentCompany}
          onChange={(e) => {
            setCurrentCompany(e.target.value as RecordCompanyResult)
          }}
        >
          {companiesQuery.loading ? (
            <MenuItem>Loading Companies...</MenuItem>
          ) : (
            companiesQuery.data?.getRecordCompanyReps.map(
              (company: RecordCompanyResult) => (
                //@ts-ignore - necessary to load object into value
                <MenuItem key={company.bidCompanyId} value={company}>
                  {company.name}
                </MenuItem>
              )
            )
          )}
        </Select>
      </FormControl>
      {currentCompany ? (
        <CompanyRepsList
          selectedCompany={currentCompany}
          surgeryId={surgeryId}
        />
      ) : null}
    </Box>
  )
}

export default RecordSender
