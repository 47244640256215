import { useEffect, useState } from 'react'
import { FilterButton } from 'components/atoms/FilterButton/FilterButton'
import { Menu, Chip, Grid } from '@mui/material'
import { useReportFiltersContext } from 'lib/context/ReportFiltersContext/ReportFiltersContext'
import { FilterMenu } from '../FilterMenu/FilterMenu'
import {
  DefaultFilterParams,
  FilterProps,
} from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'
import './FilterButtonBox.scss'

export const FilterButtonBox = ({
  renderAssetTypeFilter,
  renderMrnFilter,
  renderPatientNameFilter,
  renderSurgeonFilter,
  renderProcedureTypeFilter,
  renderDepartmentFilter,
  renderDeviceDescription,
  renderDisposition,
  isDisabled,
  condenseButtons,
  isLoading,
}: FilterProps) => {
  const { filters, setFilters } = useReportFiltersContext()

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const renderChip = (onDelete: () => void, label?: string) => (
    <Chip className="filter-chip" label={label} onDelete={onDelete} />
  )

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleFiltersActions = (
    action: 'reset' | 'remove',
    filterType?: keyof DefaultFilterParams
  ) => {
    switch (action) {
      case 'reset':
        setFilters({
          open: false,
        })
        break
      case 'remove':
        if (filterType) {
          setFilters((prevFilters) => {
            const newFilters = { ...prevFilters }
            delete newFilters[filterType]
            return newFilters
          })
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    handleMenuClose()
  }, [filters])

  return (
    <Grid className="filter-button-container" container>
      <Grid item>
        <FilterButton
          handleMenuClick={handleMenuClick}
          isDisabled={isLoading || isDisabled || false}
        />
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          sx={{
            zIndex: 1000,
            overflow: 'visible',
          }}
        >
          <FilterMenu
            onReset={() => {
              handleFiltersActions('reset')
              handleMenuClose()
            }}
            condenseButtons={condenseButtons}
            renderAssetTypeFilter={renderAssetTypeFilter}
            renderMrnFilter={renderMrnFilter}
            renderPatientNameFilter={renderPatientNameFilter}
            renderSurgeonFilter={renderSurgeonFilter}
            renderProcedureTypeFilter={renderProcedureTypeFilter}
            renderDepartmentFilter={renderDepartmentFilter}
            renderDeviceDescription={renderDeviceDescription}
            renderDisposition={renderDisposition}
            isLoading={isLoading}
          />
        </Menu>
      </Grid>
      {filters && (
        <Grid item>
          {filters.assetType &&
            renderChip(
              () => handleFiltersActions('remove', 'assetType'),
              filters.assetType
            )}
          {filters?.mrn &&
            renderChip(
              () => handleFiltersActions('remove', 'mrn'),
              filters?.mrn
            )}
          {filters?.patientName &&
            renderChip(
              () => handleFiltersActions('remove', 'patientName'),
              filters?.patientName
            )}
          {filters?.surgeonName &&
            renderChip(
              () => handleFiltersActions('remove', 'surgeonName'),
              filters?.surgeonName
            )}
          {filters?.procedureType &&
            renderChip(
              () => handleFiltersActions('remove', 'procedureType'),
              filters?.procedureType
            )}
          {filters?.department &&
            renderChip(
              () => handleFiltersActions('remove', 'department'),
              filters?.department
            )}
          {filters?.deviceDescription &&
            renderChip(
              () => handleFiltersActions('remove', 'deviceDescription'),
              filters?.deviceDescription
            )}
          {filters?.disposition &&
            renderChip(
              () => handleFiltersActions('remove', 'disposition'),
              filters?.disposition
            )}
        </Grid>
      )}
    </Grid>
  )
}
