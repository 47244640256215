import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Error as ErrorIcon } from '@mui/icons-material'
import { OperationVariables, QueryResult } from '@apollo/client'

import { bidSearchResponseToResults } from 'lib/utils/betterid'
import { useBetterIdLookupQuery } from 'lib/apollo/hooks'

import { useSPD } from '../SPD.context'

import { BetterIdLookupQuery, BetterIdResult } from 'common/types'
import { useCreateApolloClient } from 'lib/apollo'

interface SPDScanContextProps {
  scannedCode: string
  setScannedCode: Dispatch<SetStateAction<string>>
  manualCode: string
  setManualCode: Dispatch<SetStateAction<string>>
  isComplete: boolean
  setIsComplete: Dispatch<SetStateAction<boolean>>
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  productData: BetterIdResult[]
  setProductData: Dispatch<SetStateAction<BetterIdResult[]>>
  lookupQuery: QueryResult<BetterIdLookupQuery, OperationVariables>
  isAdditionalDeviceCountModalOpen: boolean
  setIsAdditionalDeviceCountModalOpen: Dispatch<SetStateAction<boolean>>
  isLoadMultipleBoxVisible: boolean
  setIsLoadMultipleBoxVisible: Dispatch<SetStateAction<boolean>>
}

const SPDScanContext = createContext<SPDScanContextProps | undefined>(undefined)

const SPDScanProvider = ({ children }: { children: JSX.Element }) => {
  const { client } = useCreateApolloClient()
  const {
    setSnackbarOpen,
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarState,
  } = useSPD()

  const [scannedCode, setScannedCode] = useState<string>('')
  const [manualCode, setManualCode] = useState<string>('')
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [productData, setProductData] = useState<BetterIdResult[]>([])
  const [
    isAdditionalDeviceCountModalOpen,
    setIsAdditionalDeviceCountModalOpen,
  ] = useState<boolean>(false)
  const [isLoadMultipleBoxVisible, setIsLoadMultipleBoxVisible] =
    useState<boolean>(false)

  const lookupQuery = useBetterIdLookupQuery(
    manualCode || scannedCode,
    {
      skip: scannedCode.length === 0 && manualCode.length === 0,
      onCompleted: (data) => {
        const products = bidSearchResponseToResults(data.betterIdLookup)
        if (products.length > 0) {
          setProductData(bidSearchResponseToResults(data.betterIdLookup))
          setScannedCode('')
          setManualCode('')
          setIsComplete(true)
          setIsDrawerOpen(true)
          return data
        }

        setIsDrawerOpen(false)
        setSnackbarMessage('Product data not found')
        setSnackbarIcon(<ErrorIcon color="error" />)
        setSnackbarState('error')
        setSnackbarOpen(true)
        throw new Error('Product data not found')
      },
      onError: () => {
        setScannedCode('')
        setIsComplete(false)
        setIsDrawerOpen(false)
        setSnackbarMessage('Failed to find product')
        setSnackbarIcon(<ErrorIcon color="error" />)
        setSnackbarState('error')
        setSnackbarOpen(true)
      },
    },
    client
  )

  useEffect(() => {
    if (isDrawerOpen) {
      if (productData.length === 0) {
        setIsDrawerOpen(false)
      }
    }
  }, [isDrawerOpen, productData.length])

  return (
    <SPDScanContext.Provider
      value={{
        scannedCode,
        setScannedCode,
        isComplete,
        setIsComplete,
        productData,
        setProductData,
        lookupQuery,
        isDrawerOpen,
        setIsDrawerOpen,
        manualCode,
        setManualCode,
        isAdditionalDeviceCountModalOpen,
        setIsAdditionalDeviceCountModalOpen,
        isLoadMultipleBoxVisible,
        setIsLoadMultipleBoxVisible,
      }}
    >
      {children}
    </SPDScanContext.Provider>
  )
}

const useSPDScan = () => {
  const context = useContext(SPDScanContext)

  if (context === undefined) {
    throw new Error('useSPDScan must be used with SPDScanProvider')
  }

  return context
}

export { useSPDScan, SPDScanProvider }
