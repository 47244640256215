import * as React from 'react'
import { AlertTypes } from 'common/types'
import { Box, Typography, Grid } from '@mui/material'
import { modeConfig } from './AlertDialog'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { FormValidationAlertType } from '../../common/types'

interface FormValidationAlertProps {
  formValidationAlertType: FormValidationAlertType
  mode?: AlertTypes
  title?: string
  message: string | Element
  handleCancel?: () => void
  cancelButtonText?: string
  handleAcknowledge?: () => void
  acknowledgeButtonText?: string
  handleOk?: () => void
  okButtonText?: string
  children?: React.ReactNode
}

function FormValidationAlert({
  formValidationAlertType,
  mode = 'error',
  title = formValidationAlertType === FormValidationAlertType.ERROR
    ? 'Error'
    : formValidationAlertType === FormValidationAlertType.CONFIRM
    ? 'Confirm'
    : 'Warning',
  message,
  handleCancel,
  cancelButtonText = formValidationAlertType === FormValidationAlertType.WARNING
    ? 'Cancel'
    : 'Back',
  handleAcknowledge,
  acknowledgeButtonText = formValidationAlertType ===
  FormValidationAlertType.WARNING
    ? 'Continue'
    : 'Confirm',
  handleOk,
  okButtonText = 'Ok',
}: FormValidationAlertProps) {
  const Icon = mode && modeConfig[mode].icon

  return (
    <div
      style={{
        backgroundColor: modeConfig[mode].backgroundColor,
        width: '330px',
      }}
    >
      <Box sx={{ display: 'flex', mr: 2 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          aria-label="main-container"
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            aria-label="title-container"
          >
            <Grid item>
              {/* @ts-ignore error due to ts wanting call signature */}
              <Icon color={modeConfig[mode].label ?? mode} sx={{ mr: 1 }} />
            </Grid>
            <Grid item>
              <Typography
                sx={{ mt: 0.3, mb: 1 }}
                variant="h3"
                color={modeConfig[mode].textColor}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-end"
            sx={{ pl: 4 }}
            aria-label="body-container"
          >
            <Grid
              container
              justifyContent="center"
              direction="row"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  color={
                    modeConfig[mode].textDescriptionColor ??
                    modeConfig[mode].textColor
                  }
                  sx={{
                    lineHeight: 1.3,
                    letterSpacing: 0.3,
                    textAlight: 'left',
                  }}
                >
                  {message}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                ml: 1,
              }}
            >
              {formValidationAlertType === FormValidationAlertType.ERROR && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  columnSpacing={1}
                  sx={{ mt: 1 }}
                >
                  {handleCancel && (
                    <Grid item>
                      <LoadingButton
                        variant="contained"
                        mode="cancel"
                        sx={{ letterSpacing: 0.7 }}
                        onClick={handleCancel}
                        disabled={false}
                        loading={false}
                      >
                        {cancelButtonText}
                      </LoadingButton>
                    </Grid>
                  )}
                </Grid>
              )}

              {formValidationAlertType === FormValidationAlertType.WARNING && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  columnSpacing={1}
                  sx={{ mt: 1 }}
                >
                  {handleCancel && (
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        mode="cancel"
                        onClick={handleCancel}
                        disabled={false}
                        loading={false}
                      >
                        {cancelButtonText}
                      </LoadingButton>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      mode="cancel"
                      sx={{ letterSpacing: 0.7 }}
                      onClick={handleAcknowledge}
                      disabled={false}
                      loading={false}
                    >
                      {acknowledgeButtonText}
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}

              {formValidationAlertType === FormValidationAlertType.CONFIRM && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  columnSpacing={1}
                  sx={{ mt: 1 }}
                >
                  {handleCancel && (
                    <Grid item>
                      <LoadingButton
                        variant="outlined"
                        mode="cancel"
                        onClick={handleCancel}
                        disabled={false}
                        loading={false}
                      >
                        {cancelButtonText}
                      </LoadingButton>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      mode="cancel"
                      sx={{ letterSpacing: 0.7 }}
                      onClick={handleAcknowledge}
                      disabled={false}
                      loading={false}
                    >
                      {acknowledgeButtonText}
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default FormValidationAlert
