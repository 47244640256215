import { Button, CircularProgress, Skeleton, Typography } from '@mui/material'

import { useTrayDetails } from './TrayDetails.logic'

import './TrayDetails.scss'
import { Link } from 'react-router-dom'
import { ArrowBack, Delete } from '@mui/icons-material'
import TrayProduct from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/TrayProduct'
import { LoadingButton } from 'components/mui'

const TrayDetails = () => {
  const {
    loading,
    error,
    trayItem,
    trayDetails,
    deleting,
    handleDeleteTray,
    handleEditTrayClick,
  } = useTrayDetails()

  return (
    <div className="tray-details_wrapper">
      <div data-testid="header" className="header">
        <div className="buttons_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            to=".."
            component={Link}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <div className="right_buttons">
            <LoadingButton variant="outlined" onClick={handleEditTrayClick}>
              Edit Tray
            </LoadingButton>

            <LoadingButton
              className="delete"
              dataTestId="delete-tray-button"
              loading={deleting}
              disabled={deleting}
              mode="delete"
              sx={{ width: deleting ? '50px' : '30px' }}
              onClick={handleDeleteTray}
            >
              <Delete sx={{ width: '17px', height: '17px' }} />
            </LoadingButton>
          </div>
        </div>

        <Typography variant="h1">Tray Details</Typography>
      </div>

      <div className="content">
        {loading && !error && <CircularProgress />}

        {error && !loading && (
          <Typography className="error" variant="body1">
            {error.message}
          </Typography>
        )}

        {!error && !loading && trayItem && (
          <>
            <div className="img_container">
              <Skeleton
                animation="wave"
                variant="rounded"
                className="skeleton"
              />

              <img src={trayDetails?.trayImage} alt={trayItem.description} />
            </div>

            {trayDetails?.screws.length === 0 && (
              <Typography variant="body2">No screws in this tray</Typography>
            )}

            {trayDetails?.screws.map((screw, _index) => (
              <TrayProduct
                key={`${screw.deviceId}${_index}`}
                {...screw}
                showDispositionStatus={false}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default TrayDetails
