import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ItemResponse } from 'common/types'
import { BetterIDTrayScrew, TrayPlate } from 'views/SPDLayout/SPD/SPD.types'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import {
  AssignedDigitalTraysProps,
  UsagePlate,
} from './AssignedDigitalTrays.types'

const AssignedDigitalTraysContext = createContext<
  AssignedDigitalTraysProps | undefined
>(undefined)

export const AssignedDigitalTraysProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const { surgeryId } = useParams()

  const [trays, setTrays] = useState<ItemResponse[]>([])
  const [selectedTray, setSelectedTray] = useState<ItemResponse | null>(null)
  const [spdScrews, setSpdScrews] = useState<BetterIDTrayScrew[]>([])
  const [usageScrews, setUsageScrews] = useState<BetterIDTrayScrew[]>([])
  const [usagePlates, setUsagePlates] = useState<UsagePlate[]>([])

  const trayDetails = extractTrayJSONData(selectedTray as ItemResponse)

  useEffect(() => {
    setUsageScrews([])
    if (selectedTray) {
      const screwsFromSpdTray = JSON.parse(
        selectedTray.productDetails as string
      )?.screws
      setSpdScrews(screwsFromSpdTray)
    } else {
      setSpdScrews([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTray])

  return (
    <AssignedDigitalTraysContext.Provider
      value={{
        trays,
        setTrays,
        selectedTray,
        setSelectedTray,
        surgeryId: surgeryId as string,
        spdScrews,
        setSpdScrews,
        usageScrews,
        setUsageScrews,
        usagePlates,
        setUsagePlates,
        trayDetails,
      }}
    >
      {children}
    </AssignedDigitalTraysContext.Provider>
  )
}

export const useAssignedDigitalTrays = () => {
  const context = useContext(AssignedDigitalTraysContext)

  if (context === undefined) {
    throw new Error(
      'useAssignedDigitalTrays must be used with AssignedDigitalTraysProvider'
    )
  }

  return context
}
