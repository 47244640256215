import { Box, CircularProgress } from '@mui/material'
import { DispositionedAssetDetails } from 'components/organisms/DispositionedAssetDetails/DispositionedAssetDetails'
import { AccurateDeviceCountModule } from 'components/organisms/AccurateDeviceCountModule/AccurateDeviceCountModule'
import { AssetGroupCount } from 'common/types'
import { useRfSpongeCount } from 'lib/context/RfSpongeCountContext/RfSpongeCountContext'
import { EditAssetButton } from 'components/organisms/EditAssetButton/EditAssetButton'
import { RfSpongeCountPanelProps } from 'lib/context/RfSpongeCountContext/RfSpongeCountContext.types'

export const RfSpongeCountPanel = ({
  value,
  label,
  spongeCount,
  isProcedureSubmitted,
  handleDelete,
  isLoading,
  isManualAddition,
  assetGroup,
  assetTray,
  surgeryId,
}: RfSpongeCountPanelProps) => {
  const {
    rfSpongeAssetGroupCount,
    saveMemoNote,
    addAssetGroupsCountMutation,
    updateAssetGroupCounts,
  } = useRfSpongeCount()

  const disabled = isProcedureSubmitted || addAssetGroupsCountMutation?.loading

  const mapToPass = rfSpongeAssetGroupCount.find(
    (group) => group._id === assetGroup._id
  ) as AssetGroupCount

  const handleSubmit = () => {
    updateAssetGroupCounts(assetGroup._id, {
      memo: mapToPass?.memo,
      accurateDeviceCount: mapToPass?.accurateDeviceCount,
      deviceCountCorrectiveActionTaken:
        mapToPass?.deviceCountCorrectiveActionTaken,
    })
    saveMemoNote()
  }

  return (
    <>
      {addAssetGroupsCountMutation?.loading && (
        <Box width="100%" padding={2}>
          <CircularProgress />
        </Box>
      )}

      {!addAssetGroupsCountMutation?.loading && (
        <DispositionedAssetDetails
          key={label}
          value={value}
          label={label}
          additionalLabels={[`Total: ${spongeCount} sponges`]}
          handleDelete={handleDelete}
          isLoading={isLoading}
          editComponent={
            <EditAssetButton
              isManualAddition={isManualAddition}
              bidAssetId={assetGroup.bidAssetId}
              assetgroupId={assetGroup._id}
              isProcedureSubmitted={isProcedureSubmitted}
              surgeryId={surgeryId}
              assetTray={assetTray}
              consumableAssetGroup={assetGroup}
              isMultipack={true}
            />
          }
        >
          <AccurateDeviceCountModule
            radioButtonDisabled={disabled}
            isLoading={addAssetGroupsCountMutation?.loading}
            handleSubmit={handleSubmit}
            memo={mapToPass?.memo}
            radioButtonLabel="Sponge Count Correct"
            assetGroupId={assetGroup._id}
            assetGroupCountMap={mapToPass}
            updateAssetGroupCount={updateAssetGroupCounts}
          />
        </DispositionedAssetDetails>
      )}
    </>
  )
}
