import {
  Box,
  DialogProps,
  Modal as ModalComponent,
  Typography,
} from '@mui/material'
import './Modal.scss'
import { NotificationsNone } from '@mui/icons-material'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { FC } from 'react'
import { ButtonSet } from 'components/atoms/ButtonSet/ButtonSet'
import { CustomModalProps } from './Modal.types'
import classNames from 'classnames'

const Modal: FC<DialogProps> = ({
  open,
  onClose,
  children,
  className,
  ...props
}) => (
  <ModalComponent
    open={open}
    onClose={onClose}
    className={classNames(`custom-modal ${className}`)}
    {...props}
  >
    <>{children}</>
  </ModalComponent>
)

export default Modal

export const CustomModal = ({
  open,
  secondaryButtonAction,
  dataTestId,
  children,
  header,
  primaryButtonDisabled,
  isLoadingState,
  handleOnSubmit,
  subheader,
  useAnalyzerState,
  isSubmitting,
  primaryButtonText = 'Submit',
  analyzingStateNode,
  icon = <NotificationsNone />,
  closeButtonOnly = false,
}: CustomModalProps) => {
  const isMobile = useIsMobile()

  return (
    <ModalComponent
      data-testid={`${dataTestId}-modal`}
      open={open}
      onClose={secondaryButtonAction}
      disableRestoreFocus
    >
      <Box className={`parent-modal-box ${isMobile ? 'isMobile' : ''}`}>
        <Box className={`header-box`}>
          {icon}
          <Typography variant="h3" sx={{ textTransform: 'none' }}>
            {header}
          </Typography>
        </Box>
        {subheader && (
          <Typography
            variant="h6"
            className={`subheader-box ${isMobile ? 'isMobile' : ''}`}
          >
            {subheader}
          </Typography>
        )}

        <Box className={`child-box`} data-testid={`${dataTestId}-children`}>
          {children}
        </Box>
        {useAnalyzerState && isSubmitting ? (
          <Box className={'analyze-state'}>{analyzingStateNode}</Box>
        ) : (
          <ButtonSet
            dataTestId={dataTestId}
            isLoadingState={isLoadingState}
            secondaryButtonAction={secondaryButtonAction}
            primaryButtonDisabled={primaryButtonDisabled}
            handleOnSubmit={handleOnSubmit}
            secondaryButtonDisabled={isLoadingState}
            primaryButtonText={primaryButtonText}
            closeButtonOnly={closeButtonOnly}
          />
        )}
      </Box>
    </ModalComponent>
  )
}
