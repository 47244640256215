import {
  Select,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  Skeleton,
  Button,
  InputAdornment,
} from '@mui/material'
import {
  ArrowBack,
  CameraAlt,
  FlipCameraIos,
  QrCode,
} from '@mui/icons-material'

import SPDCortexScanner from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner'
import DialogDrawer from 'components/molecules/Drawer/DialogDrawer'
import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'
import useTrayConfiguration from '../../../views/TrayManagementLayout/TrayManagement/TrayManagement.logic'
import TrayAnalysisContent from '../TrayAnalysisContent/TrayAnalysisContent'
import useAddTrayModal from './AddTrayModal.logic'
import { useAuth0 } from 'app/Auth'
import dayjs from 'lib/dayjs'

import TraySVG from 'assets/icons/svgs/Tray.svg'
import { AddTrayModalProps } from './AddTrayModal.types'
import { AddTraySelectType } from '../AddTraySelectType/AddTraySelectType'
import { trayTypes } from '../AddTraySelectType/AddTraySelectType.data'
import { TrayType } from '../AddTraySelectType/AddTraySelectType.types'
import './AddTrayModal.scss'

/**
 * AddTrayModal is the view responsible for creating a new tray item in the mgat-iservices database
 * @param open The open state of the add tray modal
 * @param onClose The onClose function that is triggered when the modal receives a close event
 */
const AddTrayModal = ({ open, onClose }: AddTrayModalProps) => {
  const {
    trayDeviceUDI,
    handleTrayUDIChange,
    trayDescription,
    handleTrayDescriptionChange,
    isCreatingTrayItem,
    selectedLocationId,
    handleSelectedLocationChange,
    resetTrayCategoryType,
    showScanner,
    handleCortexScan,
    handleShowScannerButtonClick,
    showCameraCapture,
    handleShowCameraCaptureClick,
    handleSPDCaptureImage,
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
    dropoffTime,
    handleSubmit,
    isUploadingImage,
    trayVendor,
    handleTrayVendorChange,
    handleAnalysisClick,
    isTrayMapDrawerOpen,
    isSubmitting,
    isComplete,
    trayScrews,
    handleTrayMapDrawerClose,
  } = useAddTrayModal({ onClose })
  const { locationsWithParents, findLocation } = useTrayConfiguration()
  const { selectedTrayCategory } = useTrayConfigurationContext()
  const { user } = useAuth0()

  const selectedTrayCategoryData: TrayType | null = selectedTrayCategory
    ? (trayTypes.find(
        (trayType) => trayType.category === selectedTrayCategory
      ) as TrayType)
    : null

  return (
    <Modal open={open} onClose={onClose}>
      <div data-testid="add-tray-modal" className="add-tray-modal">
        <div data-testid="add-tray-modal_header" className="header-box">
          <img src={TraySVG} alt="tray" />

          <Typography variant="h3">Add tray details</Typography>
        </div>

        <div className="content">
          {!selectedTrayCategory ? (
            <AddTraySelectType />
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                className="back-button"
                onClick={resetTrayCategoryType}
              >
                <ArrowBack sx={{ mr: 0.5 }} /> BACK
              </Button>
              <div className="tray-type_container">
                <img
                  src={selectedTrayCategoryData?.icon}
                  alt={selectedTrayCategoryData?.label}
                />

                <Typography variant="h2" className="label">
                  {selectedTrayCategoryData?.label}
                </Typography>
              </div>

              {selectedTrayCategory !== 'facility' && (
                <>
                  {dropoffTime && (
                    <Typography variant="body2" className="dropoff-details">
                      Dropped off at:{' '}
                      {dayjs(new Date(dropoffTime)).format('LLLL')}
                    </Typography>
                  )}

                  {user && (
                    <Typography variant="body2" className="dropoff-details">
                      Dropped off by: {user.name}
                    </Typography>
                  )}
                </>
              )}

              {showScanner && (
                <div className="scanner-container">
                  <SPDCortexScanner canScan={open} onScan={handleCortexScan} />

                  <Skeleton
                    className="skeleton"
                    variant="rounded"
                    animation="wave"
                  />
                </div>
              )}

              <TextField
                data-testid="add-tray-device-udi_input"
                id="input-with-icon-textfield"
                autoComplete="off"
                placeholder="Enter Tray ID (required)"
                fullWidth
                type="text"
                value={trayDeviceUDI}
                onChange={handleTrayUDIChange}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingButton
                        className="show-scanner_button"
                        disabled={false}
                        loading={false}
                        onClick={handleShowScannerButtonClick}
                      >
                        <QrCode />
                      </LoadingButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                data-testid="add-tray-description_input"
                id="input-with-icon-textfield"
                autoComplete="off"
                placeholder="Enter Tray Description (required)"
                fullWidth
                type="text"
                value={trayDescription}
                onChange={handleTrayDescriptionChange}
                variant="outlined"
              />

              <FormControl fullWidth>
                <Select
                  data-testid="add-tray-location-storage_input"
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Typography variant="body1" color="gray">
                          Select Tray Storage Location (required)
                        </Typography>
                      )
                    }

                    return findLocation(selected as number)?.name
                  }}
                  value={selectedLocationId}
                  onChange={handleSelectedLocationChange}
                >
                  <MenuItem disabled value="">
                    Select Tray Storage Location
                  </MenuItem>

                  {locationsWithParents.map((location) => (
                    <MenuItem
                      data-testid="hardware-menuitem"
                      style={{ cursor: 'pointer', fontSize: 16 }}
                      key={location.id}
                      value={location.id}
                    >
                      {location.name},{' '}
                      {findLocation(location.parentLocationId as number)?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  You can create new locations from the location management
                  dashboard
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <Select
                  data-testid="add-tray-location-storage_input"
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Typography variant="body1" color="gray">
                          Select Tray{' '}
                          {selectedTrayCategory === 'facility'
                            ? 'Manufacturer'
                            : 'Vendor'}
                        </Typography>
                      )
                    }

                    return selected
                  }}
                  value={trayVendor}
                  onChange={handleTrayVendorChange}
                >
                  <MenuItem disabled value="">
                    Select Tray{' '}
                    {selectedTrayCategory === 'facility'
                      ? 'Manufacturer'
                      : 'Vendor'}
                  </MenuItem>

                  <MenuItem
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    value="Stryker"
                  >
                    Stryker
                  </MenuItem>
                </Select>
              </FormControl>

              <div className="buttons_container">
                <LoadingButton
                  disabled={true} // This button is just static for the moment
                  loading={false}
                  customIcon={<CameraAlt />}
                >
                  Instrument Tray/ Control Sheet Image
                </LoadingButton>

                <LoadingButton
                  disabled={false}
                  loading={false}
                  customIcon={<CameraAlt />}
                  onClick={handleShowCameraCaptureClick}
                >
                  {image
                    ? 'Retake Tray Image'
                    : showCameraCapture
                    ? 'Close Tray Capture'
                    : 'Implantable Tray Image'}
                </LoadingButton>
              </div>

              {showCameraCapture && image.length === 0 && (
                <div className="camera-container">
                  {videoRef === null || mediaStream === null ? (
                    <Skeleton className="feed" />
                  ) : (
                    <>
                      <FormControl
                        fullWidth
                        className="camera-select_container"
                      >
                        <LoadingButton
                          loading={false}
                          disabled={false}
                          onClick={handleToggleCameraMenu}
                          sx={{ zIndex: 9999 }}
                        >
                          <FlipCameraIos />
                        </LoadingButton>

                        <div
                          className="camera-options"
                          style={{
                            display: isCameraMenuOpen ? 'flex' : 'none',
                            opacity: isCameraMenuOpen ? 1 : 0,
                          }}
                        >
                          {cameras.map((camera: any) => {
                            const isActive =
                              camera.deviceId === activeCamera?.deviceId

                            return (
                              <div
                                key={camera.deviceId}
                                className={`camera ${isActive ? 'active' : ''}`}
                                onClick={() => handleSelectCamera(camera)}
                              >
                                <p className="label">{camera.label}</p>

                                <p className="type">{camera.cameraType}</p>
                              </div>
                            )
                          })}
                        </div>
                      </FormControl>

                      <LoadingButton
                        loading={false}
                        disabled={false}
                        onClick={() => handleSPDCaptureImage(true)}
                        className="capture-photo_button"
                      >
                        <CameraAlt />
                      </LoadingButton>

                      <video
                        className="feed"
                        ref={videoRef}
                        autoPlay
                        muted
                        playsInline
                      />
                    </>
                  )}
                </div>
              )}

              {image && (
                <img
                  src={image}
                  alt="Tray Capture"
                  className="captured-image"
                />
              )}

              {selectedTrayCategory !== 'facility' && (
                <LoadingButton
                  dataTestId="add-tray-submit_button"
                  loading={isSubmitting}
                  disabled={!image || isSubmitting}
                  onClick={handleAnalysisClick}
                  variant="outlined"
                >
                  {isComplete
                    ? 'View Analysis'
                    : 'Upload Implantable Tray Image for Analysis'}
                </LoadingButton>
              )}

              <DialogDrawer
                open={isTrayMapDrawerOpen}
                onClose={handleTrayMapDrawerClose}
                height="95%"
              >
                <TrayAnalysisContent onClose={handleTrayMapDrawerClose} />
              </DialogDrawer>

              <LoadingButton
                dataTestId="add-tray-submit_button"
                disabled={
                  isCreatingTrayItem ||
                  !trayDeviceUDI ||
                  !trayDescription ||
                  !selectedLocationId ||
                  !image ||
                  isUploadingImage ||
                  !trayVendor ||
                  (trayScrews.length === 0 &&
                    selectedTrayCategory !== 'facility')
                }
                loading={isCreatingTrayItem || isUploadingImage}
                onClick={handleSubmit}
              >
                Submit Tray(s)
              </LoadingButton>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AddTrayModal
