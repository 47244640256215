import { CircularProgress, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import {
  ErrorMessage,
  Info,
  MainContainer,
  QRCodeContainer,
  SwitchContainer,
} from './styles'
import { useGetInviteRepQrCodeQuery } from 'lib/apollo/hooks'
import { InviteRepProps } from './types'

const InviteRep: React.FC<InviteRepProps> = ({ surgeryId }) => {
  const [canAddAsset, setCanAddAsset] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [navigationUrl, setNavigationUrl] = useState<string>('')

  const {
    loading,
    data,
    error: fetchError,
    refetch,
  } = useGetInviteRepQrCodeQuery(canAddAsset, surgeryId)

  useEffect(() => {
    if (loading || !data) return
    if (data.getInviteRepQrCode && !data.getInviteRepQrCode.success) {
      setError(`Error: ${data.getInviteRepQrCode.message}`)
      return
    }
    if (fetchError) {
      setError(`Error: ${fetchError.message}`)
      return
    }
    setNavigationUrl(
      `${window.location.protocol}//${window.location.host}/invite/${data.getInviteRepQrCode.data.jwt}`
    )
  }, [loading, data, fetchError])

  useEffect(() => {
    refetch({ canAddAsset, surgeryId })
  }, [canAddAsset, refetch, surgeryId])

  return (
    <>
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <MainContainer>
          <SwitchContainer>
            Allow sales rep to add assets?
            <Switch
              checked={canAddAsset}
              onChange={() => setCanAddAsset((prev) => !prev)}
            />
          </SwitchContainer>
          <QRCodeContainer>
            {loading ? (
              <CircularProgress />
            ) : (
              <QRCode fgColor="var(--primary)" value={navigationUrl} />
            )}
          </QRCodeContainer>
          <Info>
            A sales rep can capture this code to approve the list of assets.
          </Info>
        </MainContainer>
      )}
    </>
  )
}

export default InviteRep
