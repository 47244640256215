import {
  FormValidationAlertType,
  FormValidationAlertTypeMapping,
} from 'common/types'
import { getEnumValueByKey } from 'lib/utils/enumUtils'

export const getAlertType = (alert: any): FormValidationAlertType => {
  const alertType = getEnumValueByKey(
    alert.path[0]?.split('-')[0],
    Object.entries(FormValidationAlertTypeMapping)
  ) as FormValidationAlertType
  return alertType
}
