import { Box, Typography } from '@mui/material'
import './ErrorBox.css'

interface ErrorBoxProps {
  error: string
}

const ErrorBox = ({ error }: ErrorBoxProps) => (
  <Box
    sx={{
      width: '95%',
      position: 'relative',
      maxHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      bgcolor: 'white',
      borderRadius: '20px',
      p: '10px 30px',
    }}
  >
    <Typography fontSize={25} color="#11142D" fontWeight={700} mb={2}>
      Error
    </Typography>

    <Typography
      fontSize={18}
      color="#11142D"
      fontWeight={300}
      width="100%"
      textAlign="left"
      fontFamily="Source Code Pro, monospace"
      mb={2}
      sx={{
        bgcolor: '#F0F0F0',
        borderRadius: 'inherit',
        p: '10px',
      }}
    >
      {error}
    </Typography>
  </Box>
)

export default ErrorBox
