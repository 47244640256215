import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { useAuth0 } from 'app/Auth'

export const getEndpoint = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_GRAPHQL_DEV + 'graphql'
  }
  return process.env.REACT_APP_GRAPHQL_PROD + 'graphql'
}

const AWS_REGION = process.env.REACT_APP_AWS_REGION ?? 'us-east-1'

export default function InventoryProvider({ children }) {
  const { getAccessToken } = useAuth0()

  const client = useMemo(() => {
    const auth = {
      type: 'OPENID_CONNECT',
      jwtToken: async () => {
        const token = await getAccessToken()
        return `Bearer ${token}`
      },
    }

    const httpLink = createHttpLink({ uri: getEndpoint() })

    const link = ApolloLink.from([
      createAuthLink({ apiUrl: getEndpoint(), region: AWS_REGION, auth }),
      createSubscriptionHandshakeLink(
        { apiUrl: getEndpoint(), region: AWS_REGION, auth },
        httpLink
      ),
    ])

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        addTypename: false,
      }),
      connectToDevTools: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
