import { useEffect } from 'react'
import { useControls, useTransformContext } from 'react-zoom-pan-pinch'

const useTrayProcedureEditModeLogic = () => {
  const { zoomIn, zoomOut, setTransform, resetTransform } = useControls()
  const transformContext = useTransformContext()

  useEffect(() => {
    resetTransform()
  }, [])

  const handlePan = (direction: 'up' | 'down' | 'left' | 'right') => {
    if (transformContext.mounted) {
      const panStep = 130
      const {
        positionX,
        positionY,
        previousScale: scale,
      } = transformContext.getContext().state

      switch (direction) {
        case 'up':
          setTransform(positionX, positionY - panStep, scale)
          break
        case 'down':
          setTransform(positionX, positionY + panStep, scale)
          break
        case 'left':
          setTransform(positionX - panStep, positionY, scale)
          break
        case 'right':
          setTransform(positionX + panStep, positionY, scale)
          break
      }
    }
  }

  return {
    handlePan,
    zoomIn,
    zoomOut,
    resetTransform,
  }
}

export default useTrayProcedureEditModeLogic
