import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSPD } from './SPD.context'

export const useSPDHook = () => {
  const navigate = useNavigate()
  const {
    trayData,
    setShowTrayDetails,
    setShowSurgeryChangeModal,
    setShowChooseTrayLocationModal,
  } = useSPD()

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])

  useEffect(() => {
    if (!trayData) {
      navigate('/spd')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayData])

  const handleClick = () => {
    setShowTrayDetails(true)
  }

  const handleSurgeryClick = () => {
    setShowSurgeryChangeModal(true)
  }

  const handleLocationClick = () => {
    setShowChooseTrayLocationModal(true)
  }

  return {
    handleLocationClick,
    handleSurgeryClick,
    handleClick,
  }
}
