import Paper from '@mui/material/Paper/Paper'
import { GenericObject } from 'common/types'
import styles from './paperModal.module.css'

type Props = {
  backgroundColor: string
  children: JSX.Element[] | JSX.Element
  flexDirection?: string
  positionAboveBottomNavigation?: boolean
  position?: GenericObject
}

function PaperModal({
  backgroundColor = 'white',
  children,
  flexDirection = 'column',
  positionAboveBottomNavigation,
  position = undefined,
}: Props) {
  let positionValue
  if (positionAboveBottomNavigation) positionValue = { bottom: '5em' }
  if (position) positionValue = position

  const paperProps = {
    backgroundColor: backgroundColor,
    flexDirection: flexDirection,
    ...positionValue,
  }

  return (
    <Paper className={styles.paperModal} sx={paperProps} elevation={0}>
      {children}
    </Paper>
  )
}

export default PaperModal
