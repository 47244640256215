import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { TextField, Box, Switch, Alert } from '@mui/material'
import { CompanySearchBar } from '../../CompanySearchBar'
import { FC } from 'react'
import {
  AlertContainer,
  FormContainer,
  Header,
  MainContainer,
  StyledAlert,
  StyledTypography,
  Subtitle,
  Title,
} from './repSignupForm.styles'
import { RepSignUpFormProps } from './repSignupForm.types'
import { useRepSignupFormLogic } from './repSignupForm.logic'

const RepSignupForm: FC<RepSignUpFormProps> = ({ surgeryId, onClose }) => {
  const {
    formData,
    formErrors,
    canAddAsset,
    productRepInviteMutationIsLoading,
    productRepInviteMutationError,
    productRepInviteMutationData,
    isSubmitDisabled,
    setCanAddAsset,
    handleSubmit,
    updateFormData,
    handleMobileInputChange,
  } = useRepSignupFormLogic(surgeryId)

  return (
    <MainContainer>
      {productRepInviteMutationData?.sendProductRepInvite.success ? (
        <StyledAlert severity="success" variant="outlined">
          <AlertContainer>
            <StyledTypography>
              {`Rep Invite sent successfully to ${
                formData.email || "the rep's email"
              } you may go back to your dashboard`}
            </StyledTypography>
            <LoadingButton mode="default" onClick={onClose}>
              Back To Dashboard
            </LoadingButton>
          </AlertContainer>
        </StyledAlert>
      ) : (
        <>
          <Header>
            <Title>Invite Sales Rep</Title>

            <Subtitle>
              Enter the sales rep's email address and select the company that
              they work for.
            </Subtitle>
          </Header>

          <FormContainer onSubmit={handleSubmit} noValidate autoComplete="off">
            <TextField
              id="email"
              label="Email Address"
              name="email"
              inputMode="text"
              value={formData.email}
              helperText={formErrors?.email}
              onChange={(e) =>
                updateFormData(e.target.name, e.target.value.toLowerCase())
              }
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.email}
              required
            />

            <CompanySearchBar
              handleChangeFormData={(e) => {
                updateFormData(e.target.name, e.target.value)
              }}
              manualAssetInputFormData={formData}
              variant="outlined"
              disabled={productRepInviteMutationIsLoading}
            />

            <TextField
              id="firstName"
              label="First Name"
              name="firstName"
              inputMode="text"
              helperText={formErrors?.firstName}
              value={formData.firstName}
              onChange={(e) => updateFormData(e.target.name, e.target.value)}
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.firstName}
              required
            />

            <TextField
              id="lastName"
              label="Last Name"
              name="lastName"
              inputMode="text"
              helperText={formErrors?.lastName}
              value={formData.lastName}
              onChange={(e) => updateFormData(e.target.name, e.target.value)}
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.lastName}
              required
            />

            <TextField
              id="mobile"
              label="Mobile"
              name="mobile"
              inputMode="text"
              helperText={formErrors?.mobile}
              value={formData.mobile}
              onChange={handleMobileInputChange}
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.mobile}
              required
            />

            {surgeryId ? (
              <Box
                sx={{
                  mx: 'auto',
                  backgroundColor: '#ebf3fb',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  padding: '0.3em 1em',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Allow sales rep to add assets?
                <Switch
                  checked={canAddAsset}
                  onChange={() => setCanAddAsset(!canAddAsset)}
                  disabled={productRepInviteMutationIsLoading}
                />
              </Box>
            ) : null}

            {productRepInviteMutationError && (
              <Alert severity="error" variant="filled" sx={{ mb: 3 }}>
                {productRepInviteMutationError instanceof Error
                  ? productRepInviteMutationError.message
                  : 'Failed to send invite'}
              </Alert>
            )}

            <LoadingButton
              style={{ height: '50px' }}
              loadingText="Sending invite..."
              type="submit"
              loading={productRepInviteMutationIsLoading}
              mode="default"
              disabled={
                productRepInviteMutationIsLoading ? false : isSubmitDisabled
              }
            >
              Sign Up
            </LoadingButton>
          </FormContainer>
        </>
      )}
    </MainContainer>
  )
}

export default RepSignupForm
