import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { useIsTablet } from 'lib/utils/mediaQueries'
import { IDetailItem } from './DetailsItem.types'

const DetailsItem: FC<IDetailItem> = ({ value, label, isExpired }) => {
  const isTablet = useIsTablet()

  if (isTablet) {
    return (
      <Grid item xs={6} md={12} className="details-item">
        <p
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '4px',
          }}
        >
          <Typography className="key" variant="caption" fontWeight={600}>
            {label}
          </Typography>
          <Typography
            style={{ color: isExpired ? 'red' : '' }}
            variant="caption"
            fontWeight={600}
          >
            {value}
          </Typography>
        </p>
      </Grid>
    )
  }

  return (
    <p className="details-item">
      <Typography variant="caption" fontWeight={600} className="key">
        {label}
      </Typography>
      <Typography
        variant="caption"
        fontWeight={600}
        style={{ color: isExpired ? 'red' : '' }}
      >
        {value}
      </Typography>
    </p>
  )
}

export default DetailsItem
