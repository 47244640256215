import { useAuth0 } from 'app/Auth'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import {
  AssetReportsInput,
  AssetReportOutput,
} from 'views/AssetsReports/AssetsReports.types'

export const useGetAssetReports = (data: AssetReportsInput) => {
  const { getAccessToken } = useAuth0()
  const tokenPromise = getAccessToken()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL

  return useQuery<AssetReportOutput[], AxiosError | any>(
    ['assetReports', data],
    async () => {
      const token = await tokenPromise
      try {
        const response = await axios.get(
          `${REPORTS_URL}api/v1/reports/asset_groups`,
          {
            params: { ...data, enabled: undefined },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )

        return response.data.asset_groups
      } catch (error) {
        throw error
      }
    }
  )
}
