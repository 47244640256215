/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAssignedDigitalTrays } from '../../AssignedDigitalTrays.context'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useBetterIdLookupQuery } from 'lib/apollo/hooks'
import { bidSearchResponseToResults } from 'lib/utils/betterid'

import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { BetterIdResult } from 'common/types'
import { UsagePlateWithDescription } from '../TrayProducts.types'
import {
  PlateKey,
  plateDescriptionMap,
} from 'views/SPDLayout/SPD/TrayMap/TrayMap.types'

export const useTrayProducts = () => {
  const navigate = useNavigate()

  const { addUndocumentedScan, deleteScans, undocumentedScans } =
    useUndocumentedAssetsContext()
  const { usageScrews, selectedTray, usagePlates } = useAssignedDigitalTrays()

  const [codeValue, setCodeValue] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [wastedScrews, setWastedScrews] = useState<BetterIDTrayScrew[]>([])
  const [implantedScrews, setImplantedScrews] = useState<BetterIDTrayScrew[]>(
    []
  )
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const [surgeryPlateIndex, setSurgeryPlateIndex] = useState<number>(-1)
  const [dtmUndocumentedScanIds, setDtmUndocumentedScanIds] = useState<
    string[]
  >([])
  const [surgeryUsagePlates, setSurgeryUsagePlates] = useState<
    UsagePlateWithDescription[]
  >([])

  useEffect(() => {
    if (undocumentedScans.length > 0) {
      const dtmUndocumentedScanIds: string[] = undocumentedScans
        .filter((scan) => scan.isDTMScrew)
        .map((scan) => scan._id)
      setDtmUndocumentedScanIds(dtmUndocumentedScanIds)
    }
  }, [])

  useBetterIdLookupQuery(codeValue, {
    skip: isSearching === false,
    onCompleted: (data) => {
      setIsSearching(false)
      const selectedResults: BetterIdResult[] = bidSearchResponseToResults(
        data.betterIdLookup
      )
      selectedResults.forEach((selectedResult, _index) => {
        addUndocumentedScan({
          variables: {
            // @ts-ignore - being dumb
            data: {
              bidAssetId: selectedResult.id,
              bidCompanyId: selectedResult.companyId,
              // bidAssetInstanceId: selectedResult.fromAssetInstanceId,
              catalogNumber: selectedResult.catalogNumber,
              deviceDescription: selectedResult.deviceDescription,
              deviceId: selectedResult.deviceId,
              deviceCount: selectedResult.deviceCount,
              count: 1,
              pkgQuantity: selectedResult.pkgQuantity,
              expirationDate: selectedResult.expirationDate,
              lotBatch: selectedResult.lotBatch,
              companyName: selectedResult.company?.name,
              manufacturingDate: selectedResult.manufacturingDate,
              versionModelNumber: selectedResult.versionModelNumber,
              serialNumber: selectedResult.serialNumber,
              sizeText: selectedResult.sizeText,
              sizeString: selectedResult.sizeString,
              udi: selectedResult.udi,
              issuingAgency: selectedResult.issuingAgency,
              secondaryDeviceIdType: selectedResult.secondaryDeviceIdType,
              assetType: 'non-biological',
              isDTMScrew: true,
              dtmScrewData: {
                column: isFinished ? null : usageScrews[currentIndex].x,
                row: isFinished ? null : usageScrews[currentIndex].row,
                label: isFinished ? null : usageScrews[currentIndex].label,
                wasted: isFinished
                  ? surgeryUsagePlates[surgeryPlateIndex].wasted
                  : usageScrews[currentIndex].wasted,
                trayId: selectedTray?.id,
                size: isFinished ? null : usageScrews[currentIndex].column,
              },
            },
          },
        })

        setIsSearching(false)
        if (!isFinished) {
          setCurrentIndex((prevIndex) => prevIndex + 1)
        } else {
          setSurgeryPlateIndex((prevIndex) => prevIndex + 1)
        }

        if (currentIndex === usageScrews.length - 1 && !isFinished) {
          setSurgeryPlateIndex(0)
          setIsFinished(true)

          if (surgeryUsagePlates.length === 0) {
            navigate(`../asset/batch/undocumented`)
          }
        }

        if (
          (surgeryUsagePlates.length === 0 && isFinished) ||
          (surgeryUsagePlates.length > 0 &&
            surgeryPlateIndex === surgeryUsagePlates.length - 1)
        ) {
          navigate(`../asset/batch/undocumented`)
        }
      })
    },
  })

  useEffect(() => {
    setWastedScrews(usageScrews?.filter((trayProduct) => trayProduct.wasted))
    setImplantedScrews(
      usageScrews?.filter((trayProduct) => !trayProduct.wasted)
    )
  }, [])

  useEffect(() => {
    if (usageScrews.length - 1 >= currentIndex && currentIndex >= 0) {
      setCodeValue(usageScrews[currentIndex]?.deviceId as string)
      setIsSearching(true)
    } else if (isFinished) {
      if (surgeryUsagePlates.length - 1 >= surgeryPlateIndex) {
        setCodeValue(surgeryUsagePlates[surgeryPlateIndex]?.deviceId as string)
        setIsSearching(true)
      }
    }
  }, [currentIndex, isFinished, surgeryPlateIndex])

  useEffect(() => {
    const newSurgeryUsagePlates: UsagePlateWithDescription[] = usagePlates.map(
      (usagePlate) => {
        const plateDescription = findPlateDescriptionByKey(usagePlate.plateName)

        return {
          ...usagePlate,
          description: plateDescription,
        }
      }
    )

    setSurgeryUsagePlates(newSurgeryUsagePlates)
  }, [usagePlates.length])

  const handleSubmit = () => {
    if (undocumentedScans.length > 0) {
      deleteScans(dtmUndocumentedScanIds)
    }

    if (usageScrews.length > 0) {
      setCurrentIndex(0)
      setIsSearching(true)
    } else {
      setIsFinished(true)
      setSurgeryPlateIndex(0)
    }
  }

  const findPlateDescriptionByKey = (plateName: PlateKey) => {
    return plateDescriptionMap[plateName]
  }

  return {
    implantedScrews,
    wastedScrews,
    handleSubmit,
    isSearching,
    surgeryUsagePlates,
  }
}
