import { Typography } from '@mui/material'
import { Handyman } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import { ReloadScrewModalProps } from '../../TrayVisualization.types'
import './ReloadScrewModal.scss'

const ReloadScrewModal = ({
  open,
  onClose,
  selectedScrew,
  handleReloadScrew,
}: ReloadScrewModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="tray-visualization-reload-screw_modal">
        <div className="header-box">
          <Handyman />

          <Typography variant="h3">
            Are you sure you want to reload the following screw?
          </Typography>
        </div>

        <div className="screw-data-container">
          <Typography variant="body2">- {selectedScrew?.label}</Typography>
          <Typography variant="body2">- Row: {selectedScrew?.row}</Typography>
          <Typography variant="body2">
            - Size: {selectedScrew?.column}
          </Typography>
        </div>

        <div className="buttons-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            mode="cancel"
            onClick={onClose}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            disabled={false}
            loading={false}
            onClick={handleReloadScrew}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default ReloadScrewModal
