import { useCallback, useState } from 'react'
import { uniqueId } from 'lodash'
import toast from 'react-hot-toast'
import { useIsTablet } from 'lib/utils/mediaQueries'

export type IScanningResult = {
  barcodeData: string
  symbologyName: string
}

export const useLogic = () => {
  const [image, setImage] = useState<string | undefined>()
  const [scanningResult, setScanningResult] = useState<IScanningResult[]>([])
  const [open, setOpen] = useState(false)
  const isTablet = useIsTablet()

  const ROTATION_ANGLES = [0, 90, 180, 270]

  const startDecoding = () => {
    CortexDecoder.CDDecoder.setDecoding(true)
  }

  const stopDecoding = () => {
    CortexDecoder.CDDecoder.setDecoding(false)
  }

  const handleOpenCaptureModal = () => {
    setOpen(true)
  }

  const handleCloseCaptureModal = () => {
    setOpen(false)
  }

  const handleRemove = useCallback(
    (removeIndex: number) => {
      setScanningResult((prev) => {
        const result = prev.filter((item, index) => index !== removeIndex)
        if (result.length === 0) {
          setImage(undefined)
          return []
        }
        return result
      })
    },
    [setImage, setScanningResult]
  )

  const rotateImage = async (base64Image: string): Promise<string> => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()

    return new Promise<string>((resolve) => {
      image.onload = () => {
        canvas.width = image.width
        canvas.height = image.height
        ctx?.drawImage(image, 0, 0, image.width, image.height)
        ctx?.translate(image.width / 2, image.height / 2)
        ROTATION_ANGLES.forEach((angle) => ctx?.rotate((angle * Math.PI) / 180))
        ctx?.drawImage(
          image,
          -image.width / 2,
          -image.height / 2,
          image.width,
          image.height
        )

        resolve(canvas.toDataURL('image/png'))
      }

      image.src = base64Image
    })
  }

  const decodeQR = async (file: File): Promise<IScanningResult[] | null> => {
    try {
      startDecoding()
      await CortexDecoder.CDDecoder.setBarcodesToDecode(4, false)
      const qrCodes = await CortexDecoder.CDDecoder.decode(file)
      if (qrCodes) {
        return qrCodes.results.map((item: any) => ({
          barcodeData: item.barcodeData,
          symbologyName: item.symbologyName,
        }))
      }
      return null
    } catch (error) {
      console.error('Error decoding QR codes:', error)
      return null
    }
  }

  const handleImageProcessing = async (base64String: string) => {
    try {
      const rotatedImage = await rotateImage(base64String)
      const file = dataURLtoFile(
        base64String,
        `capture-${uniqueId().toString()}`
      )
      const qrResults = await decodeQR(file)
      // Re-stop the decoder to avoid scanning by mistake
      // because the scanner is running in the background
      stopDecoding()
      if (qrResults) {
        const uniqueCodes = qrResults.filter(
          (code, index) => qrResults.indexOf(code) === index
        )
        if (uniqueCodes.length) {
          setScanningResult(uniqueCodes)
        }
      } else {
        toast.error('Please Try again. Make sure the image is clear.')
        setImage(undefined)
      }
      return rotatedImage
    } catch (error) {
      console.error('Error processing image:', error)
      throw error
    }
  }

  const dataURLtoFile = (dataURL: string, filename: string): File => {
    const arr = dataURL.split(',')
    const mime = arr[0].match(/:(.*?);/)?.[1]
    const bstr = atob(arr[1])
    const n = bstr.length
    const u8arr = new Uint8Array(n)
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const handleSetImage = async (base64String: string) => {
    setImage(base64String)
    try {
      await handleImageProcessing(base64String)
    } catch (error) {
      console.error('Error handling image:', error)
    }
  }

  return {
    open,
    image,
    handleRemove,
    handleOpenCaptureModal,
    scanningResult,
    handleCloseCaptureModal,
    handleSetImage,
    isTablet,
    stopDecoding,
  }
}
