import { CircularProgress } from '@mui/material'
import { CameraAlt, FlipCameraIos } from '@mui/icons-material'

import SubmitImageDialog from './SubmitImageDialog/SubmitImageDialog'
import ManualAddModal from './ManualAddModal/ManualAddModal'
import InventoryProvider from 'lib/apollo/inventory-config'
import useCaptureCamera from './CaptureCamera.logic'

import './CaptureCamera.scss'

const CaptureCamera = () => {
  const {
    videoRef,
    mediaStream,
    videoHeight,
    orientation,
    isCameraMenuOpen,
    cameras,
    activeCamera,
    handleSelectCamera,
    handleToggleCameraMenu,
    handleManualAddClick,
    handleSubmitModalClose,
    isSubmitModalOpen,
    handleUsageCaptureImage,
    image,
    setImage,
    manualAddModalOpen,
  } = useCaptureCamera()

  if (videoRef === null || mediaStream === null) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <div className="camera-container" style={{ height: videoHeight }}>
        {videoRef === null || mediaStream === null ? (
          <CircularProgress className="spinner" />
        ) : null}

        <video
          className="camera-feed"
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />

        <div
          className="buttons-container"
          style={{
            height: videoHeight,
            justifyContent: orientation === 'landscape' ? 'center' : 'flex-end',
          }}
        >
          <div className="camera-options_container">
            <button className="flip" onClick={handleToggleCameraMenu}>
              <FlipCameraIos className="icon" />
            </button>

            <div
              className="camera-options"
              style={{
                display: isCameraMenuOpen ? 'flex' : 'none',
                opacity: isCameraMenuOpen ? 1 : 0,
              }}
            >
              {cameras.map((camera: any) => (
                <div
                  key={camera.deviceId}
                  className="camera"
                  style={{
                    backgroundColor:
                      camera.deviceId === activeCamera?.deviceId
                        ? '#5035ED'
                        : '#BCB7E1',
                  }}
                  onClick={() => handleSelectCamera(camera)}
                >
                  <p
                    className="label"
                    style={{
                      color:
                        camera.deviceId === activeCamera?.deviceId
                          ? 'white'
                          : '#11142D',
                    }}
                  >
                    {camera.label}
                  </p>

                  <p
                    className="type"
                    style={{
                      color:
                        camera.deviceId === activeCamera?.deviceId
                          ? 'white'
                          : '#11142D',
                    }}
                  >
                    {camera.cameraType}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div
            className="capture-buttons"
            style={{
              alignItems: orientation === 'landscape' ? 'center' : 'flex-end',
            }}
          >
            <button
              className="manual-add"
              style={{
                display: orientation === 'landscape' ? 'none' : 'flex',
              }}
              onClick={handleManualAddClick}
            >
              Manually Add Image
            </button>

            <button
              className="capture"
              style={{
                borderRadius: orientation === 'landscape' ? '50%' : '10px',
                padding: orientation === 'landscape' ? '10px' : '5px 10px',
              }}
              onClick={() => handleUsageCaptureImage()}
            >
              <CameraAlt className="icon" />
            </button>
          </div>
        </div>
      </div>

      <InventoryProvider>
        <SubmitImageDialog
          open={isSubmitModalOpen}
          trayImage={image}
          setTrayImage={setImage}
          handleClose={handleSubmitModalClose}
        />
        {manualAddModalOpen && (
          <ManualAddModal
            open={manualAddModalOpen}
            onClose={handleManualAddClick}
          />
        )}
      </InventoryProvider>
    </>
  )
}

export default CaptureCamera
