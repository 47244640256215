import { gql } from '@apollo/client'

// Queries
export const GET_INVENTORY_REPORT = gql`
  query GetInventoryReport(
    $limit: Float!
    $companiesIds: [Float!]
    $itemDisposition: ItemDisposition
    $productName: String
    $skip: Float
    $type: [ItemCategory!]
    $locationsIds: [Float!]
  ) {
    getInventoryReport(
      limit: $limit
      companiesIds: $companiesIds
      itemDisposition: $itemDisposition
      productName: $productName
      skip: $skip
      type: $type
      locationsIds: $locationsIds
    ) {
      totalCount
      items {
        description
        disposition
        inventoryLocationsNames
        parValue
        parValueId
        quantity
        type
        vendorName
        versionModelNumber
      }
    }
  }
`

export const GET_INVENTORY_REPORT_DETAILS = gql`
  query GetInventoryReportProductDetails($limit: Float!, $parValueId: Float!) {
    getInventoryReportProductDetails(limit: $limit, parValueId: $parValueId) {
      items {
        biologicExtraInfo {
          comment
          isValidTemperature
          packageIntegrity
        }
        expirationDate
        createdAt
        disposition
        id
        inventoryLocationName
        lotNumber
        quantity
        serial
        type
        updatedAt
      }
      totalCount
    }
  }
`

export const GET_LOCATIONS = gql`
  query GetInventoryLocations {
    getInventoryLocations {
      addressId
      createdAt
      departmentName
      description
      id
      name
      physicalType
      updatedAt
    }
  }
`

export const GET_LOCATIONS_TREE = gql`
  query GetLocationsTree {
    getLocationsTree {
      hospital {
        createdAt
        id
        locations {
          createdAt
          description
          id
          isAssignableToDepartment
          name
          order
          parentLocationId
          updatedAt
        }
        name
        updatedAt
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query SearchCompanies($searchByCompanyName: String!) {
    searchCompanies(searchByCompanyName: $searchByCompanyName) {
      companies {
        createdAt
        dunsNumber
        fromItemInstanceId
        id
        isbt128Id
        name
        regEmail
        sourceId
        updatedAt
      }
    }
  }
`
export const SEARCH_PRODUCT = gql`
  query SearchProductsByUdi($udi: String!) {
    searchProductsByUdi(udi: $udi) {
      assets {
        catalogNumber
        company {
          createdAt
          dunsNumber
          fromAssetInstanceId
          id
          isbt128Id
          name
          regEmail
          sourceId
          updatedAt
        }
        companyId
        createdAt
        deviceCount
        deviceDescription
        deviceId
        fdaProductCodeName
        fromAssetInstanceId
        gmdnPTName
        id
        isbt128ProdDescCode
        issuingAgency
        secondaryDeviceId
        secondaryDeviceIdPkgQuantity
        secondaryDeviceIdPkgType
        secondaryDeviceIdType
        sizeString
        sizeText
        sourceId
        updatedAt
        versionModelNumber
      }
      productionIdentifier {
        expirationDate
        lotBatch
        manufacturingDate
        serialNumber
      }
      term {
        isIsbt128
        isUDI
        matches {
          field
          id
          score
        }
        value
      }
    }
  }
`
export const VIEW_PRODUCT_REQUESTS = gql`
  query GetProductRequests(
    $filterByStatuses: [ProductRequestStatus!]!
    $limit: Float!
    $skip: Float
    $sortBy: [ProductRequestSortingCriteria!]
  ) {
    getProductRequests(
      filterByStatuses: $filterByStatuses
      limit: $limit
      skip: $skip
      sortBy: $sortBy
    ) {
      productRequests {
        companyId
        companyName
        createdAt
        createdByEmail
        description
        deviceId
        productRequestId
        status
        updatedAt
        versionModelNumber
      }
      totalCount
    }
  }
`
