import { REQUEST_PRODUCTS } from 'lib/services/api/schema'
import { RequestProductResponse, RequestProductVariables } from './types'
import { captureWarning } from 'lib/monitoring'
import { MutationTuple, useMutation } from '@apollo/client'

type UseAttachUDIsToProductResult = MutationTuple<
  RequestProductResponse,
  RequestProductVariables
>

const useRequestProducts = (): UseAttachUDIsToProductResult => {
  return useMutation<RequestProductResponse, RequestProductVariables>(
    REQUEST_PRODUCTS,
    {
      onError: (error: Error) => {
        captureWarning(error)
      },
    }
  )
}

export default useRequestProducts
