import { CREATE_LOCATION } from 'lib/services/api/schema'
import { MutationTuple, useMutation } from '@apollo/client'
import { captureWarning } from 'lib/monitoring'
import { CreateLocationParams, CreateLocationResponse } from './types'

type ICreateLocation = MutationTuple<
  CreateLocationResponse,
  CreateLocationParams
>

const useCreateLocation = (): ICreateLocation => {
  return useMutation<CreateLocationResponse, CreateLocationParams>(
    CREATE_LOCATION,
    {
      onError: (error: Error) => {
        captureWarning(error)
      },
    }
  )
}

export default useCreateLocation
