import { useQuery } from '@apollo/client'
import { SEARCH_PRODUCT } from 'lib/services/api/schema'
import { SearchProductVariables, SearchProductsByUdiResponse } from './types'
import { captureWarning } from 'lib/monitoring'

const useSearchProduct = ({ udi, enabled }: SearchProductVariables) =>
  useQuery<SearchProductsByUdiResponse, SearchProductVariables>(
    SEARCH_PRODUCT,
    {
      variables: {
        udi,
      },
      skip: !enabled,
      onError: (error) => {
        captureWarning(error)
      },
    }
  )

export default useSearchProduct
