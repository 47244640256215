import React, { createContext, useState, useContext } from 'react'
import {
  IReportDetailsModal,
  IUpdateParValueModal,
} from './InventoryReport.types'
import { InventoryReport } from 'lib/services/api/product-service/getInventoryReport/types'
import { FiltersParams } from './inventory-filters/InventoryFilters.types'
export interface IInventoryReportContextType {
  report: InventoryReport | null
  setReport: React.Dispatch<React.SetStateAction<InventoryReport | null>>
  parValueModal: IUpdateParValueModal
  setParValueModal: React.Dispatch<React.SetStateAction<IUpdateParValueModal>>
  detailsModal: IReportDetailsModal
  setDetailsModal: React.Dispatch<React.SetStateAction<IReportDetailsModal>>
  filters: FiltersParams
  setFilters: React.Dispatch<React.SetStateAction<FiltersParams>>
}

const InventoryReportContext = createContext<
  IInventoryReportContextType | undefined
>(undefined)

export const InventoryReportProvider: React.FC = ({ children }) => {
  const [report, setReport] = useState<InventoryReport | null>(null)
  const [parValueModal, setParValueModal] = useState<IUpdateParValueModal>({})
  const [detailsModal, setDetailsModal] = useState<IReportDetailsModal>({})
  const [filters, setFilters] = useState<FiltersParams>({})

  return (
    <InventoryReportContext.Provider
      value={{
        report,
        setReport,
        parValueModal,
        setParValueModal,
        detailsModal,
        setDetailsModal,
        filters,
        setFilters,
      }}
    >
      {children}
    </InventoryReportContext.Provider>
  )
}

export const useInventoryReport = () => {
  const context = useContext(InventoryReportContext)
  if (!context) {
    throw new Error(
      'useInventoryReport must be used within an InventoryReportProvider'
    )
  }
  return context
}
