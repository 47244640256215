import { Link, useNavigate } from 'react-router-dom'
import { useUser } from 'app/User'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

function PermissionDenied() {
  const navigate = useNavigate()
  const { isAuthenticated } = useUser()

  return (
    <PageDisplay>
      <Typography variant="h1">Permission denied</Typography>
      <Typography mb={5}>
        You do not have user permissions required to access this page.
      </Typography>
      <Button
        variant="contained"
        sx={{ my: 1 }}
        disableElevation
        onClick={() => navigate(-1)}
      >
        Go back
      </Button>
      {isAuthenticated && (
        <Button
          variant="outlined"
          disableElevation
          component={Link}
          to="/logout"
          sx={{ my: 1 }}
        >
          Log out
        </Button>
      )}
    </PageDisplay>
  )
}

export default PermissionDenied
