import { Map, Percent, QrCodeScanner } from '@mui/icons-material'
import { NavbarItemProps } from './MiniNavbar.types'

export const navbarItems: NavbarItemProps[] = [
  {
    title: 'Capture',
    route: 'capture',
    icon: <QrCodeScanner />,
  },
  {
    title: 'Visualization',
    route: 'tray-visualization',
    icon: <Map />,
  },
  {
    title: 'Results',
    route: 'results',
    icon: <Percent />,
  },
]
