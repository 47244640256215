import styled from '@emotion/styled'
import { ButtonBase, Grid, Stack, Typography } from '@mui/material'
import { MainIconFlowState } from 'common/types'
import { theme } from 'assets/styles/customTheme'

export const ImageButton = styled(ButtonBase)({
  position: 'relative',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.lightest,
  },
})

export type assetButtonProps = {
  IconComponent: React.ComponentClass
  label: MainIconFlowState['assetTypeLabel'] | MainIconFlowState['trayType']
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  dataTestId?: string
}

export const AssetIconButton = (assetButtonProps: assetButtonProps) => {
  return (
    <Grid container item display="flex" direction="column">
      <ImageButton
        focusRipple
        onClick={assetButtonProps.onClick}
        data-testid={assetButtonProps.dataTestId}
      >
        <Stack direction="column" alignItems="center" spacing={1}>
          <assetButtonProps.IconComponent />
          <Typography
            color={theme.palette.primary.main}
            sx={{ textTransform: 'uppercase', fontWeight: '500' }}
          >
            {assetButtonProps.label}
          </Typography>
        </Stack>
      </ImageButton>
    </Grid>
  )
}
