import { css } from 'styled-components'

export const flexBox = (
  display = 'flex',
  justifyContent = 'center',
  alignItems = 'center',
  direction = 'row',
  gap = '0'
) => css`
  display: ${display};
  justify-content: ${justifyContent};
  align-items: ${alignItems};
  flex-direction: ${direction};
  gap: ${gap};
`
