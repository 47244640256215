import { Helmet } from 'react-helmet'
import { useMutation } from 'react-query'
import { useAuth0 } from 'app/Auth'
import { useUser } from 'app/User'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

function Profile() {
  const { resetPassword } = useAuth0()
  const { user } = useUser()

  const resetPasswordMutation = useMutation(resetPassword)

  const handleResetPassword = () => {
    user?.email && resetPasswordMutation.mutate(user.email)
  }

  return (
    <>
      <Helmet>Profile</Helmet>
      <PageDisplay>
        <Box px={1.5} pt={1.5}>
          <Typography variant="h1">Your Profile</Typography>
          <Box mt={2}>
            <Typography variant="h3" mb={1}>
              Email
            </Typography>
            {user?.email}
          </Box>
          <Box mt={2}>
            <Typography variant="h3" mb={1}>
              First Name
            </Typography>
            {user?.firstName}
          </Box>
          <Box mt={2}>
            <Typography variant="h3" mb={1}>
              Last Name
            </Typography>
            {user?.lastName}
          </Box>
          <Box mt={2}>
            <Typography variant="h3" mb={1}>
              Mobile Number
            </Typography>
            {user?.mobileNumber}
          </Box>

          <Box mt={4}>
            <Typography variant="h1">Reset password</Typography>
            <Typography>
              To reset your password click "Reset Password". An email will be
              sent to you with a link that will guide you through choosing a new
              password.
            </Typography>
            <LoadingButton
              disabled={resetPasswordMutation.isSuccess}
              loading={resetPasswordMutation.isLoading}
              loadingText="Resetting password"
              variant="contained"
              onClick={handleResetPassword}
              mode={resetPasswordMutation.isSuccess ? 'success' : 'default'}
              sx={{ my: 3 }}
            >
              {resetPasswordMutation.isSuccess
                ? 'Reset email sent!'
                : 'Reset password'}
            </LoadingButton>
            <AlertDialog
              isOpen={resetPasswordMutation.isError}
              description={'There was an error resetting your password.'}
              position={{ bottom: '1.5em' }}
              primaryButtonAction={handleResetPassword}
              secondaryButtonAction={resetPasswordMutation.reset}
              isPrimaryDisabled={false}
              isPrimaryLoading={resetPasswordMutation.isLoading}
            />
          </Box>
        </Box>
      </PageDisplay>
    </>
  )
}

export default Profile
