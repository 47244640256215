import { gql } from '@apollo/client'

export const UPDATE_PAR_VALUE = gql`
  mutation UpdateParValue($newParValue: Float!, $parValueId: Float!) {
    updateDepartmentProductParValue(
      newParValue: $newParValue
      parValueId: $parValueId
    ) {
      message
    }
  }
`

export const RECEIVE_ITEMS = gql`
  mutation ReceiveItems($items: [ItemInput!]!, $locationId: Float!) {
    receiveItems(items: $items, locationId: $locationId) {
      message
    }
  }
`

export const REQUEST_PRODUCTS = gql`
  mutation CreateProductRequest(
    $locationId: Float!
    $productRequests: [ProductRequestInput!]!
  ) {
    createProductRequest(
      locationId: $locationId
      productRequests: $productRequests
    ) {
      failedProductRequests {
        errors
        udi
      }
      successfulProductRequests {
        udi
      }
    }
  }
`

export const UPDATE_PRODUCT_REQUEST_STATUS = gql`
  mutation UpdateProductRequestStatus(
    $productRequestId: Float!
    $updatedStatus: UpdateProductRequestStatus!
  ) {
    updateProductRequestStatus(
      productRequestId: $productRequestId
      updatedStatus: $updatedStatus
    ) {
      message
    }
  }
`

export const ATTACH_UDIS_TO_PRODUCT = gql`
  mutation AttachUdisToProduct(
    $addUniqueIdentifiers: [String!]!
    $companyId: Float!
    $versionModelNumber: String!
  ) {
    attachUdisToProduct(
      addUniqueIdentifiers: $addUniqueIdentifiers
      companyId: $companyId
      versionModelNumber: $versionModelNumber
    ) {
      failedAddedUdisResponse {
        errors
        udi
      }
      successfullyAddedUdisResponse {
        udi
      }
    }
  }
`

export const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $name: String!
    $description: String
    $order: Float
    $parentLocationId: Float
  ) {
    createLocation(
      name: $name
      description: $description
      order: $order
      parentLocationId: $parentLocationId
    ) {
      message
    }
  }
`

export const UPDATE_LOCATIONS_TREE = gql`
  mutation UpdateLocationsTree(
    $updateLocationsTreeId: Int!
    $locations: [HospitalLocationsTreeInput!]!
    $name: String!
  ) {
    updateLocationsTree(
      id: $updateLocationsTreeId
      locations: $locations
      name: $name
    ) {
      message
    }
  }
`
