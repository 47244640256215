export const getSurgeryScreenHeight = () => {
  const headerHeight =
    document.getElementById('medgeo-app-header')?.offsetHeight || 0
  const statusBarHeight =
    document.getElementById('procedure-status-bar')?.offsetHeight || 0
  const footerHeight =
    document.getElementById('asset-list-footer')?.offsetHeight || 0
  const remainingHeight =
    window.innerHeight - headerHeight - footerHeight - statusBarHeight

  return remainingHeight
}
