import { Box, Typography } from '@mui/material'
import dayjs from 'lib/dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useReportFiltersContext } from 'lib/context/ReportFiltersContext/ReportFiltersContext'

export const DateRangePicker = () => {
  const { setBeforeDate, setAfterDate, beforeDate, afterDate } =
    useReportFiltersContext()

  const customSx = {
    '.MuiPickersYear-yearButton.Mui-selected': {
      color: 'primary.dark',
      backgroundColor: 'primary.hover',
    },
    '.MuiPickersYear-yearButton.Mui-selected:hover': {
      backgroundColor: 'primary.main',
      color: 'primary.lightest',
    },
    '.MuiPickersYear-yearButton.Mui-selected&:not(:hover)': {
      backgroundColor: 'primary.hover',
    },
    '.MuiPickersYear-yearButton:hover': {
      backgroundColor: 'primary.hover',
    },
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '5px',
      }}
    >
      <DatePicker
        data-testid={'date-range-picker'}
        minDate={undefined}
        maxDate={beforeDate as dayjs.Dayjs}
        label="Start Date"
        value={afterDate as dayjs.Dayjs}
        onChange={(start) => {
          setAfterDate(start as dayjs.Dayjs)
        }}
        reduceAnimations
        slotProps={{
          desktopPaper: {
            sx: customSx,
          },
          mobilePaper: {
            sx: customSx,
          },
        }}
      />
      <Typography variant="subtitle1">to</Typography>
      <DatePicker
        data-testid={'date-range-picker'}
        minDate={afterDate as dayjs.Dayjs}
        maxDate={beforeDate as dayjs.Dayjs}
        label="End Date"
        value={beforeDate as dayjs.Dayjs}
        onChange={(start) => {
          setBeforeDate(start as dayjs.Dayjs)
        }}
        reduceAnimations
        slotProps={{
          desktopPaper: {
            sx: customSx,
          },
          mobilePaper: {
            sx: customSx,
          },
        }}
      />
    </Box>
  )
}
