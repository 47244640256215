import { useLocation } from 'react-router-dom'
import { useTrayConfigurationContext } from './TrayManagement/TrayManagement.context'
import { useEffect } from 'react'

const useTrayManagementLayoutLogic = () => {
  const { pathname } = useLocation()
  const {
    snackbarOpen,
    snackbarIcon,
    snackbarMessage,
    snackbarState,
    handleSnackbarClose,
  } = useTrayConfigurationContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return {
    snackbarOpen,
    snackbarIcon,
    snackbarMessage,
    snackbarState,
    handleSnackbarClose,
  }
}

export default useTrayManagementLayoutLogic
