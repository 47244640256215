import dayjs, { humanizedFullYearDateFormat } from 'lib/dayjs'
import { IDateInput } from './DateInput.types'

const useDateInputLogic = ({
  value = null,
  handleChangeFormErrorField,
  handleChangeFormData,
  handleValidateForm,
  name,
  variant = 'standard',
  size = 'medium',
  openTo = 'year',
  minDate,
  hideTextField = false,
  shrink = true,
  readOnly = false,
  ...props
}: IDateInput) => {
  const handleAccept = (event: any) => {
    // Prevent error if user clears date in picker
    if (!event) return

    let date = dayjs(event.$d).format(humanizedFullYearDateFormat)

    handleChangeFormData({
      target: {
        name: name,
        value: date,
      },
    })

    handleChangeFormErrorField &&
      name &&
      handleChangeFormErrorField(name, false)
  }

  const handleManualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (readOnly) {
      if (inputValue.length < 10) {
        handleChangeFormData({
          target: {
            name,
            value: null,
          },
        })
        return
      }
    }

    // If the input value is empty, set the value to null and return
    if (inputValue.trim() === '') {
      handleChangeFormData({
        target: {
          name,
          value: null,
        },
      })
      return
    }

    // Validate the input value against the date format
    if (!dayjs(inputValue, humanizedFullYearDateFormat, true).isValid()) {
      // If the input value is invalid, return without updating the state
      return
    }

    // Format the valid input value
    const formattedDate = dayjs(inputValue, humanizedFullYearDateFormat).format(
      humanizedFullYearDateFormat
    )

    // Update the state with the formatted date
    handleChangeFormData({
      target: {
        name,
        value: formattedDate,
      },
    })
  }

  return {
    value: value
      ? dayjs(value as Date).format(humanizedFullYearDateFormat)
      : null,
    handleChangeFormErrorField,
    handleChangeFormData,
    handleValidateForm,
    name,
    variant,
    size,
    openTo,
    minDate,
    hideTextField,
    shrink,
    readOnly,
    props,
    handleAccept,
    handleManualChange,
  }
}

export default useDateInputLogic
