import { Typography, Grid } from '@mui/material'
import { Error, CheckCircle } from '@mui/icons-material'
import styles from './status.module.css'
import { useStatusCheck } from 'lib/hooks/useStatusCheck'

function StatusIndicator() {
  const statusState = useStatusCheck()

  if (statusState.appsync?.status === 'OK') {
    return (
      <Grid container className={styles.container}>
        <Grid item>
          <CheckCircle className={styles.icon} sx={{ color: 'success.main' }} />
        </Grid>
        <Grid item>
          <Typography className={styles.typography}>
            All MedGEO Platform Services Operational
          </Typography>
        </Grid>
      </Grid>
    )
  } else if (statusState.appsync?.status === 'ERROR') {
    return (
      <Grid container className={styles.container}>
        <Grid item>
          <Error className={styles.icon} sx={{ color: 'error.main' }} />
        </Grid>
        <Grid item>
          <Typography className={styles.typography}>
            Connection to the MedGEO Platform Services Failed
          </Typography>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default StatusIndicator
