import { FC } from 'react'

// MUI
import { Box, LinearProgress, Stack, Typography } from '@mui/material'

// Utils
import { useIsScannerScreenMidSize } from 'lib/utils/mediaQueries'

// Types
import { CreateProductRequestResponse } from 'lib/services/api/product-service/requestProducts/types'
import { ProcessedProduct } from '../process-modal/ProcessModal.types'

// Icons
import ScanGuid from 'assets/icons/svgs/ScanGuid.svg'

// Components
import Warning from 'components/molecules/Warning/Warning'
import { ReceivingSingleScanProvider } from 'views/ReceiveProducts/scan/scanner/ReceivingSingleScanProvider'
import { ReceivingProvider } from 'views/ReceiveProducts/receiveContext'

interface IScannedItems {
  isLoadingSearch: boolean
  handleScan: (code: string) => void
  processedItems: ProcessedProduct[]
  hasNewItems: boolean
  errors: CreateProductRequestResponse[]
}

const ScannerContent: FC<IScannedItems> = ({
  processedItems,
  handleScan,
  isLoadingSearch,
  errors,
  hasNewItems,
}) => {
  const isScannerScreenMidSize = useIsScannerScreenMidSize()
  const isActive = true
  return (
    <Stack padding={isScannerScreenMidSize ? 0 : 2.5} className="scanner">
      <div className="camera">
        <ReceivingSingleScanProvider onScan={handleScan} />
      </div>
      {isLoadingSearch && (
        <Box
          sx={
            isScannerScreenMidSize
              ? {
                  width: '100%',
                  position: 'absolute',
                  bottom: '56px',
                  zIndex: 10,
                }
              : { width: '700px' }
          }
        >
          <LinearProgress />
        </Box>
      )}
      <Box display="inline-flex" flexDirection="column" alignItems="center">
        {!isActive && (
          <Box
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={10}
            sx={{
              backgroundColor: 'white',
            }}
          >
            <Box textAlign="center" mb={28}>
              <Typography
                variant="h3"
                color="grey.800"
                my={6}
                letterSpacing={1}
                fontWeight={500}
                fontSize={18}
              >
                Preparing Smart Vision
              </Typography>
              <LinearProgress />
            </Box>
          </Box>
        )}
      </Box>
      {!isScannerScreenMidSize && (
        <>
          <Stack gap={2} marginTop={1}>
            {hasNewItems ? (
              <Warning
                text="New items will be requested and have to be approved."
                type="info"
              />
            ) : null}
            {errors.length
              ? errors[0].errors?.map((error, index) => (
                  <Warning key={index} text={error} type="danger" />
                ))
              : null}
          </Stack>
          {!processedItems.length && (
            <div className="instructions">
              <img
                src={ScanGuid}
                alt="capture guide"
                height={115}
                width={120}
              />
              <>Please capture the code using the camera</>
            </div>
          )}
        </>
      )}
    </Stack>
  )
}

const Scanner: FC<IScannedItems> = (props) => (
  <ReceivingProvider>
    <ScannerContent {...props} />
  </ReceivingProvider>
)

export default Scanner
