import * as React from 'react'
import { useQuery as useApolloQuery } from '@apollo/client'
import { useQuery as useReactQuery } from 'react-query'
import { GET_STATUS_QUERY } from 'lib/apollo/schema'

export enum StatusValue {
  OK = 'OK',
  ERROR = 'ERROR',
}

export interface StatusData {
  status: StatusValue
  lastChecked: Date
}

export function useStatusCheck() {
  const [statusState, setStatusState] = React.useState<{
    appsync?: StatusData
    mongodb?: StatusData
  }>({
    appsync: undefined,
    mongodb: undefined,
  })

  useApolloQuery(GET_STATUS_QUERY, {
    onCompleted: (data) => {
      setStatusState((prev) => ({
        ...prev,
        appsync: {
          status:
            data?.getStatus?.status === 'OK'
              ? StatusValue.OK
              : StatusValue.ERROR,
          lastChecked: new Date(),
        },
      }))
    },
    onError: () => {
      setStatusState((prev) => ({
        ...prev,
        appsync: {
          status: StatusValue.ERROR,
          lastChecked: new Date(),
        },
      }))
    },
  })

  useReactQuery(
    'dbStatus',
    () =>
      fetch(`${process.env.REACT_APP_REST_API_URL}/status`).then((res) =>
        res.json()
      ),
    {
      onSuccess: (data) => {
        setStatusState((prev) => ({
          ...prev,
          mongodb: {
            status:
              data?.mongo === 'Connected' ? StatusValue.OK : StatusValue.ERROR,
            lastChecked: new Date(),
          },
        }))
      },
      onError: () => {
        setStatusState((prev) => ({
          ...prev,
          mongodb: {
            status: StatusValue.ERROR,
            lastChecked: new Date(),
          },
        }))
      },
    }
  )

  return statusState
}
