import { useAuth0 } from 'app/Auth'
import axios, { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { GetPrefCardResponse, GetPrefCardsParams } from './types'

export const useGetPreferenceCards = (data: GetPrefCardsParams) => {
  const { getAccessToken } = useAuth0()
  const tokenPromise = getAccessToken()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL

  return useQuery<GetPrefCardResponse, AxiosError | any>(
    ['preferenceCards', data],
    async () => {
      const token = await tokenPromise
      try {
        const response = await axios.get(
          `${REPORTS_URL}api/v1/preference_cards`,
          {
            params: { ...data, enabled: undefined },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data
      } catch (error) {
        throw error
      }
    },
    {
      enabled: data.enabled,
    }
  )
}
