import { FC } from 'react'

// Components
import Select from 'components/molecules/Select/Select'
import SearchInput from 'components/molecules/SearchInput/SearchInput'
import Button from 'components/molecules/Button/Button'
import { Box } from '@mui/material'

// Types
import {
  AssetType,
  Disposition,
} from 'views/ReceiveProducts/scan/process-modal/ProcessModal.types'
import { IReportFilters } from './InventoryFilters.types'

// Logic
import useLogic from './InventoryFilters.logic'

// Style
import './InventoryFilters.scss'

const InventoryFilters: FC<IReportFilters> = ({ isLoadingReport, onReset }) => {
  const {
    selectedCompany,
    consignment,
    type,
    productName,
    company,
    isLoadingCompanies,
    companies,
    isTablet,
    locations,
    selectedLocation,
    isLoadingLocations,
    setSelectedLocation,
    handleSearchProductName,
    setSelectedCompany,
    setConsignment,
    setCompany,
    setType,
    handleApply,
  } = useLogic()
  return (
    <Box className={`filters-container ${isTablet ? 'is-tablet' : ''}`}>
      <Box className="filters">
        <Box className="input-container">
          <SearchInput
            onSearch={handleSearchProductName}
            placeholder="Search Products"
            isLoading={isLoadingReport}
            label="Search Product Name"
            value={productName}
          />
        </Box>
        <Box className="input-container">
          <Select
            label="Company"
            options={companies}
            onClear={() => setSelectedCompany && setSelectedCompany(undefined)}
            onSelect={(value) =>
              setSelectedCompany && setSelectedCompany(value)
            }
            isLoading={isLoadingCompanies}
            onSearch={(search) => setCompany(search)}
            selectedValue={selectedCompany?.name}
          />
        </Box>
        <Box className="input-container">
          <Select
            label="Location"
            options={locations}
            onClear={() =>
              setSelectedLocation && setSelectedLocation(undefined)
            }
            onSelect={(value) =>
              setSelectedLocation && setSelectedLocation(value)
            }
            isLoading={isLoadingLocations}
            selectedValue={selectedLocation?.name}
          />
        </Box>
        <Box className="input-container">
          <Select
            label="Consignment"
            options={[
              {
                id: 1,
                name: 'Consigned',
              },
              {
                id: 2,
                name: 'Purchased',
              },
            ]}
            onSelect={(value) => setConsignment(value?.name as Disposition)}
            onClear={() => setConsignment(undefined)}
            selectedValue={consignment}
          />
        </Box>
        <Box className="input-container">
          <Select
            label="Product type"
            options={[
              {
                id: 1,
                name: 'Biologic',
              },
              {
                id: 2,
                name: 'Hardware',
              },
              {
                id: 3,
                name: 'Other',
              },
            ]}
            onSelect={(value) => setType(value.name as AssetType)}
            onClear={() => setType(undefined)}
            selectedValue={type}
          />
        </Box>
      </Box>
      <Box className="actions">
        <Button
          variant="contained"
          onClick={() =>
            handleApply({
              type,
              productName,
              itemDisposition: consignment,
              selectedCompany,
              company,
              selectedLocation,
            })
          }
        >
          Apply
        </Button>
        <Button variant="outlined" onClick={onReset}>
          Reset
        </Button>
      </Box>
    </Box>
  )
}

export default InventoryFilters
