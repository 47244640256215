import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { useCompanySearchQuery } from 'lib/apollo/hooks'
import { BetterIdCompany, ManualInputFormData } from 'common/types'
import { RepSignUpFormData } from './AddRepModal/RepSignupForm/repSignupForm.types'
import { useState, useEffect, ChangeEvent } from 'react'

interface CompanySearchBarProps {
  handleChangeFormData: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  manualAssetInputFormData: ManualInputFormData | RepSignUpFormData
  variant?: 'standard' | 'outlined'
  disabled?: boolean
}

export const CompanySearchBar: React.FC<CompanySearchBarProps> = ({
  handleChangeFormData,
  manualAssetInputFormData,
  variant,
  disabled = false,
}) => {
  const [companyNameInput, setCompanyNameInput] = useState<string>(
    manualAssetInputFormData?.companyName
  )

  const { data, loading } = useCompanySearchQuery(companyNameInput)
  const companiesList = (
    data?.companySearch?.map((company: BetterIdCompany) => company.name) ?? []
  ).slice(0, 100)

  const handleCompanyNameInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCompanyNameInput(e.target.value)
  }

  useEffect(() => {
    setCompanyNameInput(manualAssetInputFormData.companyName)
  }, [manualAssetInputFormData.companyName])

  return (
    <AutocompleteInput
      id="companyName"
      label="Company Name"
      name="companyName"
      variant={variant}
      value={manualAssetInputFormData.companyName}
      handleChangeFormData={(e) => {
        handleChangeFormData(
          e as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        )
      }}
      onTextInputChange={(e) =>
        handleCompanyNameInputChange(
          e as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        )
      }
      options={companiesList}
      autoSelect={false}
      isLoading={loading}
      required
      sx={{ my: 0 }}
      disabled={disabled}
    />
  )
}
