/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageSearch } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { CustomModal } from 'components/molecules/Modal/Modal'
import SearchInput from 'components/molecules/TextField/TextField'
import { useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import useSubmitTrayCapture from '../SubmitTrayCapture.logic'
import { useCaptureTrayContext } from '../../CaptureTray.context'

interface ManualAddModalProps {
  open: boolean
  onClose: () => void
}

const ManualAddModal = ({ open, onClose }: ManualAddModalProps) => {
  const { handleSubmit } = useSubmitTrayCapture()
  const {
    trayImage,
    setTrayImage,
    isSubmitting,
    setIsSubmitting,
    alertData,
    setAlertData,
    isAlertOpen,
    setIsAlertOpen,
  } = useCaptureTrayContext()

  const [isValidUrl, setIsValidUrl] = useState<boolean>(false)
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false)
  const [isImageError, setIsImageError] = useState<boolean>(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsValidUrl(false)
    setTrayImage(event.target.value)
    setIsImageError(false)
    setIsImageLoading(true)
    // Use regex to check if the image url is valid
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
    setIsValidUrl(urlRegex.test(event.target.value))
  }

  const handleImageLoad = () => {
    setIsImageLoading(false)
  }

  const handleImageError = () => {
    console.error('Image error')
    setIsImageLoading(false)
    setIsImageError(true)
  }

  const handleClose = () => {
    setTrayImage('')
    setIsValidUrl(false)
    setIsImageLoading(false)
    setIsImageError(false)
    setIsSubmitting(false)
    setIsAlertOpen(false)
    setAlertData({
      description: '',
      mode: undefined,
    })
    onClose()
  }

  const handleModalSubmit = (trayImage: string) => {
    handleSubmit(trayImage)
  }

  return (
    <CustomModal
      open={open}
      dataTestId={'dtm-manual-add'}
      secondaryButtonAction={handleClose}
      header={'Paste your tray image link'}
      primaryButtonDisabled={!isValidUrl}
      isLoadingState={isSubmitting}
      handleOnSubmit={() => handleModalSubmit(trayImage)}
      isSubmitting={isSubmitting}
      primaryButtonText={'Submit'}
      useAnalyzerState={true}
      analyzingStateNode={
        <>
          <Player
            autoplay
            loop
            src="https://lottie.host/eff24766-b4d4-4cfc-beba-351c7101b33b/ysCCscpJQH.json"
            style={{ height: '100px', width: '100px' }}
          />
          <Typography
            variant="h6"
            color="grayscale.contrastText"
            fontStyle="italic"
          >
            Analyzing your tray...
          </Typography>
        </>
      }
    >
      <>
        <div style={{ width: '100%' }}>
          <SearchInput
            placeholder="Paste your tray image link"
            handleInputChange={handleInputChange}
            isLoading={false}
            icon={<ImageSearch />}
            sx={{ backgroundColor: 'grayscale.lightest' }}
          />
        </div>

        <AlertDialog
          isOpen={isAlertOpen}
          mode={alertData.mode}
          description={alertData.description}
          primaryButtonAction={() => {
            onClose()
            setIsAlertOpen(false)
            setTrayImage('')
            setAlertData({
              description: '',
              mode: undefined,
            })
          }}
          zIndex={9}
        />

        {trayImage.length > 0 && isValidUrl ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '70px',
              position: 'relative',
            }}
          >
            {isImageLoading ? (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            ) : null}

            {isImageError ? (
              <Typography variant={'h5'} color="error.main">
                Invalid image URL
              </Typography>
            ) : (
              <img
                src={trayImage}
                alt="Tray Image"
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: '5px',
                  margin: '10px 0',
                }}
              />
            )}
          </Box>
        ) : null}
      </>
    </CustomModal>
  )
}

export default ManualAddModal
