import { Fragment } from 'react'

// Components
import Warning from 'components/molecules/Warning/Warning'
import ParValueModal from './update-par-value-modal/UpdateParValueModal'
import Header from 'components/molecules/Header/Header'
import DetailsModal from './details-modal/DetailsModal'
import ReportCard from './report-card/ReportCard'
import { FilterButton } from 'components/atoms/FilterButton/FilterButton'
import InventoryFilters from './inventory-filters/InventoryFilters'

// MUI
import { CircularProgress, Menu, Box, Chip } from '@mui/material'

// Logic
import useLogic from './InventoryReport.logic'

// Style
import './InventoryReport.scss'

// Other
import { Waypoint } from 'react-waypoint'

// Context
import { InventoryReportProvider } from './InventoryReportProvider'

const Report = () => {
  const {
    reportData,
    isLoadingReport,
    reportError,
    isTablet,
    isRefetching,
    menuAnchorEl,
    isLoadingUpdateParValue,
    filters,
    handleInfiniteScroll,
    setDetailsModal,
    setParValueModal,
    handleMenuClick,
    handleMenuClose,
    handleUpdateParValue,
    handleFiltersActions,
  } = useLogic()

  const renderChip = (label: string, onDelete: () => void) => (
    <Chip className="filter-chip" label={label} onDelete={onDelete} />
  )

  return (
    <Box className="inventory-report-container">
      <Header parent="Reports" title="Products Inventory" />
      <Box className="inventory-header-container">
        <>
          <FilterButton
            handleMenuClick={handleMenuClick}
            isDisabled={isLoadingReport}
          />
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            sx={{
              zIndex: 1000,
              overflow: 'visible',
            }}
          >
            <InventoryFilters
              isLoadingReport={isLoadingReport}
              onReset={() => handleFiltersActions('reset')}
            />
          </Menu>
        </>
        {filters.selectedCompany?.name &&
          renderChip(filters.selectedCompany.name, () =>
            handleFiltersActions('remove', 'selectedCompany')
          )}
        {filters.itemDisposition &&
          renderChip(filters.itemDisposition, () =>
            handleFiltersActions('remove', 'itemDisposition')
          )}
        {filters.productName &&
          renderChip(filters.productName, () =>
            handleFiltersActions('remove', 'productName')
          )}
        {filters.type &&
          renderChip(filters.type, () =>
            handleFiltersActions('remove', 'type')
          )}
        {filters.selectedLocation?.name &&
          renderChip(filters.selectedLocation?.name, () =>
            handleFiltersActions('remove', 'selectedLocation')
          )}
      </Box>

      <Box>
        {isLoadingReport && (
          <Box className="loader">
            <CircularProgress />
          </Box>
        )}
        <Box className="report-cards">
          {reportData?.getInventoryReport.items.length
            ? reportData.getInventoryReport.items.map((item, index) => (
                <Fragment key={index}>
                  {index === reportData.getInventoryReport.items.length - 1 && (
                    <Waypoint onEnter={handleInfiniteScroll} />
                  )}
                  <ReportCard
                    data={item}
                    isTablet={isTablet}
                    onClick={() =>
                      setDetailsModal({
                        open: true,
                        data: {
                          parValueId: item.parValueId,
                          name: item.description,
                        },
                      })
                    }
                    reportOptions={[
                      {
                        label: 'Edit PAR',
                        type: 'EDIT_PAR-VALUE',
                        onClick: (item) =>
                          setParValueModal({ data: item, open: true }),
                      },
                      {
                        label: 'View details',
                        type: 'VIEW_DETAILS',
                        onClick: () =>
                          setDetailsModal({
                            open: true,
                            data: {
                              parValueId: item.parValueId,
                              name: item.description,
                            },
                          }),
                      },
                    ]}
                  />
                </Fragment>
              ))
            : null}
          {!isLoadingReport &&
            !reportData?.getInventoryReport.items.length &&
            !reportError && (
              <Box className="error">
                <Warning type="info" text="No data yet to show 🥲" />
              </Box>
            )}
          {reportError ? (
            <Box className="error">
              <Warning type="warning" text="There was an error" />
            </Box>
          ) : null}
        </Box>
        {isRefetching && (
          <Box className="infinite-scroll-loader">
            <CircularProgress />
          </Box>
        )}
      </Box>

      <DetailsModal />
      <ParValueModal
        onChange={handleUpdateParValue}
        isLoading={isLoadingUpdateParValue}
      />
    </Box>
  )
}

const InventoryReport = () => (
  <InventoryReportProvider>
    <Report />
  </InventoryReportProvider>
)

export default InventoryReport
