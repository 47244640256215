import { Link } from 'react-router-dom'
import { Button, CircularProgress, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import TrayComponent from './TrayComponent/TrayComponent'

import useAssignedDigitalTraysLogic from './AssignedDigitalTrays.logic'

import { ItemResponse } from 'common/types'
import './AssignedDigitalTrays.scss'

const AssignedDigitalTrays = () => {
  const { loading, filteredTrays, error, surgeryId } =
    useAssignedDigitalTraysLogic()

  return (
    <div className="assigned-digital-trays_container">
      <Button
        className="back-button"
        variant="outlined"
        size="small"
        to=".."
        component={Link}
      >
        <ArrowBack sx={{ mr: 0.5 }} /> BACK
      </Button>

      <Typography variant="h1">Assigned Digital Trays</Typography>

      <div className="content">
        {loading && <CircularProgress />}

        {error && (
          <Typography className="error" variant="body1">
            {error.message}
          </Typography>
        )}

        {filteredTrays.length === 0 && !loading && (
          <Typography variant="body1">
            No trays assigned to this surgery
          </Typography>
        )}

        {filteredTrays.map((surgeryTray: ItemResponse) => (
          <TrayComponent
            key={surgeryTray.id}
            trayName={surgeryTray.description as string}
            trayID={surgeryTray.barcode as string}
            status="Loaded from SPD"
            assetCount={
              JSON.parse(surgeryTray.productDetails as string)?.screws.length
            }
            contents="Screws"
            vendor={surgeryTray.companyName as string}
            procedureID={surgeryId as string}
            products={JSON.parse(surgeryTray.productDetails as string)?.screws}
            details={JSON.parse(surgeryTray.productDetails as string)}
            trayData={surgeryTray}
          />
        ))}
      </div>
    </div>
  )
}

export default AssignedDigitalTrays
