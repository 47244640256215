import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { FC, ReactNode } from 'react'

type ButtonItem = {
  label: string
  icon: ReactNode
}
interface IBottomBar {
  buttons: ButtonItem[]
  onClick: (tab: number) => void
  selected: number
}

const BottomBar: FC<IBottomBar> = ({ buttons, selected, onClick }) => {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        right: 0,
        borderTop: '1px solid darkgray',
      }}
    >
      <BottomNavigation
        showLabels
        value={selected}
        onChange={(event, newValue) => {
          onClick(newValue)
        }}
      >
        {buttons.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.label}
            icon={item.icon}
          />
        ))}
      </BottomNavigation>
    </Box>
  )
}

export default BottomBar
