import * as React from 'react'
import { useGetImplantSitesQuery } from 'lib/apollo/hooks'
import { BetterIdImplantSite } from 'common/types'

interface ImplantSitesContext {
  isLoading: boolean
  implantSites: BetterIdImplantSite[]
}

const implantSitesContext = React.createContext<ImplantSitesContext>({
  isLoading: false,
  implantSites: [],
})

export function ImplantSitesProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { data, loading } = useGetImplantSitesQuery()
  const implantSites = data?.getImplantSites ?? []
  return (
    <implantSitesContext.Provider value={{ isLoading: loading, implantSites }}>
      {children}
    </implantSitesContext.Provider>
  )
}

export function useImplantSites() {
  const context = React.useContext(implantSitesContext)
  if (context === undefined) {
    throw new Error(
      'useImplantSites must be used within a ImplantSitesProvider'
    )
  }
  return context
}
