import Backdrop, { BackdropProps } from '@mui/material/Backdrop'

type Props = {
  children: JSX.Element[] | JSX.Element
  open: boolean
  zIndex?: number
  backgroundColor?: string
} & BackdropProps

function Mask({ children, open, zIndex = 0, backgroundColor }: Props) {
  return (
    <Backdrop
      open={open}
      transitionDuration={undefined}
      sx={{
        zIndex,
        backgroundColor,
      }}
    >
      {children}
    </Backdrop>
  )
}

export default Mask
