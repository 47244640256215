import styled from '@emotion/styled'
import { theme } from 'assets/styles/customTheme'

export type CustomIconProps = {
  active?: boolean
  color?: string
  children?: React.ReactNode
  onClick?: () => void
  addStatusColor?: boolean
  height?: string
  width?: string
  scale?: number
  dark?: boolean
} & React.SVGProps<SVGSVGElement>

// custom style to gray out icon when disabled
const Icon = styled('svg')`
  label: custom-icon;
  place-self: center;
  margin: 0.5rem;
  ${({
    active,
    addStatusColor,
    color,
    height,
    width,
    scale,
    dark,
  }: CustomIconProps) =>
    addStatusColor &&
    `
  & path {
    fill: ${
      active
        ? color
          ? color
          : theme.palette.primary.main
        : dark
        ? theme.palette.grayscale.dark
        : theme.palette.grayscale.main
    };
  };
  height: ${height ? height : 'auto'};
  width: ${width ? width : 'auto'};
  margin: 0;
  transform: scale(${scale});
  `}
`

export const CustomIcon = ({
  active = true,
  color,
  children,
  onClick,
  addStatusColor = true,
  height,
  width,
  scale,
  dark = false,
}: CustomIconProps) => (
  <Icon
    active={active}
    color={color}
    onClick={onClick}
    addStatusColor={addStatusColor}
    height={height}
    width={width}
    scale={scale}
    dark={dark}
  >
    {children}
  </Icon>
)
