/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'

// Components
import { Option } from 'common/types'

// Types
import {
  AssetType,
  Disposition,
  IProcessModal,
  LocalStateSetterProps,
  StepConfigs,
} from './ProcessModal.types'

// Components
import ConsignmentStep from './steps/ConsignmentStep'
import CreateProductStep from './steps/CreateProduct'
import InspectionStep from './steps/InspectionStep'
import ProductTypeStep from './steps/ProductTypeStep'
import MultiScanner from './steps/MultiScanner/MultiScanner'

// Context
import { useReceiving } from 'views/ReceiveProducts/receiveContext'

// Other
import { v4 as uuidv4 } from 'uuid'
import toast from 'react-hot-toast'

// Services
import useAttachUDIsToProduct from 'lib/services/api/product-service/attachUdisToProduct'

export const useLogic = ({
  serialNumber,
  expirationDate,
  manufacturingDate,
  description,
  onSave,
  lotBatch,
  modelNumber,
  udi,
  type,
  company,
  companyId,
}: IProcessModal) => {
  const { setModal } = useReceiving()
  const [attachUDIsToProduct] = useAttachUDIsToProduct()
  const randomId = uuidv4()
  const [activeStep, setActiveStep] = useState(0)
  const [hasMultiScanning, setHasMultiScanning] = useState(false)
  const [comment, setComment] = useState<string>()
  const [productType, setProductType] = useState<AssetType>()
  const [disposition, setDisposition] = useState<Disposition>()
  const [temperature, setTemperature] = useState<boolean>()
  const [packageIntegrity, setPackageIntegrity] = useState<boolean>()
  const [steps, setSteps] = useState([
    'Select product type*',
    'Select ownership type*',
  ])
  const [LocalManufacturingDate, setLocalManufacturingDate] = useState<Date>()
  const [localCompany, setLocalCompany] = useState<Option>()
  const [localExpiryDate, setLocalExpiryDate] = useState<Date>()
  const [localLot, setLocalLot] = useState<string>()
  const [localSerialNumber, setLocalSerialNumber] = useState<string>()
  const [localModelNumber, setLocalModelNumber] = useState<string>()
  const [localDescription, setLocalDescription] = useState<string>()
  const [extraCodes, setExtraCodes] = useState<string[]>([])
  const childComponentRef = useRef<any>(null)

  const stepsLength = steps.length - 1
  const isNew = type === 'new'
  const handleProductFlag = {
    Biologic: 'B',
    Hardware: 'H',
    Other: 'O',
  }

  const handleDisposition = {
    Consigned: 'Consigned',
    Purchased: 'Purchased',
    Mixed: 'Mixed',
  }

  const handleActionButtonLabel = hasMultiScanning
    ? isNew
      ? productType === 'Biologic'
        ? activeStep === 4
          ? 'Save'
          : 'Next'
        : activeStep === 3
        ? 'Save'
        : 'Next'
      : productType === 'Biologic'
      ? activeStep === 3
        ? 'Save'
        : 'Next'
      : activeStep === 2
      ? 'Save'
      : activeStep === 3
      ? 'Save'
      : 'Next'
    : isNew
    ? productType === 'Biologic'
      ? activeStep === 3
        ? 'Save'
        : 'Next'
      : activeStep === 2
      ? 'Save'
      : 'Next'
    : productType === 'Biologic'
    ? activeStep === 2
      ? 'Save'
      : 'Next'
    : activeStep === 1
    ? 'Save'
    : 'Next'

  const handleSave = () => {
    if (extraCodes.length > 0) {
      attachUDIsToProduct({
        variables: {
          addUniqueIdentifiers: extraCodes,
          companyId: companyId || 0,
          versionModelNumber: modelNumber || '',
        },
      })
        .then((results: any) => {
          // Handle successful results
          if (results.data) {
          } else {
            if (results.errors) {
              toast.error(results.errors?.graphQLErrors[0]?.message)
            }
          }
        })
        .catch((error) => {
          // Handle errors
          if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            const errorMessage = error.graphQLErrors[0].message
            console.error('Error attaching UDIs:', errorMessage)
            // Handle the specific error message here, e.g., show a toast
            toast.error(errorMessage)
          } else {
            console.error('Error attaching UDIs:', error)
            // Handle other types of errors, if needed
          }
        })
    }

    onSave({
      udi,
      expiryDate: localExpiryDate,
      manufacturingDate: manufacturingDate,
      lotBatch: localLot,
      modelNumber: localModelNumber,
      description: localDescription,
      companyId: companyId || (localCompany?.id as number),
      serialNumber: localSerialNumber,
      comment,
      productType,
      disposition,
      temperature,
      packageIntegrity,
      flag: handleProductFlag[productType as AssetType],
      quantity: 1,
      type,
      extraCodes,
    })
  }

  const isNextDisabled = useCallback((): boolean => {
    if (isNew) {
      if (activeStep === 1 && !productType) return true
      if (activeStep === 2 && !disposition) return true

      if (
        activeStep === 3 &&
        productType === 'Biologic' &&
        (!localExpiryDate ||
          temperature === undefined ||
          packageIntegrity === undefined)
      ) {
        return true
      }
    } else {
      if (activeStep === 0 && !productType) return true
      if (activeStep === 1 && !disposition) return true

      if (
        activeStep === 2 &&
        productType === 'Biologic' &&
        (!localExpiryDate ||
          temperature === undefined ||
          packageIntegrity === undefined)
      ) {
        return true
      }
    }
    return false
  }, [
    activeStep,
    disposition,
    isNew,
    localExpiryDate,
    packageIntegrity,
    productType,
    temperature,
  ])

  const handleActionButton = () => {
    if (hasMultiScanning) {
      if (isNew) {
        if (productType === 'Biologic') {
          if (activeStep === 4) {
            return handleSave()
          } else {
            return handleNext()
          }
        } else {
          if (activeStep === 0) {
            if (childComponentRef.current) {
              childComponentRef.current?.handleSubmit()
            }
          } else {
            if (activeStep === 3) {
              return handleSave()
            } else {
              return handleNext()
            }
          }
        }
      } else {
        if (productType === 'Biologic') {
          if (activeStep === 3) {
            return handleSave()
          } else {
            return handleNext()
          }
        } else {
          if (activeStep === 2 || activeStep === 3) {
            return handleSave()
          } else {
            return handleNext()
          }
        }
      }
    } else {
      if (isNew) {
        if (productType === 'Biologic') {
          if (activeStep === 3) {
            return handleSave()
          } else {
            return handleNext()
          }
        } else {
          if (activeStep === 1) {
            return handleNext()
          }
          if (activeStep === 2) {
            return handleSave()
          } else {
            if (childComponentRef.current) {
              childComponentRef.current?.handleSubmit()
            }
          }
        }
      } else {
        if (productType === 'Biologic') {
          if (activeStep === 2) {
            return handleSave()
          } else {
            return handleNext()
          }
        } else {
          if (activeStep === 1) {
            return handleSave()
          } else {
            handleNext()
          }
        }
      }
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      setModal({ isOpen: false })
      return
    }
    setActiveStep((prevStep) => prevStep - 1)
  }

  const handleNext = () => {
    if (isNew) {
      if (productType === 'Biologic') {
        if (hasMultiScanning) {
          if (activeStep === 4) return
        } else {
          if (activeStep === 3) return
        }
      } else {
        if (hasMultiScanning) {
          if (activeStep === 3) return
        } else {
          if (activeStep === 2) return
        }
      }
    } else {
      if (productType === 'Biologic') {
        if (hasMultiScanning) {
          if (activeStep === 3) return
        } else {
          if (activeStep === 2) return
        }
      } else {
        if (hasMultiScanning) {
          if (activeStep === 2) return
        } else {
          if (activeStep === 1) return
        }
      }
    }
    setActiveStep((prevStep) => prevStep + 1)
  }

  const getStepContent = (step: number) => {
    if (hasMultiScanning && step === stepsLength) {
      return (
        <MultiScanner
          onChange={(codes) =>
            setExtraCodes(() => [...codes.map((code) => code.barcodeData)])
          }
        />
      )
    }
    if (!isNew) {
      switch (step) {
        case 0:
          return (
            <ProductTypeStep
              key={`${randomId}+ProductTypeStep`}
              selected={productType}
              setSelected={(value) => setProductType(value)}
            />
          )
        case 1:
          return (
            <ConsignmentStep
              key={`${randomId}+ConsignmentStep`}
              selected={disposition}
              setSelected={(value) => setDisposition(value)}
            />
          )
        case 2:
          return (
            <InspectionStep
              key={`${randomId}+InspectionStep`}
              setExpiryDate={setLocalExpiryDate}
              expiryDate={localExpiryDate}
              setNote={setComment}
              note={comment}
              temperature={temperature}
              setTemperature={setTemperature}
              packageIntegrity={packageIntegrity}
              setPackageIntegrity={setPackageIntegrity}
              hasExpiryDate={!!expirationDate}
            />
          )
        default:
          return null
      }
    }
    switch (step) {
      case 0:
        return (
          <CreateProductStep
            handleNext={({
              lotBatch,
              serialNumber,
              modelNumber,
              description,
              expiryDate,
              manufacturingDate,
              selectedCompany,
            }) => {
              // This implementation is a bit complex, but it's the only way to pass the props from the child component (create product) to the parent component.
              // This component was working fine in the inventory project, but it didn't work here and it was rerendering on every input change.
              if (modelNumber && description && selectedCompany) {
                setLocalLot(lotBatch)
                setLocalSerialNumber(serialNumber)
                setLocalModelNumber(modelNumber)
                setLocalDescription(description)
                setLocalManufacturingDate(manufacturingDate)
                setLocalCompany(selectedCompany)
                setLocalExpiryDate(expiryDate)
                handleNext()
              } else {
                toast.error('Please fill all required fields')
                return
              }
            }}
            ref={childComponentRef as any}
            key={`${randomId}+CreateProductStep`}
            // lot
            lotBatch={localLot}
            onLotChange={(value) => setLocalLot(value)}
            hasLotBatch={!!lotBatch}
            // model number
            modelNumber={localModelNumber}
            onModelNumberChange={(value) => setLocalModelNumber(value)}
            hasModelNumber={!!modelNumber}
            // serial number
            serialNumber={localSerialNumber}
            onSerialNumberChange={(value) => setLocalSerialNumber(value)}
            hasSerialNumber={!!serialNumber}
            // udi
            udi={udi}
            // expiry date
            expiryDate={
              localExpiryDate
                ? localExpiryDate
                : expirationDate
                ? new Date(expirationDate as Date)
                : undefined
            }
            onExpiryDateChange={(value) => setLocalExpiryDate(value)}
            hasExpiryDate={!!expirationDate}
            // manufacturing date
            hasManufacturingDate={!!manufacturingDate}
            manufacturingDate={LocalManufacturingDate}
            onManufacturingDateChange={(value) =>
              setLocalManufacturingDate(value)
            }
            // vendor
            description={localDescription}
            onDescriptionChange={(value) => setLocalDescription(value)}
            // company
            onCompanyChange={(value) => setLocalCompany(value)}
            selectedCompany={
              localCompany || {
                id: companyId || 0,
                name: company || '',
              }
            }
          />
        )
      case 1:
        return (
          <ProductTypeStep
            key={`${randomId}+ProductTypeStep`}
            selected={productType}
            setSelected={(value) => setProductType(value)}
          />
        )
      case 2:
        return (
          <ConsignmentStep
            key={`${randomId}+ConsignmentStep`}
            selected={disposition}
            setSelected={(value) => setDisposition(value)}
          />
        )
      case 3:
        return (
          <InspectionStep
            key={`${randomId}+InspectionStep`}
            setExpiryDate={setLocalExpiryDate}
            setNote={setComment}
            note={comment}
            expiryDate={localExpiryDate}
            temperature={temperature}
            setTemperature={setTemperature}
            packageIntegrity={packageIntegrity}
            setPackageIntegrity={setPackageIntegrity}
            hasExpiryDate={!!expirationDate}
          />
        )
      default:
        return null
    }
  }

  const stepConfigs: StepConfigs = {
    Biologic: {
      isNew: [
        'Create product*',
        'Select product type*',
        'Select ownership type*',
        'Select inspection*',
      ],
      regular: [
        'Select product type*',
        'Select ownership type*',
        'Select inspection*',
      ],
    },
    Default: {
      isNew: [
        'Create product*',
        'Select product type*',
        'Select ownership type*',
      ],
      regular: ['Select product type*', 'Select ownership type*'],
    },
  }

  const generateSteps = (
    productType: string,
    isNew: boolean,
    hasMultiScanning: boolean
  ): string[] => {
    const config = stepConfigs[productType] || stepConfigs['Default']
    const steps = isNew ? config.isNew : config.regular

    if (hasMultiScanning) {
      steps.push('Attach codes')
    }
    return steps
  }

  const useLocalStateSetter = ({
    incomingValue,
    setLocalState,
  }: LocalStateSetterProps) => {
    useEffect(() => {
      if (incomingValue === null || incomingValue === undefined) {
        setLocalState(undefined)
      } else {
        setLocalState(incomingValue)
      }
    }, [incomingValue, setLocalState])
  }

  useLocalStateSetter({
    incomingValue: expirationDate,
    setLocalState: setLocalExpiryDate,
  })

  useLocalStateSetter({ incomingValue: lotBatch, setLocalState: setLocalLot })
  useLocalStateSetter({
    incomingValue: serialNumber,
    setLocalState: setLocalSerialNumber,
  })

  useLocalStateSetter({
    incomingValue: modelNumber,
    setLocalState: setLocalModelNumber,
  })

  useLocalStateSetter({
    incomingValue: description,
    setLocalState: setLocalDescription,
  })

  useLocalStateSetter({
    incomingValue: manufacturingDate,
    setLocalState: setLocalManufacturingDate,
  })

  useEffect(() => {
    setSteps(generateSteps(productType as AssetType, isNew, hasMultiScanning))
    if (productType === 'Biologic') {
      setHasMultiScanning(true)
    }
  }, [isNew, productType, hasMultiScanning])

  useEffect(() => {
    if (company) {
      setLocalCompany({ id: 0, name: company })
    }
  }, [company])

  useEffect(() => {
    if (company) {
      setLocalCompany({ id: 0, name: company })
    }
  }, [])

  return {
    localExpiryDate,
    localSerialNumber,
    localLot,
    localModelNumber,
    localCompany,
    localDescription,
    handleDisposition,
    handleProductFlag,
    steps,
    productType,
    comment,
    disposition,
    temperature,
    packageIntegrity,
    activeStep,
    isNew,
    handleActionButtonLabel,
    companyId: localCompany?.id,
    hasMultiScanning,
    setHasMultiScanning,
    handleBack,
    handleNext,
    handleSave,
    setComment,
    setActiveStep,
    setProductType,
    setPackageIntegrity,
    setDisposition,
    setTemperature,
    isNextDisabled,
    handleActionButton,
    getStepContent,
  }
}
