import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

interface SPDCortexScannerContextProps {
  cameras: MediaDeviceInfo[]
  setCameras: Dispatch<SetStateAction<MediaDeviceInfo[]>>
  selectedCamera: MediaDeviceInfo | undefined
  setSelectedCamera: Dispatch<SetStateAction<MediaDeviceInfo | undefined>>
  mediaStream: MediaStream | undefined
  videoRef: MutableRefObject<HTMLVideoElement | null>
}

const SPDCortexScannerContext = createContext<
  SPDCortexScannerContextProps | undefined
>(undefined)

export const SPDCortexScannerProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])
  const [selectedCamera, setSelectedCamera] = useState<
    MediaDeviceInfo | undefined
  >(undefined)
  const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(
    undefined
  )
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const cameras = devices.filter((device) => device.kind === 'videoinput')
        setCameras(cameras)
        setSelectedCamera(cameras[0]) // Select the first camera by default
      } catch (error) {
        console.error('Error enumerating media devices:', error)
      }
    }

    getCameras()
  }, [])

  useEffect(() => {
    const initializeStream = async () => {
      if (selectedCamera && videoRef) {
        try {
          const constraints: MediaStreamConstraints = {
            video: {
              deviceId: { exact: selectedCamera.deviceId },
            },
          }
          const stream = await navigator.mediaDevices.getUserMedia(constraints)
          setMediaStream(stream)
        } catch (error) {
          console.error('Error accessing media stream:', error)
        }
      }
    }

    initializeStream()
  }, [selectedCamera])

  useEffect(() => {
    if (videoRef.current && mediaStream) {
      videoRef.current.srcObject = mediaStream
    }
  }, [mediaStream, selectedCamera?.deviceId])

  return (
    <SPDCortexScannerContext.Provider
      value={{
        cameras,
        setCameras,
        selectedCamera,
        setSelectedCamera,
        mediaStream,
        videoRef,
      }}
    >
      {children}
    </SPDCortexScannerContext.Provider>
  )
}

export const useSPDCortexScannerContext = () => {
  const context = useContext(SPDCortexScannerContext)

  if (context === undefined) {
    throw new Error(
      'useSPDCortexScannerContext should be used with SPDCortexScannerProvider'
    )
  }

  return context
}
