import { CircularProgress, Typography } from '@mui/material'
import { FlipCameraIos } from '@mui/icons-material'

import { Switch } from 'components/Switch/Switch'
import { LoadingButton } from 'components/mui'

import { useSPDCortexScannerContext } from '../SPDCortexScanner.context'
import { FormatType } from 'lib/cortex'

import './SPDCortexVideo.scss'
import { useSPDCortexVideo } from './SPDCortexVideo.logic'
import classNames from 'classnames'

const SPDCortexVideo = ({
  onScan,
  isEnabled = true,
}: {
  onScan: (newScan: CortexDecoder.CDResult) => void
  isEnabled?: boolean
}) => {
  const {
    formatType,
    handleFormatChange,
    isSwitchDisabled,
    handleToggleCameraMenu,
    isCameraMenuOpen,
    handleCameraChange,
  } = useSPDCortexVideo({
    onScan,
    isEnabled,
  })
  const { cameras, selectedCamera, videoRef } = useSPDCortexScannerContext()

  return (
    <>
      {cameras.length === 0 || !selectedCamera ? (
        <CircularProgress />
      ) : (
        <div className="spd-cortex-video_wrapper">
          <div className="camera-select_container">
            <LoadingButton
              loading={false}
              disabled={false}
              onClick={handleToggleCameraMenu}
              sx={{ zIndex: 9999 }}
            >
              <FlipCameraIos />
            </LoadingButton>

            <div
              className="camera-options"
              style={{
                display: isCameraMenuOpen ? 'flex' : 'none',
                opacity: isCameraMenuOpen ? 1 : 0,
              }}
            >
              {cameras.map((camera: any) => (
                <div
                  key={camera.deviceId}
                  className={classNames('camera', {
                    active: camera.deviceId === selectedCamera?.deviceId,
                  })}
                  onClick={() => handleCameraChange(camera)}
                >
                  <Typography variant="body2" className="camera-text">
                    {camera.label}
                  </Typography>

                  <Typography variant="body2" className="camera-text">
                    {camera?.cameraType}
                  </Typography>
                </div>
              ))}
            </div>
          </div>

          <div className="format-switch_container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M16 17v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1h1zm5 4h-4v-2h2v-2h2v4zM3 3h8v8H3V3zm2 2v4h4V5H5zm8-2h8v8h-8V3zm2 2v4h4V5h-4zM3 13h8v8H3v-8zm2 2v4h4v-4H5zm13-2h3v2h-3v-2zM6 6h2v2H6V6zm0 10h2v2H6v-2zM16 6h2v2h-2V6z"
                fill="#fff"
              />
            </svg>
            <Switch
              onChange={handleFormatChange}
              checked={formatType === FormatType['1d']}
              disabled={isSwitchDisabled}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z"
                fill="#fff"
              />
            </svg>
          </div>

          <div
            className={classNames('scanner_outline', {
              rectangle: formatType === FormatType['1d'],
            })}
          />

          <video
            ref={videoRef}
            id="video"
            width={window.innerWidth}
            height={window.innerHeight}
            playsInline
            autoPlay
          />
        </div>
      )}
    </>
  )
}

export default SPDCortexVideo
