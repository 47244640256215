import { useState, ChangeEvent, FocusEvent } from 'react'

// Types
import { ICounter } from './Counter.types'

const useCounterLogic = ({
  initialValue = 1,
  min = 1,
  max,
  step = 1,
  onChange,
}: ICounter) => {
  const [count, setCount] = useState<number>(initialValue)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(event.target.value))) return
    const value = Math.max(Number(event.target.value), min)
    setCount(value)
    onChange && onChange(value)
  }

  const handleIncrement = () => {
    const newValue =
      max !== undefined ? Math.min(count + step, max) : count + step
    setCount(newValue)
    onChange && onChange(newValue)
  }

  const handleDecrement = () => {
    const newValue = Math.max(count - step, min)
    setCount(newValue)
    onChange && onChange(newValue)
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
  }

  return {
    count,
    isFocused,
    handleChange,
    handleIncrement,
    handleDecrement,
    handleFocus,
    handleBlur,
  }
}
export default useCounterLogic
