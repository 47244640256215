import React from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'

import '@react-pdf-viewer/core/lib/styles/index.css'
import './PDFViewer.scss'

const PDFViewer: React.FC<{ fileUrl: string }> = ({ fileUrl }) => {
  return (
    <div className="pdf-viewer_organism">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer fileUrl={fileUrl} />
      </Worker>
    </div>
  )
}

export default PDFViewer
