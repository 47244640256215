import { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonClicked, TSendForApprovalProps } from './SendForApproval.types'
import { groupAssetsByAssetType } from 'lib/utils/data'
import { facility } from 'common/facilityMigration'
import { getExpiredAssetCount } from 'lib/utils/getExpiredAssetCount'
import { sendForRepApprovalValidationSchema } from './sendForApprovalValidationSchema'
import useFormValidation from 'lib/hooks/useFormValidation/useFormValidation'
import { noRepAvailValidationSchema } from './noRepAvailValidationSchema'

const areAllArraysEmpty = (obj: { [key: string]: any[] }): boolean => {
  return Object.values(obj).every(
    (arr) => Array.isArray(arr) && arr.length === 0
  )
}

export const useSendForApprovalLogic = ({
  surgery,
  handleSetAssetStatus,
  setAssetStatusMutation,
}: TSendForApprovalProps) => {
  const { assetTypes } = facility
  const { assetGroups, authorizedReps } = surgery ?? {}
  const [isSentForApproval, setIsSentForApproval] = useState(false)
  const [buttonClicked, setButtonClicked] = useState<ButtonClicked>(
    ButtonClicked.NO_REP_AVAILABLE
  )

  const groupedAssets = useMemo(
    () => groupAssetsByAssetType(assetGroups, assetTypes),
    [assetGroups, assetTypes]
  )

  const sendForRepApproval = useCallback(() => {
    handleSetAssetStatus('PRE_APPROVAL', 'primary', 'KLS Martin Group')
  }, [handleSetAssetStatus])

  const isGroupedAssetsEmpty = areAllArraysEmpty(groupedAssets)
  const surgeryRepCount = authorizedReps?.length ?? 0
  const numberOfExpired = getExpiredAssetCount(assetGroups)

  // BEGIN form validation / alerts for submission to emr
  const {
    isFormSubmitted,
    handleValidation,
    isSubmitDisabled,
    FormValidationAlertToasters,
    isFormValidated,
  } = useFormValidation({
    validationSchema:
      buttonClicked === ButtonClicked.SEND_FOR_REP_APPROVAL &&
      surgeryRepCount > 0
        ? sendForRepApprovalValidationSchema
        : noRepAvailValidationSchema,
  })

  const handleSendForApproval = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleValidation(e)
    },
    [handleValidation]
  )

  useEffect(() => {
    if (isFormSubmitted && isFormValidated && !isSentForApproval) {
      if (
        buttonClicked === ButtonClicked.SEND_FOR_REP_APPROVAL &&
        surgeryRepCount > 0
      ) {
        sendForRepApproval()
      } else {
        handleSetAssetStatus('PRE_APPROVAL', 'secondary')
      }
      setIsSentForApproval(true)
    }
  }, [
    buttonClicked,
    handleSetAssetStatus,
    isFormSubmitted,
    isFormValidated,
    isSentForApproval,
    sendForRepApproval,
    surgeryRepCount,
  ])
  // END form validation / alerts for submission to emr

  const disableSendForApproval = setAssetStatusMutation.loading
    ? false
    : isSubmitDisabled || isGroupedAssetsEmpty

  return {
    isGroupedAssetsEmpty,
    surgeryRepCount,
    numberOfExpiredHardware: numberOfExpired?.expiredHardware ?? 0,
    disableSendForApproval,
    handleSendForApproval,
    FormValidationAlertToasters,
    setButtonClicked,
  }
}
