/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'

import useSubmitTrayCapture from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/SubmitTrayCapture.logic'
import useCaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera.logic'
import { useCaptureTrayContext } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'

import { Camera } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import useTrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { CheckCircle } from '@mui/icons-material'
import { getAnalysisJSONFileName } from 'lib/utils/getAnalysisJSONFile'

const useCaptureTrayDialog = () => {
  const {
    handleSPDCaptureImage,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
  } = useCaptureCamera()
  const {
    count,
    chosenCount,
    setCount,
    displayCount,
    chosenDisplayCount,
    setDisplayCount,
    setTrayScrews,
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarState,
    trayDetails,
  } = useSPD()
  const { isDrawerOpen, setIsDrawerOpen, productData } = useSPDScan()
  const {
    submitImage,
    handleSubmit,
    setIsAnalysisFromSPD,
    analysisScrews,
    isComplete: isAnalysisComplete,
    setAnalysisFileName,
  } = useSubmitTrayCapture()
  const { setIsAlertOpen, setAlertData } = useCaptureTrayContext()
  const { findScrew } = useTrayMap()

  const [shouldStop, setShouldStop] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [manualImage, setManualImage] = useState<string>('')
  const [isValidUrl, setIsValidUrl] = useState<boolean>(false)

  const trayType = trayDetails?.trayType

  useEffect(() => {
    if (trayType) {
      const analysisFilename = getAnalysisJSONFileName(trayType)

      setAnalysisFileName(analysisFilename)
    }
  }, [trayType])

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0 && !shouldStop && count > 0 && isDrawerOpen) {
        setCount((prevCount) => prevCount - 1)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [shouldStop, isDrawerOpen])

  useEffect(() => {
    const getNewDisplayCount = () => {
      const minutes = Math.floor(count / 60)
      const seconds = count % 60
      const formattedCount = `${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`

      return formattedCount
    }

    if (!shouldStop && displayCount !== '00:00') {
      setDisplayCount(getNewDisplayCount())
    }
  }, [count])

  useEffect(() => {
    if (count === 0 && displayCount === '00:00') {
      handleSPDCaptureImage(true)
      setIsComplete(true)
    }
  }, [count, displayCount])

  useEffect(() => {
    handleReset()
  }, [isDrawerOpen])

  useEffect(() => {
    const handleAddScrewToSPD = () => {
      const mappedAnalysisScrews: BetterIDTrayScrew[] = analysisScrews.map(
        (analysisScrew: any) => ({
          row: analysisScrew.row + 1,
          column: analysisScrew.column,
          label: analysisScrew.label,
          wasted: analysisScrew.wasted,
          x: analysisScrew.x,
          deviceId: productData[0]?.deviceId,
          deviceDescription: productData[0]?.deviceDescription,
          company: productData[0]?.company,
          expirationDate: productData[0]?.expirationDate,
        })
      )

      const newScrewsFromAnalysis: BetterIDTrayScrew[] =
        mappedAnalysisScrews.filter(
          (mappedAnalysisScrew) =>
            !findScrew(
              mappedAnalysisScrew.label,
              mappedAnalysisScrew.row,
              mappedAnalysisScrew.x
            )
        )

      setTrayScrews((prevTrayScrews) => [
        ...prevTrayScrews,
        ...newScrewsFromAnalysis,
      ])
    }

    if (isAnalysisComplete || analysisScrews.length > 0) {
      handleAddScrewToSPD()
      setSnackbarIcon(<CheckCircle />)
      setSnackbarMessage('Successfully completed analysis')
      setSnackbarState('success')
      setSnackbarOpen(true)
    }
  }, [isAnalysisComplete, analysisScrews.length])

  const handleStopClick = () => {
    setShouldStop((prev) => !prev)
  }

  const handleReset = () => {
    setCount(chosenCount)
    setDisplayCount(chosenDisplayCount)
    setIsComplete(false)
    setImage('')
  }

  const handleCaptureClick = () => {
    setCount(0)
    setDisplayCount('00:00')
  }

  const handleCameraChange = (e: SelectChangeEvent<Camera['deviceId']>) => {
    const selectedCamera = cameras.find(
      (camera) => camera.deviceId === e.target.value
    )
    handleSelectCamera(selectedCamera as MediaDeviceInfo)
  }

  const handleSubmitImage = () => {
    setIsAnalysisFromSPD(true)
    submitImage(image)
  }

  const handleManualImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setManualImage(e.target.value)

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
    const isValid = urlRegex.test(e.target.value)

    if (isValid) {
      setIsValidUrl(true)
      setImage('')
      return
    }
    setIsValidUrl(false)
  }

  const handleManualImageSubmit = () => {
    if (isValidUrl) {
      setIsAnalysisFromSPD(true)
      handleSubmit(manualImage)
    }
  }

  const handleAlertClose = () => {
    setIsAlertOpen(false)
    setAlertData({
      description: '',
      mode: undefined,
    })
  }

  const handleClose = () => {
    setIsDrawerOpen(false)
  }

  return {
    isComplete,
    handleReset,
    shouldStop,
    handleCaptureClick,
    handleStopClick,
    displayCount,
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    handleCameraChange,
    handleSelectCamera,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleSubmitImage,
    manualImage,
    setManualImage,
    handleManualImageChange,
    isValidUrl,
    setIsValidUrl,
    handleManualImageSubmit,
    handleAlertClose,
    handleClose,
  }
}

export default useCaptureTrayDialog
