import createTheme from '@mui/material/styles/createTheme'

export const theme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              color: '#fff',
            }),
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#0C0E35',
          '&.Mui-selected': {
            backgroundColor: '#BEB4F8',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#5035ED',
            color: '#EFECFD',
          },
          '&.Mui-selected&:not(:hover)': {
            backgroundColor: '#BEB4F8',
          },
          '&:hover': {
            backgroundColor: '#BEB4F8',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: '#5035ED',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'var(--success-lightest)',
          color: 'var(--success)',
        },
        standardError: {
          backgroundColor: 'var(--error-lightest)',
          color: 'var(--error)',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#5035ED', // rgb(80, 53, 237)
      light: '#DFD9FC', // rgb(223, 217, 252)
      lightest: '#EFECFD',
      dark: '#0C0E35', //medgeo brand dark; rgba(12, 14, 53)
      hover: '#BEB4F8',
      disabled: '#9E8EF5',
      contrastText: '#190A71',
    },
    secondary: {
      main: '#4FA4E1',
      light: '#DCEDF9',
      lightest: '#EEF6FC',
      dark: '#185B8C',
      hover: '#B9DBF3',
      disabled: '#96C9ED',
      contrastText: '#124469',
    },
    error: {
      main: '#EA4848',
      light: '#FBDADA',
      lightest: '#FDEDED',
      dark: '#921111',
      hover: '#F6B6B6',
      disabled: '#F29191',
      contrastText: '#6E0D0D',
    },
    success: {
      main: '#42B348',
      light: '#B5E3B7',
      lightest: '#E1F4E2',
      dark: '#1C4A1E',
      hover: '#97D89A',
      disabled: '#79CD7D',
      contrastText: '#112D12',
    },
    warning: {
      main: '#EE6C01',
      light: '#FEC79A',
      lightest: '#FFE9D6',
      dark: '#652E01',
      hover: '#FEB171',
      disabled: '#FE9A48',
      contrastText: '#3D1C00',
    },
    grayscale: {
      main: '#999999',
      light: '#EBEBEB',
      lightest: '#F5F5F5',
      dark: '#525252',
      hover: '#D6D6D6',
      disabled: '#C2C2C2',
      contrastText: '#3D3D3D',
    },
    safety: {
      main: '#FDE220',
      light: '#FEF7C2',
      lightest: '#FFFAD7',
      dark: '#8D7D01',
      hover: '#FEF29A',
      disabled: '#FEED72',
      contrastText: '#655901',
    },
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      lineHeight: 2.04,
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.75,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },
} as const

type CustomTheme = {
  [Key in keyof typeof theme]: (typeof theme)[Key]
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {}
}

declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export default createTheme(theme)
