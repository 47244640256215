import * as Sentry from '@sentry/react'

export function initializeSentry(dsn?: string) {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  if (!dsn) {
    return console.warn('No Sentry DSN found')
  }

  Sentry.init({
    dsn,
  })
}

export function captureException(error: string | Error | unknown) {
  if (typeof error === 'string') {
    return Sentry.captureMessage(error)
  }

  Sentry.captureException(error)
}

export function captureWarning(error: Error) {
  Sentry.withScope((scope) => {
    scope.setLevel('warning')
    Sentry.captureException(error)
  })
}

export const ErrorBoundary = Sentry.ErrorBoundary
