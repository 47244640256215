import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingIndicator from './LoadingIndicator/LoadingIndicator'
import {
  useAddRepToSurgeryMutation,
  useValidateInviteRepQrCodeQuery,
} from 'lib/apollo/hooks'
import { useUser } from 'app/User'

const Invite: React.FC = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [mutationTriggered, setMutationTriggered] = useState<boolean>(false)
  const { inviteCode } = useParams<string>()
  const { user } = useUser()
  const { data, loading } = useValidateInviteRepQrCodeQuery(inviteCode ?? '')

  const { mutate: addRepToSurgery, data: mutationData } =
    useAddRepToSurgeryMutation()

  useEffect(() => {
    const performMutation = async () => {
      if (loading || !data) return
      if (!user?.id) {
        setError(`Error: No user id found`)
        return
      }
      if (!data.validateInviteRepQrCode.success) {
        setError(`Error: ${data.validateInviteRepQrCode.message}`)
        return
      }

      const { surgeryId, canAddAsset } = data.validateInviteRepQrCode.data
      const accessLevel: number = canAddAsset ? 1 : 0
      try {
        await addRepToSurgery({
          variables: { surgeryId, repId: user.id, accessLevel },
        })
        setMutationTriggered(true)
      } catch (error: string | any) {
        setError(`Error: ${error.message}`)
      }
    }

    if (!loading && !mutationTriggered) {
      performMutation()
    }
  }, [addRepToSurgery, data, loading, mutationTriggered, user?.id])

  useEffect(() => {
    if (mutationData) {
      if (mutationData.addRepToSurgery.success) {
        const { surgeryId, canAddAsset } = data.validateInviteRepQrCode.data
        navigate(`/surgeries/${surgeryId}/${canAddAsset ? '' : 'list'}`, {
          state: { canAddAsset },
        })
      } else {
        setError(`Error: ${mutationData.addRepToSurgery.message}`)
      }
    }
  }, [mutationData, navigate, data])

  return (
    <>
      {error ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {error}
        </div>
      ) : (
        <LoadingIndicator text="Authenticating sales rep" />
      )}
    </>
  )
}

export default Invite
