import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Toaster, ToastBar } from 'react-hot-toast'
import { Box } from '@mui/system'
import ErrorDisplay from 'components/ErrorDisplay'
import { CortexDecoderProvider, CortexVideo } from 'lib/batchScanner'
import { BatchScanToast } from 'components/BatchScanToast'
import { MainIconFlowState, Surgery } from 'common/types'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { BatchScan } from './BatchScan'
import { UndocumentedList } from './UndocumentedList/UndocumentedList'

interface BatchScanningProps {
  surgery: Surgery
  setIsBatchMode: React.Dispatch<React.SetStateAction<boolean>>
  batchAddAssetsToSurgery: any
  batchAddAssetsToSurgeryMutation: any
  mainIconFlowState: MainIconFlowState
  setMainIconFlowState: (val: MainIconFlowState) => void
  currentAssetType: MainIconFlowState['assetTypeLabel']
}

export function BatchScanning({
  surgery,
  setIsBatchMode,
  batchAddAssetsToSurgery,
  batchAddAssetsToSurgeryMutation,
  mainIconFlowState,
  setMainIconFlowState,
  currentAssetType,
}: BatchScanningProps) {
  const { undocumentedScansQuery } = useUndocumentedAssetsContext()

  const [currentScan, setCurrentScan] = React.useState<
    CortexDecoder.CDResult | undefined
  >()

  React.useEffect(() => {
    setIsBatchMode(true)
  }, [setIsBatchMode])

  if (undocumentedScansQuery.error) {
    return <ErrorDisplay pathForward={undocumentedScansQuery.refetch} />
  }

  return (
    <CortexDecoderProvider
      onScan={setCurrentScan}
      currentScan={currentScan}
      setCurrentScan={setCurrentScan}
    >
      <CortexVideo />
      <Toaster
        containerStyle={{
          bottom: 100,
        }}
        position="bottom-center"
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <BatchScanToast
                icon={icon}
                message={message}
                toast={t}
                // scan={data}
              />
            )}
          </ToastBar>
        )}
      </Toaster>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        flex={1}
      >
        <Box
          maxWidth="768px"
          width="100%"
          display="flex"
          flexDirection="column"
          flex={1}
          position="relative"
        >
          <Routes>
            <Route
              path="/"
              element={
                <BatchScan
                  setIsBatchMode={setIsBatchMode}
                  mainIconFlowState={mainIconFlowState}
                  setMainIconFlowState={setMainIconFlowState}
                  currentAssetType={currentAssetType}
                />
              }
            />
            <Route
              path="/undocumented"
              element={
                <UndocumentedList
                  surgery={surgery}
                  surgeryId={surgery._id}
                  batchAddAssetsToSurgery={batchAddAssetsToSurgery}
                  batchAddAssetsToSurgeryMutation={
                    batchAddAssetsToSurgeryMutation
                  }
                />
              }
            />
          </Routes>
        </Box>
      </Box>
    </CortexDecoderProvider>
  )
}
