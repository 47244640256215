import { ImplantReportType, ImplantReportStatus } from 'common/types'
import { useState } from 'react'

export const useViewImplantReportModalLogic = (
  implantReportStatus: ImplantReportStatus[]
) => {
  const [currentReportIndex, setCurrentReportIndex] = useState(0)

  const handleNextReport = () => {
    if (implantReportStatus && implantReportStatus.length > 0) {
      setCurrentReportIndex(
        (prevIndex) => (prevIndex + 1) % implantReportStatus.length
      )
    }
  }

  const handlePreviousReport = () => {
    if (implantReportStatus && implantReportStatus.length > 0) {
      setCurrentReportIndex((prevIndex) =>
        prevIndex === 0 ? implantReportStatus.length - 1 : prevIndex - 1
      )
    }
  }

  const currentReport =
    implantReportStatus && implantReportStatus.length > 0
      ? implantReportStatus[currentReportIndex]
      : {
          type: ImplantReportType.OTHER,
          referringPhysicianLastName:
            '' as ImplantReportStatus['referringPhysicianLastName'],
          referringPhysicianEmail:
            '' as ImplantReportStatus['referringPhysicianEmail'],
          createdAt: new Date() as ImplantReportStatus['createdAt'],
          implantReport:
            '<p>No reports available.</p>' as ImplantReportStatus['implantReport'],
        }

  return {
    currentReport,
    handleNextReport,
    handlePreviousReport,
    currentReportIndex,
  }
}
